//@ts-nocheck
import React, { ReactNode } from "react";
import classNames from "classnames";
import { FixLater } from "types";
import InlineInput from "components/Typography/InlineInput";

export type TextProps = {
  input?: FixLater;
  value?: string | Object;
  placeholder?: string;
  canEdit?: boolean;
  onConfirm?: (value: any) => void;
  onKeyPress?: (value: string) => string;
  onClick?: () => void;
  clearOnConfirm?: boolean;
  children?: ReactNode;
  muted?: boolean;
  className?: string;
};

export const Text = ({
  input = { type: "text" },
  value,
  placeholder = "Enter value",
  canEdit,
  onConfirm,
  onClick,
  clearOnConfirm,
  onKeyPress,
  children,
  muted = false,
  className,
}: TextProps) => {
  const inlineInput = {
    ...input,
  };
  if (placeholder && !input.placeholder) {
    inlineInput.placeholder = placeholder;
  }
  return (
    <span className={classNames(className, { "text-muted": muted })}>
      {canEdit ? (
        <InlineInput
          input={inlineInput}
          defaultValue={value}
          onConfirm={onConfirm}
          clearOnConfirm={clearOnConfirm}
          onClick={onClick}
          onKeyPress={onKeyPress}
        />
      ) : (
        value || children
      )}
    </span>
  );
};

export default Text;
