//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import { REQUEST_CHECKOUTS, REQUEST_CHECKOUT } from "../actions/actionTypes";
import * as actions from "../actions/checkouts";
import Api from "../api/checkouts";

function* fetchCheckout(action) {
  try {
    const checkout = yield Api.getCheckout(action.payload);
    yield put(actions.receiveCheckout(checkout));
  } catch (e) {
    yield put(actions.requestCheckoutFailure(e));
  }
}

function* fetchCheckouts(action) {
  try {
    const { data, metadata } = yield Api.getCheckouts(action.payload);
    yield put(actions.receiveCheckouts(data, metadata.totalCount, metadata.page, metadata.limit));
  } catch (e) {
    yield put(actions.requestCheckoutsFailure(e));
  }
}

function* checkoutsSaga() {
  yield takeLatest(REQUEST_CHECKOUT, fetchCheckout);
  yield takeLatest(REQUEST_CHECKOUTS, fetchCheckouts);
}

export default checkoutsSaga;
