//@ts-nocheck
import * as type from "./actionTypes";
import { receivePagedResult, requestFailure, requestInitiate } from "./common";

export function requestTasks(options = {}) {
  return {
    type: type.GET_TASKS_REQUEST,
    payload: options,
  };
}
export function receiveTasks(tasks, paging) {
  return receivePagedResult(type.GET_TASKS_RECEIVE, tasks, paging);
}
export function requestTasksFailure(error) {
  return requestFailure(type.GET_TASKS_FAILURE, error);
}

export function requestTask(taskId) {
  return {
    type: type.GET_TASK_REQUEST,
    payload: taskId,
  };
}
export function receiveTask(task) {
  return {
    type: type.GET_TASK_RECEIVE,
    payload: task,
  };
}
export function requestTaskFailure(error) {
  return requestFailure(type.GET_TASK_FAILURE, error);
}

export function requestTaskBlocks(taskID, options = {}) {
  return {
    type: type.GET_TASK_BLOCKS_REQUEST,
    payload: {
      taskID,
      options,
    },
  };
}
export function receiveTaskBlocks(taskBlocks, paging) {
  return receivePagedResult(type.GET_TASK_BLOCKS_RECEIVE, taskBlocks, paging);
}
export function requestTaskBlocksFailure(error) {
  return requestFailure(type.GET_TASK_BLOCKS_FAILURE, error);
}

export const requestTaskLists = (params) => requestInitiate(type.GET_TASK_LISTS_REQUEST, params);
export const fulfillRequestTaskLists = (items, paging) => receivePagedResult(type.GET_TASK_LISTS_FULFIL, items, paging);
export const rejectRequestTaskLists = (err) => requestFailure(type.GET_TASK_LISTS_REJECT, err);
