//@ts-nocheck
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Card from "components/Card";
import KeyHandler, { KEY_CODE_ESC } from "components/KeyHandler";
import Overlay from "components/Overlay";
import style from "./dropdown.module.scss";

const Dropdown = ({ defaultOpen, children, className }) => {
  const [isOpen, setOpen] = useState(defaultOpen);

  const openDropdown = () => setOpen(true);
  const closeDropdown = () => setOpen(false);

  const render = () =>
    (Array.isArray(children) ? children : [children]).map((child, index) => {
      const isFunction = typeof child === "function";
      if (isFunction) {
        return <React.Fragment key={index}>{child(isOpen, openDropdown, closeDropdown)}</React.Fragment>;
      }
      return (
        <React.Fragment key={index}>
          {React.cloneElement(child, {
            isOpen,
            openDropdown,
            closeDropdown,
          })}
        </React.Fragment>
      );
    });
  return (
    <div className={style.dropdown}>
      <Overlay visible={isOpen} onClick={closeDropdown} className={style.overlay} />
      <KeyHandler keyCode={KEY_CODE_ESC} onKeyHandle={closeDropdown} />
      {render()}
    </div>
  );
};

Dropdown.defaultProps = {
  defaultOpen: false,
};

Dropdown.propTypes = {
  defaultOpen: PropTypes.bool,
  className: PropTypes.string,
};

export const DropdownPanel = ({ className, children }) => {
  return <Card className={classNames(style.dropdownPanel, style.panel, className)}>{children}</Card>;
};

export default Dropdown;
