import { ComponentProps, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { requestProject } from "lib/actions/projects";
import { receiveTask, requestTaskBlocks } from "lib/actions/tasks";
import TaskAPI from "lib/api/tasks";
import Capabilities from "lib/constants/Capabilities";
import TaskStatus from "lib/constants/TaskStatus";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getProjectByID } from "lib/reducers/projects.selectors";
import { getTaskBlocks } from "lib/reducers/tasks.selectors";
import { FixLater, TTask } from "types";
import { DataGrid, DataItem } from "components/DataGrid";
import { SelectInput } from "components/Input";
import Loading from "components/Loading";
import { useRoleHasCapability } from "components/Restricted";
import { Sheet } from "components/Sheet/Sheet";
import { SheetHeader } from "components/Sheet/components/SheetHeader";
import { Text } from "components/Typography";
import { Breadcrumbs, BreadcrumbsItem } from "components/breadcrumbs";
import style from "./taskSheet.module.scss";

type TaskSheetProps = ComponentProps<"div"> & {
  task: TTask;
};

export const TaskSheet = ({ task, className }: TaskSheetProps) => {
  const params = useParams();
  const projectID = parseInt(params.projectID || "");
  const dispatch = useDispatch();
  const taskBlocks = useSelector(getTaskBlocks);
  const project = useSelector(getProjectByID(projectID));
  const canUpdate = useRoleHasCapability(Capabilities.PROJECT_UPDATE);

  useEffect(() => {
    fetchBlocks();
  }, []);

  useEffect(() => {
    if (project) return;
    dispatch(requestProject(projectID));
  }, [projectID]);

  const fetchBlocks = () => {
    dispatch(requestTaskBlocks(task.id));
  };

  const saveChanges = (key: keyof TTask) => {
    return async (value: FixLater) => {
      try {
        const response = await TaskAPI.updateTask(task.id, {
          ...task,
          [key]: value,
        });
        dispatch(receiveTask(response.body.data));
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const handleBlocksChange = async (pageBlocks: FixLater[]) => {
    try {
      await TaskAPI.updateTaskBlocks(task.id, pageBlocks);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <div className={classNames(className, "px-10 flex flex-col gap-4")}>
      {project ? (
        <Breadcrumbs>
          <BreadcrumbsItem text="Work orders" href={`/apps/projects`} />
          <BreadcrumbsItem text={project.name} href={`/apps/projects/${project.id}`} />
          <BreadcrumbsItem text={task.title} />
        </Breadcrumbs>
      ) : (
        <Loading size="medium" />
      )}
      <SheetHeader
        disabled={!canUpdate}
        title={task.title}
        icon={task.icon}
        coverImageUrl={task.coverImageUrl}
        onTitleChange={saveChanges("title")}
        onIconChange={saveChanges("icon")}
        onCoverImageChange={saveChanges("coverImageUrl")}
      />
      <div className="flex flex-row divide-x">
        <div className="flex-1 pr-4 sm:pr-6">
          <Sheet
            pageBlocks={taskBlocks}
            onReorder={handleBlocksChange}
            onCreateBlock={handleBlocksChange}
            onRemoveBlock={handleBlocksChange}
          />
        </div>
        <div className="flex-[25rem] flex-grow-0 pl-4 sm:pl-6">
          <DataGrid className={style.metadata}>
            <DataItem
              label="Code"
              value={
                <Text className="text-muted">
                  {task.taskList.key}-{task.code}
                </Text>
              }
            />
            <DataItem
              label="Status"
              value={
                <SelectInput
                  input={{
                    name: "status",
                    placeholder: "Select status",
                    clearable: false,
                    options: TaskStatus.items,
                  }}
                  value={task.status}
                  onChange={(name: string, value: FixLater) => saveChanges("status")(value?.value)}
                />
              }
            />
          </DataGrid>
        </div>
      </div>
    </div>
  );
};
