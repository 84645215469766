import { TTimeInterval } from "types";

export type CalibrationIntervalsOption = {
  label: string;
  value: TTimeInterval;
};

export class CalibrationIntervals {
  static DAY = "day";
  static WEEK = "week";
  static MONTH = "month";
  static QUARTER = "quarter";
  static YEAR = "year";

  static get items() {
    return [
      {
        label: "Daily",
        value: this.DAY,
      },
      {
        label: "Weekly",
        value: this.WEEK,
      },
      {
        label: "Monthly",
        value: this.MONTH,
      },
      {
        label: "Quarterly",
        value: this.QUARTER,
      },
      {
        label: "Yearly",
        value: this.YEAR,
      },
    ];
  }
}

export type CalibrationResultOption = {
  label: string;
  description: string;
  value: "pass" | "fail";
};

export class CalibrationResults {
  static PASS: CalibrationResultOption["value"] = "pass";
  static FAIL: CalibrationResultOption["value"] = "fail";

  static get items(): CalibrationResultOption[] {
    return [
      {
        label: "Pass",
        description: "The tool passed calibration",
        value: this.PASS,
      },
      {
        label: "Fail",
        description: "The tool failed calibration",
        value: this.FAIL,
      },
    ];
  }
}
