import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FixLater } from "types";
import style from "./table.module.scss";

const TableWrapper = styled.div`
  border-radius: 0.5rem;
  border: 1px solid #eeeeee;
`;

const Table = ({ className, children, ...otherProps }: FixLater) => (
  <TableWrapper>
    <table {...otherProps} className={classNames(style.table, className)}>
      {children}
    </table>
  </TableWrapper>
);

Table.defaultProps = {
  className: "",
};

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Table;
