import React, { FormEvent, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createRagController } from "store/reducers";
import { CreateRagControllerRequest, TRagController } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared/layouts";

type CreateRagControllerModalProps = {
  onSuccess?: (entity: TRagController) => void;
};

export const CreateRagControllerModal = ({ onSuccess }: CreateRagControllerModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateRagControllerRequest>({
    imageUrl: "",
    name: "",
    description: "",
  });

  const handleChange = (name: string, value: string | number | undefined) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const entity = await dispatch(createRagController(values)).unwrap();
      closeModal();
      onSuccess && onSuccess(entity);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader title="Create rag controller" />
        <ModalContent>
          <VSpace>
            <FieldGroup label="Name">
              <TextInput
                input={{
                  name: "name",
                  placeholder: "Enter controller name",
                  autoFocus: true,
                }}
                value={values.name}
                onChange={handleChange}
              />
            </FieldGroup>
            <FieldGroup label="Location">
              <LocationSelector
                appearance="normal"
                name="locationId"
                value={values.locationId}
                onChange={(name, value) => handleChange(name, value?.id)}
              />
            </FieldGroup>
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit">Create controller</PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
