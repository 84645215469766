import { ReactNode } from "react";
import classNames from "classnames";
import { TailwindFlexDirection, TailwindGap } from "types/tailwind";

type HSpaceProps = {
  gap?: TailwindGap;
  direction?: TailwindFlexDirection;
  children: ReactNode;
  className?: string;
};

export const HSpace = ({ gap = "gap-4", direction = "flex-row", children, className }: HSpaceProps) => {
  return <div className={classNames("flex", direction, gap, className)}>{children}</div>;
};
