//@ts-nocheck
// Users
export const REQUEST_USERS = "REQUEST_USERS";
export const RECEIVE_USERS = "RECEIVE_USERS";
export const REQUEST_USERS_FAILURE = "REQUEST_USERS_FAILURE";

// User
export const REQUEST_USER = "REQUEST_USER";
export const RECEIVE_USER = "RECEIVE_USER";
export const REQUEST_USER_FAILURE = "REQUEST_USER_FAILURE";

// Tags
export const REQUEST_TAGS = "REQUEST_TAGS";
export const RECEIVE_TAGS = "RECEIVE_TAGS";
export const REQUEST_TAGS_FAILURE = "REQUEST_TAGS_FAILURE";

// Tag
export const REQUEST_TAG = "REQUEST_TAG";
export const RECEIVE_TAG = "RECEIVE_TAG";
export const REQUEST_TAG_FAILURE = "REQUEST_TAG_FAILURE";

// Assets
export const REQUEST_ASSETS = "REQUEST_TOOLS";
export const RECEIVE_ASSETS = "RECEIVE_TOOLS";
export const REQUEST_ASSETS_FAILURE = "REQUEST_TOOLS_FAILURE";

// Assigned assets
export const REQUEST_ASSIGNED_ASSETS = "REQUEST_ASSIGNED_ASSETS";
export const RECEIVE_ASSIGNED_ASSETS = "RECEIVE_ASSIGNED_ASSETS";
export const FAILURE_ASSIGNED_ASSETS = "FAILURE_ASSIGNED_ASSETS";

// Get asset history
export const GET_ASSET_HISTORY_REQUEST = "GET_ASSET_HISTORY_REQUEST";
export const GET_ASSET_HISTORY_RECEIVE = "GET_ASSET_HISTORY_RECEIVE";
export const GET_ASSET_HISTORY_FAILURE = "GET_ASSET_HISTORY_FAILURE";

// Asset
export const REQUEST_ASSET = "REQUEST_TOOL";
export const RECEIVE_ASSET = "RECEIVE_TOOL";
export const REQUEST_ASSET_FAILURE = "REQUEST_TOOL_FAILURE";

// Storages
export const REQUEST_STORAGES = "REQUEST_STORAGES";
export const RECEIVE_STORAGES = "RECEIVE_STORAGES";
export const REQUEST_STORAGES_FAILURE = "REQUEST_STORAGES_FAILURE";

// Storage
export const REQUEST_STORAGE = "REQUEST_STORAGE";
export const RECEIVE_STORAGE = "RECEIVE_STORAGE";
export const REQUEST_STORAGE_FAILURE = "REQUEST_STORAGE_FAILURE";

// Storage statistics
export const REQUEST_STORAGE_STATISTICS = "REQUEST_STORAGE_STATISTICS";
export const RECEIVE_STORAGE_STATISTICS = "RECEIVE_STORAGE_STATISTICS";
export const REQUEST_STORAGE_STATISTICS_FAILURE = "REQUEST_STORAGE_STATISTICS_FAILURE";

// Readers
export const REQUEST_READERS = "REQUEST_READERS";
export const RECEIVE_READERS = "RECEIVE_READERS";
export const REQUEST_READERS_FAILURE = "REQUEST_READERS_FAILURE";

// Reader
export const REQUEST_READER = "REQUEST_READER";
export const RECEIVE_READER = "RECEIVE_READER";
export const REQUEST_READER_FAILURE = "REQUEST_READER_FAILURE";

// Lookup data
export const GET_LOOKUPS_REQUEST = "GET_LOOKUPS_REQUEST";
export const GET_LOOKUPS_RECEIVE = "GET_LOOKUPS_RECEIVE";
export const GET_LOOKUPS_FAILURE = "GET_LOOKUPS_FAILURE";

// Roles
export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_RECEIVE = "GET_ROLES_RECEIVE";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";
export const GET_ROLE_REQUEST = "GET_ROLE_REQUEST";
export const GET_ROLE_RECEIVE = "GET_ROLE_RECEIVE";
export const GET_ROLE_FAILURE = "GET_ROLE_FAILURE";

// Checkouts
export const REQUEST_CHECKOUTS = "REQUEST_CHECKOUTS";
export const RECEIVE_CHECKOUTS = "RECEIVE_CHECKOUTS";
export const REQUEST_CHECKOUTS_FAILURE = "REQUEST_CHECKOUTS_FAILURE";

// Checkout
export const REQUEST_CHECKOUT = "REQUEST_CHECKOUT";
export const RECEIVE_CHECKOUT = "RECEIVE_CHECKOUT";
export const REQUEST_CHECKOUT_FAILURE = "REQUEST_CHECKOUT_FAILURE";

// Statistics
export const REQUEST_STATISTICS = "REQUEST_STATISTICS";
export const RECEIVE_STATISTICS = "RECEIVE_STATISTICS";
export const REQUEST_STATISTICS_FAILURE = "REQUEST_STATISTICS_FAILURE";

// Metrics
export const GET_METRICS_REQUEST = "GET_METRICS_REQUEST";
export const GET_METRICS_RECEIVE = "GET_METRICS_RECEIVE";
export const GET_METRICS_FAILURE = "GET_METRICS_FAILURE";

// Storages metrics
export const GET_STORAGES_METRICS_REQUEST = "GET_STORAGES_METRICS_REQUEST";
export const GET_STORAGES_METRICS_RECEIVE = "GET_STORAGES_METRICS_RECEIVE";
export const GET_STORAGES_METRICS_FAILURE = "GET_STORAGES_METRICS_FAILURE";

// Assets metrics
export const GET_ASSETS_METRICS_REQUEST = "GET_ASSETS_METRICS_REQUEST";
export const GET_ASSETS_METRICS_RECEIVE = "GET_ASSETS_METRICS_RECEIVE";
export const GET_ASSETS_METRICS_FAILURE = "GET_ASSETS_METRICS_FAILURE";

// Modals
export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";

// Tooltip
export const TOOLTIP_OPEN = "TOOLTIP_OPEN";
export const TOOLTIP_CLOSE = "TOOLTIP_CLOSE";

// Keyboard
export const KEYBOARD_OPEN = "KEYBOARD_OPEN";
export const KEYBOARD_CLOSE = "KEYBOARD_CLOSE";

// Sidebar
export const SET_PAGE_SIDEBAR = "SET_PAGE_SIDEBAR";

// Projects
export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";

// Project
export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "GET_PROJECT_FAILURE";
export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_FULFIL = "CREATE_PROJECT_FULFIL";
export const CREATE_PROJECT_REJECT = "CREATE_PROJECT_REJECT";
export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_FULFIL = "UPDATE_PROJECT_FULFIL";
export const UPDATE_PROJECT_REJECT = "UPDATE_PROJECT_REJECT";

// Project tasks
export const GET_PROJECT_TASKS_REQUEST = "GET_PROJECT_TASKS_REQUEST";
export const GET_PROJECT_TASKS_RECEIVE = "GET_PROJECT_TASKS_RECEIVE";
export const GET_PROJECT_TASKS_FAILURE = "GET_PROJECT_TASKS_FAILURE";

// Project task
export const GET_PROJECT_TASK_REQUEST = "GET_PROJECT_TASK_REQUEST";
export const GET_PROJECT_TASK_RECEIVE = "GET_PROJECT_TASK_RECEIVE";
export const GET_PROJECT_TASK_FAILURE = "GET_PROJECT_TASK_FAILURE";

// Projects lookup
export const GET_LOOKUP_PROJECTS_REQUEST = "GET_LOOKUP_PROJECTS_REQUEST";
export const GET_LOOKUP_PROJECTS_RECEIVE = "GET_LOOKUP_PROJECTS_RECEIVE";
export const GET_LOOKUP_PROJECTS_FAILURE = "GET_LOOKUP_PROJECTS_FAILURE";

// Project tasks lookup
export const GET_LOOKUP_PROJECT_TASKS_REQUEST = "GET_LOOKUP_PROJECT_TASKS_REQUEST";
export const GET_LOOKUP_PROJECT_TASKS_RECEIVE = "GET_LOOKUP_PROJECT_TASKS_RECEIVE";
export const GET_LOOKUP_PROJECT_TASKS_FAILURE = "GET_LOOKUP_PROJECT_TASKS_FAILURE";

// Catalogue manufacturers
export const GET_CATALOGUE_MANUFACTURERS_REQUEST = "GET_CATALOGUE_MANUFACTURERS_REQUEST";
export const GET_CATALOGUE_MANUFACTURERS_RECEIVE = "GET_CATALOGUE_MANUFACTURERS_RECEIVE";
export const GET_CATALOGUE_MANUFACTURERS_FAILURE = "GET_CATALOGUE_MANUFACTURERS_FAILURE";

// Catalogue products
export const GET_CATALOGUE_PRODUCTS_REQUEST = "GET_CATALOGUE_PRODUCTS_REQUEST";
export const GET_CATALOGUE_PRODUCTS_RECEIVE = "GET_CATALOGUE_PRODUCTS_RECEIVE";
export const GET_CATALOGUE_PRODUCTS_FAILURE = "GET_CATALOGUE_PRODUCTS_FAILURE";

// Storage users
export const GET_STORAGE_GRANTS_REQUEST = "GET_STORAGE_GRANTS_REQUEST";
export const GET_STORAGE_GRANTS_RECEIVE = "GET_STORAGE_GRANTS_RECEIVE";
export const GET_STORAGE_GRANTS_FAILURE = "GET_STORAGE_GRANTS_FAILURE";

// Catalogue manufacturer
export const GET_CATALOGUE_MANUFACTURER_REQUEST = "GET_CATALOGUE_MANUFACTURER_REQUEST";
export const GET_CATALOGUE_MANUFACTURER_RECEIVE = "GET_CATALOGUE_MANUFACTURER_RECEIVE";
export const GET_CATALOGUE_MANUFACTURER_FAILURE = "GET_CATALOGUE_MANUFACTURER_FAILURE";

// Catalogue product
export const GET_CATALOGUE_PRODUCT_REQUEST = "GET_CATALOGUE_PRODUCT_REQUEST";
export const GET_CATALOGUE_PRODUCT_RECEIVE = "GET_CATALOGUE_PRODUCT_RECEIVE";
export const GET_CATALOGUE_PRODUCT_FAILURE = "GET_CATALOGUE_PRODUCT_FAILURE";

// Report templates
export const GET_REPORT_TEMPLATES_REQUEST = "GET_REPORT_TEMPLATES_REQUEST";
export const GET_REPORT_TEMPLATES_RECEIVE = "GET_REPORT_TEMPLATES_RECEIVE";
export const GET_REPORT_TEMPLATES_FAILURE = "GET_REPORT_TEMPLATES_FAILURE";

// Reports
export const GET_REPORTS_REQUEST = "GET_REPORTS_REQUEST";
export const GET_REPORTS_RECEIVE = "GET_REPORTS_RECEIVE";
export const GET_REPORTS_FAILURE = "GET_REPORTS_FAILURE";

// Report exports
export const GET_REPORT_EXPORTS_REQUEST = "GET_REPORT_EXPORTS_REQUEST";
export const GET_REPORT_EXPORTS_RECEIVE = "GET_REPORT_EXPORTS_RECEIVE";
export const GET_REPORT_EXPORTS_FAILURE = "GET_REPORT_EXPORTS_FAILURE";

// Report template
export const GET_REPORT_TEMPLATE_REQUEST = "GET_REPORT_TEMPLATE_REQUEST";
export const GET_REPORT_TEMPLATE_RECEIVE = "GET_REPORT_TEMPLATE_RECEIVE";
export const GET_REPORT_TEMPLATE_FAILURE = "GET_REPORT_TEMPLATE_FAILURE";

// Report
export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST";
export const GET_REPORT_RECEIVE = "GET_REPORT_RECEIVE";
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";

// Categories
export const GET_MANUFACTURER_CATEGORIES_REQUEST = "GET_MANUFACTURER_CATEGORIES_REQUEST";
export const GET_MANUFACTURER_CATEGORIES_SUCCESS = "GET_MANUFACTURER_CATEGORIES_SUCCESS";
export const GET_MANUFACTURER_CATEGORIES_FAILURE = "GET_MANUFACTURER_CATEGORIES_FAILURE";

// Storage session
export const GET_STORAGE_SESSION_REQUEST = "GET_STORAGE_SESSION_REQUEST";
export const GET_STORAGE_SESSION_RECEIVE = "GET_STORAGE_SESSION_RECEIVE";
export const GET_STORAGE_SESSION_FAILURE = "GET_STORAGE_SESSION_FAILURE";

// Storage status
export const GET_STORAGE_STATUS_RECEIVE = "GET_STORAGE_STATUS_RECEIVE";

// Account settings
export const GET_ACCOUNT_SETTINGS_REQUEST = "GET_ACCOUNT_SETTINGS_REQUEST";
export const GET_ACCOUNT_SETTINGS_RECEIVE = "GET_ACCOUNT_SETTINGS_RECEIVE";
export const GET_ACCOUNT_SETTINGS_FAILURE = "GET_ACCOUNT_SETTINGS_FAILURE";

// Report builder
export const REPORT_BUILDER_GENERATE_REPORT_REQUEST = "REPORT_BUILDER_GENERATE_REPORT_REQUEST";
export const REPORT_BUILDER_GENERATE_REPORT_SUCCESS = "REPORT_BUILDER_GENERATE_REPORT_SUCCESS";
export const REPORT_BUILDER_GENERATE_REPORT_FAILURE = "REPORT_BUILDER_GENERATE_REPORT_FAILURE";
export const REPORT_BUILDER_GET_REPORT_COMPONENTS_REQUEST = "REPORT_BUILDER_GET_REPORT_COMPONENTS_REQUEST";
export const REPORT_BUILDER_GET_REPORT_COMPONENTS_SUCCESS = "REPORT_BUILDER_GET_REPORT_COMPONENTS_SUCCESS";
export const REPORT_BUILDER_GET_REPORT_COMPONENTS_FAILURE = "REPORT_BUILDER_GET_REPORT_COMPONENTS_FAILURE";
export const REPORT_BUILDER_SELECT_TEMPLATE = "REPORT_BUILDER_SELECT_TEMPLATE";
export const REPORT_BUILDER_SELECT_THEME = "REPORT_BUILDER_SELECT_THEME";
export const REPORT_BUILDER_SET_ELEMENTS = "REPORT_BUILDER_SET_ELEMENTS";
export const REPORT_BUILDER_SET_ELEMENT_CONTENT = "REPORT_BUILDER_SET_ELEMENT_CONTENT";
export const REPORT_BUILDER_RESET = "REPORT_BUILDER_RESET";

// Report evidence
export const GET_REPORT_EVIDENCE_REQUEST = "GET_REPORT_EVIDENCE_REQUEST";
export const GET_REPORT_EVIDENCE_RECEIVE = "GET_REPORT_EVIDENCE_RECEIVE";
export const GET_REPORT_EVIDENCE_FAILURE = "GET_REPORT_EVIDENCE_FAILURE";

// Evidence
export const GET_EVIDENCE_REQUEST = "GET_EVIDENCE_REQUEST";
export const GET_EVIDENCE_RECEIVE = "GET_EVIDENCE_RECEIVE";
export const GET_EVIDENCE_FAILURE = "GET_EVIDENCE_FAILURE";

// Company branding
export const GET_COMPANY_BRANDING_REQUEST = "GET_COMPANY_BRANDING_REQUEST";
export const GET_COMPANY_BRANDING_RECEIVE = "GET_COMPANY_BRANDING_RECEIVE";
export const GET_COMPANY_BRANDING_FAILURE = "GET_COMPANY_BRANDING_FAILURE";

// Popup flags for the user interface
export const SHOW_POPUP_FLAG = "SHOW_POPUP_FLAG";
export const DISMISS_POPUP_FLAG = "DISMISS_POPUP_FLAG";

// Shopping cart
export const CART_SET_PRODUCT_QUANTITY = "CART_SET_PRODUCT_QUANTITY";
export const CART_REMOVE_PRODUCT = "CART_REMOVE_PRODUCT";

// Access policy
export const GET_ACCESS_POLICY_REQUEST = "GET_ACCESS_POLICY_REQUEST";
export const GET_ACCESS_POLICY_RECEIVE = "GET_ACCESS_POLICY_RECEIVE";
export const GET_ACCESS_POLICY_FAILURE = "GET_ACCESS_POLICY_FAILURE";

// Access policy attempts
export const GET_ACCESS_POLICY_ATTEMPTS_REQUEST = "GET_ACCESS_POLICY_ATTEMPTS_REQUEST";
export const GET_ACCESS_POLICY_ATTEMPTS_RECEIVE = "GET_ACCESS_POLICY_ATTEMPTS_RECEIVE";
export const GET_ACCESS_POLICY_ATTEMPTS_FAILURE = "GET_ACCESS_POLICY_ATTEMPTS_FAILURE";

// Sentry configuration
export const GET_SENTRY_CONFIGURATION_REQUEST = "GET_SENTRY_CONFIGURATION_REQUEST";
export const GET_SENTRY_CONFIGURATION_RECEIVE = "GET_SENTRY_CONFIGURATION_RECEIVE";
export const GET_SENTRY_CONFIGURATION_FAILURE = "GET_SENTRY_CONFIGURATION_FAILURE";

// Calibration events
export const GET_CALIBRATION_EVENTS_REQUEST = "GET_CALIBRATION_EVENTS_REQUEST";
export const GET_CALIBRATION_EVENTS_RECEIVE = "GET_CALIBRATION_EVENTS_RECEIVE";
export const GET_CALIBRATION_EVENTS_FAILURE = "GET_CALIBRATION_EVENTS_FAILURE";

// Keycards
export const GET_KEYCARDS_REQUEST = "GET_KEYCARDS_REQUEST";
export const GET_KEYCARDS_RECEIVE = "GET_KEYCARDS_RECEIVE";
export const GET_KEYCARDS_FAILURE = "GET_KEYCARDS_FAILURE";

// Keycard
export const GET_KEYCARD_REQUEST = "GET_KEYCARD_REQUEST";
export const GET_KEYCARD_RECEIVE = "GET_KEYCARD_RECEIVE";
export const GET_KEYCARD_FAILURE = "GET_KEYCARD_FAILURE";

// Project blocks
export const GET_PROJECT_BLOCKS_REQUEST = "GET_PROJECT_BLOCKS_REQUEST";
export const GET_PROJECT_BLOCKS_RECEIVE = "GET_PROJECT_BLOCKS_RECEIVE";
export const GET_PROJECT_BLOCKS_FAILURE = "GET_PROJECT_BLOCKS_FAILURE";

// Tasks
export const GET_TASKS_REQUEST = "GET_TASKS_REQUEST";
export const GET_TASKS_RECEIVE = "GET_TASKS_RECEIVE";
export const GET_TASKS_FAILURE = "GET_TASKS_FAILURE";
export const GET_TASK_REQUEST = "GET_TASK_REQUEST";
export const GET_TASK_RECEIVE = "GET_TASK_RECEIVE";
export const GET_TASK_FAILURE = "GET_TASK_FAILURE";
export const GET_TASK_BLOCKS_REQUEST = "GET_TASK_BLOCKS_REQUEST";
export const GET_TASK_BLOCKS_RECEIVE = "GET_TASK_BLOCKS_RECEIVE";
export const GET_TASK_BLOCKS_FAILURE = "GET_TASK_BLOCKS_FAILURE";

// Task list
export const GET_TASK_LISTS_REQUEST = "GET_TASK_LISTS_REQUEST";
export const GET_TASK_LISTS_FULFIL = "GET_TASK_LISTS_FULFIL";
export const GET_TASK_LISTS_REJECT = "GET_TASK_LISTS_REJECT";

// Blocks
export const PAGE_BLOCKS_CHANGE = "PAGE_BLOCKS_CHANGE";
export const PAGE_BLOCK_SELECT = "PAGE_BLOCK_SELECT";
export const PAGE_BLOCK_SET_CURRENT = "PAGE_BLOCK_SET_CURRENT";
export const SHOW_BLOCK_MENU = "SHOW_BLOCK_MENU";
export const SHOW_BLOCK_MEDIA_MENU = "SHOW_BLOCK_MEDIA_MENU";
export const SHOW_BLOCK_CONFIG_MENU = "SHOW_BLOCK_CONFIG_MENU";

// Store items
export const GET_STORE_ITEMS_REQUEST = "GET_STORE_ITEMS_REQUEST";
export const GET_STORE_ITEMS_RECEIVE = "GET_STORE_ITEMS_RECEIVE";
export const GET_STORE_ITEMS_FAILURE = "GET_STORE_ITEMS_FAILURE";

// Store item
export const GET_STORE_ITEM_REQUEST = "GET_STORE_ITEM_REQUEST";
export const GET_STORE_ITEM_RECEIVE = "GET_STORE_ITEM_RECEIVE";
export const GET_STORE_ITEM_FAILURE = "GET_STORE_ITEM_FAILURE";
export const CREATE_STORE_ITEM_REQUEST = "CREATE_STORE_ITEM_REQUEST";
export const CREATE_STORE_ITEM_FULFIL = "CREATE_STORE_ITEM_FULFIL";
export const CREATE_STORE_ITEM_REJECT = "CREATE_STORE_ITEM_REJECT";
export const UPDATE_STORE_ITEM_REQUEST = "UPDATE_STORE_ITEM_REQUEST";
export const UPDATE_STORE_ITEM_FULFIL = "UPDATE_STORE_ITEM_FULFIL";
export const UPDATE_STORE_ITEM_REJECT = "UPDATE_STORE_ITEM_REJECT";
export const DELETE_STORE_ITEM_IMAGE_REQUEST = "DELETE_STORE_ITEM_IMAGE_REQUEST";
export const DELETE_STORE_ITEM_IMAGE_FULFIL = "DELETE_STORE_ITEM_IMAGE_FULFIL";
export const DELETE_STORE_ITEM_IMAGE_REJECT = "DELETE_STORE_ITEM_IMAGE_REJECT";

// Alerts
export const GET_ALERTS_REQUEST = "GET_ALERTS_REQUEST";
export const GET_ALERTS_FULFIL = "GET_ALERTS_FULFIL";
export const GET_ALERTS_REJECT = "GET_ALERTS_REJECT";
export const GET_ALERT_REQUEST = "GET_ALERT_REQUEST";
export const GET_ALERT_FULFIL = "GET_ALERT_FULFIL";
export const GET_ALERT_REJECT = "GET_ALERT_REJECT";
export const CREATE_ALERT_REQUEST = "CREATE_ALERT_REQUEST";
export const CREATE_ALERT_FULFIL = "CREATE_ALERT_FULFIL";
export const CREATE_ALERT_REJECT = "CREATE_ALERT_REJECT";
export const UPDATE_ALERT_REQUEST = "UPDATE_ALERT_REQUEST";
export const UPDATE_ALERT_FULFIL = "UPDATE_ALERT_FULFIL";
export const UPDATE_ALERT_REJECT = "UPDATE_ALERT_REJECT";
export const DELETE_ALERT_REQUEST = "DELETE_ALERT_REQUEST";
export const DELETE_ALERT_FULFIL = "DELETE_ALERT_FULFIL";
export const DELETE_ALERT_REJECT = "DELETE_ALERT_REJECT";
export const CREATE_ALERT_RULE_REQUEST = "CREATE_ALERT_RULE_REQUEST";
export const CREATE_ALERT_RULE_FULFIL = "CREATE_ALERT_RULE_FULFIL";
export const CREATE_ALERT_RULE_REJECT = "CREATE_ALERT_RULE_REJECT";
export const DELETE_ALERT_RULE_REQUEST = "DELETE_ALERT_RULE_REQUEST";
export const DELETE_ALERT_RULE_FULFIL = "DELETE_ALERT_RULE_FULFIL";
export const DELETE_ALERT_RULE_REJECT = "DELETE_ALERT_RULE_REJECT";
export const CREATE_ALERT_RECIPIENT_REQUEST = "CREATE_ALERT_RECIPIENT_REQUEST";
export const CREATE_ALERT_RECIPIENT_FULFIL = "CREATE_ALERT_RECIPIENT_FULFIL";
export const CREATE_ALERT_RECIPIENT_REJECT = "CREATE_ALERT_RECIPIENT_REJECT";
export const UPDATE_ALERT_RECIPIENT_REQUEST = "UPDATE_ALERT_RECIPIENT_REQUEST";
export const UPDATE_ALERT_RECIPIENT_FULFIL = "UPDATE_ALERT_RECIPIENT_FULFIL";
export const UPDATE_ALERT_RECIPIENT_REJECT = "UPDATE_ALERT_RECIPIENT_REJECT";
export const DELETE_ALERT_RECIPIENT_REQUEST = "DELETE_ALERT_RECIPIENT_REQUEST";
export const DELETE_ALERT_RECIPIENT_FULFIL = "DELETE_ALERT_RECIPIENT_FULFIL";
export const DELETE_ALERT_RECIPIENT_REJECT = "DELETE_ALERT_RECIPIENT_REJECT";
