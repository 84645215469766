//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import Logger from "../Logger";
import * as type from "../actions/actionTypes";
import * as builderActions from "../actions/reportBuilder";
import * as actions from "../actions/reports";
import Api from "../api/reports";

function* createReport(action) {
  try {
    const { template, elements } = action.payload;
    const data = yield Api.createReport(template, elements);
    yield put(builderActions.createReportSuccess(data.id));
  } catch (err) {
    Logger.error(err);
    yield put(builderActions.createReportFailure(err));
  }
}

function* fetchReports(action) {
  try {
    const { data, metadata } = yield Api.getReports(action.payload);
    yield put(actions.requestReportsSuccess(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestReportsFailure(err));
  }
}

function* fetchReport(action) {
  try {
    const data = yield Api.getReport(action.payload);
    yield put(actions.requestReportSuccess(data));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestReportFailure(err));
  }
}

function* fetchReportEvidence(action) {
  try {
    const evidence = yield Api.getReportEvidence(action.payload);
    yield put(actions.requestReportEvidenceSuccess(evidence));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestReportEvidenceFailure(err));
  }
}

function* fetchReportComponents(action) {
  try {
    const { data, metadata } = yield Api.getReportComponents(action.payload);
    yield put(builderActions.requestReportComponentsSuccess(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(builderActions.requestReportComponentsFailure(err));
  }
}

function* fetchReportExports(action) {
  try {
    const { data, metadata } = yield Api.getReportExports(action.payload.reportID, action.payload.options);
    yield put(actions.receiveReportExports(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestReportExportsFailure(err));
  }
}

function* reportsSaga() {
  yield takeLatest(type.REPORT_BUILDER_GENERATE_REPORT_REQUEST, createReport);
  yield takeLatest(type.REPORT_BUILDER_GET_REPORT_COMPONENTS_REQUEST, fetchReportComponents);
  yield takeLatest(type.GET_REPORTS_REQUEST, fetchReports);
  yield takeLatest(type.GET_REPORT_REQUEST, fetchReport);
  yield takeLatest(type.GET_REPORT_EVIDENCE_REQUEST, fetchReportEvidence);
  yield takeLatest(type.GET_REPORT_EXPORTS_REQUEST, fetchReportExports);
}

export default reportsSaga;
