//@ts-nocheck
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import get from "lodash/get";
import { deleteAlert, requestAlerts, updateAlert } from "lib/actions/alerts";
import { openModal } from "lib/actions/modal";
import { showPopupFlag } from "lib/actions/userInterface";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getAlerts, getAlertsPaging } from "lib/reducers/alerts.selectors";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { TableLink } from "components/Table";
import { SmartTable, SmartTablePagination } from "components/Table/SmartTable";
import { Text } from "components/Typography";
import { DropButton, DropItem } from "components/dropdowns";
import PageStage from "components/stages/PageStage";

const StorageAlerts = () => {
  const { pathname: url } = useLocation();
  const dispatch = useDispatch();
  const data = useSelector(getAlerts);
  const paging = useSelector(getAlertsPaging);

  const fetch = (pageStart, pageLimit) =>
    dispatch(
      requestAlerts({
        pageStart,
        pageLimit,
        rules: ["storage_access_granted", "storage_access_denied", "storage_online", "storage_offline"],
      }),
    );

  useEffect(() => fetch(paging.pageStart, paging.pageLimit), []);

  const rowData = useMemo(() => data, [data]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <TableLink to={`${url}/${id}`}>
              <Text
                canEdit={false}
                placeholder="Enter alert name"
                value={get(row, "original.name", "")}
                onConfirm={(value) =>
                  saveChanges({
                    ...row.original,
                    name: value,
                  })
                }
              />
            </TableLink>
          );
        },
      },
      {
        Header: "Description",
        Cell: ({ row }) => {
          return (
            <Text
              canEdit={true}
              placeholder="Alert description"
              value={get(row, "original.description", "")}
              onConfirm={(value) =>
                saveChanges({
                  ...row.original,
                  description: value,
                })
              }
            />
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }) => {
          const { id } = row.original;
          const isAdmin = get(row, "original.isAdmin", false);
          return (
            !isAdmin && (
              <DropButton icon="/images/icons/dots_1.svg" alignRight={true}>
                <DropItem icon="/images/icons/cross.svg" onClick={handleRemove(id)}>
                  Remove alert
                </DropItem>
              </DropButton>
            )
          );
        },
      },
    ];
  }, []);

  const handleCreate = () => {
    dispatch(
      openModal(Modals.CREATE_ALERT, {
        onSuccess: () => {
          const { pageLimit } = paging;
          fetch(0, pageLimit);
        },
      }),
    );
  };

  const saveChanges = (alert) => {
    dispatch(
      updateAlert(
        alert,
        () => {
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Changes saved",
            }),
          );
        },
        (err) => {
          handlePopupFlagError(err);
        },
      ),
    );
  };

  const handleRemove = (alertID) => {
    return () => {
      dispatch(
        openModal(Modals.CONFIRMATION, {
          description: "Are you sure you want to delete this alert? Doing this is permanent.",
          actionText: "Delete Alert",
          onConfirm: () => {
            dispatch(
              deleteAlert(
                alertID,
                () => {
                  dispatch(
                    showPopupFlag({
                      appearance: "success",
                      title: "Alert Removed",
                    }),
                  );
                  const { pageLimit } = paging;
                  fetch(0, pageLimit);
                },
                (err) => {
                  handlePopupFlagError(err);
                },
              ),
            );
          },
        }),
      );
    };
  };

  return (
    <PageStage>
      <StageHeader
        title="Storage alerts"
        subtitle="Configure and manage your company tool storage alerts"
        action={
          <SecondaryButton onClick={handleCreate} icon="/images/icons/plus_1.svg">
            Create alert
          </SecondaryButton>
        }
      />
      <SmartTable columns={columns} data={rowData} />
      <SmartTablePagination paging={paging} onChange={fetch} />
    </PageStage>
  );
};

export default StorageAlerts;
