import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TAsset, UUID } from "types";

const selectState = (state: RootStore) => state.assetsReducer;

export const selectAssets = createSelector(selectState, (state) => state.data);

export const selectAssetsList = createSelector(selectAssets, (data) => Object.values(data));

export const selectAssetByID = (id: number) => createSelector(selectAssets, (data): TAsset | undefined => data[id]);

export const selectAssetByGUID = (id: UUID) =>
  createSelector(selectAssetsList, (list): TAsset | undefined => list.find((asset) => asset.guid === id));
