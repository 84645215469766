//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  readers: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  reader: {
    fetching: false,
    error: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.REQUEST_READERS:
      return {
        ...state,
        readers: {
          ...state.readers,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.RECEIVE_READERS:
      return {
        ...state,
        readers: {
          ...state.readers,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.REQUEST_READERS_FAILURE:
      return {
        ...state,
        readers: {
          ...state.readers,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.REQUEST_READER:
      return {
        ...state,
        reader: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.RECEIVE_READER:
      return {
        ...state,
        reader: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.REQUEST_READER_FAILURE:
      return {
        ...state,
        reader: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    default:
      return state;
  }
};
