//@ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import { showPopupFlag } from "lib/actions/userInterface";
import UserAPI from "lib/api/users";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import store from "store";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextAreaInput, TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import style from "./RootModal/modal.module.scss";

const CreateKeycardModal = ({ modal, onClose }) => {
  const { onSuccess } = modal.props;
  const [values, setValues] = useState({ id: "", name: "", description: "" });
  const [submitting, isSubmitting] = useState(false);

  const handleChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    isSubmitting(true);
    try {
      event.preventDefault();
      const report = await UserAPI.createKeycard({
        ...values,
      });
      store.dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Created Keycard",
        }),
      );
      onClose();
      onSuccess && onSuccess(report);
    } catch (err) {
      handlePopupFlagError(err);
    }
    isSubmitting(false);
  };

  const valid = values.name;

  return (
    <Modal className={style.genericModal}>
      <ModalHeader title="Setup a new keycard" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="ID">
            <TextInput
              input={{
                name: "id",
                placeholder: "Enter keycard ID",
                autoFocus: true,
              }}
              value={values.id}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter keycard name",
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Description">
            <TextAreaInput
              input={{
                name: "description",
                placeholder: "Add a small description",
              }}
              value={values.description}
              onChange={handleChange}
            />
          </FieldGroup>
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton disabled={!valid || submitting} onClick={handleSubmit}>
          Create keycard
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

CreateKeycardModal.defaultProps = {};

CreateKeycardModal.propTypes = {
  className: PropTypes.string,
};

export default CreateKeycardModal;
