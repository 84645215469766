import Lookup from "lib/constants/Lookup";

class ImportAssetColumnTypes extends Lookup<string> {
  PRODUCT_NUMBER = "mpn";
  SERIAL_NUMBER = "serialNumber";
  STORAGE_ID = "storageId";
  BARCODE_VALUE = "barcodeValue";
  RFID_VALUE = "rfidTagId";
  STORAGE_ASSET_DRAWER_NUMBER = "drawerNumber";
  STORAGE_ASSET_ORDER = "order";
  STORAGE_ASSET_SVG = "svgPath";
  STORAGE_ASSET_BOARD_ID = "irSensorBoardId";
  STORAGE_ASSET_BOARD_SENSOR_ID = "irSensorNumber";
  STORAGE_ASSET_BOARD_SENSOR_THRESHOLD = "irSensorThreshold";
  ASSET_NAME = "name";
  ASSET_IMAGE_URL = "imageUrl";
  ASSET_DESCRIPTION = "description";
  ASSET_LOCATION = "locationName";

  constructor() {
    super();
    this.items = [
      {
        label: "Product number",
        description: "",
        value: this.PRODUCT_NUMBER,
      },
      {
        label: "Serial number",
        description: "",
        value: this.SERIAL_NUMBER,
      },
      {
        label: "Name",
        description: "",
        value: this.ASSET_NAME,
      },
      {
        label: "Image URL",
        description: "",
        value: this.ASSET_IMAGE_URL,
      },
      {
        label: "Description",
        description: "",
        value: this.ASSET_DESCRIPTION,
      },
      {
        label: "Location",
        description: "",
        value: this.ASSET_LOCATION,
      },
      {
        label: "Toolbox code",
        description: "",
        value: this.STORAGE_ID,
      },
      {
        label: "Drawer number",
        description: "",
        value: this.STORAGE_ASSET_DRAWER_NUMBER,
      },
      {
        label: "Barcode value",
        description: "",
        value: this.BARCODE_VALUE,
      },
      {
        label: "RFID value",
        description: "",
        value: this.RFID_VALUE,
      },
      {
        label: "Tool drawer order",
        description: "",
        value: this.STORAGE_ASSET_ORDER,
      },
      {
        label: "Tool SVG",
        description: "",
        value: this.STORAGE_ASSET_SVG,
      },
      {
        label: "Tool board ID",
        description: "",
        value: this.STORAGE_ASSET_BOARD_ID,
      },
      {
        label: "Tool board sensor ID",
        description: "",
        value: this.STORAGE_ASSET_BOARD_SENSOR_ID,
      },
      {
        label: "Tool board sensor threshold",
        description: "",
        value: this.STORAGE_ASSET_BOARD_SENSOR_THRESHOLD,
      },
    ];
  }
}

export default new ImportAssetColumnTypes();
