import { ReactNode } from "react";
import styled from "styled-components";

const Grid = styled.div<GridContainerProps>`
  display: grid;
  grid-auto-flow: dense;
  grid-gap: 1.5rem 1.5rem;
  grid-template-columns: repeat(${({ mobileColumns }) => mobileColumns}, 1fr);

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(${({ tabletColumns }) => tabletColumns}, 1fr);
  }

  @media only screen and (min-width: 1024px) {
    grid-gap: 1rem 1rem;
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  }
`;

type GridContainerProps = {
  columns?: number;
  tabletColumns?: number;
  mobileColumns?: number;
  children: ReactNode;
};

export const GridContainer = ({ columns = 3, tabletColumns = 2, mobileColumns = 1, children }: GridContainerProps) => {
  return (
    <Grid columns={columns} tabletColumns={tabletColumns} mobileColumns={mobileColumns}>
      {children}
    </Grid>
  );
};
