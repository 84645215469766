import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { fetchToolStores, selectToolStoresList } from "store/reducers";
import { TToolStore } from "types";
import { ToolStoreGridItem } from "components/shared/features/toolStores/ToolStoreGridItem/ToolStoreGridItem";

export const ToolStoresGrid = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const entities = useSelector(selectToolStoresList);

  useEffect(() => {
    dispatch(fetchToolStores({ pageLimit: 999 }));
  }, []);

  const selectToolStore = (value: TToolStore) => {
    navigate(`/toolstore/${value.id}`);
  };

  return (
    <div className="grid grid-cols-1 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-2 md:gap-y-0 lg:gap-x-4">
      {entities.map((entity) => (
        <ToolStoreGridItem key={entity.id} toolStoreId={entity.id} onClick={() => selectToolStore(entity)} />
      ))}
    </div>
  );
};
