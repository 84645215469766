import { FixLater, ToolStoreOrderStatus } from "types";
import { TailwindTextColor } from "types/tailwind";

type ToolStoreOrderStatusTextProps = {
  as?: FixLater;
  status: ToolStoreOrderStatus;
  textColor?: TailwindTextColor;
};

const DefaultElement = (props: FixLater): JSX.Element => {
  return <span {...props} />;
};

export const ToolStoreOrderStatusText = ({
  as = DefaultElement,
  status,
  textColor = "text-gray-900",
}: ToolStoreOrderStatusTextProps) => {
  const As = as;
  let text = "";
  switch (status) {
    case "pending":
      text = "Pending";
      break;
    case "accepted":
      text = "Processing";
      break;
    case "in_transit":
      text = "In transit";
      break;
    case "delivered":
      text = "Delivered";
      break;
    case "return_requested":
      text = "Return requested";
      break;
    case "rejected":
      text = "Rejected";
      break;
    case "fulfilled":
      text = "Fulfilled";
      break;
    case "cancelled":
      text = "Cancelled";
      break;
  }

  return <As className={textColor}>{text}</As>;
};
