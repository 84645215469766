import Account from "lib/Account";
import { MixPanel, MixAction } from "lib/mixpanel";
import { getMe } from "lib/reducers/lookupData.selectors";
import { useSelector } from "store";
import PlatformLogo from "components/PlatformNavigationBar/PlatformLogo";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import { MarketingButton } from "pages/marketing/components/Common";
import style from "pages/marketing/marketing.module.scss";

export const NavBar = () => {
  const me = useSelector(getMe);
  return (
    <div className={style.navBarContainer}>
      <header className={style.navBar}>
        <div className={style.navBarLogoContainer}>
          <PlatformLogo to="/" image="/images/automify_logo_no_border.png" className={style.navBarLogo} />
        </div>
        <menu className={style.navBarMenu}>
          {Account.session() ? (
            <>
              {me && (
                <MenuItem
                  to="/dashboard"
                  image={me.profileImage || null}
                  icon={!me.profileImage && "/images/icons/account.svg"}
                  alignLeft={true}
                >
                  Your Dashboard
                </MenuItem>
              )}
            </>
          ) : (
            <>
              <a href="/#where-to-buy" className={style.menuLink}>
                <MarketingButton hero onClick={() => MixPanel.track(MixAction.CLICK_TALK_TO_SALES)}>
                  TALK TO SALES
                </MarketingButton>
              </a>
            </>
          )}
        </menu>
      </header>
    </div>
  );
};

export default NavBar;
