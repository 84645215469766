//@ts-nocheck
import Api from "./api";

class Logs extends Api {
  /**
   * Send logs to backend
   * @async
   * @param {Object[]} logs A collection of logs
   * @returns {Promise} A promise.
   */
  static async sendLogs(logs) {
    return await this.post("/logs").send(logs);
  }
}

export default Logs;
