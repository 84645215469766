import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";
import CalibrationTrackingMethods from "lib/constants/CalibrationTrackingMethods";
import { TAsset } from "types";
import { ButtonGroup, PrimaryButton, SecondaryButton } from "components/Buttons";
import { NumberInput } from "components/Input";
import { Paragraph, Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import style from "./modal.module.scss";

const Status = {
  INITIAL: 1,
  ACTUATIONS: 2,
};

type CheckinAssetSummaryProps = {
  asset: TAsset;
  onSubmit: any;
  onCancel: any;
};

const CheckinAssetSummary = ({ asset, onSubmit, onCancel }: CheckinAssetSummaryProps) => {
  const [status, setStatus] = useState(Status.INITIAL);
  const [actuations, setActuations] = useState("");
  const { catalogProduct, calibrationConfig, assignment } = asset;
  const handleSubmit = () => {
    if (
      status === Status.INITIAL &&
      calibrationConfig &&
      calibrationConfig.methodId !== CalibrationTrackingMethods.TIME
    ) {
      setStatus(Status.ACTUATIONS);
    } else {
      onSubmit(actuations);
    }
  };

  if (!catalogProduct || !assignment) return null;

  return (
    <div>
      {status === Status.INITIAL ? (
        <>
          <div className={style.summary}>
            <div>
              {catalogProduct.imageUrl && (
                <>
                  <div
                    style={{
                      backgroundImage: `url(${catalogProduct.imageUrl})`,
                    }}
                    className={style.image}
                  />
                </>
              )}
            </div>
            <div>
              <FieldGroup label="Tool name">
                <Text>{catalogProduct.name}</Text>
              </FieldGroup>
              <FieldGroup label="Product number">
                <Text>{catalogProduct.mpn}</Text>
              </FieldGroup>
              <FieldGroup label="Check-out time">
                <Text>{moment(assignment.dateAssigned).format("MMMM Do [at] h:mm a")}</Text>
              </FieldGroup>
            </div>
          </div>
          <ButtonGroup className={style.buttonGroup}>
            <SecondaryButton className={classNames(style.button, style.secondary)} onClick={onCancel}>
              Cancel
            </SecondaryButton>
            <PrimaryButton className={style.button} onClick={handleSubmit}>
              I have returned this tool
            </PrimaryButton>
          </ButtonGroup>
        </>
      ) : (
        <>
          <FieldGroup label="Calibration actuations tracking">
            <Paragraph>Please enter the number of actuations performed for calibration tracking</Paragraph>
            <NumberInput
              input={{
                name: "actuations",
                placeholder: "Enter how many actuations you performed",
              }}
              showKeyboard={true}
              keyboardOffset={window.screen.height / 2}
              value={actuations}
              onChange={(_: string, value: any) => setActuations(value)}
            />
          </FieldGroup>
          <PrimaryButton className={style.button} onClick={handleSubmit}>
            I have performed these actuations
          </PrimaryButton>
        </>
      )}
    </div>
  );
};

export default CheckinAssetSummary;
