import { Menu } from "@headlessui/react";
import { TrashIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { deleteRole } from "store/reducers";
import { TransparentButton } from "components/shared/buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import { DropdownMenu, DropdownMenuItemAction } from "components/shared/menus";

type RoleTableRowDropdownProps = {
  roleId: number;
  onDelete?: () => void;
};

export const RoleTableRowDropdown = ({ roleId, onDelete }: RoleTableRowDropdownProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const removeRole = () => {
    openModal(
      <ConfirmModal
        actionText="Remove role"
        description="Are you sure you want to delete this role? The role must not be assigned to any team members."
        actionColor="warning"
        onConfirm={async () => {
          try {
            await dispatch(deleteRole(roleId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Role was removed",
              }),
            );
            onDelete && onDelete();
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const actions: DropdownMenuItemAction[][] = [
    [
      {
        label: "Delete",
        icon: TrashIcon,
        onClick: removeRole,
      },
    ],
  ];

  return (
    <DropdownMenu items={actions}>
      <Menu.Button as={TransparentButton} trailingIcon={DotsVerticalIcon} padding="px-2" />
    </DropdownMenu>
  );
};
