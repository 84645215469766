import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "store";
import { fetchToolStoreUserOrders, selectToolStoreUserOrdersList } from "store/reducers";
import Loading from "components/Loading";
import { GridContainer } from "components/shared/containers";
import { ToolStoreUserOrderCard } from "components/shared/features/toolStoreOrders/ToolStoreUserOrderCard/ToolStoreUserOrderCard";

export const ToolStoreUserOrdersGrid = () => {
  const dispatch = useDispatch();
  const entities = useSelector(selectToolStoreUserOrdersList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchToolStoreUserOrders({
        statuses: ["pending", "accepted", "in_transit", "delivered", "return_requested"],
      }),
    )
      .unwrap()
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (entities.length === 0) {
    return (
      <div>
        <h3 className="font-medium text-lg text-gray-700">No active orders</h3>
        <p className="text-gray-500">You currently do not have any orders.</p>
      </div>
    );
  }

  return (
    <GridContainer>
      {entities.map((entity) => (
        <ToolStoreUserOrderCard key={entity.id} id={entity.id} />
      ))}
    </GridContainer>
  );
};
