import React, { FormEvent, useState } from "react";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createAlert } from "store/reducers";
import { CreateAlertRequest, FixLater, TAlert } from "types";
import { TextAreaInput, TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalHeader } from "components/modals/Modal";
import { HSpace, ModalPrimaryActionButton, ModalSecondaryActionButton, VSpace } from "components/shared";

type CreateAlertModalProps = {
  onSuccess: (value: TAlert) => void;
};

export const CreateAlertModal = ({ onSuccess }: CreateAlertModalProps) => {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const [submitting, isSubmitting] = useState(false);
  const [values, setValues] = useState<CreateAlertRequest>({
    name: "",
    description: "",
    enabled: true,
  });
  const invalid = !values.name;

  const handleChange = (name: string, value: FixLater) =>
    setValues((values: FixLater) => ({ ...values, [name]: value }));

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault();
      isSubmitting(true);

      const response = await dispatch(
        createAlert({
          ...values,
        }),
      ).unwrap();

      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Alert created",
        }),
      );

      onSuccess(response);
      closeModal();
    } catch (err) {
      handlePopupFlagError(err);
    } finally {
      isSubmitting(false);
    }
  };

  return (
    <Modal>
      <ModalHeader title="Configure an alert" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter alert name",
                autoFocus: true,
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Description">
            <TextAreaInput
              input={{
                name: "description",
                placeholder: "Enter a description for the alert",
              }}
              value={values.description}
              onChange={handleChange}
            />
          </FieldGroup>
        </VSpace>
        <HSpace>
          <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
          <ModalPrimaryActionButton disabled={invalid || submitting} onClick={handleSubmit}>
            Create alert
          </ModalPrimaryActionButton>
        </HSpace>
      </ModalContent>
    </Modal>
  );
};
