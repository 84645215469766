//@ts-nocheck
import React from "react";
import { AutoDismissFlag, FlagGroup } from "@atlaskit/flag";
import SuccessIcon from "@atlaskit/icon/glyph/check-circle";
import ErrorIcon from "@atlaskit/icon/glyph/error";
import InfoIcon from "@atlaskit/icon/glyph/info";
import WarningIcon from "@atlaskit/icon/glyph/warning";
import { G400, N500, R400, Y200 } from "@atlaskit/theme/colors";
import { dismissPopupFlag } from "lib/actions/userInterface";
import { getPopupFlags } from "lib/reducers/userInterface.selectors";
import { useSelector } from "store";
import store from "store";
import "./flags.module.scss";

const AutoDismissFlags = () => {
  const flags = useSelector(getPopupFlags);

  const handleDismiss = (id) => {
    store.dispatch(dismissPopupFlag(id));
  };

  const renderFlag = (flag) => {
    const appearance = flag.appearance || "info";
    let icon;
    switch (appearance) {
      case "success":
        icon = <SuccessIcon label="Success" secondaryColor={G400} />;
        break;
      case "warning":
        icon = <WarningIcon label="Warning" secondaryColor={Y200} />;
        break;
      case "error":
        icon = <ErrorIcon label="Error" secondaryColor={R400} />;
        break;
      default:
        icon = <InfoIcon label="Info" secondaryColor={N500} />;
    }
    return (
      <AutoDismissFlag
        key={flag.id}
        appearance={appearance}
        icon={icon}
        id={flag.id}
        title={flag.title}
        description={flag.description}
      />
    );
  };

  return (
    <FlagGroup onDismissed={handleDismiss} dismissAllowed={true}>
      {flags.map(renderFlag)}
    </FlagGroup>
  );
};

export default AutoDismissFlags;
