//@ts-nocheck
import Api from "./api";

class Readers extends Api {
  /**
   * Get readers
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, paging: Object}>} Response
   */
  static async getReaders(queryParams = {}) {
    const response = await this.get(`/readers`).query(queryParams);
    return await response.body;
  }

  /**
   * Get a reader
   * @async
   * @param {string} macAddress The reader MAC address
   * @returns {Promise.<Object>} A project
   */
  static async getReader(macAddress) {
    const response = await this.get(`/readers/${macAddress}`);
    return await response.body.data;
  }

  /**
   * Create a reader
   * @async
   * @param {Object} reader
   * @returns {Promise} Response
   */
  static async createReader(reader) {
    const response = await this.post(`/readers`).send(reader);
    return response.body.data;
  }

  /**
   * Update a reader
   * @param {string} macAddress The reader MAC address
   * @param {Object} reader
   * @returns {Promise} Response
   */
  static async updateReader(macAddress, reader) {
    return await this.put(`/readers/${macAddress}`).send(reader);
  }

  /**
   * Delete a reader
   * @param {string} macAddress The reader MAC address
   * @returns {Promise<void>}
   */
  static async deleteReader(macAddress) {
    return await this.delete(`/readers/${macAddress}`).send();
  }

  /**
   * Get antennas for a reader
   * @async
   * @param {string} macAddress The reader MAC address
   * @returns {Promise.<Object>} Response
   */
  static async getReaderAntennas(macAddress) {
    const response = await this.get(`/readers/${macAddress}/antennas`);
    return response.body;
  }

  /**
   * Create an antenna for a reader
   * @async
   * @param {string} macAddress The reader MAC address
   * @param {Object} antenna
   * @returns {Promise.<Object>}
   */
  static async createReaderAntenna(macAddress, antenna) {
    const response = await this.post(`/readers/${macAddress}/antennas`).send(antenna);
    return response.body.data;
  }

  /**
   * Delete a reader antenna
   * @async
   * @param {string} macAddress The reader MAC address
   * @param {number} antennaPort
   * @returns {Promise.<void>}
   */
  static async deleteReaderAntenna(macAddress, antennaPort) {
    return await this.delete(`/readers/${macAddress}/antennas/${antennaPort}`).send();
  }
}

export default Readers;
