import { useEffect, useState } from "react";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { fetchDoorways } from "store/reducers";
import { Pagination, UUID } from "types";
import { Table, TableHeader, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import TablePagination from "components/Table/TablePagination";
import TableRowNotice from "components/Table/TableRowNotice";
import { ListDoorwaysTableRow } from "components/shared/features/doorways/components/ListDoorwaysTableRow";
import { TableRowLoading } from "components/shared/tables";

type DoorwaysTableProps = {
  doorwayId?: UUID;
};

export const ListDoorwaysTable = ({ doorwayId }: DoorwaysTableProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [ids, setIds] = useState<UUID[]>([]);
  const [paging, setPaging] = useState<Pagination>({
    pageStart: 0,
    pageLimit: 20,
    pageCount: 0,
    totalCount: 0,
    nextPageStart: null,
    previousPageStart: null,
  });

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart, paging.pageLimit, doorwayId]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchDoorways({ pageStart, pageLimit })).unwrap();
      setIds(response.results.map(({ id }) => id));
      setPaging(response.paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.previousPageStart !== null ? prevState.previousPageStart : 0,
    }));
  };

  const nextPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.nextPageStart !== null ? prevState.nextPageStart : 0,
    }));
  };

  const handleLimitChange = (value: number) => {
    setPaging((prevState) => ({
      ...prevState,
      pageLimit: value,
    }));
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeadCell>Status</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Location</TableHeadCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!loading && ids.map((id) => <ListDoorwaysTableRow key={id} doorwayId={id} />)}
          {!loading && ids.length === 0 ? <TableRowNotice colSpan={3} notice="You have no doorways yet" /> : null}
        </TableBody>
      </Table>
      {loading && <TableRowLoading />}
      <TablePagination paging={paging} onPrevPage={prevPage} onNextPage={nextPage} onChangeLimit={handleLimitChange} />
    </>
  );
};
