//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { InputGroup, InputGroups, TextAreaInput, TextInput } from "components/Input";
import { LocationSelector } from "components/inputs/LocationSelector/LocationSelector";

const ReaderTab = ({ reader, onChange }) => (
  <div>
    <InputGroups>
      <InputGroup label="Name">
        <TextInput input={{ name: "name", placeholder: "Enter reader name" }} value={reader.name} onChange={onChange} />
      </InputGroup>
      <InputGroup label="MAC address">
        <TextInput
          input={{
            name: "macAddress",
            placeholder: "Enter unique MAC address",
          }}
          value={reader.macAddress}
          onChange={onChange}
        />
      </InputGroup>
      <InputGroup label="Summary">
        <TextInput
          input={{
            name: "summary",
            placeholder: "Enter reader summary",
          }}
          value={reader.summary}
          onChange={onChange}
        />
      </InputGroup>
      <InputGroup label="Description">
        <TextAreaInput
          input={{
            name: "description",
            placeholder: "Enter reader description",
          }}
          value={reader.description}
          onChange={onChange}
        />
      </InputGroup>
      <InputGroup label="Location">
        <LocationSelector name="location" value={reader.location} onChange={onChange} />
      </InputGroup>
    </InputGroups>
  </div>
);

ReaderTab.defaultProps = {};

ReaderTab.propTypes = {
  reader: PropTypes.object,
  onChange: PropTypes.func,
};

export default ReaderTab;
