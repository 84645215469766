import { ReactNode } from "react";
import styled from "styled-components";

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 0;
  }
`;

type OutstandingCardsProps = {
  children?: ReactNode | ReactNode[];
};

export const OutstandingCards = ({ children }: OutstandingCardsProps) => {
  return <Cards>{children}</Cards>;
};
