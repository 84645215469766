//@ts-nocheck
import Lookup from "lib/constants/Lookup";
import store from "store";

class Capabilities extends Lookup {
  CONFIGURE_ROLES = "configure_roles";
  CONFIGURE_BRANDING = "configure_branding";
  CONFIGURE_TEAM = "configure_team";
  CONFIGURE_LOCATIONS = "configure_locations";
  CONFIGURE_KEYCARDS = "configure_keycards";
  CONFIGURE_ASSET_ISSUE_PROBLEMS = "configure_asset_issue_problems";

  ASSET_READ = "asset_read";
  ASSET_CREATE = "asset_create";
  ASSET_UPDATE = "asset_update";
  ASSET_REMOVE = "asset_remove";

  STORAGE_READ = "storage_read";
  STORAGE_CREATE = "storage_create";
  STORAGE_UPDATE = "storage_update";
  STORAGE_REMOVE = "storage_remove";

  REPORT_READ = "report_read";
  REPORT_CREATE = "report_create";
  REPORT_UPDATE = "report_update";
  REPORT_REMOVE = "report_remove";

  PROJECT_READ = "project_read";
  PROJECT_CREATE = "project_create";
  PROJECT_UPDATE = "project_update";
  PROJECT_REMOVE = "project_remove";

  PROJECT_TEMPLATE_READ = "project_template_read";
  PROJECT_TEMPLATE_CREATE = "project_template_create";
  PROJECT_TEMPLATE_UPDATE = "project_template_update";
  PROJECT_TEMPLATE_REMOVE = "project_template_remove";

  TOOL_STORE_READ = "tool_store_read";
  TOOL_STORE_CREATE = "tool_store_create";
  TOOL_STORE_UPDATE = "tool_store_update";
  TOOL_STORE_REMOVE = "tool_store_remove";

  TOOL_STORE_ORDER_READ = "tool_store_order_read";
  TOOL_STORE_ORDER_CREATE = "tool_store_order_create";
  TOOL_STORE_ORDER_UPDATE = "tool_store_order_update";
  TOOL_STORE_ORDER_REMOVE = "tool_store_order_remove";

  TOOL_ROOM_READ = "tool_room_read";
  TOOL_ROOM_CREATE = "tool_room_create";
  TOOL_ROOM_UPDATE = "tool_room_update";
  TOOL_ROOM_REMOVE = "tool_room_remove";

  CASE_SCANNER_READ = "case_scanner_read";
  CASE_SCANNER_CREATE = "case_scanner_create";
  CASE_SCANNER_UPDATE = "case_scanner_update";
  CASE_SCANNER_REMOVE = "case_scanner_remove";

  RAG_CONTROL_READ = "rag_control_read";
  RAG_CONTROL_CREATE = "rag_control_create";
  RAG_CONTROL_UPDATE = "rag_control_update";
  RAG_CONTROL_REMOVE = "rag_control_remove";

  VEHICLE_READ = "vehicle_read";
  VEHICLE_CREATE = "vehicle_create";
  VEHICLE_UPDATE = "vehicle_update";
  VEHICLE_REMOVE = "vehicle_remove";

  ASSET_ISSUE_READ = "asset_issue_read";
  ASSET_ISSUE_CREATE = "asset_issue_create";
  ASSET_ISSUE_UPDATE = "asset_issue_update";
  ASSET_ISSUE_REMOVE = "asset_issue_remove";

  DOORWAY_READ = "doorway_read";
  DOORWAY_CREATE = "doorway_create";
  DOORWAY_UPDATE = "doorway_update";
  DOORWAY_REMOVE = "doorway_remove";

  constructor() {
    super();
    super._items = () => {
      const { lookupData } = store.getState();
      return lookupData.capabilities.slice(0).map(({ id, label, description }) => ({
        value: id,
        label,
        description,
      }));
    };
  }
}

export default new Capabilities();
