import { useSlideOver } from "effects/useSlideOver";
import styled from "styled-components";
import { padNumber } from "lib/utility/format";
import { useSelector } from "store";
import { selectToolStoreOrderByID, selectToolStoreOrderItemsByOrderID } from "store/reducers";
import { UUID } from "types";
import Card from "components/Card";
import { ProgressInput } from "components/Input";
import { ToolStoreOrderLocation } from "components/shared/features/toolStoreOrders/ToolStoreOrderLocation/ToolStoreOrderLocation";
import { ToolStoreOrderRequester } from "components/shared/features/toolStoreOrders/ToolStoreOrderRequester/ToolStoreOrderRequester";
import { ToolStoreOrderSlideOver } from "components/shared/features/toolStoreOrders/ToolStoreOrderSlideOver/ToolStoreOrderSlideOver";
import { ToolStoreOrderStatusText } from "components/shared/features/toolStoreOrders/ToolStoreOrderStatusText";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
`;

type ToolStoreOrderCardProps = {
  id: UUID;
  disabled?: boolean;
};

export const ToolStoreOrderCard = ({ id, disabled = false }: ToolStoreOrderCardProps) => {
  const { openSlideOver } = useSlideOver();
  const entity = useSelector(selectToolStoreOrderByID(id));
  const items = useSelector(selectToolStoreOrderItemsByOrderID(id));

  const openToolStoreSlideOver = () => {
    if (entity && !disabled) {
      openSlideOver(<ToolStoreOrderSlideOver toolStoreOrderId={entity.id} />);
    }
  };

  if (!entity) {
    return null;
  }

  const itemsReady = items.filter((item) => item.dateReadyForDelivery);
  return (
    <StyledCard className="cursor-pointer" onClick={openToolStoreSlideOver}>
      <div>
        <h3 className="text-sm text-gray-500">Order #{padNumber(entity.orderNumber)}</h3>
        <p className="mt-1 text-lg font-medium">
          <ToolStoreOrderStatusText status={entity.status} />
        </p>
      </div>
      <ToolStoreOrderLocation toolStoreOrderId={entity.id} />
      <ToolStoreOrderRequester toolStoreOrderId={entity.id} />
      {entity.status === "accepted" && (
        <div>
          <dt className="font-medium text-gray-500">
            Items ready for delivery ({itemsReady.length}/{items.length})
          </dt>
          <dd className="flex mt-1 text-gray-900 sm:col-span-2">
            <ProgressInput value={(itemsReady.length / items.length) * 100} />
          </dd>
        </div>
      )}
    </StyledCard>
  );
};
