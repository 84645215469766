//@ts-nocheck
import React from "react";
import Logger from "lib/Logger";
import Api from "lib/api/account";
import { ButtonGroup, PrimaryButton, SecondaryButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import Icon from "components/Icon";
import { InputGroups, PasswordInput } from "components/Input";
import { Heading, TextBody, VSpace } from "components/shared";

const Status = {
  INITIAL: 1,
  EDITING: 2,
  SUCCESS: 3,
};

export class ChangePassword extends React.Component {
  static propTypes = {};

  state = {
    status: Status.INITIAL,
    isSubmitting: false,
    error: null,
    password: "",
    confirmPassword: "",
  };

  setStatus = (status) => this.setState({ status });

  onChange = (name, value) => this.setState({ [name]: value });

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true, error: null });
    try {
      const { password, confirmPassword } = this.state;
      if (password !== confirmPassword) {
        return this.setState({
          isSubmitting: false,
          error: new Error("Passwords do not match"),
        });
      }

      await Api.changePassword(password);
      Logger.info("password changed successful");
      this.setState({ status: Status.SUCCESS });
    } catch (err) {
      if (err.status && err.status === 400) {
        this.setState({
          isSubmitting: false,
          error: err.response.body.error,
        });
      } else {
        this.setState({
          isSubmitting: false,
          error: new Error("An unexpected error occurred"),
        });
      }
    }
    this.setState({ isSubmitting: false });
  };

  render() {
    const { status, isSubmitting, password, confirmPassword, error } = this.state;
    return (
      <VSpace>
        <div>
          <Heading level="h2">Password change</Heading>
          <TextBody>Change your account password</TextBody>
        </div>
        <form onSubmit={this.handleSubmit}>
          {status === Status.EDITING ? (
            <InputGroups>
              <DataGrid>
                <DataItem label="New password">
                  <PasswordInput
                    input={{
                      name: "password",
                      placeholder: "New password",
                    }}
                    value={password}
                    onChange={this.onChange}
                  />
                </DataItem>
                <DataItem label="Confirm password">
                  <PasswordInput
                    input={{
                      name: "confirmPassword",
                      placeholder: "Confirm new password",
                    }}
                    value={confirmPassword}
                    onChange={this.onChange}
                  />
                </DataItem>
              </DataGrid>
            </InputGroups>
          ) : null}
          {error ? <p className="text-danger">{error.message}</p> : null}
          <ButtonGroup>
            {status === Status.INITIAL ? (
              <PrimaryButton onClick={() => this.setStatus(Status.EDITING)}>Change password</PrimaryButton>
            ) : status === Status.EDITING ? (
              <React.Fragment>
                <SecondaryButton onClick={() => this.setStatus(Status.INITIAL)}>Cancel</SecondaryButton>
                <PrimaryButton type="submit" disabled={isSubmitting}>
                  Save password
                </PrimaryButton>
              </React.Fragment>
            ) : null}
          </ButtonGroup>
          {status === Status.SUCCESS ? (
            <p className="text-success">
              <Icon src="/images/icons/tick.svg" color="success" /> <span>Your password has been changed</span>
            </p>
          ) : null}
        </form>
      </VSpace>
    );
  }
}
