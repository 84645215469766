import React from "react";
import styled from "styled-components";
import { CalibrationIntervals, CalibrationIntervalsOption } from "lib/constants/Calibration";
import RadioInput from "components/Input/RadioInput";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { TextBody } from "components/shared/typography";

const MethodRadioInput = styled(RadioInput)`
  margin-bottom: 1.25rem;
`;

type TimeIntervalsProps = {
  value?: CalibrationIntervalsOption | CalibrationIntervalsOption["value"];
  onChange: (value?: CalibrationIntervalsOption) => void;
};

export const TimeInterval = ({ value, onChange }: TimeIntervalsProps) => {
  return (
    <FieldGroup label="Time between calibrations">
      <TextBody textColor="text-gray-400">Select the interval frequency between calibrations</TextBody>
      <MethodRadioInput options={CalibrationIntervals.items} value={value} onChange={onChange} />
    </FieldGroup>
  );
};
