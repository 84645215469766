//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Resolve from "lib/Resolve";
import { Text } from "components/Typography";
import { SelectInput } from "../../Input";

class SelectCalibrationTrackingMethod extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    appearance: PropTypes.oneOf(["normal", "inline"]),
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    appearance: "normal",
    placeholder: "Select tracking method",
  };

  render() {
    const { name, value, onChange, placeholder, appearance, className } = this.props;
    const input = {
      type: "select",
      name,
      placeholder: placeholder,
      options: Resolve.getCalibrationTrackingMethods(),
    };

    return appearance === "inline" ? (
      <Text
        value={value}
        canEdit={true}
        input={input}
        onConfirm={(value) => onChange(name, value)}
        className={className}
      />
    ) : (
      <SelectInput input={input} value={value} onChange={onChange} className={className} />
    );
  }
}

export default SelectCalibrationTrackingMethod;
