import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateAlertRequest,
  DataResponse,
  FetchAlertsRequest,
  PagedDataResponse,
  Pagination,
  TAlert,
  UUID,
  UpdateAlertRequest,
} from "types";

export const fetchAlerts = createAsyncThunk(
  "alerts/fetchAlerts",
  async (params: FetchAlertsRequest): Promise<{ results: TAlert[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TAlert[]>>(`/alerts`, params);
    return { results: data, paging: metadata };
  },
);

export const fetchAlertById = createAsyncThunk("alerts/fetchAlertById", async (id: UUID): Promise<TAlert> => {
  const { data } = await API.GET<DataResponse<TAlert>>(`/alerts/${id}`);
  return data;
});

export const createAlert = createAsyncThunk(
  "alerts/createAlert",
  async (request: CreateAlertRequest): Promise<TAlert> => {
    const { data } = await API.POST<CreateAlertRequest, DataResponse<TAlert>>(`/alerts`, request);
    return data;
  },
);

export const updateAlert = createAsyncThunk(
  "alerts/updateAlert",
  async (request: UpdateAlertRequest): Promise<TAlert> => {
    const { data } = await API.PUT<UpdateAlertRequest, DataResponse<TAlert>>(`/alerts/${request.id}`, request);
    return data;
  },
);

export const deleteAlert = createAsyncThunk("alerts/deleteAlert", async (id: UUID): Promise<void> => {
  await API.DELETE(`/alerts/${id}`);
});
