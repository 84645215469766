import { useEffect, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { fetchLocations } from "store/reducers";
import { Pagination } from "types";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { CreateLocationModal, LocationsTable } from "components/shared";
import PageStage from "components/stages/PageStage";

export const LocationsPage = () => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [ids, setIds] = useState<string[]>([]);
  const [paging, setPaging] = useState<Pagination>({
    pageStart: 0,
    pageLimit: 20,
    pageCount: 0,
    totalCount: 0,
    nextPageStart: null,
    previousPageStart: null,
  });

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart, paging.pageLimit]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchLocations({ pageStart, pageLimit })).unwrap();
      setIds(response.results.map(({ id }) => id));
      setPaging(response.paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const createLocation = () => {
    openModal(
      <CreateLocationModal
        onSuccess={() => {
          fetch(0, paging.pageLimit);
        }}
      />,
    );
  };

  const prevPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.previousPageStart !== null ? prevState.previousPageStart : 0,
    }));
  };

  const nextPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.nextPageStart !== null ? prevState.nextPageStart : 0,
    }));
  };

  const handleLimitChange = (value: number) => {
    setPaging((prevState) => ({
      ...prevState,
      pageLimit: value,
    }));
  };

  return (
    <PageStage>
      <StageHeader
        title="Locations"
        subtitle="Locations are physical facilities that an employee, toolbox, or tool may be located."
        action={
          <SecondaryButton icon="/images/icons/plus_1.svg" onClick={createLocation}>
            Create location
          </SecondaryButton>
        }
      />
      <LocationsTable
        loading={loading}
        locationIds={ids}
        paging={paging}
        onPrevPage={prevPage}
        onNextPage={nextPage}
        onLimitChange={handleLimitChange}
      />
    </PageStage>
  );
};
