//@ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "lib/actions/modal";
import Modals from "lib/constants/Modals";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { ManufacturersTable } from "components/shared";
import PageStage from "components/stages/PageStage";

export const ListManufacturersPage = () => {
  const dispatch = useDispatch();
  const breadcrumb = [{ label: "Configuration", href: "/configuration" }, "Manufacturers"];

  const onCreate = () => {
    dispatch(openModal(Modals.CREATE_MANUFACTURER));
  };

  return (
    <PageStage>
      <StageHeader
        title="Manufacturers"
        subtitle="Manage product manufacturers across the Automify software."
        breadcrumb={breadcrumb}
        action={
          <SecondaryButton icon="/images/icons/plus_1.svg" onClick={onCreate}>
            Create manufacturer
          </SecondaryButton>
        }
      />
      <ManufacturersTable />
    </PageStage>
  );
};
