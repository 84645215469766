//@ts-nocheck
import { PagedDataResponse, TCatalogueProduct } from "types";
import Api from "./api";

class Catalogue extends Api {
  /**
   * Get manufacturers
   * @async
   * @param {Object} queryParams
   * @returns {Promise.<{data: Array}>} Manufacturers
   */
  static async getManufacturers(queryParams) {
    const response = await this.get(`/catalogue/manufacturers`).query(queryParams);
    return response.body;
  }

  /**
   * Get a manufacturer
   * @async
   * @param {string} id
   * @returns {Promise.<{data: Object}>}
   */
  static async getManufacturer(id) {
    const response = await this.get(`/catalogue/manufacturers/${id}`);
    return response.body.data;
  }

  /**
   * Create a manufacturer
   * @async
   * @param {Object} manufacturer
   * @returns {Promise.<{data:{id: number, location: string}}>}
   */
  static async createManufacturer(manufacturer) {
    const response = await this.post(`/catalogue/manufacturers`).send(manufacturer);
    return response.body.data;
  }

  /**
   * Update a manufacturer
   * @async
   * @param {number} id
   * @param {Object} manufacturer
   * @returns {Promise.<void>}
   */
  static async updateManufacturer(id, manufacturer) {
    return await this.put(`/catalogue/manufacturers/${id}`).send(manufacturer);
  }

  /**
   * Delete a manufacturer
   * @async
   * @param {number} id
   * @returns {Promise.<void>}
   */
  static async deleteManufacturer(id) {
    return await this.delete(`/catalogue/manufacturers/${id}`).send();
  }

  /**
   * Get products
   * @async
   * @param {Object} queryParams
   * @returns {Promise.<{data: Array}>} Product catalogue items
   */
  static async getProducts(queryParams): Promise<TCatalogueProduct[]> {
    const response = await this.get("/catalogue/products").query(queryParams);
    return response.body.data;
  }

  /**
   * Get manufacturer products
   * @async
   * @param {number} manufacturerId
   * @param {Object} queryParams
   * @returns {Promise.<{data: Array}>} Product catalogue items
   */
  static async getManufacturerProducts(manufacturerId, queryParams): Promise<PagedDataResponse<TCatalogueProduct[]>> {
    const response = await this.get(`/catalogue/manufacturers/${manufacturerId}/products`).query(queryParams);
    return response.body;
  }

  /**
   * Get a product
   * @async
   * @param {number} id
   * @returns {Promise.<{data: Object}>}
   */
  static async getProduct(id) {
    const response = await this.get(`/catalogue/products/${id}`);
    return response.body.data;
  }

  /**
   * Create a product
   * @async
   * @param {Object} product
   * @returns {Promise.<{data:{id: number, location: string}}>}
   */
  static async createProduct(product) {
    const { manufacturerId } = product;
    const response = await this.post(`/catalogue/manufacturers/${manufacturerId}/products`).send(product);
    return response.body.data;
  }

  /**
   * Update a product
   * @async
   * @param {number} id
   * @param {Object} product
   * @returns {Promise.<void>}
   */
  static async updateProduct(id, product) {
    const { manufacturerId } = product;
    return await this.put(`/catalogue/manufacturers/${manufacturerId}/products/${id}`).send(product);
  }

  /**
   * Delete a product
   * @async
   * @param {number} id
   * @returns {Promise.<void>}
   */
  static async deleteProduct(id) {
    return await this.delete(`/catalogue/products/${id}`).send();
  }

  /**
   * Get getCategories
   * @async
   * @param {Object} queryParams
   * @returns {Promise.<{data: Array}>}
   */
  static async getCategories(queryParams) {
    const response = await this.get("/catalogue/categories").query(queryParams);
    return response.body;
  }

  /**
   * Get categories
   * @async
   * @param {int} categoryId
   * @returns {Promise.<{data: Object}>}
   */
  static async getCategory(categoryId) {
    const response = await this.get(`/catalogue/categories/${categoryId}`);
    return response.body.data;
  }

  /**
   * Create a category
   * @async
   * @param {Object} category
   * @returns {Promise.<{data:{id: number, location: string}}>}
   */
  static async createCategory(category) {
    const response = await this.post(`/catalogue/categories`).send(category);
    return response.body.data;
  }

  /**
   * Update a category
   * @async
   * @param {int} categoryId
   * @param {Object} category
   * @returns {Promise.<void>}
   */
  static async updateCategory(categoryId, category) {
    return await this.put(`/catalogue/categories/${categoryId}`).send(category);
  }

  /**
   * Delete a category
   * @async
   * @param {int} categoryId
   * @returns {Promise.<void>}
   */
  static async removeCategory(categoryId) {
    return await this.delete(`/catalogue/categories/${categoryId}`).send();
  }
}

export default Catalogue;
