import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToolStorage } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { openModal } from "lib/actions/modal";
import { showPopupFlag } from "lib/actions/userInterface";
import Api from "lib/api/storages";
import Modals from "lib/constants/Modals";
import RemoteControlCommands from "lib/constants/RemoteControlCommands";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { MixAction, MixPanel } from "lib/mixpanel";
import { useDispatch, useSelector } from "store";
import {
  deleteRagBin,
  selectStorageByID,
  selectRagBinByID,
  unlinkStorage,
  updateStorage,
  updateRagBin,
} from "store/reducers";
import { FixLater, TLocation, TStorage, TRagBin, UUID, RagBinType } from "types";
import Avatar from "components/Avatar";
import { SecondaryButton, DangerButton, WarningButton } from "components/Buttons";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import { TextAreaInput, TextInput } from "components/Input";
import StageHeader from "components/StageHeader";
import { Paragraph, Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs/LocationSelector/LocationSelector";
import { RagBinTypeSelector, VSpace } from "components/shared";

type StorageSettingsPageProps = {
  ragBinId: UUID;
};

export const RagBinSettingsPage = ({ ragBinId }: StorageSettingsPageProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storageId } = useToolStorage();
  const storage = useSelector(selectStorageByID(storageId || ""));
  const entity = useSelector(selectRagBinByID(ragBinId));
  const [values, setValues] = useState<Partial<TStorage>>();

  useEffect(() => {
    if (!storage) return;
    setValues(storage);
  }, [storage]);

  const handleChange = (name: string, value: FixLater) => {
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleBlur = (name: keyof TStorage) => {
    return (value: FixLater) => {
      if (!storage) return;
      saveStorageChanges({
        ...storage,
        [name]: value,
      });
    };
  };

  const saveStorageChanges = async (entity: TStorage) => {
    try {
      await dispatch(updateStorage(entity)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const saveRagBinChanges = async (entity: TRagBin) => {
    try {
      await dispatch(updateRagBin(entity)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const changeLocation = (name: string, value?: TLocation) => {
    if (!storage) return;
    saveStorageChanges({
      ...storage,
      locationId: value?.id,
    });
  };

  const handleUnlinkStorage = () => {
    if (!storageId) return;
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description:
          "Are you sure you want to unlink this rag bin? Doing this will return the rag bin to factory settings.",
        actionText: "Unlink rag bin",
        actionColor: "warning",
        onConfirm: async () => {
          try {
            await dispatch(unlinkStorage(storageId)).unwrap();
            MixPanel.track(MixAction.RemoteControlUnlinkDevice, {
              storageId: storageId,
            });
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Rag bin unlinked",
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
        },
      }),
    );
  };

  const lockStorage = (shouldLock: boolean) => {
    return sendRemoteControlCommand(
      shouldLock ? RemoteControlCommands.MOTOR_LOCK : RemoteControlCommands.MOTOR_UNLOCK,
      `Are you sure you want to ${shouldLock ? "lock" : "unlock"} the rag bin?`,
      shouldLock ? "Lock door" : "Unlock door",
    );
  };

  const powerOffStorage = (shouldReboot: boolean) => {
    return sendRemoteControlCommand(
      shouldReboot ? RemoteControlCommands.REBOOT : RemoteControlCommands.POWER_OFF,
      `Are you sure you want to ${shouldReboot ? "reboot" : "power off"} this rag bin?`,
      shouldReboot ? "Reboot" : "Power off",
    );
  };

  const refreshStorageScreen = () => {
    return sendRemoteControlCommand(
      RemoteControlCommands.REFRESH_SCREEN,
      `Are you sure you want to refresh the rag bin screen?`,
      "Refresh screen",
    );
  };

  const sendRemoteControlCommand = (command: string, description: string, actionText = "Confirm") => {
    return () => {
      dispatch(
        openModal(Modals.CONFIRMATION, {
          description: description,
          actionText: actionText,
          actionColor: "primary",
          onConfirm: async () => {
            try {
              await Api.sendStorageRemoteCommand(storageId, {
                command: command,
              });
              dispatch(
                showPopupFlag({
                  appearance: "success",
                  title: "Command sent",
                }),
              );
            } catch (err) {
              handlePopupFlagError(err);
            }
          },
        }),
      );
    };
  };

  const removeRagBin = () => {
    if (!entity) return;
    const ragControllerId = entity.ragControllerId;
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description: "Are you sure you want to delete this rag bin? This action is permanent.",
        actionText: "Delete rag bin",
        onConfirm: async () => {
          try {
            await dispatch(deleteRagBin(ragBinId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Rag bin deleted",
              }),
            );
            navigate(`/apps/storage/rag_controllers/${ragControllerId}/bins`, {
              replace: true,
            });
          } catch (err) {
            handlePopupFlagError(err);
          }
        },
      }),
    );
  };

  const handleRagBinChange = async (key: keyof TRagBin, value?: string | RagBinType) => {
    if (!entity) return;
    await saveRagBinChanges({
      ...entity,
      [key]: value,
    });
  };

  if (!entity || !values || !storage || !storageId) {
    return null;
  }

  return (
    <>
      <StageHeader title="Settings" />
      <DataGrid>
        <DataItem
          label="Image"
          value={<Avatar canEdit image={entity.imageUrl} onChange={(value) => handleRagBinChange("imageUrl", value)} />}
        />
        <DataItem label="Storage code" value={<Text>{storage.id}</Text>} />
        <DataItem
          label="Name"
          value={
            <TextInput
              value={values.name}
              input={{ name: "name", placeholder: "Enter name" }}
              onChange={handleChange}
              onBlur={handleBlur("name")}
            />
          }
        />
        <DataItem
          label="Description"
          value={
            <TextAreaInput
              value={values.description}
              input={{
                name: "description",
                placeholder: "Enter description",
              }}
              onChange={handleChange}
              onBlur={handleBlur("description")}
            />
          }
        />
        <DataItem
          label="Bin type"
          value={
            <RagBinTypeSelector
              appearance="normal"
              name="binType"
              value={entity.binType}
              onChange={(value?: RagBinType) => handleRagBinChange("binType", value)}
            />
          }
        />
        <DataItem
          label="Location"
          value={
            <LocationSelector
              appearance="normal"
              name="locationId"
              value={storage.locationId}
              onChange={changeLocation}
            />
          }
        />
        {storage.sentryApiKey?.dateActivated && (
          <DataItem
            label="Remote control"
            value={
              storage.online ? (
                <DataItemGroup>
                  <VSpace gap="gap-2">
                    <FieldGroup label="Drawers">
                      <SecondaryButton fitContent icon="/images/icons/lock_5.svg" onClick={lockStorage(true)}>
                        Lock door
                      </SecondaryButton>
                      <SecondaryButton fitContent icon="/images/icons/unlock_2.svg" onClick={lockStorage(false)}>
                        Unlock door
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Power options">
                      <SecondaryButton fitContent icon="/images/icons/restart_1.svg" onClick={powerOffStorage(true)}>
                        Reboot
                      </SecondaryButton>
                      <SecondaryButton fitContent icon="/images/icons/power_1.svg" onClick={powerOffStorage(false)}>
                        Power off
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Miscellaneous">
                      <SecondaryButton fitContent icon="/images/icons/loading.svg" onClick={refreshStorageScreen()}>
                        Refresh screen
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Unlink rag bin">
                      <WarningButton fitContent icon="/images/icons/unlink_1.svg" onClick={handleUnlinkStorage}>
                        Unlink rag bin
                      </WarningButton>
                    </FieldGroup>
                  </VSpace>
                </DataItemGroup>
              ) : (
                <DataItemGroup>
                  <FieldGroup label="Commands">
                    <Paragraph muted>The rag bin must be online to access remote control commands.</Paragraph>
                  </FieldGroup>
                  <FieldGroup label="Unlink rag bin">
                    <WarningButton fitContent icon="/images/icons/unlink_1.svg" onClick={handleUnlinkStorage}>
                      Unlink rag bin
                    </WarningButton>
                  </FieldGroup>
                </DataItemGroup>
              )
            }
          />
        )}
        <DataItem
          label="Delete rag bin"
          value={
            <DangerButton icon="/images/icons/trash.svg" onClick={removeRagBin}>
              Delete rag bin
            </DangerButton>
          }
        />
      </DataGrid>
    </>
  );
};
