//@ts-nocheck
import { createSelector } from "reselect";

export const getAccessAttempts = createSelector(
  (state) => state.storages,
  (storages = {}) => {
    return storages.accessAttempts;
  },
);

export const selectAccessPolicy = createSelector(
  (state) => state.storages,
  (storages = {}) => {
    return storages.accessPolicy?.data;
  },
);

export const getAccessAttemptsPaging = createSelector(
  (state) => state.storages,
  (storages = {}) => {
    return storages.accessAttempts.paging;
  },
);
