//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import get from "lodash/get";
import { requestProductsSuccess } from "lib/actions/catalogue";
import { openModal } from "lib/actions/modal";
import Api from "lib/api/catalogue";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { selectProducts, selectProductsPaging } from "lib/reducers/products.selectors";
import { ButtonGroup, SecondaryButton } from "components/Buttons";
import { SmartTable, SmartTableNav, SmartTablePagination } from "components/Table/SmartTable";
import { ImportCatalogProductButton } from "components/shared/features/dataimport";

export const ManufacturerProductsTable = ({ manufacturerId }) => {
  const dispatch = useDispatch();
  const products = useSelector(selectProducts);
  const paging = useSelector(selectProductsPaging);
  const [fetching, setFetching] = useState(false);
  const [query, setQuery] = useState("");
  const [productIds, setProductIds] = useState([]);
  const rowData = useMemo(() => productIds.map((id) => products[id]).filter(Boolean), [products, productIds]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Product number",
        accessor: ({ row }) => get(row, "original.mpn", ""),
        Cell: ({ row }) => {
          const { mpn } = row.original;
          return <div>{mpn}</div>;
        },
      },
      {
        Header: "Name",
        accessor: ({ row }) => get(row, "original.name", ""),
        Cell: ({ row }) => {
          const { id, name } = row.original;
          return <Link to={`/configuration/manufacturers/${manufacturerId}/products/${id}`}>{name}</Link>;
        },
      },
      {
        Header: "Description",
        Key: "Description",
        Cell: ({ row }) => {
          const { description } = row.original;
          return <div>{description.slice(0, 25) + (description.length <= 25 ? "" : "...")}</div>;
        },
      },
    ];
  }, []);

  useEffect(() => {
    fetch(0, paging.pageLimit);
  }, [paging.pageLimit, query]);

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart]);

  const fetch = async (pageStart, pageLimit) => {
    setFetching(true);
    try {
      const { data, metadata } = await Api.getManufacturerProducts(manufacturerId, {
        pageStart,
        pageLimit,
        query: query || undefined,
      });
      dispatch(requestProductsSuccess(data, metadata));
      setProductIds(data.map(({ id }) => id));
    } catch (err) {
      handlePopupFlagError(err);
    }
    setFetching(false);
  };

  const createProduct = () => {
    dispatch(
      openModal(Modals.CREATE_MANUFACTURER_PRODUCT, {
        manufacturerId,
      }),
    );
  };

  return (
    <>
      <SmartTableNav
        paging={paging}
        onPageChange={fetch}
        showQueryInput={true}
        queryPlaceholder="Search by product name or number"
        onQueryChange={setQuery}
      >
        <ButtonGroup>
          <ImportCatalogProductButton manufacturerId={manufacturerId} onSuccess={() => fetch(0, paging.pageLimit)} />
          <SecondaryButton icon="/images/icons/plus_1.svg" onClick={createProduct}>
            Create
          </SecondaryButton>
        </ButtonGroup>
      </SmartTableNav>
      <SmartTable columns={columns} data={rowData} />
      <SmartTablePagination paging={paging} onChange={fetch} />
    </>
  );
};
