import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useSlideOver } from "effects/useSlideOver";

type SlideOverBannerHeaderProps = {
  title: string;
  description?: string;
};

export const SlideOverBannerHeader = ({ title, description }: SlideOverBannerHeaderProps) => {
  const { closeSlideOver } = useSlideOver();
  return (
    <div className="bg-primary-700 py-6 px-4 sm:px-6 top-0 sticky z-10">
      <div className="flex items-center justify-between">
        <Dialog.Title className="text-lg font-medium text-white">{title}</Dialog.Title>
        <div className="ml-3 flex h-7 items-center">
          <button
            type="button"
            className="rounded-md bg-primary-700 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
            onClick={closeSlideOver}
          >
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="mt-1">
        <p className="text-sm text-indigo-300">{description}</p>
      </div>
    </div>
  );
};
