//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Logger from "../../../lib/Logger";
import Api from "../../../lib/api/projects";
import { SelectInput } from "../../Input";

class ProjectsInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  state = {
    items: [],
  };

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    try {
      const { data } = await Api.getProjects({
        pageStart: 0,
        pageLimit: 9999,
      });
      this.setState({
        items: data,
      });
    } catch (err) {
      Logger.error(err);
    }
  }

  render() {
    const { name, value, onChange, className } = this.props;
    const { items } = this.state;
    return (
      <SelectInput
        input={{
          name,
          placeholder: "Select project",
          labelKey: "name",
          valueKey: "id",
          options: items,
        }}
        value={value}
        onChange={onChange}
        className={className}
      />
    );
  }
}

export default ProjectsInput;
