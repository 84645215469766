import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  fetchToolStoreById,
  fetchToolStoreOrders,
  FetchToolStoreOrdersParams,
  selectToolStoreByID,
  selectToolStoreOrdersList,
} from "store/reducers";
import { Pagination } from "types";
import {
  GridContainer,
  Heading,
  PageLoading,
  ToolStoreOrderCard,
  ToolStorePageHeader,
  VSpace,
} from "components/shared";
import PageStage from "components/stages/PageStage";

export const ToolStoreOrdersPage = () => {
  const dispatch = useDispatch();
  const { toolStoreID } = useParams();
  const entity = useSelector(selectToolStoreByID(toolStoreID || ""));
  const ordersList = useSelector(selectToolStoreOrdersList);
  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState<Pagination>({
    pageStart: 0,
    pageLimit: 25,
    pageCount: 0,
    totalCount: 0,
    nextPageStart: null,
    previousPageStart: null,
  });

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      if (!entity) {
        return;
      }
      setLoading(true);
      const params: FetchToolStoreOrdersParams = {
        paging: {
          pageStart,
          pageLimit,
        },
        toolStoreId: entity.id,
      };
      const { paging } = await dispatch(fetchToolStoreOrders(params)).unwrap();
      setPaging(paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!entity && toolStoreID) {
      dispatch(fetchToolStoreById(toolStoreID));
    }
  }, [entity]);

  useEffect(() => {
    if (entity) {
      fetch(paging.pageStart, paging.pageLimit);
    }
  }, [entity, paging.pageStart, paging.pageLimit]);

  if (!entity || loading || !toolStoreID) {
    return <PageLoading />;
  }

  const pendingOrders = ordersList.filter((item) => ["pending", "return_requested"].indexOf(item.status) !== -1);
  const activeOrders = ordersList.filter((item) => ["accepted", "in_transit", "delivered"].indexOf(item.status) !== -1);
  const inActiveOrders = ordersList.filter(
    (item) => ["fulfilled", "rejected", "cancelled"].indexOf(item.status) !== -1,
  );
  return (
    <PageStage>
      <ToolStorePageHeader toolStoreId={toolStoreID} />
      <VSpace gap="gap-12">
        {pendingOrders.length !== 0 && (
          <VSpace>
            <Heading level="h2">Action required</Heading>
            <GridContainer>
              {pendingOrders.map((order) => (
                <ToolStoreOrderCard key={order.id} id={order.id} />
              ))}
            </GridContainer>
          </VSpace>
        )}

        <VSpace>
          <Heading level="h2">Active orders</Heading>
          {activeOrders.length === 0 && (
            <div>
              <h3 className="font-medium text-lg text-gray-700">No active orders</h3>
              <p className="text-gray-500">This tool store currently does not have any active orders.</p>
            </div>
          )}
          <GridContainer>
            {activeOrders.map((order) => (
              <ToolStoreOrderCard key={order.id} id={order.id} />
            ))}
          </GridContainer>
        </VSpace>

        <VSpace>
          <Heading level="h2">Inactive orders</Heading>
          {inActiveOrders.length === 0 && (
            <div>
              <h3 className="font-medium text-lg text-gray-700">No inactive orders</h3>
              <p className="text-gray-500">This tool store has not yet completed or rejected an order.</p>
            </div>
          )}
          <GridContainer>
            {inActiveOrders.map((order) => (
              <ToolStoreOrderCard key={order.id} id={order.id} />
            ))}
          </GridContainer>
        </VSpace>
      </VSpace>
    </PageStage>
  );
};
