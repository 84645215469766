import { ReactNode } from "react";
import styled from "styled-components";
import { Modal } from "components/modals/Modal";

const StyledBottomSheetModal = styled(Modal)`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  min-width: unset;
  padding-top: 1.5rem;
  border-radius: 1rem 1rem 0 0;
`;

type BottomSheetModalProps = {
  children: ReactNode;
  className?: string;
};

export const BottomSheetModal = ({ className, children }: BottomSheetModalProps) => {
  return <StyledBottomSheetModal className={className}>{children}</StyledBottomSheetModal>;
};
