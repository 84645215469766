//@ts-nocheck
import Cookies from "universal-cookie";
import Store from "lib/Store";

export default class Account {
  /**
   * Get the customer ID. Empty string if the user is not authenticated.
   * @returns {number}
   */
  static customerId() {
    const session = this.session();
    return session ? session.cid : "";
  }

  /**
   * Get the user ID. Empty string if the user is not authenticated.
   * @returns {number}
   */
  static userId() {
    const session = this.session();
    return session ? session.userId : "";
  }

  /**
   * Get the employee ID. Empty string if the user is not authenticated.
   * @returns {string}
   */
  static employeeId() {
    const session = this.session();
    return session ? session.employeeId : "";
  }

  /**
   * Get the user role ID. 0 is returned if the user is not authenticated.
   * @returns {number}
   */
  static roleId() {
    const session = this.session();
    return session ? session.roleId : 0;
  }

  /**
   * Get or set the user auth session. Session is null if the user is not authenticated.
   * @param {Object} [session]
   * @returns {{employeeId: number, forename: string, surname: string, userId: number, roleId: number}|null}
   */
  static session(session?: any) {
    if (session) {
      console.error("calling session() with a value is deprecated");
    } else {
      const activeSession = Store.collection("session").findOne();
      // Check that the session has not expired. If it has removed it and return null.
      if (activeSession && activeSession.exp * 1000 <= Date.now()) {
        Account.removeSession();
        return null;
      }
      return activeSession ? activeSession : null;
    }
  }

  /**
   * Remove any existing session
   */
  static removeSession() {
    Store.collection("session").remove();

    const cookies = new Cookies();
    cookies.remove("session", this._getCookieOptions());
  }

  /**
   * Returns the timestamp at which the session expires
   * @returns {number} unix milli timestamp
   */
  static sessionExpiry() {
    const session = this.session();
    return session ? session.exp * 1000 : "";
  }

  /**
   * Get the raw JWT token string. Empty string if the user is not authenticated.
   * @returns {string}
   */
  static token() {
    const session = this.session();
    return session ? session.tokenString : "";
  }

  /**
   * Build the cookie options for the session cookie.
   * @returns {{domain: string, expires: Date}}
   * @private
   */
  static _getCookieOptions = () => {
    const { hostname } = window.location;
    return {
      domain: hostname.indexOf("localhost") === -1 ? "." + hostname : hostname,
      expires: new Date(this.sessionExpiry()),
    };
  };
}
