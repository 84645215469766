import { createSlice } from "@reduxjs/toolkit";
import {
  fetchStorageAlerts,
  fetchStorageAlertById,
  createStorageAlert,
  updateStorageAlert,
  deleteStorageAlert,
} from "store/reducers/storageAlerts/storageAlerts.thunks";
import { TStorageAlert, UUID } from "types";

export type StorageAlertsReducerStateData = Record<UUID, TStorageAlert>;

export interface StorageAlertsReducerState {
  data: StorageAlertsReducerStateData;
}

const initialState: StorageAlertsReducerState = {
  data: {},
};

export const storageAlertsSlice = createSlice({
  name: "storageAlerts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchStorageAlerts.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: StorageAlertsReducerStateData, value: TStorageAlert) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchStorageAlertById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createStorageAlert.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateStorageAlert.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteStorageAlert.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg.id];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const storageAlertsReducer = storageAlertsSlice.reducer;
