//@ts-nocheck
export function requestInitiate(actionType, payload) {
  return {
    type: actionType,
    payload,
  };
}

const noop = () => null;

export function requestInitiateWithCallback(actionType, payload, onSuccess = noop, onFailed = noop) {
  return {
    type: actionType,
    payload,
    onSuccess,
    onFailed,
  };
}

export function receivePagedResult(actionType, payload, paging) {
  return {
    type: actionType,
    payload,
    paging,
  };
}

export function receiveSingleResult(actionType, payload) {
  return {
    type: actionType,
    payload,
  };
}

export function requestFailure(actionType, error) {
  const payload = typeof error === "string" ? new Error(error) : error;
  return {
    type: actionType,
    payload,
  };
}
