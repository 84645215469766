//@ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { PrimaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import PageStage from "components/stages/PageStage";
import ReadersTableContainer from "./ReadersTableContainer";

const ListReaders = () => {
  const breadcrumb = [{ label: "Configuration", href: "/configuration" }, "Readers"];
  return (
    <PageStage>
      <StageHeader
        title="Readers"
        breadcrumb={breadcrumb}
        action={
          <Link to="/configuration/readers/register">
            <PrimaryButton>Register reader</PrimaryButton>
          </Link>
        }
      />
      <p>RFID readers are used to detect RFID tags</p>
      <ReadersTableContainer />
    </PageStage>
  );
};

ListReaders.defaultProps = {};

ListReaders.propTypes = {};

export default ListReaders;
