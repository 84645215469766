import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.locations;

export const selectLocations = createSelector(selectState, (state) => state.data);

export const selectLocationsList = createSelector(selectLocations, (data) => Object.values(data));

export const selectLocationByID = (id: UUID) => createSelector(selectLocations, (data) => data[id]);
