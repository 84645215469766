import { NumberInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { TextBody } from "components/shared/typography";

type ActuationIntervalProps = {
  value?: number;
  onChange: (value: number) => void;
};

export const ActuationInterval = ({ value, onChange }: ActuationIntervalProps) => {
  return (
    <FieldGroup label="Actuations between calibrations">
      <TextBody textColor="text-gray-400">
        Technicians will be asked during tool check-in to specify the number of actuations performed
      </TextBody>
      <NumberInput
        input={{
          name: "actuationInterval",
          placeholder: "Enter a number e.g. 200",
        }}
        value={value}
        onChange={(_: string, value: number) => onChange(value)}
      />
    </FieldGroup>
  );
};
