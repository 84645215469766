//@ts-nocheck
import * as type from "./actionTypes";
import {
  requestInitiate,
  receivePagedResult,
  requestFailure,
  requestInitiateWithCallback,
  receiveSingleResult,
} from "./common";

export const requestAlerts = (params) => requestInitiate(type.GET_ALERTS_REQUEST, params);
export const fulfillRequestAlerts = (items, paging) => receivePagedResult(type.GET_ALERTS_FULFIL, items, paging);
export const rejectRequestAlerts = (err) => requestFailure(type.GET_ALERTS_REJECT, err);

export const requestAlert = (params) => requestInitiate(type.GET_ALERT_REQUEST, params);
export const fulfilRequestAlert = (item) => receiveSingleResult(type.GET_ALERT_FULFIL, item);
export const rejectRequestAlert = (err) => requestFailure(type.GET_ALERT_REJECT, err);

export const createAlert = (item, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.CREATE_ALERT_REQUEST, item, onSuccess, onFailed);
export const fulfilCreateAlert = (item) => receiveSingleResult(type.CREATE_ALERT_FULFIL, item);
export const rejectCreateAlert = (err) => requestFailure(type.CREATE_ALERT_REJECT, err);

export const updateAlert = (item, onSuccess, onFailed) => {
  if (!Array.isArray(item.recipients)) {
    item.recipients = Object.values(item.recipients);
  }
  if (!Array.isArray(item.rules)) {
    item.rules = Object.values(item.rules);
  }
  return requestInitiateWithCallback(type.UPDATE_ALERT_REQUEST, item, onSuccess, onFailed);
};
export const fulfilUpdateAlert = (item) => receiveSingleResult(type.UPDATE_ALERT_FULFIL, item);
export const rejectUpdateAlert = (err) => requestFailure(type.UPDATE_ALERT_REJECT, err);

export const deleteAlert = (itemID, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.DELETE_ALERT_REQUEST, itemID, onSuccess, onFailed);
export const fulfilDeleteAlert = (itemID) => receiveSingleResult(type.DELETE_ALERT_FULFIL, itemID);
export const rejectDeleteAlert = (err) => requestFailure(type.DELETE_ALERT_REJECT, err);

export const createAlertRule = (item, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.CREATE_ALERT_RULE_REQUEST, item, onSuccess, onFailed);
export const fulfilCreateAlertRule = (item) => receiveSingleResult(type.CREATE_ALERT_RULE_FULFIL, item);
export const rejectCreateAlertRule = (err) => requestFailure(type.CREATE_ALERT_RULE_REJECT, err);

export const deleteAlertRule = (itemID, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.DELETE_ALERT_RULE_REQUEST, itemID, onSuccess, onFailed);
export const fulfilDeleteAlertRule = (itemID) => receiveSingleResult(type.DELETE_ALERT_RULE_FULFIL, itemID);
export const rejectDeleteAlertRule = (err) => requestFailure(type.DELETE_ALERT_RULE_REJECT, err);

export const createAlertRecipient = (item, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.CREATE_ALERT_RECIPIENT_REQUEST, item, onSuccess, onFailed);
export const fulfilCreateAlertRecipient = (item) => receiveSingleResult(type.CREATE_ALERT_RECIPIENT_FULFIL, item);
export const rejectCreateAlertRecipient = (err) => requestFailure(type.CREATE_ALERT_RECIPIENT_REJECT, err);

export const updateAlertRecipient = (item, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.UPDATE_ALERT_RECIPIENT_REQUEST, item, onSuccess, onFailed);
export const fulfilUpdateAlertRecipient = (item) => receiveSingleResult(type.UPDATE_ALERT_RECIPIENT_FULFIL, item);
export const rejectUpdateAlertRecipient = (err) => requestFailure(type.UPDATE_ALERT_RECIPIENT_REJECT, err);

export const deleteAlertRecipient = (itemID, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.DELETE_ALERT_RECIPIENT_REQUEST, itemID, onSuccess, onFailed);
export const fulfilDeleteAlertRecipient = (itemID) => receiveSingleResult(type.DELETE_ALERT_RECIPIENT_FULFIL, itemID);
export const rejectDeleteAlertRecipient = (err) => requestFailure(type.DELETE_ALERT_RECIPIENT_REJECT, err);
