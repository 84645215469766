//@ts-nocheck
import React from "react";
import AppComingSoon from "components/AppComingSoon";
import PageStage from "components/stages/PageStage";

const WorkforceDashboard = () => {
  return (
    <PageStage>
      <AppComingSoon
        title="Workforce is coming soon"
        description="The Workforce App is a powerful integrated management tool that enables your business to manage their workforce including employees, contractors, and freelancers."
      />
    </PageStage>
  );
};

WorkforceDashboard.defaultProps = {};

WorkforceDashboard.propTypes = {};

export default WorkforceDashboard;
