import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { fetchManufacturers } from "store/reducers/catalogue/manufacturers.thunks";
import { TManufacturer } from "types";

const selectState = (state: RootStore) => state.manufacturersReducer;

export const selectManufacturers = createSelector(selectState, (state) => state.data);

export const selectManufacturersLoading = createSelector(
  selectState,
  ({ loading }) => loading === fetchManufacturers.pending.type,
);

export const selectManufacturersList = createSelector(selectManufacturers, (data) => Object.values(data));

export const selectManufacturersPage = createSelector(selectState, ({ page, data }) => {
  return page.map((id) => data[id]).filter(Boolean);
});

export const selectManufacturerByID = (id: number) =>
  createSelector(selectManufacturers, (data): TManufacturer | undefined => data[id]);
