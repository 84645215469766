import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { GET_LOOKUPS_RECEIVE } from "lib/actions/actionTypes";
import {
  createAssetIssueProblem,
  deleteAssetIssueProblem,
  fetchAssetIssueProblemById,
  fetchAssetIssueProblems,
  updateAssetIssueProblem,
} from "store/reducers/assetIssueProblems/assetIssueProblems.thunks";
import { FixLater, TAssetIssueProblem, UUID } from "types";

export type AssetIssueProblemsReducerStateData = Record<UUID, TAssetIssueProblem>;

export interface AssetIssueProblemsReducerState {
  loading: string;
  page: UUID[];
  data: AssetIssueProblemsReducerStateData;
}

const initialState: AssetIssueProblemsReducerState = {
  loading: "",
  page: [],
  data: {},
};

export const assetIssueProblemsSlice = createSlice({
  name: "assetIssueProblems",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GET_LOOKUPS_RECEIVE, (state, action: FixLater) => {
        state.data = action.payload?.assetIssueProblems?.reduce(
          (data: AssetIssueProblemsReducerStateData, value: TAssetIssueProblem) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchAssetIssueProblems.pending, (state, action) => {
        state.page = [];
      })
      .addCase(fetchAssetIssueProblems.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: AssetIssueProblemsReducerStateData, value: TAssetIssueProblem) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
        state.page = action.payload.results.map(({ id }) => id);
      })
      .addCase(fetchAssetIssueProblemById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createAssetIssueProblem.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateAssetIssueProblem.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteAssetIssueProblem.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addMatcher(isPending, (state, action) => {
        state.loading = action.type;
      })
      .addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
        state.loading = "";
      });
  },
});

export const assetIssueProblemsReducer = assetIssueProblemsSlice.reducer;
