//@ts-nocheck
import { createSelector } from "reselect";

export const getTasks = createSelector(
  (state) => state.tasks,
  (state = {}) => {
    return state.tasks.data;
  },
);

export const getTasksPaging = createSelector(
  (state) => state.tasks,
  (state = {}) => {
    return state.tasks.paging;
  },
);

export const getTask = createSelector(
  (state) => state.tasks,
  (state = {}) => {
    return state.task.data ? state.task.data : null;
  },
);

export const getTaskBlocks = createSelector(
  (state) => state.tasks,
  (state = {}) => {
    return state.taskBlocks.data;
  },
);

export const getTaskFetching = createSelector(
  (state) => state.tasks,
  (state = {}) => {
    return state.taskBlocks.fetching;
  },
);

export const getTaskBlocksPaging = createSelector(
  (state) => state.tasks,
  (state = {}) => {
    return state.taskBlocks.paging;
  },
);
