import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateDoorwayRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TDoorway,
  UpdateDoorwayRequest,
  UUID,
} from "types";

type FetchDoorwaysParams = PaginationQueryParams;

export const fetchDoorways = createAsyncThunk(
  "doorways/fetchDoorways",
  async (params: FetchDoorwaysParams): Promise<{ results: TDoorway[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TDoorway[]>>("/doorways", params);
    return { results: data, paging: metadata };
  },
);

export const fetchDoorwayById = createAsyncThunk("doorways/fetchDoorwayById", async (id: UUID): Promise<TDoorway> => {
  const { data } = await API.GET<DataResponse<TDoorway>>(`/doorways/${id}`);
  return data;
});

export const fetchDoorwayByStorageId = createAsyncThunk(
  "doorways/fetchDoorwayByStorageId",
  async (storageId: string): Promise<TDoorway> => {
    const { data } = await API.GET<DataResponse<TDoorway>>(`/storages/${storageId}/doorway`);
    return data;
  },
);

export const createDoorway = createAsyncThunk(
  "doorways/createDoorway",
  async (request: CreateDoorwayRequest): Promise<TDoorway> => {
    const { data } = await API.POST<CreateDoorwayRequest, DataResponse<TDoorway>>("/doorways", request);
    return data;
  },
);

export const updateDoorway = createAsyncThunk(
  "doorways/updateDoorway",
  async (request: UpdateDoorwayRequest): Promise<TDoorway> => {
    const { data } = await API.PUT<UpdateDoorwayRequest, DataResponse<TDoorway>>(`/doorways/${request.id}`, request);
    return data;
  },
);

export const deleteDoorway = createAsyncThunk("doorways/deleteDoorway", async (id: UUID): Promise<void> => {
  await API.DELETE(`/doorways/${id}`);
});
