//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { DateTimeInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import style from "../textEditorDropdown.module.scss";

const DatetimeFilter = ({ filter, className, onChange }) => {
  return (
    <FieldGroup label={filter.name} className={classNames(style.contentInputGroup, className)}>
      <DateTimeInput
        input={{ name: filter.type, placeholder: "" }}
        value={filter.value}
        onChange={onChange}
        className={style.contentInput}
      />
    </FieldGroup>
  );
};

DatetimeFilter.defaultProps = {};

DatetimeFilter.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default DatetimeFilter;
