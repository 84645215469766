//@ts-nocheck

export default class Block {
  /**
   * @type {string}
   */
  id;

  /**
   * @type {number}
   */
  type;

  /**
   * @type {?number}
   */
  lastUpdatedBy;

  /**
   * @type {number}
   */
  createdBy;

  /**
   * @type {?string}
   */
  dateUpdated;

  /**
   * @type {string}
   */
  dateCreated;

  /**
   * @type {Object}
   */
  properties;
}
