import { ReactNode } from "react";
import classNames from "classnames";
import { TailwindFlexDirection, TailwindGap } from "types/tailwind";

export type VSpaceProps = {
  gap?: TailwindGap;
  direction?: TailwindFlexDirection;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

export const VSpace = ({ gap = "gap-4", direction = "flex-col", children, className, ...otherProps }: VSpaceProps) => {
  return (
    <div {...otherProps} className={classNames("flex", direction, gap, className)}>
      {children}
    </div>
  );
};
