//@ts-nocheck
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import ImportProductColumnTypes from "lib/constants/ImportProductColumnTypes";
import { SmartTable } from "components/Table/SmartTable";

export const ImportedProductsTable = ({ itemsCreated }) => {
  const rowData = useMemo(() => itemsCreated, [itemsCreated]);
  const columns = useMemo(() => {
    return [
      {
        Header: ImportProductColumnTypes.resolveItemLabel(ImportProductColumnTypes.PRODUCT_NUMBER),
        Key: "mpn",
        Cell: ({ row }) => {
          const { mpn } = row.original;
          return <div>{mpn}</div>;
        },
      },
      {
        Header: ImportProductColumnTypes.resolveItemLabel(ImportProductColumnTypes.NAME),
        Key: "name",
        Cell: ({ row }) => {
          const { id, manufacturerId, name } = row.original;
          return <Link to={`/configuration/manufacturers/${manufacturerId}/products/${id}`}>{name}</Link>;
        },
      },
    ];
  }, []);
  return <SmartTable columns={columns} data={rowData} />;
};
