import React, { CSSProperties } from "react";
import { Outlet } from "react-router-dom";
import { LayoutHelmet } from "components/layouts/LayoutHelmet/LayoutHelmet";
import AutoDismissFlags from "components/modals/PopupFlag/AutoDismissFlags";
import RootModal from "components/modals/RootModal/RootModal";

type EmptyLayoutProps = {
  className?: string;
  style?: CSSProperties;
};

const EmptyLayout = ({ className, style }: EmptyLayoutProps) => {
  return (
    <div className={className} style={style}>
      <AutoDismissFlags key="layoutFlags" />
      <RootModal key="rootModal" />
      <LayoutHelmet />
      <Outlet />
    </div>
  );
};

export default EmptyLayout;
