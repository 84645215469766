import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { handleThunkError, ThunkError } from "store/utils/thunk";
import {
  CreateToolStoreOrderItemRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TToolStoreOrderItem,
  UpdateToolStoreOrderItemRequest,
  UUID,
} from "types";

type FetchToolStoreOrderItemsParams = PaginationQueryParams;

export const fetchToolStoreOrderItems = createAsyncThunk(
  "toolStoreOrderItems/list",
  async (params: FetchToolStoreOrderItemsParams): Promise<{ results: TToolStoreOrderItem[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TToolStoreOrderItem[]>>("/tool_stores", params);
    return { results: data, paging: metadata };
  },
);

export const fetchToolStoreOrderItemById = createAsyncThunk(
  "toolStoreOrderItems/getById",
  async (id: UUID): Promise<TToolStoreOrderItem> => {
    const { data } = await API.GET<DataResponse<TToolStoreOrderItem>>(`/tool_stores/${id}`);
    return data;
  },
);

export const createToolStoreOrderItem = createAsyncThunk(
  "toolStoreOrderItems/create",
  async (request: CreateToolStoreOrderItemRequest): Promise<TToolStoreOrderItem> => {
    const { data } = await API.POST<CreateToolStoreOrderItemRequest, DataResponse<TToolStoreOrderItem>>(
      `/tool_stores/${request.toolStoreId}/orders/${request.toolStoreOrderId}/items`,
      request,
    );
    return data;
  },
);

export const updateToolStoreOrderItem = createAsyncThunk<
  TToolStoreOrderItem,
  UpdateToolStoreOrderItemRequest,
  ThunkError
>("toolStoreOrderItems/update", async (request: UpdateToolStoreOrderItemRequest, { rejectWithValue }) => {
  try {
    const { data } = await API.PUT<UpdateToolStoreOrderItemRequest, DataResponse<TToolStoreOrderItem>>(
      `/tool_stores/${request.toolStoreId}/orders/${request.toolStoreOrderId}/items/${request.id}`,
      request,
    );
    return data;
  } catch (err) {
    return handleThunkError(rejectWithValue, err);
  }
});

export const deleteToolStoreOrderItem = createAsyncThunk(
  "toolStoreOrderItems/delete",
  async (id: UUID): Promise<void> => {
    await API.DELETE(`/tool_stores/${id}`);
  },
);
