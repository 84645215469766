//@ts-nocheck
import { combineReducers } from "redux";
import alerts from "./alerts";
import assets from "./assets";
import blocks from "./blocks";
import cart from "./cart";
import catalogue from "./catalogue";
import checkouts from "./checkouts";
import evidences from "./evidences";
import lookupData from "./lookupData";
import manufacturers from "./manufacturers";
import modal from "./modal";
import products from "./products";
import projects from "./projects";
import readers from "./readers";
import reportBuilder from "./reportBuilder";
import reports from "./reports";
import sentry from "./sentry";
import statistics from "./statistics";
import storages from "./storages";
import storeItems from "./storeItems";
import tags from "./tags";
import taskLists from "./taskLists";
import tasks from "./tasks";
import userInterface from "./userInterface";
import users from "./users";

export default combineReducers({
  users,
  assets,
  storages,
  readers,
  lookupData,
  checkouts,
  tags,
  statistics,
  modal,
  projects,
  catalogue,
  reports,
  reportBuilder,
  evidences,
  userInterface,
  sentry,
  blocks,
  tasks,
  taskLists,
  storeItems,
  alerts,
  cart,
  manufacturers,
  products,
});
