import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { ThunkError, handleThunkError } from "store/utils/thunk";
import {
  CreateAssetRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TAsset,
  UUID,
  UpdateAssetRequest,
} from "types";

export type FetchAssetsParams = PaginationQueryParams & {
  storageId?: string;
  query?: string;
  assigned?: boolean;
  calibrated?: boolean;
  toolStore?: boolean; // Returns only assets assigned to tool stores
  toolStoreId?: UUID; // Returns only assets assigned to the specific tool store
  vehicleId?: UUID; // Returns only assets assigned to the specific vehicle
};

export const fetchAssets = createAsyncThunk(
  "assets/fetchAssets",
  async (params: FetchAssetsParams): Promise<{ results: TAsset[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TAsset[]>>("/assets", params);
    return { results: data, paging: metadata };
  },
);

export const fetchAssetById = createAsyncThunk("assets/fetchAssetById", async (id: number | UUID): Promise<TAsset> => {
  const { data } = await API.GET<DataResponse<TAsset>>(`/assets/${id}`);
  return data;
});

export const createAsset = createAsyncThunk<TAsset, CreateAssetRequest, ThunkError>(
  "assets/createAsset",
  async (request, { rejectWithValue }) => {
    try {
      const { data } = await API.POST<CreateAssetRequest, DataResponse<TAsset>>("/assets", request);
      return data;
    } catch (err) {
      return handleThunkError(rejectWithValue, err);
    }
  },
);

export const updateAsset = createAsyncThunk(
  "assets/updateAsset",
  async (request: UpdateAssetRequest): Promise<TAsset> => {
    const { data } = await API.PUT<UpdateAssetRequest, DataResponse<TAsset>>(`/assets/${request.id}`, request);
    return data;
  },
);

export const deleteAsset = createAsyncThunk("assets/deleteAsset", async (id: number): Promise<void> => {
  await API.DELETE(`/assets/${id}`);
});
