//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import DropSelect from "components/dropdowns/DropSelect";

const AssetStatusFilter = ({ value, onChange }) => {
  return (
    <DropSelect
      value={value}
      text="Status"
      icon="/images/icons/caret_down_1.svg"
      onChange={onChange}
      items={[
        {
          label: "In Use",
          description: "Only show tools that are currently being used by someone",
          value: true,
        },
        {
          label: "Stored",
          description: "Only show tools that are currently stored",
          value: false,
        },
      ]}
    />
  );
};

AssetStatusFilter.defaultProps = {};

AssetStatusFilter.propTypes = {
  className: PropTypes.string,
};

export default AssetStatusFilter;
