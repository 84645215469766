//@ts-nocheck
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import IconWithConfetti from "components/Icon/IconWithConfetti";
import { Header, Text } from "components/Typography";
import style from "./recoverPassword.module.scss";

const OnSuccess = ({ email }) => {
  const [animate, setAnimate] = useState(false);
  useEffect(() => setAnimate(true), []);
  return (
    <div className="text-center">
      <IconWithConfetti src="/images/icons/update_1.svg" size="xlarge" active={animate} className={style.successIcon} />
      <Header>
        <span>Great! We've sent you an email</span>
      </Header>
      <Text>
        <span className={classNames("text-muted", style.confirmationText)}>
          We've sent instructions to recover your password to
        </span>
        <br />
        <strong>{email}</strong>
      </Text>
    </div>
  );
};

OnSuccess.defaultProps = {};

OnSuccess.propTypes = {
  email: PropTypes.string,
};

export default OnSuccess;
