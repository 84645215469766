import classNames from "classnames";
import {
  TailwindBackgroundColor,
  TailwindFontWeight,
  TailwindPaddingX,
  TailwindPaddingY,
  TailwindRounding,
  TailwindTextColor,
  TailwindTextSize,
  TailwindTextTransform,
} from "types/tailwind";

export type StatusPillProps = {
  id?: string;
  text: string;
  onClick?: (event: any) => void;
  rounding?: TailwindRounding;
  paddingY?: TailwindPaddingY;
  paddingX?: TailwindPaddingX;
  textColor?: TailwindTextColor;
  fontWeight?: TailwindFontWeight;
  textTransform?: TailwindTextTransform;
  textSize?: TailwindTextSize;
  backgroundColor?: TailwindBackgroundColor;
  iconBackgroundColor?: TailwindBackgroundColor;
  className?: string;
};

export const StatusPill = ({
  id,
  text,
  textSize = "text-sm",
  textColor = "text-green-800",
  backgroundColor = "bg-green-100",
  iconBackgroundColor = "bg-green-500",
  textTransform = "uppercase",
  fontWeight = "font-medium",
  paddingX = "px-2.5",
  paddingY = "py-0.5",
  rounding = "rounded-full",
  className,
}: StatusPillProps) => {
  const classes = classNames(
    "inline-flex items-center mr-2 whitespace-nowrap",
    fontWeight,
    paddingX,
    paddingY,
    rounding,
    textTransform,
    textColor,
    textSize,
    backgroundColor,
    className,
  );
  return (
    <span id={id} className={classes}>
      <span className={classNames("w-2 h-2 mr-1 rounded-full", iconBackgroundColor)} />
      {text}
    </span>
  );
};
