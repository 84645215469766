import { createSlice, isPending, isFulfilled, isRejected, isAnyOf } from "@reduxjs/toolkit";
import { TManufacturer } from "types";
import { fetchManufacturers, fetchManufacturerById } from "./manufacturers.thunks";

export type ManufacturersReducerStateData = Record<number, TManufacturer>;

export interface ManufacturersReducerState {
  loading: string;
  page: number[];
  data: ManufacturersReducerStateData;
}

const initialState: ManufacturersReducerState = {
  loading: "",
  page: [],
  data: {},
};

export const manufacturersSlice = createSlice({
  name: "manufacturers",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchManufacturers.pending, (state, action) => {
        state.page = [];
      })
      .addCase(fetchManufacturers.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: ManufacturersReducerStateData, value: TManufacturer) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
        state.page = action.payload.results.map(({ id }) => id);
      })
      .addCase(fetchManufacturerById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addMatcher(isPending, (state, action) => {
        state.loading = action.type;
      })
      .addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
        state.loading = "";
      });
  },
});
export const manufacturersReducer = manufacturersSlice.reducer;
