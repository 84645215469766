//@ts-nocheck
import Account from "../Account";
import Api from "./api";

class Evidences extends Api {
  /**
   * Get evidence by ID
   * @async
   * @param {string} evidenceId
   * @returns {Promise.<Object>}
   */
  static async getEvidence(evidenceId) {
    const response = await this.get(`/evidences/${evidenceId}`).send();
    return response.body.data;
  }

  /**
   * Download evidence KSI signature
   * @param {string} evidenceId
   * @param {string} filename
   */
  static async downloadEvidence(evidenceId, filename) {
    if (!filename) {
      throw new Error("expected non-empty evidence filename");
    }
    window.open(
      `${Api.getBaseUrl}/evidences/${evidenceId}?download=true&authorization=${Account.token()}&filename=${filename}`,
      "_blank",
    );
  }
}

export default Evidences;
