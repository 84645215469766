import { createSlice } from "@reduxjs/toolkit";
import {
  createAlert,
  deleteAlert,
  fetchAlertById,
  fetchAlerts,
  updateAlert,
} from "store/reducers/alerts/alerts.thunks";
import {
  createStorageAlert,
  fetchStorageAlertById,
  updateStorageAlert,
} from "store/reducers/storageAlerts/storageAlerts.thunks";
import { TAlert, UUID } from "types";

export type AlertsReducerStateData = Record<UUID, TAlert>;

export interface AlertsReducerState {
  loading: string;
  page: UUID[];
  data: AlertsReducerStateData;
}

const initialState: AlertsReducerState = {
  loading: "",
  page: [],
  data: {},
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAlerts.pending, (state, action) => {
        state.page = [];
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: AlertsReducerStateData, value: TAlert) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
        state.page = action.payload.results.map(({ id }) => id);
      })
      .addCase(fetchAlertById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createAlert.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteAlert.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addCase(fetchStorageAlertById.fulfilled, (state, action) => {
        if (action.payload.alert) {
          state.data[action.payload.alert.id] = action.payload.alert;
        }
      })
      .addCase(createStorageAlert.fulfilled, (state, action) => {
        if (action.payload.alert) {
          state.data[action.payload.alert.id] = action.payload.alert;
        }
      })
      .addCase(updateStorageAlert.fulfilled, (state, action) => {
        if (action.payload.alert) {
          state.data[action.payload.alert.id] = action.payload.alert;
        }
      });
  },
});

export const alertsReducer = alertsSlice.reducer;
