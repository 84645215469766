import { ReactNode, MouseEvent } from "react";
import classNames from "classnames";
import styled from "styled-components";
import { ButtonGroup } from "components/Buttons";
import Card from "components/Card";
import { Header } from "components/Typography";
import { Breadcrumbs, BreadcrumbsItem } from "components/breadcrumbs";
import { VSpace, VSpaceProps } from "components/shared";
import style from "./modal.module.scss";

export type ModalHeaderBreadcrumb = {
  label: string;
  href: string;
  onClick: (event: MouseEvent) => void;
};

type ModalHeaderProps = {
  title: string;
  breadcrumbs?: ModalHeaderBreadcrumb[];
  concise?: boolean;
  className?: string;
  children?: ReactNode;
};

export const ModalHeader = ({ title, children, breadcrumbs = [], concise = false, className }: ModalHeaderProps) => (
  <div className={classNames(style.modalHeader, { [style.concise]: concise }, className)}>
    {breadcrumbs.length > 0 ? (
      <Breadcrumbs>
        {breadcrumbs.map((item) => (
          <BreadcrumbsItem key={item.label} text={item.label} href={item.href} onClick={item.onClick} />
        ))}
      </Breadcrumbs>
    ) : null}
    {title && <Header className={style.title}>{title}</Header>}
    {children}
  </div>
);

type ModalContentProps = {
  className?: string;
  children?: ReactNode;
} & Pick<VSpaceProps, "gap" | "direction">;

export const ModalContent = ({ children, className, gap = "gap-8", ...otherProps }: ModalContentProps) => (
  <VSpace {...otherProps} gap={gap} className={classNames(style.modalContent, className)}>
    {children}
  </VSpace>
);

type ModalFooterProps = {
  alignRight?: boolean;
  className?: string;
  children?: ReactNode;
};

export const ModalFooter = ({ children, alignRight = true, className }: ModalFooterProps) => (
  <div className={classNames(style.modalFooter, className)}>
    <ButtonGroup alignRight={alignRight}>{children}</ButtonGroup>
  </div>
);

const StyledModal = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: 37.5rem;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 1rem;
  max-height: 85vh;
  overflow: auto;
`;

type ModalProps = {
  className?: string;
  children?: ReactNode;
};

export const Modal = ({ children, className }: ModalProps) => {
  return <StyledModal className={classNames(style.modal, className)}>{children}</StyledModal>;
};
