import { UUID } from "types";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { VSpace, AlertEmailAddresses, AlertTelephones } from "components/shared";

type AlertRecipientFormProps = {
  alertId: UUID;
};

export const AlertRecipientForm = ({ alertId }: AlertRecipientFormProps) => {
  return (
    <DataGrid>
      <DataItem
        label="Recipients"
        value={
          <DataItemGroup>
            <VSpace gap="gap-2">
              <FieldGroup label="Email addresses">
                <AlertEmailAddresses alertId={alertId} />
              </FieldGroup>
              <FieldGroup label="Telephone numbers">
                <AlertTelephones alertId={alertId} />
              </FieldGroup>
            </VSpace>
          </DataItemGroup>
        }
      />
    </DataGrid>
  );
};
