//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Header } from "components/Typography";
import PageStage from "components/stages/PageStage";
import style from "./notfound.module.scss";

const NotFound = ({ className }) => {
  return (
    <PageStage>
      <div className={style.container}>
        <Header>This page is not available</Header>
      </div>
    </PageStage>
  );
};

NotFound.defaultProps = {};

NotFound.propTypes = {
  className: PropTypes.string,
};

export default NotFound;
