import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { TAsset, TLocation, TToolStore } from "types";

type ToolStoreOrderProviderProps = {
  children: ReactNode;
};

type ToolStoreOrderProviderContextProperties = {
  toolStore?: TToolStore;
  setToolStore: Dispatch<SetStateAction<TToolStore | undefined>>;
  location?: TLocation;
  setLocation: Dispatch<SetStateAction<TLocation | undefined>>;
  basket: Record<number, TAsset>;
  setBasket: Dispatch<SetStateAction<Record<number, TAsset>>>;
};

const warnOutsideContext = () => console.warn("useToolStoreOrder must be used within a ToolStoreOrderProviderContext");

export const ToolStoreOrderProviderContext = createContext<ToolStoreOrderProviderContextProperties>({
  toolStore: undefined,
  setToolStore: warnOutsideContext,
  location: undefined,
  setLocation: warnOutsideContext,
  basket: {},
  setBasket: warnOutsideContext,
});

export const useToolStoreOrder = (): ToolStoreOrderProviderContextProperties => {
  const context = useContext(ToolStoreOrderProviderContext);
  if (context === undefined) {
    throw new Error("useToolStoreOrder must be used within a ToolStoreOrderProviderContext");
  }
  return context;
};

export const ToolStoreOrderProvider = ({ children }: ToolStoreOrderProviderProps) => {
  const [toolStore, setToolStore] = useState<TToolStore>();
  const [location, setLocation] = useState<TLocation>();
  const [basket, setBasket] = useState<Record<number, TAsset>>({});
  return (
    <ToolStoreOrderProviderContext.Provider
      value={{
        toolStore,
        setToolStore,
        location,
        setLocation,
        basket,
        setBasket,
      }}
    >
      {children}
    </ToolStoreOrderProviderContext.Provider>
  );
};
