//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { SelectInput } from "components/Input";
import { timeIntervals } from "./options";

const defaultOptions = timeIntervals.map((option) => ({
  label: option,
  value: option,
}));

const SelectTime = ({
  name,
  placeholder,
  value,
  options,
  appearance,
  onChange,
  hideIndicator,
  clearable,
  className,
}) => {
  const input = {
    name,
    placeholder,
    options,
    hideIndicator,
    clearable,
  };
  return appearance === "normal" ? (
    <SelectInput input={input} value={value} onChange={onChange} className={className} />
  ) : null;
};

SelectTime.defaultProps = {
  appearance: "normal",
  placeholder: "",
  options: defaultOptions,
  hideIndicator: true,
  clearable: false,
};

SelectTime.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  appearance: PropTypes.oneOf(["normal", "inline"]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  hideIndicator: PropTypes.bool,
  clearable: PropTypes.bool,
  className: PropTypes.string,
};

export default SelectTime;
