import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TToolStore, UUID } from "types";

const selectState = (state: RootStore) => state.toolStores;

export const selectToolStores = createSelector(selectState, (state) => state.data);

export const selectToolStoresList = createSelector(selectToolStores, (data) => Object.values(data));

export const selectToolStoreByID = (id: UUID) =>
  createSelector(selectToolStores, (data): TToolStore | undefined => data[id]);

export const selectToolStoreByStorageID = (storageId: string) =>
  createSelector(selectToolStoresList, (list) => list.find((entity) => entity.storageId === storageId));
