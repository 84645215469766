//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { SelectInput } from "components/Input";
import classes from "components/OptionSelector/OptionSelector.module.scss";

export default class OptionSelector extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    value: PropTypes.any,
    onSelect: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: "",
  };

  constructor(props) {
    super(props);
  }

  handleSelect = (_, value) => {
    const { onSelect } = this.props;
    onSelect(value.value);
  };

  transformOptions = (options) => options.map((value) => ({ label: value, value }));

  render() {
    const { className, options, value } = this.props;
    return (
      <div className={classNames(classes.wrapper, className)}>
        <span className={classes.label}>Rows per page:</span>
        <SelectInput
          input={{
            name: "options",
            options: this.transformOptions(options),
            clearable: false,
            isSearchable: false,
          }}
          className={classes.selectInput}
          value={value}
          onChange={this.handleSelect}
        />
      </div>
    );
  }
}
