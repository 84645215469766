import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { handleThunkError, ThunkError } from "store/utils/thunk";
import {
  CreateToolStoreOrderRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  ToolStoreOrderStatus,
  TToolStoreOrder,
  UpdateToolStoreOrderRequest,
  UUID,
} from "types";

export type FetchToolStoreOrdersParams = {
  toolStoreId: UUID;
  paging: PaginationQueryParams;
};

export type FetchToolStoreOrderById = {
  toolStoreId: UUID;
  toolStoreOrderId: UUID;
};

export type DeleteToolStoreOrderParams = {
  toolStoreId: UUID;
  toolStoreOrderId: UUID;
};

export const fetchToolStoreOrders = createAsyncThunk(
  "toolStoreOrders/list",
  async (params: FetchToolStoreOrdersParams): Promise<{ results: TToolStoreOrder[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TToolStoreOrder[]>>(
      `/tool_stores/${params.toolStoreId}/orders`,
      { ...params.paging },
    );
    return { results: data, paging: metadata };
  },
);

type FetchUserToolStoreOrdersParams = {
  statuses?: ToolStoreOrderStatus[];
};

export const fetchToolStoreUserOrders = createAsyncThunk(
  "toolStoreOrders/fetchUserToolStoreOrders",
  async (params: FetchUserToolStoreOrdersParams): Promise<{ results: TToolStoreOrder[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TToolStoreOrder[]>, FetchUserToolStoreOrdersParams>(
      `/tool_store_orders`,
      {
        ...params,
      },
    );
    return { results: data, paging: metadata };
  },
);

export const fetchToolStoreOrderById = createAsyncThunk(
  "toolStoreOrders/getById",
  async (params: FetchToolStoreOrderById): Promise<TToolStoreOrder> => {
    const { data } = await API.GET<DataResponse<TToolStoreOrder>>(
      `/tool_stores/${params.toolStoreId}/orders/${params.toolStoreOrderId}`,
    );
    return data;
  },
);

export const createToolStoreOrder = createAsyncThunk(
  "toolStoreOrders/create",
  async (request: CreateToolStoreOrderRequest): Promise<TToolStoreOrder> => {
    const { data } = await API.POST<CreateToolStoreOrderRequest, DataResponse<TToolStoreOrder>>(
      `/tool_stores/${request.toolStoreId}/orders`,
      request,
    );
    return data;
  },
);

export const updateToolStoreOrder = createAsyncThunk<TToolStoreOrder, UpdateToolStoreOrderRequest, ThunkError>(
  "toolStoreOrders/update",
  async (request: UpdateToolStoreOrderRequest, { rejectWithValue }) => {
    try {
      const { data } = await API.PUT<UpdateToolStoreOrderRequest, DataResponse<TToolStoreOrder>>(
        `/tool_stores/${request.toolStoreId}/orders/${request.id}`,
        request,
      );
      return data;
    } catch (err) {
      return handleThunkError(rejectWithValue, err);
    }
  },
);

export const deleteToolStoreOrder = createAsyncThunk(
  "toolStoreOrders/delete",
  async (params: DeleteToolStoreOrderParams): Promise<void> => {
    await API.DELETE(`/tool_stores/${params.toolStoreId}/orders/${params.toolStoreOrderId}`);
  },
);
