//@ts-nocheck
import { createSelector } from "reselect";

export const selectManufacturersStore = (state) => state.manufacturers;

export const selectManufacturers = createSelector(selectManufacturersStore, (state) => state.data);

export const selectManufacturersList = createSelector(selectManufacturers, (manufacturers) =>
  Object.values(manufacturers),
);

export const selectManufacturersPaging = createSelector(selectManufacturersStore, (state) => state.paging);

export const selectManufacturersError = createSelector(selectManufacturersStore, (state) => state.error);

export const selectManufacturerByID = (id) => createSelector(selectManufacturers, (data) => data[id]);
