//@ts-nocheck
import { useEffect } from "react";

const useTicker = (callback, timeout) => {
  useEffect(() => {
    if (typeof callback !== "function") {
      return;
    }
    // Call immediately
    callback();

    // Setup an interval to execute the callback
    const intervalID = setInterval(callback, timeout);
    return () => {
      clearInterval(intervalID);
    };
  }, []);
};

export default useTicker;
