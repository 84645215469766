import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateKeycardRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TKeycard,
  UpdateKeycardRequest,
} from "types";

type FetchKeycardsParams = PaginationQueryParams & {};

export const fetchKeycards = createAsyncThunk(
  "keycards/list",
  async (params: FetchKeycardsParams): Promise<{ results: TKeycard[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TKeycard[]>>("/keycards", params);
    return { results: data, paging: metadata };
  },
);

export const fetchKeycardById = createAsyncThunk("keycards/getById", async (id: string): Promise<TKeycard> => {
  const { data } = await API.GET<DataResponse<TKeycard>>(`/keycards/${id}`);
  return data;
});

export const createKeycard = createAsyncThunk(
  "keycards/create",
  async (request: CreateKeycardRequest): Promise<TKeycard> => {
    const { data } = await API.POST<CreateKeycardRequest, DataResponse<TKeycard>>("/keycards", request);
    return data;
  },
);

export const updateKeycard = createAsyncThunk(
  "keycards/update",
  async (request: UpdateKeycardRequest): Promise<TKeycard> => {
    const { data } = await API.PUT<UpdateKeycardRequest, DataResponse<TKeycard>>(`/keycards/${request.id}`, request);
    return data;
  },
);

export const deleteKeycard = createAsyncThunk("keycards/delete", async (id: string): Promise<void> => {
  await API.DELETE(`/keycards/${id}`);
});
