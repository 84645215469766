import React, { ChangeEvent, ReactNode, useRef, useState } from "react";
import { OpenDirectionShape, SingleDatePicker } from "react-dates";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";
import classNames from "classnames";
import dayjs from "dayjs";
import useDidMount from "effects/useDidMount";
import moment from "moment";
import PropTypes from "prop-types";
import { FixLater } from "types";
import { SecondaryButton } from "components/Buttons";
import { CurrencyInput, CurrencyInputElement, CurrencyInputValue } from "components/Input/CurrencyInput";
import SelectTime from "components/inputs/SelectTime/SelectTime";
import Dropzone from "./Dropzone";
import style from "./input.module.scss";

type InputGroupsProps = {
  className?: string;
  children?: ReactNode;
};

export const InputGroups = ({ className, children }: InputGroupsProps) => (
  <div className={classNames(style.inputGroups, className)}>{children}</div>
);

type InputGroupProps = {
  label?: string;
  withAddon: boolean;
  className?: string;
  children?: ReactNode;
};

export const InputGroup = ({ className, label, children, withAddon }: InputGroupProps) => (
  <div className={classNames(style.inputGroup, { [style.withAddon]: withAddon }, className)}>
    {label ? <label>{label}</label> : null}
    {children}
  </div>
);

InputGroup.defaultProps = {
  className: "",
  withAddon: false,
};

InputGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  withAddon: PropTypes.bool,
};

export type ComplexInputValue =
  | string
  | number
  | File[]
  | boolean
  | undefined
  | moment.Moment
  | null
  | ReadonlyArray<string>
  | CurrencyInputValue;

export type ComplexInputProps = {
  input: {
    name: string;
    type:
      | "password"
      | "email"
      | "text"
      | "number"
      | "select"
      | "date"
      | "textarea"
      | "pills"
      | "file"
      | "checkbox"
      | "currency";
    icon?: string | ReactNode;
    placeholder?: string;
    disabled?: boolean;
    isSearchable?: boolean;
    clearable?: boolean;
    hasError?: boolean;
    hideIndicator?: boolean;
    isMulti?: boolean;
    multi?: boolean;
    async?: boolean;
    labelKey?: string;
    valueKey?: string;
    options?: any[];
    loadOptions?: FixLater;
    defaultOptions?: FixLater;
    cacheOptions?: FixLater;
    numberOfMonths?: FixLater;
    displayFormat?: string;
    autoFocus?: boolean;
    rows?: number;
    step?: number;
    min?: number;
    max?: number;
    autoComplete?: "on" | "email" | "off" | string;
    menuPlacement?: "top" | "auto" | "bottom";
    openDirection?: OpenDirectionShape;
  };
  value?: ComplexInputValue;
  style?: any;
  className?: string;
  onChange?: (name: string, value: ComplexInputValue) => void;
  onFocus?: () => void;
  onBlur?: (value?: ComplexInputValue) => void;
};

const ComplexInput = ({ input, value, style: styleProp, className, onChange, onFocus, onBlur }: ComplexInputProps) => {
  const [dateFocused, setDateFocused] = useState(false);
  let Component;
  const inputRef = useRef<FixLater>();
  const onTextChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(event.target.name, event.target.value);
  const onTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    onChange && onChange(event.target.name, event.target.value);
  const onNumberChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(event.target.name, parseFloat(event.target.value));
  const onFileChange = (files: File[]) => onChange && onChange(input.name, files);
  const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(input.name, event.target.checked);

  const handleBlur = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | CurrencyInputElement>) => {
    if (!onBlur) return;

    if (event.target.type === "currency") {
      return onBlur(event.target.value);
    }

    if (event.target.type === "number") {
      return onBlur(parseFloat(event.target.value));
    }

    if (event.target instanceof HTMLInputElement && event.target.type === "checkbox") {
      return onBlur(event.target.checked);
    }

    onBlur(event.target.value);
  };

  let iconStyle = {};
  if (typeof input.icon === "string") {
    iconStyle = {
      backgroundImage: `url(${input.icon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "0.625rem center",
      backgroundSize: "1.25rem",
      textIndent: "1.5rem",
      ...styleProp,
    };
  }

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const hasError = () => {
    return { [style.hasError]: input.hasError };
  };

  switch (input.type) {
    case "select":
      const selectClasses = classNames(
        style.selectInput,
        hasError(),
        { [style.hideDropdownIndicator]: input.hideIndicator },
        className,
      );
      const selectValue =
        typeof value === "object"
          ? value
          : Array.isArray(input.options)
          ? input.options.find((option) => option[input.valueKey || "value"] === value)
          : value;
      const commonProps = {
        ...input,
        className: selectClasses,
        classNamePrefix: "react-select",
        isMulti: input.multi || input.isMulti,
        onChange: (optionValue: FixLater) => onChange && onChange(input.name, optionValue),
        getOptionLabel: (option: FixLater) => option[input.labelKey || "label"],
        getOptionValue: (option: FixLater) => option[input.valueKey || "value"],
        placeholder: input.placeholder || "",
        isDisabled: !!input.disabled,
        isClearable: typeof input.clearable !== "undefined" ? input.clearable : true,
        styles: {
          menuPortal: (base: FixLater) => ({ ...base, zIndex: 9999 }),
        },
        isSearchable: input.isSearchable,
        menuPortalTarget: window.document.body,
        onBlur: handleBlur,
        value: selectValue,
        menuPlacement: input.menuPlacement,
      };
      Component = input.async ? (
        <AsyncSelect
          {...commonProps}
          loadOptions={input.loadOptions}
          defaultOptions={input.defaultOptions}
          cacheOptions={input.cacheOptions}
        />
      ) : (
        <Select {...commonProps} />
      );
      break;
    case "date":
      Component = (
        <div className={classNames(style.datePicker, hasError(), className)}>
          <SingleDatePicker
            noBorder
            hideKeyboardShortcutsPanel
            anchorDirection="left"
            openDirection={input.openDirection || "down"}
            id={`date-picker-${input.name}`}
            date={value ? moment(value as FixLater) : null}
            focused={dateFocused}
            onFocusChange={({ focused }) => setDateFocused(focused)}
            numberOfMonths={input.numberOfMonths || 1}
            displayFormat={input.displayFormat || "MMM D, YYYY"}
            placeholder={input.placeholder || ""}
            onDateChange={(date: moment.Moment | null) => date && onChange && onChange(input.name, date)}
            isOutsideRange={() => false}
          />
        </div>
      );
      break;
    case "textarea":
      Component = (
        <textarea
          className={classNames(style.input, hasError(), className)}
          ref={inputRef}
          name={input.name}
          value={value as FixLater}
          autoFocus={input.autoFocus}
          onChange={onTextAreaChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={!!input.disabled}
          rows={input.rows}
          placeholder={input.placeholder || ""}
        />
      );
      break;
    case "number":
      Component = (
        <input
          type={input.type}
          ref={inputRef}
          style={iconStyle}
          className={classNames(style.input, hasError(), className)}
          name={input.name}
          value={value as FixLater}
          autoFocus={input.autoFocus}
          onChange={onNumberChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={!!input.disabled}
          placeholder={input.placeholder || ""}
          step={input.step}
          min={input.min}
          max={input.max}
        />
      );
      break;
    case "pills":
      Component = <CreatableSelect {...input} className={classNames("select-input", className)} value={value} />;
      break;
    case "file":
      Component = (
        <Dropzone
          className={classNames(style.input, style.fileInputWrapper, className)}
          value={value as FixLater}
          placeholder={input.placeholder}
          icon={input.icon}
          onChange={onFileChange}
        />
      );
      break;
    case "checkbox":
      Component = (
        <input
          type={input.type}
          name={input.name}
          className={classNames(style.checkbox, className)}
          checked={value as boolean}
          autoFocus={input.autoFocus}
          onChange={onCheckboxChange}
          onBlur={handleBlur}
          disabled={!!input.disabled}
          placeholder={input.placeholder || ""}
        />
      );
      break;
    case "currency":
      Component = (
        <CurrencyInput
          type={input.type}
          ref={inputRef}
          style={iconStyle}
          className={classNames(style.input, hasError(), className)}
          name={input.name}
          value={value as FixLater}
          autoFocus={input.autoFocus}
          // TODO: CurrencyInput does not yet have an onChange prop. It could be added if needed.
          onFocus={handleFocus}
          onBlur={handleBlur}
          min={input.min}
          max={input.max}
          disabled={!!input.disabled}
          autoComplete={input.autoComplete || "off"}
          placeholder={input.placeholder || ""}
        />
      );
      break;
    default:
      Component = (
        <input
          type={input.type}
          ref={inputRef}
          style={iconStyle}
          className={classNames(style.input, hasError(), className)}
          name={input.name}
          value={value as FixLater}
          autoFocus={input.autoFocus}
          onChange={onTextChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          min={input.min}
          max={input.max}
          disabled={!!input.disabled}
          autoComplete={input.autoComplete || "off"}
          placeholder={input.placeholder || ""}
        />
      );
  }
  return Component;
};

export const TextInput = (options: any = {}) => {
  const input = Object.assign({}, options.input, { type: "text" });
  return <ComplexInput {...options} input={input} />;
};

export const PasswordInput = (options: any = {}) => {
  const input = Object.assign({}, options.input, { type: "password" });
  return <ComplexInput {...options} input={input} />;
};

export const NumberInput = (options: any = {}) => {
  const input = Object.assign({ step: 1 }, options.input, { type: "number" });
  return <ComplexInput {...options} input={input} />;
};

export const SelectInput = (options: any = {}) => {
  const input = Object.assign({}, options.input, { type: "select" });
  return <ComplexInput {...options} input={input} />;
};

export const DateInput = (options: any = {}) => {
  const input = Object.assign({}, options.input, { type: "date" });
  return <ComplexInput {...options} input={input} />;
};

export const DateTimeInput = ({ value, className, onChange }: any) => {
  const [date, setDate] = useState<moment.Moment | null>(value ? moment(value) : null);
  const [time, setTime] = useState<FixLater>(null);

  useDidMount(() => {
    if (!date) {
      return null;
    }
    const dateString = date.format("YYYY-MM-DD");
    const dateTimeString = `${dateString}T${time ? time.value : "00:00"}`;
    const isoString = dayjs(dateTimeString, "YYYY-MM-DDTHH:mm").toISOString();
    onChange(isoString);
  }, [date, time]);

  const activateEndTime = () => {
    setTime("12:00");
  };

  return (
    <div className={classNames(style.timeRangeInput, className)}>
      <DateInput
        input={{ name: "date", placeholder: "Select date" }}
        value={date}
        className={style.incidentDateInput}
        onChange={(name: string, value: moment.Moment) => setDate(value)}
      />
      <div className={style.incidentTimeSeparator}>at</div>
      {time ? (
        <SelectTime
          name="time"
          value={time}
          clearable={true}
          className={style.incidentTimeInput}
          onChange={(_, value) => setTime(value)}
        />
      ) : (
        <div className="align-center">
          <SecondaryButton size="small" onClick={activateEndTime}>
            Add time
          </SecondaryButton>
        </div>
      )}
    </div>
  );
};

DateTimeInput.propTypes = {
  value: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
  }),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export const TextAreaInput = (options: any = {}) => {
  const input = Object.assign({ rows: 3 }, options.input, {
    type: "textarea",
  });
  return <ComplexInput {...options} input={input} />;
};

export class PillsInput extends React.Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  };
  state = {
    inputValue: "",
  };
  handleChange = (value: FixLater) => {
    const { input, onChange }: FixLater = this.props;
    onChange(
      input.name,
      value.map(({ value: itemValue }: FixLater) => itemValue),
    );
  };
  handleInputChange = (inputValue: FixLater) => this.setState({ inputValue });
  handleKeyDown = (event: FixLater) => {
    const { inputValue } = this.state;
    const { input, value, onChange }: FixLater = this.props;
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        event.preventDefault();
        this.setState({ inputValue: "" });
        if (value.indexOf(inputValue) === -1) {
          onChange(input.name, [...value, inputValue]);
        }
    }
  };

  render() {
    const { value, onChange, input: baseInput }: FixLater = this.props;
    const { inputValue } = this.state;
    const input = {
      ...baseInput,
      type: "pills",
      inputValue: inputValue,
      isClearable: true,
      isMulti: true,
      menuIsOpen: false,
      onChange: this.handleChange,
      onInputChange: this.handleInputChange,
      onKeyDown: this.handleKeyDown,
      components: {
        DropdownIndicator: null,
      },
    };
    return (
      <ComplexInput
        {...this.props}
        onChange={onChange}
        input={input}
        value={value.map((label: string) => ({ label, value: label }))}
      />
    );
  }
}

/**
 * An input used to upload files
 * @example <TextInput input={Object} value={Any} onChange={Function} />
 * @param options
 * @returns {*}
 * @constructor
 */
export const FileInput = (options: any = {}) => {
  const input = Object.assign({}, options.input, { type: "file" });
  return <ComplexInput {...options} input={input} />;
};

export const CheckboxInput = (options: any = {}) => {
  const input = Object.assign({}, options.input, { type: "checkbox" });
  if (!options.label) {
    return <ComplexInput {...options} input={input} />;
  }

  return (
    <label className={style.checkboxLabel}>
      <ComplexInput {...options} input={input} />
      <span>{options.label}</span>
    </label>
  );
};

export default ComplexInput;

export const ProgressInput = ({ value, invertProgress, className }: any) => {
  const colorCode = !invertProgress
    ? {
        [style.progressBarOK]: value > 20,
        [style.progressBarWarn]: value <= 20,
        [style.progressBarDanger]: value <= 10,
      }
    : {
        [style.progressBarOK]: value < 80,
        [style.progressBarWarn]: value >= 80,
        [style.progressBarDanger]: value >= 90,
      };
  return (
    <div className={classNames(style.progress, className)}>
      <div className={style.progressBarBackground} />
      <div className={classNames(style.progressBar, colorCode)} style={{ width: `${value}%` }} />
    </div>
  );
};

ProgressInput.defaultProps = {
  invertProgress: false,
};

ProgressInput.propTypes = {
  value: PropTypes.number,
  invertProgress: PropTypes.bool,
  className: PropTypes.string,
};

export const MoneyInput = (options: any = {}) => {
  const input = Object.assign({}, options.input, { type: "currency" });
  return <ComplexInput {...options} input={input} />;
};

export { default as RadioInput } from "./RadioInput";
