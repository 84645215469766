import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { applicationMenu, MenuItem } from "./appMenuConfig";

type AppSidebarMenuProps = {
  setSidebarOpen: (value: boolean) => void;
};

export const AppSidebarMenu = ({ setSidebarOpen }: AppSidebarMenuProps) => {
  const handleClick = (item: MenuItem) => {
    setSidebarOpen(false);
    if (item.onClick) {
      item.onClick();
    }
  };

  return (
    <nav className="px-3">
      <div className="space-y-1">
        {applicationMenu.map((item) =>
          !item.children ? (
            <NavLink
              key={item.name}
              to={item.to || ""}
              onClick={() => handleClick(item)}
              className={({ isActive }) => {
                return classNames(
                  isActive ? "bg-gray-200 text-gray-900" : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                  "group flex items-center px-2 py-2 font-medium rounded-md",
                );
              }}
            >
              {({ isActive }) => (
                <>
                  <item.icon
                    className={classNames(
                      isActive ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
                      "mr-3 flex-shrink-0 h-6 w-6",
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </>
              )}
            </NavLink>
          ) : (
            <Disclosure as="div" key={item.name} className="space-y-1">
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(
                      open
                        ? "bg-gray-100 text-gray-900"
                        : "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group w-full flex items-center pl-2 pr-1 py-2 text-left font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-pantone-500",
                    )}
                  >
                    <item.icon
                      className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="flex-1">{item.name}</span>
                    <svg
                      className={classNames(
                        open ? "text-gray-400 rotate-90" : "text-gray-300",
                        "ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150",
                      )}
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                  </Disclosure.Button>
                  <Disclosure.Panel className="space-y-1">
                    {item.children?.map((subItem) => (
                      <NavLink
                        key={subItem.name}
                        to={subItem.to || ""}
                        onClick={() => handleClick(item)}
                        className="group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                      >
                        {subItem.name}
                      </NavLink>
                    ))}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ),
        )}
      </div>
    </nav>
  );
};
