import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useModal } from "effects";
import get from "lodash/get";
import queryString from "query-string";
import { showPopupFlag } from "lib/actions/userInterface";
import AssetsAPI from "lib/api/assets";
import Capabilities from "lib/constants/Capabilities";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { fetchAssets } from "store/reducers";
import { FixLater, TAsset } from "types";
import { SecondaryButton } from "components/Buttons";
import { Restrict, useRoleHasCapability } from "components/Restricted";
import StageHeader from "components/StageHeader";
import StatusPill from "components/StatusPill";
import { SmartTable, SmartTableNav, SmartTablePagination } from "components/Table/SmartTable";
import { TableLink } from "components/Table/TableLink/TableLink";
import { Anchor, Text } from "components/Typography";
import DropSelect from "components/dropdowns/DropSelect";
import { CreateAssetModal, ImportAssetButton } from "components/shared";
import PageStage from "components/stages/PageStage";

export const ListAssetsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { pathname: url } = useLocation();
  const { openModal } = useModal();
  const [paging, setPaging] = useState({
    pageStart: 0,
    pageLimit: 10,
  });
  const [fetching, setFetching] = useState(false);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({
    assigned: "assigned" in queryParams ? queryParams.assigned === "true" : null,
    calibrated: "calibrated" in queryParams ? queryParams.calibrated === "true" : null,
  });
  const [assets, setAssets] = useState<TAsset[]>([]);
  const rowData = useMemo(() => {
    return assets;
  }, [assets]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: ({ row }: FixLater) => get(row, "original.catalogProduct.name", ""),
        Cell: ({ row }: FixLater) => {
          const { id, catalogProduct } = row.original;
          return <TableLink to={`/apps/tracking/assets/${id}`}>{catalogProduct.name}</TableLink>;
        },
      },
      {
        Header: "Toolbox",
        accessor: ({ row }: FixLater) => get(row, "original.storageConfig.storageId", ""),
        Cell: ({ row }: FixLater) => {
          const { storageConfig } = row.original;
          return (
            storageConfig && (
              <Anchor to={`/apps/storage/toolboxes/${storageConfig.storageId}`}>{storageConfig.storageId}</Anchor>
            )
          );
        },
      },
      {
        Header: "Serial number",
        accessor: "serialNumber",
        Cell: ({ row }: FixLater) => {
          const asset = row.original;
          return (
            <Text
              canEdit={useRoleHasCapability(Capabilities.ASSET_UPDATE)}
              placeholder="Add serial number"
              value={asset.serialNumber}
              onConfirm={saveAssetSerialNumber(asset)}
            />
          );
        },
      },
      {
        Header: "Status",
        accessor: ({ row }: FixLater) => !!get(row, "original.assignment", null),
        Cell: ({ row }: FixLater) => {
          const { assignment } = row.original;
          return assignment ? (
            <StatusPill text="In Use" color="warning" />
          ) : (
            <StatusPill text="Stored" color="success" />
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    fetch(0, paging.pageLimit);
  }, [paging.pageLimit, query, filters]);

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    setFetching(true);
    try {
      const { results, paging } = await dispatch(
        fetchAssets({
          pageStart,
          pageLimit,
          assigned: filters.assigned === null ? undefined : filters.assigned,
          calibrated: filters.calibrated === null ? undefined : filters.calibrated,
          query: query || undefined,
        }),
      ).unwrap();
      setAssets(results);
      setPaging(paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setFetching(false);
  };

  const openCreateAssetModal = () => {
    openModal(<CreateAssetModal onSuccess={(asset) => navigate(`${url}/${asset.id}`)} />);
  };

  const saveAssetSerialNumber = (asset: TAsset) => {
    return async (serialNumber = "") => {
      try {
        await AssetsAPI.updateAsset(asset.id, {
          ...asset,
          serialNumber,
        });
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Saved Serial Number",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  return (
    <PageStage>
      <StageHeader
        title="Tool tracking"
        subtitle="Monitor your tools across all your toolboxes."
        action={
          <Restrict capability={Capabilities.ASSET_CREATE}>
            <ImportAssetButton onSuccess={() => fetch(0, paging.pageLimit)} />
            <SecondaryButton onClick={openCreateAssetModal} icon="/images/icons/plus_1.svg">
              Create tool
            </SecondaryButton>
          </Restrict>
        }
      />
      <SmartTableNav
        paging={paging}
        onPageChange={fetch}
        showQueryInput={true}
        queryPlaceholder="Search by name or serial number"
        onQueryChange={setQuery}
      >
        <DropSelect
          value={filters.assigned}
          text="Status"
          icon="/images/icons/caret_down_1.svg"
          onChange={(value) => setFilters({ ...filters, assigned: value })}
          items={[
            {
              label: "In Use",
              description: "Only show tools that are currently being used by someone",
              value: true,
            },
            {
              label: "Stored",
              description: "Only show tools that are currently stored",
              value: false,
            },
          ]}
        />
        <DropSelect
          value={filters.calibrated}
          text="Calibration"
          icon="/images/icons/caret_down_1.svg"
          onChange={(value) => setFilters({ ...filters, calibrated: value })}
          items={[
            {
              label: "Standard",
              description: "Only show tools that dont have calibration configured",
              value: false,
            },
            {
              label: "Calibrated",
              description: "Only show tools that have calibrated configured",
              value: true,
            },
          ]}
        />
      </SmartTableNav>
      <SmartTable loading={fetching} columns={columns} data={rowData} />
      <SmartTablePagination paging={paging} onChange={fetch} />
    </PageStage>
  );
};
