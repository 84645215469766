//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import { useSelector } from "store";
import StatusPill from "components/StatusPill";

const ShopItemStockIndicator = ({ itemID, appearance }) => {
  const item = useSelector(getStoreItemByID(itemID));

  if (!item) {
    return null;
  }

  switch (appearance) {
    case "compact":
    case "normal":
      return item.quantity <= 0 ? (
        <StatusPill color="danger" text="OUT OF STOCK" />
      ) : item.quantity <= 1 ? (
        <StatusPill color="warning" text="LOW STOCK" />
      ) : (
        <StatusPill color="success" text="IN STOCK" />
      );
    default:
      return null;
  }
};

ShopItemStockIndicator.defaultProps = {
  appearance: "normal",
};

ShopItemStockIndicator.propTypes = {
  appearance: PropTypes.oneOf(["compact", "normal"]),
  itemID: PropTypes.number,
};

export default ShopItemStockIndicator;
