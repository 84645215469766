//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import withReports from "../../../../hocs/withReports";
import Api from "../../../../lib/api/reports";
import ListReport from "./ListReport";

class ListReportContainer extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    evidence: null,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.requestReportEvidence(match.params.reportID);
  }

  onDownload = () => {
    const { match } = this.props;
    const reportId = match.params.reportID;
    let filename = `automify-report-${reportId}.html`;
    Api.downloadResult(reportId, filename);
  };

  render() {
    const reportId = this.props.match.params.reportID;
    return <ListReport reportId={reportId} onDownload={this.onDownload} />;
  }
}

export default withReports(ListReportContainer);
