import React, { FormEvent, useState } from "react";
import { useModal } from "effects";
import { InputGroup, TextInput } from "components/Input";
import { Paragraph } from "components/Typography";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { PrimaryButton, SecondaryButton } from "components/shared/buttons";
import { ButtonGroup } from "components/shared/containers";

type ConfirmWithReasonModalProps = {
  title?: string;
  description?: string;
  actionText?: string;
  placeholder?: string;
  onConfirm?: (reason: string) => void;
  onCancel?: () => void;
};

export const ConfirmWithReasonModal = ({
  title = "Are you sure?",
  description = "Please give a reason to confirm that you would like to do this action.",
  actionText = "Confirm",
  placeholder = "Enter a reason",
  onConfirm,
  onCancel,
}: ConfirmWithReasonModalProps) => {
  const { closeModal } = useModal();
  const [reason, setReason] = useState("");

  const handleConfirm = (event?: FormEvent) => {
    event?.preventDefault();
    closeModal();
    if (onConfirm && reason) {
      onConfirm(reason);
    }
  };

  const handleCancel = () => {
    closeModal();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <form onSubmit={handleConfirm}>
      <Modal>
        <ModalHeader title={title} />
        {description && (
          <ModalContent>
            <Paragraph>{description}</Paragraph>
            <InputGroup>
              <TextInput
                input={{ name: "reason", placeholder }}
                value={reason}
                onChange={(name: string, value: string) => setReason(value)}
              />
            </InputGroup>
          </ModalContent>
        )}
        <ModalFooter alignRight>
          <ButtonGroup>
            <PrimaryButton type="submit" onClick={handleConfirm} disabled={!reason} width="">
              {actionText}
            </PrimaryButton>
            <SecondaryButton width="" onClick={handleCancel}>
              Cancel
            </SecondaryButton>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </form>
  );
};
