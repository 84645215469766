//@ts-nocheck
import Api from "./api";

export default class Company extends Api {
  /**
   * Get the company branding settings
   * @async
   * @returns {Promise<Object>}
   */
  static async getBrandingSettings() {
    const response = await this.get("/company/branding").send();
    return response.body;
  }

  /**
   * Get the company public branding settings
   * @async
   * @returns {Promise<Object>}
   */
  static async getPublicBrandingSettings() {
    const response = await this.get("/company/public-branding").send();
    return response.body;
  }

  /**
   * Update the company branding settings
   * @async
   * @param {Object} branding
   * @returns {Promise<Object>}
   */
  static async updateBrandingSettings(branding = {}) {
    return await this.put("/company/branding").send(branding);
  }
}
