//@ts-nocheck
import emojiRegex from "emoji-regex/RGI_Emoji.js";
import orderBy from "lodash/orderBy";
import style from "./sheet.module.scss";

export const getCaretCoordinates = () => {
  let x, y;
  const selection = window.getSelection();
  if (selection.rangeCount !== 0) {
    const range = selection.getRangeAt(0).cloneRange();
    range.collapse(false);
    const rect = range.getClientRects()[0];
    if (rect) {
      x = rect.left;
      y = rect.top;
    }
  }
  return { x, y };
};

export const setCaretToEnd = (element) => {
  const range = document.createRange();
  const selection = window.getSelection();
  range.selectNodeContents(element);
  range.collapse(false);
  selection.removeAllRanges();
  selection.addRange(range);
  element.focus();
};

export const wrapEmojis = (text) => {
  return text.replace(emojiRegex(), (emoji) => {
    return `<span class="${style.emoji}">${emoji}</span>`;
  });
};

export const orderPageBlocks = (pageBlocks) => {
  return orderBy(Array.isArray(pageBlocks) ? pageBlocks : Object.values(pageBlocks), "order", "asc");
};

/**
 * Get the DOM node for a block
 * @param {string} blockID
 * @return {Element|null}
 */
export const getDOMNodeForBlockID = (blockID) => {
  const blockNode = document.getElementById(blockID);
  return blockNode || null;
};

/**
 *
 * @param {Object} pageBlocks
 * @param {string} siblingBlockID
 * @param {Block} newBlock
 * @return {Array.<PageBlock>}
 */
export const insertBlockAfterSibling = (pageBlocks, siblingBlockID, newBlock) => {
  const siblingPageBlock = pageBlocks[siblingBlockID];
  const currentBlockOrder = siblingPageBlock.order;

  // Order the existing page block
  let newPageBlocks = orderPageBlocks(pageBlocks).map((pageBlock) => ({
    ...pageBlock,
    order: pageBlock.order > currentBlockOrder ? pageBlock.order + 1 : pageBlock.order,
  }));

  // Add the new page block
  newPageBlocks.push({
    ...siblingPageBlock,
    blockId: newBlock.id,
    order: currentBlockOrder + 1,
    block: newBlock,
  });

  // Reset the order numbers now
  return orderPageBlocks(newPageBlocks).map((block, index) => ({
    ...block,
    order: index,
  }));
};

/**
 * Add a block to the page blocks
 * @param {Object} pageBlocks
 * @param {Block|null} block
 * @return {Array.<PageBlock>}
 */
export const updateBlock = (pageBlocks, block) => {
  return Object.values({
    ...pageBlocks,
    [block.id]: {
      ...pageBlocks[block.id],
      block: block,
    },
  });
};

/**
 * Remove a block from the page blocks
 * @param {Object} pageBlocks
 * @param {string} blockID
 * @return {Array.<PageBlock>}
 */
export const removeBlockFromPageBlocks = (pageBlocks, blockID) => {
  const newPageBlocks = {
    ...pageBlocks,
  };
  delete newPageBlocks[blockID];
  return Object.values(orderPageBlocks(newPageBlocks));
};
