import Apps from "lib/constants/Apps";
import Capabilities from "lib/constants/Capabilities";
import { Restrict } from "components/Restricted";
import StageMenuItem from "components/StageMenuItem";
import { VSpace, Heading } from "components/shared";
import PageStage from "components/stages/PageStage";

export const ConfigurationPage = () => {
  return (
    <PageStage>
      <VSpace>
        <Restrict app={Apps.PRODUCT_CATALOGUE_MANAGEMENT}>
          <Heading level="h2">Global Configurations</Heading>
          <StageMenuItem
            title="Manufacturers"
            path="/configuration/manufacturers"
            description="Manage product manufacturers across the Automify software"
            icon="/images/icons/maintenance_1.svg"
          />
        </Restrict>

        <Heading level="h2">Company Configurations</Heading>
        <VSpace gap="gap-0.5">
          <Restrict capability={Capabilities.CONFIGURE_TEAM}>
            <StageMenuItem
              title="Team members"
              path="/configuration/users"
              description="View and manage workspace users"
              icon="/images/icons/workforce_2.svg"
            />
          </Restrict>
          <Restrict capability={Capabilities.CONFIGURE_ROLES}>
            <StageMenuItem
              title="Roles"
              path="/configuration/roles"
              description="View and manage your user roles"
              icon="/images/icons/privacy.svg"
            />
          </Restrict>
          <Restrict capability={Capabilities.CONFIGURE_BRANDING}>
            <StageMenuItem
              title="Branding"
              path="/configuration/branding"
              description="Configure your workspace branding"
              icon="/images/icons/brand_colours_1.svg"
            />
          </Restrict>
          <Restrict capability={Capabilities.CONFIGURE_KEYCARDS}>
            <StageMenuItem
              title="Keycards"
              path="/configuration/keycards"
              description="View and manage your company keycards"
              icon="/images/icons/keycard_1.svg"
            />
          </Restrict>
          <Restrict capability={Capabilities.CONFIGURE_LOCATIONS}>
            <StageMenuItem
              title="Locations"
              path="/configuration/locations"
              description="View and manage existing locations"
              icon="/images/icons/location.svg"
            />
          </Restrict>
        </VSpace>
      </VSpace>
    </PageStage>
  );
};
