import { useState } from "react";
import { usePopper } from "react-popper";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { showPopupFlag } from "lib/actions/userInterface";
import { useDispatch, useSelector } from "store";
import { selectAssetByID } from "store/reducers";
import { SecondaryButton } from "components/shared/buttons";

type AssetSlideOverOptionsMenuProps = {
  assetId: number;
};

export const AssetSlideOverOptionsMenu = ({ assetId }: AssetSlideOverOptionsMenuProps) => {
  const dispatch = useDispatch();
  const entity = useSelector(selectAssetByID(assetId));

  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "left-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [5, 5],
        },
      },
    ],
  });

  if (!entity) {
    return null;
  }

  const copyAssetUrl = async () => {
    if (entity && navigator.clipboard) {
      await navigator.clipboard.writeText(`${window.location.origin}/apps/tracking/assets/${entity.id}`);
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Copied asset link",
        }),
      );
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        ref={setReferenceElement}
        as={SecondaryButton}
        className="inline-flex items-center rounded-md border border-gray-300 bg-white px-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span className="sr-only">Open options menu</span>
        <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Menu.Items
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
        className="absolute right-0 mt-2 z-10 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <Link
                to={`/apps/tracking/assets/${entity.id}`}
                target="_blank"
                className={classNames(
                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                  "block px-4 py-2 text-sm",
                )}
              >
                View tool
              </Link>
            )}
          </Menu.Item>
          {navigator.clipboard && (
            <Menu.Item>
              {({ active }) => (
                <span
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block cursor-pointer px-4 py-2 text-sm",
                  )}
                  onClick={copyAssetUrl}
                >
                  Copy tool link
                </span>
              )}
            </Menu.Item>
          )}
        </div>
      </Menu.Items>
    </Menu>
  );
};
