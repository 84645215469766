import React from "react";
import { Moment } from "moment";
import { DateInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { TextBody } from "components/shared/typography";

type NextDueProps = {
  value?: string | Moment;
  onChange: (value: Moment) => void;
};

export const NextDue = ({ value, onChange }: NextDueProps) => {
  return (
    <FieldGroup label="When is calibration next due?">
      <TextBody textColor="text-gray-400">Specify when the day when the next calibration is due</TextBody>
      <DateInput
        input={{
          name: "nextDue",
          openDirection: "up",
          placeholder: "Pick a date",
        }}
        value={value}
        onChange={(_: string, val: Moment) => onChange(val)}
      />
    </FieldGroup>
  );
};
