//@ts-nocheck
import Api from "./api";

export default class AlertAPI extends Api {
  /**
   * Get alerts
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, metadata: Object}>}
   */
  static async getAlerts(queryParams = {}) {
    const response = await this.get("/alerts").query(queryParams);
    return response.body;
  }

  /**
   * Get a alert
   * @async
   * @param {string} alertID
   * @returns {Promise.<Object>}
   */
  static async getAlert(alertID) {
    const response = await this.get(`/alerts/${alertID}`);
    return response.body.data;
  }

  /**
   * Create alert
   * @async
   * @param {Object} alert
   * @returns {Promise.<Object>}
   */
  static async createAlert(alert) {
    const response = await this.post("/alerts").send(alert);
    return response.body.data;
  }

  /**
   * Update alert
   * @param {string} alertID
   * @param {Object} alert
   * @returns {Promise.<Object>}
   */
  static async updateAlert(alertID, alert) {
    const response = await this.put(`/alerts/${alertID}`).send(alert);
    return response.body.data;
  }

  /**
   * Delete alert
   * @param {string} alertID
   * @returns {Promise<void>}
   */
  static async deleteAlert(alertID) {
    await this.delete(`/alerts/${alertID}`).send();
  }

  /**
   * Create alert rule
   * @async
   * @param {string} alertID
   * @param {string} eventKey
   * @returns {Promise.<Object>}
   */
  static async createAlertRule(alertID, eventKey) {
    const response = await this.post(`/alerts/${alertID}/rules`).send({
      key: eventKey,
    });
    return response.body.data;
  }

  /**
   * Delete alert rule
   * @param {string} alertID
   * @param {string} ruleID
   * @returns {Promise<void>}
   */
  static async deleteAlertRule(alertID, ruleID) {
    await this.delete(`/alerts/${alertID}/rules/${ruleID}`).send();
  }

  /**
   * Create alert recipient
   * @async
   * @param {string} alertID
   * @param {Object} recipient
   * @returns {Promise.<Object>}
   */
  static async createAlertRecipient(alertID, recipient) {
    const response = await this.post(`/alerts/${alertID}/recipients`).send(recipient);
    return response.body.data;
  }

  /**
   * Update alert recipient
   * @param {string} alertID
   * @param {Object} recipient
   * @returns {Promise.<Object>}
   */
  static async updateAlertRecipient(alertID, recipient) {
    const response = await this.put(`/alerts/${alertID}/recipients/${recipient.id}`).send(recipient);
    return response.body.data;
  }

  /**
   * Delete alert recipient
   * @param {string} alertID
   * @param {string} recipientID
   * @returns {Promise<void>}
   */
  static async deleteAlertRecipient(alertID, recipientID) {
    await this.delete(`/alerts/${alertID}/recipients/${recipientID}`).send();
  }
}
