//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "./charts.module.scss";

export const ProgressRing = ({ percentage, radius = 90, strokeWidth = 6, className }) => {
  const config = {
    viewBox: `0 0 ${radius * 2} ${radius * 2}`,
    x: radius,
    y: radius,
  };
  const normalisedRadius = radius - strokeWidth * 2;
  const circumference = 2 * Math.PI * normalisedRadius;
  const offset = circumference - (percentage / 100) * circumference;
  return (
    <svg
      className={classNames(style.progressRing, className)}
      height={radius * 2}
      width={radius * 2}
      viewBox={config.viewBox}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r={normalisedRadius}
        cx={config.x}
        cy={config.y}
        strokeWidth={strokeWidth}
        fill="transparent"
        className={style.ringStrokeBackground}
      />
      <circle
        r={normalisedRadius}
        cx={config.x}
        cy={config.y}
        strokeWidth={strokeWidth}
        fill="transparent"
        className={style.ringStroke}
        strokeDasharray={`${circumference} ${circumference}`}
        strokeDashoffset={offset}
      />
    </svg>
  );
};

ProgressRing.defaultProps = {};

ProgressRing.propTypes = {
  className: PropTypes.string,
};
