import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useModal } from "effects";
import Capabilities from "lib/constants/Capabilities";
import { TStorage } from "types";
import { SecondaryButton } from "components/Buttons";
import { Restrict } from "components/Restricted";
import StageHeader from "components/StageHeader";
import { CreateStorageModal, ToolStorageTable } from "components/shared";
import PageStage from "components/stages/PageStage";

const ListStorages = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { pathname: url } = useLocation();

  const createStorage = () => {
    openModal(
      <CreateStorageModal
        onSuccess={(storage: TStorage) => {
          navigate(`${url}/${storage.id}`);
        }}
      />,
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Toolboxes"
        subtitle="Manage your tool storage by configuring inventory, access control, and settings."
        action={
          <Restrict capability={Capabilities.STORAGE_CREATE}>
            <SecondaryButton onClick={createStorage} icon="/images/icons/plus_1.svg">
              Create toolbox
            </SecondaryButton>
          </Restrict>
        }
      />
      <ToolStorageTable />
    </PageStage>
  );
};

export default ListStorages;
