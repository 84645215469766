//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "components/Buttons/Button";
import style from "pages/authentication/components/AuthSubmitButton.module.scss";

const AuthNextButton = ({ children, isDisabled, className, onClick }) => {
  return (
    <Button type="button" disabled={isDisabled} className={classNames(style.submitButton, className)} onClick={onClick}>
      {children}
    </Button>
  );
};

AuthNextButton.defaultProps = {};

AuthNextButton.propTypes = {
  children: PropTypes.any,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default AuthNextButton;
