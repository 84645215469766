import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { ThunkError, handleThunkError } from "store/utils/thunk";
import {
  CreateAssetIssueRequest,
  DataResponse,
  FetchAssetIssuesRequest,
  PagedDataResponse,
  Pagination,
  TAssetIssue,
  UUID,
  UpdateAssetIssueRequest,
  UpdateAssetIssueResponse,
} from "types";

export const fetchAssetIssues = createAsyncThunk(
  "assetIssues/fetchAssetIssues",
  async (request: FetchAssetIssuesRequest): Promise<{ results: TAssetIssue[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TAssetIssue[]>>("/asset_issues", request);
    return { results: data, paging: metadata };
  },
);

export const fetchAssetIssueById = createAsyncThunk(
  "assetIssues/fetchAssetIssueById",
  async (id: UUID): Promise<TAssetIssue> => {
    const { data } = await API.GET<DataResponse<TAssetIssue>>(`/asset_issues/${id}`);
    return data;
  },
);

export const createAssetIssue = createAsyncThunk(
  "assetIssues/createAssetIssue",
  async (request: CreateAssetIssueRequest): Promise<TAssetIssue> => {
    const { data } = await API.POST<CreateAssetIssueRequest, DataResponse<TAssetIssue>>("/asset_issues", request);
    return data;
  },
);

export const updateAssetIssue = createAsyncThunk<UpdateAssetIssueResponse["data"], UpdateAssetIssueRequest, ThunkError>(
  "assetIssues/updateAssetIssue",
  async (request: UpdateAssetIssueRequest, { rejectWithValue }): Promise<TAssetIssue> => {
    try {
      const { data } = await API.PUT<UpdateAssetIssueRequest, UpdateAssetIssueResponse>(
        `/asset_issues/${request.id}`,
        request,
      );
      return data;
    } catch (err) {
      return handleThunkError(rejectWithValue, err);
    }
  },
);

export const deleteAssetIssue = createAsyncThunk("assetIssues/deleteAssetIssue", async (id: UUID): Promise<void> => {
  await API.DELETE(`/asset_issues/${id}`);
});
