//@ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ButtonGroup, PrimaryButton, SecondaryButton } from "components/Buttons";
import { InputGroup, InputGroups, TextAreaInput, TextInput } from "components/Input";
import StageHeader from "components/StageHeader";
import { LocationSelector } from "components/inputs/LocationSelector/LocationSelector";
import PageStage from "components/stages/PageStage";

class RegisterReaderForm extends React.Component {
  static propTypes = {
    reader: PropTypes.object,
    error: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
  };

  render() {
    const { reader, error, onChange, onSubmit } = this.props;
    const breadcrumb = [
      { label: "Configuration", href: "/configuration" },
      { label: "Readers", href: "/configuration/readers" },
      "Register reader",
    ];
    return (
      <PageStage>
        <StageHeader title="Register a location" breadcrumb={breadcrumb} />
        <p>Enter information about the location</p>
        <InputGroups>
          <InputGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter reader name",
              }}
              value={reader.name}
              onChange={onChange}
            />
          </InputGroup>
          <InputGroup label="MAC address">
            <TextInput
              input={{
                name: "macAddress",
                placeholder: "Enter unique MAC address",
              }}
              value={reader.macAddress}
              onChange={onChange}
            />
          </InputGroup>
          <InputGroup label="Summary">
            <TextInput
              input={{
                name: "summary",
                placeholder: "Enter reader summary",
              }}
              value={reader.summary}
              onChange={onChange}
            />
          </InputGroup>
          <InputGroup label="Description">
            <TextAreaInput
              input={{
                name: "description",
                placeholder: "Enter reader description",
              }}
              value={reader.description}
              onChange={onChange}
            />
          </InputGroup>
          <InputGroup label="Location">
            <LocationSelector name="location" value={reader.location} onChange={onChange} />
          </InputGroup>
        </InputGroups>
        {error ? <p className="text-error">{error.message}</p> : null}
        <ButtonGroup alignRight={true}>
          <Link to="/configuration/readers">
            <SecondaryButton>Cancel</SecondaryButton>
          </Link>
          <PrimaryButton onClick={onSubmit}>Register reader</PrimaryButton>
        </ButtonGroup>
      </PageStage>
    );
  }
}

export default RegisterReaderForm;
