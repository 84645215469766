import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  DataResponse,
  PagedDataResponse,
  Pagination,
  TVehicle,
  UUID,
  CreateVehicleRequest,
  UpdateVehicleRequest,
  FetchVehiclesRequest,
} from "types";

export const fetchVehicles = createAsyncThunk(
  "vehicles/fetchVehicles",
  async (request: FetchVehiclesRequest): Promise<{ results: TVehicle[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TVehicle[]>>("/vehicles", request);
    return { results: data, paging: metadata };
  },
);

export const fetchVehicleById = createAsyncThunk("vehicles/fetchVehicleById", async (id: UUID): Promise<TVehicle> => {
  const { data } = await API.GET<DataResponse<TVehicle>>(`/vehicles/${id}`);
  return data;
});

export const createVehicle = createAsyncThunk(
  "vehicles/createVehicle",
  async (request: CreateVehicleRequest): Promise<TVehicle> => {
    const { data } = await API.POST<CreateVehicleRequest, DataResponse<TVehicle>>("/vehicles", request);
    return data;
  },
);

export const updateVehicle = createAsyncThunk(
  "vehicles/updateVehicle",
  async (request: UpdateVehicleRequest): Promise<TVehicle> => {
    const { data } = await API.PUT<UpdateVehicleRequest, DataResponse<TVehicle>>(`/vehicles/${request.id}`, request);
    return data;
  },
);

export const deleteVehicle = createAsyncThunk("vehicles/deleteVehicle", async (id: UUID): Promise<void> => {
  await API.DELETE(`/vehicles/${id}`);
});
