import { ReactNode } from "react";
import styled from "styled-components";
import InlineInput from "components/Typography/InlineInput";

const StyledText = styled.h2`
  color: #0f172a;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0;
  line-height: 1.3333333;
`;

type HeaderProps = {
  id?: string;
  value?: string;
  canEdit?: boolean;
  onConfirm?: (value: string) => void;
  onClick?: () => void;
  placeholder?: string;
  children?: ReactNode;
  className?: string;
  inputClassName?: string;
};

export const Header = ({
  id,
  value,
  canEdit,
  onConfirm,
  onClick,
  placeholder,
  children,
  className,
  inputClassName,
}: HeaderProps) => {
  return (
    <StyledText id={id} className={className}>
      {canEdit ? (
        <InlineInput
          input={{ type: "text", placeholder }}
          className={inputClassName}
          defaultValue={value}
          onConfirm={onConfirm}
          onClick={onClick}
        />
      ) : (
        value || children
      )}
    </StyledText>
  );
};

export default Header;
