import React, { useEffect, useMemo, useState } from "react";
import cuid from "cuid";
import styled from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectAssetByID } from "store/reducers";
import { updateStorageAsset } from "store/reducers/assets/storageAssets.thunks";
import { FixLater } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { FileInput, NumberInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { DrawerSVG } from "components/shared/features/svgs";
import { VSpace } from "components/shared/layouts";

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`;

const StyledModal = styled(Modal)`
  min-width: calc(100% - 3rem);
  max-width: 50rem;
  margin: 0 1.5rem;
`;

export const ConfigureStorageAssetLayoutModal = ({ modal, onClose }: FixLater) => {
  const dispatch = useDispatch();
  const { drawer, drawerAssets = [], onSuccess } = modal.props;
  const asset = useSelector(selectAssetByID(modal.props.assetId));
  const storageAsset = asset?.storageConfig;

  const [values, setValues] = useState({
    viewBox: "",
    irSensorNumber: storageAsset?.irSensorNumber,
    irSensorThreshold: storageAsset?.irSensorThreshold,
    irSensorBoardId: storageAsset?.irSensorBoardId,
  });
  const [files, setFiles] = useState([]);
  const [fullSvg, setFullSvg] = useState(null);
  const [shapes, setShapes] = useState([]);
  const [assetShape, setAssetShape] = useState<FixLater>(null);
  const [groupShape, setGroupShape] = useState(null);
  const drawerShapes = useMemo(() => {
    return shapes.map((shape: FixLater): FixLater => {
      const asset = drawerAssets.find((drawerAsset: FixLater) => shape.path === drawerAsset.storageConfig?.svgPath);
      return {
        ...shape,
        located: !!asset,
      };
    });
  }, [shapes]);

  const drawerShape = useMemo(() => {
    return shapes.find((shape: FixLater) => shape.path === drawer.svgPath);
  }, [shapes]);
  const valid = fullSvg && assetShape;

  useEffect(() => {
    if (!storageAsset) return;
    if (!fullSvg) return;
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(fullSvg, "image/svg+xml");
    const svgElement = svgDoc.querySelector("svg");
    if (!svgElement) {
      handlePopupFlagError(null, "No SVG element found in the file");
      return;
    }

    // Pull out the SVG height, width, and viewport
    const viewBox = svgElement.getAttribute("viewBox");
    setValues({
      ...values,
      viewBox: viewBox || "0 0 1000 1000",
    });

    // Now extract all the path shapes.
    // @ts-ignore
    setGroupShape(svgDoc.querySelector("g") || null);

    // @ts-ignore
    const shapes: FixLater = [...svgDoc.querySelectorAll("path")].map((shape) => {
      const pathLength = shape.getTotalLength();
      return {
        id: cuid(),
        path: shape.getAttribute("d"),
        pathLength,
      };
    });
    setShapes(shapes);

    if (storageAsset.svgPath) {
      const defaultShape = shapes.find((shape: FixLater) => shape.path === storageAsset.svgPath);
      if (defaultShape) {
        setAssetShape(defaultShape);
      }
    }
  }, [storageAsset, fullSvg]);

  useEffect(() => {
    if (drawer.fullSvg) {
      setFullSvg(drawer.fullSvg);
    }
  }, []);

  const handleUpload = async (_: string, files: FixLater) => {
    try {
      setFiles(files);
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const svgText: FixLater = reader.result;
        setFullSvg(svgText);
      };
      reader.readAsText(file);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleSubmit = async (event: FixLater) => {
    try {
      event.preventDefault();
      if (!storageAsset) return;

      const newStorageAsset = {
        ...storageAsset,
        svgPath: assetShape.path,
        irSensorNumber: values.irSensorNumber,
        irSensorThreshold: values.irSensorThreshold,
        irSensorBoardId: values.irSensorBoardId,
      };

      await dispatch(updateStorageAsset(newStorageAsset)).unwrap();

      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Tool saved",
        }),
      );
      onClose();
      onSuccess && onSuccess(assetShape.path);
    } catch (err) {
      console.log(err);
      handlePopupFlagError(err);
    }
  };

  const handleNumberChange = (name: string, value: FixLater) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClear = () => {
    setValues((prevState) => ({
      ...prevState,
      irSensorNumber: NaN,
      irSensorThreshold: NaN,
      irSensorBoardId: NaN,
    }));
  };

  const handleShapeClick = (shape: FixLater) => {
    setAssetShape(shape);
  };

  if (!storageAsset) return null;

  return (
    <StyledModal>
      <form onSubmit={handleSubmit}>
        <ModalHeader title="Configure tool layout" />
        <ModalContent>
          <VSpace>
            <Horizontal>
              <FieldGroup label="Sensor board">
                <NumberInput
                  input={{
                    name: "irSensorBoardId",
                    step: "1",
                    placeholder: "Enter sensor board",
                  }}
                  value={values.irSensorBoardId}
                  onChange={handleNumberChange}
                />
              </FieldGroup>
              <FieldGroup label="Sensor number">
                <NumberInput
                  input={{
                    name: "irSensorNumber",
                    step: "1",
                    placeholder: "Enter sensor number",
                  }}
                  value={values.irSensorNumber}
                  onChange={handleNumberChange}
                />
              </FieldGroup>
              <FieldGroup label="Sensor threshold">
                <NumberInput
                  input={{
                    name: "irSensorThreshold",
                    step: "1",
                    placeholder: "Enter sensor threshold",
                  }}
                  value={values.irSensorThreshold}
                  onChange={handleNumberChange}
                />
              </FieldGroup>
            </Horizontal>
            <Horizontal>
              <div>
                <SecondaryButton onClick={handleClear}>Clear config</SecondaryButton>
              </div>
            </Horizontal>
            {shapes.length ? (
              <FieldGroup label="Select tool shape">
                <DrawerSVG
                  viewBox={values.viewBox}
                  shapes={drawerShapes}
                  value={[assetShape?.id]}
                  drawerShape={drawerShape}
                  groupShape={groupShape}
                  onClick={handleShapeClick}
                />
              </FieldGroup>
            ) : (
              <FieldGroup label="Upload layout">
                <FileInput
                  input={{
                    name: "file",
                    placeholder: "Upload a SVG file or drag and drop",
                  }}
                  value={files}
                  onChange={handleUpload}
                />
              </FieldGroup>
            )}
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit" disabled={!valid} onClick={handleSubmit}>
            Save changes
          </PrimaryButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </ModalFooter>
      </form>
    </StyledModal>
  );
};
