//@ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestReportComponents } from "lib/actions/reportBuilder";
import { requestReport } from "lib/actions/reports";
import { getReport } from "lib/reducers/reports.selectors";
import Loading from "components/Loading";
import PageStage from "components/stages/PageStage";
import ReportExportsPage from "pages/reporting/reports/ReportPage/components/ReportExportsPage";
import ReportSettingsPage from "pages/reporting/reports/ReportPage/components/ReportSettingsPage";
import ReportTemplatePage from "pages/reporting/reports/ReportPage/components/ReportTemplatePage";

const ReportPage = () => {
  const dispatch = useDispatch();
  const report = useSelector(getReport);
  const { reportID, section } = useParams();
  useEffect(() => dispatch(requestReportComponents({ pageLimit: 999 })), []);
  useEffect(() => {
    dispatch(requestReport(reportID));
  }, []);

  const renderPage = () => {
    switch (section) {
      case "exports":
        return <ReportExportsPage report={report} />;
      case "template":
        return <ReportTemplatePage report={report} />;
      case "settings":
        return <ReportSettingsPage report={report} />;
    }
  };

  return <PageStage>{report ? renderPage() : <Loading size="large" />}</PageStage>;
};

export default ReportPage;
