//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { SelectInput } from "components/Input";
import { SmartTable } from "components/Table/SmartTable";

const SelectColumnTypesInput = ({ name, value, columnTypeOptions, onChange }) => {
  const input = {
    name,
    placeholder: "Select column type",
    options: columnTypeOptions,
  };
  return <SelectInput input={input} value={value} onChange={onChange} />;
};

const SelectColumnTypes = ({ rows, columnTypeOptions, onConfirm }) => {
  const [columnTypes, setColumnTypes] = useState({});
  const rowData = useMemo(() => {
    if (rows.length === 0) {
      return [];
    }
    const headerRow = Object.keys(rows[0]);
    return headerRow.map((value) => {
      return {
        name: value,
        type: null,
      };
    });
  }, [rows]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Column name",
        accessor: ({ row }) => get(row, "original.name", ""),
        Cell: ({ row }) => {
          const { name } = row.original;
          return <div>{name}</div>;
        },
      },
      {
        Header: "Column type",
        Cell: ({ row }) => {
          const { name } = row.original;
          const handleChange = (name, value) => {
            setColumnTypes((prevValues) => ({
              ...prevValues,
              [name]: value ? value.value : null,
            }));
          };
          return (
            <SelectColumnTypesInput
              name={name}
              value={columnTypes[name]}
              columnTypeOptions={columnTypeOptions}
              onChange={handleChange}
            />
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    onConfirm && onConfirm(columnTypes);
  }, [columnTypes]);

  return <SmartTable columns={columns} data={rowData} />;
};
SelectColumnTypes.defaultProps = {};

SelectColumnTypes.propTypes = {
  className: PropTypes.string,
  onConfirm: PropTypes.func,
};

export default SelectColumnTypes;
