import Lookup, { LookupItem } from "lib/constants/Lookup";
import { VehicleCategory, VehicleType } from "types";

export interface VehicleLookup extends LookupItem<VehicleType> {
  category: VehicleCategory;
}

class VehicleTypes extends Lookup<VehicleType, { category: VehicleCategory }> {
  constructor() {
    super();
    this.items = [
      {
        value: "miscellaneous",
        label: "Miscellaneous",
        description: "",
        category: "miscellaneous",
      },
      {
        value: "plane",
        label: "Aircraft",
        description: "",
        category: "aircraft",
      },
      {
        value: "helicopter",
        label: "Helicopter",
        description: "",
        category: "aircraft",
      },
      {
        value: "van",
        label: "Van",
        description: "",
        category: "motor_vehicle",
      },
    ];
  }
}

export default new VehicleTypes();
