//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import StorageInput from "components/inputs/StorageInput";
import style from "../textEditorDropdown.module.scss";

const StorageFilter = ({ filter, className, onChange }) => {
  return (
    <FieldGroup label={filter.name} className={style.contentInputGroup}>
      <StorageInput name={filter.type} value={filter.value} onChange={onChange} className={style.contentInput} />
    </FieldGroup>
  );
};

StorageFilter.defaultProps = {};

StorageFilter.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default StorageFilter;
