//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Resolve from "lib/Resolve";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

class SelectRoleInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    appearance: PropTypes.oneOf(["normal", "inline"]),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    appearance: "normal",
    placeholder: "Select role",
  };

  render() {
    const { name, value, placeholder, onChange, onBlur, className, appearance, disabled } = this.props;
    const input = {
      type: "select",
      name,
      placeholder,
      labelKey: "name",
      valueKey: "id",
      options: Resolve.getRoles(),
      disabled,
    };
    return appearance === "inline" ? (
      <Text
        value={value}
        canEdit={true}
        input={input}
        className={className}
        onConfirm={(value) => onChange(name, value)}
      />
    ) : (
      <SelectInput value={value} input={input} onBlur={onBlur} onChange={onChange} className={className} />
    );
  }
}

export default SelectRoleInput;
