import React, { useEffect } from "react";
import { HiPaperClip } from "react-icons/hi";
import moment from "moment";
import styled from "styled-components";
import Utils from "lib/Utils";
import { useDispatch, useSelector } from "store";
import {
  fetchCalibrationCertificates,
  selectCalibrationCertificateByAssetID,
  selectCalibrationCertificatesLoading,
} from "store/reducers";
import { TCalibrationCertificateAttachment } from "types";
import StatusPill from "components/StatusPill";
import { TableBody, TableCell, TableHeader, TableLink, TableRow } from "components/Table";
import Table from "components/Table/Table";
import TableHeadCell from "components/Table/TableHeadCell";
import TableRowNotice from "components/Table/TableRowNotice";
import Trusted from "components/Trusted";
import { TextBody, VSpace } from "components/shared";

const FileIcon = styled(HiPaperClip)`
  width: 1rem;
  height: 1rem;
`;

const FileName = styled(TextBody)`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
`;

type CalibrationEventsTableProps = {
  assetId: string;
};

export const CalibrationEventsTable = ({ assetId }: CalibrationEventsTableProps) => {
  const dispatch = useDispatch();
  const entities = useSelector(selectCalibrationCertificateByAssetID(assetId));
  const loading = useSelector(selectCalibrationCertificatesLoading);

  useEffect(() => {
    dispatch(fetchCalibrationCertificates({ assetId, pageLimit: 999 }));
  }, [assetId]);

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeadCell>Certificate</TableHeadCell>
            <TableHeadCell>Calibrated on</TableHeadCell>
            <TableHeadCell>Attachments</TableHeadCell>
            <TableHeadCell>Result</TableHeadCell>
            <TableHeadCell isEmpty={true} />
          </TableRow>
        </TableHeader>
        <TableBody>
          {entities.map((entity) => (
            <TableRow key={entity.id}>
              <TableCell>{entity.certificateNumber}</TableCell>
              <TableCell>{moment(entity.dateCalibrated).format("Do MMMM YYYY")}</TableCell>
              <TableCell>
                {entity.attachments.length ? (
                  <VSpace gap="gap-2">
                    {entity.attachments.map(
                      ({ id, file }: TCalibrationCertificateAttachment) =>
                        file && (
                          <FileName key={id}>
                            <FileIcon />
                            <a href={file.url} target="_blank" rel="noreferrer">
                              {file.fileName}
                            </a>
                            <TextBody textSize="text-sm">{Utils.formatBytesToSize(file.size)}</TextBody>
                          </FileName>
                        ),
                    )}
                  </VSpace>
                ) : (
                  <TextBody textColor="text-gray-400">Not provided</TextBody>
                )}
              </TableCell>
              <TableCell>
                {entity.result === "pass" && <StatusPill color="success" text="Pass" />}
                {entity.result === "fail" && <StatusPill color="danger" text="Fail" />}
              </TableCell>
              <TableCell>
                {entity.evidenceId ? (
                  <TableLink to={`/evidences/${entity.evidenceId}`} target="_blank">
                    <Trusted />
                  </TableLink>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
          {!loading && entities.length === 0 && <TableRowNotice colSpan={5} notice="No calibration certificates yet" />}
        </TableBody>
      </Table>
    </>
  );
};
