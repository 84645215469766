import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateToolStoreOrderItemEventRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TToolStoreOrderItemEvent,
  UpdateToolStoreOrderItemEventRequest,
  UUID,
} from "types";

type FetchToolStoreOrderItemEventsParams = PaginationQueryParams;

export const fetchToolStoreOrderItemEvents = createAsyncThunk(
  "toolStoreOrderItemEvents/list",
  async (
    params: FetchToolStoreOrderItemEventsParams,
  ): Promise<{ results: TToolStoreOrderItemEvent[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TToolStoreOrderItemEvent[]>>("/tool_stores", params);
    return { results: data, paging: metadata };
  },
);

export const fetchToolStoreOrderItemEventById = createAsyncThunk(
  "toolStoreOrderItemEvents/getById",
  async (id: UUID): Promise<TToolStoreOrderItemEvent> => {
    const { data } = await API.GET<DataResponse<TToolStoreOrderItemEvent>>(`/tool_stores/${id}`);
    return data;
  },
);

export const createToolStoreOrderItemEvent = createAsyncThunk(
  "toolStoreOrderItemEvents/create",
  async (request: CreateToolStoreOrderItemEventRequest): Promise<TToolStoreOrderItemEvent> => {
    const { data } = await API.POST<CreateToolStoreOrderItemEventRequest, DataResponse<TToolStoreOrderItemEvent>>(
      "/tool_stores",
      request,
    );
    return data;
  },
);

export const updateToolStoreOrderItemEvent = createAsyncThunk(
  "toolStoreOrderItemEvents/update",
  async (request: UpdateToolStoreOrderItemEventRequest): Promise<TToolStoreOrderItemEvent> => {
    const { data } = await API.PUT<UpdateToolStoreOrderItemEventRequest, DataResponse<TToolStoreOrderItemEvent>>(
      `/tool_stores/${request.id}`,
      request,
    );
    return data;
  },
);

export const deleteToolStoreOrderItemEvent = createAsyncThunk(
  "toolStoreOrderItemEvents/delete",
  async (id: UUID): Promise<void> => {
    await API.DELETE(`/tool_stores/${id}`);
  },
);
