//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as jwt from "jose";
import get from "lodash/get";
import PropTypes from "prop-types";
import queryString from "query-string";
import Logger from "lib/Logger";
import { requestLookups } from "lib/actions/lookupData";
import Authentication from "lib/api/authentication";
import CustomerTypes from "lib/constants/CustomerTypes";
import { useDispatch } from "store";
import { fetchLocations, setAuthToken } from "store/reducers";
import { PasswordInput, TextInput } from "components/Input";
import RadioInput from "components/Input/RadioInput";
import { Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { VSpace } from "components/shared";
import PasswordRequirements from "pages/authentication/Login/PasswordRequirements";
import AuthForm from "pages/authentication/components/AuthForm";
import AuthFormTitle from "pages/authentication/components/AuthFormTitle";
import AuthNextButton from "pages/authentication/components/AuthNextButton";
import AuthSubmitButton from "pages/authentication/components/AuthSubmitButton";
import style from "./signup.module.scss";

const animation = "animate__animated animate__fast animate__fadeIn";

const Section = {
  COMPANY: 1,
  ACCOUNT: 2,
  FINISHED: 3,
};

const CompanyForm = ({ values, onChange, onNext }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onNext();
  };

  const isValid =
    values.customerType === CustomerTypes.INDIVIDUAL ? values.companyPhone : values.companyName && values.companyPhone;

  return (
    <AuthForm className={animation} onSubmit={handleSubmit}>
      <VSpace>
        <AuthFormTitle title="Tell us about yourself" />
        <FieldGroup label="What best describes you?">
          <div className={style.customerTypes}>
            {CustomerTypes.items.map((opt) => {
              return (
                <RadioInput
                  options={[opt]}
                  value={values.customerType}
                  onChange={(value) => onChange("customerType", value.value)}
                />
              );
            })}
          </div>
        </FieldGroup>
        {values.customerType && (
          <div className={animation}>
            {values.customerType === CustomerTypes.BUSINESS && (
              <FieldGroup label="Company name">
                <TextInput
                  input={{
                    name: "companyName",
                    placeholder: "Enter company name",
                  }}
                  value={values.companyName}
                  onChange={onChange}
                />
              </FieldGroup>
            )}

            <FieldGroup label="Contact telephone number">
              <TextInput
                input={{
                  name: "companyPhone",
                  placeholder: "Enter telephone number",
                }}
                value={values.companyPhone}
                onChange={onChange}
              />
            </FieldGroup>
          </div>
        )}
        <AuthSubmitButton isDisabled={!isValid}>Next</AuthSubmitButton>
      </VSpace>
    </AuthForm>
  );
};

const AccountForm = ({ values, authToken, onChange, onNext }) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [error, setError] = useState(null);

  const handlePasswordValidate = (isValid) => setValidPassword(isValid);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setSubmitting(true);
      setError(null);
      const { tokenString, token, resetPassword } = await Authentication.completeSignup(authToken, values);
      Logger.debug("account creation successful");

      dispatch(setAuthToken(tokenString));

      // Fetch lookup data
      dispatch(requestLookups());
      dispatch(fetchLocations({ pageLimit: 999 }));
      onNext();
    } catch (err) {
      const message = get(err, "response.body.error.message", "Something went wrong");
      setError(message);
      setSubmitting(false);
    }
  };

  return (
    <AuthForm className={animation} onSubmit={handleSubmit}>
      <AuthFormTitle title="Let's secure your account" />
      <input type="hidden" name="username" defaultValue={values.email} />
      <FieldGroup label="Password">
        <VSpace gap="gap-2">
          <PasswordInput
            input={{
              name: "password",
              placeholder: "Enter password",
            }}
            value={values.password}
            onChange={onChange}
          />
          <PasswordRequirements password={values.password} onValidate={handlePasswordValidate} />
        </VSpace>
      </FieldGroup>
      {error && <Text className="text-danger">{error}</Text>}
      <AuthSubmitButton isDisabled={!validPassword || submitting}>Next</AuthSubmitButton>
    </AuthForm>
  );
};

const Finished = ({ history }) => {
  const options = [
    {
      label: "Just having a look!",
      description: "Take me to my dashboard",
      value: "/dashboard",
    },
    {
      label: "Register my toolbox",
      description: "Setup an Automify toolbox",
      value: "/apps/storage/toolboxes",
    },
    {
      label: "Invite your team",
      description: "Invite additional users to Automify",
      value: "/configuration/users",
    },
  ];
  const [option, setOption] = useState(options[0]);

  const handleClick = () => {
    history(option.value, { replace: true });
  };

  return (
    <div>
      <AuthFormTitle title="You’re ready! Where would you like to start?" />
      <div className={style.nextSteps}>
        {options.map((opt) => {
          return <RadioInput options={[opt]} value={option} onChange={(value) => setOption(value)} />;
        })}
      </div>
      <AuthNextButton isDisabled={!option} onClick={handleClick}>
        Jump right in
      </AuthNextButton>
    </div>
  );
};

const SignupConfirmPage = () => {
  const location = useLocation();
  const history = useNavigate();
  const [authToken, setAuthToken] = useState(null);
  const [values, setValues] = useState({
    companyName: "",
    companyPhone: "",
    password: "",
    passwordConfirm: "",
    customerType: null,
  });
  const [section, setSection] = useState(Section.COMPANY);

  const handleChange = (name, value) => setValues({ ...values, [name]: value });

  // On first load, check for the token in the URL
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const authToken = jwt.decodeJwt(queryParams.token);
    setAuthToken(queryParams.token);
    setValues({
      ...values,
      email: authToken.email,
      forename: authToken.forename,
      surname: authToken.surname,
    });
  }, []);

  switch (section) {
    case Section.COMPANY:
      return <CompanyForm values={values} onChange={handleChange} onNext={() => setSection(Section.ACCOUNT)} />;
    case Section.ACCOUNT:
      return (
        <AccountForm
          values={values}
          authToken={authToken}
          onChange={handleChange}
          onNext={() => setSection(Section.FINISHED)}
        />
      );
    case Section.FINISHED:
      return <Finished history={history} />;
    default:
      return <div />;
  }
};

SignupConfirmPage.defaultProps = {};

SignupConfirmPage.propTypes = {
  className: PropTypes.string,
};

export default SignupConfirmPage;
