import { useEffect, useState } from "react";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchToolRooms, selectToolRooms } from "store/reducers";
import { TToolRoom, UUID } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

type LocationSelectorProps = {
  name: string;
  value?: TToolRoom | UUID;
  onChange: (name: string, value: TToolRoom) => void;
  placeholder?: string;
  appearance?: "inline" | "normal";
  searchable?: boolean;
  menuPlacement?: string;
  className?: string;
};

export const ToolRoomSelector = ({
  name,
  value,
  onChange,
  placeholder = "Select tool room",
  appearance = "normal",
  className,
  menuPlacement,
  searchable = false,
}: LocationSelectorProps): JSX.Element => {
  const dispatch = useDispatch();
  const entities = useSelector(selectToolRooms);
  const [ids, setIds] = useState<UUID[]>([]);
  const input = {
    type: "select",
    name,
    placeholder,
    labelKey: "label",
    valueKey: "id",
    menuPlacement,
    options: ids.map((id) => ({
      ...entities[id],
      label: entities[id]?.storage?.name,
    })),
    isSearchable: searchable,
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const { results } = await dispatch(fetchToolRooms({ pageLimit: 999 })).unwrap();
      setIds(results.map(({ id }) => id));
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return appearance === "inline" ? (
    <Text
      canEdit
      input={input}
      value={value as any}
      onConfirm={(value) => onChange(name, value)}
      className={className}
    />
  ) : (
    <SelectInput input={input} value={value} onChange={onChange} className={className} />
  );
};
