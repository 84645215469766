import React, { ReactNode, SyntheticEvent } from "react";
import classNames from "classnames";
import styled from "styled-components";
import style from "components/Buttons/Buttons.module.scss";
import Icon from "components/Icon";
import Loading from "components/Loading";

const StyledButton = styled.button<ButtonProps>`
  ${({ fitContent }) => fitContent && "width: fit-content;"}
`;

const colorStyle = (color: string) => {
  switch (color) {
    case "primary":
      return style.primary;
    case "secondary":
      return style.secondary;
    case "secondary_active":
      return { [style.secondary]: true, [style.active]: true };
    case "success":
      return style.success;
    case "danger":
      return style.danger;
    case "warning":
      return style.warning;
    default:
      return style.primary;
  }
};

export type ButtonProps = {
  type?: "button" | "submit";
  size?: "small" | "medium" | "large";
  color?: "primary" | "secondary" | "secondary_active" | "success" | "danger" | "warning" | string;
  submitting?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: (event: SyntheticEvent) => void;
  disabled?: boolean;
  icon?: string | ReactNode;
  iconSize?: string;
  iconClassName?: string;
  fitContent?: boolean;
};

export const Button = ({
  type = "button",
  size = "medium",
  color = "primary",
  submitting,
  children,
  className,
  onClick,
  disabled,
  icon,
  iconSize = "medium",
  iconClassName,
  fitContent,
  ...otherProps
}: ButtonProps) => {
  if (submitting) {
    disabled = true;
    children = null;
    icon = null;
  }

  const buttonClasses = classNames(style.button, style[size], colorStyle(color), className);

  return (
    <StyledButton
      {...otherProps}
      fitContent={fitContent}
      type={type}
      className={buttonClasses}
      onClick={onClick}
      disabled={disabled}
    >
      {submitting && <Loading size="medium" />}
      <div className={style.labelWrapper}>
        {typeof icon === "string" ? (
          <Icon src={icon} size={iconSize} className={classNames(style.icon, iconClassName)} />
        ) : (
          icon
        )}
        {children && <span className={style.label}>{children}</span>}
      </div>
    </StyledButton>
  );
};
