//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";

export const KEY_EVENT_DOWN = "keydown";
export const KEY_EVENT_PRESS = "keypress";
export const KEY_EVENT_UP = "keyup";

export const KEY_CODE_ESC = 27;
export const KEY_CODE_ENTER = 13;
export const KEY_CODE_ARROW_UP = 38;
export const KEY_CODE_ARROW_DOWN = 40;

class KeyHandler extends React.Component {
  static defaultProps = {
    keyEventName: KEY_EVENT_UP,
  };

  static propTypes = {
    keyEventName: PropTypes.oneOf([KEY_EVENT_DOWN, KEY_EVENT_PRESS, KEY_EVENT_UP]).isRequired,
    keyCode: PropTypes.number,
    onKeyHandle: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleKey = this.handleKey.bind(this);
  }

  componentDidMount() {
    const { keyEventName } = this.props;
    document.addEventListener(keyEventName, this.handleKey, false);
  }

  componentWillUnmount() {
    const { keyEventName } = this.props;
    document.removeEventListener(keyEventName, this.handleKey, false);
  }

  shouldComponentUpdate() {
    return false;
  }

  handleKey(event) {
    const { keyCode, onKeyHandle } = this.props;
    if (event.keyCode === keyCode) {
      onKeyHandle(event);
    }
  }

  render() {
    return null;
  }
}

export default KeyHandler;
