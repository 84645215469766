//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import CalibrationTrackingMethod from "./CalibrationTrackingMethod";
import DatetimeFilter from "./DatetimeFilter";
import StorageFilter from "./StorageFilter";
import UserFilter from "./UserFilter";

const Types = {
  DATE_FROM: "dateFrom",
  DATE_UNTIL: "dateUntil",
  USER_ID: "userId",
  STORAGE_ID: "storageId",
  CALIBRATION_METHOD_ID: "calibrationMethodId",
  CALIBRATION_NEXT_DUE_BEFORE: "calibrationNextDueBefore",
  CALIBRATION_NEXT_DUE_AFTER: "calibrationNextDueAfter",
};

const ComponentFilters = ({ component, onChange, className }) => {
  const handleChange = (filterType, value) => {
    console.log(filterType, value);
    const filters = component.metadata.filters.map((filter) => {
      if (filter.type !== filterType) {
        return filter;
      }
      filter.value = value;
      return filter;
    });
    onChange("metadata", {
      ...component.metadata,
      filters,
    });
  };
  return component.metadata.filters.map((filter) => {
    switch (filter.type) {
      case Types.DATE_FROM:
      case Types.DATE_UNTIL:
      case Types.CALIBRATION_NEXT_DUE_BEFORE:
      case Types.CALIBRATION_NEXT_DUE_AFTER:
        return (
          <DatetimeFilter key={filter.type} filter={filter} onChange={(value) => handleChange(filter.type, value)} />
        );
      case Types.STORAGE_ID:
        return <StorageFilter key={filter.type} filter={filter} onChange={handleChange} />;
      case Types.USER_ID:
        return <UserFilter key={filter.type} filter={filter} onChange={handleChange} />;
      case Types.CALIBRATION_METHOD_ID:
        return <CalibrationTrackingMethod key={filter.type} filter={filter} onChange={handleChange} />;
      default:
        return null;
    }
  });
};

ComponentFilters.defaultProps = {};

ComponentFilters.propTypes = {
  component: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default ComponentFilters;
