import { Button, GenericButtonProps } from "components/shared/buttons";

export type PrimaryButtonProps = GenericButtonProps & {};

export const PrimaryButton = ({ ...otherProps }: PrimaryButtonProps) => {
  return (
    <Button
      backgroundColor="bg-primary-600"
      textHoverColor="hover:text-white"
      backgroundHover="hover:bg-primary-700"
      ringFocusColor="focus:ring-primary-700"
      backgroundDisabled="disabled:bg-primary-200"
      {...otherProps}
    />
  );
};
