//@ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { TextInput, PasswordInput } from "components/Input";
import { Paragraph, Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { VSpace } from "components/shared";
import style from "pages/authentication/Login/Login.module.scss";
import AuthForm from "pages/authentication/components/AuthForm";
import AuthFormTitle from "pages/authentication/components/AuthFormTitle";
import AuthSubmitButton from "pages/authentication/components/AuthSubmitButton";

const LoginForm = ({ username, password, isAuthenticating, error, title, onChange, onSubmit }) => {
  const isValid = username && password;
  return (
    <AuthForm onSubmit={onSubmit}>
      <VSpace>
        <AuthFormTitle title={title} />
        <FieldGroup label="Email address">
          <TextInput
            input={{
              name: "username",
              placeholder: "Enter email address",
            }}
            value={username}
            onChange={onChange}
          />
        </FieldGroup>
        <FieldGroup label="Password">
          <PasswordInput
            input={{
              name: "password",
              placeholder: "Enter password",
            }}
            value={password}
            onChange={onChange}
          />
          <div className="text-right">
            <Link to="/recover_password" className={style.forgotPassword}>
              Forgot password?
            </Link>
          </div>
        </FieldGroup>
        {error && <Text className="text-danger">{error}</Text>}
        <AuthSubmitButton isDisabled={!isValid || isAuthenticating}>{title}</AuthSubmitButton>
        {false && (
          <Paragraph className="text-center">
            Don't have an account? <Link to="/signup">Sign up</Link>
          </Paragraph>
        )}
      </VSpace>
    </AuthForm>
  );
};

LoginForm.defaultProps = {
  title: "Sign into your account",
};

LoginForm.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  isAuthenticating: PropTypes.bool,
  error: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default LoginForm;
