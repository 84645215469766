//@ts-nocheck
import defaultBranding from "brands/defaultBranding";
import Store from "../Store";
import * as type from "../actions/actionTypes";

const initialState = {
  fetching: false,
  error: null,
  me: Store.collection("me").findOne(),
  customer: Store.collection("customer").findOne(),
  branding: Store.collection("branding").findOne() || defaultBranding,
  roles: Store.collection("roles").find(),
  users: Store.collection("users").find(),
  capabilities: Store.collection("capabilities").find(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_LOOKUPS_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case type.GET_LOOKUPS_RECEIVE:
      const { customer, me, capabilities, branding, roles, users } = action.payload;
      return {
        ...state,
        fetching: false,
        error: null,
        customer,
        me,
        branding: branding && branding.configuration ? branding.configuration : defaultBranding,
        roles: Array.isArray(roles) ? roles : [],
        users: Array.isArray(users) ? users : [],
        capabilities: Array.isArray(capabilities) ? capabilities : [],
      };
    case type.GET_LOOKUPS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
    case type.GET_COMPANY_BRANDING_RECEIVE:
      return {
        ...state,
        branding: action.payload,
      };
    default:
      return state;
  }
};
