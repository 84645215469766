//@ts-nocheck
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import TaskAPI from "lib/api/tasks";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import Loading from "components/Loading";
import TaskList from "components/lists/TaskList";
import style from "../sheet.module.scss";

const TaskListBlock = ({ block, onChange, onAddBlock, onRemoveBlock, className }) => {
  const [taskList, setTaskList] = useState(null);
  useEffect(() => {
    fetch();
  }, [block.properties.taskListId]);

  const fetch = async () => {
    try {
      const taskList = await TaskAPI.getTaskList(block.properties.taskListId);
      setTaskList(taskList);
    } catch (err) {}
  };

  const handleChange = async (taskListTasks) => {
    try {
      setTaskList({
        ...taskList,
        tasks: taskListTasks,
      });
      await TaskAPI.reorderTaskList(taskList.id, taskListTasks);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleUpdateTaskList = async (name, value) => {
    try {
      const updatedTaskList = {
        ...taskList,
        [name]: value,
      };
      setTaskList(updatedTaskList);
      await TaskAPI.updateTaskList(taskList.id, updatedTaskList);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <div className={classNames(style.flex)}>
      {taskList ? (
        <TaskList
          taskList={taskList}
          className={style.taskListBlock}
          onUpdateTaskList={handleUpdateTaskList}
          onChange={handleChange}
        />
      ) : (
        <Loading inline size="medium" />
      )}
    </div>
  );
};

export default TaskListBlock;
