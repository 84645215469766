//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Title } from "components/Typography";
import { VSpace } from "components/shared";
import style from "./comingsoon.module.scss";

const AppComingSoon = ({ title, description, showAction, className }) => {
  return (
    <VSpace className={style.container}>
      <Title>{title}</Title>
      <label>{description}</label>
    </VSpace>
  );
};

AppComingSoon.defaultProps = {
  description: "Coming soon!",
  showAction: true,
};

AppComingSoon.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showAction: PropTypes.bool,
  className: PropTypes.string,
};

export default AppComingSoon;
