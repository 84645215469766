//@ts-nocheck

export default class InputError extends Error {
  constructor(message, inputName) {
    super();
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
    this.inputName = inputName;
    this.message = message;
  }
}
