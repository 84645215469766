import React, { ReactNode } from "react";
import classNames from "classnames";
import TableCell from "./TableCell";
import TableRow from "./TableRow";
import style from "./table.module.scss";

type TableRowNoticeProps = {
  notice: string;
  colSpan: number;
  className?: string;
  children?: ReactNode;
};

export const TableRowNotice = ({ notice, colSpan = 1, children, className }: TableRowNoticeProps) => {
  return (
    <TableRow className={classNames(style.rowNotice, className)}>
      <TableCell colSpan={colSpan} className={style.rowNoticeCell}>
        {notice || children}
      </TableCell>
    </TableRow>
  );
};

export default TableRowNotice;
