//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Header } from "components/Typography";
import PageStage from "components/stages/PageStage";
import style from "./page.module.scss";

const PermissionDenied = ({ className }) => {
  return (
    <PageStage>
      <div className={style.container}>
        <Header>You are not allowed to access this page</Header>
      </div>
    </PageStage>
  );
};

PermissionDenied.defaultProps = {};

PermissionDenied.propTypes = {
  className: PropTypes.string,
};

export default PermissionDenied;
