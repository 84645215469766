//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import StageHeader from "components/StageHeader";
import PageStage from "components/stages/PageStage";

const AccountMessages = ({ className }) => {
  return (
    <PageStage>
      <StageHeader title="Messages" />
    </PageStage>
  );
};

AccountMessages.defaultProps = {};

AccountMessages.propTypes = {
  className: PropTypes.string,
};

export default AccountMessages;
