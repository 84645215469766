import { createSlice, isPending, isFulfilled, isRejected, isAnyOf } from "@reduxjs/toolkit";
import { TCatalogueProduct } from "types";
import { fetchCatalogueProducts, fetchCatalogueProductById } from "./products.thunks";

export type CatalogueProductsReducerStateData = Record<number, TCatalogueProduct>;

export interface CatalogueProductsReducerState {
  loading: string;
  page: number[];
  data: CatalogueProductsReducerStateData;
}

const initialState: CatalogueProductsReducerState = {
  loading: "",
  page: [],
  data: {},
};

export const catalogueProductsSlice = createSlice({
  name: "catalogueProducts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCatalogueProducts.pending, (state, action) => {
        state.page = [];
      })
      .addCase(fetchCatalogueProducts.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: CatalogueProductsReducerStateData, value: TCatalogueProduct) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
        state.page = action.payload.results.map(({ id }) => id);
      })
      .addCase(fetchCatalogueProductById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addMatcher(isPending, (state, action) => {
        state.loading = action.type;
      })
      .addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
        state.loading = "";
      });
  },
});
export const catalogueProductsReducer = catalogueProductsSlice.reducer;
