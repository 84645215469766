//@ts-nocheck
import * as type from "./actionTypes";
import { receivePagedResult, requestFailure } from "./common";

export function requestStatistics(options = {}) {
  return {
    type: type.REQUEST_STATISTICS,
    payload: options,
  };
}
export function receiveStatistics(statistics) {
  return {
    type: type.RECEIVE_STATISTICS,
    statistics,
  };
}
export function requestStatisticsFailure(error) {
  const payload = typeof error === "string" ? new Error(error) : error;
  return {
    type: type.REQUEST_STATISTICS_FAILURE,
    error: payload,
  };
}

export function requestMetrics(options = {}) {
  return {
    type: type.GET_METRICS_REQUEST,
    payload: options,
  };
}
export function receiveMetrics(metrics, paging) {
  return receivePagedResult(type.GET_METRICS_RECEIVE, metrics, paging);
}
export function requestMetricsFailure(error) {
  return requestFailure(type.GET_METRICS_FAILURE, error);
}

export function requestStoragesMetrics(options = {}) {
  return {
    type: type.GET_STORAGES_METRICS_REQUEST,
    payload: options,
  };
}
export function receiveStoragesMetrics(metrics) {
  return {
    type: type.GET_STORAGES_METRICS_RECEIVE,
    payload: metrics,
  };
}
export function requestStoragesMetricsFailure(error) {
  return requestFailure(type.GET_STORAGES_METRICS_FAILURE, error);
}

export function requestAssetsMetrics(options = {}) {
  return {
    type: type.GET_ASSETS_METRICS_REQUEST,
    payload: options,
  };
}
export function receiveAssetsMetrics(metrics) {
  return {
    type: type.GET_ASSETS_METRICS_RECEIVE,
    payload: metrics,
  };
}
export function requestAssetsMetricsFailure(error) {
  return requestFailure(type.GET_ASSETS_METRICS_FAILURE, error);
}
