//@ts-nocheck
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAlertRecipient, deleteAlertRecipient, updateAlertRecipient } from "lib/actions/alerts";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getAlertRecipientsByAlertID } from "lib/reducers/alerts.selectors";
import { Text } from "components/Typography";
import { DataList, DataListItem } from "components/lists/DataList";

const AlertRecipient = ({ alert, name, placeholder }) => {
  const dispatch = useDispatch();
  const recipientsSlice = useSelector(getAlertRecipientsByAlertID(alert.id));

  const recipients = useMemo(() => {
    return recipientsSlice.filter((recipient) => !!recipient[name]);
  }, [recipientsSlice]);

  const maxOrder = useMemo(() => {
    return recipients.reduce((maxOrder, cur) => {
      if (cur.order > maxOrder) {
        return cur.order;
      }
      return maxOrder;
    }, 0);
  }, [recipients]);

  const handleRemove = (recipient) => {
    return () => {
      dispatch(
        deleteAlertRecipient(
          {
            alertId: alert.id,
            id: recipient.id,
          },
          null,
          (err) => handlePopupFlagError(err),
        ),
      );
    };
  };

  const handleCreate = (value) => {
    dispatch(
      createAlertRecipient(
        {
          alertId: alert.id,
          [name]: value,
          order: maxOrder + 1,
        },
        null,
        (err) => handlePopupFlagError(err),
      ),
    );
  };

  return (
    <DataList>
      {recipients.map((recipient, i) => {
        const handleConfirm = (value) => {
          dispatch(
            updateAlertRecipient(
              {
                ...recipient,
                [name]: value,
              },
              null,
              (err) => handlePopupFlagError(err),
            ),
          );
        };
        return (
          <DataListItem key={recipient.id} onRemove={handleRemove(recipient)}>
            <Text
              canEdit={true}
              value={recipient[name]}
              placeholder={`Enter email address`}
              onConfirm={handleConfirm}
            />
          </DataListItem>
        );
      })}
      <DataListItem>
        <Text canEdit clearOnConfirm value="" placeholder={placeholder} onConfirm={handleCreate} />
      </DataListItem>
    </DataList>
  );
};

export const AlertEmailAddresses = ({ alert }) => {
  return <AlertRecipient name="emailAddress" placeholder="Enter email address to alert" alert={alert} />;
};

export const AlertTelephones = ({ alert }) => {
  return <AlertRecipient name="telephone" placeholder="Enter alert SMS to a telephone number" alert={alert} />;
};
