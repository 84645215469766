import { useEffect } from "react";
import { useDispatch, useSelector } from "store";
import { fetchVehicles, selectVehiclesPage } from "store/reducers";
import { OutstandingCards } from "components/shared/features/dashboard/OutstandingCards";
import { OutstandingVehicleCard } from "components/shared/features/dashboard/OutstandingVehicleCard";
import { Heading, TextBody } from "components/shared/typography";

export const OutstandingVehicles = () => {
  const dispatch = useDispatch();
  const vehicles = useSelector(selectVehiclesPage).filter(
    ({ toolsAssigned, ragsAssigned }) => toolsAssigned + ragsAssigned !== 0,
  );

  useEffect(() => {
    dispatch(
      fetchVehicles({
        pageStart: 0,
        pageLimit: 999,
        available: true,
      }),
    );
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Heading level="h3">Vehicles with outstanding tools</Heading>
      {vehicles.length === 0 ? (
        <TextBody>Nothing outstanding</TextBody>
      ) : (
        <OutstandingCards>
          {vehicles.map((entity) => (
            <OutstandingVehicleCard key={entity.id} vehicleId={entity.id} />
          ))}
        </OutstandingCards>
      )}
    </div>
  );
};
