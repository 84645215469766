import { AxiosError } from "axios";
import { ErrorResponse } from "types";

export type ThunkError = {
  rejectValue: AxiosError<ErrorResponse>;
};

/**
 * handleThunkError will handle an API error that occurs in a redux thunk.
 * @param rejectWithValue
 * @param err
 */
export const handleThunkError = (rejectWithValue: any, err: any) => {
  const error = err as AxiosError<ErrorResponse>;
  if (!error.response) throw err;
  return rejectWithValue(error);
};
