//@ts-nocheck
import request from "superagent";
import Api from "./api";

class Tags extends Api {
  /**
   * Get tags
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>} Array of tags.
   */
  static async getTags(queryParams = {}) {
    const uri = `${Api.getBaseUrl}/tags`;
    const response = await request.get(uri).set(this.requestOptions).query(queryParams);
    return await response.body;
  }

  /**
   * Get a tag
   * @async
   * @param {string} epc The tags tag epc
   * @returns {Promise.<Object>} A single tag.
   */
  static async getTag(epc) {
    const uri = `${Api.getBaseUrl}/tags/${epc}`;
    const response = await request.get(uri).set(this.requestOptions);
    return await response.body.data;
  }

  // TODO: Create tag

  // TODO: Update tag
}

export default Tags;
