//@ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import { showPopupFlag } from "lib/actions/userInterface";
import ReportAPI from "lib/api/reports";
import ReportVisibility from "lib/constants/ReportVisibility";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import store from "store";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import SelectReportVisibility from "components/inputs/SelectReportVisibility";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import style from "../RootModal/modal.module.scss";

const CreateReportModal = ({ modal, onClose }) => {
  const { onSuccess } = modal.props;
  const [values, setValues] = useState({
    name: "",
    visibility: ReportVisibility.PRIVATE,
    password: "",
  });
  const [submitting, isSubmitting] = useState(false);

  const handleChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    isSubmitting(true);
    try {
      event.preventDefault();
      const report = await ReportAPI.createReport({
        ...values,
        visibility: values.visibility.value || values.visibility,
      });
      store.dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Created Report",
        }),
      );
      onClose();
      onSuccess && onSuccess(report);
    } catch (err) {
      handlePopupFlagError(err);
    }
    isSubmitting(false);
  };

  const valid = values.name && values.visibility;

  return (
    <Modal className={style.genericModal}>
      <ModalHeader title="Create a new report" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter report name",
                autoFocus: true,
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Visibility">
            <SelectReportVisibility name="visibility" value={values.visibility} onChange={handleChange} />
          </FieldGroup>
          {values.visibility && values.visibility.value === ReportVisibility.PASSWORD_PROTECTED && (
            <FieldGroup label="Password">
              <TextInput
                input={{
                  name: "password",
                  placeholder: "Enter report password",
                }}
                value={values.password}
                onChange={handleChange}
              />
            </FieldGroup>
          )}
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton disabled={!valid || submitting} onClick={handleSubmit}>
          Create report
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

CreateReportModal.defaultProps = {};

CreateReportModal.propTypes = {
  className: PropTypes.string,
};

export default CreateReportModal;
