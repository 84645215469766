//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import UsersInput from "components/inputs/UsersInput";
import style from "../textEditorDropdown.module.scss";

const UserFilter = ({ filter, className, onChange }) => {
  return (
    <FieldGroup label={filter.name} className={style.contentInputGroup}>
      <UsersInput name={filter.type} value={filter.value} onChange={onChange} className={style.contentInput} />
    </FieldGroup>
  );
};

UserFilter.defaultProps = {};

UserFilter.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default UserFilter;
