import { createAction } from "@reduxjs/toolkit";
import { TVehicle } from "types";

export const streamCreateVehicle = createAction("vehicles/streamCreate", (data: TVehicle) => ({
  payload: data,
}));

export const streamUpdateVehicle = createAction("vehicles/streamUpdate", (data: TVehicle) => ({
  payload: data,
}));
