//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "./pool.module.scss";

const Pool = ({ className, children }) => {
  return <div className={classNames(style.pool, className)}>{children}</div>;
};

Pool.defaultProps = {};

Pool.propTypes = {
  className: PropTypes.string,
};

export default Pool;
