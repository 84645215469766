//@ts-nocheck
/**
 * High-order component redux-actions prop merging
 * @param stateProps
 * @param dispatchProps
 * @param ownProps
 * @returns {any}
 */
export default (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, ownProps, stateProps, dispatchProps, {
    actions: Object.assign({}, ownProps.actions, dispatchProps.actions),
    stateStore: Object.assign({}, stateProps.stateStore, ownProps.stateStore),
  });
};
