import { createSlice } from "@reduxjs/toolkit";
import {
  createToolRoom,
  fetchToolRooms,
  updateToolRoom,
  deleteToolRoom,
  fetchToolRoomById,
  fetchToolRoomByStorageId,
} from "store/reducers/toolRooms/toolRooms.thunks";
import { TToolRoom, UUID } from "types";

export type ToolRoomsReducerStateData = Record<UUID, TToolRoom>;

export interface ToolRoomsReducerState {
  data: ToolRoomsReducerStateData;
}

const initialState: ToolRoomsReducerState = {
  data: {},
};

export const toolRoomsSlice = createSlice({
  name: "toolRooms",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchToolRooms.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: ToolRoomsReducerStateData, value: TToolRoom) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchToolRoomById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(fetchToolRoomByStorageId.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createToolRoom.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateToolRoom.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteToolRoom.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const toolRoomsReducer = toolRoomsSlice.reducer;
