import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateCaseScannerRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TCaseScanner,
  UpdateCaseScannerRequest,
  UUID,
} from "types";

type FetchCaseScannersParams = PaginationQueryParams & {
  toolRoomId?: UUID;
};
type FetchCaseScannerHistoryParams = PaginationQueryParams & {
  caseScannerId: UUID;
};

export const fetchCaseScanners = createAsyncThunk(
  "caseScanners/list",
  async (params: FetchCaseScannersParams): Promise<{ results: TCaseScanner[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TCaseScanner[]>>("/case_scanners", params);
    return { results: data, paging: metadata };
  },
);

export const fetchCaseScannerHistory = createAsyncThunk(
  "caseScanners/fetchCaseScannerHistory",
  async ({
    caseScannerId,
    ...otherParams
  }: FetchCaseScannerHistoryParams): Promise<{
    results: TCaseScanner[];
    paging: Pagination;
  }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TCaseScanner[]>>(
      `/case_scanners/${caseScannerId}/history`,
      otherParams,
    );
    return { results: data, paging: metadata };
  },
);

export const fetchCaseScannerById = createAsyncThunk(
  "caseScanners/getById",
  async (id: UUID): Promise<TCaseScanner> => {
    const { data } = await API.GET<DataResponse<TCaseScanner>>(`/case_scanners/${id}`);
    return data;
  },
);

export const fetchCaseScannerByStorageId = createAsyncThunk(
  "caseScanners/fetchCaseScannerByStorageId",
  async (storageId: string): Promise<TCaseScanner> => {
    const { data } = await API.GET<DataResponse<TCaseScanner>>(`/storages/${storageId}/tool_room`);
    return data;
  },
);

export const createCaseScanner = createAsyncThunk(
  "caseScanners/create",
  async (request: CreateCaseScannerRequest): Promise<TCaseScanner> => {
    const { data } = await API.POST<CreateCaseScannerRequest, DataResponse<TCaseScanner>>("/case_scanners", request);
    return data;
  },
);

export const updateCaseScanner = createAsyncThunk(
  "caseScanners/update",
  async (request: UpdateCaseScannerRequest): Promise<TCaseScanner> => {
    const { data } = await API.PUT<UpdateCaseScannerRequest, DataResponse<TCaseScanner>>(
      `/case_scanners/${request.id}`,
      request,
    );
    return data;
  },
);

export const deleteCaseScanner = createAsyncThunk("caseScanners/delete", async (id: UUID): Promise<void> => {
  await API.DELETE(`/case_scanners/${id}`);
});
