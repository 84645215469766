//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import * as types from "../actions/actionTypes";
import * as actions from "../actions/tags";
import Api from "../api/tags";

function* fetchTag(action) {
  try {
    const tag = yield Api.getTag(action.payload);
    yield put(actions.receiveTag(tag));
  } catch (e) {
    yield put(actions.requestTagFailure(e));
  }
}

function* fetchTags(action) {
  try {
    const { data, metadata } = yield Api.getTags(action.payload);
    yield put(actions.receiveTags(data, metadata.totalCount, metadata.page, metadata.limit));
  } catch (e) {
    yield put(actions.requestTagsFailure(e));
  }
}

function* tagsSaga() {
  yield takeLatest(types.REQUEST_TAG, fetchTag);
  yield takeLatest(types.REQUEST_TAGS, fetchTags);
}

export default tagsSaga;
