import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateRagRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  RagBinType,
  TRag,
  UpdateRagRequest,
  UUID,
} from "types";

type FetchRagsParams = PaginationQueryParams & {
  ragBinType?: RagBinType[];
  ragBinId?: UUID[];
  ragControllerId?: UUID[];
};

export const fetchRags = createAsyncThunk(
  "rags/fetchRags",
  async (params: FetchRagsParams): Promise<{ results: TRag[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TRag[]>>("/rags", params);
    return { results: data, paging: metadata };
  },
);

export const fetchRagById = createAsyncThunk("rags/fetchRagById", async (id: UUID): Promise<TRag> => {
  const { data } = await API.GET<DataResponse<TRag>>(`/rags/${id}`);
  return data;
});

export const createRag = createAsyncThunk("rags/createRag", async (request: CreateRagRequest): Promise<TRag> => {
  const { data } = await API.POST<CreateRagRequest, DataResponse<TRag>>("/rags", request);
  return data;
});

export const updateRag = createAsyncThunk("rags/updateRag", async (request: UpdateRagRequest): Promise<TRag> => {
  const { data } = await API.PUT<UpdateRagRequest, DataResponse<TRag>>(`/rags/${request.id}`, request);
  return data;
});

export const deleteRag = createAsyncThunk("rags/deleteRag", async (id: UUID): Promise<void> => {
  await API.DELETE(`/rags/${id}`);
});
