//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import style from "./power.module.scss";

const PowerInput = ({ value, onChange, className }) => {
  return (
    <div
      className={classNames(style.container, { [style.active]: !!value }, className)}
      onClick={() => onChange(!value)}
    >
      <div className={style.control}>
        <Icon src="/images/icons/power_1.svg" size="medium" color="font" className={style.icon} />
      </div>
    </div>
  );
};

PowerInput.defaultProps = {};

PowerInput.propTypes = {
  className: PropTypes.string,
};

export default PowerInput;
