import { useNavigate } from "react-router-dom";
import { useModal } from "effects";
import { TToolRoom } from "types";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { CreateToolRoomModal, ToolRoomsTable } from "components/shared";
import PageStage from "components/stages/PageStage";

export const ToolRoomsPage = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();

  const createToolRoom = () => {
    openModal(
      <CreateToolRoomModal
        onSuccess={(entity: TToolRoom) => {
          navigate(`/apps/storage/tool_rooms/${entity.id}`);
        }}
      />,
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Tool rooms"
        subtitle="Manage your tool rooms."
        action={
          <SecondaryButton icon="/images/icons/plus_1.svg" onClick={createToolRoom}>
            Create tool room
          </SecondaryButton>
        }
      />
      <ToolRoomsTable />
    </PageStage>
  );
};
