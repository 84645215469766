//@ts-nocheck
import React, { ReactNode } from "react";
import classNames from "classnames";
import InlineInput from "components/Typography/InlineInput";
import style from "components/Typography/typography.module.scss";

export type ParagraphProps = {
  bold?: boolean;
  value?: string;
  muted?: boolean;
  size?: "large" | "normal";
  placeholder?: string;
  canEdit?: boolean;
  onConfirm?: (value: string) => void;
  onClick?: () => void;
  children?: ReactNode;
  className?: string;
  inputClassName?: string;
};

export const Paragraph = ({
  bold,
  value,
  muted,
  size = "normal",
  placeholder = "Enter value",
  canEdit,
  onConfirm,
  onClick,
  children,
  className,
  inputClassName = "",
}: ParagraphProps) => {
  const inlineInput = {
    type: size === "large" ? "textarea" : "text",
    rows: 4,
    placeholder: placeholder,
  };
  return (
    <pre
      className={classNames(
        style.paragraph,
        {
          [style.bold]: bold,
          "text-muted": muted,
        },
        className,
      )}
    >
      {canEdit ? (
        <InlineInput
          input={inlineInput}
          place
          defaultValue={value}
          onConfirm={onConfirm}
          className={inputClassName}
          onClick={onClick}
        />
      ) : (
        value || children
      )}
    </pre>
  );
};

export default Paragraph;
