//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class AccessScopes extends Lookup {
  ANYONE = "ACCESS_SCOPE_ANYONE";
  GROUP = "ACCESS_SCOPE_GROUP";
  PROJECT = "ACCESS_SCOPE_PROJECT";
  ROLE = "ACCESS_SCOPE_ROLE";
  USER = "ACCESS_SCOPE_USER";
  KEYCARD = "ACCESS_SCOPE_KEYCARD";

  constructor() {
    super();
    super._items = [
      {
        value: this.ANYONE,
        label: "Anyone",
        description: "Anyone can access this resource",
      },
      {
        value: this.ROLE,
        label: "User Role",
        description: "Allow any users with a role to access this resource",
      },
      {
        value: this.GROUP,
        label: "User Group",
        description: "Allow any users this a group to access this resource",
      },
      {
        value: this.PROJECT,
        label: "Project",
        description: "Allow any users in a project to access this resource",
      },
      {
        value: this.USER,
        label: "User",
        description: "Specify a user who can access this resource",
      },
      {
        value: this.KEYCARD,
        label: "Keycard",
        description: "Specify a keycard which can access this resource",
      },
    ];
  }
}

export default new AccessScopes();
