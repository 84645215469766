import { ReactNode } from "react";
import styled from "styled-components";
import InlineInput from "components/Typography/InlineInput";

const StyledText = styled.h3`
  color: #0f172a;
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
  line-height: 1.6;
`;

type SubheaderProps = {
  id?: string;
  value?: string;
  canEdit?: boolean;
  onConfirm?: (value: string) => void;
  onClick?: () => void;
  placeholder?: string;
  children?: ReactNode;
  className?: string;
  inputClassName?: string;
};

export const Subheader = ({
  id,
  value,
  canEdit,
  onConfirm,
  onClick,
  placeholder,
  children,
  className,
  inputClassName,
}: SubheaderProps) => (
  <StyledText id={id} className={className}>
    {canEdit ? (
      <InlineInput
        input={{ type: "text", placeholder }}
        className={inputClassName}
        defaultValue={value}
        onConfirm={onConfirm}
        onClick={onClick}
      />
    ) : (
      value || children
    )}
  </StyledText>
);

export default Subheader;
