//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "components/Sheet/sheet.module.scss";
import { Text } from "components/Typography";

export const PortalMenu = ({ parentNode, maxHeight = 40, children }) => {
  const position = parentNode.getBoundingClientRect();
  const screenHeight = window.screen.height;
  const positionY = position.y + position.height;
  const safeMargin = 0.1; // 10% of screen size safe margin
  const maxPortalHeight = screenHeight * (maxHeight / 100 + safeMargin);
  const maxY = positionY + maxPortalHeight;

  const inlineBlockStyle = { left: position.x, top: positionY };
  const inlineWrapperStyle = {};

  if (maxY > screenHeight) {
    inlineBlockStyle.top = positionY - position.height;
    inlineWrapperStyle.justifyContent = "flex-end";
  }

  return (
    <div className={style.blockMenu} style={inlineBlockStyle}>
      <div className={style.blockMenuWrapper} style={inlineWrapperStyle}>
        <div>{children}</div>
      </div>
    </div>
  );
};

PortalMenu.defaultProps = {};

PortalMenu.propTypes = {
  maxHeight: PropTypes.number, // % of screen from 0 to 100
  className: PropTypes.string,
};

export const SectionTitle = ({ children, className, ...otherProps }) => {
  return (
    <Text {...otherProps} className={classNames(style.sectionTitle, className)}>
      {children}
    </Text>
  );
};

export default PortalMenu;
