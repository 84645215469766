import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchAssets, selectAssets } from "store/reducers";
import { TAsset } from "types";
import { SelectInput } from "components/Input";

type AssetSelectorProps = {
  name: string;
  value?: TAsset;
  placeholder?: string;
  queryParams?: Object;
  onChange?: (value?: TAsset) => void;
  className?: string;
};

export const AssetSelector = ({
  name,
  value,
  onChange,
  className,
  queryParams,
  placeholder = "Select a tool",
}: AssetSelectorProps) => {
  const dispatch = useDispatch();
  const assets = useSelector(selectAssets);

  const loadOptions = async (inputValue: string) => {
    try {
      const { results } = await dispatch(
        fetchAssets({
          ...queryParams,
          pageStart: 0,
          pageLimit: 20,
          query: inputValue,
        }),
      ).unwrap();
      return results.map(formatAsset);
    } catch (err) {
      handlePopupFlagError(err);
      return [];
    }
  };

  const formatAsset = (asset: TAsset) => ({
    id: asset.id,
    productName: asset.catalogProduct?.name,
  });

  const handleChange = (_: string, value: { id: number }) => {
    if (onChange) {
      const asset = assets[value?.id];
      onChange(asset);
    }
  };

  return (
    <SelectInput
      input={{
        async: true,
        name,
        loadOptions,
        placeholder,
        labelKey: "productName",
        valueKey: "id",
        cacheOptions: true,
        defaultOptions: true,
      }}
      value={value && formatAsset(value)}
      onChange={handleChange}
      className={className}
    />
  );
};
