import { createSlice } from "@reduxjs/toolkit";
import { GET_LOOKUPS_RECEIVE } from "lib/actions/actionTypes";
import { assignUserKeycard, createUser, fetchUserKeycardById, fetchUsers, updateUser } from "store/reducers";
import { deleteUser, fetchUserById } from "store/reducers/users/users.thunks";
import { FixLater, TUser, UUID } from "types";

export type UsersReducerStateData = Record<UUID, TUser>;

export interface UsersReducerState {
  data: UsersReducerStateData;
}

const initialState: UsersReducerState = {
  data: {},
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: UsersReducerStateData, value: TUser) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addCase(fetchUserKeycardById.fulfilled, (state, action) => {
        if (action.payload?.user) {
          state.data[action.payload.user.id] = action.payload.user;
        }
      })
      .addCase(assignUserKeycard.fulfilled, (state, action) => {
        if (action.payload?.user) {
          state.data[action.payload.user.id] = action.payload.user;
        }
      })
      .addCase(GET_LOOKUPS_RECEIVE, (state, action: FixLater) => {
        // TODO: This is legacy. We need to pull lookups using redux toolkit.
        state.data = action.payload.users.reduce(
          (data: UsersReducerStateData, value: TUser) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      });
  },
});

export const usersReducer = usersSlice.reducer;
