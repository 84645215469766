import { ReactNode } from "react";
import classNames from "classnames";
import styles from "./Group.module.scss";

export type GroupProps = {
  className?: string;
  spaceBetween?: boolean;
  alignVertical?: boolean;
  children?: ReactNode | ReactNode[];
};

export const Group = ({ className, spaceBetween = false, alignVertical = false, ...otherProps }: GroupProps) => {
  return (
    <div
      {...otherProps}
      className={classNames(
        styles.Group,
        className,
        { [styles.verticalAlign]: alignVertical },
        { [styles.spaceBetween]: spaceBetween },
      )}
    />
  );
};
