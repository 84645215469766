import styled from "styled-components";

const Indicator = styled.div`
  position: relative;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
  background-color: currentColor;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    top: -0.4375rem;
    left: -0.4375rem;
    background-color: currentColor;
    animation: vehicle-status-pulse 2s infinite;
    -webkit-transform-origin: center center;
  }
`;

type VehicleStatusIndicatorProps = {
  ready: boolean;
};

export const VehicleStatusIndicator = ({ ready }: VehicleStatusIndicatorProps) => {
  return <Indicator className={ready ? "text-green-600" : "text-red-600"} />;
};
