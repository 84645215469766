//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class CheckoutTypes extends Lookup {
  RFID = 1;
  MANUAL = 2;

  constructor() {
    super();
    super._items = [
      {
        value: this.RFID,
        label: "RFID",
        description: "System Reported Checkout",
      },
      {
        value: this.MANUAL,
        label: "Manual",
        description: "User Reported Checkout",
      },
    ];
  }
}

export default new CheckoutTypes();
