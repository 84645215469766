//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  checkouts: {
    fetching: false,
    error: null,
    data: [],
    totalCount: 0,
    page: 1,
    limit: 10,
  },
  checkout: {
    fetching: false,
    error: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  let checkouts, checkout;
  switch (action.type) {
    // Fetching multiple checkouts
    case type.REQUEST_CHECKOUTS:
      checkouts = Object.assign({}, state.checkouts, {
        fetching: true,
        error: null,
        data: [],
      });
      return Object.assign({}, state, { checkouts });
    case type.REQUEST_CHECKOUTS_FAILURE:
      checkouts = Object.assign({}, state.checkouts, {
        fetching: false,
        error: action.error,
        data: [],
      });
      return Object.assign({}, state, { checkouts });
    case type.RECEIVE_CHECKOUTS:
      checkouts = Object.assign({}, state.checkouts, {
        fetching: false,
        error: null,
        data: action.checkouts,
        totalCount: action.totalCount,
        page: action.page,
        limit: action.limit,
      });
      return Object.assign({}, state, { checkouts });

    // Fetching a single checkout
    case type.REQUEST_CHECKOUT:
      checkout = Object.assign({}, state.checkout, {
        fetching: true,
        error: null,
        data: null,
      });
      return Object.assign({}, state, { checkout });
    case type.REQUEST_CHECKOUT_FAILURE:
      checkout = Object.assign({}, state.checkout, {
        fetching: false,
        error: action.error,
        data: null,
      });
      return Object.assign({}, state, { checkout });
    case type.RECEIVE_CHECKOUT:
      checkout = Object.assign({}, state.checkout, {
        fetching: false,
        error: null,
        data: action.checkout,
      });
      return Object.assign({}, state, { checkout });
    default:
      return state;
  }
};
