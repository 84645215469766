//@ts-nocheck
import React from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FixLater } from "types";
import style from "components/Draggable/draggable.module.scss";
import Icon from "components/Icon";

export const Draggable = SortableContainer(({ className, children, handleSortEnd, ...otherProps }) => (
  <div {...otherProps} className={classNames(style.draggable, className)}>
    {children}
  </div>
));

Draggable.propTypes = {
  onSortEnd: PropTypes.func,
  useDragHandle: PropTypes.bool,
  className: PropTypes.string,
};

export const DraggableItem = SortableElement(
  ({ value, children, dragHandle, className, valueClassName, showSorting, ...otherProps }) => {
    return (
      <div {...otherProps} className={classNames(style.draggableItem, className)}>
        {showSorting ? (
          dragHandle ? (
            dragHandle
          ) : (
            <div className="sortable-handle">
              <DragHandle />
            </div>
          )
        ) : null}
        <div className={classNames(style.draggableItemContent, valueClassName)}>{value || children}</div>
      </div>
    );
  },
);

DraggableItem.propTypes = {
  index: PropTypes.number,
  className: PropTypes.string,
};

export const DragHandle = SortableHandle(({ onClick, className }: FixLater) => (
  <Icon
    src="/images/icons/drag_1.svg"
    size="small"
    className={classNames(className, "sortable-handle-icon")}
    onClick={onClick}
  />
));
