//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  tags: {
    fetching: false,
    error: null,
    data: [],
    totalCount: 0,
    page: 1,
    limit: 10,
  },
  tag: {
    fetching: false,
    error: null,
    data: null,
  },
  history: {
    fetching: false,
    error: null,
    data: [],
    totalCount: 0,
    page: 1,
    limit: 10,
  },
  ingestEvents: {
    fetching: false,
    error: null,
    data: [],
    totalCount: 0,
    page: 1,
    limit: 10,
  },
};

export default (state = initialState, action) => {
  let tags, tag;
  switch (action.type) {
    // Fetching multiple tags
    case type.REQUEST_TAGS:
      tags = Object.assign({}, state.tags, {
        fetching: true,
        error: null,
      });
      if (!action.skipClear) {
        tags = Object.assign({}, tags, {
          data: [],
        });
      }
      return Object.assign({}, state, { tags });
    case type.REQUEST_TAGS_FAILURE:
      tags = Object.assign({}, state.tags, {
        fetching: false,
        error: action.error,
        data: [],
      });
      return Object.assign({}, state, { tags });
    case type.RECEIVE_TAGS:
      tags = Object.assign({}, state.tags, {
        fetching: false,
        error: null,
        data: action.tags,
        totalCount: action.totalCount,
        page: action.page,
        limit: action.limit,
      });
      return Object.assign({}, state, { tags });

    // Fetching a single tag
    case type.REQUEST_TAG:
      tag = Object.assign({}, state.tag, {
        fetching: true,
        error: null,
        data: null,
      });
      return Object.assign({}, state, { tag });
    case type.REQUEST_TAG_FAILURE:
      tag = Object.assign({}, state.tag, {
        fetching: false,
        error: action.error,
        data: null,
      });
      return Object.assign({}, state, { tag });
    case type.RECEIVE_TAG:
      tag = Object.assign({}, state.tag, {
        fetching: false,
        error: null,
        data: action.tag,
      });
      return Object.assign({}, state, { tag });
    default:
      return state;
  }
};
