//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Logger from "../../../../../lib/Logger";
import Api from "../../../../../lib/api/readers";
import ReaderAntennas from "./ReaderAntennas";

class ReaderAntennasContainer extends React.Component {
  static propTypes = {
    reader: PropTypes.shape({
      macAddress: PropTypes.string,
    }).isRequired,
    match: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    antennas: [],
    antenna: {
      antennaPort: "",
      type: null,
    },
  };

  componentDidMount() {
    this.fetchAntennas();
  }

  fetchAntennas = async () => {
    try {
      const { reader } = this.props;
      const { data } = await Api.getReaderAntennas(reader.macAddress);
      this.setState({
        antennas: data,
      });
    } catch (err) {
      Logger.error(err);
    }
  };

  onChange = (name, value) =>
    this.setState({
      antenna: {
        ...this.state.antenna,
        [name]: value,
      },
    });

  onCreate = async () => {
    try {
      const { reader } = this.props;
      const { antenna } = this.state;
      await Api.createReaderAntenna(reader.macAddress, {
        ...antenna,
        typeId: antenna.type.value,
      });
      this.setState({
        antenna: {
          antennaPort: "",
          type: null,
        },
      });
      this.fetchAntennas();
    } catch (err) {
      Logger.error(err);
    }
  };

  onDelete = async (antennaPort) => {
    try {
      const { reader } = this.props;
      await Api.deleteReaderAntenna(reader.macAddress, antennaPort);
      this.fetchAntennas();
    } catch (err) {
      Logger.error(err);
    }
  };

  render() {
    const { antennas, antenna } = this.state;
    return (
      <ReaderAntennas
        antennas={antennas}
        antenna={antenna}
        onChange={this.onChange}
        onCreate={this.onCreate}
        onRemove={this.onDelete}
      />
    );
  }
}

export default ReaderAntennasContainer;
