import { useEffect, useState } from "react";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { fetchStorages } from "store/reducers";
import { UUID } from "types";
import { OutstandingCards } from "components/shared/features/dashboard/OutstandingCards";
import { OutstandingStorageCard } from "components/shared/features/dashboard/OutstandingStorageCard";
import { Heading, TextBody } from "components/shared/typography";

export const OutstandingStorages = () => {
  const dispatch = useDispatch();
  const [ids, setIds] = useState<UUID[]>([]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      const response = await dispatch(fetchStorages({ pageStart, pageLimit, type: "toolbox" })).unwrap();
      setIds(response.results.filter(({ assetsMetric }) => assetsMetric && assetsMetric.taken > 0).map(({ id }) => id));
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  useEffect(() => {
    fetch(0, 100);
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Heading level="h3">Toolboxes with outstanding tools</Heading>
      {ids.length === 0 ? (
        <TextBody>Nothing outstanding</TextBody>
      ) : (
        <OutstandingCards>
          {ids.map((id) => (
            <OutstandingStorageCard key={id} storageId={id} />
          ))}
        </OutstandingCards>
      )}
    </div>
  );
};
