import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { ToolStorageProvider } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { useDispatch, useSelector } from "store";
import {
  createStorageViewer,
  fetchStorageById,
  fetchDoorwayById,
  selectStorageByID,
  selectDoorwayByID,
} from "store/reducers";
import { PageLoading } from "components/shared";
import { DoorwaySettingsPage } from "components/shared/features/doorways/pages/DoorwaySettingsPage";
import PageStage from "components/stages/PageStage";
import { StorageAlertPage, StorageAlertsPage } from "pages";

export const DoorwayPage = () => {
  const dispatch = useDispatch();
  const { pathname: url } = useLocation();
  const { doorwayID } = useParams();
  const entity = useSelector(selectDoorwayByID(doorwayID || ""));
  const storageID = entity?.storageId;
  const storage = useSelector(selectStorageByID(storageID || ""));

  // Fetch the doorway if it is not in memory yet
  useEffect(() => {
    if (!doorwayID) return;
    if (entity) return;
    dispatch(fetchDoorwayById(doorwayID));
  }, [doorwayID, entity]);

  useEffect(() => {
    if (!storageID) return;
    if (storage) return;
    dispatch(fetchStorageById(storageID));
  }, [storageID, storage]);

  useEffect(() => {
    if (!storageID) return;
    dispatch(createStorageViewer(storageID));
  }, [storageID]);

  if (!entity || !storage || !storageID || !doorwayID) {
    return <PageLoading />;
  }

  return (
    <ToolStorageProvider storageId={storageID}>
      <PageStage>
        <Routes>
          <Route path="settings" element={<DoorwaySettingsPage doorwayId={doorwayID} />} />
          <Route path="alerts" element={<StorageAlertsPage />} />
          <Route path="alerts/:storageAlertID" element={<StorageAlertPage />} />
          <Route path="" element={<Navigate replace to={`${url}/settings`} />} />
        </Routes>
      </PageStage>
    </ToolStorageProvider>
  );
};
