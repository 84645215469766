import { Text } from "components/Typography";
import { DataList, DataListItem } from "components/lists/DataList";

type AssetRFIDConfigProps = {
  epcs: string[];
  onChange: (epcs: string[]) => void;
};

export const AssetRFIDConfig = ({ epcs = [], onChange }: AssetRFIDConfigProps) => {
  const handleCreate = (value: string) => {
    onChange([...epcs, value.trim()]);
  };

  const handleConfirm = (epcIndex: number) => {
    return (value: string) => {
      const newTags = [...epcs];
      newTags[epcIndex] = value.trim();
      onChange(newTags);
    };
  };

  const handleRemove = (epcValue: string, epcIndex: number) => {
    return () => {
      const newTags = [...epcs];
      newTags.splice(epcIndex, 1);
      onChange(newTags);
    };
  };

  return (
    <DataList>
      {epcs.map((epc: string, i: number) => {
        return (
          <DataListItem key={`${epc}_${i}`} onRemove={handleRemove(epc, i)}>
            <Text canEdit={true} value={epc} placeholder={`Enter EPC to track`} onConfirm={handleConfirm(i)} />
          </DataListItem>
        );
      })}
      <DataListItem>
        <Text canEdit clearOnConfirm value="" placeholder="Enter EPC to track" onConfirm={handleCreate} />
      </DataListItem>
    </DataList>
  );
};
