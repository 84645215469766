import { useState } from "react";
import { useDispatch } from "react-redux";
import { requestProducts } from "lib/actions/catalogue";
import { showPopupFlag } from "lib/actions/userInterface";
import CatalogueAPI from "lib/api/catalogue";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { FixLater } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";

export const CreateManufacturerProductModal = ({ modal, onClose }: FixLater) => {
  const dispatch = useDispatch();
  const { manufacturerId, onSuccess } = modal.props;
  const [values, setValues] = useState({
    manufacturerId,
    mpn: "",
    name: "",
    description: "",
    productUrl: "",
    thumbnailUrl: "",
    imageUrl: "",
    websiteUrl: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const valid = values.mpn && values.name;

  const handleChange = (name: string, value: string) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FixLater) => {
    setSubmitting(true);
    try {
      event.preventDefault();
      const product = await CatalogueAPI.createProduct({
        ...values,
      });
      dispatch(requestProducts(product));
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Product created",
        }),
      );
      onClose();
      onSuccess && onSuccess(product);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setSubmitting(false);
  };

  if (!manufacturerId) {
    return (
      <Modal>
        <p>Expected form to have a manufacturerId prop</p>
      </Modal>
    );
  }

  return (
    <Modal>
      <ModalHeader title="Create a product" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Product number">
            <TextInput
              input={{
                name: "mpn",
                placeholder: "Enter product number",
                autoFocus: true,
              }}
              value={values.mpn}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter product name",
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Website URL">
            <TextInput
              input={{
                name: "websiteUrl",
                placeholder: "Enter product website URL",
              }}
              value={values.websiteUrl}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Image URL">
            <TextInput
              input={{
                name: "imageUrl",
                placeholder: "Enter product image URL",
              }}
              value={values.imageUrl}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Description">
            <TextInput
              input={{
                name: "description",
                placeholder: "Enter product description",
              }}
              value={values.description}
              onChange={handleChange}
            />
          </FieldGroup>
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton disabled={!valid || submitting} onClick={handleSubmit}>
          Create product
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};
