import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "store";
import { logout } from "store/reducers";
import Loading from "components/Loading";

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const LogoutPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(logout());
    }, 200);
  }, [navigate]);

  return (
    <Container>
      <Loading />
    </Container>
  );
};
