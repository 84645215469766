//@ts-nocheck
import React from "react";
import CompanyPrivacy from "./CompanyPrivacy";

class CompanyPrivacyContainer extends React.Component {
  static propTypes = {};

  state = {};

  render() {
    return <CompanyPrivacy />;
  }
}

export default CompanyPrivacyContainer;
