import { Button, GenericButtonProps } from "components/shared/buttons";

export type WhiteButtonProps = GenericButtonProps & {};

export const WhiteButton = ({ ...otherProps }: WhiteButtonProps) => {
  return (
    <Button
      backgroundColor="bg-white"
      textColor="text-gray-700"
      backgroundHover="hover:bg-zinc-50"
      ringFocusColor="focus:ring-zinc-500"
      borderColor="border-zinc-500"
      shadow="shadow-sm"
      {...otherProps}
    />
  );
};
