import styled from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectToolStoreByID, updateToolStore } from "store/reducers";
import { FixLater, UUID } from "types";
import StageHeader from "components/StageHeader";
import { Header } from "components/Typography";

const StyledHeader = styled(Header)`
  margin-bottom: 1rem;
`;

type ToolStorePageHeaderProps = {
  toolStoreId: UUID;
  actions?: FixLater;
};

export const ToolStorePageHeader = ({ toolStoreId, actions }: ToolStorePageHeaderProps) => {
  const dispatch = useDispatch();
  const entity = useSelector(selectToolStoreByID(toolStoreId));

  if (!entity) {
    return null;
  }

  const updateName = async (value: string) => {
    try {
      await dispatch(
        updateToolStore({
          ...entity,
          storage: {
            ...entity.storage,
            name: value,
          },
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Store name changed",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <StageHeader
      title={
        <StyledHeader
          canEdit={true}
          placeholder="Enter store name"
          value={entity.storage.name}
          onConfirm={updateName}
        />
      }
      action={actions}
    />
  );
};
