import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectUserByID, fetchUserById } from "store/reducers";
import StageHeader from "components/StageHeader";
import { PageLoading } from "components/shared";
import { AccountProfile } from "components/shared/features/accounts";
import PageStage from "components/stages/PageStage";

export const UserPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const userId = parseInt(id || "");
  const entity = useSelector(selectUserByID(userId));
  const breadcrumb = [
    { label: "Configuration", href: "/configuration" },
    { label: "Team members", href: "/configuration/users" },
    `${entity?.email}`,
  ];

  useEffect(() => {
    if (!entity && id) {
      dispatch(fetchUserById(id));
    }
  }, [entity]);

  if (!entity) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <StageHeader
        title="Account settings"
        subtitle="Manage your team member account settings."
        breadcrumb={breadcrumb}
      />
      <AccountProfile userId={userId} />
    </PageStage>
  );
};
