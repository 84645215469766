import { useNavigate } from "react-router-dom";
import { useModal } from "effects";
import { TRagController } from "types";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { CreateRagControllerModal, RagControllersTable } from "components/shared";
import PageStage from "components/stages/PageStage";

export const RagControllersPage = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();

  const createRagController = () => {
    openModal(
      <CreateRagControllerModal
        onSuccess={(entity: TRagController) => {
          navigate(`/apps/storage/rag_controllers/${entity.id}`);
        }}
      />,
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Rag controllers"
        subtitle="Rag controllers are used monitor clean, dirty, and missing rags."
        action={
          <SecondaryButton icon="/images/icons/plus_1.svg" onClick={createRagController}>
            Create controller
          </SecondaryButton>
        }
      />
      <RagControllersTable />
    </PageStage>
  );
};
