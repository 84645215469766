import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateStorageAlertRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TStorageAlert,
  UpdateStorageAlertRequest,
  UUID,
} from "types";

type FetchStorageAlertsParams = PaginationQueryParams & {
  storageId: string;
};

export const fetchStorageAlerts = createAsyncThunk(
  "storageAlerts/fetchStorageAlerts",
  async (params: FetchStorageAlertsParams): Promise<{ results: TStorageAlert[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TStorageAlert[]>>(
      `/storages/${params.storageId}/alerts`,
      params,
    );
    return { results: data, paging: metadata };
  },
);

type FetchStorageAlertByIdRequest = {
  id: UUID;
  storageId: string;
};

export const fetchStorageAlertById = createAsyncThunk(
  "storageAlerts/fetchStorageAlertById",
  async (request: FetchStorageAlertByIdRequest): Promise<TStorageAlert> => {
    const { data } = await API.GET<DataResponse<TStorageAlert>>(`/storages/${request.storageId}/alerts/${request.id}`);
    return data;
  },
);

export const createStorageAlert = createAsyncThunk(
  "storageAlerts/createStorageAlert",
  async (request: CreateStorageAlertRequest): Promise<TStorageAlert> => {
    const { data } = await API.POST<CreateStorageAlertRequest, DataResponse<TStorageAlert>>(
      `/storages/${request.storageId}/alerts`,
      request,
    );
    return data;
  },
);

export const updateStorageAlert = createAsyncThunk(
  "storageAlerts/updateStorageAlert",
  async (request: UpdateStorageAlertRequest): Promise<TStorageAlert> => {
    const { data } = await API.PUT<UpdateStorageAlertRequest, DataResponse<TStorageAlert>>(
      `/storages/${request.storageId}/alerts/${request.id}`,
      request,
    );
    return data;
  },
);

type DeleteStorageAlertParams = {
  id: UUID;
  storageId: string;
};

export const deleteStorageAlert = createAsyncThunk(
  "storageAlerts/deleteStorageAlert",
  async (request: DeleteStorageAlertParams): Promise<void> => {
    await API.DELETE(`/storages/${request.storageId}/alerts/${request.id}`);
  },
);
