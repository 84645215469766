//@ts-nocheck
import React from "react";
import AppComingSoon from "components/AppComingSoon";
import PageStage from "components/stages/PageStage";

const ComplianceDashboard = () => {
  return (
    <PageStage>
      <AppComingSoon
        title="Regulatory Compliance is coming soon"
        description="The Regulatory Compliance App enables your business to identity and rectify compliance risk and violations quick and effectively."
      />
    </PageStage>
  );
};

ComplianceDashboard.defaultProps = {};

ComplianceDashboard.propTypes = {};

export default ComplianceDashboard;
