import styled from "styled-components";
import { Paragraph, Subheader } from "components/Typography";

const Container = styled.div`
  margin-bottom: 1rem;
`;

type SidebarNameProps = {
  name: string;
  description?: string;
};

export const SidebarName = ({ name, description }: SidebarNameProps) => {
  return (
    <Container>
      <Subheader>{name}</Subheader>
      {description && <Paragraph>{description}</Paragraph>}
    </Container>
  );
};
