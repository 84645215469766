import Map, { FullscreenControl, NavigationControl, ViewStateChangeEvent } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { VehiclesMapDriver } from "components/shared/features/vehicles/VehiclesMapDriver/VehiclesMapDriver";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

type VehiclesMapProps = {
  longitude: number;
  latitude: number;
  zoom?: number;
  pitch?: number;
  offsetLeft?: number;
  mapStyle?: string;
  onReady?: () => void;
  onDragStart?: (event: ViewStateChangeEvent) => void;
  onDragEnd?: (event: ViewStateChangeEvent) => void;
};

export const VehiclesMap = ({
  longitude,
  latitude,
  zoom = 12,
  pitch = 60,
  offsetLeft = 0,
  mapStyle = "automify/cldwwcisd003901pas6iutz3p",
  onReady,
  onDragStart,
  onDragEnd,
}: VehiclesMapProps) => {
  return (
    <Map
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ID}
      initialViewState={{
        longitude,
        latitude,
        zoom,
        pitch,
        padding: {
          top: 0,
          bottom: 0,
          right: 0,
          left: offsetLeft,
        },
      }}
      style={{ width: "100%", height: "100%" }}
      mapStyle={`mapbox://styles/${mapStyle}`}
      attributionControl={false}
      onLoad={onReady}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <VehiclesMapDriver latitude={latitude} longitude={longitude} zoom={zoom} />
      <FullscreenControl />
      <NavigationControl position="top-right" />
    </Map>
  );
};
