import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import get from "lodash/get";
import AssetsAPI from "lib/api/assets";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { FixLater } from "types";
import { TAssetProduct } from "types/features/assetProduct";
import { SmartTable, SmartTableNav, SmartTablePagination } from "components/Table/SmartTable";
import { Text, Paragraph } from "components/Typography";
import DropSelect from "components/dropdowns/DropSelect";
import style from "./list.module.scss";

type ManualCheckoutListProps = {
  session: any;
  className?: string;
  onSelect?: any;
};

const ManualCheckoutList = ({ session, className, onSelect }: ManualCheckoutListProps) => {
  const [paging, setPaging] = useState({
    pageStart: 0,
    pageLimit: 20,
  });
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({
    assigned: null,
    calibrated: null,
  });
  const [assets, setAssets] = useState([]);
  const rowData = useMemo(() => {
    return assets;
  }, [assets]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Image",
        Cell: ({ row }: FixLater) => {
          const { catalogProduct } = row.original;
          return (
            <div
              style={{
                backgroundImage: `url(${catalogProduct.imageUrl})`,
              }}
              className={style.image}
            />
          );
        },
      },
      {
        Header: "Name",
        accessor: ({ row }: FixLater) => get(row, "original.catalogProduct.name", ""),
        Cell: ({ row }: FixLater) => {
          const { catalogProduct }: { catalogProduct?: TAssetProduct } = row.original;
          return (
            catalogProduct && (
              <>
                <Paragraph>{catalogProduct.name}</Paragraph>
                <Text className="text-muted">{catalogProduct.mpn}</Text>
              </>
            )
          );
        },
      },
      {
        Header: "Serial Number",
        accessor: "serialNumber",
        Cell: ({ row }: FixLater) => {
          const asset = row.original;
          return <Text canEdit={false} placeholder="" value={asset.serialNumber} />;
        },
      },
    ];
  }, []);

  useEffect(() => {
    fetch(0, paging.pageLimit);
  }, [paging.pageLimit, query, filters]);

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      const { dirtyDrawers } = session;
      const { data, metadata } = await AssetsAPI.getAssets({
        pageStart,
        pageLimit,
        drawerNumber: Array.isArray(dirtyDrawers) ? dirtyDrawers.join(",") : "",
        assigned: false,
        calibrated: filters.calibrated === null ? undefined : filters.calibrated,
        query: query || undefined,
      });
      setAssets(data);
      setPaging(metadata);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleRowClick = ({ original }: FixLater) => {
    onSelect && onSelect(original);
  };

  return (
    <div className={classNames(style.tableContainer, className)}>
      <SmartTableNav
        paging={paging}
        onPageChange={fetch}
        showQueryInput={true}
        showPageCount={false}
        queryPlaceholder="Search by name or serial number"
        onQueryChange={setQuery}
      >
        <DropSelect
          value={filters.calibrated}
          text="Calibration"
          icon="/images/icons/caret_down_1.svg"
          onChange={(value) => setFilters({ ...filters, calibrated: value })}
          items={[
            {
              label: "Standard",
              description: "Only show tools that dont have calibration configured",
              value: false,
            },
            {
              label: "Calibrated",
              description: "Only show tools that have calibrated configured",
              value: true,
            },
          ]}
        />
      </SmartTableNav>
      <SmartTable columns={columns} data={rowData} onRowClick={handleRowClick} cellClassName={style.tableCell} />
      <SmartTablePagination paging={paging} onChange={fetch} />
    </div>
  );
};

export default ManualCheckoutList;
