import styled from "styled-components";
import Loading from "components/Loading";
import Sidebar from "components/sidebars/Sidebar";

const StyledSidebar = styled(Sidebar)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const SidebarLoading = () => {
  return (
    <StyledSidebar>
      <Loading />
    </StyledSidebar>
  );
};
