//@ts-nocheck
export const timeIntervals = [
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45",
];

const hourInMinutes = 60;

export const durations = [
  ["15 mins", 15],
  ["30 mins", 30],
  ["45 mins", 45],
  ["1 hr", hourInMinutes],
  ["1.5 hrs", 1.5 * hourInMinutes],
  ["2 hrs", 2 * hourInMinutes],
  ["2.5 hrs", 2.5 * hourInMinutes],
  ["3 hrs", 3 * hourInMinutes],
  ["3.5 hrs", 3.5 * hourInMinutes],
  ["4 hrs", 4 * hourInMinutes],
  ["4.5 hrs", 4.5 * hourInMinutes],
  ["5 hrs", 5 * hourInMinutes],
  ["5.5 hrs", 5.5 * hourInMinutes],
  ["6 hrs", 6 * hourInMinutes],
  ["6.5 hrs", 6.5 * hourInMinutes],
  ["7 hrs", 7 * hourInMinutes],
  ["7.5 hrs", 7.5 * hourInMinutes],
  ["8 hrs", 8 * hourInMinutes],
  ["8.5 hrs", 8.5 * hourInMinutes],
  ["9 hrs", 9 * hourInMinutes],
  ["9.5 hrs", 9.5 * hourInMinutes],
  ["10 hrs", 10 * hourInMinutes],
  ["10.5 hrs", 10.5 * hourInMinutes],
  ["11 hrs", 11 * hourInMinutes],
  ["11.5 hrs", 11.5 * hourInMinutes],
  ["12 hrs", 12 * hourInMinutes],
  ["12.5 hrs", 12.5 * hourInMinutes],
  ["13 hrs", 13 * hourInMinutes],
  ["13.5 hrs", 13.5 * hourInMinutes],
  ["14 hrs", 14 * hourInMinutes],
  ["14.5 hrs", 14.5 * hourInMinutes],
  ["15 hrs", 15 * hourInMinutes],
  ["15.5 hrs", 15.5 * hourInMinutes],
  ["16 hrs", 16 * hourInMinutes],
  ["16.5 hrs", 16.5 * hourInMinutes],
  ["17 hrs", 17 * hourInMinutes],
  ["17.5 hrs", 17.5 * hourInMinutes],
  ["18 hrs", 18 * hourInMinutes],
  ["18.5 hrs", 18.5 * hourInMinutes],
  ["19 hrs", 19 * hourInMinutes],
  ["19.5 hrs", 19.5 * hourInMinutes],
  ["20 hrs", 20 * hourInMinutes],
  ["20.5 hrs", 20.5 * hourInMinutes],
  ["21 hrs", 21 * hourInMinutes],
  ["21.5 hrs", 21.5 * hourInMinutes],
  ["22 hrs", 22 * hourInMinutes],
  ["22.5 hrs", 22.5 * hourInMinutes],
  ["23 hrs", 23 * hourInMinutes],
  ["23.5 hrs", 23.5 * hourInMinutes],
  ["24 hrs", 24 * hourInMinutes],
];
