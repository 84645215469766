//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import AlertAPI from "lib/api/alerts";
import Logger from "../Logger";
import * as types from "../actions/actionTypes";
import * as actions from "../actions/alerts";

function* fetchMany(action) {
  try {
    const { data, metadata } = yield AlertAPI.getAlerts(action.payload);
    yield put(actions.fulfillRequestAlerts(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectRequestAlerts(err));
  }
}
function* fetchAlert(action) {
  try {
    const data = yield AlertAPI.getAlert(action.payload);
    yield put(actions.fulfilRequestAlert(data));
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectRequestAlert(err));
  }
}
function* createAlert(action) {
  try {
    const data = yield AlertAPI.createAlert(action.payload);
    yield put(actions.fulfilCreateAlert(data));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectCreateAlert(err));
    action.onFailed && action.onFailed(err);
  }
}
function* updateAlert(action) {
  try {
    const data = yield AlertAPI.updateAlert(action.payload.id, action.payload);
    yield put(actions.fulfilUpdateAlert(data));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectUpdateAlert(err));
    action.onFailed && action.onFailed(err);
  }
}
function* deleteAlert(action) {
  try {
    yield AlertAPI.deleteAlert(action.payload);
    yield put(actions.fulfilDeleteAlert(action.payload));
    action.onSuccess && action.onSuccess(action.payload);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectDeleteAlert(err));
    action.onFailed && action.onFailed(err);
  }
}

function* createRule(action) {
  try {
    const data = yield AlertAPI.createAlertRule(action.payload.alertId, action.payload.key);
    yield put(actions.fulfilCreateAlertRule(data));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectCreateAlertRule(err));
    action.onFailed && action.onFailed(err);
  }
}
function* deleteRule(action) {
  try {
    yield AlertAPI.deleteAlertRule(action.payload.alertId, action.payload.id);
    yield put(actions.fulfilDeleteAlertRule(action.payload));
    action.onSuccess && action.onSuccess(action.payload);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectDeleteAlertRule(err));
    action.onFailed && action.onFailed(err);
  }
}

function* createRecipient(action) {
  try {
    const data = yield AlertAPI.createAlertRecipient(action.payload.alertId, action.payload);
    yield put(actions.fulfilCreateAlertRecipient(data));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectCreateAlertRecipient(err));
    action.onFailed && action.onFailed(err);
  }
}
function* updateRecipient(action) {
  try {
    const data = yield AlertAPI.updateAlertRecipient(action.payload.alertId, action.payload);
    yield put(actions.fulfilUpdateAlertRecipient(data));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectUpdateAlertRecipient(err));
    action.onFailed && action.onFailed(err);
  }
}
function* deleteRecipient(action) {
  try {
    yield AlertAPI.deleteAlertRecipient(action.payload.alertId, action.payload.id);
    yield put(actions.fulfilDeleteAlertRecipient(action.payload));
    action.onSuccess && action.onSuccess(action.payload);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectDeleteAlertRecipient(err));
    action.onFailed && action.onFailed(err);
  }
}

function* alertSaga() {
  yield takeLatest(types.GET_ALERTS_REQUEST, fetchMany);
  yield takeLatest(types.GET_ALERT_REQUEST, fetchAlert);
  yield takeLatest(types.CREATE_ALERT_REQUEST, createAlert);
  yield takeLatest(types.UPDATE_ALERT_REQUEST, updateAlert);
  yield takeLatest(types.DELETE_ALERT_REQUEST, deleteAlert);

  yield takeLatest(types.CREATE_ALERT_RULE_REQUEST, createRule);
  yield takeLatest(types.DELETE_ALERT_RULE_REQUEST, deleteRule);

  yield takeLatest(types.CREATE_ALERT_RECIPIENT_REQUEST, createRecipient);
  yield takeLatest(types.UPDATE_ALERT_RECIPIENT_REQUEST, updateRecipient);
  yield takeLatest(types.DELETE_ALERT_RECIPIENT_REQUEST, deleteRecipient);
}

export default alertSaga;
