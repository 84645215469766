//@ts-nocheck
import React from "react";
import InputError from "../../../lib/InputError";
import Logger from "../../../lib/Logger";
import Api from "../../../lib/api/readers";
import RegisterReaderForm from "./RegisterReaderForm";

class RegisterReaderContainer extends React.Component {
  static propTypes = {};

  state = {
    reader: {
      name: "",
      macAddress: "",
      summary: "",
      description: "",
      location: null,
    },
    error: null,
  };

  onChange = (name, value) =>
    this.setState({
      reader: { ...this.state.reader, [name]: value },
      error: null,
    });

  onSubmit = async () => {
    const { reader } = this.state;
    const { history } = this.props;

    if (!reader.name) {
      return this.setState({
        error: new InputError("Reader name must be provided", "name"),
      });
    }
    if (!reader.macAddress) {
      return this.setState({
        error: new InputError("Reader MAC address must be provided", "macAddress"),
      });
    }

    try {
      const receipt = await Api.createReader({
        ...reader,
        locationId: reader.location ? reader.location.id : 0,
      });
      history(`/configuration/readers/${receipt.id}`);
    } catch (err) {
      Logger.error(err);
    }
  };

  render() {
    const { reader, error } = this.state;
    return <RegisterReaderForm reader={reader} error={error} onChange={this.onChange} onSubmit={this.onSubmit} />;
  }
}

export default RegisterReaderContainer;
