//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { DangerButton } from "components/Buttons";
import { InputGroup, InputGroups, TextInput } from "components/Input";

const ReaderSettings = ({ reader, onDelete }) => (
  <div>
    <InputGroups>
      <InputGroup label="API key">
        <TextInput input={{ name: "apiKey", disabled: true }} value={reader.apiKey} />
      </InputGroup>
      <InputGroup label="Delete reader">
        <div>
          <p className="text-muted">Warning: This action cannot be undone</p>
          <DangerButton onClick={onDelete}>Delete reader</DangerButton>
        </div>
      </InputGroup>
    </InputGroups>
  </div>
);

ReaderSettings.defaultProps = {};

ReaderSettings.propTypes = {
  reader: PropTypes.object,
  onDelete: PropTypes.func,
};

export default ReaderSettings;
