//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class ProjectStatus extends Lookup {
  Unknown = 0;
  Open = 1;
  Active = 2;
  OnHold = 3;
  Completed = 4;
  Cancelled = 5;

  constructor() {
    super();
    super._items = [
      {
        value: this.Open,
        label: "Todo",
        description: "The project is pending",
      },
      {
        value: this.Active,
        label: "In progress",
        description: "The project is being worked on",
      },
      {
        value: this.OnHold,
        label: "Blocked",
        description: "The project is blocked",
      },
      {
        value: this.Completed,
        label: "Completed",
        description: "The project has been completed",
      },
      {
        value: this.Cancelled,
        label: "Cancelled",
        description: "The project has been cancelled",
      },
    ];
  }
}

export default new ProjectStatus();
