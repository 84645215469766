//@ts-nocheck
import LayoutTypes from "../../pages/reporting/reports/ReportPage/LayoutTypes";
import * as type from "../actions/actionTypes";

const templates = [
  {
    id: 1,
    name: "Single table",
    preview: "/images/report-style-1.svg",
    layout: [
      {
        id: 1,
        order: 0,
        type: LayoutTypes.TEXT,
        placeholder: "/images/placeholder-text.svg",
        style: { marginBottom: "20px" },
      },
      {
        id: 2,
        order: 1,
        type: LayoutTypes.TEXT,
        placeholder: "/images/placeholder-text.svg",
        style: { marginBottom: "50px" },
      },
      {
        id: 3,
        order: 2,
        type: LayoutTypes.TABLE,
        placeholder: "/images/placeholder-table.svg",
      },
    ],
  },
  {
    id: 2,
    name: "Dual table",
    preview: "/images/report-style-2.svg",
    layout: [
      {
        id: 4,
        order: 0,
        type: LayoutTypes.TEXT,
        placeholder: "/images/placeholder-text.svg",
        style: { marginBottom: "20px" },
      },
      {
        id: 5,
        order: 1,
        type: LayoutTypes.TEXT,
        placeholder: "/images/placeholder-text.svg",
        style: { marginBottom: "50px" },
      },
      {
        id: 6,
        order: 3,
        type: LayoutTypes.GROUP,
        layout: [
          {
            id: 12,
            order: 0,
            type: LayoutTypes.TABLE,
            placeholder: "/images/placeholder-table-thin.svg",
          },
          {
            id: 14,
            order: 1,
            type: LayoutTypes.TABLE,
            placeholder: "/images/placeholder-table-thin.svg",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Single table with 2 charts",
    preview: "/images/report-style-3.svg",
  },
  {
    id: 4,
    name: "Single table  with 3 charts",
    preview: "/images/report-style-4.svg",
  },
];
const themes = [
  {
    id: 0,
    backgroundColor: "#854DFF",
  },
  {
    id: 1,
    backgroundColor: "#FA6B63",
  },
  {
    id: 2,
    backgroundColor: "#00236C",
  },
  {
    id: 3,
    backgroundColor: "#488AFF",
  },
];

const extractElements = (layout) => {
  if (!Array.isArray(layout)) {
    return null;
  }
  return layout.reduce((elements, element) => {
    if (element.type === LayoutTypes.GROUP) {
      return {
        ...elements,
        ...extractElements(element.layout),
      };
    }
    return {
      ...elements,
      [element.id]: null,
    };
  }, {});
};

const initialState = {
  templates: {
    fetching: false,
    error: null,
    data: templates,
  },
  themes: {
    fetching: false,
    error: null,
    data: themes,
  },
  components: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  generate: {
    generating: false,
    reportId: null,
    error: null,
  },
  template: templates[0],
  theme: null,
  elements: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.REPORT_BUILDER_RESET:
      return initialState;
    case type.REPORT_BUILDER_SELECT_TEMPLATE:
      return {
        ...state,
        template: action.payload,
        elements: extractElements(action.payload.layout),
      };
    case type.REPORT_BUILDER_SELECT_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case type.REPORT_BUILDER_SET_ELEMENTS:
      return {
        ...state,
        elements: action.payload,
      };
    case type.REPORT_BUILDER_SET_ELEMENT_CONTENT:
      const { elementId, content } = action.payload;
      return {
        ...state,
        elements: {
          ...state.elements,
          [elementId]: content,
        },
      };
    case type.REPORT_BUILDER_GENERATE_REPORT_REQUEST:
      return {
        ...state,
        generate: {
          generating: true,
          reportId: null,
          error: null,
        },
      };
    case type.REPORT_BUILDER_GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        generate: {
          generating: false,
          reportId: action.payload,
          error: null,
        },
      };
    case type.REPORT_BUILDER_GENERATE_REPORT_FAILURE:
      return {
        ...state,
        generate: {
          generating: false,
          reportId: null,
          error: action.payload,
        },
      };
    case type.REPORT_BUILDER_GET_REPORT_COMPONENTS_REQUEST:
      return {
        ...state,
        components: {
          ...state.components,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.REPORT_BUILDER_GET_REPORT_COMPONENTS_SUCCESS:
      return {
        ...state,
        components: {
          ...state.components,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.REPORT_BUILDER_GET_REPORT_COMPONENTS_FAILURE:
      return {
        ...state,
        components: {
          ...state.components,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    default:
      return state;
  }
};
