//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class ReasonCodes extends Lookup {
  // Granted reasons [1-99]
  ReasonCodeOK = 1;
  ReasonCodeAnyone = 2;
  ReasonCodeGroup = 3;
  ReasonCodeProject = 4;
  ReasonCodeRole = 5;
  ReasonCodeUserMatch = 6;
  ReasonCodeKeycard = 7;

  // Denied reason [100-199]
  ReasonCodeNoMatch = 100;
  ReasonCodeNoKeycard = 101;
  ReasonCodeNoMobileApp = 102;
  ReasonCodeGrantNotActiveYet = 103;
  ReasonCodeGrantExpired = 104;
  ReasonCodeGrantDisabled = 105;
  ReasonCodeGrantKeycardRevoked = 106;

  // Failed reasons [200-299]
  ReasonCodeAttemptError = 200;
  ReasonCodeStoreAttemptFailed = 201;
  ReasonCodeTrackingModeRequiresAssignedKeycard = 202;

  constructor() {
    super();
    super._items = [
      {
        label: "OK",
        description: "",
        value: this.ReasonCodeOK,
      },
      {
        label: "Anyone",
        description: "",
        value: this.ReasonCodeAnyone,
      },
      {
        label: "Group",
        description: "",
        value: this.ReasonCodeGroup,
      },
      {
        label: "Project",
        description: "",
        value: this.ReasonCodeProject,
      },
      {
        label: "Role",
        description: "",
        value: this.ReasonCodeRole,
      },
      {
        label: "User",
        description: "",
        value: this.ReasonCodeUserMatch,
      },
      {
        label: "Keycard",
        description: "",
        value: this.ReasonCodeKeycard,
      },
      {
        label: "No Match",
        description: "",
        value: this.ReasonCodeNoMatch,
      },
      {
        label: "Keycard Disallowed",
        description: "",
        value: this.ReasonCodeNoKeycard,
      },
      {
        label: "Mobile App Disallowed",
        description: "",
        value: this.ReasonCodeNoMobileApp,
      },
      {
        label: "Rule Not Active Yet",
        description: "",
        value: this.ReasonCodeGrantNotActiveYet,
      },
      {
        label: "Rule Expired",
        description: "",
        value: this.ReasonCodeGrantExpired,
      },
      {
        label: "Rule Disabled",
        description: "",
        value: this.ReasonCodeGrantDisabled,
      },
      {
        label: "Keycard Revoked",
        description: "",
        value: this.ReasonCodeGrantKeycardRevoked,
      },
      {
        label: "Internal Error",
        description: "",
        value: this.ReasonCodeAttemptError,
      },
      {
        label: "Internal Store Error",
        description: "",
        value: this.ReasonCodeStoreAttemptFailed,
      },
      {
        label: "Tracking Mode requires assigned keycard",
        description: "",
        value: this.ReasonCodeTrackingModeRequiresAssignedKeycard,
      },
    ];
  }
}

export default new ReasonCodes();
