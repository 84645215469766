import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { ToolStorageProvider } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { useDispatch, useSelector } from "store";
import {
  createStorageViewer,
  fetchStorageById,
  fetchToolStoreById,
  selectStorageByID,
  selectToolStoreByID,
} from "store/reducers";
import { PageLoading } from "components/shared";
import PageStage from "components/stages/PageStage";
import { ToolStoreInventoryPage, ToolStoreOrdersPage } from "pages/features/toolStores";
import { ToolStoreSettingsPage } from "pages/features/toolStores/ToolStoreSettingsPage/ToolStoreSettingsPage";

export const ToolStorePage = () => {
  const dispatch = useDispatch();
  const { pathname: url } = useLocation();
  const { toolStoreID } = useParams();
  const entity = useSelector(selectToolStoreByID(toolStoreID || ""));
  const storageID = entity?.storageId;
  const storage = useSelector(selectStorageByID(storageID || ""));

  // Fetch the tool store if it is not in memory yet
  useEffect(() => {
    if (!toolStoreID) return;
    if (entity) return;
    dispatch(fetchToolStoreById(toolStoreID));
  }, [toolStoreID, entity]);

  useEffect(() => {
    if (!storageID) return;
    if (storage) return;
    dispatch(fetchStorageById(storageID));
  }, [storageID, storage]);

  useEffect(() => {
    if (!storageID) return;
    dispatch(createStorageViewer(storageID));
  }, [storageID]);

  if (!entity || !storage || !storageID || !toolStoreID) {
    return <PageLoading />;
  }

  return (
    <ToolStorageProvider storageId={storageID}>
      <PageStage>
        <Routes>
          <Route path="orders" element={<ToolStoreOrdersPage />} />
          <Route path="inventory" element={<ToolStoreInventoryPage />} />
          <Route path="settings" element={<ToolStoreSettingsPage />} />
          <Route path="" element={<Navigate replace to={`${url}/orders`} />} />
        </Routes>
      </PageStage>
    </ToolStorageProvider>
  );
};
