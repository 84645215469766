import { useToolStorage } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { showPopupFlag } from "lib/actions/userInterface";
import Apps from "lib/constants/Apps";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectStorageByID, updateStorage } from "store/reducers";
import { DataGrid, DataItem } from "components/DataGrid";
import { RestrictByApp } from "components/Restricted";
import StageHeader from "components/StageHeader";
import PowerInput from "components/inputs/PowerInput";
import { PageLoading, VSpace } from "components/shared";

export const CaseScannerAccessControlPage = () => {
  const dispatch = useDispatch();
  const { storageId } = useToolStorage();
  const storage = useSelector(selectStorageByID(storageId || ""));

  const toggleRequestVehicle = async () => {
    try {
      if (!storage) return;
      const newStorage = {
        ...storage,
        requestVehicle: !storage.requestVehicle,
      };
      await dispatch(updateStorage(newStorage)).unwrap();
      dispatch(
        showPopupFlag({
          title: `${newStorage.requestVehicle ? "Requesting" : "Not requesting"} vehicles`,
          appearance: newStorage.requestVehicle ? "success" : "info",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (!storage) {
    return <PageLoading />;
  }

  return (
    <>
      <StageHeader title="Manage access" />
      <VSpace gap="gap-8">
        <DataGrid>
          <RestrictByApp app={Apps.VEHICLES}>
            <DataItem
              label="Request vehicle"
              description="Ask users to select a vehicle before scanning the case"
              value={<PowerInput value={storage.requestVehicle} onChange={toggleRequestVehicle} />}
            />
          </RestrictByApp>
        </DataGrid>
      </VSpace>
    </>
  );
};
