import LocationTypes from "lib/constants/LocationTypes";
import { LookupItem } from "lib/constants/Lookup";
import { LocationType } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

type LocationTypeSelectorProps = {
  name: string;
  value?: LocationType;
  onChange: (value: LocationType) => void;
  placeholder?: string;
  appearance?: "inline" | "normal";
  searchable?: boolean;
  className?: string;
};

export const LocationTypeSelector = ({
  name,
  value,
  onChange,
  placeholder = "Select type",
  appearance = "normal",
  searchable = false,
  className,
}: LocationTypeSelectorProps): JSX.Element => {
  const input = {
    type: "select",
    name,
    placeholder,
    options: LocationTypes.items,
    isSearchable: searchable,
  };

  return appearance === "inline" ? (
    <Text
      canEdit
      input={input}
      value={LocationTypes.resolveItem(value)}
      onConfirm={(value: LookupItem<LocationType>) => {
        onChange(value?.value);
      }}
      className={className}
    />
  ) : (
    <SelectInput
      input={input}
      value={LocationTypes.resolveItem(value)}
      onChange={(name: string, value: LookupItem<LocationType>) => {
        onChange(value?.value);
      }}
      className={className}
    />
  );
};
