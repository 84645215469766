import React from "react";
import { RiSignalTowerFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/outline";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { isSynchronisingRagControllerRags, synchroniseRagControllerRags } from "store/reducers";
import { TRagBin, UUID } from "types";
import StageHeader from "components/StageHeader";
import { ButtonGroup, CreateRagBinModal, RagBinsTable } from "components/shared";
import { SecondaryButton } from "components/shared/buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import PageStage from "components/stages/PageStage";

type RagControllerRagBinsPageProps = {
  ragControllerId: UUID;
};

export const RagControllerRagBinsPage = ({ ragControllerId }: RagControllerRagBinsPageProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const synchronising = useSelector(isSynchronisingRagControllerRags);

  const createRagBin = () => {
    openModal(
      <CreateRagBinModal
        ragControllerId={ragControllerId}
        onSuccess={(entity: TRagBin) => {
          navigate(`/apps/storage/rag_controllers/${entity.ragControllerId}/bins/${entity.id}`);
        }}
      />,
    );
  };

  const synchroniseControllerRags = () => {
    openModal(
      <ConfirmModal
        description="You are about to inventory all the rag bins in this rag controller. This action will reset the rags associated with the controller."
        onConfirm={async () => {
          try {
            const ragsFoundCount = await dispatch(
              synchroniseRagControllerRags({
                ragControllerId,
              }),
            ).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: `Found ${ragsFoundCount} rags in the rag bins`,
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Rag bins"
        subtitle="Manage the bins assigned to your rag controller."
        action={
          <ButtonGroup>
            <SecondaryButton
              leadingIcon={RiSignalTowerFill}
              onClick={synchroniseControllerRags}
              loading={synchronising}
            >
              Synchronise
            </SecondaryButton>
            <SecondaryButton leadingIcon={PlusIcon} onClick={createRagBin}>
              Create bin
            </SecondaryButton>
          </ButtonGroup>
        }
      />
      <RagBinsTable ragControllerId={ragControllerId} />
    </PageStage>
  );
};
