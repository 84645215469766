//@ts-nocheck
import Api from "./api";

class TaskAPI extends Api {
  /**
   * Get tasks
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, metadata: Object}>}
   */
  static async getTasks(queryParams = {}) {
    const response = await this.get("/tasks").query(queryParams);
    return response.body;
  }

  /**
   * Get a task
   * @async
   * @param {string} taskID
   * @returns {Promise.<Object>}
   */
  static async getTask(taskID) {
    const response = await this.get(`/tasks/${taskID}`);
    return response.body.data;
  }

  /**
   * Create task
   * @async
   * @param {Object} task
   * @returns {Promise.<Object>}
   */
  static async createTask(task) {
    const response = await this.post("/tasks").send(task);
    return response.body.data;
  }

  /**
   * Update task
   * @param {string} taskID
   * @param {Object} task
   * @returns {Promise.<Object>}
   */
  static async updateTask(taskID, task) {
    return await this.put(`/tasks/${taskID}`).send(task);
  }

  /**
   * Delete task
   * @param {string} taskID
   * @returns {Promise<void>}
   */
  static async deleteTask(taskID) {
    return await this.delete(`/tasks/${taskID}`).send();
  }

  /**
   * Get task blocks
   * @async
   * @param {string} taskID
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, metadata: Object}>}
   */
  static async getTaskBlocks(taskID, queryParams = {}) {
    const response = await this.get(`/tasks/${taskID}/blocks`).query(queryParams);
    return response.body;
  }

  /**
   * Update or reorder task blocks
   * @async
   * @param {string} taskID
   * @param {Array} taskBlocks
   * @returns {Promise}
   */
  static async updateTaskBlocks(taskID, taskBlocks) {
    return await this.put(`/tasks/${taskID}/blocks`).send(taskBlocks);
  }

  /**
   * Get task lists
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Array>}
   */
  static async getTaskLists(queryParams = {}) {
    const response = await this.get(`/task_lists`).query({
      ...queryParams,
      id: Array.isArray(queryParams.id) ? queryParams.id.join(",") : queryParams.id,
    });
    return response.body;
  }

  /**
   * Get a task list by ID
   * @async
   * @param {number} taskListID
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Array>}
   */
  static async getTaskList(taskListID, queryParams = {}) {
    const response = await this.get(`/task_lists/${taskListID}`).query(queryParams);
    return response.body.data;
  }

  /**
   * Create a task list
   * @async
   * @param {Object} taskList
   * @returns {Promise.<Object>}
   */
  static async createTaskList(taskList) {
    const response = await this.post("/task_lists").send(taskList);
    return response.body.data;
  }

  /**
   * Update a task list
   * @param {number} taskListID
   * @param {Object} taskList
   * @returns {Promise.<Object>}
   */
  static async updateTaskList(taskListID, taskList) {
    return await this.put(`/task_lists/${taskListID}`).send(taskList);
  }

  /**
   * Reorder a task list
   * @param {number} taskListID
   * @param {Array.<Object>} taskListTasks
   * @returns {Promise.<Object>}
   */
  static async reorderTaskList(taskListID, taskListTasks) {
    return await this.put(`/task_lists/${taskListID}/tasks`).send(taskListTasks);
  }

  /**
   * Delete a task list
   * @param {number} taskListID
   * @returns {Promise<void>}
   */
  static async deleteTaskList(taskListID) {
    return await this.delete(`/task_lists/${taskListID}`).send();
  }
}

export default TaskAPI;
