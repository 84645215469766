//@ts-nocheck
import React from "react";
import { Element } from "react-scroll";
import PropTypes from "prop-types";
import Avatar from "components/Avatar";
import StatusPill from "components/StatusPill";
import { Title } from "components/Typography";
import { VSpace } from "components/shared";
import { InfoText } from "pages/marketing/components/Common";
import style from "pages/marketing/marketing.module.scss";

const MarketingWorkspaceAvailable = ({ className }) => {
  return (
    <div className={style.workspaceAvailable}>
      <VSpace>
        <Element name="where-to-buy">
          <Title>Workspace available!</Title>
        </Element>
        <div>
          <StatusPill color="grey" text={window.location.hostname} className={style.workspacePill} />
        </div>
      </VSpace>
      <InfoText>Let's talk and make this the new home for your business.</InfoText>
      <div className={style.whereToBuy}>
        <a
          href="https://www.toolraptors.co.uk/product-page/smart-access-roll-cab-key-card-access"
          target="_blank"
          rel="noreferrer"
        >
          <Avatar
            image="/images/toolraptors_logo.svg"
            alt="Tool Raptors"
            onClick={() => null}
            hoverIcon="/images/icons/export_1.svg"
            className={style.partner}
            imageClassName={style.partnerImage}
          />
        </a>
        <a href="https://www.tengtools.com/r/gb/en/contact-us" target="_blank" rel="noreferrer">
          <Avatar
            image="/images/clients_teng_tools.png"
            alt="Teng Tools"
            onClick={() => null}
            hoverIcon="/images/icons/export_1.svg"
            className={style.partner}
            imageClassName={style.partnerImage}
          />
        </a>
      </div>
    </div>
  );
};

MarketingWorkspaceAvailable.defaultProps = {};

MarketingWorkspaceAvailable.propTypes = {
  className: PropTypes.string,
};

export default MarketingWorkspaceAvailable;
