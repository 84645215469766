//@ts-nocheck
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getComponents } from "lib/reducers/reportBuilder.selectors";
import { useSelector } from "store";
import LayoutTypes from "pages/reporting/reports/ReportPage/LayoutTypes";
import Panel from "./Panel";
import extractIntoCategories from "./extractIntoCategories";

const TextPanel = (props) => {
  const components = useSelector(getComponents);
  const textComponents = useMemo(() => components.filter(({ typeId }) => typeId === LayoutTypes.TEXT), [components]);
  const categories = useMemo(() => Object.values(textComponents.reduce(extractIntoCategories, {})), [textComponents]);

  return <Panel {...props} categories={categories} />;
};

TextPanel.defaultProps = {};

TextPanel.propTypes = {
  layout: PropTypes.object,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  closeDropdown: PropTypes.func,
};

export default TextPanel;
