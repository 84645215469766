import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useDebounce from "effects/useDebounce";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchAssets, FetchAssetsParams, fetchToolStoreById, selectToolStoreByID } from "store/reducers";
import Loading from "components/Loading";
import { useToolStoreOrder } from "components/shared/features/toolStoreOrders/ToolStoreOrderProvider/ToolStoreOrderProvider";
import { ToolStoreAssetsListItem, ToolStoreInventorySearch } from "components/shared/features/toolStores";
import { ToolStoreFooterButton } from "components/shared/features/toolStores/ToolStoreFooterButton/ToolStoreFooterButton";

export const CreateToolStoreOrderForm = () => {
  const dispatch = useDispatch();
  const { toolStoreId } = useParams();
  const { setToolStore } = useToolStoreOrder();
  const [toolStoreAssetIds, setToolStoreAssetIds] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const toolStore = useSelector(selectToolStoreByID(toolStoreId || ""));
  const [filters, setFilters] = useState<FetchAssetsParams>({});
  const debouncedFilters = useDebounce(filters, 500);

  useEffect(() => {
    if (toolStoreId) {
      dispatch(fetchToolStoreById(toolStoreId));
    }
  }, [toolStoreId]);

  useEffect(() => {
    // TODO: Add query params
    fetchToolStoreAssets();
  }, [debouncedFilters]);

  useEffect(() => {
    if (toolStore) {
      setToolStore(toolStore);
    }
  }, [toolStore]);

  const fetchToolStoreAssets = async () => {
    try {
      setLoading(true);
      const { results } = await dispatch(
        fetchAssets({
          ...filters,
          pageLimit: 9999,
          toolStoreId: toolStoreId,
        }),
      ).unwrap();
      setToolStoreAssetIds(results.map(({ id }) => id));
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="py-6">
        <ToolStoreInventorySearch value={filters} onChange={setFilters} />
      </div>
      {loading && (
        <div className="py-4 px-6 flex justify-center">
          <Loading />
        </div>
      )}
      <ul className="flex-1 -mx-6 md:mx-0 pl-0 divide-y divide-gray-200 border-b border-t border-gray-200">
        {toolStoreAssetIds.map((id) => (
          <ToolStoreAssetsListItem key={id} assetId={id} />
        ))}
      </ul>
      <ToolStoreFooterButton />
    </div>
  );
};
