import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.users;

export const selectUsers = createSelector(selectState, (state) => state.data);

export const selectUsersList = createSelector(selectUsers, (data) => Object.values(data));

export const selectUserByID = (id: number) => createSelector(selectUsers, (data) => data[id]);

export const selectUserByGUID = (id: UUID) =>
  createSelector(selectUsersList, (list) => list.find((u) => u.guid === id));
