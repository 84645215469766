//@ts-nocheck
import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import MarketingEdgeProduct from "pages/marketing/components/MarketingEdgeProduct";
import MarketingFeatures from "pages/marketing/components/MarketingFeatures";
import MarketingFooter from "pages/marketing/components/MarketingFooter";
import MarketingHero from "pages/marketing/components/MarketingHero";
import MarketingPricing from "pages/marketing/components/MarketingPricing";
import NavBar from "pages/marketing/components/NavBar";
import style from "pages/marketing/marketing.module.scss";

const MarketingPage = () => {
  return (
    <div className={style.page}>
      <Helmet>
        <title>Automify</title>
        <link rel="icon" type="image/png" href="/images/target.png" />
      </Helmet>

      <NavBar />
      <MarketingHero />
      <MarketingEdgeProduct />
      <MarketingFeatures />
      <MarketingPricing />
      <MarketingFooter />
    </div>
  );
};

MarketingPage.defaultProps = {};

MarketingPage.propTypes = {
  className: PropTypes.string,
};

export default MarketingPage;
