//@ts-nocheck
import React from "react";
import classNames from "classnames";
import ProjectPriority from "lib/constants/ProjectPriority";
import { FixLater } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";
import styles from "./ProjectPrioritySelector.module.scss";

export const ProjectPrioritySelector = ({
  name,
  value,
  onChange,
  placeholder = "Select priority",
  appearance = "normal",
  className,
  ...otherProps
}: FixLater) => {
  const input = {
    type: "select",
    name,
    placeholder: placeholder,
    options: ProjectPriority.items,
  };

  return appearance === "inline" ? (
    <Text
      {...otherProps}
      value={value}
      canEdit={true}
      input={input}
      onConfirm={(value) => onChange(name, value)}
      className={className}
    />
  ) : (
    <SelectInput
      {...otherProps}
      input={input}
      value={value}
      onChange={onChange}
      className={classNames(styles.ProjectPrioritySelector, className)}
    />
  );
};
