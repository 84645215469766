//@ts-nocheck
import { createSelector } from "reselect";

export const getTemplates = createSelector(
  (state) => state.reportBuilder,
  (state = {}) => {
    return state.templates.data;
  },
);

export const getTemplate = createSelector(
  (state) => state.reportBuilder,
  (state = {}) => {
    return state.template;
  },
);

export const getThemes = createSelector(
  (state) => state.reportBuilder,
  (state = {}) => {
    return state.themes.data;
  },
);

export const getComponents = createSelector(
  (state) => state.reportBuilder,
  (state = {}) => {
    return state.components.data;
  },
);

export const getElements = createSelector(
  (state) => state.reportBuilder,
  (state = {}) => {
    return state.elements;
  },
);
