import { useEffect } from "react";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchManufacturers, selectManufacturersPage } from "store/reducers";
import { TManufacturer, UUID } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

type CatalogueManufacturerSelectorProps = {
  name: string;
  value?: TManufacturer | UUID;
  onChange: (name: string, value: TManufacturer) => void;
  placeholder?: string;
  appearance?: "inline" | "normal";
  searchable?: boolean;
  menuPlacement?: string;
  pageLimit?: number;
  className?: string;
};

export const CatalogueManufacturerSelector = ({
  name,
  value,
  onChange,
  placeholder = "Select a product",
  appearance = "normal",
  className,
  menuPlacement,
  pageLimit = 999,
  searchable = false,
}: CatalogueManufacturerSelectorProps) => {
  const dispatch = useDispatch();
  const entities = useSelector(selectManufacturersPage);
  const input = {
    type: "select",
    name,
    placeholder,
    labelKey: "label",
    valueKey: "id",
    menuPlacement,
    options: entities.map((entity) => ({
      ...entity,
      label: entity.name,
    })),
    isSearchable: searchable,
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      await dispatch(fetchManufacturers({ pageLimit }));
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return appearance === "inline" ? (
    <Text
      canEdit
      input={input}
      value={value as any}
      onConfirm={(value) => onChange(name, value)}
      className={className}
    />
  ) : (
    <SelectInput input={input} value={value} onChange={onChange} className={className} />
  );
};
