import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { fetchToolStoreById, selectToolStoreByID } from "store/reducers";
import { PageLoading, ToolStorePageHeader, ToolStoreSettings } from "components/shared";
import PageStage from "components/stages/PageStage";

export const ToolStoreSettingsPage = () => {
  const dispatch = useDispatch();
  const { toolStoreID } = useParams();
  const entity = useSelector(selectToolStoreByID(toolStoreID || ""));

  useEffect(() => {
    if (!entity && toolStoreID) {
      dispatch(fetchToolStoreById(toolStoreID));
    }
  }, [entity]);

  if (!entity || !toolStoreID) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <ToolStorePageHeader toolStoreId={toolStoreID} />
      <ToolStoreSettings toolStoreId={toolStoreID} />
    </PageStage>
  );
};
