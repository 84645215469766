//@ts-nocheck
import React, { useState } from "react";
import classNames from "classnames";
import useDebounce from "effects/useDebounce";
import useDidMount from "effects/useDidMount";
import PropTypes from "prop-types";
import { CheckboxInput } from "components/Input";
import EditableText from "components/Sheet/components/EditableText";
import style from "components/Sheet/sheet.module.scss";

const ToDoBlock = React.forwardRef(({ block, tag = "div", onChange, onAddBlock, onRemoveBlock, className }, ref) => {
  const [values, setValues] = useState(block);
  const debouncedValues = useDebounce(values, 400);
  const { text, checked } = values.properties;

  useDidMount(() => {
    onChange(values);
  }, [debouncedValues]);

  const handleChange = (name, value) => {
    setValues({
      ...block,
      properties: {
        ...block.properties,
        [name]: value,
      },
    });
  };

  return (
    <div ref={ref} className={classNames(style.flex, style.toDoBlock, className)}>
      <CheckboxInput input={{ name: "checked" }} value={checked} onChange={handleChange} />
      <EditableText
        value={text}
        tag={tag}
        onChange={(value) => handleChange("text", value)}
        onAddBlock={onAddBlock}
        onRemoveBlock={onRemoveBlock}
      />
    </div>
  );
});

ToDoBlock.defaultProps = {};

ToDoBlock.propTypes = {
  className: PropTypes.string,
};

export default ToDoBlock;
