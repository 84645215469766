import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useToolStorage } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import AssetsAPI from "lib/api/assets";
import Capabilities from "lib/constants/Capabilities";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchAssets, FetchAssetsParams, selectAssets, updateAsset } from "store/reducers";
import { FixLater, Pagination, TAsset, TLocation } from "types";
import { SecondaryButton } from "components/Buttons";
import { useRoleHasCapability } from "components/Restricted";
import StageHeader from "components/StageHeader";
import StatusPill from "components/StatusPill";
import { SmartTable, SmartTableNav, SmartTablePagination } from "components/Table/SmartTable";
import { TableLink } from "components/Table/TableLink/TableLink";
import { Text } from "components/Typography";
import DropSelect from "components/dropdowns/DropSelect";
import { LocationSelector } from "components/inputs";
import { AssignStorageAssetModal, PageLoading } from "components/shared";
import { StorageAssetRowDropdown } from "components/shared/features/assets/StorageAssetRowDropdown/StorageAssetRowDropdown";
import PageStage from "components/stages/PageStage";
import { removeAsset } from "pages/storages/ListStorage/components/StorageInventoryPage/common";

export const ToolRoomInventoryPage = () => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { toolRoomID } = useParams();
  const { storageId } = useToolStorage();
  const assets = useSelector(selectAssets);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState<number[]>([]);
  const [filters, setFilters] = useState({
    query: "",
    assigned: null,
    calibrated: null,
  });
  const [paging, setPaging] = useState<Pagination>({
    pageStart: 0,
    pageLimit: 20,
    pageCount: 0,
    totalCount: 0,
    nextPageStart: null,
    previousPageStart: null,
  });

  const updateLocation = (asset: TAsset) => {
    return async (name: string, value?: TLocation) => {
      try {
        await dispatch(
          updateAsset({
            ...asset,
            locationId: value?.id,
          }),
        ).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Location updated",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const rowData = useMemo(() => {
    return ids.map((id) => assets[id]).filter(Boolean);
  }, [ids, assets]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        Key: "name",
        Cell: ({ row }: FixLater) => {
          const asset: TAsset = row.original;
          return <TableLink to={`/apps/tracking/assets/${asset.id}`}>{asset.catalogProduct?.name}</TableLink>;
        },
      },
      {
        Header: "Serial Number",
        Key: "serialNumber",
        Cell: ({ row }: FixLater) => {
          const asset: TAsset = row.original;
          return (
            <Text
              canEdit={useRoleHasCapability(Capabilities.ASSET_UPDATE)}
              placeholder="Add serial number"
              value={asset.serialNumber}
              onConfirm={saveAssetSerialNumber(asset)}
            />
          );
        },
      },
      {
        Header: "Status",
        Key: "status",
        Cell: ({ row }: FixLater) => {
          const asset: TAsset = row.original;
          return asset.assignment ? (
            <StatusPill text="In Use" color="warning" />
          ) : (
            <StatusPill text="Available" color="success" />
          );
        },
      },
      {
        Key: "Location",
        Header: "Location",
        Cell: ({ row }: FixLater) => {
          const asset: TAsset = row.original;
          return (
            <LocationSelector
              appearance="inline"
              name="locationId"
              placeholder="Tool location"
              value={asset.locationId}
              onChange={updateLocation(asset)}
              types={["shelf", "bay"]}
            />
          );
        },
      },
      {
        Header: "Actions",
        Key: "action",
        Cell: ({ row }: FixLater) => {
          const asset: TAsset = row.original;
          return (
            <StorageAssetRowDropdown
              assetId={asset.id}
              onDelete={() => removeAsset(storageId, asset.id, () => fetch(paging.pageStart, paging.pageLimit))}
            />
          );
        },
      },
    ];
  }, [paging.pageStart, paging.pageLimit]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      if (!storageId) {
        return;
      }
      setLoading(true);
      const params: FetchAssetsParams = {
        pageStart,
        pageLimit,
        storageId,
      };
      if (filters.query) params.query = filters.query;
      if (filters.assigned !== null) params.assigned = filters.assigned;
      if (filters.calibrated !== null) params.calibrated = filters.calibrated;
      const { results, paging } = await dispatch(fetchAssets(params)).unwrap();
      setIds(results.map(({ id }) => id));
      setPaging(paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (storageId) {
      fetch(paging.pageStart, paging.pageLimit);
    }
  }, [storageId, filters, paging.pageStart, paging.pageLimit]);

  const saveAssetSerialNumber = (asset: FixLater) => {
    return async (serialNumber = "") => {
      try {
        await AssetsAPI.updateAsset(asset.id, {
          ...asset,
          serialNumber,
        });
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Saved Serial Number",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const assignTool = () => {
    storageId &&
      openModal(
        <AssignStorageAssetModal
          title="Add to your tool room"
          storageId={storageId}
          onSuccess={() => {
            fetch(paging.pageStart, paging.pageLimit);
          }}
        />,
      );
  };

  if (!storageId || !toolRoomID) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <StageHeader
        action={
          <SecondaryButton icon="/images/icons/plus_1.svg" onClick={assignTool}>
            Add tool
          </SecondaryButton>
        }
      />
      <SmartTableNav
        paging={paging}
        onPageChange={fetch}
        showQueryInput={true}
        queryPlaceholder="Search by name or serial number"
        onQueryChange={(query: string) => setFilters((prevState) => ({ ...prevState, query }))}
      >
        <DropSelect
          value={filters.assigned}
          text="Status"
          icon="/images/icons/caret_down_1.svg"
          onChange={(value) => setFilters({ ...filters, assigned: value })}
          items={[
            {
              label: "In Use",
              description: "Only show tools that are currently being used by someone",
              value: true,
            },
            {
              label: "Stored",
              description: "Only show tools that are currently stored",
              value: false,
            },
          ]}
        />
        <DropSelect
          value={filters.calibrated}
          text="Calibration"
          icon="/images/icons/caret_down_1.svg"
          onChange={(value) => setFilters({ ...filters, calibrated: value })}
          items={[
            {
              label: "Standard",
              description: "Only show tools that dont have calibration configured",
              value: false,
            },
            {
              label: "Calibrated",
              description: "Only show tools that have calibrated configured",
              value: true,
            },
          ]}
        />
      </SmartTableNav>
      <SmartTable loading={loading} columns={columns} data={rowData} />
      <SmartTablePagination paging={paging} onChange={fetch} />
    </PageStage>
  );
};
