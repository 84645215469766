import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TUser } from "types";

const selectState = (state: RootStore) => state.settings;

export const selectMainSideBarCollapsed = createSelector(selectState, (state) => state.mainSideBarCollapsed);

export const selectWorkspace = createSelector(selectState, (state): string | undefined => state.workspace);
export const selectMe = createSelector(selectState, (state): TUser | undefined => state.me);
