//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "./stagesection.module.scss";

const StageSection = ({ className, isFirst, children }) => (
  <div className={classNames(style.section, className)}>
    {isFirst || <Separator />}
    <section>{children}</section>
  </div>
);

StageSection.defaultProps = {
  isFirst: false,
};

StageSection.propTypes = {
  isFirst: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
};

const Separator = () => <div className={style.separator} />;

export default StageSection;
