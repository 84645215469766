//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Resolve from "lib/Resolve";
import StorageModes from "lib/constants/StorageModes";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

class SelectStorageModeInput extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      requestManufacturers: PropTypes.func,
    }),
    placeholder: PropTypes.string,
    appearance: PropTypes.oneOf(["normal", "inline"]),
    stateStore: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    appearance: "normal",
    placeholder: "Select storage mode",
  };

  constructor(props) {
    super(props);
    const appsAvailable = Resolve.resolveCustomerApps();
    this.state = {
      modes: StorageModes.filterByApps(appsAvailable),
    };
  }

  render() {
    const { name, value, placeholder, onChange, appearance, className, ...otherProps } = this.props;
    const { modes } = this.state;
    const input = {
      type: "select",
      name,
      placeholder: placeholder,
      labelKey: "label",
      valueKey: "value",
      options: modes,
    };

    return appearance === "inline" ? (
      <Text {...otherProps} value={value} canEdit={true} input={input} onConfirm={(value) => onChange(name, value)} />
    ) : (
      <SelectInput {...otherProps} input={input} value={value} onChange={onChange} className={className} />
    );
  }
}

export default SelectStorageModeInput;
