//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  name: null,
  props: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.MODAL_OPEN:
      return {
        name: action.name,
        props: Object.assign({}, action.props),
      };
    case type.MODAL_CLOSE:
      return initialState;
    default:
      return state;
  }
};
