export default class Modals {
  static INVITE_USER = "INVITE_USER";
  static CONFIRMATION = "CONFIRMATION";

  static CREATE_ROLE = "CREATE_ROLE";

  static CREATE_KEYCARD = "CREATE_KEYCARD";

  static MANUAL_CHECKOUT = "MANUAL_CHECKOUT";
  static MANUAL_CHECKIN = "MANUAL_CHECKIN";

  static ACCESS_RULE_CREATE = "ACCESS_RULE_CREATE";
  static ACCESS_RULE_VIEW = "ACCESS_RULE_VIEW";

  static CREATE_STORAGE = "CREATE_STORAGE";
  static CONFIGURE_STORAGE_DRAWER_LAYOUT = "CONFIGURE_STORAGE_DRAWER_LAYOUT";
  static CONFIGURE_STORAGE_ASSET_LAYOUT = "CONFIGURE_STORAGE_ASSET_LAYOUT";

  static CREATE_ASSET = "CREATE_ASSET";
  static ASSIGN_STORAGE_ASSET = "ASSIGN_STORAGE_ASSET";
  static IMPORT_ASSETS = "IMPORT_ASSETS";

  static CREATE_REPORT = "CREATE_REPORT";

  static CREATE_PROJECT = "CREATE_PROJECT";
  static CREATE_TASK = "CREATE_TASK";
  static CREATE_PROJECT_TEMPLATE = "CREATE_PROJECT_TEMPLATE";

  static CREATE_STORE_ITEM = "CREATE_STORE_ITEM";

  static CREATE_ALERT = "CREATE_ALERT";

  static CREATE_MANUFACTURER = "CREATE_MANUFACTURER";
  static CREATE_MANUFACTURER_PRODUCT = "CREATE_MANUFACTURER_PRODUCT";
  static IMPORT_MANUFACTURER_PRODUCT = "IMPORT_MANUFACTURER_PRODUCT";
}
