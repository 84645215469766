//@ts-nocheck

/**
 * Returns yes if the asset is due for calibration
 * @param asset
 */
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import utc from "dayjs/plugin/utc";
import CalibrationTrackingMethods from "lib/constants/CalibrationTrackingMethods";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

export const isPassedNextDue = (nextDue) => {
  if (!nextDue) {
    return false;
  }
  return dayjs.utc().isSameOrAfter(dayjs.utc(nextDue));
};

export const hasSurpassActuations = (count, limit) => {
  return count >= limit;
};

/**
 * Returns true when there are only 7 days left before calibration is due
 * @returns {boolean}
 */
export const isNearNextDue = (nextDue) => {
  if (!nextDue) {
    return false;
  }
  return dayjs.utc().add(7, "day").isSameOrAfter(dayjs.utc(nextDue));
};

/**
 * Returns true when 85% of the actuations limit is used
 * @param count
 * @param limit
 * @returns {boolean}
 */
export const isNearActuations = (count, limit) => {
  return count >= limit * 0.85;
};

export default (asset) => {
  if (!asset || !asset.calibrationConfig) {
    return false;
  }

  const { methodId, actuationCount, nextDue, actuationInterval } = asset.calibrationConfig;

  const nextDuePassed = isPassedNextDue(nextDue);
  const actuationsSurpassed = hasSurpassActuations(actuationCount, actuationInterval);

  switch (methodId) {
    case CalibrationTrackingMethods.TIME:
      return nextDuePassed;
    case CalibrationTrackingMethods.ACTUATION:
      return actuationsSurpassed;
    case CalibrationTrackingMethods.TIME_AND_ACTUATION:
      return nextDuePassed || actuationsSurpassed;
  }

  return false;
};
