import React, { FormEvent, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createRagBin } from "store/reducers";
import { CreateRagBinRequest, RagBinType, TLocation, TRagBin, UUID } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { RagBinTypeSelector } from "components/shared/features/ragBins/RagBinTypeSelector/RagBinTypeSelector";
import { VSpace } from "components/shared/layouts";

type CreateRagBinModalProps = {
  ragControllerId: UUID;
  onSuccess?: (caseScanner: TRagBin) => void;
};

export const CreateRagBinModal = ({ ragControllerId, onSuccess }: CreateRagBinModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateRagBinRequest>({
    ragControllerId,
    binType: "",
    name: "",
    description: "",
  });
  const valid = Boolean(values.name && values.binType);

  const handleChange = (name: keyof CreateRagBinRequest, value: string | number | undefined) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const caseScanner = await dispatch(createRagBin(values)).unwrap();
      closeModal();
      onSuccess && onSuccess(caseScanner);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleChangeLocation = (name: string, value?: TLocation) => {
    handleChange("locationId", value?.id);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader title="Create a rag bin" />
        <ModalContent>
          <VSpace>
            <FieldGroup label="Name">
              <TextInput
                input={{
                  name: "name",
                  placeholder: "Enter bin name",
                  autoFocus: true,
                }}
                value={values.name}
                onChange={handleChange}
              />
            </FieldGroup>
            <FieldGroup label="Bin type">
              <RagBinTypeSelector
                appearance="normal"
                name="binType"
                value={values.binType}
                onChange={(value?: RagBinType) => handleChange("binType", value)}
              />
            </FieldGroup>
            <FieldGroup label="Location">
              <LocationSelector
                appearance="normal"
                name="locationId"
                value={values.locationId}
                onChange={handleChangeLocation}
              />
            </FieldGroup>
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit" disabled={!valid}>
            Create bin
          </PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
