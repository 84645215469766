import moment from "moment";
import CalibrationTrackingMethods from "lib/constants/CalibrationTrackingMethods";
import isAssetCalibrationDue, {
  hasSurpassActuations,
  isPassedNextDue,
  isNearNextDue,
  isNearActuations,
} from "lib/utility/calibration/isAssetCalibrationDue";
import { TAsset } from "types";
import { SecondaryButton, ButtonGroup, DangerButton, PrimaryButton } from "components/Buttons";
import StatusPill from "components/StatusPill";
import { Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import style from "./modal.module.scss";

type CheckoutAssetSummaryProps = {
  asset: TAsset;
  onSubmit: any;
  onCancel: any;
};

const CheckoutAssetSummary = ({ asset, onSubmit, onCancel }: CheckoutAssetSummaryProps) => {
  const { storageConfig, calibrationConfig, catalogProduct } = asset;
  const isCalibrationDue = isAssetCalibrationDue(asset);

  if (!calibrationConfig || !catalogProduct) return null;

  return (
    <div>
      <div className={style.summary}>
        <div className={style.content}>
          <div>
            {catalogProduct.imageUrl && (
              <img src={catalogProduct.imageUrl} className={style.image} alt={catalogProduct.name} />
            )}
          </div>
          <div className={style.contentOverflow}>
            <FieldGroup label="Tool name" className={style.fieldGroup}>
              <Text>{catalogProduct.name}</Text>
            </FieldGroup>
            {asset.serialNumber && (
              <FieldGroup label="Serial number" className={style.fieldGroup}>
                <Text>{asset.serialNumber}</Text>
              </FieldGroup>
            )}
            <FieldGroup label="Product number" className={style.fieldGroup}>
              <Text>{catalogProduct.mpn}</Text>
            </FieldGroup>
            <FieldGroup label="Drawer number" className={style.fieldGroup}>
              <Text>{storageConfig ? storageConfig.drawerNumber : "N/A"}</Text>
            </FieldGroup>
          </div>
        </div>
        {calibrationConfig && (
          <div className={style.calibrationInfo}>
            {calibrationConfig.methodId !== CalibrationTrackingMethods.ACTUATION && (
              <Text className={style.calibrationField}>
                Calibration due: {moment(calibrationConfig.nextDue).format("Do MMMM YYYY")}
                {isPassedNextDue(calibrationConfig.nextDue) ? (
                  <StatusPill color="danger" text="Required" className={style.calibrationStatus} />
                ) : (
                  isNearNextDue(calibrationConfig.nextDue) && (
                    <StatusPill color="warning" text="Fix soon" className={style.calibrationStatus} />
                  )
                )}
              </Text>
            )}
            {calibrationConfig.methodId !== CalibrationTrackingMethods.TIME && (
              <Text className={style.calibrationField}>
                Calibration actuations: {calibrationConfig.actuationCount} / {calibrationConfig.actuationInterval}
                {hasSurpassActuations(calibrationConfig.actuationCount, calibrationConfig.actuationInterval) ? (
                  <StatusPill color="danger" text="Required" className={style.calibrationStatus} />
                ) : (
                  isNearActuations(calibrationConfig.actuationCount, calibrationConfig.actuationInterval) && (
                    <StatusPill color="warning" text="Fix soon" className={style.calibrationStatus} />
                  )
                )}
              </Text>
            )}
          </div>
        )}
      </div>

      <ButtonGroup className={style.checkoutButtons}>
        <SecondaryButton onClick={onCancel} className={style.cancelButton}>
          Cancel
        </SecondaryButton>
        {isCalibrationDue ? (
          <DangerButton className={style.button} onClick={onSubmit} icon="/images/icons/alert.svg">
            CALIBRATION REQUIRED - I have taken this tool
          </DangerButton>
        ) : (
          <PrimaryButton className={style.button} onClick={onSubmit} icon="/images/icons/tick_4.svg">
            I have taken this tool
          </PrimaryButton>
        )}
      </ButtonGroup>
    </div>
  );
};

export default CheckoutAssetSummary;
