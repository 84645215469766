//@ts-nocheck
import React from "react";
import { useNavigate } from "react-router-dom";
import { openModal } from "lib/actions/modal";
import Capabilities from "lib/constants/Capabilities";
import Modals from "lib/constants/Modals";
import store from "store";
import { SecondaryButton } from "components/Buttons";
import { Restrict } from "components/Restricted";
import StageHeader from "components/StageHeader";
import PageStage from "components/stages/PageStage";
import ReportsTableContainer from "./ReportsTableContainer";

const ListReports = () => {
  const history = useNavigate();
  const createReport = () => {
    store.dispatch(
      openModal(Modals.CREATE_REPORT, {
        onSuccess: (report) => {
          history(`/apps/reporting/reports/${report.id}/template`, {
            report,
          });
        },
      }),
    );
  };
  return (
    <PageStage>
      <StageHeader
        title="Reporting"
        action={
          <Restrict capability={Capabilities.REPORT_CREATE}>
            <SecondaryButton onClick={createReport} icon="/images/icons/plus_1.svg">
              Create Report
            </SecondaryButton>
          </Restrict>
        }
      />
      <ReportsTableContainer />
    </PageStage>
  );
};

export default ListReports;
