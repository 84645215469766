//@ts-nocheck
import Block from "lib/blocks/Block";

export default class TextBlockProperties extends Block {
  /**
   * @type {string}
   */
  text;

  constructor(text = "") {
    super();
    this.text = text;
  }
}
