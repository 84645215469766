import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "store";
import { fetchRagControllerStatsById } from "store/reducers";
import { GetRagControllerStatsResponse, UUID } from "types";
import StageHeader from "components/StageHeader";
import { Text } from "components/Typography";
import { PageLoading, ProgressCircle } from "components/shared";
import PageStage from "components/stages/PageStage";

const Charts = styled.div`
  display: flex;
  flex-direction: row;
`;

type RagControllerDashboardProps = {
  ragControllerId: UUID;
};

export const RagControllerDashboard = ({ ragControllerId }: RagControllerDashboardProps) => {
  const dispatch = useDispatch();
  const [stats, setStats] = useState<GetRagControllerStatsResponse>();

  useEffect(() => {
    fetchStats();
  }, [ragControllerId]);

  const fetchStats = async () => {
    const response = await dispatch(fetchRagControllerStatsById(ragControllerId)).unwrap();
    setStats(response);
  };

  if (!stats) {
    return <PageLoading />;
  }

  let ragsUsed = stats.totalRags - stats.cleanRags - stats.dirtyRags;
  if (ragsUsed < 0) ragsUsed = 0;

  return (
    <PageStage>
      <StageHeader
        title="Dashboard"
        subtitle="A breakdown of how the rags associated with the bins in this controller are being used."
      />
      <Charts>
        <ProgressCircle
          headline="Clean rags"
          count={stats.cleanRags >= 0 ? stats.cleanRags : 0}
          totalCount={stats.totalRags}
          popoverContent={() => (
            <div>
              <Text>
                {stats.cleanRags}/{stats.totalRags} rags are clean
              </Text>
            </div>
          )}
        />
        <ProgressCircle
          headline="Rags used"
          count={ragsUsed}
          totalCount={stats.totalRags}
          popoverContent={() => (
            <div>
              <Text>
                {stats.cleanRags}/{stats.totalRags} rags used
              </Text>
            </div>
          )}
        />
        <ProgressCircle
          headline="Dirty rags"
          count={stats.dirtyRags >= 0 ? stats.dirtyRags : 0}
          totalCount={stats.totalRags}
          popoverContent={() => (
            <div>
              <Text>
                {stats.dirtyRags}/{stats.totalRags} rags are dirty
              </Text>
            </div>
          )}
        />
      </Charts>
    </PageStage>
  );
};
