//@ts-nocheck
import React from "react";
import classNames from "classnames";
import { FixLater } from "types";
import { Submit } from "components/Buttons";
import style from "pages/authentication/components/AuthSubmitButton.module.scss";

const AuthSubmitButton = ({ children, isDisabled, className, onClick }: FixLater) => {
  return (
    <Submit disabled={isDisabled} className={classNames(style.submitButton, className)} onClick={onClick}>
      {children}
    </Submit>
  );
};

export default AuthSubmitButton;
