//@ts-nocheck
import { createSelector } from "reselect";

export const getStoragesMetrics = createSelector(
  (state) => state.statistics,
  (state = {}) => {
    return state.storagesMetrics.data || null;
  },
);

export const getAssetsMetrics = createSelector(
  (state) => state.statistics,
  (state = {}) => {
    return state.assetsMetrics.data || null;
  },
);
