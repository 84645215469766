import { ReactNode, forwardRef } from "react";
import classNames from "classnames";
import {
  TailwindBackgroundColor,
  TailwindBackgroundDisabledColor,
  TailwindBackgroundHoverColor,
  TailwindBorderColor,
  TailwindFlex,
  TailwindFontWeight,
  TailwindHeight,
  TailwindLayout,
  TailwindPadding,
  TailwindPaddingX,
  TailwindPaddingY,
  TailwindRingColor,
  TailwindRingFocusColor,
  TailwindRounding,
  TailwindShadow,
  TailwindTextColor,
  TailwindTextHoverColor,
  TailwindTextSize,
  TailwindTextTransform,
  TailwindWidth,
} from "types/tailwind";
import Loading from "components/Loading";

export type GenericButtonProps = {
  id?: string;
  type?: "button" | "submit";
  loading?: any;
  children?: ReactNode;
  disabled?: boolean;
  leadingIcon?: any;
  trailingIcon?: any;
  onClick?: (event: any) => void;
  rounding?: TailwindRounding;
  width?: TailwindWidth;
  height?: TailwindHeight;
  padding?: TailwindPadding;
  paddingY?: TailwindPaddingY;
  paddingX?: TailwindPaddingX;
  textColor?: TailwindTextColor;
  textHoverColor?: TailwindTextHoverColor;
  fontWeight?: TailwindFontWeight;
  textTransform?: TailwindTextTransform;
  borderColor?: TailwindBorderColor;
  textSize?: TailwindTextSize;
  backgroundColor?: TailwindBackgroundColor;
  backgroundHover?: TailwindBackgroundHoverColor;
  backgroundDisabled?: TailwindBackgroundDisabledColor;
  ringColor?: TailwindRingColor;
  ringFocusColor?: TailwindRingFocusColor;
  shadow?: TailwindShadow;
  layout?: TailwindLayout;
  flex?: TailwindFlex;
  className?: string;
};

export type ButtonProps = GenericButtonProps & {};

export const Button = forwardRef(
  (
    {
      width = "w-full",
      layout = "inline-flex",
      flex = "flex-1",
      rounding = "rounded-md",
      textColor = "text-white",
      textHoverColor = "",
      borderColor = "border-transparent",
      textSize = "text-base",
      fontWeight = "font-normal",
      height = "",
      type = "button",
      padding = "",
      paddingY = "py-2",
      paddingX = "px-4",
      backgroundColor = "bg-blue-400",
      backgroundHover = "hover:bg-blue-500",
      backgroundDisabled = "",
      ringColor = "ring-transparent",
      ringFocusColor = "focus:ring-blue-500",
      textTransform = "",
      shadow = "",
      children,
      id,
      disabled = false,
      loading,
      onClick,
      leadingIcon: LeadingIcon,
      trailingIcon: TrailingIcon,
      className,
      ...otherProps
    }: ButtonProps,
    ref: any,
  ) => {
    const classes = classNames(
      "items-center whitespace-nowrap justify-center border ring-2 focus:ring-2 focus:ring-offset-2",
      className,
      layout,
      flex,
      width,
      height,
      padding,
      rounding,
      textColor,
      textHoverColor,
      textSize,
      fontWeight,
      borderColor,
      backgroundColor,
      backgroundHover,
      backgroundDisabled,
      ringColor,
      ringFocusColor,
      padding,
      paddingX,
      paddingY,
      shadow,
      textTransform,
    );

    if (loading) {
      children = <Loading textColor={textColor} />;
      LeadingIcon = null;
      TrailingIcon = null;
      disabled = true;
    }

    return (
      <button {...otherProps} ref={ref} type={type} id={id} className={classes} onClick={onClick} disabled={disabled}>
        {LeadingIcon && <LeadingIcon className={classNames("h-5 w-5", !!children && "-ml-1 mr-2")} />}
        {children}
        {TrailingIcon && <TrailingIcon className={classNames("h-5 w-5", !!children && "ml-2 -mr-1")} />}
      </button>
    );
  },
);
