import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import {
  createRagController,
  fetchRagControllers,
  updateRagController,
  deleteRagController,
  fetchRagControllerById,
} from "store/reducers/ragControllers/ragControllers.thunks";
import { TRagController, UUID } from "types";

export type RagControllersReducerStateData = Record<UUID, TRagController>;

export interface RagControllersReducerState {
  loading: string;
  data: RagControllersReducerStateData;
}

const initialState: RagControllersReducerState = {
  loading: "",
  data: {},
};

export const ragControllersSlice = createSlice({
  name: "ragControllers",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRagControllers.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: RagControllersReducerStateData, value: TRagController) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchRagControllerById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createRagController.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateRagController.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteRagController.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addMatcher(isPending, (state, action) => {
        state.loading = action.type;
      })
      .addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
        state.loading = "";
      });
  },
});

export const ragControllersReducer = ragControllersSlice.reducer;
