import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { openModal } from "lib/actions/modal";
import { showPopupFlag } from "lib/actions/userInterface";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { deleteRagController, selectRagControllerByID, updateRagController } from "store/reducers";
import { FixLater, TLocation, TRagController, UUID } from "types";
import Avatar from "components/Avatar";
import { DangerButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import { TextAreaInput, TextInput } from "components/Input";
import StageHeader from "components/StageHeader";
import { LocationSelector } from "components/inputs";
import PageStage from "components/stages/PageStage";

type RagControllerDashboardProps = {
  ragControllerId: UUID;
};

export const RagControllerSettings = ({ ragControllerId }: RagControllerDashboardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const entity = useSelector(selectRagControllerByID(ragControllerId || ""));
  const [values, setValues] = useState<Partial<TRagController>>();

  useEffect(() => {
    if (!entity) return;
    setValues(entity);
  }, [entity]);

  const handleChange = (name: keyof TRagController, value: FixLater) => {
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const saveChanges = async (value: TRagController) => {
    try {
      await dispatch(updateRagController(value)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleBlur = (name: keyof TRagController) => {
    return (value: FixLater) => {
      if (!entity) return;
      saveChanges({
        ...entity,
        [name]: value,
      });
    };
  };

  const handleImageChange = async (imageUrl: string) => {
    if (!entity) return;
    await saveChanges({
      ...entity,
      imageUrl,
    });
  };

  const changeLocation = (name: string, value?: TLocation) => {
    if (!entity) return;
    saveChanges({
      ...entity,
      locationId: value?.id,
    });
  };

  const removeRagController = () => {
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description: "Are you sure you want to delete this rag controller? This action is permanent.",
        actionText: "Delete controller",
        onConfirm: async () => {
          try {
            await dispatch(deleteRagController(ragControllerId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Rag controller deleted",
              }),
            );
            navigate("/apps/storage/rag_controllers");
          } catch (err) {
            handlePopupFlagError(err);
          }
        },
      }),
    );
  };

  if (!entity || !values || !ragControllerId) {
    return null;
  }

  return (
    <PageStage>
      <StageHeader title="Settings" />
      <DataGrid>
        <DataItem label="Image" value={<Avatar canEdit image={entity.imageUrl} onChange={handleImageChange} />} />
        <DataItem
          label="Name"
          value={
            <TextInput
              value={values.name}
              input={{ name: "name", placeholder: "Enter name" }}
              onChange={handleChange}
              onBlur={handleBlur("name")}
            />
          }
        />
        <DataItem
          label="Description"
          value={
            <TextAreaInput
              value={values.description}
              input={{
                name: "description",
                placeholder: "Enter description",
              }}
              onChange={handleChange}
              onBlur={handleBlur("description")}
            />
          }
        />
        <DataItem
          label="Location"
          value={
            <LocationSelector
              appearance="normal"
              name="locationId"
              value={entity.locationId}
              onChange={changeLocation}
            />
          }
        />
        <DataItem
          label="Delete"
          value={
            <DangerButton icon="/images/icons/trash.svg" onClick={removeRagController}>
              Delete rag controller
            </DangerButton>
          }
        />
      </DataGrid>
    </PageStage>
  );
};
