//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ReactSVG from "react-svg";
import withReportBuilder from "hocs/withReportBuilder";
import { requestReportExports } from "lib/actions/reports";
import { showPopupFlag } from "lib/actions/userInterface";
import ReportAPI from "lib/api/reports";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getReport, getReportExportsPaging } from "lib/reducers/reports.selectors";
import { PrimaryButton } from "components/Buttons";
import Icon from "components/Icon";
import IconButton from "components/IconButton";
import style from "./reportController.module.scss";

const ReportTemplate = ({ children }) => <section className={style.container}>{children}</section>;

const Header = ({ title, subtitle }) => (
  <div className={style.header}>
    <h1>{title}</h1>
    <p>{subtitle}</p>
  </div>
);

const SelectTemplate = withReportBuilder(({ actions, stateStore }) => {
  const templates = stateStore.reportBuilder.templates.data;
  const template = stateStore.reportBuilder.template;
  const { generating, reportId } = stateStore.reportBuilder.generate;

  useEffect(() => actions.selectTemplate(templates[0]), []);

  const nextTemplate = () => {
    const temp = templates[template.id];
    actions.selectTemplate(temp);
  };
  const previousTemplate = () => {
    const temp = templates[template.id - 2];
    actions.selectTemplate(temp);
  };
  return template ? (
    <div className={style.templateSelector}>
      <div className={style.templateSelectorButton}>
        {!reportId ? (
          <IconButton
            icon="/images/icons/caret-left.svg"
            onClick={previousTemplate}
            disabled={template.id === 1 || generating}
          />
        ) : null}
      </div>
      <div className={style.templatePreviewContainer}>
        <ReactSVG src={template.preview} className={style.templatePreview} />
        {reportId ? (
          <Icon src="/images/icons/tick.svg" size="large" color="success" className={style.templateSuccessIcon} />
        ) : null}
      </div>
      <div className={style.templateSelectorButton}>
        {!reportId ? (
          <IconButton
            icon="/images/icons/caret-right.svg"
            onClick={nextTemplate}
            disabled={template.id === 2 || generating}
          />
        ) : null}
      </div>
    </div>
  ) : null;
});

const SelectTheme = withReportBuilder(({ actions, stateStore }) => {
  const themes = stateStore.reportBuilder.themes.data;
  const selectedTheme = stateStore.reportBuilder.theme;
  useEffect(() => {
    if (themes.length) {
      actions.selectTheme(themes[0]);
    }
  }, [themes.length]);
  return (
    <div className={style.themeSelector}>
      <p>Choose a theme</p>
      <div className={style.themes}>
        {themes.map((theme) => (
          <div
            key={theme.id}
            className={style.theme}
            style={{ backgroundColor: theme.backgroundColor }}
            onClick={() => actions.selectTheme(theme)}
          >
            {selectedTheme && selectedTheme.id === theme.id ? (
              <Icon src="/images/icons/tick_2.svg" size="small" className={style.themeIcon} />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
});

export const GenerateReportButton = () => {
  const dispatch = useDispatch();
  const report = useSelector(getReport);
  const paging = useSelector(getReportExportsPaging);
  const [creating, setCreating] = useState(false);

  const fetch = (pageStart, pageLimit) => dispatch(requestReportExports(report.id, { pageStart, pageLimit }));

  const onSubmit = async () => {
    try {
      setCreating(true);
      const response = await ReportAPI.createReportExport(report.id);
      fetch(0, paging.pageLimit);
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Export generated",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
    setCreating(() => false);
  };
  return (
    <PrimaryButton size="large" onClick={onSubmit} disabled={creating}>
      Generate report
    </PrimaryButton>
  );
};

const ReportController = ({ stateStore }) => {
  const history = useNavigate();
  const { reportId } = stateStore.reportBuilder.generate;
  const onViewReport = () => {
    history("/apps/reporting/reports", { replace: true });
  };
  return (
    <ReportTemplate>
      {reportId ? (
        <React.Fragment>
          <Header title="Report ready!" subtitle="Click below to see your report" />
          <SelectTemplate />
          <div className={style.successActionsContainer}>
            <Link target="_blank" to={`/apps/reporting/reports/${reportId}`} onClick={onViewReport}>
              <PrimaryButton size="large">Take me to my report</PrimaryButton>
            </Link>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Header title="Report style" subtitle="Choose a template" />
          <SelectTemplate />
          <SelectTheme />
        </React.Fragment>
      )}
    </ReportTemplate>
  );
};

export default withReportBuilder(ReportController);
