//@ts-nocheck
import Api from "./api";

class Blocks extends Api {
  /**
   * Get blocks
   * @async
   * @param {Object} [queryParams=null]
   * @returns {Promise.<Object.<{ data: Array.<Block>, metadata: Object }>>}.
   */
  static async getBlocks(queryParams = {}) {
    const response = await this.get(`/blocks`).query(queryParams);
    return response.body;
  }

  /**
   * Get block
   * @async
   * @param {string} blockID
   * @returns {Promise.<Block>}
   */
  static async getBlock(blockID) {
    const response = await this.get(`/blocks/${blockID}`);
    return response.body.data;
  }

  /**
   * Update block
   * @async
   * @param {string} blockID
   * @param {Object} block
   * @returns {Promise.<Block>}
   */
  static async updateBlock(blockID, block) {
    const response = await this.put(`/blocks/${blockID}`).send(block);
    return response.body.data;
  }

  /**
   * Create block
   * @async
   * @param {Block} block
   * @returns {Promise.<Block>}
   */
  static async createBlock(block) {
    const response = await this.post(`/blocks`).send(block);
    return await response.body.data;
  }

  /**
   * Delete block
   * @param {string} blockID
   * @returns {Promise.<void>}
   */
  static async deleteBlock(blockID) {
    return await this.delete(`/blocks/${blockID}`).send();
  }
}

export default Blocks;
