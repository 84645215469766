//@ts-nocheck
import Api from "./api";

class Storages extends Api {
  /**
   * Get storages
   * @async
   * @param {Object} [queryParams={}]
   * @param {Object} [options]
   * @returns {Promise.<Object>} Array of storages.
   */
  static async getStorages(queryParams = {}, options = {}) {
    const baseURL = options.baseURL || "";
    const response = await this.get(`${baseURL}/storages`).query(queryParams);
    return response.body;
  }

  /**
   * Get storage
   * @async
   * @param {string} storageId
   * @returns {Promise.<Object>} A single storage.
   */
  static async getStorage(storageId) {
    const response = await this.get(`/storages/${storageId}`);
    return response.body.data;
  }

  /**
   * Get authenticated storage recent access details
   * @returns {Promise<{data: Object|null}>}
   */
  static async getStorageAccess() {
    const response = await this.get(`/storage/access`);
    return response.body;
  }

  /**
   * Get storage heartbeats
   * @async
   * @param {string} storageId
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>} Array of assets.
   */
  static async getStorageHeartbeats(storageId, queryParams = {}) {
    const response = await this.get(`/storages/${storageId}`).query(queryParams);
    return response.body;
  }

  /**
   * Close the storage session
   * @returns {Promise}
   */
  static async closeStorageAccessImmediately() {
    return await this.post(`/storage/access/close`).send();
  }

  /**
   * Begin RFID scanning of the storage
   * @returns {Promise}
   */
  static async scanStorageUsingRFID() {
    const response = await this.post(`/storage/access/scan_rfid`).send();
    return response.body.data;
  }

  /**
   * Get authenticated storage statistics
   * @returns {Promise<Object>}
   */
  static async getStorageStatistics() {
    const response = await this.get(`/storage/statistics`);
    return response.body.data;
  }

  /**
   * Create storage
   * @async
   * @param {Object} storage
   * @returns {Promise} Response
   */
  static async createStorage(storage) {
    const response = await this.post(`/storages`).send(storage);
    return response.body.data;
  }

  /**
   * Send a remote control message to a storage
   * @async
   * @param {string} storageID
   * @param {Object} requestBody
   * @returns {Promise} Response
   */
  static async sendStorageRemoteCommand(storageID, requestBody) {
    return await this.post(`/storages/${storageID}/remote_control`).send(requestBody);
  }

  /**
   * Update storage
   * @param {string} storageId
   * @param {Object} storage
   * @param {Object} [options]
   * @returns {Promise} Response
   */
  static async updateStorage(storageId, storage, options = {}) {
    const baseURL = options.baseURL || "";
    return await this.put(`${baseURL}/storages/${storageId}`).send(storage);
  }

  /**
   * Delete a storage
   * @param {string} storageId
   * @returns {Promise.<void>}
   */
  static async deleteStorage(storageId) {
    return await this.delete(`/storages/${storageId}`).send();
  }

  /**
   * Unlink a linked storage
   * @async
   * @param {string} storageID
   * @returns {Promise} Response
   */
  static async unlinkStorage(storageID) {
    return await this.post(`/storages/${storageID}/unlink`).send();
  }

  /**
   * Add an asset to a storage
   * @async
   * @param {string} storageId
   * @param {number} assetId
   * @param {number} drawerNumber
   * @returns {Promise} Response
   */
  static async addStorageAsset(storageId, assetId, drawerNumber) {
    if (typeof assetId !== "number") {
      throw new Error("expected asset ID to be number");
    }
    return await this.post(`/storages/${storageId}/assets`).send({
      assetId,
      drawerNumber,
    });
  }

  /**
   * Update the asset storage drawer
   * @async
   * @param {Object} storageAsset
   * @returns {Promise} Response
   */
  static async updateStorageAsset(storageAsset) {
    const { storageId, assetId } = storageAsset;
    return await this.put(`/storages/${storageId}/assets/${assetId}`).send({
      ...storageAsset,
    });
  }

  /**
   * ReOrder storage assets
   * @async
   * @param {string} storageId
   * @param {Object[]} storageAssets
   * @returns {Promise} Response
   */
  static async reOrderStorageAssets(storageId, storageAssets) {
    return await this.put(`/storages/${storageId}/assets`).send(storageAssets);
  }

  /**
   * Remove an asset from a storage
   * @async
   * @param {string} storageId
   * @param {number} assetId
   * @returns {Promise} Response
   */
  static async removeStorageAsset(storageId, assetId) {
    if (typeof assetId !== "number") {
      throw new Error("expected asset ID to be number");
    }
    return await this.delete(`/storages/${storageId}/assets/${assetId}`).send();
  }

  /**
   * Get users which have been granted access to a storage
   * @async
   * @param {string} storageId
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>} Array of storages.
   */
  static async getStorageAccessGrants(storageId, queryParams = {}) {
    const response = await this.get(`/storages/${storageId}/grants`).query(queryParams);
    return response.body;
  }

  /**
   * Grant storage access to a user
   * @async
   * @param {string} storageId
   * @param {Object} grant
   * @returns {Promise} Response
   */
  static async createStorageAccessGrant(storageId, grant) {
    return await this.post(`/storages/${storageId}/grants`).send(grant);
  }

  /**
   * Remove storage access from a user
   * @async
   * @param {string} storageId
   * @param {number} userId
   * @returns {Promise} Response
   */
  static async removeStorageAccessGrant(storageId, userId) {
    return await this.delete(`/storages/${storageId}/grants/${userId}`).send();
  }

  /**
   * Get RFID antennas for a storage
   * @param {string} storageId
   * @returns {Promise<Object>}
   */
  static async getStorageAntennas(storageId) {
    const response = await this.get(`/storages/${storageId}/antennas`);
    return response.body;
  }

  /**
   * Add an RFID antenna to the storage
   * @param {string} storageId
   * @param {string} readerId
   * @param {number} antennaPort
   * @returns {Promise<void>}
   */
  static async addStorageAntenna(storageId, readerId, antennaPort) {
    return await this.post(`/storages/${storageId}/antennas`).send({
      readerId,
      antennaPort,
    });
  }

  /**
   * Remove an RFID antenna to the storage
   * @param {string} storageId
   * @param {string} readerId
   * @param {number} antennaPort
   * @returns {Promise<void>}
   */
  static async removeStorageAntenna(storageId, readerId, antennaPort) {
    return await this.delete(`/storages/${storageId}/antennas/${readerId}/${antennaPort}`).send();
  }

  /**
   * Get access policy attempts
   * @param {number} policyID
   * @param {Object} queryParams
   * @returns {Promise<Object>}
   */
  static async getAccessPolicyAttempts(policyID, queryParams = {}) {
    const response = await this.get(`/access_control/policies/${policyID}/attempts`).query(queryParams);
    return response.body;
  }

  /**
   * Sentry: Check if sentry is in onboarding mode
   * @returns {Promise<boolean>}
   */
  static async isSentryOnboarding() {
    try {
      const response = await this.get(`/onboarding`).send();
      return response.statusCode === 204;
    } catch (err) {
      if (err.status === 404) {
        return false;
      }
      throw err;
    }
  }

  /**
   * Sentry: OnboardingComplete the onboarding process
   * @param config
   * @returns {Promise<*>}
   */
  static async completeOnboarding(config) {
    return await this.post(`/onboarding`).send(config);
  }

  /**
   * Sentry: Get a list of wifi network SSIDs
   * @returns {Promise<>}
   */
  static async getWifiNetworks() {
    const response = await this.get(`/wifi/networks`).send();
    return response.body;
  }

  /**
   * Sentry: Configure wifi
   * @param {string} ssid
   * @param {string} password
   * @returns {Promise<*>}
   */
  static async configureWifi(ssid, password) {
    return await this.post(`/wifi/configure`).send({
      ssid,
      password,
    });
  }

  /**
   * Get an access policy by ID
   * @param {number} policyID
   * @returns {Promise<Object>}
   */
  static async getAccessPolicy(policyID) {
    const response = await this.get(`/access_control/policies/${policyID}`);
    return response.body;
  }

  /**
   * Sentry: Update sentry
   * @returns {Promise<*>}
   */
  static async updateSentry() {
    return await this.post(`/update`).send();
  }

  /**
   * Get storage viewers
   * @param {string} storageID
   * @param {Object} queryParams
   * @returns {Promise<Object>}
   */
  static async getStorageViewers(storageID, queryParams = {}) {
    const response = await this.get(`/storages/${storageID}/viewers`).query(queryParams);
    return response.body;
  }

  /**
   * Create storage viewer
   * @async
   * @param {string} storageID
   * @returns {Promise} Response
   */
  static async createStorageViewer(storageID) {
    return await this.post(`/storages/${storageID}/viewers`).send();
  }
}

export default Storages;
