import { useState } from "react";
import { useModal } from "effects/useModal";
import { useTrackMixpanel } from "effects/useTrackMixpanel";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { MixPanel } from "lib/mixpanel";
import { useDispatch, useSelector } from "store";
import { isRunningDeviceRFIDTagsScanning, runDeviceRFIDTagsScan } from "store/reducers";
import { TCaseScanner } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { DataList, DataListItem } from "components/lists/DataList";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { CaseScannerSelector } from "components/shared/features/caseScanners";
import { VSpace } from "components/shared/layouts";

type DetectAssetRFIDTagsModalProps = {
  onSuccess: (epcs: string[]) => void;
};

export const DetectAssetRFIDTagsModal = ({ onSuccess }: DetectAssetRFIDTagsModalProps) => {
  useTrackMixpanel("Open Modal: Scan RFID Tags");

  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const scanning = useSelector(isRunningDeviceRFIDTagsScanning);
  const [caseScanner, setCaseScanner] = useState<TCaseScanner>();
  const [epcs, setEpcs] = useState<string[]>();

  const handleCaseScannerChange = (name: string, value?: TCaseScanner) => {
    setCaseScanner(value);
  };

  const handleSubmit = async () => {
    try {
      if (!caseScanner) return;
      if (Array.isArray(epcs)) {
        MixPanel.track("Click Button: Rescan RFID Tags", {
          storageId: caseScanner.storageId,
        });
      } else {
        MixPanel.track("Click Button: Scan RFID Tags", {
          storageId: caseScanner.storageId,
        });
      }
      const data = await dispatch(
        runDeviceRFIDTagsScan({
          storageId: caseScanner.storageId,
        }),
      ).unwrap();
      setEpcs(data);
    } catch (err: any) {
      handlePopupFlagError(err);
    }
  };

  const handleConfirm = () => {
    closeModal();
    onSuccess && onSuccess(epcs || []);
    MixPanel.track("Click Button: Use Scanned RFID Tags", {
      epcs: epcs?.length || 0,
    });
  };

  return (
    <form>
      <Modal>
        <ModalHeader title="Detect RFID tags" />
        {epcs ? (
          <>
            <ModalContent>
              <VSpace>
                {epcs.length ? (
                  <FieldGroup label={`Detected ${epcs.length} tags`}>
                    <DataList>
                      {epcs.map((epc: string) => (
                        <DataListItem key={epc}>
                          <Text value={epc} />
                        </DataListItem>
                      ))}
                    </DataList>
                  </FieldGroup>
                ) : (
                  <Text>No tags detected, please try again</Text>
                )}
              </VSpace>
            </ModalContent>
            <ModalFooter>
              {epcs.length ? (
                <PrimaryButton type="button" onClick={handleConfirm}>
                  Use tags
                </PrimaryButton>
              ) : (
                <PrimaryButton submitting={scanning} disabled={!caseScanner} onClick={handleSubmit}>
                  Try again
                </PrimaryButton>
              )}
              <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
            </ModalFooter>
          </>
        ) : (
          <>
            <ModalContent>
              <VSpace>
                <FieldGroup label="Case scanner to use">
                  <CaseScannerSelector
                    onlineOnly
                    appearance="normal"
                    name="caseScannerId"
                    value={caseScanner?.id}
                    onChange={handleCaseScannerChange}
                  />
                </FieldGroup>
              </VSpace>
            </ModalContent>
            <ModalFooter>
              <PrimaryButton submitting={scanning} disabled={!caseScanner} onClick={handleSubmit}>
                Start scanning
              </PrimaryButton>
              <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
            </ModalFooter>
          </>
        )}
      </Modal>
    </form>
  );
};
