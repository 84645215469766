import { useModal } from "effects";
import styled from "styled-components";
import { Button, ButtonProps, SecondaryButton } from "components/Buttons";
import { Paragraph } from "components/Typography";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";

const StyledModal = styled(Modal)`
  max-width: 37.5rem;
`;

type ConfirmModalProps = {
  title?: string;
  description?: string;
  actionText?: string;
  actionColor?: ButtonProps["color"];
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const ConfirmModal = ({
  title = "Are you sure?",
  description = "Please confirm that you would like to do this action.",
  actionText = "Confirm",
  actionColor = "primary",
  onConfirm,
  onCancel,
}: ConfirmModalProps) => {
  const { closeModal } = useModal();

  const handleConfirm = () => {
    closeModal();
    if (onConfirm) {
      onConfirm();
    }
  };

  const handleCancel = () => {
    closeModal();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <StyledModal>
      <ModalHeader title={title} />
      {description && (
        <ModalContent>
          <Paragraph>{description}</Paragraph>
        </ModalContent>
      )}
      <ModalFooter alignRight>
        <Button color={actionColor} type="submit" onClick={handleConfirm}>
          {actionText}
        </Button>
        <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
      </ModalFooter>
    </StyledModal>
  );
};
