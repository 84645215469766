//@ts-nocheck
import React, { useState } from "react";
import dayjs from "dayjs";
import get from "lodash/get";
import Logger from "lib/Logger";
import { receiveAccessPolicy } from "lib/actions/storages";
import { showPopupFlag } from "lib/actions/userInterface";
import AccessControlAPI from "lib/api/accessControl";
import AccessScopes from "lib/constants/AccessScopes";
import store from "store";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import { TextInput } from "components/Input";
import { DateTimeInput } from "components/Input";
import RadioInput from "components/Input/RadioInput";
import { Paragraph } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import ProjectsInput from "components/inputs/ProjectsInput";
import SelectAccessScope from "components/inputs/SelectAccessScope";
import SelectKeycard from "components/inputs/SelectKeycard";
import SelectRoleInput from "components/inputs/SelectRoleInput";
import UsersInput from "components/inputs/UsersInput";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import parentStyle from "../RootModal/modal.module.scss";
import style from "./modal.module.scss";

const CreateAccessRuleModal = ({ modal, onClose }) => {
  const [submitting, isSubmitting] = useState(false);
  const [values, setValues] = useState({
    enabled: true,
    scope: AccessScopes.resolveItem(AccessScopes.ROLE),
    valueString: "",
    valueInt: "",
    allowKeycard: true,
    allowMobileApp: false,
    activeDate: null,
    expireDate: null,
  });

  const { session, policy } = modal.props;

  const handleSubmit = async (e) => {
    isSubmitting(true);
    try {
      e.preventDefault();
      const newPolicy = {
        ...policy,
      };
      const newGrant = {
        enabled: values.enabled,
        rule: {
          scope: values.scope.value,
          valueString: get(values, "valueString.id", values.valueString),
          valueInt: get(values, "valueInt.id", parseInt(values.valueInt)),
        },
        allowKeycard: values.allowKeycard,
        allowMobileApp: values.allowMobileApp,
        activeDate: values.activeDate ? dayjs(values.activeDate).toISOString() : null,
        expireDate: values.expireDate ? dayjs(values.expireDate).toISOString() : null,
      };
      newPolicy.grants = [...newPolicy.grants, newGrant];
      const data = await AccessControlAPI.updatePolicy(newPolicy.id, newPolicy);
      store.dispatch(receiveAccessPolicy(data));
      store.dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Created Access Rule",
        }),
      );
      onClose();
    } catch (err) {
      Logger.error(err);
      store.dispatch(
        showPopupFlag({
          appearance: "error",
          title: "Failed to Create Access Rule",
        }),
      );
    }
    isSubmitting(false);
  };

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const renderScope = () => {
    const scope = values.scope;
    if (!scope) {
      return null;
    }
    switch (scope.value) {
      case AccessScopes.GROUP:
        return (
          <TextInput
            input={{
              name: "valueString",
              placeholder: "Enter group name",
            }}
            value={values.valueString}
            onChange={handleChange}
          />
        );
      case AccessScopes.ROLE:
        return (
          <SelectRoleInput
            name="valueInt"
            value={values.valueInt}
            onChange={(_, value) => handleChange("valueInt", value.id)}
          />
        );
      case AccessScopes.PROJECT:
        return (
          <ProjectsInput
            name="valueInt"
            value={values.valueInt}
            onChange={(_, value) => handleChange("valueInt", value.id)}
          />
        );
      case AccessScopes.USER:
        return (
          <UsersInput
            name="valueInt"
            value={values.valueInt}
            onChange={(_, value) => handleChange("valueInt", value.id)}
          />
        );
      case AccessScopes.KEYCARD:
        return (
          <SelectKeycard
            name="valueString"
            value={values.valueString}
            onChange={(_, value) => handleChange("valueString", value)}
          />
        );
      default:
        return null;
    }
  };

  const valid =
    values.scope && (values.valueString || values.valueInt) && (values.allowKeycard || values.allowMobileApp);

  return (
    <Modal className={parentStyle.wideModal}>
      <ModalHeader title="Grant access" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Access Scope">
            <SelectAccessScope name="scope" value={values.scope} onChange={handleChange} />
            <br />
            {renderScope()}
          </FieldGroup>
          {values.scope.value !== AccessScopes.KEYCARD && (
            <FieldGroup label="Methods allowed">
              <Paragraph className="text-muted">Select which access methods will be active for the rule</Paragraph>
              <div className={style.methods}>
                <RadioInput
                  options={[{ value: true, name: "Keycard" }]}
                  value={values.allowKeycard}
                  onChange={(value) => handleChange("allowKeycard", value ? value.value : null)}
                />
                <RadioInput
                  options={[{ value: true, name: "Mobile app" }]}
                  value={values.allowMobileApp}
                  onChange={(value) => handleChange("allowMobileApp", value ? value.value : null)}
                />
              </div>
            </FieldGroup>
          )}
          <FieldGroup label="Access period">
            <DataGrid>
              <DataItem label="Active from">
                <DateTimeInput value={values.activeDate} onChange={(value) => handleChange("activeDate", value)} />
              </DataItem>
              <DataItem label="Expire on">
                <DateTimeInput value={values.expireDate} onChange={(value) => handleChange("expireDate", value)} />
              </DataItem>
            </DataGrid>
          </FieldGroup>
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton disabled={!valid || submitting} onClick={handleSubmit}>
          Grant access
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

export default CreateAccessRuleModal;
