//@ts-nocheck
import React, { useState } from "react";
import ReactPopover from "react-popover";
import classNames from "classnames";
import { FixLater } from "types";
import style from "./popover.module.scss";

const Popover = ({ content, options, preferPlace = "below", children, className }: FixLater) => {
  const [hovering, setHovering] = useState(false);
  const popoverProps = {
    isOpen: hovering,
    preferPlace: preferPlace,
    enterExitTransitionDurationMs: 0,
    body: <div className={style.popOverContent}>{content}</div>,
    ...options,
  };
  return (
    <ReactPopover {...popoverProps}>
      <div
        className={classNames(style.Popover, className)}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        {children}
      </div>
    </ReactPopover>
  );
};

export default Popover;
