//@ts-nocheck
import { createSelector } from "reselect";

export const selectProductsStore = (state) => state.products;

export const selectProducts = createSelector(selectProductsStore, (state) => state.data);

export const selectProductsList = createSelector(selectProducts, (products) => Object.values(products));

export const selectProductsPaging = createSelector(selectProductsStore, (state) => state.paging);

export const selectProductsError = createSelector(selectProductsStore, (state) => state.error);

export const selectProductsByID = (id) => createSelector(selectProducts, (data) => data[id]);

export const selectProductsByManufacturerID = (id) =>
  createSelector(selectProductsList, (data) => data.filter((value) => value.manufacturerId === id));
