//@ts-nocheck
import { createSelector } from "reselect";
import BlockTypes from "lib/constants/BlockTypes";

export const getProjects = createSelector(
  (state) => state.projects,
  (state) => state.data,
);

export const getProjectsList = createSelector(getProjects, (data) => Object.values(data));

export const getProjectsPaging = createSelector(
  (state) => state.projects,
  (state) => state.paging,
);

export const getProjectByID = (id: number) => createSelector(getProjects, (data) => data[id] || null);

export const getProjectBlocks = createSelector(
  (state) => state.projects,
  (state = {}) => {
    return state.projectBlocks.data;
  },
);

export const getProjectFetching = createSelector(
  (state) => state.projects,
  (state = {}) => {
    return state.projectBlocks.fetching;
  },
);

export const getProjectBlocksPaging = createSelector(
  (state) => state.projects,
  (state = {}) => {
    return state.projectBlocks.paging;
  },
);

export const getProjectTaskListIDsByProjectID = (id) =>
  createSelector(getProjects, (projects) => {
    const project = projects[id];
    if (!project || !Array.isArray(project.projectBlocks)) {
      return [];
    }
    return project.projectBlocks
      .filter(({ block }) => block?.type === BlockTypes.TASK_LIST)
      .map(({ block }) => block?.properties?.taskListId)
      .filter(Boolean);
  });
