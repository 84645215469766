//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import Logger from "../Logger";
import * as types from "../actions/actionTypes";
import * as actions from "../actions/readers";
import Api from "../api/readers";

function* fetchReader(action) {
  try {
    const reader = yield Api.getReader(action.payload);
    yield put(actions.receiveReader(reader));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestReaderFailure(err));
  }
}

function* fetchReaders(action) {
  try {
    const { data, metadata } = yield Api.getReaders(action.payload);
    yield put(actions.receiveReaders(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestReadersFailure(err));
  }
}

function* readersSaga() {
  yield takeLatest(types.REQUEST_READER, fetchReader);
  yield takeLatest(types.REQUEST_READERS, fetchReaders);
}

export default readersSaga;
