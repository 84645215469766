import {
  HiOutlineCash,
  HiOutlineClipboardCheck,
  HiOutlineQrcode,
  HiOutlineDatabase,
  HiOutlineCog,
} from "react-icons/hi";
import { useParams } from "react-router-dom";
import useSetSidebar from "effects/useSidebar";
import PropTypes from "prop-types";
import Capabilities from "lib/constants/Capabilities";
import { useSelector } from "store";
import { selectAssetByID } from "store/reducers";
import { Restrict } from "components/Restricted";
import { Header } from "components/Typography";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import style from "components/sidebars/sidebar.module.scss";
import Sidebar, { MenuSeparator } from "./Sidebar";

const AssetSidebar = () => {
  useSetSidebar(true);
  const params = useParams();
  const assetID = params.assetID;
  const asset = useSelector(selectAssetByID(parseInt(assetID || "")));

  return (
    <Sidebar>
      {asset?.catalogProduct && (
        <div className={style.sidebarImageHeader}>
          <img src={asset.catalogProduct.imageUrl} className={style.assetImage} alt="" />
          <Header>{asset.catalogProduct.name}</Header>
        </div>
      )}
      <MenuSeparator />
      <MenuItem to={`/apps/tracking/assets/${assetID}/summary`} icon={<HiOutlineDatabase />}>
        Information
      </MenuItem>
      <Restrict capability={Capabilities.ASSET_UPDATE}>
        <MenuItem to={`/apps/tracking/assets/${assetID}/tracking`} icon={<HiOutlineQrcode />}>
          Tracking
        </MenuItem>
      </Restrict>
      <MenuItem to={`/apps/tracking/assets/${assetID}/calibration`} icon={<HiOutlineClipboardCheck />}>
        Calibration
      </MenuItem>
      <MenuItem to={`/apps/tracking/assets/${assetID}/history`} icon="/images/icons/update_2.svg">
        Activity history
      </MenuItem>
      <MenuItem to={`/apps/tracking/assets/${assetID}/financials`} icon={<HiOutlineCash />}>
        Financials
      </MenuItem>
      <Restrict capability={Capabilities.ASSET_UPDATE}>
        <MenuSeparator />
        <MenuItem to={`/apps/tracking/assets/${assetID}/settings`} icon={<HiOutlineCog />}>
          Settings
        </MenuItem>
      </Restrict>
    </Sidebar>
  );
};

AssetSidebar.defaultProps = {};

AssetSidebar.propTypes = {
  className: PropTypes.string,
};

export default AssetSidebar;
