//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import DropSelect from "components/dropdowns/DropSelect";

const CalibrationFilter = ({ value, onChange }) => {
  return (
    <DropSelect
      value={value}
      text="Calibration"
      icon="/images/icons/caret_down_1.svg"
      onChange={onChange}
      items={[
        {
          label: "Standard",
          description: "Only show tools that dont have calibration configured",
          value: false,
        },
        {
          label: "Calibrated",
          description: "Only show tools that have calibrated configured",
          value: true,
        },
      ]}
    />
  );
};

CalibrationFilter.defaultProps = {};

CalibrationFilter.propTypes = {
  className: PropTypes.string,
};

export default CalibrationFilter;
