//@ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useDebounce from "effects/useDebounce";
import useDidMount from "effects/useDidMount";
import PropTypes from "prop-types";
import { openModal } from "lib/actions/modal";
import { requestProjectSuccess } from "lib/actions/projects";
import { showPopupFlag } from "lib/actions/userInterface";
import ReportAPI from "lib/api/reports";
import Capabilities from "lib/constants/Capabilities";
import Modals from "lib/constants/Modals";
import ReportVisibility from "lib/constants/ReportVisibility";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { DangerButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import { TextInput } from "components/Input";
import { Restrict, useRoleHasCapability } from "components/Restricted";
import StageHeader from "components/StageHeader";
import SelectReportVisibility from "components/inputs/SelectReportVisibility";
import { VSpace } from "components/shared";

const ReportSettingsPage = ({ report }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [values, setValues] = useState(report);
  const debounceValues = useDebounce(values, 500);
  const requiresPassword = ReportVisibility.resolveItemValue(values.visibility) === ReportVisibility.PASSWORD_PROTECTED;

  useDidMount(() => saveChanges(), [debounceValues]);

  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const saveChanges = async () => {
    try {
      if (requiresPassword && !values.password) {
        return;
      }
      const requestBody = {
        ...report,
        ...values,
        visibility: ReportVisibility.resolveItemValue(values.visibility),
      };
      await ReportAPI.updateReport(report.id, requestBody);
      dispatch(requestProjectSuccess(requestBody));
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Changes saved",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const removeReport = () => {
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description: "Are you sure you want to remove this report and all previous exports? Doing this is permanent.",
        actionText: "Remove report",
        onConfirm: async () => {
          try {
            await ReportAPI.deleteReport(report.id);
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Report removed",
              }),
            );
            history("/apps/reporting");
          } catch (err) {
            handlePopupFlagError(err);
          }
        },
      }),
    );
  };

  const disabled = !useRoleHasCapability(Capabilities.REPORT_UPDATE);
  return (
    values && (
      <>
        <StageHeader title="Settings" />
        <VSpace>
          <DataGrid>
            <DataItem
              label="Name"
              value={
                <TextInput
                  input={{
                    name: "name",
                    placeholder: "Enter name",
                    disabled,
                  }}
                  value={values.name}
                  onChange={handleChange}
                />
              }
            />
            <DataItem
              label="Export visibility"
              value={
                <SelectReportVisibility
                  disabled={disabled}
                  name="visibility"
                  value={values.visibility}
                  onChange={handleChange}
                />
              }
            />
            {requiresPassword && (
              <DataItem
                label="Password"
                value={
                  <TextInput
                    input={{
                      name: "password",
                      placeholder: "Enter report password",
                      disabled,
                    }}
                    value={values.password}
                    onChange={handleChange}
                  />
                }
              />
            )}
          </DataGrid>
          <Restrict capability={Capabilities.REPORT_REMOVE}>
            <DataGrid>
              <DataItem
                label="Remove report"
                value={<DangerButton onClick={removeReport}>Remove report</DangerButton>}
              />
            </DataGrid>
          </Restrict>
        </VSpace>
      </>
    )
  );
};

ReportSettingsPage.defaultProps = {};

ReportSettingsPage.propTypes = {
  className: PropTypes.string,
};

export default ReportSettingsPage;
