import { createSlice } from "@reduxjs/toolkit";
import { createAsset, fetchAssets, updateAsset } from "store/reducers";
import { streamUpdateAsset } from "store/reducers/assets/assets.actions";
import { deleteAsset, fetchAssetById } from "store/reducers/assets/assets.thunks";
import { updateStorageAsset } from "store/reducers/assets/storageAssets.thunks";
import { TAsset } from "types";

export type AssetsReducerStateData = Record<number, TAsset>;

export interface AssetsReducerState {
  data: AssetsReducerStateData;
}

const initialState: AssetsReducerState = {
  data: {},
};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: AssetsReducerStateData, value: TAsset) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchAssetById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createAsset.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateAsset.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(streamUpdateAsset, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addCase(updateStorageAsset.fulfilled, (state, action) => {
        const entity = state.data[action.payload.assetId];
        if (!entity) return;
        entity.storageConfig = action.payload;
      });
  },
});

export const assetsReducer = assetsSlice.reducer;
