//@ts-nocheck
import * as type from "./actionTypes";
import { receivePagedResult, requestFailure } from "./common";

export function selectTemplate(template) {
  return { type: type.REPORT_BUILDER_SELECT_TEMPLATE, payload: template };
}

export function selectTheme(theme) {
  return { type: type.REPORT_BUILDER_SELECT_THEME, payload: theme };
}

export function setElements(elements) {
  return { type: type.REPORT_BUILDER_SET_ELEMENTS, payload: elements };
}

export function setElementContent(elementId, content) {
  return {
    type: type.REPORT_BUILDER_SET_ELEMENT_CONTENT,
    payload: { elementId, content },
  };
}

export function resetReportBuilder() {
  return { type: type.REPORT_BUILDER_RESET };
}

export function createReport(template, elements) {
  return {
    type: type.REPORT_BUILDER_GENERATE_REPORT_REQUEST,
    payload: {
      template,
      elements,
    },
  };
}

export function createReportSuccess(reportId) {
  return {
    type: type.REPORT_BUILDER_GENERATE_REPORT_SUCCESS,
    payload: reportId,
  };
}

export function createReportFailure(error) {
  return requestFailure(type.REPORT_BUILDER_GENERATE_REPORT_FAILURE, error);
}

export function requestReportComponents(options = {}) {
  return {
    type: type.REPORT_BUILDER_GET_REPORT_COMPONENTS_REQUEST,
    payload: options,
  };
}

export function requestReportComponentsSuccess(components, paging) {
  return receivePagedResult(type.REPORT_BUILDER_GET_REPORT_COMPONENTS_SUCCESS, components, paging);
}

export function requestReportComponentsFailure(error) {
  return requestFailure(type.REPORT_BUILDER_GET_REPORT_COMPONENTS_FAILURE, error);
}
