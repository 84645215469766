import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TAssetIssueProblem, UUID } from "types";

const selectState = (state: RootStore) => state.assetIssueProblems;

export const selectAssetIssueProblems = createSelector(selectState, (state) => state.data);

export const selectAssetIssueProblemsList = createSelector(selectAssetIssueProblems, (data) => Object.values(data));

export const selectAssetIssueProblemsPage = createSelector(selectState, ({ page, data }) => {
  return page.map((id) => data[id]).filter(Boolean);
});

export const selectAssetIssueProblemByID = (id: UUID) =>
  createSelector(selectAssetIssueProblems, (data): TAssetIssueProblem | undefined => data[id]);
