import { createContext, ReactNode, useContext } from "react";

type ToolStorageProviderProps = {
  storageId: string;
  children: ReactNode;
};

type ToolStorageProviderContextProperties = {
  storageId?: string;
};

export const ToolStorageProviderContext = createContext<ToolStorageProviderContextProperties>({
  storageId: undefined,
});

export const useToolStorage = (): ToolStorageProviderContextProperties => {
  const context = useContext(ToolStorageProviderContext);
  if (context === undefined) {
    throw new Error("useToolStorage must be used within a ToolStorageProviderContext");
  }
  return context;
};

export const ToolStorageProvider = ({ storageId, children }: ToolStorageProviderProps) => {
  return (
    <ToolStorageProviderContext.Provider
      value={{
        storageId,
      }}
    >
      {children}
    </ToolStorageProviderContext.Provider>
  );
};
