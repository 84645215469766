import Lookup from "lib/constants/Lookup";
import { AlertEventKey } from "types";

export type TAlertRule = Lookup<AlertEventKey>;

class AlertRules extends Lookup<AlertEventKey> {
  STORAGE_ACCESS_GRANTED: AlertEventKey = "storage_access_granted";
  STORAGE_ACCESS_DENIED: AlertEventKey = "storage_access_denied";
  STORAGE_ONLINE: AlertEventKey = "storage_online";
  STORAGE_OFFLINE: AlertEventKey = "storage_offline";
  DOORWAY_UNASSIGNED_ASSET_DETECTED: AlertEventKey = "doorway_unassigned_asset_detected";
  DOORWAY_ASSIGNED_ASSET_DETECTED: AlertEventKey = "doorway_assigned_asset_detected";
  CHECK_IN_WEIGHT_MISMATCH: AlertEventKey = "check_in_weight_mismatch";
  ASSET_ISSUE_CREATED: AlertEventKey = "asset_issue_created";
  CALIBRATION_VIOLATION: AlertEventKey = "calibration_violation";

  constructor() {
    super();
    this.items = [
      {
        value: this.STORAGE_ACCESS_GRANTED,
        label: "Device access granted",
        description: "Triggered when access to the device is granted",
      },
      {
        value: this.STORAGE_ACCESS_DENIED,
        label: "Device access denied",
        description: "Triggered when access to the device is denied",
      },
      {
        value: this.STORAGE_ONLINE,
        label: "Device online",
        description: "Triggered when device comes online",
      },
      {
        value: this.STORAGE_OFFLINE,
        label: "Device offline",
        description: "Triggered when device goes offline",
      },
      {
        value: this.DOORWAY_UNASSIGNED_ASSET_DETECTED,
        label: "Unauthorised tool detected",
        description: "Triggered when a tool is detected without being assigned to someone",
      },
      {
        value: this.DOORWAY_ASSIGNED_ASSET_DETECTED,
        label: "Authorised tool detected",
        description: "Triggered when a tool is detected while assigned to someone",
      },
      {
        value: this.CHECK_IN_WEIGHT_MISMATCH,
        label: "Weight mismatch",
        description: "Triggered when a tool is checked in with a different weight then at check out",
      },
      {
        value: this.ASSET_ISSUE_CREATED,
        label: "Tool defect reported",
        description: "Triggered when an engineer reports a defect with a tool",
      },
      {
        value: this.CALIBRATION_VIOLATION,
        label: "Calibration violation",
        description: "Triggered when a tool is checked out while out of calibration",
      },
    ];
  }
}

export default new AlertRules();
