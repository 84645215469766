//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FixLater } from "types";
import classes from "./table.module.scss";

const TableHeader = ({ className, children, ...otherProps }: FixLater) => (
  <thead {...otherProps} className={classNames(classes.tableHeader, className)}>
    {children}
  </thead>
);

TableHeader.defaultProps = {
  className: "",
};

TableHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default TableHeader;
