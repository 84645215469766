import { UUID } from "types";
import { AlertRecipient } from "components/shared/features/alerts/AlertRecipient/AlertRecipient";

type AlertTelephonesProps = {
  alertId: UUID;
};

export const AlertTelephones = ({ alertId }: AlertTelephonesProps) => {
  return <AlertRecipient name="telephone" placeholder="Enter alert SMS to a telephone number" alertId={alertId} />;
};
