//@ts-nocheck
import Api from "./api";

class Statistics extends Api {
  /**
   * Get statistics
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>} Statistics object.
   */
  static async getStatistics(queryParams = {}) {
    const response = await this.get("/statistics").query(queryParams);
    return response.body;
  }

  /**
   * Get metrics
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>} Statistics object.
   */
  static async getMetrics(queryParams = {}) {
    const response = await this.get("/metrics").query(queryParams);
    return response.body;
  }

  /**
   * Get storages metrics
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>}
   */
  static async getStoragesMetrics(queryParams = {}) {
    const response = await this.get("/metrics/storages").query(queryParams);
    return response.body.data;
  }
  /**
   * Get assets metrics
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>}
   */
  static async getAssetsMetrics(queryParams = {}) {
    const response = await this.get("/metrics/assets").query(queryParams);
    return response.body.data;
  }
}

export default Statistics;
