//@ts-nocheck
import * as type from "./actionTypes";
import { receivePagedResult, requestFailure } from "./common";

export function requestAssets(options = {}) {
  return {
    type: type.REQUEST_ASSETS,
    payload: options,
  };
}

export function receiveAssets(assets, paging) {
  return receivePagedResult(type.RECEIVE_ASSETS, assets, paging);
}

export function requestAssetsFailure(error) {
  return requestFailure(type.REQUEST_ASSETS_FAILURE, error);
}

export function requestAsset(id) {
  return {
    type: type.REQUEST_ASSET,
    payload: id,
  };
}

export function receiveAsset(asset) {
  return {
    type: type.RECEIVE_ASSET,
    payload: asset,
  };
}

export function requestAssetFailure(error) {
  return requestFailure(type.REQUEST_ASSET_FAILURE, error);
}

export function requestAssignedAssets(options = {}) {
  return {
    type: type.REQUEST_ASSIGNED_ASSETS,
    payload: options,
  };
}

export function receiveAssignedAssets(assets, paging) {
  return receivePagedResult(type.RECEIVE_ASSIGNED_ASSETS, assets, paging);
}

export function requestAssignedAssetsFailure(error) {
  return requestFailure(type.FAILURE_ASSIGNED_ASSETS, error);
}

export function requestAssetHistory(assetId, options = {}) {
  return {
    type: type.GET_ASSET_HISTORY_REQUEST,
    payload: assetId,
    options,
  };
}

export function receiveAssetHistory(events, paging) {
  return receivePagedResult(type.GET_ASSET_HISTORY_RECEIVE, events, paging);
}

export function requestAssetHistoryFailure(error) {
  return requestFailure(type.GET_ASSET_HISTORY_FAILURE, error);
}

export function requestCalibrationEvents(assetId, options = {}) {
  return {
    type: type.GET_CALIBRATION_EVENTS_REQUEST,
    payload: assetId,
    options,
  };
}

export function receiveCalibrationEvents(events, paging) {
  return receivePagedResult(type.GET_CALIBRATION_EVENTS_RECEIVE, events, paging);
}

export function requestCalibrationEventsFailure(error) {
  return requestFailure(type.GET_CALIBRATION_EVENTS_FAILURE, error);
}
