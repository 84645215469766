import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import qs from "query-string";
import store from "store";
import { logout } from "store/reducers";

axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params);
};

axios.interceptors.response.use(undefined, (error: AxiosError) => {
  if (
    error.response?.status === 401 ||
    (error.response?.status === 403 && error.response.data?.message === "Your account has been disabled")
  ) {
    store.dispatch(logout());
  }
  return Promise.reject(error);
});

export class API {
  static hostname = process.env.REACT_APP_API_URL;

  static async GET<K, T = any>(url: string, queryParams?: T, config?: AxiosRequestConfig): Promise<K> {
    const response = await axios.get<K>(this.formatURL(url), {
      ...this.makeConfig(config),
      params: queryParams,
    });
    return response.data;
  }

  static async POST<T, K>(url: string, request?: T, config?: AxiosRequestConfig): Promise<K> {
    const response = await axios.post<K>(this.formatURL(url), request, this.makeConfig(config));
    return response.data;
  }

  static async PUT<T, K>(url: string, request: T, config?: AxiosRequestConfig): Promise<K> {
    const response = await axios.put<K>(this.formatURL(url), request, this.makeConfig(config));
    return response.data;
  }

  static async DELETE<K>(url: string, config?: AxiosRequestConfig): Promise<K> {
    const response = await axios.delete<K>(this.formatURL(url), this.makeConfig(config));
    return response.data;
  }

  private static makeConfig(config?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      withCredentials: true,
      headers: { ...this.makeHeaders(), ...config?.headers },
      ...config,
    };
  }

  private static makeHeaders(): AxiosRequestHeaders {
    return {
      Authorization: this.getAuthTokenFromReduxState(),
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }

  private static formatURL(value: string): string {
    if (value.startsWith("http")) {
      return value;
    }
    if (value.length && value[0] !== "/") {
      value = "/" + value;
    }
    return this.hostname + value;
  }

  private static getAuthTokenFromReduxState(): string {
    const session = store.getState().accounts.session;
    return session ? session.authToken : "";
  }
}
