//@ts-nocheck
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Logger from "lib/Logger";
import { openModal } from "lib/actions/modal";
import Api from "lib/api/assets";
import Apps from "lib/constants/Apps";
import CheckoutTypes from "lib/constants/CheckoutTypes";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import store from "store";
import { FixLater, TAssetAssignment } from "types";
import { WarningButton, PrimaryButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import { InputGroups, InputGroup } from "components/Input";
import Loading from "components/Loading";
import Popover from "components/Popover";
import { Restrict } from "components/Restricted";
import { Text, Anchor } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import UsersInput from "components/inputs/UsersInput";
import { VehicleName } from "components/shared";
import styles from "./AssetAssignee.module.scss";

class AssetAssignee extends React.Component {
  static propTypes = {
    assetId: PropTypes.number,
  };

  state: FixLater & {
    assignment: TAssetAssignment;
  } = {
    fetched: false,
    isUpdating: false,
    assignment: null,
    user: null,
  };

  onChange = (name, value) => this.setState({ [name]: value, error: null });

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    try {
      const { assetId } = this.props;
      const assignment = await Api.getAssetAssignment(assetId);
      this.setState({ fetched: true, assignment });
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  onUnassign = () => {
    store.dispatch(
      openModal(Modals.CONFIRMATION, {
        description: "Are you sure you want to remove this tool assignment?",
        actionText: "Remove assignment",
        actionColor: "warning",
        onConfirm: async () => {
          try {
            this.setState({ isUpdating: true });
            const { assetId } = this.props;
            await Api.removeAssetAssignment(assetId);
            this.setState({ user: null });
            this.fetch();
          } catch (err) {
            handlePopupFlagError(err);
          }
          this.setState({ isUpdating: false });
        },
      }),
    );
  };

  onAssign = async () => {
    try {
      const { assetId } = this.props;
      const { user } = this.state;
      this.setState({ isUpdating: true });
      await Api.setAssetAssignment(assetId, user.id);
      this.fetch();
    } catch (err) {
      Logger.error(err);
    }
    this.setState({ isUpdating: false });
  };

  render() {
    const { fetched, isUpdating, assignment, user } = this.state;
    if (!fetched) {
      return null;
    }
    return assignment ? (
      <DataGrid>
        <DataItem
          label="Team member"
          value={
            assignment.userId ? (
              <Anchor to={`/configuration/users/${assignment.userId}`}>
                {`${assignment.user.forename} ${assignment.user.surname}`}
              </Anchor>
            ) : (
              <div className={styles.flex}>
                <Popover content={<Text>Automify could not determine the user</Text>}>
                  <Text className="text-muted">Not assigned</Text>
                </Popover>
              </div>
            )
          }
        />
        <DataItem label="Checkout time" value={<Text>{moment(assignment.dateAssigned).format("lll")}</Text>} />
        <DataItem
          label="Checkout method"
          value={
            <>
              <Text>
                {CheckoutTypes.resolveItemLabel(assignment.typeId)} -{" "}
                {CheckoutTypes.resolveItemDescription(assignment.typeId)}
              </Text>
            </>
          }
        />
        <Restrict app={Apps.VEHICLES}>
          <DataItem
            label="Vehicle"
            value={
              assignment.vehicleId ? (
                <Anchor to="/apps/vehicles">
                  <VehicleName vehicleId={assignment.vehicleId} />
                </Anchor>
              ) : (
                <Text className="text-gray-400">N/A</Text>
              )
            }
          />
        </Restrict>
        <FieldGroup label="Actions">
          <WarningButton
            onClick={this.onUnassign}
            disabled={isUpdating}
            icon={isUpdating && <Loading size="small" className={styles.removingIcon} />}
          >
            Manually check-in
          </WarningButton>
        </FieldGroup>
      </DataGrid>
    ) : (
      <div className={styles.container}>
        <InputGroups>
          <InputGroup>
            <UsersInput name="user" value={user} onChange={this.onChange} />
            <PrimaryButton onClick={this.onAssign} disabled={!user || isUpdating} className={styles.submitBtn}>
              Assign
            </PrimaryButton>
          </InputGroup>
        </InputGroups>
      </div>
    );
  }
}

export default AssetAssignee;
