import { useEffect, useState } from "react";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectKeycardByID, updateKeycard } from "store/reducers";
import { TKeycard } from "types";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import PowerInput from "components/inputs/PowerInput";
import { VSpace } from "components/shared/layouts";

type KeycardPinCodeInputProps = {
  keycardId: string;
};

export const KeycardPinCodeInput = ({ keycardId }: KeycardPinCodeInputProps) => {
  const dispatch = useDispatch();
  const data = useSelector(selectKeycardByID(keycardId));
  const [dirty, setDirty] = useState<boolean>(false);
  const [value, setValue] = useState<Pick<TKeycard, "pinCode" | "rotatePinPad">>({
    pinCode: data.pinCode,
    rotatePinPad: data.rotatePinPad,
  });

  useEffect(() => {
    setValue({
      pinCode: data.pinCode,
      rotatePinPad: data.rotatePinPad,
    });
  }, [data]);

  const onSuccess = () => {
    dispatch(
      showPopupFlag({
        appearance: "success",
        title: "Pin code configuration saved",
      }),
    );
  };

  const saveChanges = async () => {
    try {
      await dispatch(
        updateKeycard({
          ...data,
          ...value,
        }),
      ).unwrap();
      setDirty(false);
      onSuccess();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleChange = (name: keyof TKeycard, value: string) => {
    if (name === "pinCode") {
      // Do not include any non-numbers. We do not support alphanumeric characters yet.
      value = value.replace(/\D/g, "");
    }

    // Set dirty if the value changes
    if (value !== data[name]) {
      setDirty(true);
    }

    setValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBlur = () => {
    if (dirty) {
      saveChanges();
    }
  };

  const toggleRotatePinPad = async () => {
    try {
      await dispatch(
        updateKeycard({
          ...data,
          rotatePinPad: !data.rotatePinPad,
        }),
      ).unwrap();
      onSuccess();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <VSpace>
      <FieldGroup
        label="Pin code"
        value={
          <TextInput
            input={{
              name: "pinCode",
              placeholder: "Enter pin code",
            }}
            value={value.pinCode}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        }
      />
      <FieldGroup
        label="Shuffle pin pad"
        value={<PowerInput value={data.rotatePinPad} onChange={toggleRotatePinPad} />}
      />
    </VSpace>
  );
};
