//@ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as type from "lib/actions/actionTypes";
import { createAlert } from "lib/actions/alerts";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getAlertFetching } from "lib/reducers/alerts.selectors";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextAreaInput, TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import style from "./RootModal/modal.module.scss";

const CreateAlertModal = ({ modal, onClose }) => {
  const dispatch = useDispatch();
  const { onSuccess } = modal.props;
  const [values, setValues] = useState({ name: "", description: "" });
  const submitting = useSelector(getAlertFetching(type.CREATE_ALERT_REQUEST));

  const handleChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      createAlert(
        values,
        (data) => {
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Created Alert",
            }),
          );
          onClose();
          onSuccess && onSuccess(data);
        },
        (err) => {
          handlePopupFlagError(err);
        },
      ),
    );
  };

  const valid = values.name;

  return (
    <Modal className={style.genericModal}>
      <ModalHeader title="Setup an alert" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter alert name",
                autoFocus: true,
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Description">
            <TextAreaInput
              input={{
                name: "description",
                placeholder: "Describe the alert",
              }}
              value={values.description}
              onChange={handleChange}
            />
          </FieldGroup>
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton type="submit" disabled={!valid || submitting} onClick={handleSubmit}>
          Create alert
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

export default CreateAlertModal;
