import { ChangeEvent } from "react";
import { Disclosure } from "@headlessui/react";
import { FilterIcon, SearchIcon } from "@heroicons/react/outline";
import { FetchAssetsParams } from "store/reducers";

type ToolStoreInventorySearchProps = {
  value: FetchAssetsParams;
  onChange: (value: FetchAssetsParams) => void;
};

const filters = {
  available: [
    { value: true, label: "In-use", checked: false },
    { value: false, label: "Available", checked: false },
  ],
  calibration: [
    { value: true, label: "Calibrated", checked: false },
    { value: false, label: "Standard", checked: false },
  ],
};

export const ToolStoreInventorySearch = ({ value, onChange }: ToolStoreInventorySearchProps) => {
  const filtersCount = [value.calibrated, value.assigned]
    .filter((value) => typeof value !== "undefined")
    .reduce((count) => count + 1, 0);

  const clearFilters = () => {
    onChange({
      ...value,
      assigned: undefined,
      calibrated: undefined,
    });
  };

  return (
    <div className="flex-1 flex flex-col gap-6 justify-center">
      <div>
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            name="search"
            className="block w-full pl-10 pr-3 py-3 border border-transparent rounded-md leading-5 bg-gray-100 text-gray-300 placeholder-gray-400 focus:outline-none focus:text-gray-900 sm:text-sm"
            placeholder="Search"
            type="search"
            value={value.query}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange({ ...value, query: event.target.value })}
          />
        </div>
      </div>

      <Disclosure as="section" aria-labelledby="filter-heading" className="relative grid items-center">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="relative col-start-1 row-start-1">
          <div className="flex justify-between space-x-6 text-sm px-2">
            <div>
              <Disclosure.Button className="group text-gray-700 font-medium flex items-center">
                <FilterIcon
                  className="flex-none w-5 h-5 mr-2 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                {filtersCount !== 0 ? `${filtersCount} ` : null}
                Filters
              </Disclosure.Button>
            </div>
            <div>
              <button type="button" className="text-gray-500" onClick={clearFilters}>
                Clear all
              </button>
            </div>
          </div>
        </div>
        <Disclosure.Panel className="border-t border-gray-200 py-10 mt-6">
          <div className="max-w-7xl mx-auto grid grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <div className="grid grid-cols-1 gap-y-10 auto-rows-min md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">Available</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  {filters.available.map((option) => (
                    <div key={option.label} className="flex items-center text-base sm:text-sm">
                      <input
                        id={`available_${option.label}`}
                        name="available"
                        type="radio"
                        value={`${option.value}`}
                        className="flex-shrink-0 h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                        checked={value.assigned === option.value}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          onChange({
                            ...value,
                            assigned: event.target.value === "true",
                          });
                        }}
                      />
                      <label htmlFor={`available_${option.label}`} className="ml-3 min-w-0 flex-1 text-gray-600">
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Calibration</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  {filters.calibration.map((option) => (
                    <div key={option.label} className="flex items-center text-base sm:text-sm">
                      <input
                        id={`calibration_${option.label}`}
                        name="calibration"
                        type="radio"
                        value={`${option.value}`}
                        className="flex-shrink-0 h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                        checked={value.calibrated === option.value}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          onChange({
                            ...value,
                            calibrated: event.target.value === "true",
                          });
                        }}
                      />
                      <label htmlFor={`calibration_${option.label}`} className="ml-3 min-w-0 flex-1 text-gray-600">
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};
