//@ts-nocheck

export default class CustomerTypes {
  static BUSINESS = "business";
  static INDIVIDUAL = "individual";

  static get items() {
    return [
      {
        label: "Business",
        description: "You are creating a business or corporation account",
        value: this.BUSINESS,
      },
      {
        label: "Individual",
        description: "You are creating an individual account ",
        value: this.INDIVIDUAL,
      },
    ];
  }
}
