//@ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Papa from "papaparse";
import Catalogue from "lib/api/catalogue";
import ImportProductColumnTypes from "lib/constants/ImportProductColumnTypes";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { FileInput } from "components/Input";
import Loading from "components/Loading";
import { Paragraph } from "components/Typography";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { ImportedProductsTable } from "components/shared/features/dataimport/tables";
import SelectColumnTypes from "pages/apps/ShopManager/ShopImportPage/components/ShopImporter/SelectColumnTypes";

const State = {
  Import: 1,
  Map: 2,
  Processing: 3,
  Finish: 4,
};

export const ImportProductsModal = ({ modal, onClose }) => {
  const dispatch = useDispatch();
  const { manufacturerId, onSuccess } = modal.props;
  const [state, setState] = useState(State.Import);
  const [files, setFiles] = useState([]);
  const [parsing, setParsing] = useState(false);
  const [itemsCreated, setItemsCreated] = useState([]);
  const [columnsMapping, setColumnsMapping] = useState(null);
  const [rows, setRows] = useState([]);

  const handleUpload = async (_, files) => {
    try {
      setFiles(files);
      const file = files[0];
      setParsing(true);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        worker: true,
        complete: (results) => {
          setParsing(false);
          setRows(results.data);
          setState(State.Map);
        },
      });
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleConfirmColumns = (mapping) => {
    setColumnsMapping(mapping);
  };

  const handleBeginImport = async () => {
    const columnsMappingKeys = Object.keys(columnsMapping);
    setState(State.Processing);
    for (const row of rows) {
      const product = {
        manufacturerId,
      };

      for (const columnKey of columnsMappingKeys) {
        const columnType = ImportProductColumnTypes.resolveItem(columnsMapping[columnKey]);
        if (!columnType) {
          continue;
        }
        const cellKey = columnType.fieldName;
        const cellValue = row[columnKey];

        switch (columnType.value) {
          case ImportProductColumnTypes.PRICE:
            product[cellKey] = parseFloat(cellValue);
            break;
          default:
            product[cellKey] = cellValue;
            break;
        }
      }

      try {
        const createdStoreItem = await Catalogue.createProduct(product);
        setItemsCreated((items) => [...items, createdStoreItem]);
      } catch (err) {
        handlePopupFlagError(err);
      }
    }

    setState(State.Finish);
    onSuccess(itemsCreated);
  };

  const renderContent = () => {
    switch (state) {
      case State.Import:
        return parsing ? (
          <Loading size="large" />
        ) : (
          <FileInput
            input={{
              name: "file",
              placeholder: "Drag & Drop your product file here",
            }}
            value={files}
            onChange={handleUpload}
          />
        );
      case State.Map:
        return (
          <SelectColumnTypes
            rows={rows}
            columnTypeOptions={ImportProductColumnTypes.items}
            onConfirm={handleConfirmColumns}
          />
        );
      case State.Processing:
        return (
          <>
            <Loading size="large" />
            <br />
            <Paragraph className="text-center" align="center">
              Processed {itemsCreated.length} of {rows.length} rows
            </Paragraph>
          </>
        );
      case State.Finish:
        return <ImportedProductsTable itemsCreated={itemsCreated} />;
      default:
        return null;
    }
  };

  const renderFooter = () => {
    if (!manufacturerId) {
      return null;
    }

    switch (state) {
      case State.Import:
      case State.Map:
        return (
          <>
            <PrimaryButton type="submit" disabled={!columnsMapping} onClick={handleBeginImport}>
              Begin import
            </PrimaryButton>
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          </>
        );
      case State.Processing:
        return null;
      case State.Finish:
        return <SecondaryButton onClick={onClose}>Close</SecondaryButton>;
      default:
        return null;
    }
  };

  return (
    <Modal>
      <ModalHeader title="Import products" />
      <ModalContent>{renderContent()}</ModalContent>
      <ModalFooter>{renderFooter()}</ModalFooter>
    </Modal>
  );
};
