//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import * as type from "../actions/actionTypes";
import * as actions from "../actions/lookupData";
import Api from "../api/lookupData";

function* fetchLookups() {
  try {
    const { data } = yield Api.getLookups();
    yield put(actions.receiveLookups(data));

    const { customer, me, branding, roles, users } = data;
    yield put(actions.storeLookup("customer", customer));
    yield put(actions.storeLookup("branding", branding ? branding.configuration : null));
    yield put(actions.storeLookup("roles", roles));
    yield put(actions.storeLookup("users", users));

    if (me) {
      yield put(actions.storeLookup("me", me));
    }
  } catch (err) {
    yield put(actions.requestLookupsFailure(err));
  }
}

function* lookupDataSaga() {
  yield takeLatest(type.GET_LOOKUPS_REQUEST, fetchLookups);
}

export default lookupDataSaga;
