//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class ImportProductColumnTypes extends Lookup {
  PRODUCT_NUMBER = "mpn";
  NAME = "name";
  DESCRIPTION = "description";
  PRODUCT_URL = "productUrl";
  IMAGE_URL = "imageUrl";
  THUMBNAIL_IMAGE_URL = "thumbnailUrl";
  PRICE = "price";
  PRICE_CURRENCY = "priceCurrency";

  constructor() {
    super();
    super._items = [
      {
        label: "Product Number",
        value: this.PRODUCT_NUMBER,
        fieldName: "mpn",
      },
      {
        label: "Name",
        value: this.NAME,
        fieldName: "name",
      },
      {
        label: "Description",
        value: this.DESCRIPTION,
        fieldName: "description",
      },
      {
        label: "Image",
        value: this.IMAGE_URL,
        fieldName: "imageUrl",
      },
      {
        label: "Thumbnail Image",
        value: this.THUMBNAIL_IMAGE_URL,
        fieldName: "imageUrl",
      },
      {
        label: "Product URL",
        value: this.PRODUCT_URL,
        fieldName: "productUrl",
      },
      {
        label: "Price",
        value: this.PRICE,
        fieldName: "price",
      },
      {
        label: "Price Currency",
        value: this.PRICE_CURRENCY,
        fieldName: "priceCurrency",
      },
    ];
  }
}

export default new ImportProductColumnTypes();
