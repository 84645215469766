//@ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  pageBlocksChange,
  selectBlockID,
  setCurrentBlockID,
  showBlockMenuFor,
  showConfigMenuFor,
} from "lib/actions/blocks";
import BlockAPI from "lib/api/blocks";
import BlockProperties from "lib/blocks/BlockProperties";
import BlockTypes from "lib/constants/BlockTypes";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getCurrentBlockID, getPageBlocks } from "lib/reducers/blocks.selectors";
import { DragHandle, DraggableItem } from "components/Draggable";
import Icon from "components/Icon";
import FileBlock from "components/Sheet/components/FileBlock";
import TaskListBlock from "components/Sheet/components/TaskListBlock";
import TextBlock from "components/Sheet/components/TextBlock";
import ToDoBlock from "components/Sheet/components/ToDoBlock";
import WebLinkBlock from "components/Sheet/components/WebLinkBlock";
import YouTubeBlock from "components/Sheet/components/YouTubeBlock";
import { getDOMNodeForBlockID, insertBlockAfterSibling, orderPageBlocks, updateBlock } from "components/Sheet/utils";
import style from "../sheet.module.scss";

const determineNextBlockType = (currentBlock) => {
  switch (currentBlock.type) {
    case BlockTypes.TODO:
      return BlockTypes.TODO;
    case BlockTypes.TEXT:
    default:
      return BlockTypes.TEXT;
  }
};

const SheetBlockHandle = ({ blockID }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(showBlockMenuFor(getDOMNodeForBlockID(blockID)));
  };

  const handleShowMenu = () => {
    dispatch(showConfigMenuFor(getDOMNodeForBlockID(blockID)));
  };

  return (
    <div className={classNames("sortable-handle", style.blockHandle)}>
      <Icon src="/images/icons/plus_1.svg" className="sortable-handle-icon" onClick={handleClick} />
      <DragHandle onClick={handleShowMenu} />
    </div>
  );
};

const SheetBlock = ({ blockID, onCreateBlock, onRemoveBlock, showSorting, className }) => {
  const dispatch = useDispatch();
  const pageBlocks = useSelector(getPageBlocks);
  const currentBlockID = useSelector(getCurrentBlockID);
  const blockActive = currentBlockID === blockID;
  const pageBlock = pageBlocks[blockID];
  const { block } = pageBlock;

  const swapCurrentBlockForTextBlock = async () => {
    const updatedBlock = {
      ...block,
      type: BlockTypes.TEXT,
      properties: {
        text: "",
      },
    };
    dispatch(
      pageBlocksChange(
        Object.values({
          ...pageBlocks,
          [block.id]: {
            ...pageBlocks[block.id],
            block: updatedBlock,
          },
        }),
      ),
    );
    await BlockAPI.updateBlock(block.id, updatedBlock);
  };

  const handleAddBlock = async () => {
    try {
      // Determine whether to swap the current block for a text block when double tapping enter
      if (block.type === BlockTypes.TODO && block.properties.text === "") {
        await swapCurrentBlockForTextBlock();
        return;
      }

      // Create the new block
      const newBlock = await BlockAPI.createBlock({
        type: determineNextBlockType(block),
        properties: BlockProperties.createForBlockType(BlockTypes.TEXT),
      });

      const newPageBlocks = insertBlockAfterSibling(pageBlocks, currentBlockID, newBlock);
      dispatch(pageBlocksChange(newPageBlocks));
      dispatch(selectBlockID(newBlock.id));

      onCreateBlock && onCreateBlock(newPageBlocks, newBlock);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleRemoveBlock = async () => {
    try {
      const currentOrder = pageBlock.order;
      const blockID = block.id;
      const { [blockID]: value, ...otherPageBlocks } = pageBlocks;
      const newPageBlocks = orderPageBlocks(otherPageBlocks).map((block, index) => ({
        ...block,
        order: index,
      }));
      dispatch(pageBlocksChange(newPageBlocks));

      // Select the previous block
      dispatch(selectBlockID(newPageBlocks[currentOrder - 1].blockId));

      onRemoveBlock && onRemoveBlock(newPageBlocks, blockID);
      await BlockAPI.deleteBlock(blockID);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleClick = () => {
    dispatch(setCurrentBlockID(blockID));
  };

  const handleChange = async (updatedBlock) => {
    try {
      dispatch(pageBlocksChange(updateBlock(pageBlocks, updatedBlock)));
      await BlockAPI.updateBlock(block.id, updatedBlock);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const commonProps = {
    block,
    onChange: handleChange,
    onAddBlock: handleAddBlock,
    onRemoveBlock: handleRemoveBlock,
  };

  const renderers = {
    [BlockTypes.TEXT]: <TextBlock {...commonProps} />,
    [BlockTypes.HEADER_ONE]: <TextBlock {...commonProps} tag="h2" className={style.headerOneBlock} />,
    [BlockTypes.HEADER_TWO]: <TextBlock {...commonProps} tag="h3" className={style.headerTwoBlock} />,
    [BlockTypes.HEADER_THREE]: <TextBlock {...commonProps} tag="h4" className={style.headerThreeBlock} />,
    [BlockTypes.NOTICE]: <TextBlock {...commonProps} className={style.notice} />,
    [BlockTypes.TODO]: <ToDoBlock {...commonProps} />,
    [BlockTypes.YOUTUBE]: <YouTubeBlock {...commonProps} />,
    [BlockTypes.FILE]: <FileBlock {...commonProps} />,
    [BlockTypes.LINK]: <WebLinkBlock {...commonProps} />,
    [BlockTypes.TASK_LIST]: <TaskListBlock {...commonProps} />,
  };

  return block ? (
    <DraggableItem
      id={blockID}
      index={pageBlock.order}
      className={classNames({ [style.blockActive]: blockActive })}
      dragHandle={<SheetBlockHandle blockID={blockID} />}
      showSorting={showSorting}
      onClick={handleClick}
    >
      {renderers[block.type]}
    </DraggableItem>
  ) : null;
};

SheetBlock.defaultProps = {};

SheetBlock.propTypes = {
  onCreateBlock: PropTypes.func,
  onRemoveBlock: PropTypes.func,
  className: PropTypes.string,
};

export default SheetBlock;
