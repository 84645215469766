import { ReactNode } from "react";
import classNames from "classnames";
import styled from "styled-components";
import { Group, GroupProps } from "components/shared";
import { TextBody } from "components/shared/typography/TextBody/TextBody";
import style from "./dataGrid.module.scss";

type DataGridProps = {
  className?: string;
  children?: ReactNode | ReactNode[];
};

export const DataGrid = ({ className, children }: DataGridProps) => (
  <dl className={classNames(style.dataGrid, className)}>{children}</dl>
);

const DataLabel = styled.dt`
  color: #525866;
  font-weight: 500;
  padding-right: 1rem;
  align-self: flex-start;
  min-width: 8rem;

  label {
    margin-bottom: 0;
  }
`;

const DataItemDescription = styled(TextBody)`
  font-weight: 400;
  font-size: 0.875rem;
  margin-top: 0.125rem;
  line-height: 1em;
`;

type DataItemProps = {
  label: string | ReactNode;
  description?: string | ReactNode;
  value?: string | ReactNode;
  children?: ReactNode;
  labelClassName?: string;
  valueClassName?: string;
};

export const DataItem = ({ label, description, value, children, labelClassName, valueClassName }: DataItemProps) => (
  <>
    <DataLabel className={labelClassName}>
      {typeof label !== "string" ? label : <label>{label}</label>}
      {typeof description !== "string" ? (
        description
      ) : (
        <DataItemDescription textColor="text-gray-400">{description}</DataItemDescription>
      )}
    </DataLabel>
    <dd className={classNames(style.dataValue, valueClassName)}>{value || children}</dd>
  </>
);

type DataItemGroupProps = GroupProps;

export const DataItemGroup = (props: DataItemGroupProps) => {
  return <Group {...props} />;
};
