//@ts-nocheck
import React from "react";
import AppComingSoon from "components/AppComingSoon";
import PageStage from "components/stages/PageStage";

const ComingSoon = () => {
  return (
    <PageStage>
      <AppComingSoon title="🛠 Coming soon" description="We are working hard on bringing this feature to Automify" />
    </PageStage>
  );
};

ComingSoon.defaultProps = {};

ComingSoon.propTypes = {};

export default ComingSoon;
