import { ReactNode } from "react";
import styled from "styled-components";
import { Heading } from "components/shared";

const StyledHeading = styled(Heading)`
  color: rgb(107 114 128);
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  max-width: 90%;
`;

type SidebarHeadingProps = {
  children: ReactNode;
};

export const SidebarHeading = ({ children }: SidebarHeadingProps) => {
  return <StyledHeading level="h3">{children}</StyledHeading>;
};
