//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import styles from "./QuantityInput.module.scss";

export const QuantityInput = ({ value, step, min, max, onChange }) => {
  const handleChange = (value) => {
    if (value < min) {
      value = min;
    }
    if (value > max) {
      value = max;
    }
    onChange && onChange(value);
  };
  const increment = () => handleChange(value + 1);
  const decrement = () => handleChange(value - 1);
  const setValue = (event) => event.target.value && handleChange(parseInt(event.target.value));
  return (
    <div className={styles.QuantityInput}>
      <div className={styles.QuantityInputMinus} onClick={decrement}>
        <Icon src="/images/icons/minus_1.svg" size="small" className={styles.QuantityInputMinusIcon} />
      </div>
      <div className={styles.QuantityInputValue}>
        <input
          type="number"
          step={step}
          min={min}
          max={max}
          value={value}
          className={styles.QuantityInputValueInput}
          onChange={setValue}
        />
      </div>
      <div className={styles.QuantityInputPlus} onClick={increment}>
        <Icon src="/images/icons/plus_2.svg" className={styles.QuantityInputPlusIcon} />
      </div>
    </div>
  );
};

QuantityInput.defaultProps = {
  value: 1,
  min: 1,
  max: 100,
  step: 1,
};

QuantityInput.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
};
