//@ts-nocheck
import { createSelector } from "reselect";

export const getBranding = createSelector(
  (state) => state.lookupData,
  (lookupData = {}) => {
    return lookupData.branding ? lookupData.branding : null;
  },
);

export const getCustomer = createSelector(
  (state) => state.lookupData,
  (lookupData = {}) => {
    return lookupData.customer ? lookupData.customer : null;
  },
);

export const getMe = createSelector(
  (state) => state.lookupData,
  (lookupData = {}) => {
    return lookupData.me ? lookupData.me : null;
  },
);

export const selectCapabilities = createSelector(
  (state) => state.lookupData,
  (data = {}) => {
    return data.capabilities;
  },
);
