//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FixLater } from "types";
import classes from "./table.module.scss";

export const TableRow = ({ className, children, onClick, ...otherProps }: FixLater) => (
  <tr {...otherProps} className={classNames(classes.tableRow, className)} onClick={onClick}>
    {children}
  </tr>
);

TableRow.defaultProps = {
  className: "",
};

TableRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default TableRow;
