import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  DataResponse,
  PagedDataResponse,
  Pagination,
  TCatalogueProduct,
  UUID,
  FetchCatalogueProductsRequest,
} from "types";

export const fetchCatalogueProducts = createAsyncThunk(
  "catalogue/fetchCatalogueProducts",
  async (request: FetchCatalogueProductsRequest): Promise<{ results: TCatalogueProduct[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TCatalogueProduct[]>>("/catalogue/products", request);
    return { results: data, paging: metadata };
  },
);

export const fetchCatalogueProductById = createAsyncThunk(
  "catalogue/fetchCatalogueProductById",
  async (id: UUID): Promise<TCatalogueProduct> => {
    const { data } = await API.GET<DataResponse<TCatalogueProduct>>(`/catalogue/products/${id}`);
    return data;
  },
);
