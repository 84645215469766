//@ts-nocheck
import { createSelector } from "reselect";

export const getPopupFlags = createSelector(
  (state) => state.userInterface,
  (userInterface = {}) => {
    return userInterface.popupFlags;
  },
);

export const getModal = createSelector(
  (state) => state.userInterface,
  (userInterface = {}) => {
    return userInterface.modal;
  },
);

export const getSidebar = createSelector(
  (state) => state.userInterface,
  (userInterface = {}) => {
    return userInterface.sidebar;
  },
);

export const getKeyboard = createSelector(
  (state) => state.userInterface,
  (userInterface = {}) => {
    return userInterface.keyboard;
  },
);

export const getTooltip = createSelector(
  (state) => state.userInterface,
  (userInterface = {}) => {
    return userInterface.tooltip;
  },
);
