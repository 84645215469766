import React, { useState } from "react";
import Papa from "papaparse";
import Resolve from "lib/Resolve";
import { showPopupFlag } from "lib/actions/userInterface";
import AssetAPI from "lib/api/assets";
import ImportAssetColumnTypes from "lib/constants/ImportAssetColumnTypes";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { CreateAssetRequest, FixLater, TAsset } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { CheckboxInput, FileInput } from "components/Input";
import Loading from "components/Loading";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { ImportedAssetsTable, TextBody, VSpace } from "components/shared";
import SelectColumnTypes from "pages/apps/ShopManager/ShopImportPage/components/ShopImporter/SelectColumnTypes";

const State = {
  Import: 1,
  Map: 2,
  Preflight: 3,
  Processing: 4,
  Finish: 5,
  Failed: 6,
};

export const ImportAssetsModal = ({ modal, onClose }: FixLater) => {
  const dispatch = useDispatch();
  const { onSuccess } = modal.props;
  const [state, setState] = useState(State.Import);
  const [files, setFiles] = useState([]);
  const [parsing, setParsing] = useState(false);
  const [invalidRows, setInvalidRows] = useState<number[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [itemsCreated, setItemsCreated] = useState<TAsset[]>([]);
  const [conflicts, setConflicts] = useState<CreateAssetRequest[]>([]);
  const [unknowns, setUnknowns] = useState<CreateAssetRequest[]>([]);
  const [columnsMapping, setColumnsMapping] = useState<FixLater>(null);
  const [rows, setRows] = useState([]);
  const [customTooling, setCustomTooling] = useState<boolean>(false);

  const handleUpload = async (_: FixLater, files: FixLater) => {
    try {
      setFiles(files);
      const file = files[0];
      setParsing(true);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        worker: true,
        complete: (results: FixLater) => {
          setParsing(false);
          setRows(results.data);
          setState(State.Map);
        },
      });
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleBeginImport = async () => {
    const columnsMappingKeys = Object.keys(columnsMapping);
    setState(State.Preflight);

    const assets: CreateAssetRequest[] = [];
    for (const row of rows) {
      const entity: CreateAssetRequest = {
        mpn: undefined,
        barcodeTracking: false,
        barcodeValue: "",
        rfidTracking: false,
        rfidTagId: [],
        includeInReports: true,
        enabled: true,
        serialNumber: "",
        description: "",
        customAssetProduct: customTooling
          ? {
              name: "",
              description: "",
              imageUrl: "",
              mpn: "",
            }
          : undefined,
      };

      for (const columnKey of columnsMappingKeys) {
        const columnType = ImportAssetColumnTypes.resolveItem(columnsMapping[columnKey]);
        if (!columnType) {
          continue;
        }
        const cellKey: keyof CreateAssetRequest = columnType.value;
        const cellValue = row[columnKey];

        switch (cellKey) {
          case ImportAssetColumnTypes.PRODUCT_NUMBER:
            if (entity.customAssetProduct) {
              entity.customAssetProduct.mpn = cellValue;
            } else {
              entity.mpn = cellValue;
            }
            break;
          case ImportAssetColumnTypes.BARCODE_VALUE:
            entity.barcodeValue = cellValue;
            entity.barcodeTracking = true;
            break;
          case ImportAssetColumnTypes.RFID_VALUE:
            entity.rfidTagId = (cellValue as string).split(",");
            entity.rfidTracking = true;
            break;
          case ImportAssetColumnTypes.ASSET_NAME:
            if (entity.customAssetProduct) {
              entity.customAssetProduct.name = cellValue;
            }
            break;
          case ImportAssetColumnTypes.ASSET_IMAGE_URL:
            if (entity.customAssetProduct) {
              entity.customAssetProduct.imageUrl = cellValue;
            }
            break;
          case ImportAssetColumnTypes.ASSET_DESCRIPTION:
            if (entity.customAssetProduct) {
              entity.customAssetProduct.description = cellValue;
            }
            entity.description = cellValue;
            break;
          case ImportAssetColumnTypes.ASSET_LOCATION: {
            // Resolve location name in the import data to a location ID
            const location = Resolve.resolveLocationByName((cellValue as string).trim());
            if (location) {
              entity.locationId = location.id;
            }
            break;
          }
          case ImportAssetColumnTypes.STORAGE_ID:
          case ImportAssetColumnTypes.STORAGE_ASSET_DRAWER_NUMBER:
          case ImportAssetColumnTypes.STORAGE_ASSET_ORDER:
          case ImportAssetColumnTypes.STORAGE_ASSET_SVG:
          case ImportAssetColumnTypes.STORAGE_ASSET_BOARD_ID:
          case ImportAssetColumnTypes.STORAGE_ASSET_BOARD_SENSOR_ID:
          case ImportAssetColumnTypes.STORAGE_ASSET_BOARD_SENSOR_THRESHOLD: {
            // Make sure that the storageAsset has been initialized
            if (!entity.storageAsset) {
              entity.storageAsset = {
                storageId: "",
                drawerNumber: 0,
              };
            }

            // Numbers will need to be parsed.
            const nestedKey = cellKey as FixLater;
            switch (nestedKey) {
              case ImportAssetColumnTypes.STORAGE_ASSET_DRAWER_NUMBER:
              case ImportAssetColumnTypes.STORAGE_ASSET_ORDER:
              case ImportAssetColumnTypes.STORAGE_ASSET_BOARD_ID:
              case ImportAssetColumnTypes.STORAGE_ASSET_BOARD_SENSOR_ID:
              case ImportAssetColumnTypes.STORAGE_ASSET_BOARD_SENSOR_THRESHOLD:
                entity.storageAsset = {
                  ...entity.storageAsset,
                  [nestedKey]: parseInt(cellValue) || 0,
                };
                break;
              default:
                entity.storageAsset = {
                  ...entity.storageAsset,
                  [nestedKey]: cellValue,
                };
                break;
            }
            break;
          }
          default:
            entity[cellKey] = cellValue;
            break;
        }
      }

      assets.push(entity);
    }

    // Dry run. If any errors, the import will be cancelled
    const failedRows: number[] = [];
    setProgress(0);
    for (let i = 0; i < assets.length; i++) {
      try {
        const asset = assets[i];
        await AssetAPI.createAsset({
          ...asset,
          dryRun: true,
        });
      } catch (err: FixLater) {
        // Conflicts are not an issue.
        if (err.status === 404) {
          failedRows.push(i);
        }
        if (err.status === 500) {
          handlePopupFlagError(err);
          // Return.
          return;
        }
      } finally {
        setProgress((prevState) => prevState + 1);
      }
    }

    // Stop now if there are issues.
    if (failedRows.length) {
      setState(State.Failed);
      setInvalidRows(failedRows);
      dispatch(
        showPopupFlag({
          appearance: "info",
          title: `${failedRows.length} issues, import cancelled`,
        }),
      );
      return;
    }

    setState(State.Processing);
    setProgress(0);

    // Actually create the assets
    for (const asset of assets) {
      try {
        const createdItem = await AssetAPI.createAsset(asset);
        setItemsCreated((items) => [...items, createdItem]);
      } catch (err: FixLater) {
        // For conflicts do not show the error flag
        if (err.status !== 409) {
          handlePopupFlagError(err);
        }

        // Capture the errors
        if (err.status === 400) {
          setUnknowns((items) => [...items, asset]);
        }
        if (err.status === 409) {
          setConflicts((items) => [...items, asset]);
        }
      } finally {
        setProgress((prevState) => prevState + 1);
      }
    }

    setState(State.Finish);
    onSuccess(itemsCreated);
  };

  const renderContent = () => {
    switch (state) {
      case State.Import:
        return parsing ? (
          <Loading size="large" />
        ) : (
          <FileInput
            input={{
              name: "file",
              placeholder: "Upload a file or drag and drop",
            }}
            value={files}
            onChange={handleUpload}
          />
        );
      case State.Map:
        return (
          <SelectColumnTypes
            rows={rows}
            columnTypeOptions={ImportAssetColumnTypes.items}
            onConfirm={setColumnsMapping}
          />
        );
      case State.Preflight:
        return (
          <VSpace className="items-center">
            <Loading size="large" />
            <TextBody>
              Validating import data row {progress} of {rows.length}
            </TextBody>
          </VSpace>
        );
      case State.Processing:
        return (
          <VSpace className="items-center">
            <Loading size="large" />
            <TextBody>
              Importing row {progress} of {rows.length}
            </TextBody>
          </VSpace>
        );
      case State.Finish:
        return <ImportedAssetsTable itemsCreated={itemsCreated} conflicts={conflicts} unknowns={unknowns} />;
      case State.Failed:
        return (
          <VSpace>
            <TextBody className="text-center">Import failed</TextBody>
            <TextBody>Invalid rows: {invalidRows.sort((a, b) => a - b).join(", ")}</TextBody>
          </VSpace>
        );
      default:
        return null;
    }
  };

  const renderFooter = () => {
    switch (state) {
      case State.Import:
      case State.Map:
        return (
          <div className="flex flex-row flex-1 items-center justify-between">
            <CheckboxInput
              input={{ name: "isCustom" }}
              label="Create custom products"
              value={customTooling}
              onChange={(_: string, value: boolean) => setCustomTooling(value)}
            />
            <div className="flex gap-2">
              <PrimaryButton type="submit" disabled={!columnsMapping} onClick={handleBeginImport}>
                Begin import
              </PrimaryButton>
              <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
            </div>
          </div>
        );
      case State.Processing:
      case State.Preflight:
        return null;
      case State.Finish:
      case State.Failed:
        return <SecondaryButton onClick={onClose}>Close</SecondaryButton>;
      default:
        return null;
    }
  };

  return (
    <Modal>
      <ModalHeader title="Import tools" />
      <ModalContent>{renderContent()}</ModalContent>
      <ModalFooter>{renderFooter()}</ModalFooter>
    </Modal>
  );
};
