//@ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getStoreItemDocumentByID } from "lib/reducers/storeItems.selectors";
import IconButton from "components/IconButton";
import Loading from "components/Loading";
import styles from "./ShopItemDocument.module.scss";

const ShopItemDocument = ({ editable, itemId, documentId }) => {
  const dispatch = useDispatch();
  const document = useSelector(getStoreItemDocumentByID(itemId, documentId));

  const handleClick = () => {
    // TODO
  };

  return document ? (
    <div className={styles.ShopItemDocument}>
      <a href={document.url}>{document.name}</a>
      {editable && <IconButton className={styles.deleteIcon} icon="/images/icons/cross.svg" onClick={handleClick} />}
    </div>
  ) : (
    <Loading />
  );
};

ShopItemDocument.defaultProps = {};

ShopItemDocument.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
};

export default ShopItemDocument;
