//@ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { requestReportSuccess } from "lib/actions/reports";
import { getReport } from "lib/reducers/reports.selectors";
import IconButton from "components/IconButton";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import LayoutTypes from "../../../LayoutTypes";
import { DropdownPanel } from "../../Dropdown";
import ComponentFilters from "./filters/ComponentFilters";
import style from "./textEditorDropdown.module.scss";

export const RemoveIcon = ({ onClick, className }) => (
  <IconButton icon="/images/icons/trash.svg" iconSize="medium" className={className} onClick={onClick} />
);

const EditTextComponent = ({ section, component, contentValue, onChange, onRemove }) => {
  return (
    <>
      <FieldGroup label={component.name} className={classNames(style.contentInputGroup, style.groupHeader)}>
        <RemoveIcon onClick={onRemove} />
      </FieldGroup>
      <FieldGroup label="Text content" className={classNames(style.contentInputGroup)}>
        <TextInput
          input={{ name: "value", placeholder: "Enter text content" }}
          value={contentValue}
          onChange={onChange}
          className={style.contentInput}
        />
      </FieldGroup>
    </>
  );
};

const EditTableComponent = ({ layout, component, onChange, onRemove }) => {
  return (
    <>
      <FieldGroup label={component.name} className={classNames(style.contentInputGroup, style.groupHeader)}>
        <RemoveIcon onClick={onRemove} />
      </FieldGroup>
      <ComponentFilters component={component} onChange={onChange} />
    </>
  );
};

const SectionEditDropdown = ({ layout, component, className }) => {
  const dispatch = useDispatch();
  const report = useSelector(getReport);

  const handleChange = (name, value) => {
    component[name] = value;
    dispatch(
      requestReportSuccess({
        ...report,
        components: {
          ...report.components,
          [layout.id]: component,
        },
      }),
    );
  };
  const handleRemove = () => {
    dispatch(
      requestReportSuccess({
        ...report,
        components: {
          ...report.components,
          [layout.id]: null,
        },
      }),
    );
  };
  return (
    <DropdownPanel>
      <form className={classNames(style.sectionEditPanel, className)}>
        {layout.type === LayoutTypes.TEXT ? (
          <EditTextComponent
            section={layout}
            component={component}
            contentValue={component.value}
            onChange={handleChange}
            onRemove={handleRemove}
          />
        ) : null}
        {layout.type === LayoutTypes.TABLE ? (
          <EditTableComponent section={layout} component={component} onChange={handleChange} onRemove={handleRemove} />
        ) : null}
      </form>
    </DropdownPanel>
  );
};

SectionEditDropdown.defaultProps = {};

SectionEditDropdown.propTypes = {
  layout: PropTypes.object,
  component: PropTypes.object,
  className: PropTypes.string,
};

export default SectionEditDropdown;
