import { ExternalLinkIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import Resolve from "lib/Resolve";
import { useSelector } from "store";
import { selectToolStoreOrderByID } from "store/reducers";
import { UUID } from "types";
import Anchor from "components/Typography/Anchor";
import { Heading } from "components/shared/typography";

type ToolStoreOrderSlideOverKeyDatesProps = {
  toolStoreOrderId: UUID;
};

const EventKeyDate = ({ label, date }: { label: string; date: string }) => {
  return (
    <div>
      <dt className="font-medium text-gray-500">{label}</dt>
      <dd className="flex mt-1 text-gray-900 sm:col-span-2">{dayjs(date).format("ddd, MMM D, YYYY h:mm A")}</dd>
    </div>
  );
};

const EventTrigger = ({ label, userId }: { label: string; userId: number }) => {
  return (
    <div>
      <dt className="font-medium text-gray-500">{label}</dt>
      <dd className="flex mt-1 text-gray-900 sm:col-span-2">
        {Resolve.resolveUserFullName(userId)}
        <Anchor to={`/configuration/users/${userId}`} target="_blank">
          <ExternalLinkIcon className="w-5 ml-2" />
        </Anchor>
      </dd>
    </div>
  );
};

export const ToolStoreOrderSlideOverKeyDates = ({ toolStoreOrderId }: ToolStoreOrderSlideOverKeyDatesProps) => {
  const entity = useSelector(selectToolStoreOrderByID(toolStoreOrderId));
  if (!entity) {
    return null;
  }
  return (
    <dl className="space-y-4 px-4 sm:px-6">
      <Heading level="h3">Key dates</Heading>

      {entity.dateCreated && <EventKeyDate label="Ordered on" date={entity.dateCreated} />}

      {entity.dateRejected && <EventKeyDate label="Rejected on" date={entity.dateRejected} />}
      {entity.rejectedByUserId && <EventTrigger label="Rejected by" userId={entity.rejectedByUserId} />}

      {entity.dateAccepted && <EventKeyDate label="Accepted on" date={entity.dateAccepted} />}
      {entity.acceptedByUserId && <EventTrigger label="Accepted by" userId={entity.acceptedByUserId} />}

      {entity.dateInTransit && <EventKeyDate label="In transit on" date={entity.dateInTransit} />}
      {entity.inTransitByUserId && <EventTrigger label="In transit by" userId={entity.inTransitByUserId} />}

      {entity.dateDelivered && <EventKeyDate label="Delivered on" date={entity.dateDelivered} />}
      {entity.deliveredByUserId && <EventTrigger label="Delivered by" userId={entity.deliveredByUserId} />}

      {entity.dateReturnRequested && <EventKeyDate label="Return requested on" date={entity.dateReturnRequested} />}
      {entity.returnRequestedByUserId && (
        <EventTrigger label="Return requested by" userId={entity.returnRequestedByUserId} />
      )}

      {entity.dateFulfilled && <EventKeyDate label="Fulfilled on" date={entity.dateFulfilled} />}
      {entity.fulfilledByUserId && <EventTrigger label="Fulfilled by" userId={entity.fulfilledByUserId} />}
    </dl>
  );
};
