//@ts-nocheck
import React from "react";
import AppComingSoon from "components/AppComingSoon";
import PageStage from "components/stages/PageStage";

const AppsConfiguration = () => {
  return (
    <PageStage>
      <AppComingSoon
        title="App configurations are coming soon"
        description="We are building the facility for your business to add, remove, or configure platform Apps."
        showAction={false}
      />
    </PageStage>
  );
};

AppsConfiguration.defaultProps = {};

AppsConfiguration.propTypes = {};

export default AppsConfiguration;
