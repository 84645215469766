import { CreateFileRequest, TFile } from "types/features/file";
import Api from "./api";

class Files extends Api {
  /**
   * Upload a file
   * @async
   * @param {File} file
   * @returns {Promise} Response
   */
  static async upload(file: CreateFileRequest): Promise<TFile> {
    const data = new FormData();
    data.append("file", file);
    const response = await this.post("/files").send(data);
    return response.body.data;
  }
}

export default Files;
