//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as actions from "../lib/actions/evidences";
import mergeProps from "./mergeProps";
import mergeStateStore from "./mergeStateStore";

export default function withReports(WrappedComponent) {
  class Component extends React.Component {
    static propTypes = {
      actions: PropTypes.shape({
        requestEvidence: PropTypes.func,
      }),
      stateStore: PropTypes.shape({
        evidence: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.object,
        }),
      }),
    };
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToProps = (state) =>
    mergeStateStore(state, {
      evidence: state.evidences.evidence,
    });
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  });
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
}
