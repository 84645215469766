import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "store";
import { completePasswordRecovery } from "store/reducers";
import { PasswordInput } from "components/Input";
import { Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { VSpace } from "components/shared";
import { ChangePasswordSuccess } from "pages/authentication/ChangePasswordPage/ChangePasswordPage";
import PasswordRequirements from "pages/authentication/Login/PasswordRequirements";
import AuthForm from "pages/authentication/components/AuthForm";
import AuthFormTitle from "pages/authentication/components/AuthFormTitle";
import AuthSubmitButton from "pages/authentication/components/AuthSubmitButton";

const animation = "animate__animated animate__fast animate__fadeIn";

export const CompletePasswordRecoveryPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState<string>();
  const [values, setValues] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [error, setError] = useState<string>();

  const handleChange = (name: string, value: string) => setValues({ ...values, [name]: value });

  const handlePasswordValidate = (isValid: boolean) => setValidPassword(isValid);

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault();
      if (!authToken) {
        setError("Invalid password recovery state");
        return;
      }

      setSubmitting(true);
      setError(undefined);
      await dispatch(
        completePasswordRecovery({
          authToken,
          password: values.password,
        }),
      ).unwrap();
      setSubmitted(true);
    } catch (err: any) {
      setError(
        err.response.data.message || err.response.data.error.message || "Something went wrong, please try again",
      );
      setSubmitting(false);
    }
  };

  // On first load, check for the token in the URL
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    setAuthToken(queryParams.token as string);
  }, []);

  const isValid = validPassword && values.password === values.passwordConfirm;

  return submitted ? (
    <ChangePasswordSuccess />
  ) : (
    <AuthForm className={animation} onSubmit={handleSubmit}>
      <VSpace>
        <AuthFormTitle title="Create a new password" />
        <FieldGroup label="New password">
          <VSpace gap="gap-2">
            <PasswordInput
              input={{
                name: "password",
                placeholder: "Enter password",
                autoFocus: true,
              }}
              value={values.password}
              onChange={handleChange}
            />
            <PasswordRequirements password={values.password} onValidate={handlePasswordValidate} />
          </VSpace>
        </FieldGroup>
        <FieldGroup label="Confirm new password">
          <PasswordInput
            input={{
              name: "passwordConfirm",
              placeholder: "Confirm password",
            }}
            value={values.passwordConfirm}
            onChange={handleChange}
          />
        </FieldGroup>
        {error && <Text className="text-danger">{error}</Text>}
        <AuthSubmitButton isDisabled={!isValid || submitting}>Change password</AuthSubmitButton>
      </VSpace>
    </AuthForm>
  );
};
