//@ts-nocheck
import Lookup from "lib/constants/Lookup";

export class BlockCategory extends Lookup {
  static BASIC = 1;
  static MEDIA = 2;
  static LIST = 3;
}

class BlockTypes extends Lookup {
  TEXT = 1;
  HEADER_ONE = 2;
  HEADER_TWO = 3;
  HEADER_THREE = 4;
  TODO = 5;
  TASK_LIST = 6;
  ASSET_LIST = 7;
  YOUTUBE = 8;
  FILE = 9;
  IMAGE = 10;
  LINK = 11;
  NOTICE = 12;

  constructor() {
    super();
    super._items = [
      {
        value: this.TEXT,
        label: "Text",
        category: BlockCategory.BASIC,
        description: "Just start writing with plain text",
      },
      {
        value: this.HEADER_ONE,
        label: "Header 1",
        category: BlockCategory.BASIC,
        description: "Big section heading",
      },
      {
        value: this.HEADER_TWO,
        label: "Header 2",
        category: BlockCategory.BASIC,
        description: "Medium section heading",
      },
      {
        value: this.HEADER_THREE,
        label: "Header 3",
        category: BlockCategory.BASIC,
        description: "Small section heading",
      },
      {
        value: this.NOTICE,
        label: "Notice",
        category: BlockCategory.BASIC,
        description: "Make text stand out",
      },
      {
        value: this.TODO,
        label: "To-Do list",
        category: BlockCategory.BASIC,
        description: "Track items with a to-do list",
      },
      {
        value: this.TASK_LIST,
        label: "Task list",
        category: BlockCategory.LIST,
        description: "Create a list of tasks",
      },
      {
        value: this.ASSET_LIST,
        label: "Tool list",
        category: BlockCategory.LIST,
        description: "Create a list of tools",
      },
      {
        value: this.YOUTUBE,
        label: "Youtube video",
        category: BlockCategory.MEDIA,
        description: "Embed a youtube video",
      },
      {
        value: this.FILE,
        label: "File",
        category: BlockCategory.MEDIA,
        description: "Upload a file to embed",
      },
      {
        value: this.LINK,
        label: "Website Link",
        category: BlockCategory.MEDIA,
        description: "Embed a website link",
      },
    ];
  }

  /**
   * Get a filtered list of block types by category
   * @param {number} category
   */
  getTypesForCategory = (category) => {
    return this.items.filter((blockType) => blockType.category === category);
  };
}

export default new BlockTypes();
