//@ts-nocheck
import { openModal } from "lib/actions/modal";
import { showPopupFlag } from "lib/actions/userInterface";
import AssetsAPI from "lib/api/assets";
import Api from "lib/api/storages";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import store from "store";

export const assignAsset = (storage, drawer, callback) => {
  store.dispatch(
    openModal(Modals.ASSIGN_STORAGE_ASSET, {
      storage,
      drawer,
      onSuccess: () => {
        callback && callback();
      },
    }),
  );
};

export const removeAsset = (storageID, assetID, callback) => {
  store.dispatch(
    openModal(Modals.CONFIRMATION, {
      description: "Are you sure you want to un-assign this tool from the toolbox? You can re-assign it later.",
      actionText: "Unassign tool",
      actionColor: "warning",
      onConfirm: async () => {
        try {
          await Api.removeStorageAsset(storageID, assetID);
          store.dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Tool unassigned",
            }),
          );
          callback && callback();
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
    }),
  );
};

export const saveAssetSerialNumber = async (asset, serialNumber = "", callback) => {
  try {
    const updatedAsset = await AssetsAPI.updateAsset(asset.id, {
      ...asset,
      serialNumber,
    });
    store.dispatch(
      showPopupFlag({
        appearance: "success",
        title: "Saved Serial Number",
      }),
    );
    return updatedAsset;
  } catch (err) {
    handlePopupFlagError(err);
  }
};
