import { ComponentProps, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import styled from "styled-components";
import { requestProjectBlocks, requestProjectSuccess } from "lib/actions/projects";
import Projects from "lib/api/projects";
import ProjectAPI from "lib/api/projects";
import Capabilities from "lib/constants/Capabilities";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getProjectBlocks } from "lib/reducers/projects.selectors";
import { FixLater, TProject } from "types";
import { useRoleHasCapability } from "components/Restricted";
import { Sheet } from "components/Sheet/Sheet";
import { SheetHeader } from "components/Sheet/components/SheetHeader";
import { Breadcrumbs, BreadcrumbsItem } from "components/breadcrumbs";
import { ProjectSheetSidebar } from "components/shared/features/projects/ProjectSheetSidebar";

type ProjectSheetProps = ComponentProps<"div"> & {
  project: TProject;
};

export const ProjectSheet = styled(({ project, className }: ProjectSheetProps) => {
  const dispatch = useDispatch();
  const projectBlocks = useSelector(getProjectBlocks);
  const canUpdate = useRoleHasCapability(Capabilities.PROJECT_UPDATE);

  useEffect(() => {
    fetchBlocks();
  }, []);

  const fetchBlocks = () => {
    dispatch(requestProjectBlocks(project.id));
  };

  const handleConfirm = (name: keyof TProject) => {
    return async (value: FixLater) => {
      try {
        if (project[name] === value) {
          // If value has no changed, do nothing
          return;
        }
        const requestBody = {
          ...project,
          [name]: value,
        };
        await Projects.updateProject(project.id, requestBody);
        dispatch(requestProjectSuccess(requestBody));
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const handleBlocksChange = async (pageBlocks: FixLater[]) => {
    try {
      await ProjectAPI.updateProjectBlocks(project.id, pageBlocks);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <div className={classNames(className, "px-10 flex flex-col gap-4")}>
      <Breadcrumbs>
        <BreadcrumbsItem text="Work orders" href="/apps/projects" />
        <BreadcrumbsItem text={project.name} />
      </Breadcrumbs>
      <Helmet>
        <title>{[project.icon, project.name || "Untitled"].filter(Boolean).join(" ")}</title>
      </Helmet>
      <SheetHeader
        disabled={!canUpdate}
        title={project.name}
        icon={project.icon}
        coverImageUrl={project.coverImageUrl}
        onTitleChange={handleConfirm("name")}
        onIconChange={handleConfirm("icon")}
        onCoverImageChange={handleConfirm("coverImageUrl")}
      />
      <div className="flex flex-row divide-x">
        <div className="flex-1 pr-4 sm:pr-6">
          <Sheet
            pageBlocks={projectBlocks}
            onReorder={handleBlocksChange}
            onCreateBlock={handleBlocksChange}
            onRemoveBlock={handleBlocksChange}
          />
        </div>
        <div className="flex-[25rem] flex-grow-0 pl-4 sm:pl-6">
          <ProjectSheetSidebar projectId={project.id} />
        </div>
      </div>
    </div>
  );
})``;
