import mixpanel from "mixpanel-browser";
import { MixAction, TrackAction } from "lib/mixpanel/MixAction";
import { UUID } from "types";

const PROJECT_TOKEN = process.env["REACT_APP_MIXPANEL_PROJECT_TOKEN"] || "";

mixpanel.init(PROJECT_TOKEN, {
  api_host: "https://api-eu.mixpanel.com",
});

export class MixPanel {
  static track(action: MixAction | TrackAction, data: object = {}) {
    mixpanel.track(action, data);
  }

  static identify(userId: UUID) {
    mixpanel.identify(userId);
  }

  static reset() {
    mixpanel.reset();
  }
}
