import styled from "styled-components";
import { Anchor } from "components/Typography";
import { AnchorProps } from "components/Typography/Anchor";

const StyledLink = styled(Anchor)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.375rem 0.5rem;
  width: fit-content;

  &:hover {
    transition: background-color ease 50ms;
    background-color: rgba(208, 215, 222, 0.32);
    border-radius: 0.25rem;
    text-decoration: none;
  }
`;

export const TableLink = (props: AnchorProps) => {
  return <StyledLink {...props} />;
};
