//@ts-nocheck
import React from "react";
import { DangerButton, PrimaryButton, SuccessButton } from "components/Buttons";
import StageHeader, { StageState } from "components/StageHeader";
import { Tab, StageTabs } from "components/StageTabs";
import PageStage from "components/stages/PageStage";
import withReaders from "../../../hocs/withReaders";
import ReaderAntennasContainer from "./components/ReaderAntennas/ReaderAntennasContainer";
import ReaderSettings from "./components/ReaderSettings";
import ReaderTab from "./components/ReaderTab";

const ListReader = ({ reader, stateStore, onChange, onSave, onDelete, stageState }) => {
  const breadcrumb = [
    { label: "Configuration", href: "/configuration" },
    { label: "Readers", href: "/configuration/readers" },
    stateStore.reader.data.name,
  ];
  const buildActionButton = () => {
    switch (stageState) {
      case StageState.INITIAL:
        return (
          <PrimaryButton onClick={onSave} disabled={true}>
            Save changes
          </PrimaryButton>
        );
      case StageState.DIRTY:
        return <PrimaryButton onClick={onSave}>Save changes</PrimaryButton>;
      case StageState.SUCCESS:
        return <SuccessButton disabled={true}>Changes saved!</SuccessButton>;
      case StageState.FAILURE:
        return <DangerButton disabled={true}>Unexpected error</DangerButton>;
    }
  };
  const action = buildActionButton();
  return (
    <PageStage>
      <StageHeader title={stateStore.reader.data.name} breadcrumb={breadcrumb} action={action} />
      <StageTabs initialTab="Reader">
        <Tab name="Reader">
          <ReaderTab reader={reader} onChange={onChange} onDelete={onDelete} />
        </Tab>
        <Tab name="Antennas">
          <ReaderAntennasContainer reader={reader} />
        </Tab>
        <Tab name="Settings">
          <ReaderSettings reader={reader} onDelete={onDelete} />
        </Tab>
      </StageTabs>
    </PageStage>
  );
};

ListReader.defaultProps = {};

ListReader.propTypes = {};

export default withReaders(ListReader);
