//@ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { showMediaMenuFor } from "lib/actions/blocks";
import BlockTypes from "lib/constants/BlockTypes";
import Icon from "components/Icon";
import { Text } from "components/Typography";
import style from "../sheet.module.scss";

const WebLinkBlock = ({ block, onChange, onAddBlock, onRemoveBlock, className }) => {
  const dispatch = useDispatch();
  const { text, url } = block.properties;

  const openMediaMenu = () => {
    if (!url) {
      const blockNode = document.getElementById(block.id);
      if (blockNode) {
        dispatch(showMediaMenuFor(blockNode));
      }
    }
  };

  return (
    <div
      className={classNames(style.flex, style.webLinkBlock, {
        [style.contentPlaceholder]: !url,
      })}
      onClick={openMediaMenu}
    >
      <Text className={style.blockPlaceholder}>
        <Icon src="/images/icons/export_1.svg" className={style.blockPlaceholderIcon} />
        {url ? (
          <>
            <a target="_blank" href={url} rel="noreferrer">
              {text || url}
            </a>
          </>
        ) : (
          BlockTypes.resolveItem(BlockTypes.LINK).description
        )}
      </Text>
    </div>
  );
};

export default WebLinkBlock;
