import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToolStorage } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { openModal } from "lib/actions/modal";
import { showPopupFlag } from "lib/actions/userInterface";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { MixAction, MixPanel } from "lib/mixpanel";
import { useDispatch, useSelector } from "store";
import {
  deleteToolRoom,
  lockDevice,
  powerOffDevice,
  rebootDevice,
  refreshDeviceScreen,
  selectStorageByID,
  selectToolRoomByID,
  unlinkStorage,
  unlockDevice,
  updateStorage,
  updateToolRoom,
} from "store/reducers";
import { FixLater, TLocation, TStorage, TToolRoom, UUID } from "types";
import Avatar from "components/Avatar";
import { SecondaryButton, DangerButton, WarningButton } from "components/Buttons";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import { TextAreaInput, TextInput } from "components/Input";
import StageHeader from "components/StageHeader";
import { Paragraph, Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs/LocationSelector/LocationSelector";
import { VSpace } from "components/shared";

type StorageSettingsPageProps = {
  toolRoomId: UUID;
};

export const ToolRoomSettingsPage = ({ toolRoomId }: StorageSettingsPageProps) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { storageId } = useToolStorage();
  const storage = useSelector(selectStorageByID(storageId || ""));
  const entity = useSelector(selectToolRoomByID(toolRoomId));
  const [values, setValues] = useState<Partial<TStorage>>();

  useEffect(() => {
    if (!storage) return;
    setValues(storage);
  }, [storage]);

  const handleChange = (name: string, value: FixLater) => {
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleBlur = (name: keyof TStorage) => {
    return (value: FixLater) => {
      if (!storage) return;
      saveStorageChanges({
        ...storage,
        [name]: value,
      });
    };
  };

  const saveStorageChanges = async (entity: TStorage) => {
    try {
      await dispatch(updateStorage(entity)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const saveToolRoomChanges = async (entity: TToolRoom) => {
    try {
      await dispatch(updateToolRoom(entity)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const changeLocation = (name: string, value?: TLocation) => {
    if (!storage) return;
    saveStorageChanges({
      ...storage,
      locationId: value?.id,
    });
  };

  const handleUnlinkStorage = () => {
    if (!storageId) return;
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description:
          "Are you sure you want to unlink this tool room? Doing this will return the tool room to factory settings.",
        actionText: "Unlink tool room",
        actionColor: "warning",
        onConfirm: async () => {
          try {
            await dispatch(unlinkStorage(storageId)).unwrap();
            MixPanel.track(MixAction.RemoteControlUnlinkDevice, {
              storageId: storageId,
            });
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Tool room unlinked",
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
        },
      }),
    );
  };

  const lock = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          if (!storage) return;
          await dispatch(
            lockDevice({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will lock shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to lock this device?",
      "Lock device",
    );
  };

  const unlock = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          if (!storage) return;
          await dispatch(
            unlockDevice({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will unlock shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to unlock this device?",
      "Unlock device",
    );
  };

  const reboot = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          if (!storage) return;
          await dispatch(
            rebootDevice({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will reboot shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to reboot this device?",
      "Reboot device",
    );
  };

  const powerOff = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          if (!storage) return;
          await dispatch(
            powerOffDevice({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will shutdown shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to shutdown this device?",
      "Shutdown device",
    );
  };

  const refreshStorageScreen = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          if (!storage) return;
          await dispatch(
            refreshDeviceScreen({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Screen was refreshed",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      `Are you sure you want to refresh the tool room screen?`,
      "Refresh screen",
    );
  };

  const sendRemoteControlCommand = (callback: () => void, description: string, actionText = "Confirm") => {
    return () => {
      dispatch(
        openModal(Modals.CONFIRMATION, {
          description: description,
          actionText: actionText,
          actionColor: "primary",
          onConfirm: callback,
        }),
      );
    };
  };

  const removeToolRoom = () => {
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description: "Are you sure you want to delete this tool room? This action is permanent.",
        actionText: "Delete tool room",
        onConfirm: async () => {
          try {
            await dispatch(deleteToolRoom(toolRoomId));
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Tool room deleted",
              }),
            );
            history("/apps/storage/tool_rooms");
          } catch (err) {
            handlePopupFlagError(err);
          }
        },
      }),
    );
  };

  const handleImageChange = async (imageUrl: string) => {
    if (!entity) return;
    await saveToolRoomChanges({
      ...entity,
      imageUrl,
    });
  };

  if (!entity || !values || !storage || !storageId) {
    return null;
  }

  return (
    <>
      <StageHeader title="Settings" />
      <DataGrid>
        <DataItem label="Image" value={<Avatar canEdit image={entity.imageUrl} onChange={handleImageChange} />} />
        <DataItem label="Storage code" value={<Text>{storage.id}</Text>} />
        <DataItem
          label="Name"
          value={
            <TextInput
              value={values.name}
              input={{ name: "name", placeholder: "Enter name" }}
              onChange={handleChange}
              onBlur={handleBlur("name")}
            />
          }
        />
        <DataItem
          label="Description"
          value={
            <TextAreaInput
              value={values.description}
              input={{
                name: "description",
                placeholder: "Enter description",
              }}
              onChange={handleChange}
              onBlur={handleBlur("description")}
            />
          }
        />
        <DataItem
          label="Location"
          value={
            <LocationSelector
              appearance="normal"
              name="locationId"
              value={storage.locationId}
              onChange={changeLocation}
            />
          }
        />
        {storage.sentryApiKey?.dateActivated && (
          <DataItem
            label="Remote control"
            value={
              storage.online ? (
                <DataItemGroup>
                  <VSpace gap="gap-2">
                    <FieldGroup label="Drawers">
                      <SecondaryButton fitContent icon="/images/icons/lock_5.svg" onClick={lock()}>
                        Lock door
                      </SecondaryButton>
                      <SecondaryButton fitContent icon="/images/icons/unlock_2.svg" onClick={unlock()}>
                        Unlock door
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Power options">
                      <SecondaryButton fitContent icon="/images/icons/restart_1.svg" onClick={reboot()}>
                        Reboot
                      </SecondaryButton>
                      <SecondaryButton fitContent icon="/images/icons/power_1.svg" onClick={powerOff()}>
                        Power off
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Miscellaneous">
                      <SecondaryButton fitContent icon="/images/icons/loading.svg" onClick={refreshStorageScreen()}>
                        Refresh screen
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Unlink tool room">
                      <WarningButton fitContent icon="/images/icons/unlink_1.svg" onClick={handleUnlinkStorage}>
                        Unlink tool room
                      </WarningButton>
                    </FieldGroup>
                  </VSpace>
                </DataItemGroup>
              ) : (
                <DataItemGroup>
                  <FieldGroup label="Commands">
                    <Paragraph muted>The tool room must be online to access remote control commands.</Paragraph>
                  </FieldGroup>
                  <FieldGroup label="Unlink tool room">
                    <WarningButton fitContent icon="/images/icons/unlink_1.svg" onClick={handleUnlinkStorage}>
                      Unlink tool room
                    </WarningButton>
                  </FieldGroup>
                </DataItemGroup>
              )
            }
          />
        )}
        <DataItem
          label="Delete tool room"
          value={
            <DangerButton icon="/images/icons/trash.svg" onClick={removeToolRoom}>
              Delete tool room
            </DangerButton>
          }
        />
      </DataGrid>
    </>
  );
};
