//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  storages: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  storage: {
    fetching: false,
    error: null,
    data: null,
  },
  accessPolicy: {
    fetching: false,
    error: null,
    data: null,
  },
  accessAttempts: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  storageStatistics: {
    fetching: false,
    error: null,
    data: null,
  },
  grants: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  status: {
    fetching: false,
    error: null,
    data: null,
  },
  session: {
    fetching: false,
    error: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.REQUEST_STORAGES:
      return {
        ...state,
        storages: {
          ...state.storages,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.REQUEST_STORAGES_FAILURE:
      return {
        ...state,
        storages: {
          ...state.storages,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.RECEIVE_STORAGES:
      return {
        ...state,
        storages: {
          ...state.storages,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.REQUEST_STORAGE:
      return {
        ...state,
        storage: {
          ...state.storage,
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.REQUEST_STORAGE_FAILURE:
      return {
        ...state,
        storage: {
          ...state.storage,
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.RECEIVE_STORAGE:
      return {
        ...state,
        storage: {
          ...state.storage,
          fetching: false,
          error: null,
          data: action.payload,
        },
      };

    case type.REQUEST_STORAGE_STATISTICS:
      return {
        ...state,
        storageStatistics: {
          fetching: true,
          error: null,
          data: state.storageStatistics.data,
        },
      };
    case type.RECEIVE_STORAGE_STATISTICS:
      return {
        ...state,
        storageStatistics: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.REQUEST_STORAGE_STATISTICS_FAILURE:
      return {
        ...state,
        storageStatistics: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };

    case type.GET_STORAGE_SESSION_REQUEST:
      return {
        ...state,
        session: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_STORAGE_SESSION_RECEIVE:
      return {
        ...state,
        session: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_STORAGE_SESSION_FAILURE:
      return {
        ...state,
        session: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };

    case type.GET_ACCESS_POLICY_REQUEST:
      return {
        ...state,
        accessPolicy: {
          ...state.accessPolicy,
          fetching: true,
          error: null,
        },
      };
    case type.GET_ACCESS_POLICY_RECEIVE:
      return {
        ...state,
        accessPolicy: {
          ...state.accessPolicy,
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_ACCESS_POLICY_FAILURE:
      return {
        ...state,
        accessPolicy: {
          ...state.accessPolicy,
          fetching: false,
          error: action.payload,
          data: null,
        },
      };

    case type.GET_ACCESS_POLICY_ATTEMPTS_REQUEST:
      return {
        ...state,
        accessAttempts: {
          ...state.accessAttempts,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_ACCESS_POLICY_ATTEMPTS_RECEIVE:
      return {
        ...state,
        accessAttempts: {
          ...state.accessAttempts,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_ACCESS_POLICY_ATTEMPTS_FAILURE:
      return {
        ...state,
        accessAttempts: {
          ...state.accessAttempts,
          fetching: false,
          error: action.payload,
          data: [],
          s,
        },
      };

    case type.GET_STORAGE_STATUS_RECEIVE:
      return {
        ...state,
        status: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
