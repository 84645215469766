//@ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { CREATE_STORE_ITEM_REQUEST } from "lib/actions/actionTypes";
import { createStoreItem } from "lib/actions/marketplace";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getStoreItemFetching } from "lib/reducers/storeItems.selectors";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { NumberInput, TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import style from "./RootModal/modal.module.scss";

const CreateStoreItemModal = ({ modal, onClose }) => {
  const dispatch = useDispatch();
  const submitting = useSelector(getStoreItemFetching(CREATE_STORE_ITEM_REQUEST));
  const { onSuccess } = modal.props;
  const [values, setValues] = useState({
    name: "",
    summary: "",
    description: "",
    sku: "",
    priceExcVat: 0,
    discount: 0,
    quantity: 0,
    shippable: true,
    active: true,
    images: [],
    documents: [],
    metadata: [],
  });

  const handleChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    dispatch(
      createStoreItem(
        values,
        (storeItem) => {
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Created Store Item",
            }),
          );
          onClose();
          onSuccess && onSuccess(storeItem);
        },
        (err) => {
          handlePopupFlagError(err);
        },
      ),
    );
  };

  const valid = values.name && values.sku;

  return (
    <Modal className={style.genericModal}>
      <ModalHeader title="Create a store item" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter name",
                autoFocus: true,
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Summary">
            <TextInput
              input={{
                name: "summary",
                placeholder: "Enter summary",
              }}
              value={values.summary}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Description">
            <TextInput
              input={{
                name: "description",
                placeholder: "Enter description",
              }}
              value={values.description}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="SKU">
            <TextInput input={{ name: "sku", placeholder: "Enter SKU" }} value={values.sku} onChange={handleChange} />
          </FieldGroup>
          <FieldGroup label="Price (Excl VAT)">
            <NumberInput
              input={{
                name: "priceExcVat",
                min: 0,
                step: 0.01,
                placeholder: "Enter price excl VAT",
              }}
              value={values.priceExcVat}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Discount (0 to 100%)">
            <NumberInput
              input={{
                name: "discount",
                min: 0,
                max: 100,
                step: 0.01,
                placeholder: "Enter discount",
              }}
              value={values.discount}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Stock quantity">
            <NumberInput
              input={{
                name: "quantity",
                min: 0,
                step: 1,
                placeholder: "Enter stock quantity",
              }}
              value={values.quantity}
              onChange={handleChange}
            />
          </FieldGroup>
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton disabled={!valid || submitting} onClick={handleSubmit}>
          Create store item
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

CreateStoreItemModal.defaultProps = {};

CreateStoreItemModal.propTypes = {
  className: PropTypes.string,
};

export default CreateStoreItemModal;
