import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { streamCreateAssetIssue, streamUpdateAssetIssue } from "store/reducers/assetIssues/assetIssues.actions";
import {
  createAssetIssue,
  deleteAssetIssue,
  fetchAssetIssueById,
  fetchAssetIssues,
  updateAssetIssue,
} from "store/reducers/assetIssues/assetIssues.thunks";
import { Pagination, TAssetIssue, UUID } from "types";

export type AssetIssuesReducerStateData = Record<UUID, TAssetIssue>;

export interface AssetIssuesReducerState {
  loading: string;
  page: UUID[];
  paging: Pagination;
  data: AssetIssuesReducerStateData;
}

const initialState: AssetIssuesReducerState = {
  loading: "",
  page: [],
  paging: {
    pageStart: 0,
    pageLimit: 20,
    totalCount: 0,
    nextPageStart: 0,
    previousPageStart: 0,
    pageCount: 0,
  },
  data: {},
};

export const assetIssuesSlice = createSlice({
  name: "assetIssues",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAssetIssues.pending, (state, action) => {
        state.page = [];
      })
      .addCase(fetchAssetIssues.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: AssetIssuesReducerStateData, value: TAssetIssue) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
        state.page = action.payload.results.map(({ id }) => id);
        state.paging = action.payload.paging;
      })
      .addCase(fetchAssetIssueById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createAssetIssue.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateAssetIssue.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(streamCreateAssetIssue, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(streamUpdateAssetIssue, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteAssetIssue.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addMatcher(isPending, (state, action) => {
        state.loading = action.type;
      })
      .addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
        state.loading = "";
      });
  },
});

export const assetIssuesReducer = assetIssuesSlice.reducer;
