import {
  ArchiveIcon,
  ClipboardIcon,
  CogIcon,
  HomeIcon,
  LogoutIcon,
  QrcodeIcon,
  ShoppingCartIcon,
  TruckIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { RoutePath } from "routes/RoutePath";
import Apps from "lib/constants/Apps";
import { FixLater } from "types";

export type MenuItem = {
  name: string;
  to?: string;
  icon: FixLater;
  onClick?: () => void;
  app?: string;
  children?: MenuItem[];
};

export const applicationMenu: MenuItem[] = [
  {
    name: "Dashboard",
    to: RoutePath.dashboard.root,
    icon: HomeIcon,
  },
  {
    name: "Tool storage",
    to: "/apps/storage",
    icon: ArchiveIcon,
  },
  {
    name: "Tool tracking",
    to: "/apps/tracking",
    icon: QrcodeIcon,
  },
  {
    name: "Tool store",
    to: RoutePath.toolStores.root,
    icon: ShoppingCartIcon,
    app: Apps.TOOL_STORES,
  },
  {
    name: "Vehicles",
    to: "/apps/vehicles/map",
    icon: TruckIcon,
  },
  {
    name: "Projects",
    to: "/apps/projects",
    icon: ClipboardIcon,
  },
  {
    name: "Configuration",
    to: "/configuration",
    icon: CogIcon,
  },
  {
    name: "Account settings",
    to: "/account/settings",
    icon: UserIcon,
  },
  {
    name: "Logout",
    to: RoutePath.logout,
    icon: LogoutIcon,
  },
];
