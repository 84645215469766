import { BiQrScan } from "react-icons/bi";
import { useParams } from "react-router-dom";
import useSetSidebar from "effects/useSidebar";
import Apps from "lib/constants/Apps";
import Capabilities from "lib/constants/Capabilities";
import { useSelector } from "store";
import { selectStorageByID, selectToolRoomByID } from "store/reducers";
import { Restrict, RestrictByApp } from "components/Restricted";
import { Header } from "components/Typography";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import Sidebar, { MenuSeparator } from "components/sidebars/Sidebar";
import { SidebarImage } from "components/sidebars/SidebarImage/SidebarImage";
import style from "./sidebar.module.scss";

export const ToolRoomSidebar = () => {
  useSetSidebar(true);
  const { toolRoomID } = useParams();
  const url = `/apps/storage/tool_rooms/${toolRoomID}`;
  const entity = useSelector(selectToolRoomByID(toolRoomID || ""));
  const storage = useSelector(selectStorageByID(entity?.storageId || ""));
  return (
    <Sidebar>
      {entity && (
        <div className={style.sidebarImageHeader}>
          {entity.imageUrl && <SidebarImage imageUrl={entity.imageUrl} />}
          <Header>{storage?.name}</Header>
        </div>
      )}
      <MenuSeparator />
      <RestrictByApp app={Apps.TOOL_TRACKING}>
        <MenuItem to={`${url}/inventory`} icon="/images/icons/tools_1.svg">
          Inventory
        </MenuItem>
      </RestrictByApp>
      <RestrictByApp app={Apps.TOOL_CASE_TRACKING}>
        <MenuItem to={`${url}/case_scanners`} icon={<BiQrScan />}>
          Case scanners
        </MenuItem>
      </RestrictByApp>
      <Restrict capability={Capabilities.STORAGE_UPDATE}>
        <MenuItem to={`${url}/access_control`} icon="/images/icons/identity.svg">
          Manage access
        </MenuItem>
      </Restrict>
      <MenuItem to={`${url}/activity`} icon="/images/icons/update_2.svg">
        Access history
      </MenuItem>
      <Restrict capability={Capabilities.STORAGE_UPDATE}>
        <MenuSeparator />
        <MenuItem to={`${url}/alerts`} icon="/images/icons/alert_2.svg">
          Alerts
        </MenuItem>
        <MenuItem to={`${url}/settings`} icon="/images/icons/config_2.svg">
          Settings
        </MenuItem>
      </Restrict>
    </Sidebar>
  );
};
