//@ts-nocheck
import Account from "../Account";
import Api from "./api";

class Reports extends Api {
  /**
   * Get reports
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, paging: Object}>} Response
   */
  static async getReports(queryParams = {}) {
    const response = await this.get("/reports").query(queryParams);
    return response.body;
  }

  /**
   * Get report components
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, paging: Object}>} Response
   */
  static async getReportComponents(queryParams = {}) {
    const response = await this.get("/report_components").query(queryParams);
    return response.body;
  }

  /**
   * Get report exports
   * @async
   * @param {string} reportID
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, metadata: Object}>} Response
   */
  static async getReportExports(reportID, queryParams = {}) {
    const response = await this.get(`/reports/${reportID}/exports`).query(queryParams);
    return response.body;
  }

  /**
   * Get report export
   * @async
   * @param {string} reportID
   * @param {string} exportID
   * @returns {Promise.<{data: Array, metadata: Object}>} Response
   */
  static async getReportExport(reportID, exportID) {
    const response = await this.get(`/reports/${reportID}/exports/${exportID}`).send();
    return response.body.data;
  }

  /**
   * Create report export
   * @async
   * @param {string} reportID
   * @returns {Promise.<{data: Array, metadata: Object}>} Response
   */
  static async createReportExport(reportID) {
    const response = await this.post(`/reports/${reportID}/exports`).send();
    return response.body.data;
  }

  /**
   * Remove report export
   * @async
   * @param {string} reportID
   * @param {string} exportID
   * @returns {Promise.<{data: Array, metadata: Object}>} Response
   */
  static async deleteReportExport(reportID, exportID) {
    return await this.delete(`/reports/${reportID}/exports/${exportID}`).send();
  }

  /**
   * Get report templates
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, paging: Object}>} Response
   * @deprecated
   */
  static async getTemplates(queryParams = {}) {
    const response = await this.get("/templates").query(queryParams);
    return response.body;
  }

  /**
   * Get report
   * @async
   * @param {string} reportId
   * @returns {Promise.<Object>}
   */
  static async getReport(reportId) {
    const response = await this.get(`/reports/${reportId}`).send();
    return response.body.data;
  }

  /**
   * Get report evidence if one exists
   * @async
   * @param {string} reportId
   * @returns {Promise.<Object>}
   */
  static async getReportEvidence(reportId) {
    const response = await this.get(`/reports/${reportId}/evidence`).send();
    return response.body.data;
  }

  /**
   * Get report template
   * @async
   * @param {number} templateId
   * @returns {Promise.<Object>}
   * @deprecated
   */
  static async getTemplate(templateId) {
    const response = await this.get(`/templates/${templateId}`).send();
    return response.body.data;
  }

  /**
   * Create template
   * @async
   * @param {Object} template
   * @returns {Promise} Response
   * @deprecated
   */
  static async createTemplate(template) {
    const response = await this.post("/templates").send(template);
    return response.body.data;
  }

  /**
   * Create report
   * @async
   * @param {Object} report
   * @returns {Promise} Response
   */
  static async createReport(report) {
    const response = await this.post("/reports").send(report);
    return response.body.data;
  }

  /**
   * Update a report
   * @async
   * @param {string} reportID
   * @param {Object} report
   * @returns {Promise} Response
   */
  static async updateReport(reportID, report) {
    const response = await this.put(`/reports/${reportID}`).send(report);
    return response.body.data;
  }

  /**
   * Update template
   * @async
   * @param {number} templateId
   * @param {Object} template
   * @returns {Promise} Response
   */
  static async updateTemplate(templateId, template) {
    return await this.put(`/templates/${templateId}`).send(template);
  }

  /**
   * Delete template
   * @async
   * @param {number} templateId
   * @returns {Promise} Response
   * @deprecated
   */
  static async deleteTemplate(templateId) {
    return await this.delete(`/templates/${templateId}`).send();
  }

  /**
   * Delete report
   * @async
   * @param {string} reportID
   * @returns {Promise} Response
   */
  static async deleteReport(reportID) {
    return await this.delete(`/reports/${reportID}`).send();
  }

  /**
   * Get report result
   * @async
   * @param {string} reportId
   * @returns {Promise.<Object>}
   */
  static async getResult(reportId) {
    const response = await this.get(`/reports/${reportId}/result`);
    return response.body.data;
  }

  /**
   * Download report result
   * @param {string} reportId
   * @param {string} filename
   */
  static async downloadResult(reportId, filename) {
    if (!filename) {
      throw new Error("expected non-empty result filename");
    }
    window.open(
      `${
        Api.getBaseUrl
      }/reports/${reportId}/result?download=true&authorization=${Account.token()}&filename=${filename}`,
      "_blank",
    );
  }
}

export default Reports;
