//@ts-nocheck
import * as type from "./actionTypes";
import { receivePagedResult, requestFailure } from "./common";

export function requestReaders(options = {}) {
  return {
    type: type.REQUEST_READERS,
    payload: options,
  };
}

export function receiveReaders(readers, paging) {
  return receivePagedResult(type.RECEIVE_READERS, readers, paging);
}

export function requestReadersFailure(error) {
  return requestFailure(type.REQUEST_READERS_FAILURE, error);
}

export function requestReader(macAddress) {
  return {
    type: type.REQUEST_READER,
    payload: macAddress,
  };
}

export function receiveReader(reader) {
  return {
    type: type.RECEIVE_READER,
    payload: reader,
  };
}

export function requestReaderFailure(error) {
  return requestFailure(type.REQUEST_READER_FAILURE, error);
}
