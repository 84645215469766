import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { fetchCatalogueProducts } from "store/reducers";
import { TCatalogueProduct, UUID } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

type CatalogueProductSelectorProps = {
  name: string;
  value?: TCatalogueProduct | UUID;
  onChange: (name: string, value: TCatalogueProduct) => void;
  manufacturerId?: number;
  placeholder?: string;
  appearance?: "inline" | "normal";
  menuPlacement?: string;
  pageLimit?: number;
  className?: string;
};

export const CatalogueProductSelector = ({
  name,
  value,
  onChange,
  manufacturerId,
  placeholder = "Select a product",
  appearance = "normal",
  className,
  menuPlacement,
  pageLimit = 25,
}: CatalogueProductSelectorProps) => {
  const dispatch = useDispatch();

  const formatOption = (entity: TCatalogueProduct) => ({
    ...entity,
    label: entity.name,
  });
  const fetch = async (inputValue?: string) => {
    try {
      const { results } = await dispatch(
        fetchCatalogueProducts({
          pageLimit,
          manufacturer_id: manufacturerId,
          search_term: inputValue,
        }),
      ).unwrap();
      return results.map((entity) => formatOption(entity));
    } catch (err) {
      handlePopupFlagError(err);
      return [];
    }
  };

  const input = {
    type: "select",
    name,
    placeholder,
    labelKey: "label",
    valueKey: "id",
    menuPlacement,
    isSearchable: true,
    async: true,
    loadOptions: fetch,
    defaultOptions: true,
  };

  return appearance === "inline" ? (
    <Text
      canEdit
      input={input}
      value={value as any}
      onConfirm={(value) => onChange(name, value)}
      className={className}
    />
  ) : (
    <SelectInput input={input} value={value} onChange={onChange} className={className} />
  );
};
