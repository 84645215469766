//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import style from "./timeline.module.scss";

const Timeline = ({ events, className }) => {
  return (
    <div>
      {events.map((event) => (
        <Event key={event.name} event={event} />
      ))}
    </div>
  );
};

Timeline.defaultProps = {};

Timeline.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.any,
    }),
  ),
  className: PropTypes.string,
};

const Event = ({ event }) => {
  return (
    <div className={style.event}>
      <EventIcon className={style.icon} />
      <div className={style.eventContent}>
        <span className={style.name}>{event.name}</span>
        {event.description ? <span className={style.description}>{event.description}</span> : null}
      </div>
    </div>
  );
};

const EventIcon = ({ className }) => (
  <div>
    <Icon src="/images/icons/tick.svg" size="small" color="success" className={className} />
  </div>
);

export default Timeline;
