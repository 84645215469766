//@ts-nocheck
import { put, takeLatest, takeEvery } from "redux-saga/effects";
import Logger from "../Logger";
import * as type from "../actions/actionTypes";
import * as actions from "../actions/projects";
import Api from "../api/projects";

function* fetchProject(action) {
  try {
    const project = yield Api.getProject(action.payload);
    yield put(actions.requestProjectSuccess(project));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestProjectFailure(err));
  }
}

function* fetchProjects(action) {
  try {
    const { data, metadata } = yield Api.getProjects(action.payload);
    yield put(actions.fulfilRequestProjects(data, metadata));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectRequestProjects(err));
    action.onFailed && action.onFailed(err);
  }
}

function* updateProject(action) {
  try {
    const data = yield Api.updateProject(action.payload.id, action.payload);
    yield put(actions.fulfilUpdateProject(data));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectUpdateProject(err));
    action.onFailed && action.onFailed(err);
  }
}

function* fetchProjectBlocks(action) {
  try {
    const { data, metadata } = yield Api.getProjectBlocks(action.payload.projectID, action.payload.options);
    yield put(actions.requestProjectBlocksSuccess(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestProjectBlocksFailure(err));
  }
}

function* projectsSaga() {
  yield takeLatest(type.GET_PROJECT_REQUEST, fetchProject);
  yield takeLatest(type.GET_PROJECTS_REQUEST, fetchProjects);
  yield takeEvery(type.UPDATE_PROJECT_REQUEST, updateProject);
  yield takeLatest(type.GET_PROJECT_BLOCKS_REQUEST, fetchProjectBlocks);
}

export default projectsSaga;
