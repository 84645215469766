import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  createAlertRecipient,
  deleteAlertRecipient,
  selectAlertRecipientsByAlertID,
  selectAlertRecipientsHighestOrderByAlertID,
  updateAlertRecipient,
} from "store/reducers";
import { TAlertRecipient, UUID } from "types";
import { Text } from "components/Typography";
import { DataList, DataListItem } from "components/lists/DataList";

type AlertRecipientProps = {
  alertId: UUID;
  name: "emailAddress" | "telephone";
  placeholder: string;
};

export const AlertRecipient = ({ alertId, name, placeholder }: AlertRecipientProps) => {
  const dispatch = useDispatch();
  const entities = useSelector(selectAlertRecipientsByAlertID(alertId)).filter((entity) => Boolean(entity[name]));
  const highestOrder = useSelector(selectAlertRecipientsHighestOrderByAlertID(alertId));

  const handleCreate = async (value: string) => {
    try {
      await dispatch(
        createAlertRecipient({
          alertId,
          [name]: value,
          order: highestOrder + 1,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Alert recipient added",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleRemove = (recipient: TAlertRecipient) => {
    return async () => {
      try {
        await dispatch(
          deleteAlertRecipient({
            id: recipient.id,
            alertId,
          }),
        ).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Alert recipient removed",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const handleConfirm = (recipient: TAlertRecipient) => {
    return async (value: string) => {
      try {
        await dispatch(
          updateAlertRecipient({
            ...recipient,
            [name]: value,
          }),
        ).unwrap();
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  return (
    <DataList>
      {entities.map((recipient: TAlertRecipient) => {
        return (
          <DataListItem key={recipient.id} onRemove={handleRemove(recipient)}>
            <Text
              canEdit={true}
              value={recipient[name]}
              placeholder={`Enter ${name === "emailAddress" ? "email address" : "phone number"}`}
              onConfirm={handleConfirm(recipient)}
            />
          </DataListItem>
        );
      })}
      <DataListItem>
        <Text canEdit clearOnConfirm value="" placeholder={placeholder} onConfirm={handleCreate} />
      </DataListItem>
    </DataList>
  );
};
