import { PayloadAction, createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { streamCreateVehicle, streamUpdateVehicle } from "store/reducers/vehicles/vehicles.actions";
import {
  createVehicle,
  deleteVehicle,
  fetchVehicleById,
  fetchVehicles,
  updateVehicle,
} from "store/reducers/vehicles/vehicles.thunks";
import { Pagination, TVehicle, UUID, VehiclesQueryFilters } from "types";

export type VehiclesReducerStateData = Record<UUID, TVehicle>;

export interface VehiclesReducerState {
  loading: string;
  filters: VehiclesQueryFilters;
  page: UUID[];
  paging: Pagination;
  data: VehiclesReducerStateData;
}

const initialState: VehiclesReducerState = {
  loading: "",
  filters: {
    statuses: [],
    types: [],
  },
  paging: {
    pageStart: 0,
    pageLimit: 20,
    totalCount: 0,
    nextPageStart: 0,
    previousPageStart: 0,
    pageCount: 0,
  },
  page: [],
  data: {},
};

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setVehiclesFilter: (state, action: PayloadAction<VehiclesQueryFilters>) => {
      state.filters = action.payload;
    },
    setVehiclesFilterLocationId: (state, action: PayloadAction<UUID | undefined>) => {
      state.filters.locationId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVehicles.pending, (state, action) => {
        state.page = [];
      })
      .addCase(fetchVehicles.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: VehiclesReducerStateData, value: TVehicle) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
        state.page = action.payload.results.map(({ id }) => id);
        state.paging = action.payload.paging;
      })
      .addCase(fetchVehicleById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createVehicle.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateVehicle.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(streamCreateVehicle, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(streamUpdateVehicle, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteVehicle.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addMatcher(isPending, (state, action) => {
        state.loading = action.type;
      })
      .addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
        state.loading = "";
      });
  },
});

export const { setVehiclesFilter, setVehiclesFilterLocationId } = vehiclesSlice.actions;
export const vehiclesReducer = vehiclesSlice.reducer;
