import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import dismissPopupFlag from "lib/middleware/dismissPopupFlag";
import lookupStorage from "lib/middleware/lookupStorage";
import sagas from "lib/sagas";
import reducer from "store/reducer";

const sagaMiddleware = createSagaMiddleware();

const createStore = (initialState = {}) => {
  const store = configureStore({
    reducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat(sagaMiddleware, lookupStorage, dismissPopupFlag),
    devTools: process.env.NODE_ENV !== "production",
  });
  sagaMiddleware.run(sagas);
  return store;
};

export default createStore;
