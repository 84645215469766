import { useEffect, useRef, useState } from "react";
import { useModal } from "effects";
import QrScanner from "qr-scanner";
import Loading from "components/Loading";
import { Modal } from "components/modals";

type QRCodeScannerModalProps = {
  onSuccess?: (data: string) => void;
};

export const QRCodeScannerModal = ({ onSuccess }: QRCodeScannerModalProps) => {
  const { closeModal } = useModal();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let qrScanner: QrScanner;
    const start = async () => {
      const cameras = await QrScanner.listCameras(true);
      if (videoRef.current && cameras.length) {
        qrScanner = new QrScanner(videoRef.current, (result) => {
          qrScanner.stop();
          closeModal();
          if (onSuccess) {
            onSuccess(result);
          }
        });
        await qrScanner.start();
        setLoading(false);
      }
    };
    start();

    return () => {
      if (qrScanner) {
        qrScanner.destroy();
      }
    };
  }, [videoRef]);

  return (
    <Modal>
      {loading && (
        <div className="h-40 flex items-center justify-center">
          <Loading />
        </div>
      )}
      <video ref={videoRef} className={loading ? "absolute" : ""} />
    </Modal>
  );
};
