import { VSpace } from "components/shared";
import { MarketingSection, InfoText, MarketingTitle } from "pages/marketing/components/Common";
import style from "pages/marketing/marketing.module.scss";

const MarketingHero = () => {
  return (
    <MarketingSection className={style.heroSection}>
      <div className={style.centerAlign}>
        <VSpace>
          <MarketingTitle className={style.heroTitle}>
            Complete tool control solution for responsible businesses
          </MarketingTitle>
          <InfoText concise spaceBottom>
            Lead the way with the Automify access control, tool tracking and business operations software.
          </InfoText>
        </VSpace>
        <div className={style.heroProductDemo}>
          <img src="/images/demo/storage_hero.png" alt="Automify roll cab" className={style.productImage} />
        </div>
      </div>
    </MarketingSection>
  );
};

export default MarketingHero;
