import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { fetchCalibrationCertificates } from "store/reducers/calibrationCertificates/calibrationCertificates.thunks";
import { TCalibrationCertificate, UUID } from "types";

const selectState = (state: RootStore) => state.calibrationCertificates;

export const selectCalibrationCertificates = createSelector(selectState, (state) => state.data);

export const selectCalibrationCertificatesLoading = createSelector(
  selectState,
  ({ loading }) => loading === fetchCalibrationCertificates.pending.type,
);

export const selectCalibrationCertificatesList = createSelector(selectCalibrationCertificates, (data) =>
  Object.values(data),
);

export const selectCalibrationCertificatesPage = createSelector(selectState, ({ page, data }) => {
  return page.map((id: UUID) => data[id]).filter(Boolean);
});

export const selectCalibrationCertificateByID = (id: UUID) =>
  createSelector(selectCalibrationCertificates, (data): TCalibrationCertificate | undefined => data[id]);

export const selectCalibrationCertificateByAssetID = (assetId: UUID) =>
  createSelector(selectCalibrationCertificatesList, (list): TCalibrationCertificate[] =>
    list.filter((item) => item.assetId === assetId),
  );
