import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { ToolStorageProvider } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { useDispatch, useSelector } from "store";
import {
  createStorageViewer,
  fetchStorageById,
  fetchToolRoomById,
  selectStorageByID,
  selectToolRoomByID,
} from "store/reducers";
import { PageLoading } from "components/shared";
import PageStage from "components/stages/PageStage";
import { StorageAlertsPage, StorageAlertPage } from "pages/features/storageAlerts";
import { ToolRoomCaseScannersPage } from "pages/features/toolRooms/ToolRoomCaseScannersPage/ToolRoomCaseScannersPage";
import { ToolRoomInventoryPage } from "pages/features/toolRooms/ToolRoomInventoryPage/ToolRoomInventoryPage";
import { ToolRoomSettingsPage } from "pages/features/toolRooms/ToolRoomSettingsPage/ToolRoomSettingsPage";
import { StorageAccessControlPage } from "pages/storages";
import StorageActivity from "pages/storages/ListStorage/components/StorageAccessHistory";

export const ToolRoomPage = () => {
  const dispatch = useDispatch();
  const { pathname: url } = useLocation();
  const { toolRoomID } = useParams();
  const entity = useSelector(selectToolRoomByID(toolRoomID || ""));
  const storageID = entity?.storageId;
  const storage = useSelector(selectStorageByID(storageID || ""));

  // Fetch the tool room if it is not in memory yet
  useEffect(() => {
    if (!toolRoomID) return;
    if (entity) return;
    dispatch(fetchToolRoomById(toolRoomID));
  }, [toolRoomID, entity]);

  useEffect(() => {
    if (!storageID) return;
    if (storage) return;
    dispatch(fetchStorageById(storageID));
  }, [storageID, storage]);

  useEffect(() => {
    if (!storageID) return;
    dispatch(createStorageViewer(storageID));
  }, [storageID]);

  if (!entity || !storage || !storageID || !toolRoomID) {
    return <PageLoading />;
  }

  return (
    <ToolStorageProvider storageId={storageID}>
      <PageStage>
        <Routes>
          <Route path="inventory" element={<ToolRoomInventoryPage />} />
          <Route path="access_control" element={<StorageAccessControlPage />} />
          <Route path="activity" element={<StorageActivity storage={storage} />} />
          <Route path="settings" element={<ToolRoomSettingsPage toolRoomId={toolRoomID} />} />
          <Route path="case_scanners" element={<ToolRoomCaseScannersPage toolRoomId={toolRoomID} />} />
          <Route path="alerts" element={<StorageAlertsPage />} />
          <Route path="alerts/:storageAlertID" element={<StorageAlertPage />} />
          <Route path="" element={<Navigate replace to={`${url}/inventory`} />} />
        </Routes>
      </PageStage>
    </ToolStorageProvider>
  );
};
