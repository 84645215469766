import { useEffect, useState } from "react";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchLocations, selectLocations } from "store/reducers";
import { LocationType, TLocation, UUID } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

type LocationSelectorProps = {
  name: string;
  value?: TLocation | UUID;
  onChange: (name: string, value: TLocation) => void;
  placeholder?: string;
  appearance?: "inline" | "normal";
  searchable?: boolean;
  menuPlacement?: string;
  types?: LocationType[];
  className?: string;
  canEdit?: boolean;
};

export const LocationSelector = ({
  name,
  value,
  onChange,
  placeholder = "Select location",
  appearance = "normal",
  types,
  className,
  menuPlacement,
  searchable = true,
  canEdit,
}: LocationSelectorProps): JSX.Element => {
  const dispatch = useDispatch();
  const locations = useSelector(selectLocations);
  const [ids, setIds] = useState<UUID[]>([]);
  const input = {
    type: "select",
    name,
    placeholder,
    labelKey: "name",
    valueKey: "id",
    menuPlacement,
    options: ids.map((id) => locations[id]),
    isSearchable: searchable,
    disabled: canEdit === false,
  };

  useEffect(() => {
    fetch();
  }, [types]);

  const fetch = async () => {
    try {
      const { results } = await dispatch(fetchLocations({ pageLimit: 5000, types })).unwrap();
      setIds(results.map(({ id }) => id));
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const resolveValue = () => {
    if (typeof value === "string") {
      return locations[value];
    }
    return value;
  };

  return appearance === "inline" ? (
    <Text
      canEdit
      input={input}
      value={resolveValue()}
      onConfirm={(value) => onChange(name, value)}
      className={className}
    />
  ) : (
    <SelectInput input={input} value={value} onChange={onChange} className={className} />
  );
};
