import { useParams } from "react-router-dom";
import useSetSidebar from "effects/useSidebar";
import Capabilities from "lib/constants/Capabilities";
import { useSelector } from "store";
import { selectStorageByID, selectDoorwayByID } from "store/reducers";
import { Restrict } from "components/Restricted";
import { Header } from "components/Typography";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import Sidebar, { MenuSeparator } from "components/sidebars/Sidebar";
import { SidebarImage } from "components/sidebars/SidebarImage/SidebarImage";
import style from "./sidebar.module.scss";

export const DoorwaySidebar = () => {
  useSetSidebar(true);
  const { doorwayID } = useParams();
  const url = `/apps/tracking/doorways/${doorwayID}`;
  const entity = useSelector(selectDoorwayByID(doorwayID || ""));
  const storage = useSelector(selectStorageByID(entity?.storageId || ""));
  console.log(storage, entity);
  return (
    <Sidebar>
      {entity && (
        <div className={style.sidebarImageHeader}>
          {entity.imageUrl && <SidebarImage imageUrl={entity.imageUrl} />}
          <Header>{storage?.name}</Header>
        </div>
      )}
      <MenuSeparator />
      <Restrict capability={Capabilities.STORAGE_UPDATE}>
        <MenuItem to={`${url}/alerts`} icon="/images/icons/alert_2.svg">
          Alerts
        </MenuItem>
        <MenuItem to={`${url}/settings`} icon="/images/icons/config_2.svg">
          Settings
        </MenuItem>
      </Restrict>
    </Sidebar>
  );
};
