//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FixLater } from "types";
import style from "./table.module.scss";

const TableHeadCell = ({ className, numerical, children, isEmpty, ...otherProps }: FixLater) => {
  const classes = classNames(
    style.tableCell,
    style.tableHeadCell,
    {
      [style.numericalCell]: numerical,
      [style.buttonRemoveColumn]: isEmpty,
    },
    className,
  );
  return (
    <th {...otherProps} className={classes}>
      {children}
    </th>
  );
};

TableHeadCell.defaultProps = {
  className: "",
  numerical: false,
  isEmpty: false,
};

TableHeadCell.propTypes = {
  className: PropTypes.string,
  numerical: PropTypes.bool.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default TableHeadCell;
