import classNames from "classnames";
import { TailwindBackgroundColor, TailwindWidth } from "types/tailwind";

type ProgressBarProps = {
  value: number; // between 0 and 1.
  width?: TailwindWidth;
  backgroundColor?: TailwindBackgroundColor;
  className?: string;
};

export const ProgressBar = ({
  value,
  width = "w-full",
  backgroundColor = "bg-primary-600",
  className,
}: ProgressBarProps) => {
  if (isNaN(value)) value = 0;
  if (value < 0) value = 0;
  if (value > 1) value = 1;
  return (
    <div className={classNames("rounded-full h-2.5 bg-gray-200", width, className)}>
      <div className={classNames("h-2.5 rounded-full", backgroundColor)} style={{ width: value * 100 + "%" }}></div>
    </div>
  );
};
