//@ts-nocheck
import React, { useMemo } from "react";
import TaskStatus from "lib/constants/TaskStatus";
import { getProjectTaskListIDsByProjectID } from "lib/reducers/projects.selectors";
import { getTaskLists } from "lib/reducers/taskLists.selectors";
import { useSelector } from "store";
import { ProgressInput } from "components/Input";
import { Text } from "components/Typography";
import styles from "./ProjectKanbanItemProgress.module.scss";

export const ProjectKanbanItemProgress = ({ projectID }) => {
  const taskListIDs = useSelector(getProjectTaskListIDsByProjectID(projectID));
  const taskLists = useSelector(getTaskLists);
  const percent = useMemo(() => {
    const list = taskListIDs
      .map((taskListID) => {
        return taskLists[taskListID];
      })
      .filter((taskList) => {
        if (!taskList) return false;
        if (!Array.isArray(taskList.tasks)) return false;
        return taskList.tasks.length;
      });
    let total = 0,
      complete = 0;
    for (const taskList of list) {
      total += taskList.tasks.length;
      for (const taskListTask of taskList.tasks) {
        if (taskListTask.task.status === TaskStatus.COMPLETED) {
          complete += 1;
        }
      }
    }
    return complete !== 0 ? ((complete / total) * 100).toFixed(0) : 0;
  }, [taskListIDs, taskLists]);

  return (
    <div className={styles.ProjectKanbanItemProgress}>
      <ProgressInput value={percent} className={styles.input} />
      <div className={styles.footer}>
        <Text muted>Progress</Text>
        <Text className={styles.percentage}>{percent}%</Text>
      </div>
    </div>
  );
};
