import Resolve from "lib/Resolve";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getCustomer } from "lib/reducers/lookupData.selectors";
import { useDispatch, useSelector } from "store";
import { selectUserByID, updateUser } from "store/reducers";
import { TUser } from "types";
import Avatar from "components/Avatar";
import { DataGrid, DataItem } from "components/DataGrid";
import Loading from "components/Loading";
import { Text } from "components/Typography";
import { SecondaryButton } from "components/shared/buttons";
import { TextBody } from "components/shared/typography";

type AccountProfileProps = {
  userId: number;
};

export const AccountProfile = ({ userId }: AccountProfileProps) => {
  const dispatch = useDispatch();
  const customer = useSelector(getCustomer);
  const user = useSelector(selectUserByID(userId));

  const saveChanges = async (data: TUser) => {
    try {
      await dispatch(updateUser(data)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleSubmit = async (key: keyof TUser, value: string | null) => {
    if (!user) return;
    await saveChanges({
      ...user,
      [key]: value,
    });
    dispatch(
      showPopupFlag({
        appearance: "success",
        title: "Profile updated",
      }),
    );
  };

  const removeFaceId = async () => {
    if (!user) return;
    await saveChanges({
      ...user,
      faceId: undefined,
      pinCode: undefined,
    });
    dispatch(
      showPopupFlag({
        appearance: "success",
        title: "Face ID removed",
      }),
    );
  };

  if (!user || !customer) {
    return <Loading />;
  }

  return (
    <DataGrid className="gap-4">
      <DataItem
        label="Profile image"
        value={<Avatar canEdit image={user.profileImage} onChange={(value) => handleSubmit("profileImage", value)} />}
      />
      <DataItem
        label="First name"
        value={<Text canEdit value={user.forename} onConfirm={(value) => handleSubmit("forename", value)} />}
      />
      <DataItem
        label="Last name"
        value={<Text canEdit value={user.surname} onConfirm={(value) => handleSubmit("surname", value)} />}
      />
      <DataItem
        label="Face ID"
        value={
          user.faceId ? (
            <SecondaryButton width="" onClick={removeFaceId}>
              Delete face ID
            </SecondaryButton>
          ) : (
            <TextBody textColor="text-slate-400">Not configured</TextBody>
          )
        }
      />
      {user.faceId && (
        <DataItem
          label="Pin code"
          value={
            user.pinCode ? (
              <Text value={user.pinCode} />
            ) : (
              <TextBody textColor="text-slate-400">Not configured</TextBody>
            )
          }
        />
      )}
      <DataItem label="Email" value={<Text value={user.email} />} />
      <DataItem label="Company" value={customer.name} />
      <DataItem label="Role" value={Resolve.resolveRoleName(user.roleId)} />
    </DataGrid>
  );
};
