import Lookup from "lib/constants/Lookup";
import { LocationType } from "types";

class LocationTypes extends Lookup<LocationType> {
  constructor() {
    super();
    this.items = [
      {
        value: "building",
        label: "Building",
        description: "",
      },
      {
        value: "room",
        label: "Room",
        description: "",
      },
      {
        value: "bay",
        label: "Bay",
        description: "",
      },
      {
        value: "shelf",
        label: "Shelf",
        description: "",
      },
    ];
  }
}

export default new LocationTypes();
