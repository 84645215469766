//@ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestStoreItem } from "lib/actions/marketplace";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import { ButtonGroup } from "components/Buttons";
import { Anchor } from "components/Typography";
import ButtonAddToCart from "pages/apps/Shop/shared/ButtonAddToCart/ButtonAddToCart";
import ShopItemPrice from "pages/apps/Shop/shared/ShopItemPrice/ShopItemPrice";
import ShopItemPrimaryImage from "pages/apps/Shop/shared/ShopItemPrimaryImage/ShopItemImage";
import styles from "./ShopItemCard.module.scss";

const ShopItemCard = ({ itemID }) => {
  const dispatch = useDispatch();
  const item = useSelector(getStoreItemByID(itemID));

  useEffect(() => {
    if (!item) {
      dispatch(requestStoreItem(itemID));
    }
  }, []);

  const itemMetadata = {
    "data-item-sku": item.sku,
    "data-item-name": item.name,
    "data-item-price-exc-vat": item.priceExcVat,
    "data-item-shippable": item.shippable,
    "data-item-summary": item.summary,
  };

  return (
    item && (
      <div {...itemMetadata} className={styles.ShopItemCard}>
        <div className={styles.ShopItemCardImage}>
          <Anchor to={`/apps/shop/items/${itemID}`}>
            <ShopItemPrimaryImage itemID={itemID} />
          </Anchor>
        </div>
        <Anchor to={`/apps/shop/items/${itemID}`} className={styles.ShopItemCardName}>
          {item.name}
        </Anchor>
        <div className={styles.ShopItemCardPrice}>
          <ShopItemPrice vatDisclaimer itemID={itemID} appearance="compact" />
        </div>
        <ButtonGroup>
          <ButtonAddToCart itemID={itemID} />
        </ButtonGroup>
      </div>
    )
  );
};

export default ShopItemCard;
