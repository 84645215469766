import { Route, Routes } from "react-router-dom";
import { RagBinSidebar, RagControllerSidebar } from "components/shared";
import { DoorwaySidebar, ToolRoomSidebar, ToolStoreSidebar } from "components/sidebars";
import AssetSidebar from "components/sidebars/AssetSidebar";
import { CaseScannerSidebar } from "components/sidebars/CaseScannerSidebar";
import ConfigurationSidebar from "components/sidebars/ConfigurationSidebar";
import { MainSidebar } from "components/sidebars/MainSidebar";
import ProjectSidebar from "components/sidebars/ProjectSidebar";
import ProjectsSidebar from "components/sidebars/ProjectsSidebar";
import ReportAppSidebar from "components/sidebars/ReportAppSidebar";
import { StorageAppSidebar } from "components/sidebars/StorageAppSidebar";
import ToolboxAppSidebar from "components/sidebars/ToolboxAppSidebar";
import { TrackingAppSidebar } from "components/sidebars/TrackingAppSidebar";
import style from "./sidebar.module.scss";

export const RootSidebar = () => {
  return (
    <div className={style.sidebars}>
      <MainSidebar />
      <Routes>
        <Route path="/apps/tracking/assets/:assetID*" element={<AssetSidebar />} />
        <Route path="/apps/storage/toolboxes/:storageID*" element={<ToolboxAppSidebar />} />
        <Route path="/apps/storage/stores/:toolStoreID*" element={<ToolStoreSidebar />} />
        <Route path="/apps/storage/tool_rooms/:toolRoomID*" element={<ToolRoomSidebar />} />
        <Route path="/apps/storage/case_scanners/:caseScannerID*" element={<CaseScannerSidebar />} />
        <Route path="/apps/storage/rag_controllers/:ragControllerID/bins/:ragBinID*" element={<RagBinSidebar />} />
        <Route path="/apps/storage/rag_controllers/:ragControllerID*" element={<RagControllerSidebar />} />
        <Route path="/apps/storage*" element={<StorageAppSidebar />} />
        <Route path="/apps/reporting/reports/:reportID*" element={<ReportAppSidebar />} />
        <Route path="/configuration*" element={<ConfigurationSidebar />} />
        <Route path="/apps/projects*" element={<ProjectsSidebar />} />
        <Route path="/apps/tracking/doorways/:doorwayID*" element={<DoorwaySidebar />} />
        <Route path="/apps/tracking*" element={<TrackingAppSidebar />} />
        {false && <Route path="/apps/projects/:projectID*" element={<ProjectSidebar />} />}
      </Routes>
    </div>
  );
};

export default RootSidebar;
