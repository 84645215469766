//@ts-nocheck
import React, { useState } from "react";
import { ChromePicker } from "react-color";
import classNames from "classnames";
import PropTypes from "prop-types";
import Overlay from "../Overlay";
import style from "./colorpicker.module.scss";

const defaultUnsetColor = "#cecece";

const ColorPicker = ({ name, value, className, onClick, onChange }) => {
  const [color, setColor] = useState(value || "#000");
  const [visible, setVisible] = useState(false);
  const handleChangeComplete = (color) => onChange(name, color.hex);
  const handleChange = (color) => setColor(color.hex);

  return (
    <div className={style.container} onClick={onClick}>
      <div
        className={style.preview}
        style={{ backgroundColor: value || defaultUnsetColor }}
        onClick={() => (typeof onChange === "function" ? setVisible(!visible) : null)}
      />
      <span className={style.text}>{value || ""}</span>
      {typeof onChange === "function" ? (
        <React.Fragment>
          <Overlay visible={visible} className={style.overlay} onClick={() => setVisible(false)} />
          <ChromePicker
            color={color}
            className={classNames(style.picker, { [style.pickerVisible]: visible }, className)}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
          />
        </React.Fragment>
      ) : null}
    </div>
  );
};

ColorPicker.defaultProps = {};

ColorPicker.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default ColorPicker;
