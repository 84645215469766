//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Utils from "lib/Utils";
import { Text } from "components/Typography";
import Logger from "../../../lib/Logger";
import Api from "../../../lib/api/storages";
import { SelectInput } from "../../Input";

class StorageInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    appearance: PropTypes.oneOf(["normal", "inline"]),
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    appearance: "normal",
    placeholder: "Select storage",
  };

  state = {
    storages: [],
  };

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    try {
      const { data } = await Api.getStorages({
        pageStart: 0,
        pageLimit: 999,
        includeHeartbeat: true,
      });
      this.setState({
        storages: data,
      });
    } catch (err) {
      Logger.error(err);
    }
  }

  inlineSelectStyles() {
    const dot = (color) => ({
      alignItems: "center",
      display: "flex",

      ":before": {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
      },
    });

    const applyStyle = (styles, { data }) => {
      if (
        data.lastHeartbeat &&
        data.lastHeartbeat.dateCreated &&
        Utils.timeSinceNow(data.lastHeartbeat.dateCreated) < 300
      ) {
        return { ...styles, ...dot("#2ac845") };
      }
      return { ...styles, ...dot("#C6C6C6") };
    };

    return {
      option: applyStyle,
      singleValue: applyStyle,
    };
  }

  render() {
    const { name, value, placeholder, appearance, onChange, className } = this.props;
    const { storages } = this.state;
    const input = {
      type: "select",
      name,
      placeholder: placeholder,
      labelKey: "name",
      valueKey: "id",
      options: storages,
      styles: this.inlineSelectStyles(),
    };

    return appearance === "inline" ? (
      <Text value={value} canEdit={true} input={input} onConfirm={(value) => onChange(name, value)} />
    ) : (
      <SelectInput input={input} value={value} onChange={onChange} className={className} />
    );
  }
}

export default StorageInput;
