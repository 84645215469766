//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Table, TableCell, TableHeader, TableRow, TableLink } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import TablePagination from "components/Table/TablePagination";

const ReportsTable = ({ reports, fetching, paging, handlePrevPage, handleNextPage, handleLimitChange }) => {
  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeadCell>Name</TableHeadCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {reports.map((report) => (
            <TableRow key={report.id}>
              <TableCell>
                <TableLink to={`/apps/reporting/reports/${report.id}`}>{report.name}</TableLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        paging={paging}
        onPrevPage={handlePrevPage}
        onNextPage={handleNextPage}
        onChangeLimit={handleLimitChange}
      />
    </div>
  );
};

ReportsTable.defaultProps = {};

ReportsTable.propTypes = {
  reports: PropTypes.array,
  fetching: PropTypes.bool,
  paging: PropTypes.object,
  handlePrevPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleLimitChange: PropTypes.func,
};

export default ReportsTable;
