import { createSlice } from "@reduxjs/toolkit";
import { assignUserKeycard, createKeycard, fetchKeycards, updateKeycard } from "store/reducers";
import { deleteKeycard, fetchKeycardById } from "store/reducers/keycards/keycards.thunks";
import { TKeycard } from "types";

export type KeycardsReducerStateData = Record<string, TKeycard>;

export interface KeycardsReducerState {
  data: KeycardsReducerStateData;
}

const initialState: KeycardsReducerState = {
  data: {},
};

export const keycardsSlice = createSlice({
  name: "keycards",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchKeycards.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: KeycardsReducerStateData, value: TKeycard) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchKeycardById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createKeycard.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateKeycard.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteKeycard.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addCase(assignUserKeycard.fulfilled, (state, action) => {
        if (action.payload.keycard) {
          state.data[action.payload.keycard.id] = action.payload.keycard;
        }
      });
  },
});

export const keycardsReducer = keycardsSlice.reducer;
