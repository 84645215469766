//@ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import withReportBuilder from "hocs/withReportBuilder";
import PropTypes from "prop-types";
import { requestReportSuccess } from "lib/actions/reports";
import { getReport } from "lib/reducers/reports.selectors";
import { Header, Subheader, Paragraph } from "components/Typography";
import TextTypes from "../../../TextTypes";
import style from "../builder.module.scss";

const TextSpec = ({ layout, component, actions }) => {
  const dispatch = useDispatch();
  const report = useSelector(getReport);

  const handleChange = (value) => {
    component.value = value;
    dispatch(
      requestReportSuccess({
        ...report,
        components: {
          ...report.components,
          [layout.id]: component,
        },
      }),
    );
  };

  const renderNode = () => {
    switch (component.id) {
      case TextTypes.TITLE:
        return (
          <Header
            canEdit={true}
            value={component.value}
            placeholder={component.placeholder}
            onConfirm={handleChange}
            inputClassName={style.textInlineInput}
          />
        );
      case TextTypes.HEADER:
        return (
          <Subheader
            canEdit={true}
            value={component.value}
            placeholder={component.placeholder}
            onConfirm={handleChange}
            inputClassName={style.textInlineInput}
          />
        );
      case TextTypes.PARAGRAPH:
        return (
          <Paragraph
            canEdit={true}
            value={component.value}
            placeholder={component.placeholder}
            onConfirm={handleChange}
            inputClassName={style.textInlineInput}
          />
        );
      case TextTypes.REPORT_DATE_GENERATE:
        return (
          <Paragraph placeholder={component.placeholder} className="report-content-disabled">
            {component.placeholder}
          </Paragraph>
        );
    }
  };
  return <div className={style.sectionTextValue}>{component ? renderNode() : null}</div>;
};

TextSpec.defaultProps = {};

TextSpec.propTypes = {
  layout: PropTypes.object,
  component: PropTypes.object,
  className: PropTypes.string,
};

export default withReportBuilder(TextSpec);
