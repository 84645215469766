import { useEffect, useState } from "react";
import { openModal } from "lib/actions/modal";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { fetchUsers } from "store/reducers";
import { Pagination } from "types";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { TeamMembersTable } from "components/shared";
import PageStage from "components/stages/PageStage";

export const ListUsersPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [ids, setIds] = useState<number[]>([]);
  const [paging, setPaging] = useState<Pagination>({
    pageStart: 0,
    pageLimit: 20,
    pageCount: 0,
    totalCount: 0,
    nextPageStart: null,
    previousPageStart: null,
  });

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart, paging.pageLimit]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchUsers({ pageStart, pageLimit })).unwrap();
      setIds(response.results.map(({ id }) => id));
      setPaging(response.paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.previousPageStart !== null ? prevState.previousPageStart : 0,
    }));
  };

  const nextPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.nextPageStart !== null ? prevState.nextPageStart : 0,
    }));
  };

  const handleLimitChange = (value: number) => {
    setPaging((prevState) => ({
      ...prevState,
      pageLimit: value,
    }));
  };

  const openInviteModal = () => {
    dispatch(
      openModal(Modals.INVITE_USER, {
        onSuccess: () => {
          const { pageLimit } = paging;
          fetch(0, pageLimit);
        },
      }),
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Team members"
        subtitle="Invite new team members and manage your existing team."
        action={
          <SecondaryButton onClick={openInviteModal} icon="/images/icons/plus_1.svg">
            Invite team member
          </SecondaryButton>
        }
      />
      <TeamMembersTable
        loading={loading}
        userIds={ids}
        paging={paging}
        onPrevPage={prevPage}
        onNextPage={nextPage}
        onLimitChange={handleLimitChange}
        onFetch={() => fetch(0, paging.pageLimit)}
      />
    </PageStage>
  );
};
