import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TDoorway, UUID } from "types";

const selectState = (state: RootStore) => state.doorways;

export const selectDoorways = createSelector(selectState, (state) => state.data);

export const selectDoorwaysList = createSelector(selectDoorways, (data) => Object.values(data));

export const selectDoorwayByID = (id: UUID) => createSelector(selectDoorways, (data): TDoorway | undefined => data[id]);

export const selectDoorwayByStorageID = (storageId: string) =>
  createSelector(selectDoorwaysList, (list) => list.find((entity) => entity.storageId === storageId));
