//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import withReaders from "../../../hocs/withReaders";
import ReadersTable from "./ReadersTable";

class ReadersTableContainer extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      requestReaders: PropTypes.func,
    }),
    stateStore: PropTypes.shape({
      readers: PropTypes.object,
    }),
  };

  componentDidMount() {
    this.fetch(0, 5);
  }

  fetch = (pageStart = 0, pageLimit = 5) => {
    const { requestReaders } = this.props.actions;
    requestReaders({ pageStart, pageLimit });
  };

  handlePrevPage = () => {
    const { previousPageStart, pageLimit } = this.props.stateStore.readers.paging;
    this.fetch(previousPageStart, pageLimit);
  };

  handleNextPage = () => {
    const { nextPageStart, pageLimit } = this.props.stateStore.readers.paging;
    this.fetch(nextPageStart, pageLimit);
  };

  handleLimitChange = (pageLimit) => {
    this.fetch(0, pageLimit);
  };

  render() {
    const { data, paging } = this.props.stateStore.readers;
    return (
      <ReadersTable
        readers={data}
        paging={paging}
        handlePrevPage={this.handlePrevPage}
        handleNextPage={this.handleNextPage}
        handleLimitChange={this.handleLimitChange}
      />
    );
  }
}

export default withReaders(ReadersTableContainer);
