//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FixLater } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { Header, Paragraph, Title } from "components/Typography";
import style from "pages/marketing/marketing.module.scss";

export const MarketingButton = ({ primary = false, hero, children, className, onClick, ...otherProps }: FixLater) => {
  const Component = primary ? PrimaryButton : SecondaryButton;
  const classes = classNames(style.marketingButton, { [style.buttonHero]: hero }, className);
  return (
    <Component {...otherProps} size={hero ? "large" : "medium"} className={classes} onClick={onClick}>
      {children}
    </Component>
  );
};

export const HalfHalf = ({ children, largerRight, largerLeft, className }) => {
  const classes = classNames(
    style.halfHalf,
    { [style.largerRight]: largerRight },
    { [style.largerLeft]: largerLeft },
    className,
  );
  return <div className={classes}>{children}</div>;
};

HalfHalf.propTypes = {
  largerRight: PropTypes.bool,
  className: PropTypes.string,
};

export const FullWidth = ({ children, className }) => {
  return <div className={classNames(style.fullWidth, className)}>{children}</div>;
};

FullWidth.propTypes = {
  className: PropTypes.string,
};

export const MarketingSection = ({ children, className }) => {
  return <div className={classNames(style.section, className)}>{children}</div>;
};

MarketingSection.propTypes = {
  className: PropTypes.string,
};

export const MarketingSectionWrapper = ({ children, className }) => {
  return <div className={classNames(style.sectionWrapper, className)}>{children}</div>;
};

MarketingSectionWrapper.propTypes = {
  className: PropTypes.string,
};

export const InfoText = ({ children, large, concise, spaceBottom, className }) => {
  const classes = classNames(
    style.paragraphInfo,
    { [style.paragraphInfoLarge]: large },
    { [style.paragraphConcise]: concise },
    { [style.paragraphSpace]: spaceBottom },
    className,
  );
  return <Paragraph className={classes}>{children}</Paragraph>;
};

InfoText.propTypes = {
  large: PropTypes.bool,
  concise: PropTypes.bool,
  spaceBottom: PropTypes.bool,
  className: PropTypes.string,
};

export const InfoHeader = ({ children, className }) => {
  return <InfoText className={classNames(style.paragraphHeader, className)}>{children}</InfoText>;
};

InfoHeader.propTypes = {
  className: PropTypes.string,
};

export const MarketingTitle = ({ children, className }) => {
  return <Title className={classNames(style.marketingTitle, className)}>{children}</Title>;
};

export const MarketingHeader = ({ children, className }) => {
  return <Header className={classNames(style.marketingHeader, className)}>{children}</Header>;
};
