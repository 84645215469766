import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TToolRoom, UUID } from "types";

const selectState = (state: RootStore) => state.toolRooms;

export const selectToolRooms = createSelector(selectState, (state) => state.data);

export const selectToolRoomsList = createSelector(selectToolRooms, (data) => Object.values(data));

export const selectToolRoomByID = (id: UUID) =>
  createSelector(selectToolRooms, (data): TToolRoom | undefined => data[id]);

export const selectToolRoomByStorageID = (storageId: string) =>
  createSelector(selectToolRoomsList, (list) => list.find((entity) => entity.storageId === storageId));
