import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TStorageAlert, UUID } from "types";

const selectState = (state: RootStore) => state.storageAlerts;

export const selectStorageAlerts = createSelector(selectState, (state) => state.data);

export const selectStorageAlertsList = createSelector(selectStorageAlerts, (data) => Object.values(data));

export const selectStorageAlertByID = (id: UUID) =>
  createSelector(selectStorageAlerts, (data): TStorageAlert | undefined => data[id]);

export const selectStorageAlertByStorageID = (storageId: string) =>
  createSelector(selectStorageAlertsList, (list) => list.find((entity) => entity.storageId === storageId));
