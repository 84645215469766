import { createSlice } from "@reduxjs/toolkit";
import {
  createRagBin,
  fetchRagBins,
  updateRagBin,
  deleteRagBin,
  fetchRagBinById,
  fetchRagBinByStorageId,
} from "store/reducers/ragBins/ragBins.thunks";
import { TRagBin, UUID } from "types";

export type RagBinsReducerStateData = Record<UUID, TRagBin>;

export interface RagBinsReducerState {
  data: RagBinsReducerStateData;
}

const initialState: RagBinsReducerState = {
  data: {},
};

export const ragBinsSlice = createSlice({
  name: "ragBins",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRagBins.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: RagBinsReducerStateData, value: TRagBin) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchRagBinById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(fetchRagBinByStorageId.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createRagBin.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateRagBin.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteRagBin.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const ragBinsReducer = ragBinsSlice.reducer;
