//@ts-nocheck
import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Header } from "components/Typography";
import { Breadcrumbs, BreadcrumbsItem } from "components/breadcrumbs";
import { TextBody } from "components/shared";
import style from "./stageheader.module.scss";

const BreadcrumbsContainer = styled.div`
  margin-bottom: 1rem;
`;

export const StageState = {
  INITIAL: 0,
  DIRTY: 1,
  SUCCESS: 2,
  FAILURE: 3,
};

const StageHeader = ({ title, subtitle, breadcrumb, action, noMarginTop, className }: any) => {
  const history = useNavigate();
  const menuItems = Array.isArray(breadcrumb)
    ? breadcrumb.map((item) => (typeof item === "string" ? { label: item, href: null } : item))
    : [];
  const handleClick = (item) => {
    if (item.href) {
      history(item.href);
    }
  };
  return (
    <div className={classNames(style.container, { [style.noMarginTop]: noMarginTop }, className)}>
      {breadcrumb && (
        <BreadcrumbsContainer>
          {Array.isArray(breadcrumb) && menuItems.length > 0 ? (
            <Breadcrumbs>
              {menuItems.map((item) => (
                <BreadcrumbsItem key={item.label} text={item.label} href={null} onClick={() => handleClick(item)} />
              ))}
            </Breadcrumbs>
          ) : (
            breadcrumb
          )}
        </BreadcrumbsContainer>
      )}
      {title || action ? (
        <div className={style.header}>
          <div className={style.titleWrapper}>
            {typeof title === "string" ? (
              <>
                <Header className={style.title}>{title}</Header>
                {subtitle && <TextBody>{subtitle}</TextBody>}
              </>
            ) : title ? (
              title
            ) : null}
          </div>
          {action ? <div className={style.action}>{action}</div> : null}
        </div>
      ) : null}
    </div>
  );
};

StageHeader.defaultProps = {
  breadcrumb: [],
};

StageHeader.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  noMarginTop: PropTypes.bool,
  breadcrumb: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.any,
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        href: PropTypes.string,
      }),
    ]),
  ),
  action: PropTypes.any,
};

export default StageHeader;
