//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import StageHeader from "components/StageHeader";
import ReportCanvas from "pages/reporting/reports/ReportPage/components/ReportBuilder/ReportCanvas";
import { GenerateReportButton } from "pages/reporting/reports/ReportPage/components/ReportController";

const ReportTemplatePage = ({ report, className }) => {
  return (
    <>
      <StageHeader action={<GenerateReportButton />} />
      <ReportCanvas />
    </>
  );
};

ReportTemplatePage.defaultProps = {};

ReportTemplatePage.propTypes = {
  className: PropTypes.string,
};

export default ReportTemplatePage;
