import { ReactNode } from "react";
import Header from "components/Typography/Header";
import Subheader from "components/Typography/Subheader";
import Title from "components/Typography/Title";

type HeadingProps = {
  level: "h1" | "h2" | "h3";
  children: ReactNode;
  className?: string;
  id?: string;
};

export const Heading = ({ level = "h3", children, className, id }: HeadingProps) => {
  switch (level) {
    case "h1":
      return (
        <Title id={id} className={className}>
          {children}
        </Title>
      );
    case "h2":
      return (
        <Header id={id} className={className}>
          {children}
        </Header>
      );
    case "h3":
    default:
      return (
        <Subheader id={id} className={className}>
          {children}
        </Subheader>
      );
  }
};
