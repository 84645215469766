import { createSlice } from "@reduxjs/toolkit";
import {
  createToolStoreOrderItemEvent,
  fetchToolStoreOrderItemEvents,
  updateToolStoreOrderItemEvent,
  deleteToolStoreOrderItemEvent,
  fetchToolStoreOrderItemEventById,
} from "store/reducers/toolStoreOrderItemEvents/toolStoreOrderItemEvents.thunks";
import { TToolStoreOrderItemEvent, UUID } from "types";

export type ToolStoreOrderItemEventsReducerStateData = Record<UUID, TToolStoreOrderItemEvent>;

export interface ToolStoreOrderItemEventsReducerState {
  data: ToolStoreOrderItemEventsReducerStateData;
}

const initialState: ToolStoreOrderItemEventsReducerState = {
  data: {},
};

export const toolStoreOrderItemEventsSlice = createSlice({
  name: "toolStoreOrderItemEvents",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // TODO: Add case for tool store orders.
      .addCase(fetchToolStoreOrderItemEvents.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: ToolStoreOrderItemEventsReducerStateData, value: TToolStoreOrderItemEvent) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchToolStoreOrderItemEventById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createToolStoreOrderItemEvent.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateToolStoreOrderItemEvent.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteToolStoreOrderItemEvent.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const toolStoreOrderItemEventsReducer = toolStoreOrderItemEventsSlice.reducer;
