//@ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import { useSelector } from "store";
import { QuantityInput } from "components/shared";
import ButtonAddToCart from "pages/apps/Shop/shared/ButtonAddToCart/ButtonAddToCart";
import styles from "./ShopItemPagePurchaseControls.module.scss";

const ShopItemPagePurchaseControls = ({ itemID, appearance }) => {
  const item = useSelector(getStoreItemByID(itemID));
  const [quantity, setQuantity] = useState(1);

  if (!item) {
    return null;
  }

  switch (appearance) {
    case "compact":
      return <ButtonAddToCart itemID={itemID} />;
    case "normal":
      return (
        <div className={styles.ShopItemPagePurchaseControlsNormal}>
          <QuantityInput value={quantity} onChange={(value) => setQuantity(value)} />
          <ButtonAddToCart itemID={itemID} quantity={quantity} />
        </div>
      );
    default:
      return null;
  }
};

ShopItemPagePurchaseControls.defaultProps = {
  appearance: "normal",
};

ShopItemPagePurchaseControls.propTypes = {
  appearance: PropTypes.oneOf(["compact", "normal"]),
  itemID: PropTypes.number,
};

export default ShopItemPagePurchaseControls;
