import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { PagedDataResponse, Pagination, PaginationQueryParams, TCaseScannerHistoryEvent, UUID } from "types";

type FetchCaseScannerHistoryEventsParams = PaginationQueryParams & {
  caseScannerId: UUID;
};

export const fetchCaseScannerHistoryEvents = createAsyncThunk(
  "caseScannerHistoryEvents/fetchCaseScannerHistoryEvents",
  async (
    params: FetchCaseScannerHistoryEventsParams,
  ): Promise<{ results: TCaseScannerHistoryEvent[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TCaseScannerHistoryEvent[]>>(
      `/case_scanners/${params.caseScannerId}/history`,
      params,
    );
    return { results: data, paging: metadata };
  },
);
