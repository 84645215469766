//@ts-nocheck
import Logger from "../Logger";
import Store from "../Store";
import { STORE_LOOKUP_VALUE } from "../actions/lookupData";

const lookupStorage = (store) => (next) => (action) => {
  if (action.type === STORE_LOOKUP_VALUE) {
    if (action.collectionName !== "") {
      Store.collection(action.collectionName).remove();
      if (action.payload !== null) {
        Store.collection(action.collectionName).insert(action.payload);
      }
    } else {
      Logger.warning("lookupStorage middleware received empty collection name");
    }
  }
  next(action);
};

export default lookupStorage;
