import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TRag, UUID } from "types";

const selectState = (state: RootStore) => state.rags;

export const selectRags = createSelector(selectState, (state) => state.data);

export const selectRagsList = createSelector(selectRags, (data) => Object.values(data));

export const selectRagByID = (id: UUID) => createSelector(selectRags, (data): TRag | undefined => data[id]);
