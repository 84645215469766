import { createSelector } from "reselect";
import { RootStore } from "store/reducer";

const selectState = (state: RootStore) => state.keycards;

export const selectKeycards = createSelector(selectState, (state) => state.data);

export const selectKeycardsList = createSelector(selectKeycards, (data) => Object.values(data));

export const selectKeycardByID = (id: string) => createSelector(selectKeycards, (data) => data[id]);
