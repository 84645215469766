import useSetSidebar from "effects/useSidebar";
import Apps from "lib/constants/Apps";
import Capabilities from "lib/constants/Capabilities";
import { Restrict } from "components/Restricted";
import { VSpace } from "components/shared";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import { SidebarHeading } from "components/sidebars/SidebarHeading/SidebarHeading";
import Sidebar from "./Sidebar";

export const ConfigurationSidebar = () => {
  useSetSidebar(true);
  const url = `/configuration`;

  return (
    <Sidebar>
      <VSpace gap="gap-4">
        <Restrict app={Apps.PRODUCT_CATALOGUE_MANAGEMENT}>
          <VSpace gap="gap-4">
            <SidebarHeading>Global Configurations</SidebarHeading>
            <VSpace gap="gap-0.5">
              <MenuItem to="/configuration/manufacturers" icon="/images/icons/maintenance_1.svg">
                Manufacturers
              </MenuItem>
            </VSpace>
          </VSpace>
        </Restrict>

        <SidebarHeading>Company Configurations</SidebarHeading>
        <VSpace gap="gap-0.5">
          <Restrict capability={Capabilities.CONFIGURE_TEAM}>
            <MenuItem to={`${url}/users`} icon="/images/icons/workforce_2.svg">
              Team members
            </MenuItem>
          </Restrict>
          <Restrict capability={Capabilities.CONFIGURE_ROLES}>
            <MenuItem to={`${url}/roles`} icon="/images/icons/privacy.svg">
              Roles
            </MenuItem>
          </Restrict>
          <Restrict capability={Capabilities.CONFIGURE_BRANDING}>
            <MenuItem to={`${url}/branding`} icon="/images/icons/brand_colours_1.svg">
              Branding
            </MenuItem>
          </Restrict>
          <Restrict capability={Capabilities.CONFIGURE_KEYCARDS}>
            <MenuItem to={`${url}/keycards`} icon="/images/icons/keycard_1.svg">
              Keycards
            </MenuItem>
          </Restrict>
          <Restrict capability={Capabilities.CONFIGURE_LOCATIONS}>
            <MenuItem to={`${url}/locations`} icon="/images/icons/location.svg">
              Locations
            </MenuItem>
          </Restrict>
        </VSpace>
      </VSpace>
    </Sidebar>
  );
};

export default ConfigurationSidebar;
