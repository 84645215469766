import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { login, selectAuthSession } from "store/reducers";
import LoginForm from "pages/authentication/Login/LoginForm";

export const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector(selectAuthSession);
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [authenticating, setAuthenticating] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setError(undefined);
  }, [setError, state]);

  useEffect(
    () => {
      if (session) {
        navigate("/dashboard", { replace: true });
      }
    },
    [
      /* Do not add session to deps */
    ],
  );

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const { username, password } = state;

    if (!username) {
      return setState((prevState) => ({
        ...prevState,
        error: "Email address cannot be empty",
      }));
    }

    if (!password) {
      return setState((prevState) => ({
        ...prevState,
        error: "Password cannot be empty",
      }));
    }

    setAuthenticating(true);

    dispatch(
      login({
        username: state.username,
        password: state.password,
      }),
    )
      .unwrap()
      .then(({ resetPassword }) => {
        if (resetPassword) {
          navigate("/change_password", { replace: true });
        }
        navigate("/dashboard", { replace: true });
      })
      .catch((err) => {
        setError(err.response.data.message || "Something went wrong, please try again");
      })
      .finally(() => {
        setAuthenticating(false);
      });
  };

  const onChange = (name: string, value: string) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <LoginForm
      username={state.username}
      password={state.password}
      isAuthenticating={authenticating}
      error={error}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};
