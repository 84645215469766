import { useEffect, useMemo, useState } from "react";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  fetchVehicles,
  selectVehiclesLoading,
  selectVehiclesPage,
  selectVehiclesPaging,
  updateVehicle,
} from "store/reducers";
import { TLocation, TVehicle } from "types";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { SmartTable, SmartTableNav, SmartTablePagination } from "components/Table";
import { Text } from "components/Typography";
import { LocationSelector } from "components/inputs";
import { CreateVehicleModal, VehiclesTableRowDropdown } from "components/shared";
import { VehicleAvailableIndicator } from "components/shared/features/vehicles/VehicleAvailableIndicator/VehicleAvailableIndicator";
import PageStage from "components/stages/PageStage";

type RowData = {
  row: {
    original: TVehicle;
  };
};

export const VehiclesPage = () => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const loading = useSelector(selectVehiclesLoading);
  const vehicles = useSelector(selectVehiclesPage);
  const paging = useSelector(selectVehiclesPaging);
  const [query, setQuery] = useState<string>("");
  const [vehicleCreated, setVehicleCreated] = useState<TVehicle>();

  const rowData = vehicles;
  const columns = useMemo(() => {
    return [
      {
        Key: "Available",
        Header: "Available",
        Cell: ({ row }: RowData) => {
          const vehicle = row.original;
          return <VehicleAvailableIndicator available={vehicle.available} />;
        },
      },
      {
        Key: "Registration",
        Header: "Registration",
        Cell: ({ row }: RowData) => {
          const vehicle = row.original;
          return (
            <Text
              canEdit
              placeholder="Add registration"
              value={vehicle.registration}
              onConfirm={updateRegistration(vehicle)}
            />
          );
        },
      },
      {
        Key: "Location",
        Header: "Location",
        Cell: ({ row }: RowData) => {
          const vehicle = row.original;
          return (
            <LocationSelector
              searchable
              appearance="inline"
              name="locationId"
              placeholder="Vehicle location"
              value={vehicle.locationId}
              onChange={updateLocation(vehicle)}
            />
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: RowData) => {
          const { id } = row.original;
          return <VehiclesTableRowDropdown vehicleId={id} onDelete={() => fetch(0, paging.pageLimit)} />;
        },
      },
    ];
  }, [paging]);

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [query]);

  useEffect(() => {
    if (!vehicleCreated) return;
    dispatch(
      showPopupFlag({
        appearance: "success",
        title: `Vehicle "${vehicleCreated.registration}" created`,
      }),
    );
    fetch(0, paging.pageLimit);
  }, [vehicleCreated]);

  const fetch = (pageStart: number, pageLimit: number) => {
    dispatch(
      fetchVehicles({
        pageStart,
        pageLimit,
        search_term: query || undefined,
      }),
    );
  };

  const updateRegistration = (vehicle: TVehicle) => {
    return async (value: string) => {
      try {
        await dispatch(
          updateVehicle({
            ...vehicle,
            registration: value,
          }),
        ).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Vehicle registration updated",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const updateLocation = (vehicle: TVehicle) => {
    return async (name: string, value?: TLocation) => {
      try {
        await dispatch(
          updateVehicle({
            ...vehicle,
            locationId: value?.id,
          }),
        ).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Vehicle location changed",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const createVehicle = () => {
    openModal(
      <CreateVehicleModal
        onSuccess={(entity: TVehicle) => {
          setVehicleCreated(entity);
        }}
      />,
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Vehicles"
        subtitle="Manage your vehicle fleet"
        breadcrumb={["Vehicles", { label: "Live map", href: "/apps/vehicles/map" }, "Manage vehicles"]}
        action={
          <SecondaryButton onClick={createVehicle} icon="/images/icons/plus_1.svg">
            Create vehicle
          </SecondaryButton>
        }
      />
      <SmartTableNav
        paging={paging}
        onPageChange={fetch}
        showQueryInput={true}
        queryPlaceholder="Search by name or registration"
        onQueryChange={setQuery}
      />
      <SmartTable loading={loading} columns={columns} data={rowData} />
      <SmartTablePagination
        paging={paging}
        onChange={(pageStart: number, pageLimit: number) => fetch(pageStart, pageLimit)}
      />
    </PageStage>
  );
};
