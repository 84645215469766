//@ts-nocheck
import React, { useMemo } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Resolve from "lib/Resolve";
import Utils from "lib/Utils";
import Avatar from "components/Avatar";
import Popover from "components/Popover";
import { Text } from "components/Typography";
import style from "./viewers.module.scss";

const ItemViewer = ({ viewer }) => {
  const user = useMemo(() => Resolve.resolveUser(viewer.userId), [viewer.userId]);
  if (!user) {
    return null;
  }

  const initials = Utils.formatNameInitials(user);
  return (
    <Popover
      content={
        <div>
          <Text>{Resolve.resolveUserFullName(viewer.userId)}</Text>
          <Text className={style.viewerEmail}>{user.email}</Text>
          <Text className={style.viewerTime}>
            Last viewed {dayjs(viewer.dateLastViewed).format("MMM D, YYYY h:mm A")}
          </Text>
        </div>
      }
    >
      <Avatar image={user.profileImage} alt={initials} className={style.viewerAvatar} size="s" />
    </Popover>
  );
};

const ItemViewers = ({ viewers, maxItems, className }) => {
  const render = () => {
    if (!Array.isArray(viewers)) {
      return null;
    }
    return viewers.slice(0, maxItems).map((viewer) => <ItemViewer key={viewer.id} viewer={viewer} />);
  };
  const renderAdditional = () => {
    if (!Array.isArray(viewers) || viewers.length <= maxItems) {
      return null;
    }
    const count = viewers.length - maxItems;
    return <Avatar alt={`+${count}`} className={style.viewerAvatar} size="s" />;
  };
  return (
    <div className={style.viewers}>
      {render()}
      {renderAdditional()}
    </div>
  );
};

ItemViewers.defaultProps = {
  maxItems: 5,
};

ItemViewers.propTypes = {
  maxItems: PropTypes.number,
  className: PropTypes.string,
};

export default ItemViewers;
