import React from "react";
import { useSelector } from "store";
import { selectStorageByID } from "store/reducers";
import Loading from "components/Loading";
import StatusPill from "components/StatusPill";

type StorageStatusPillProps = {
  storageId: string;
};

export const StorageStatusPill = ({ storageId }: StorageStatusPillProps) => {
  const entity = useSelector(selectStorageByID(storageId));
  if (!entity) {
    return <Loading />;
  }
  const { sentryApiKey, online } = entity;

  // Is not assigned to a physical storage
  if (!sentryApiKey?.dateActivated) {
    return <StatusPill color="grey" text="Unassigned" />;
  }

  return online ? <StatusPill color="success" text="Online" /> : <StatusPill color="danger" text="Offline" />;
};
