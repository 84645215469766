import { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { useCustomerHasApp, useModal } from "effects";
import styled from "styled-components";
import Icon from "components/Icon";
import { Text } from "components/Typography";
import style from "components/sidebars/sidebar.module.scss";

const RawIconWrapper = styled.span`
  font-size: 1.875rem;
`;

type MenuItemProps = {
  children?: ReactNode;
  to?: string;
  icon?: string | ReactNode;
  image?: string;
  noActive?: boolean;
  target?: string;
  className?: string;
  alignLeft?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  app?: string;
  upsell?: ReactNode; // The upsell modal to open
};

export const MenuItem = ({
  children,
  icon,
  image,
  to,
  noActive,
  target,
  className,
  alignLeft,
  onClick,
  disabled,
  app,
  upsell,
}: MenuItemProps) => {
  const { openModal } = useModal();
  const customerHasApp = useCustomerHasApp(app || "");
  const hideMenuItem = app && !customerHasApp && !upsell;

  const handleClick = () => {
    if (disabled) {
      return;
    }
    if (app && !customerHasApp && upsell) {
      openModal(upsell);
      return;
    }
    onClick && onClick();
  };

  const renderContent = () => {
    return (
      <div
        className={classNames(style.menuItemContent, {
          [style.alignLeft]: alignLeft,
        })}
      >
        {typeof icon === "string" ? (
          <RawIconWrapper className={style.menuItemIcon}>
            <Icon src={icon} size="large" />
          </RawIconWrapper>
        ) : (
          icon && <RawIconWrapper className={style.menuItemIcon}>{icon}</RawIconWrapper>
        )}
        {image && <div style={{ backgroundImage: `url(${image})` }} className={style.menuItemImage} />}
        {children && <Text className={style.menuText}>{children}</Text>}
      </div>
    );
  };

  if (hideMenuItem) {
    return null;
  }

  return !!to && !disabled ? (
    <NavLink
      to={to}
      target={target}
      className={({ isActive }) => classNames(style.menuItem, { [style.active]: isActive && !noActive }, className)}
    >
      {renderContent()}
    </NavLink>
  ) : (
    <span className={classNames(style.menuItem, className)} onClick={handleClick}>
      {renderContent()}
    </span>
  );
};
