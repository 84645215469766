import React from "react";
import classNames from "classnames";
import styled, { css } from "styled-components";
import styles from "./ToolSVG.module.scss";

const DrawerAppearance = css`
  fill: rgb(226, 232, 240);
  &:hover {
    fill: rgb(226, 232, 240);
  }
`;

const ToolAppearance = css`
  fill: rgb(248, 250, 252);
  cursor: pointer;

  &:hover {
    fill: rgb(203, 213, 225);
  }
`;

const AllocatedTool = css`
  fill: rgb(234 88 12);
  cursor: not-allowed;

  &:hover {
    fill: rgb(234 88 12);
  }
`;

const ToolPath = styled.path<Pick<ToolSVGProps, "appearance" | "located">>`
  stroke-width: 1;
  stroke-opacity: 1;

  &:hover {
    transition: fill 100ms ease-in-out;

    &.selected {
      fill: rgb(37, 99, 235);
    }
  }

  &.selected {
    fill: rgb(59, 130, 246);
  }

  ${({ appearance }) => appearance === "drawer" && DrawerAppearance}
  ${({ appearance }) => appearance === "tool" && ToolAppearance}
  ${({ located }) => located && AllocatedTool}
`;

type ToolSVGProps = {
  path: string;
  appearance: "tool" | "drawer";
  selected: boolean;
  located: boolean;
  onClick?: () => void;
};

export const ToolSVG = ({ path, appearance, selected = false, located = false, onClick }: ToolSVGProps) => {
  const classes = classNames(styles.ToolSVG, {
    [styles.selected]: selected,
    [styles.drawer]: appearance === "drawer",
  });

  const handleClick = () => {
    if (!located) {
      onClick && onClick();
    }
  };

  return (
    <ToolPath
      className={classes}
      onClick={handleClick}
      d={path}
      located={located}
      appearance={appearance}
      style={{
        strokeWidth: 1,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeOpacity: 1,
        stroke: "transparent",
      }}
    />
  );
};
