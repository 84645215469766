import Lookup from "lib/constants/Lookup";
import { RagBinType } from "types";

class RagBinTypes extends Lookup<RagBinType> {
  constructor() {
    super();
    this.items = [
      {
        value: "clean",
        label: "Clean",
        description: "Bin containing clean rags",
      },
      {
        value: "dirty",
        label: "Dirty",
        description: "Bin containing dirty rags",
      },
      {
        value: "maintenance",
        label: "Maintenance",
        description: "Bin containing rags that are out-of-service",
      },
    ];
  }
}

export default new RagBinTypes();
