//@ts-nocheck
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import classNames from "classnames";
import { Text } from "components/Typography";
import { KanbanItem } from "components/shared/index";
import styles from "./KanbanColumn.module.scss";

export const KanbanColumn = ({ columnID, title, isDropDisabled, items }) => {
  return (
    <Droppable droppableId={columnID} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.droppableProps} className={styles.KanbanColumn}>
          <Text className={styles.Title}>{title}</Text>
          <div
            className={classNames(styles.KanbanColumnItems, {
              [styles.IsDraggingOver]: snapshot.isDraggingOver,
            })}
          >
            {items.map((item, index) => (
              <KanbanItem key={`${columnID}-${item.id}`} index={index} {...item} />
            ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
