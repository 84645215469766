import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { createToolStoreOrder, selectLocationByID } from "store/reducers";
import { TLocation } from "types";
import { PrimaryButton } from "components/Buttons";
import Overlay from "components/Overlay";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs";
import { useToolStoreOrder } from "components/shared/features/toolStoreOrders";
import { VSpace } from "components/shared/layouts";
import { Heading, TextBody } from "components/shared/typography";

const ReviewOrderOverlay = styled(Overlay)`
  z-index: 3;
`;

const ReviewOrderContainer = styled.div<{ rounded: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  left: 0;
  background: white;
  padding: 1rem 1rem 2.5rem;
  width: 100vw;
  box-shadow: 0 -1px 4px rgb(0 0 0 / 8%);
  z-index: 4;

  @media only screen and (min-width: 768px) {
    & > * {
      max-width: 40rem;
      width: 100%;
    }
  }

  ${({ rounded }) =>
    rounded &&
    css`
      padding-top: 1.5rem;
      border-radius: 1rem 1rem 0 0;
    `}
`;

const ReviewOrderButton = styled(PrimaryButton)`
  position: relative;
  width: 100%;
  padding: 1rem 2rem !important;
  border-radius: 0.5rem !important;
`;

const ReviewOrderButtonQuantity = styled.div`
  position: absolute;
  left: 1rem;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  background-color: #9d6fff;
`;

export const ToolStoreFooterButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { toolStore, setToolStore, location, setLocation, basket, setBasket } = useToolStoreOrder();
  const toolStoreLocation = useSelector(selectLocationByID(toolStore?.storage.locationId || ""));
  const [review, setReview] = useState<boolean>(false);

  const submitOrder = async () => {
    try {
      if (!location || !toolStore || !Object.keys(basket).length) {
        return;
      }
      await dispatch(
        createToolStoreOrder({
          toolStoreId: toolStore.id,
          dropOffLocationId: location.id,
          items: Object.values(basket).map((asset) => ({
            assetId: asset.id,
          })),
        }),
      );
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Your order has been submitted",
        }),
      );
      // Clean up order form
      setBasket({});
      setLocation(undefined);
      setToolStore(undefined);
      navigate(`/toolstore`);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (Object.keys(basket).length === 0) {
    return null;
  }

  return (
    <>
      {review && <ReviewOrderOverlay isDark onClick={() => setReview(false)} />}
      <ReviewOrderContainer rounded={review}>
        {review ? (
          <VSpace gap="gap-8">
            <div>
              <Heading level="h2">Review order</Heading>
              <TextBody>
                Please select a delivery location and check that your order details are correct before completing the
                order.
              </TextBody>
            </div>
            <VSpace>
              <FieldGroup label="Tool store" value={<TextBody>{toolStore?.storage.name}</TextBody>} />
              <FieldGroup label="Tool store location" value={<TextBody>{toolStoreLocation?.name}</TextBody>} />
              <FieldGroup
                label="Delivery location"
                value={
                  <LocationSelector
                    appearance="normal"
                    name="location"
                    menuPlacement="top"
                    value={location}
                    types={["bay"]}
                    onChange={(name: string, value: TLocation) => setLocation(value)}
                  />
                }
              />
            </VSpace>
            <ReviewOrderButton type="button" size="large" disabled={!location} onClick={submitOrder}>
              Complete order
            </ReviewOrderButton>
          </VSpace>
        ) : (
          <ReviewOrderButton type="button" size="large" onClick={() => setReview(true)}>
            <ReviewOrderButtonQuantity>{Object.keys(basket).length}</ReviewOrderButtonQuantity>
            Review order
          </ReviewOrderButton>
        )}
      </ReviewOrderContainer>
    </>
  );
};
