//@ts-nocheck
import Store from "lib/Store";

const CartCollection = "cart";

export const getCartItems = () => {
  const items = Store.collection(CartCollection).find();
  const itemsObj = {};
  for (const item of items) {
    itemsObj[item.itemID] = item.quantity;
  }
  return itemsObj;
};

export const setItemQuantity = (itemID, quantity) => {
  const item = Store.collection(CartCollection).findOne({ itemID });
  if (item) {
    Store.collection(CartCollection).update(
      { itemID },
      {
        itemID,
        quantity,
      },
    );
  } else {
    Store.collection(CartCollection).insert({
      itemID,
      quantity,
    });
  }
};

export const removeItem = (itemID) => {
  Store.collection(CartCollection).remove({ itemID });
};
