//@ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import Resolve from "lib/Resolve";
import AccessScopes from "lib/constants/AccessScopes";
import Icon from "components/Icon";
import Text from "components/Typography/Text";
import style from "./swimlaneCard.module.scss";

const resolveName = (grant) => {
  switch (grant.rule.scope) {
    case AccessScopes.GROUP:
      return grant.rule.valueString;
    case AccessScopes.PROJECT:
      return Resolve.resolveProjectName(grant.rule.valueInt);
    case AccessScopes.ROLE:
      return Resolve.resolveRoleName(grant.rule.valueInt);
    case AccessScopes.USER:
      return Resolve.resolveUserFullName(grant.rule.valueInt);
    case AccessScopes.KEYCARD:
      return grant.rule.valueString;
  }
};

const resolveLink = (grant) => {
  // Ensure the access modal does not get opened
  const handleClick = (e) => e.stopPropagation();
  switch (grant.rule.scope) {
    case AccessScopes.PROJECT:
      return (
        <Link to={`/apps/projects/${grant.rule.valueInt}`} onClick={handleClick}>
          View project
        </Link>
      );
    case AccessScopes.USER:
      // return <Link to={`/configuration/users`} onClick={handleClick}>
      //     View profile
      // </Link>;
      return null;
    default:
      return null;
  }
};

const SwimlaneAccessCard = ({ grant, className, onClick }) => {
  const { allowKeycard, allowMobileApp } = grant;
  return (
    <div className={classNames(style.card, className)} onClick={onClick}>
      <div className={style.content}>
        <Text className={style.name}>{resolveName(grant)}</Text>
        {resolveLink(grant)}
      </div>
      <div className={style.footer}>
        <Text className={style.expiry}>
          {grant.expireDate !== "0001-01-01T00:00:00Z" ? (
            <span>{dayjs(grant.expireDate).format("MMM D, YYYY")}</span>
          ) : (
            "No expiry"
          )}
        </Text>
        <div className={style.methods}>
          {allowMobileApp && <Icon src="/images/icons/phone_1.svg" className={style.icon} />}
          {allowKeycard && <Icon src="/images/icons/keycard_1.svg" className={style.icon} />}
        </div>
      </div>
    </div>
  );
};

SwimlaneAccessCard.defaultProps = {};

SwimlaneAccessCard.propTypes = {
  grant: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default SwimlaneAccessCard;
