//@ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { requestStoreItem } from "lib/actions/marketplace";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import Loading from "components/Loading";
import PageStage from "components/stages/PageStage";
import ShopItemDashboardPage from "./components/ShopItemDashboardPage";

const ShopManageItemPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { itemID, section } = params;
  const storeItem = useSelector(getStoreItemByID(itemID));

  useEffect(() => {
    dispatch(requestStoreItem(itemID));
  }, [itemID]);

  const renderPage = () => {
    switch (section) {
      case "dashboard":
        return <ShopItemDashboardPage storeItem={storeItem} />;
    }
  };

  return <PageStage>{storeItem ? renderPage() : <Loading size="large" />}</PageStage>;
};

ShopManageItemPage.defaultProps = {};

ShopManageItemPage.propTypes = {
  className: PropTypes.string,
};

export default ShopManageItemPage;
