import React, { useEffect } from "react";
import { useDispatch, useSelector } from "store";
import { fetchAssetById, selectAssetByGUID, selectAssetIssueByID } from "store/reducers";
import { UUID } from "types";
import { TableLink } from "components/Table";

type AssetIssueAssetAvatarProps = {
  assetIssueId: UUID;
};

export const AssetIssueAssetAvatar = ({ assetIssueId }: AssetIssueAssetAvatarProps) => {
  const dispatch = useDispatch();
  const entity = useSelector(selectAssetIssueByID(assetIssueId));
  const asset = useSelector(selectAssetByGUID(entity?.assetId || ""));

  useEffect(() => {
    if (!entity) return;
    if (!entity.assetId) return;
    if (asset) return;
    dispatch(fetchAssetById(entity.assetId));
  }, [entity?.assetId]);

  if (!asset?.catalogProduct) return null;
  return <TableLink to={`/apps/tracking/assets/${asset.id}`}>{asset.catalogProduct.name}</TableLink>;
};
