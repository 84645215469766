import { combineReducers } from "redux";
import alerts from "lib/reducers/alerts";
import assets from "lib/reducers/assets";
import blocks from "lib/reducers/blocks";
import cart from "lib/reducers/cart";
import catalogue from "lib/reducers/catalogue";
import checkouts from "lib/reducers/checkouts";
import evidences from "lib/reducers/evidences";
import lookupData from "lib/reducers/lookupData";
import manufacturers from "lib/reducers/manufacturers";
import modal from "lib/reducers/modal";
import products from "lib/reducers/products";
import projects from "lib/reducers/projects";
import readers from "lib/reducers/readers";
import reportBuilder from "lib/reducers/reportBuilder";
import reports from "lib/reducers/reports";
import sentry from "lib/reducers/sentry";
import statistics from "lib/reducers/statistics";
import storages from "lib/reducers/storages";
import storeItems from "lib/reducers/storeItems";
import tags from "lib/reducers/tags";
import taskLists from "lib/reducers/taskLists";
import tasks from "lib/reducers/tasks";
import userInterface from "lib/reducers/userInterface";
import {
  accountsReducer,
  alertRecipientsReducer,
  alertRulesReducer,
  alertsReducer,
  assetIssueProblemsReducer,
  assetIssuesReducer,
  assetProductsReducer,
  assetsReducer,
  calibrationCertificatesReducer,
  caseScannerHistoryEventsReducer,
  caseScannersReducer,
  catalogueProductsReducer,
  keycardsReducer,
  locationsReducer,
  manufacturersReducer,
  ragBinsReducer,
  ragControllersReducer,
  ragsReducer,
  rolesReducer,
  settingsReducer,
  storageAlertsReducer,
  storagesReducer,
  toolRoomsReducer,
  toolStoreOrderItemEventsReducer,
  toolStoreOrderItemsReducer,
  toolStoreOrdersReducer,
  toolStoresReducer,
  userKeycardsReducer,
  usersReducer,
  vehiclesReducer,
  doorwaysReducer,
} from "store/reducers";

const appReducer = combineReducers({
  assets,
  storages,
  readers,
  lookupData,
  checkouts,
  tags,
  statistics,
  modal,
  projects,
  catalogue,
  reports,
  reportBuilder,
  evidences,
  userInterface,
  sentry,
  blocks,
  tasks,
  taskLists,
  storeItems,
  alerts,
  cart,
  manufacturers,
  products,
  assetsReducer,
  accounts: accountsReducer,
  users: usersReducer,
  keycards: keycardsReducer,
  userKeycards: userKeycardsReducer,
  roles: rolesReducer,
  locations: locationsReducer,
  toolStores: toolStoresReducer,
  toolStoreOrders: toolStoreOrdersReducer,
  toolStoreOrderItems: toolStoreOrderItemsReducer,
  toolStoreOrderItemEvents: toolStoreOrderItemEventsReducer,
  toolRooms: toolRoomsReducer,
  caseScanners: caseScannersReducer,
  caseScannerHistoryEvents: caseScannerHistoryEventsReducer,
  toolStorages: storagesReducer,
  alertsReducer,
  storageAlerts: storageAlertsReducer,
  alertRules: alertRulesReducer,
  alertRecipients: alertRecipientsReducer,
  ragControllers: ragControllersReducer,
  ragBins: ragBinsReducer,
  rags: ragsReducer,
  vehicles: vehiclesReducer,
  settings: settingsReducer,
  manufacturersReducer,
  catalogueProductsReducer,
  assetProducts: assetProductsReducer,
  calibrationCertificates: calibrationCertificatesReducer,
  assetIssueProblems: assetIssueProblemsReducer,
  assetIssues: assetIssuesReducer,
  doorways: doorwaysReducer,
});

export type RootStore = ReturnType<typeof appReducer>;

const reducer = (state: RootStore | undefined, action: any) => {
  return appReducer(state, action);
};

export default reducer;
