import { createSlice } from "@reduxjs/toolkit";
import { createLocation, fetchLocations, updateLocation } from "store/reducers";
import { deleteLocation, fetchLocationById } from "store/reducers/locations/locations.thunks";
import { TLocation, UUID } from "types";

export type LocationsReducerStateData = Record<UUID, TLocation>;

export interface LocationsReducerState {
  data: LocationsReducerStateData;
}

const initialState: LocationsReducerState = {
  data: {},
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: LocationsReducerStateData, value: TLocation) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchLocationById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateLocation.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const locationsReducer = locationsSlice.reducer;
