//@ts-nocheck
import React from "react";
import { Element } from "react-scroll";
import Avatar from "components/Avatar";
import { Title } from "components/Typography";
import { InfoText, MarketingSection } from "pages/marketing/components/Common";
import style from "pages/marketing/marketing.module.scss";

const MarketingPricing = () => {
  return (
    <MarketingSection>
      <div id="where-to-buy" className={style.pricing}>
        <Element name="where-to-buy">
          <Title>
            <span>Where to purchase</span>
          </Title>
        </Element>
        <InfoText concise={true}>
          We sell our tool control solution through industry-leading partners. Click a partner below to visit their
          website and enquire about product demonstration and purchasing.
        </InfoText>
        <div className={style.whereToBuy}>
          <a
            href="https://www.toolraptors.co.uk/product-page/smart-access-roll-cab-key-card-access"
            target="_blank"
            rel="noreferrer"
          >
            <Avatar
              image="/images/toolraptors_logo.svg"
              alt="Tool Raptors"
              onClick={() => null}
              hoverIcon="/images/icons/export_1.svg"
              className={style.partner}
              imageClassName={style.partnerImage}
            />
          </a>
          <a href="https://www.tengtools.com/r/gb/en/contact-us" target="_blank" rel="noreferrer">
            <Avatar
              image="/images/clients_teng_tools.png"
              alt="Teng Tools"
              onClick={() => null}
              hoverIcon="/images/icons/export_1.svg"
              className={style.partner}
              imageClassName={style.partnerImage}
            />
          </a>
        </div>
      </div>
    </MarketingSection>
  );
};

export default MarketingPricing;
