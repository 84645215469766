import { useDispatch } from "react-redux";
import { openModal } from "lib/actions/modal";
import Modals from "lib/constants/Modals";
import { SecondaryButton } from "components/Buttons";

type ImportAssetButtonProps = {
  onSuccess: () => void;
};

export const ImportAssetButton = ({ onSuccess }: ImportAssetButtonProps) => {
  const dispatch = useDispatch();

  const beginImport = () => {
    dispatch(
      openModal(Modals.IMPORT_ASSETS, {
        onSuccess: onSuccess,
      }),
    );
  };

  return (
    <SecondaryButton onClick={beginImport} icon="/images/icons/import_1.svg">
      Import tools
    </SecondaryButton>
  );
};
