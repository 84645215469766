//@ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useTicker from "effects/useTicker";
import moment from "moment";
import { openModal } from "lib/actions/modal";
import { requestProjects, requestProjectSuccess, updateProject } from "lib/actions/projects";
import Capabilities from "lib/constants/Capabilities";
import Modals from "lib/constants/Modals";
import ProjectStatus from "lib/constants/ProjectStatus";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getProjectsList } from "lib/reducers/projects.selectors";
import { SecondaryButton } from "components/Buttons";
import Loading from "components/Loading";
import Popover from "components/Popover";
import { Restrict } from "components/Restricted";
import StageHeader from "components/StageHeader";
import { Text } from "components/Typography";
import { KanbanBoard } from "components/shared";
import { ProjectKanbanItem } from "components/shared/features/projects/ProjectKanbanItem/ProjectKanbanItem";
import PageStage from "components/stages/PageStage";

export const ProjectsKanbanScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const projects = useSelector(getProjectsList);
  const [_, setNow] = useState(moment());
  const [lastUpdate, setLastUpdate] = useState(null);
  const [loading, setLoading] = useState(true);
  const columns = [ProjectStatus.Open, ProjectStatus.Active, ProjectStatus.OnHold, ProjectStatus.Completed].map(
    (statusID) => {
      return {
        id: statusID,
        columnID: `${statusID}`,
        title: ProjectStatus.resolveItemLabel(statusID),
        items: projects
          .filter((project) => project.status === statusID)
          .map((project) => ({
            id: `${project.id}`,
            data: project,
            component: (
              <ProjectKanbanItem projectID={project.id} onClick={() => history(`/apps/projects/${project.id}`)} />
            ),
          })),
      };
    },
  );

  // // Every 20 seconds refresh re-render so we can update the "Last updated" text
  //useTicker(() => setNow(moment()), 1000*20);

  // Every 5 minutes, refresh the projects
  useTicker(() => fetchProjects(0, 999), 1000 * 60 * 5);

  const fetchProjects = (pageStart, pageLimit) => {
    setLastUpdate(moment());
    setLoading(true);
    dispatch(
      requestProjects(
        { pageStart, pageLimit, blocks: true },
        () => setLoading(false),
        (err) => {
          setLoading(false);
          handlePopupFlagError(err);
        },
      ),
    );
  };

  const handleMoveColumn = (item, sourceColumn, destinationColumn) => {
    const { data } = item;
    data.status = destinationColumn.id;
    dispatch(
      updateProject(
        data,
        () => null,
        (err) => {
          handlePopupFlagError(err);
        },
      ),
    );
  };

  const createProject = () => {
    dispatch(
      openModal(Modals.CREATE_PROJECT, {
        onSuccess: (project) => {
          dispatch(requestProjectSuccess(project));
        },
      }),
    );
  };

  return (
    <PageStage appearance="fullWidth">
      <StageHeader
        noMarginTop
        title="Work orders"
        subtitle={<Text>Last updated - {loading ? <Loading inline /> : lastUpdate && lastUpdate.fromNow()}</Text>}
        action={
          <>
            <Popover content={<Text>Coming soon!</Text>}>
              <SecondaryButton icon="/images/icons/tv_2.svg">TV mode</SecondaryButton>
            </Popover>
            <Restrict capability={Capabilities.PROJECT_CREATE}>
              <SecondaryButton onClick={createProject} icon="/images/icons/plus_1.svg">
                New job
              </SecondaryButton>
            </Restrict>
          </>
        }
      />
      <KanbanBoard columns={columns} onMoveColumn={handleMoveColumn} />
    </PageStage>
  );
};
