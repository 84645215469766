import { StatusPill, StatusPillProps } from "components/shared/pills/StatusPill";

type GreenStatusPillProps = StatusPillProps;

export const GreenStatusPill = (props: GreenStatusPillProps) => {
  return (
    <StatusPill
      {...props}
      textColor="text-green-800"
      backgroundColor="bg-green-100"
      iconBackgroundColor="bg-green-500"
    />
  );
};
