//@ts-nocheck
import React from "react";
import ReactSVG from "react-svg";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "./icon.module.scss";

const Icon = ({ src, size, color, className, onClick }) => {
  return (
    <ReactSVG
      src={src}
      className={classNames(style.icon, style[color], style[size], className)}
      onClick={onClick}
      wrapper="span"
    />
  );
};

Icon.defaultProps = {
  size: "small",
  color: "font",
};

Icon.propTypes = {
  color: PropTypes.oneOf(["theme", "primary", "success", "danger", "warning", "font", "white"]),
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge", ""]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Icon;
