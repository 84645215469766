import { useState } from "react";
import { usePopper } from "react-popper";
import { Combobox } from "@headlessui/react";
import { SearchIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import styled from "styled-components";
import { useSelector } from "store";
import { selectLocationsList } from "store/reducers";
import { TLocation } from "types";
import { VehiclesFilterMenu } from "components/shared/features/vehicles/VehiclesFilterMenu/VehiclesFilterMenu";

const PrettyComboboxInput = styled.input`
  :placeholder-shown {
    text-overflow: ellipsis;
  }
`;

type SetLocationCardProps = {
  value?: TLocation;
  onChange: (location?: TLocation) => void;
};

export const MapLocationController = ({ value, onChange }: SetLocationCardProps) => {
  const [query, setQuery] = useState("");
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<any | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    strategy: "absolute",
  });
  const locations = useSelector(selectLocationsList);

  const filteredLocations =
    query === ""
      ? []
      : locations.filter((location: TLocation) => {
          return location.name.toLowerCase().includes(query.toLowerCase());
        });

  const changeLocation = (value?: TLocation | null) => {
    onChange(value || undefined);
  };

  return (
    <div className="transform rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all z-10">
      <Combobox value={null} onChange={changeLocation} nullable>
        <div ref={setReferenceElement} className="flex relative">
          <div className="relative flex flex-grow items-stretch focus-within:z-10">
            <SearchIcon
              className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <Combobox.Input
              as={PrettyComboboxInput}
              className={classNames(
                "h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 focus:ring-0 sm:text-sm",
                value ? "placeholder-gray-800" : "placeholder-gray-400",
              )}
              placeholder={value ? value.name : "Search by location..."}
              onChange={(event) => setQuery(event.target.value)}
              value={query}
            />
          </div>
          <VehiclesFilterMenu />
        </div>

        {filteredLocations.length > 0 && (
          <Combobox.Options
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="max-h-72 mt-1 divide-y divide-gray-100 w-full scroll-py-2 overflow-y-auto py-2 px-0 text-sm text-gray-800 rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5"
          >
            {filteredLocations.map((location) => (
              <Combobox.Option
                key={location.id}
                value={location}
                className={({ active }) =>
                  classNames("cursor-default select-none px-4 py-2", active && "bg-primary-600 text-white")
                }
              >
                {location.name}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}

        {query !== "" && filteredLocations.length === 0 && (
          <p className="p-4 text-sm text-gray-500">No locations found.</p>
        )}
      </Combobox>
    </div>
  );
};
