//@ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { pageBlocksChange, showMediaMenuFor } from "lib/actions/blocks";
import BlockAPI from "lib/api/blocks";
import Files from "lib/api/files";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getPageBlocks } from "lib/reducers/blocks.selectors";
import { FileInput } from "components/Input";
import { updateBlock } from "components/Sheet/utils";
import style from "../sheet.module.scss";

const FileBlockMediaMenu = ({ block, className }) => {
  const dispatch = useDispatch();
  const pageBlocks = useSelector(getPageBlocks);

  const handleUpload = async (_, files) => {
    try {
      const file = files[0];
      const { id, fileName, url, mimeType, size } = await Files.upload(file);
      const updatedBlock = {
        ...block,
        properties: {
          fileId: id,
          fileName,
          url,
          mimeType,
          size,
        },
      };
      dispatch(pageBlocksChange(updateBlock(pageBlocks, updatedBlock)));
      dispatch(showMediaMenuFor(null));
      await BlockAPI.updateBlock(block.id, updatedBlock);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <form className={style.fileMediaMenu}>
      <FileInput input={{ name: "file" }} value={[]} onChange={handleUpload} />
    </form>
  );
};

FileBlockMediaMenu.defaultProps = {};

FileBlockMediaMenu.propTypes = {
  className: PropTypes.string,
};

export default FileBlockMediaMenu;
