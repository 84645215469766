import React from "react";
import classNames from "classnames";
import { FixLater } from "types";
import RadioOption from "components/Input/RadioInput/RadioOption";
import style from "./radioInput.module.scss";

type RadioInputOption = {
  value: FixLater;
  label: string;
};

type RadioInputProps = {
  value: FixLater;
  options: RadioInputOption[];
  className?: string;
  onChange?: FixLater;
};

const RadioInput = ({ value, options, className, onChange }: RadioInputProps) => {
  return (
    <div className={classNames(style.options, className)}>
      {options.map((option) => {
        return <RadioOption key={option.value} option={option} value={value} onChange={onChange} />;
      })}
    </div>
  );
};

export default RadioInput;
