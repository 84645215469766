//@ts-nocheck
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { requestStoreItems } from "lib/actions/marketplace";
import { getCartItems } from "lib/reducers/cart.selectors";
import { getStoreItems } from "lib/reducers/storeItems.selectors";
import { SmartTable } from "components/Table/SmartTable";
import { Text } from "components/Typography";
import ShopItemPrice from "pages/apps/Shop/shared/ShopItemPrice/ShopItemPrice";
import ShopItemPrimaryImage from "pages/apps/Shop/shared/ShopItemPrimaryImage/ShopItemImage";
import styles from "./ShopCheckoutCartTable.module.scss";

export const ShopCheckoutCartTable = () => {
  const dispatch = useDispatch();
  const storeItems = useSelector(getStoreItems);
  const cartItems = useSelector(getCartItems);

  const rowData = useMemo(() => {
    const cartItemIDs = Object.keys(cartItems);
    return cartItemIDs
      .map((cartItemID) => {
        const item = storeItems[cartItemID];
        return item
          ? {
              ...item,
              cartQuantity: cartItems[cartItemID],
            }
          : null;
      })
      .filter(Boolean);
  }, [storeItems, cartItems]);
  const columns = useMemo(() => {
    return [
      {
        id: "image",
        Cell: ({ row }) => {
          const { id, name } = row.original;
          return (
            <Link to={`/apps/shop/items/${id}`} className={styles.ShopCheckoutCartTableImage}>
              <ShopItemPrimaryImage itemID={id} />
            </Link>
          );
        },
      },
      {
        Header: "Product",
        Cell: ({ row }) => {
          const { name } = row.original;
          return <Text>{name}</Text>;
        },
      },
      {
        Header: "Price",
        Cell: ({ row }) => {
          const { id } = row.original;
          return <ShopItemPrice itemID={id} appearance="inline" />;
        },
      },
      {
        Header: "Quantity",
        Cell: ({ row }) => {
          const { cartQuantity } = row.original;
          return <div>{cartQuantity}</div>;
        },
      },
      {
        Header: "Subtotal",
        Cell: ({ row }) => {
          const { id, cartQuantity } = row.original;
          return <ShopItemPrice itemID={id} quantity={cartQuantity} appearance="compact" />;
        },
      },
    ];
  }, []);

  useEffect(() => {
    const cartItemIDs = Object.keys(cartItems);
    dispatch(
      requestStoreItems({
        itemId: cartItemIDs.join(","),
        pageLimit: cartItemIDs.length,
      }),
    );
  }, [cartItems]);

  return <SmartTable columns={columns} data={rowData} />;
};
