import { Menu } from "@headlessui/react";
import { TrashIcon, PauseIcon, DotsVerticalIcon, MailIcon, PlayIcon } from "@heroicons/react/outline";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import UsersAPI from "lib/api/users";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { deleteUser, selectUserByID, updateUser } from "store/reducers";
import { TransparentButton } from "components/shared/buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import { DropdownMenu, DropdownMenuItemAction } from "components/shared/menus";

type TeamMemberTableRowDropdownProps = {
  userId: number;
  onDelete?: () => void;
};

export const TeamMemberTableRowDropdown = ({ userId, onDelete }: TeamMemberTableRowDropdownProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const user = useSelector(selectUserByID(userId));

  const resendInvitation = async () => {
    try {
      await UsersAPI.inviteUser({
        email: user.email,
        roleId: user.roleId,
      });
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Team member invited",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const toggleEnabled = async () => {
    try {
      await dispatch(
        updateUser({
          ...user,
          enabled: !user.enabled,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: `Team member ${user.enabled ? "disabled" : "enabled"}`,
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const deleteAccount = () => {
    openModal(
      <ConfirmModal
        actionText="Remove team member"
        description="Are you sure you want to remove this team member? You can re-invite them later."
        actionColor="warning"
        onConfirm={async () => {
          try {
            await dispatch(deleteUser(userId)).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Team member was removed",
              }),
            );
            onDelete && onDelete();
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const actions: DropdownMenuItemAction[][] = [
    [
      user.dateConfirmed
        ? {
            label: user.enabled ? "Disable" : "Enabled",
            icon: user.enabled ? PauseIcon : PlayIcon,
            onClick: toggleEnabled,
          }
        : {
            label: "Resend invitation",
            icon: MailIcon,
            onClick: resendInvitation,
          },
    ],
    [
      {
        label: "Delete",
        icon: TrashIcon,
        onClick: deleteAccount,
      },
    ],
  ];

  return (
    <DropdownMenu items={actions}>
      <Menu.Button as={TransparentButton} trailingIcon={DotsVerticalIcon} padding="px-2" />
    </DropdownMenu>
  );
};
