//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FixLater } from "types";
import classes from "./table.module.scss";

const TableBody = ({ className, children, ...otherProps }: FixLater) => (
  <tbody {...otherProps} className={classNames(classes.tableBody, className)}>
    {children}
  </tbody>
);

TableBody.defaultProps = {
  className: "",
};

TableBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default TableBody;
