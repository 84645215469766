//@ts-nocheck
import React, { useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "pages/authentication/Login/passwordRequirements.module.scss";

const PasswordRequirements = ({ password, onValidate }) => {
  const minimumPasswordLength = 6;
  const hasUpperCase = () => /[A-Z]/.test(password);
  const hasNumbers = () => /[0-9]/.test(password);
  const hasCharacterLength = (len) => password.length >= len;
  useEffect(() => {
    if (typeof onValidate === "function") {
      onValidate(hasUpperCase() && hasNumbers() && hasCharacterLength(minimumPasswordLength));
    }
  }, [password]);
  return (
    <ul className={style.list}>
      <li className={classNames({ [style.valid]: hasUpperCase() })}>At least 1 upper case letter (A-Z)</li>
      <li className={classNames({ [style.valid]: hasNumbers() })}>At least 1 number (0-9)</li>
      <li
        className={classNames({
          [style.valid]: hasCharacterLength(minimumPasswordLength),
        })}
      >
        At least 6 characters
      </li>
    </ul>
  );
};

PasswordRequirements.defaultProps = {
  password: "",
};

PasswordRequirements.propTypes = {
  password: PropTypes.string.isRequired,
  onValidate: PropTypes.func,
};

export default PasswordRequirements;
