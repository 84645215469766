import styled from "styled-components";
import Loading from "components/Loading";
import PageStage from "components/stages/PageStage";

const StyledPage = styled(PageStage)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const PageLoading = () => {
  return (
    <StyledPage>
      <Loading />
    </StyledPage>
  );
};
