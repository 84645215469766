import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useToolStorage } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { showPopupFlag } from "lib/actions/userInterface";
import AlertRules from "lib/constants/AlertRules";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  deleteStorageAlert,
  fetchAlertById,
  fetchStorageAlertById,
  selectAlertByID,
  selectStorageAlertByID,
  selectStorageByID,
  updateAlert,
} from "store/reducers";
import { AlertEventKey, TAlert, TStorage } from "types";
import { DataGrid, DataItem } from "components/DataGrid";
import { Header, Paragraph } from "components/Typography";
import { AlertRecipientForm, AlertRemoveButton, AlertRulesForm, PageLoading, VSpace } from "components/shared";
import PageStage from "components/stages/PageStage";

const determineRulesVisible = (storageType: TStorage["type"]): AlertEventKey[] => {
  console.log("storageType", storageType);
  switch (storageType) {
    case "toolbox":
      return [
        AlertRules.STORAGE_ACCESS_GRANTED,
        AlertRules.STORAGE_ACCESS_DENIED,
        AlertRules.STORAGE_ONLINE,
        AlertRules.STORAGE_OFFLINE,
      ];
    case "tool_room":
      return [
        AlertRules.DOORWAY_UNASSIGNED_ASSET_DETECTED,
        AlertRules.DOORWAY_ASSIGNED_ASSET_DETECTED,
        AlertRules.STORAGE_ONLINE,
        AlertRules.STORAGE_OFFLINE,
      ];
    case "case_scanner":
      return [AlertRules.CHECK_IN_WEIGHT_MISMATCH, AlertRules.STORAGE_ONLINE, AlertRules.STORAGE_OFFLINE];
    case "doorway":
      return [AlertRules.DOORWAY_UNASSIGNED_ASSET_DETECTED, AlertRules.DOORWAY_ASSIGNED_ASSET_DETECTED];
    default:
      return [];
  }
};

export const StorageAlertPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storageAlertID, toolRoomID, caseScannerID, doorwayID } = useParams();
  const { storageId } = useToolStorage();
  const entity = useSelector(selectStorageAlertByID(storageAlertID || ""));
  const storage = useSelector(selectStorageByID(storageId || ""));
  const alert = useSelector(selectAlertByID(entity?.alertId || ""));

  useEffect(() => {
    if (!storageId) return;
    if (!storageAlertID) return;
    dispatch(
      fetchStorageAlertById({
        id: storageAlertID,
        storageId,
      }),
    );
  }, [storageId, storageAlertID]);

  useEffect(() => {
    if (!entity?.alertId) return;
    dispatch(fetchAlertById(entity?.alertId));
  }, [entity?.alertId]);

  const saveChanges = (name: keyof TAlert) => {
    return async (value: string) => {
      try {
        if (!alert) return;
        await dispatch(
          updateAlert({
            ...alert,
            [name]: value,
          }),
        ).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Changes saved",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const handleRemoveAlert = async () => {
    try {
      if (!storage) return;
      if (!entity) return;
      await dispatch(
        deleteStorageAlert({
          id: entity.id,
          storageId: storage.id,
        }),
      ).unwrap();

      let url = "/apps/storage";
      switch (storage.type) {
        case "toolbox":
          url = `/apps/storage/toolboxes/${storage.id}/alerts`;
          break;
        case "tool_room":
          url = `/apps/storage/tool_rooms/${toolRoomID}/alerts`;
          break;
        case "case_scanner":
          url = `/apps/storage/case_scanners/${caseScannerID}/alerts`;
          break;
        case "doorway":
          url = `/apps/tracking/doorways/${doorwayID}/alerts`;
          break;
      }

      navigate(url, {
        replace: true,
      });
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (!entity || !alert || !storage) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <Header canEdit placeholder="Enter alert name" value={alert.name} onConfirm={saveChanges("name")} />
      <Paragraph
        canEdit
        size="large"
        placeholder="Enter alert description"
        value={alert.description}
        onConfirm={saveChanges("description")}
      />
      <VSpace>
        <AlertRulesForm alertId={alert.id} ruleKeys={determineRulesVisible(storage?.type)} />
        <AlertRecipientForm alertId={alert.id} />
        <DataGrid>
          <DataItem label="Remove alert" value={<AlertRemoveButton onClick={handleRemoveAlert} />} />
        </DataGrid>
      </VSpace>
    </PageStage>
  );
};
