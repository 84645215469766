import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { AlertEventKey, TAlertRule, UUID } from "types";

const selectState = (state: RootStore) => state.alertRules;

export const selectAlertRules = createSelector(selectState, (state) => state.data);

export const selectAlertRulesList = createSelector(selectAlertRules, (data) => Object.values(data));

export const selectAlertRuleByID = (id: UUID) =>
  createSelector(selectAlertRules, (data): TAlertRule | undefined => data[id]);

export const selectAlertRuleByAlertID = (alertId: UUID, key: AlertEventKey) =>
  createSelector(selectAlertRulesList, (list) =>
    list.find((entity) => entity.alertId === alertId && entity.key === key),
  );
