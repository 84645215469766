//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as actions from "../lib/actions/reports";
import mergeProps from "./mergeProps";
import mergeStateStore from "./mergeStateStore";

export default function withReports(WrappedComponent) {
  class Component extends React.Component {
    static propTypes = {
      actions: PropTypes.shape({
        requestTemplates: PropTypes.func,
        requestReports: PropTypes.func,
        requestReportEvidence: PropTypes.func,
      }),
      stateStore: PropTypes.shape({
        reports: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
          paging: PropTypes.shape({
            pageStart: PropTypes.number,
            pageLimit: PropTypes.number,
            nextPageStart: PropTypes.string,
            previousPageStart: PropTypes.string,
          }),
        }),
        templates: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
          paging: PropTypes.shape({
            pageStart: PropTypes.number,
            pageLimit: PropTypes.number,
            nextPageStart: PropTypes.string,
            previousPageStart: PropTypes.string,
          }),
        }),
        report: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.object,
        }),
        template: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.object,
        }),
      }),
    };
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToProps = (state) =>
    mergeStateStore(state, {
      reports: state.reports.reports,
      templates: state.reports.templates,
      report: state.reports.report,
      evidence: state.reports.evidence,
      template: state.reports.template,
    });
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  });
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
}
