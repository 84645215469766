//@ts-nocheck
import React, { useState } from "react";
import get from "lodash/get";
import Logger from "lib/Logger";
import { showPopupFlag } from "lib/actions/userInterface";
import UsersAPI from "lib/api/users";
import store from "store";
import { TextInput } from "components/Input";
import { Paragraph } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import SelectRoleInput from "components/inputs/SelectRoleInput";
import { Modal, ModalContent, ModalHeader } from "components/modals/Modal";
import { HSpace, ModalPrimaryActionButton, ModalSecondaryActionButton, VSpace } from "components/shared";

const InviteUser = ({ modal, onClose }) => {
  const { onSuccess } = modal.props;
  const [values, setValues] = useState({ email: "", role: null });
  const [submitting, isSubmitting] = useState(false);

  const handleChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      isSubmitting(true);
      await UsersAPI.inviteUser({
        ...values,
        roleId: values.role.id,
      });
      store.dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Team member invited",
        }),
      );
      onClose();
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } catch (err) {
      Logger.error(err);
      isSubmitting(false);
      const message = get(err, "response.body.message", "Something went wrong");
      store.dispatch(
        showPopupFlag({
          appearance: "error",
          title: message,
        }),
      );
    }
  };

  const valid = values.email && values.role;

  return (
    <Modal>
      <ModalHeader title="Invite team member" />
      <ModalContent>
        <VSpace>
          <Paragraph className="text-muted">Your teammate will be invited to join Automify.</Paragraph>
          <FieldGroup label="Email address">
            <TextInput
              input={{
                name: "email",
                placeholder: "Enter email address",
                autoFocus: true,
              }}
              value={values.email}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Role">
            <SelectRoleInput name="role" placeholder="Select user role" value={values.role} onChange={handleChange} />
          </FieldGroup>
        </VSpace>
        <HSpace>
          <ModalSecondaryActionButton onClick={onClose}>Cancel</ModalSecondaryActionButton>
          <ModalPrimaryActionButton disabled={!valid || submitting} onClick={handleSubmit}>
            Send invite
          </ModalPrimaryActionButton>
        </HSpace>
      </ModalContent>
    </Modal>
  );
};

export default InviteUser;
