//@ts-nocheck
import get from "lodash/get";
import { createSelector } from "reselect";

export const getReport = createSelector(
  (state) => state.reports,
  (state = {}) => {
    return state.report.data;
  },
);

export const getReportComponents = createSelector(
  (state) => state.reports,
  (state = {}) => {
    return get(state.report, "data.components", {}) || {};
  },
);

export const getReportExports = createSelector(
  (state) => state.reports,
  (state = {}) => {
    return state.exports.data;
  },
);

export const getReportExportsPaging = createSelector(
  (state) => state.reports,
  (state = {}) => {
    return state.exports.paging;
  },
);
