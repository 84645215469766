//@ts-nocheck
import React from "react";
import AppComingSoon from "components/AppComingSoon";
import PageStage from "components/stages/PageStage";

const AccessControlDashboard = () => {
  return (
    <PageStage>
      <AppComingSoon
        title="Access Control is coming soon"
        description="The Access Control App will enable you configure access control for your toolboxes"
      />
    </PageStage>
  );
};

AccessControlDashboard.defaultProps = {};

AccessControlDashboard.propTypes = {};

export default AccessControlDashboard;
