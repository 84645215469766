//@ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { openModal } from "lib/actions/modal";
import Modals from "lib/constants/Modals";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import PageStage from "components/stages/PageStage";
import ShopInventory from "pages/apps/ShopManager/Dashboard/components/ShopInventory";

const ShopManagerDashboard = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { pathname: url } = useLocation();

  const createProduct = () => {
    dispatch(
      openModal(Modals.CREATE_STORE_ITEM, {
        onSuccess: (storeItem) => {
          history(`${url}/items/${storeItem.id}`);
        },
      }),
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Shop manager"
        subtitle="Manage products, customers, and view shopping reports."
        action={
          <>
            <Link to={`${url}/import`}>
              <SecondaryButton icon="/images/icons/import_1.svg">Import products</SecondaryButton>
            </Link>
            <SecondaryButton onClick={createProduct} icon="/images/icons/add_to_cart_1.svg">
              Add store item
            </SecondaryButton>
          </>
        }
      />
      <ShopInventory />
    </PageStage>
  );
};

export default ShopManagerDashboard;
