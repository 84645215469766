import { useSelector } from "store";
import { selectLocationByID, selectDoorwayByID } from "store/reducers";
import { TableCell, TableRow } from "components/Table";
import { TableLink } from "components/Table/TableLink/TableLink";
import { StorageStatusPill } from "components/shared/features/storages";

type ListDoorwaysTableRowProps = {
  doorwayId: string;
};

export const ListDoorwaysTableRow = ({ doorwayId }: ListDoorwaysTableRowProps) => {
  const entity = useSelector(selectDoorwayByID(doorwayId));
  const location = useSelector(selectLocationByID(entity?.storage?.locationId || ""));
  if (!entity) return null;
  return (
    <TableRow>
      <TableCell>
        <StorageStatusPill storageId={entity?.storageId} />
      </TableCell>
      <TableCell>
        <TableLink to={`/apps/tracking/doorways/${doorwayId}`}>{entity.storage?.name}</TableLink>
      </TableCell>
      <TableCell>{location ? location.name : "N/A"}</TableCell>
    </TableRow>
  );
};
