//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { Table, TableCell, TableHeader, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import TablePagination from "components/Table/TablePagination";
import { Anchor } from "components/Typography";
import Resolve from "../../../lib/Resolve";

const ReadersTable = ({ readers, paging, handlePrevPage, handleNextPage, handleLimitChange }) => {
  return (
    <div>
      <Table>
        <TableHeader>
          <TableHeadCell>Name</TableHeadCell>
          <TableHeadCell>MAC Address</TableHeadCell>
          <TableHeadCell>Location</TableHeadCell>
        </TableHeader>
        <TableBody>
          {readers.map(({ name, macAddress, locationId }) => (
            <TableRow key={macAddress}>
              <TableCell>
                <Anchor to={`/configuration/readers/${macAddress}`}>{name}</Anchor>
              </TableCell>
              <TableCell>{macAddress}</TableCell>
              <TableCell>{Resolve.resolveLocationName(locationId)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        paging={paging}
        onPrevPage={handlePrevPage}
        onNextPage={handleNextPage}
        onChangeLimit={handleLimitChange}
      />
    </div>
  );
};

ReadersTable.defaultProps = {};

ReadersTable.propTypes = {
  readers: PropTypes.array,
  paging: PropTypes.object,
  handlePrevPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleLimitChange: PropTypes.func,
};

export default ReadersTable;
