import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { handleThunkError, ThunkError } from "store/utils/thunk";
import {
  CreateRagControllerRequest,
  DataResponse,
  GetRagControllerStatsResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  SynchroniseRagControllerRagsRequest,
  SynchroniseRagControllerRagsResponse,
  TRagController,
  UpdateRagControllerRequest,
  UUID,
} from "types";

type FetchRagControllersParams = PaginationQueryParams & {};

export const fetchRagControllers = createAsyncThunk(
  "ragControllers/fetchRagControllers",
  async (params: FetchRagControllersParams): Promise<{ results: TRagController[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TRagController[]>>("/rag_controllers", params);
    return { results: data, paging: metadata };
  },
);

export const fetchRagControllerById = createAsyncThunk(
  "ragControllers/fetchRagControllerById",
  async (id: UUID): Promise<TRagController> => {
    const { data } = await API.GET<DataResponse<TRagController>>(`/rag_controllers/${id}`);
    return data;
  },
);

export const fetchRagControllerStatsById = createAsyncThunk(
  "ragControllers/fetchRagControllerStatsById",
  async (id: UUID): Promise<GetRagControllerStatsResponse> => {
    const { data } = await API.GET<DataResponse<GetRagControllerStatsResponse>>(`/rag_controllers/${id}/stats`);
    return data;
  },
);

export const createRagController = createAsyncThunk(
  "ragControllers/createRagController",
  async (request: CreateRagControllerRequest): Promise<TRagController> => {
    const { data } = await API.POST<CreateRagControllerRequest, DataResponse<TRagController>>(
      "/rag_controllers",
      request,
    );
    return data;
  },
);

export const synchroniseRagControllerRags = createAsyncThunk<
  SynchroniseRagControllerRagsResponse,
  SynchroniseRagControllerRagsRequest,
  ThunkError
>("ragControllers/synchroniseRagControllerRags", async (request, { rejectWithValue }) => {
  try {
    const { data } = await API.POST(`/rag_controllers/${request.ragControllerId}/synchronise`, request);
    return data;
  } catch (err) {
    return handleThunkError(rejectWithValue, err);
  }
});

export const updateRagController = createAsyncThunk(
  "ragControllers/updateRagController",
  async (request: UpdateRagControllerRequest): Promise<TRagController> => {
    const { data } = await API.PUT<UpdateRagControllerRequest, DataResponse<TRagController>>(
      `/rag_controllers/${request.id}`,
      request,
    );
    return data;
  },
);

export const deleteRagController = createAsyncThunk<void, UUID, ThunkError>(
  "ragControllers/deleteRagController",
  async (id: UUID, { rejectWithValue }) => {
    try {
      await API.DELETE(`/rag_controllers/${id}`);
    } catch (err) {
      return handleThunkError(rejectWithValue, err);
    }
  },
);
