import { UUID } from "types";
import StageHeader from "components/StageHeader";
import { CaseScannerUsageTable } from "components/shared";

type CaseScannerUsagePageProps = {
  caseScannerId: UUID;
};

export const CaseScannerUsagePage = ({ caseScannerId }: CaseScannerUsagePageProps) => {
  return (
    <>
      <StageHeader title="Recent scans" />
      <CaseScannerUsageTable caseScannerId={caseScannerId} />
    </>
  );
};
