import { createAction } from "@reduxjs/toolkit";
import { TAssetIssue } from "types";

export const streamCreateAssetIssue = createAction("assetIssues/streamCreate", (data: TAssetIssue) => ({
  payload: data,
}));

export const streamUpdateAssetIssue = createAction("assetIssues/streamUpdate", (data: TAssetIssue) => ({
  payload: data,
}));
