//@ts-nocheck
import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { LayoutHelmet } from "components/layouts/LayoutHelmet/LayoutHelmet";
import AutoDismissFlags from "components/modals/PopupFlag/AutoDismissFlags";
import RootModal from "components/modals/RootModal/RootModal";
import RootSidebar from "components/sidebars/RootSidebar";
import style from "./layout.module.scss";

const Layout = styled.div`
  min-height: 100vh;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

const ManagerLayout = () => {
  return (
    <Layout>
      <AutoDismissFlags key="layoutFlags" />
      <RootModal key="rootModal" />
      <RootSidebar key="rootSidebar" />
      <LayoutHelmet />
      <div className={style.stage}>
        <Outlet />
      </div>
    </Layout>
  );
};

export default ManagerLayout;
