import { useModal } from "effects";
import styled from "styled-components";
import { useSelector } from "store";
import { selectLocations } from "store/reducers";
import { selectVehiclesFilters, selectVehiclesPage } from "store/reducers/vehicles";
import { TVehicle, UUID } from "types";
import { VehicleAssignmentsModal } from "components/shared/features/vehicles/VehicleAssignmentsModal/VehicleAssignmentsModal";
import { VehicleStatusIndicator } from "components/shared/features/vehicles/VehicleStatusIndicator/VehicleStatusIndicator";

const Image = styled.div<{ src: string; vehicleType?: string }>`
  background: url("${({ src }) => src}") no-repeat center;
  background-size: cover;
  position: absolute;

  // Be sure to normalise the vehicle image rather than changing these dimensions.
  height: 5rem;
  width: 5.5rem;
  right: 0;
  bottom: 0;
`;

export const VehicleList = () => {
  const { openModal } = useModal();
  const locations = useSelector(selectLocations);
  const filters = useSelector(selectVehiclesFilters);
  const vehicles = useSelector(selectVehiclesPage)
    .filter(({ available, liveStream, toolsAssigned, ragsAssigned }) => {
      if (!(available && liveStream)) return false;
      if (!filters.statuses.length) return true;
      return filters.statuses.indexOf(toolsAssigned + ragsAssigned === 0 ? "ready" : "busy") !== -1;
    })
    .sort((a, b) => b.toolsAssigned - a.toolsAssigned);

  const formatDescription = (vehicle: TVehicle): string => {
    if (vehicle.toolsAssigned === 0 && vehicle.ragsAssigned === 0) {
      return "Nothing outstanding";
    }
    if (vehicle.toolsAssigned > 0 && vehicle.ragsAssigned > 0) {
      return `${vehicle.toolsAssigned} tools and ${vehicle.ragsAssigned} rags outstanding`;
    }
    return vehicle.toolsAssigned > 0
      ? `${vehicle.toolsAssigned} tools outstanding`
      : `${vehicle.ragsAssigned} rags outstanding`;
  };

  const handleOpenAssignmentListModal = (vehicleId: UUID) => {
    return () => {
      openModal(<VehicleAssignmentsModal vehicleId={vehicleId} />);
    };
  };

  return (
    <ul className="divide-y divide-gray-200 pl-0">
      {vehicles.map((vehicle: TVehicle) => (
        <li
          key={vehicle.id}
          className="flex py-4 pr-24 relative overflow-hidden items-center hover:bg-gray-50 cursor-pointer"
          onClick={handleOpenAssignmentListModal(vehicle.id)}
        >
          <Image src={vehicle.imageUrl} vehicleType={vehicle.vehicleType} />
          <div className="ml-7">
            <VehicleStatusIndicator ready={vehicle.toolsAssigned === 0 && vehicle.ragsAssigned === 0} />
          </div>
          <div className="ml-4">
            <p className="text-sm font-medium text-gray-900">{vehicle.registration}</p>
            {vehicle.locationId && <p className="text-sm text-gray-400">{locations[vehicle.locationId]?.name}</p>}
            <p className="text-sm mt-1 text-gray-500">{formatDescription(vehicle)}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};
