//@ts-nocheck
import React from "react";
import dayjs from "dayjs";
import Resolve from "lib/Resolve";
import { requestAccessPolicy } from "lib/actions/storages";
import { showPopupFlag } from "lib/actions/userInterface";
import API from "lib/api/accessControl";
import AccessScopes from "lib/constants/AccessScopes";
import store from "store";
import { DangerButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import RadioInput from "components/Input/RadioInput";
import { Paragraph, Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import SelectAccessScope from "components/inputs/SelectAccessScope";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import style from "../CreateAccessRuleModal/modal.module.scss";
import parentStyle from "../RootModal/modal.module.scss";

const AccessModalRenderScope = ({ rule }) => {
  switch (rule.scope) {
    case AccessScopes.GROUP:
      return <Text className="capitalise">Group: {rule.valueString}</Text>;
    case AccessScopes.ROLE:
      return <Text>Role: {Resolve.resolveRoleName(rule.valueInt)}</Text>;
    case AccessScopes.PROJECT:
      return <Text>Project {Resolve.resolveProjectName(rule.valueInt)}</Text>;
    case AccessScopes.USER:
      return <Text>User: {Resolve.resolveUserFullName(rule.valueInt)}</Text>;
    case AccessScopes.KEYCARD:
      return <Text>Keycard: {rule.valueString}</Text>;
    default:
      return null;
  }
};

const ViewAccessRuleModal = ({ modal, onClose }) => {
  const { storage, policy, grant } = modal.props;

  const handleRemove = async () => {
    try {
      await API.removePolicyGrant(policy.id, grant.id);
      store.dispatch(requestAccessPolicy(policy.id));
      store.dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Access revoked",
        }),
      );
      onClose();
    } catch (err) {
      console.error(err);
      store.dispatch(
        showPopupFlag({
          appearance: "error",
          title: "Failed to revoke access",
        }),
      );
    }
  };

  return (
    <Modal className={parentStyle.wideModal}>
      <ModalHeader title="Grant access" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Access scope">
            <SelectAccessScope name="scope" value={grant.rule.scope} disabled={true} />
            <br />
            <AccessModalRenderScope rule={grant.rule} />
          </FieldGroup>
          {grant.rule.scope !== AccessScopes.KEYCARD && (
            <FieldGroup label="Methods allowed">
              <Paragraph className="text-muted">Select which access methods will be active for the rule</Paragraph>
              <div className={style.methods}>
                <RadioInput options={[{ value: true, name: "Keycard" }]} value={grant.allowKeycard} />
                <RadioInput options={[{ value: true, name: "Mobile app" }]} value={grant.allowMobileApp} />
              </div>
            </FieldGroup>
          )}
          <FieldGroup label="Access period">
            <DataGrid>
              <DataItem label="Active from">
                <Text>
                  {grant.activeDate !== "0001-01-01T00:00:00Z"
                    ? dayjs(grant.activeDate).format("dddd, MMMM D, YYYY h:mm A")
                    : "Not specified"}
                </Text>
              </DataItem>
              <DataItem label="Expire On">
                <Text>
                  {grant.expireDate !== "0001-01-01T00:00:00Z"
                    ? dayjs(grant.expireDate).format("dddd, MMMM D, YYYY h:mm A")
                    : "No expiry"}
                </Text>
              </DataItem>
            </DataGrid>
          </FieldGroup>
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <DangerButton onClick={handleRemove}>Revoke Access</DangerButton>
      </ModalFooter>
    </Modal>
  );
};

export default ViewAccessRuleModal;
