//@ts-nocheck
import React, { useMemo } from "react";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import { useSelector } from "store";
import Loading from "components/Loading";
import styles from "./ShopItemPrimaryImage.module.scss";

const ShopItemPrimaryImage = ({ itemID }) => {
  const item = useSelector(getStoreItemByID(itemID));
  const sortedImages = useMemo(() => {
    return (
      item?.images?.sort((left, right) => {
        if (left.order < right.order) return -1;
        if (left.order > right.order) return 1;
        return 0;
      }) || []
    );
  }, [item]);
  const primaryImage = sortedImages.length ? sortedImages[0] : null;

  return primaryImage ? <img src={primaryImage.url} alt="" className={styles.ShopItemPrimaryImage} /> : <Loading />;
};

export default ShopItemPrimaryImage;
