import { useEffect, useState } from "react";
import moment from "moment";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  assignUserKeycard,
  fetchUserKeycardById,
  selectUserByID,
  selectUserKeycardByKeycardID,
  unAssignUserKeycard,
} from "store/reducers";
import { TUser } from "types";
import { SecondaryButton } from "components/Buttons";
import Loading from "components/Loading";
import { Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import UsersInput from "components/inputs/UsersInput";
import { VSpace } from "components/shared";

type KeycardAssignmentProps = {
  keycardId: string;
};

export const KeycardAssignment = ({ keycardId }: KeycardAssignmentProps) => {
  const dispatch = useDispatch();
  const userKeycard = useSelector(selectUserKeycardByKeycardID(keycardId));
  const user = useSelector(selectUserByID(userKeycard?.userId || 0));
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!userKeycard) {
      fetch();
    }
  }, [userKeycard, keycardId]);

  const fetch = async () => {
    try {
      setLoading(true);
      await dispatch(fetchUserKeycardById(keycardId)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    } finally {
      setLoading(false);
    }
  };

  const setAssignment = async (_: string, value: TUser) => {
    if (!value) {
      return await removeAssignment();
    }
    try {
      await dispatch(
        assignUserKeycard({
          keycardId,
          userId: value.id,
        }),
      ).unwrap();
      // Fetch the assignment
      await fetch();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Keycard assigned",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const removeAssignment = async () => {
    try {
      await dispatch(unAssignUserKeycard(keycardId)).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Keycard unassigned",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (loading) {
    return <Loading size="medium" />;
  }

  return (
    <div>
      {userKeycard ? (
        <VSpace>
          <FieldGroup label="Team member" value={<Text>{`${user?.forename} ${user?.surname}`}</Text>} />
          <FieldGroup label="Date assigned" value={<Text>{moment(userKeycard.dateAssigned).format("lll")}</Text>} />
          <FieldGroup
            label="Actions"
            value={
              <SecondaryButton fitContent icon="/images/icons/trash.svg" onClick={removeAssignment}>
                Remove assignment
              </SecondaryButton>
            }
          />
        </VSpace>
      ) : (
        <FieldGroup label="Assign team member">
          <UsersInput name="user" onChange={setAssignment} />
        </FieldGroup>
      )}
    </div>
  );
};
