import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import {
  fetchAssetIssues,
  selectAssetIssueProblems,
  selectAssetIssuesLoading,
  selectAssetIssuesPage,
  selectAssetIssuesPaging,
} from "store/reducers";
import { TAssetIssue } from "types";
import Popover from "components/Popover";
import StageHeader from "components/StageHeader";
import { SmartTable, SmartTablePagination } from "components/Table";
import {
  AssetIssueAssetAvatar,
  AssetIssueReporter,
  AssetIssueStatusIndicator,
  AssetIssueTableRowDropdown,
  BlueStatusPill,
  TextBody,
} from "components/shared";
import { GrayStatusPill } from "components/shared/pills";
import PageStage from "components/stages/PageStage";

type RowData = {
  row: {
    original: TAssetIssue;
  };
};

export const AssetIssuesPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(selectAssetIssuesLoading);
  const entities = useSelector(selectAssetIssuesPage);
  const paging = useSelector(selectAssetIssuesPaging);
  const problems = useSelector(selectAssetIssueProblems);

  const rowData = entities;
  const columns = useMemo(() => {
    return [
      {
        Key: "Status",
        Header: "Status",
        Cell: ({ row }: RowData) => {
          const entity = row.original;
          return <AssetIssueStatusIndicator resolved={!!entity.dateResolved} assigned={!!entity.assignedToUserId} />;
        },
      },
      {
        Key: "Asset",
        Header: "Asset",
        Cell: ({ row }: RowData) => {
          const entity = row.original;
          return <AssetIssueAssetAvatar assetIssueId={entity.id} />;
        },
      },
      {
        Key: "Defect",
        Header: "Defect",
        Cell: ({ row }: RowData) => {
          const entity = row.original;
          const problem = problems[entity.assetIssueProblemId];
          if (!problem) return <div />;
          return (
            <Popover content={<TextBody textColor="text-white">{problem.description}</TextBody>}>
              <TextBody>{problem?.label}</TextBody>
            </Popover>
          );
        },
      },
      {
        Key: "Replacement",
        Header: "Replacement needed?",
        Cell: ({ row }: RowData) => {
          const entity = row.original;
          return (
            <TextBody>{entity.requestReorder ? <BlueStatusPill text="Yes" /> : <GrayStatusPill text="No" />}</TextBody>
          );
        },
      },
      // {
      //     Key: 'Note',
      //     Header: 'Note',
      //     Cell: ({ row }: RowData) => {
      //         const entity = row.original;
      //         return <TextBody>{entity.description}</TextBody>;
      //     },
      // },
      {
        Key: "Reporter",
        Header: "Reporter",
        Cell: ({ row }: RowData) => {
          const entity = row.original;
          return (
            <div className="flex">
              <Link to={`/configuration/users/${entity.createdByUserId}`}>
                <AssetIssueReporter assetIssueId={entity.id} />
              </Link>
            </div>
          );
        },
      },
      {
        id: "actions",
        Cell: ({ row }: RowData) => {
          const { id } = row.original;
          return <AssetIssueTableRowDropdown assetIssueId={id} onDelete={() => fetch(0, paging.pageLimit)} />;
        },
      },
    ];
  }, [paging, problems]);

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, []);

  const fetch = (pageStart: number, pageLimit: number) => {
    dispatch(
      fetchAssetIssues({
        pageStart,
        pageLimit,
      }),
    );
  };

  return (
    <PageStage>
      <StageHeader title="Tool issues" subtitle="A list of tool issues reported." />
      {/*<SmartTableNav*/}
      {/*    paging={paging}*/}
      {/*    onPageChange={fetch}*/}
      {/*    showQueryInput={true}*/}
      {/*    queryPlaceholder="Search by note"*/}
      {/*    onQueryChange={setQuery}*/}
      {/*/>*/}
      <SmartTable loading={loading} columns={columns} data={rowData} />
      <SmartTablePagination
        paging={paging}
        onChange={(pageStart: number, pageLimit: number) => fetch(pageStart, pageLimit)}
      />
    </PageStage>
  );
};
