//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class RemoteControlCommands extends Lookup {
  POWER_OFF = "sync.sentry.poweroff";
  REBOOT = "sync.sentry.reboot";
  MOTOR_UNLOCK = "sync.sentry.motor.unlock";
  MOTOR_LOCK = "sync.sentry.motor.lock";
  REFRESH_SCREEN = "sync.sentry.browser.refresh";

  constructor() {
    super();
    super._items = [
      {
        value: this.POWER_OFF,
        label: "Power Off",
        description: "Remotely power off the toolbox",
      },
      {
        value: this.REBOOT,
        label: "Reboot",
        description: "Remotely reboot the toolbox",
      },
      {
        value: this.MOTOR_UNLOCK,
        label: "Unlock Toolbox",
        description: "Remotely unlock the toolbox",
      },
      {
        value: this.MOTOR_LOCK,
        label: "Lock Toolbox",
        description: "Remotely lock the toolbox",
      },
      {
        value: this.REFRESH_SCREEN,
        label: "Refresh Toolbox Screen",
        description: "Remotely refresh the toolbox screen",
      },
    ];
  }
}

export default new RemoteControlCommands();
