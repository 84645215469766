import { XIcon } from "@heroicons/react/outline";
import { useModal } from "effects";
import styled from "styled-components";
import { useSelector } from "store";
import { selectAssetByID } from "store/reducers";
import { PrimaryButton } from "components/Buttons";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent } from "components/modals/Modal";
import { VSpace } from "components/shared/layouts";
import { Heading, TextBody } from "components/shared/typography";

const StyledModal = styled(Modal)`
  position: fixed;
  height: 100vh;
  padding-top: 0;
  top: 0;
  left: 0;
  width: 100vw;
  min-width: unset;
  border-radius: 0;

  @media only screen and (min-width: 768px) {
    max-width: 40rem;
    max-height: 40rem;
    width: 100%;
    top: auto;
    left: auto;
    border-radius: 1rem;
    overflow: hidden;
  }
`;

const CloseModalButton = styled.div`
  position: absolute;
  width: 3rem;
  top: 2rem;
  right: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #f2f2f2;
  color: #9d6fff;
  z-index: 3;
  cursor: pointer;
`;

const AssetImageContainer = styled.div`
  position: relative;
  height: 0;
  padding-top: 66.66%;
  width: 100%;
  z-index: 2;

  @media only screen and (min-width: 768px) {
    padding-top: 40%;
  }
`;

const AssetImage = styled.div<{ imageUrl?: string }>`
  position: absolute;
  background-image: url("${({ imageUrl }) => imageUrl}");
  background-size: cover;
  background-position: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const AddButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  padding: 1rem 1rem 2.5rem;
  width: 100%;
  box-shadow: 0 -1px 4px rgb(0 0 0 / 8%);

  @media only screen and (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem;
  }
`;

const AddButton = styled(PrimaryButton)`
  position: relative;
  width: 100%;
  padding: 1rem 2rem !important;
  border-radius: 0.5rem !important;
`;

type ToolStoreAssetDetailsModalProps = {
  assetId: number;
  onSuccess: () => void;
};

export const ToolStoreAssetDetailsModal = ({ assetId, onSuccess }: ToolStoreAssetDetailsModalProps) => {
  const { closeModal } = useModal();
  const asset = useSelector(selectAssetByID(assetId));
  if (!asset) {
    return null;
  }

  const addToOrder = () => {
    if (onSuccess) {
      onSuccess();
    }
    closeModal();
  };

  return (
    <StyledModal>
      <CloseModalButton onClick={closeModal}>
        <XIcon />
      </CloseModalButton>
      <AssetImageContainer>
        <AssetImage imageUrl={asset.catalogProduct?.imageUrl} />
      </AssetImageContainer>
      <ModalContent>
        <VSpace>
          <Heading level="h2">{asset.catalogProduct?.name}</Heading>
          <TextBody>{asset.catalogProduct?.description}</TextBody>
          <FieldGroup label="Product number" value={asset.catalogProduct?.mpn} />
        </VSpace>
      </ModalContent>
      <AddButtonContainer>
        <AddButton type="button" onClick={addToOrder}>
          Add to order
        </AddButton>
      </AddButtonContainer>
    </StyledModal>
  );
};
