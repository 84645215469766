//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createAlertRule, deleteAlert, deleteAlertRule, requestAlert, updateAlert } from "lib/actions/alerts";
import { openModal } from "lib/actions/modal";
import { showPopupFlag } from "lib/actions/userInterface";
import AlertRules from "lib/constants/AlertRules";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getAlertByID, getAlertRulesByAlertID } from "lib/reducers/alerts.selectors";
import { DangerButton } from "components/Buttons";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import Loading from "components/Loading";
import { Header, Paragraph } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import PowerInput from "components/inputs/PowerInput";
import { VSpace } from "components/shared";
import PageStage from "components/stages/PageStage";
import { AlertEmailAddresses, AlertTelephones } from "pages/alerts/StorageAlert/components/AlertRecipientForms";

const AlertRuleInput = ({ alert, items }) => {
  const dispatch = useDispatch();
  const rules = useSelector(getAlertRulesByAlertID(alert.id));
  const handleChange = (ruleKey) => {
    return (value) => {
      if (value) {
        dispatch(
          createAlertRule({
            alertId: alert.id,
            key: ruleKey,
          }),
        );
        dispatch(
          showPopupFlag({
            appearance: "info",
            title: "Alert rule enabled",
          }),
        );
      } else {
        const alertRule = rules.find(({ key }) => key === ruleKey);
        if (alertRule) {
          dispatch(
            deleteAlertRule({
              alertId: alert.id,
              id: alertRule.id,
            }),
          );
          dispatch(
            showPopupFlag({
              appearance: "info",
              title: "Alert rule disabled",
            }),
          );
        }
      }
    };
  };
  const isActive = (value) => !!rules.find(({ key }) => value === key);
  return (
    <DataGrid>
      {items.map((itemValue) => {
        const rule = AlertRules.resolveItem(itemValue);
        return (
          rule && (
            <DataItem
              key={itemValue}
              label={rule.label}
              description={rule.description}
              value={<PowerInput value={isActive(itemValue)} onChange={handleChange(itemValue)} />}
            />
          )
        );
      })}
    </DataGrid>
  );
};

const defaultRules = [
  AlertRules.STORAGE_ACCESS_GRANTED,
  AlertRules.STORAGE_ACCESS_DENIED,
  AlertRules.STORAGE_ONLINE,
  AlertRules.STORAGE_OFFLINE,
];

const AlertRulesForm = ({ alert, rules = defaultRules }) => {
  return (
    <DataGrid>
      <DataItem label="Access control" value={<AlertRuleInput alert={alert} items={rules} />} />
    </DataGrid>
  );
};

const AlertRecipientForm = ({ alert }) => {
  return (
    <DataGrid>
      <DataItem
        label="Recipients"
        value={
          <DataItemGroup>
            <VSpace gap="gap-2">
              <FieldGroup label="Email addresses">
                <AlertEmailAddresses alert={alert} />
              </FieldGroup>
              <FieldGroup label="Telephone numbers">
                <AlertTelephones alert={alert} />
              </FieldGroup>
            </VSpace>
          </DataItemGroup>
        }
      />
    </DataGrid>
  );
};

const StorageAlert = () => {
  const { alertID } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(getAlertByID(alertID));
  const [values, setValues] = useState(null);
  const fetch = () => dispatch(requestAlert(alertID));
  useEffect(() => fetch(), [alertID]);
  useEffect(() => setValues(data), [data]);

  const saveChanges = (name) => {
    return (value) => {
      dispatch(
        updateAlert(
          {
            ...values,
            [name]: value,
          },
          () => {
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Changes saved",
              }),
            );
          },
          (err) => {
            handlePopupFlagError(err);
          },
        ),
      );
    };
  };

  const removeAlert = () => {
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description: "Are you sure you want to delete this alert? Doing this is permanent.",
        actionText: "Remove alert",
        onConfirm: () => {
          dispatch(
            deleteAlert(
              alertID,
              () => {
                dispatch(
                  showPopupFlag({
                    appearance: "success",
                    title: "Alert Removed",
                  }),
                );
                history(`/apps/storage/alerts`, {
                  replace: true,
                });
              },
              (err) => {
                handlePopupFlagError(err);
              },
            ),
          );
        },
      }),
    );
  };

  return (
    <PageStage>
      {values ? (
        <>
          <Header canEdit={true} placeholder="Enter alert name" value={values.name} onConfirm={saveChanges("name")} />
          <Paragraph
            canEdit={true}
            size="large"
            placeholder="Enter alert description"
            value={values.description}
            onConfirm={saveChanges("description")}
          />
          <VSpace>
            <AlertRulesForm alert={values} />
            <AlertRecipientForm alert={values} />
            <DataGrid>
              <DataItem label="Remove alert" value={<DangerButton onClick={removeAlert}>Remove alert</DangerButton>} />
            </DataGrid>
          </VSpace>
        </>
      ) : (
        <Loading size="large" />
      )}
    </PageStage>
  );
};

export default StorageAlert;
