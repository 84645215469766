//@ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { removeStoreItemImage } from "lib/actions/marketplace";
import { openModal } from "lib/actions/modal";
import { showPopupFlag } from "lib/actions/userInterface";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getStoreItemImageByID } from "lib/reducers/storeItems.selectors";
import Avatar from "components/Avatar";
import Loading from "components/Loading";

const ShopItemImage = ({ editable, itemID, imageID }) => {
  const dispatch = useDispatch();
  const image = useSelector(getStoreItemImageByID(itemID, imageID));

  const handleClick = () => {
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description: "Are you sure you want to remove this image?",
        actionText: "Remove image",
        actionColor: "warning",
        onConfirm: () => {
          dispatch(
            removeStoreItemImage(
              itemID,
              imageID,
              () => {
                dispatch(
                  showPopupFlag({
                    appearance: "success",
                    title: "Image removed",
                  }),
                );
              },
              handlePopupFlagError,
            ),
          );
        },
      }),
    );
  };

  return image ? (
    <Avatar onClick={editable ? handleClick : null} image={image.url} hoverIcon="/images/icons/cross.svg" />
  ) : (
    <Loading />
  );
};

ShopItemImage.defaultProps = {};

ShopItemImage.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
};

export default ShopItemImage;
