//@ts-nocheck
import { createSelector } from "reselect";

export const getStoreItems = createSelector(
  (state) => state.storeItems,
  (state) => state.data,
);

export const getStoreItemsList = createSelector(getStoreItems, (data) => Object.values(data));

export const getStoreItemFetching = (actionType) =>
  createSelector(
    (state) => state.storeItems,
    (state) => state.fetching === actionType,
  );

export const getStoreItemByID = (itemID) =>
  createSelector(
    (state) => state.storeItems,
    (state) => state.data[itemID] || null,
  );

export const getStoreItemsPaging = createSelector(
  (state) => state.storeItems,
  (state) => state.paging,
);

export const getStoreItemsError = createSelector(
  (state) => state.storeItems,
  (state) => state.error,
);

export const getStoreItemImageByID = (itemID, imageID) =>
  createSelector(getStoreItemByID(itemID), (item) => {
    if (!item) return null;
    const image = item.images?.find((image) => image.id === imageID);
    return image || null;
  });

export const getStoreItemDocumentByID = (itemID, documentID) =>
  createSelector(getStoreItemByID(itemID), (item) => {
    if (!item) return null;
    const image = item.documents?.find((document) => document.id === documentID);
    return image || null;
  });
