//@ts-nocheck
import React from "react";
import StageHeader from "components/StageHeader";
import PageStage from "components/stages/PageStage";
import ShopImporter from "pages/apps/ShopManager/ShopImportPage/components/ShopImporter";

const ShopImportPage = () => {
  return (
    <PageStage>
      <StageHeader title="Import Shop Products" subtitle="Either create or update products in the Automify shop." />
      <ShopImporter />
    </PageStage>
  );
};

export default ShopImportPage;
