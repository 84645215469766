//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import { useSelector } from "store";
import StageHeader from "components/StageHeader";
import styles from "./ShopItemBreadcrumb.module.scss";

export const ShopItemBreadcrumb = ({ itemID }) => {
  const item = useSelector(getStoreItemByID(itemID));
  const breadcrumb = [{ label: "Products", href: "/apps/shop" }, item?.name];
  return item && <StageHeader breadcrumb={breadcrumb} className={styles.ShopItemBreadcrumb} />;
};

ShopItemBreadcrumb.propTypes = {
  itemID: PropTypes.number,
};
