//@ts-nocheck
import * as type from "./actionTypes";

export function requestCheckouts(options = {}) {
  return {
    type: type.REQUEST_CHECKOUTS,
    payload: options,
  };
}

export function receiveCheckouts(checkouts, totalCount, page, limit) {
  return {
    type: type.RECEIVE_CHECKOUTS,
    checkouts,
    totalCount,
    page,
    limit,
  };
}

export function requestCheckoutsFailure(error) {
  const payload = typeof error === "string" ? new Error(error) : error;
  return {
    type: type.REQUEST_CHECKOUTS_FAILURE,
    error: payload,
  };
}

export function requestCheckout(id) {
  return {
    type: type.REQUEST_CHECKOUT,
    payload: id,
  };
}

export function receiveCheckout(checkout) {
  return {
    type: type.RECEIVE_CHECKOUT,
    checkout,
  };
}

export function requestCheckoutFailure(error) {
  const payload = typeof error === "string" ? new Error(error) : error;
  return {
    type: type.REQUEST_CHECKOUT_FAILURE,
    error: payload,
  };
}
