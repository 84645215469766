import { FixLater } from "types";
import { Text } from "components/Typography";
import { DropButton, DropItem } from "components/dropdowns/index";

type DropSelectProps = {
  text: string;
  icon: string;
  value: string | boolean | null;
  onChange: (value: FixLater) => void;
  items: FixLater[];
  className?: string;
};

const DropSelect = ({ text, icon, value, items, className, onChange }: DropSelectProps) => {
  const renderButtonText = () => {
    if (value === null) {
      return text;
    }
    const selected = typeof value !== "object" ? items.find((item) => item.value === value) : value;
    if (!selected) {
      return text;
    }
    return `${text}: ${selected.label}`;
  };
  return (
    <DropButton text={renderButtonText()} icon={icon} iconSize="small" border={true} className={className}>
      {items.map((item) => (
        <DropItem
          key={item.value}
          appearance="input"
          active={item.value === value}
          onClick={() => onChange(item.value === value ? null : item.value)}
        >
          <div>{item.label}</div>
          {item.description && <Text className="text-muted">{item.description}</Text>}
        </DropItem>
      ))}
    </DropButton>
  );
};

export default DropSelect;
