import styled from "styled-components";
import Popover from "components/Popover";
import { Header, Paragraph } from "components/Typography";
import { ProgressRing } from "components/charts";

const Wrapper = styled.div`
  flex: auto;
  text-align: center;
  margin-bottom: 1.875rem;
`;

const ProgressWrapper = styled.div`
  display: flex;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.875rem;
`;

const Footer = styled.div`
  position: absolute;
`;

const Value = styled(Header)`
  width: 100%;
  font-size: 3rem;
  margin: 0;
  font-weight: 300;
`;

const Label = styled(Paragraph)`
  max-width: 6rem;
  margin: 0;
`;

type ProgressCircleProps = {
  headline: string;
  title?: string;
  description?: string;
  count: number;
  totalCount: number;
  popoverContent?: () => JSX.Element;
  className?: string;
};

export const ProgressCircle = ({
  title,
  description,
  headline,
  count,
  totalCount,
  popoverContent,
  className,
}: ProgressCircleProps) => {
  return (
    <Wrapper className={className}>
      <ProgressWrapper>
        <Popover content={popoverContent && popoverContent()}>
          <ProgressRing percentage={totalCount !== 0 ? (count / totalCount) * 100 : 0} radius={100} strokeWidth={8} />
        </Popover>
        <Footer>
          <Value>{count}</Value>
          <Label>{headline}</Label>
        </Footer>
      </ProgressWrapper>
      <Header>{title}</Header>
      <Paragraph>{description}</Paragraph>
    </Wrapper>
  );
};
