import React, { FormEvent, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createToolStore } from "store/reducers";
import { CreateToolStoreRequest, TToolStore } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";

type CreateToolStoreModalProps = {
  onSuccess?: (toolStore: TToolStore) => void;
};

export const CreateToolStoreModal = ({ onSuccess }: CreateToolStoreModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateToolStoreRequest>({
    name: "",
    description: "",
  });

  const handleChange = (name: string, value: string | number) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const toolStore = await dispatch(createToolStore(values)).unwrap();
      closeModal();
      onSuccess && onSuccess(toolStore);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader title="Create tool store" />
        <ModalContent>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter store name",
                autoFocus: true,
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit">Create store</PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
