import React, { useEffect, useState } from "react";
import { HiOutlineUpload, HiOutlineDownload, HiOutlineScale } from "react-icons/hi";
import moment from "moment/moment";
import styled from "styled-components";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  fetchAssetById,
  fetchCaseScannerHistoryEvents,
  selectAssetByID,
  selectCaseScannerHistoryEventByID,
} from "store/reducers";
import { Pagination, UUID } from "types";
import Loading from "components/Loading";
import { Table, TableCell, TableHeader, TableLink, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import TablePagination from "components/Table/TablePagination";
import TableRowNotice from "components/Table/TableRowNotice";
import Trusted from "components/Trusted";
import { TableRowLoading } from "components/shared/tables";

const ActionIcon = styled.div`
  font-size: 1.25rem;
`;

const When = styled.span`
  color: #a6a6a6;
`;

type CaseScannerUsageTableProps = {
  caseScannerId: UUID;
};

export const CaseScannerUsageTable = ({ caseScannerId }: CaseScannerUsageTableProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [ids, setIds] = useState<UUID[]>([]);
  const [paging, setPaging] = useState<Pagination>({
    pageStart: 0,
    pageLimit: 20,
    pageCount: 0,
    totalCount: 0,
    nextPageStart: null,
    previousPageStart: null,
  });

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart, paging.pageLimit, caseScannerId]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      setLoading(true);
      const response = await dispatch(
        fetchCaseScannerHistoryEvents({
          pageStart,
          pageLimit,
          caseScannerId,
        }),
      ).unwrap();
      setIds(response.results.map(({ id }) => id));
      setPaging(response.paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.previousPageStart !== null ? prevState.previousPageStart : 0,
    }));
  };

  const nextPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.nextPageStart !== null ? prevState.nextPageStart : 0,
    }));
  };

  const handleLimitChange = (value: number) => {
    setPaging((prevState) => ({
      ...prevState,
      pageLimit: value,
    }));
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeadCell isEmpty />
            <TableHeadCell>Action</TableHeadCell>
            <TableHeadCell>Tool</TableHeadCell>
            <TableHeadCell>Weight</TableHeadCell>
            <TableHeadCell>Date</TableHeadCell>
            <TableHeadCell isEmpty />
          </TableRow>
        </TableHeader>
        <TableBody>
          {!loading && ids.map((id) => <Row key={id} caseScannerHistoryEventId={id} />)}
          {!loading && ids.length === 0 ? (
            <TableRowNotice colSpan={6} notice="This case scanner has no activity yet" />
          ) : null}
        </TableBody>
      </Table>
      {loading && <TableRowLoading />}
      <TablePagination paging={paging} onPrevPage={prevPage} onNextPage={nextPage} onChangeLimit={handleLimitChange} />
    </>
  );
};

type TableRowProps = {
  caseScannerHistoryEventId: string;
};

const Row = ({ caseScannerHistoryEventId }: TableRowProps) => {
  const dispatch = useDispatch();
  const entity = useSelector(selectCaseScannerHistoryEventByID(caseScannerHistoryEventId));
  const asset = useSelector(selectAssetByID(entity?.assetId || 0));

  useEffect(() => {
    if (!entity?.assetId) return;
    if (asset) return;
    dispatch(fetchAssetById(entity?.assetId));
  }, [entity?.assetId, asset]);

  const renderTime = () => {
    if (!entity) return;
    const when = moment(entity.dateCreated);
    const since = moment().diff(when, "hours");
    return (
      <When>
        {since === 0
          ? when.fromNow()
          : when.calendar(null, {
              sameElse: "DD/MM/YYYY [at] h:mm A",
            })}
      </When>
    );
  };

  if (!entity) return null;

  return (
    <TableRow>
      <TableCell>
        <ActionIcon>
          {entity.typeId === 22 ? (
            <HiOutlineUpload />
          ) : entity.typeId === 23 ? (
            <HiOutlineDownload />
          ) : (
            <HiOutlineScale />
          )}
        </ActionIcon>
      </TableCell>
      <TableCell>{entity.typeName}</TableCell>
      <TableCell>
        {asset?.catalogProduct ? (
          <TableLink to={`/apps/tracking/assets/${entity.assetId}`} target="_blank">
            {asset.catalogProduct.name}
          </TableLink>
        ) : (
          <Loading />
        )}
      </TableCell>
      <TableCell>{entity.weight ? `${(entity.weight / 1000).toFixed(2)}kg` : "N/A"}</TableCell>
      <TableCell>{renderTime()}</TableCell>
      <TableCell>
        {entity.evidenceId && (
          <TableLink to={`/evidences/${entity.evidenceId}`} target="_blank">
            <Trusted />
          </TableLink>
        )}
      </TableCell>
    </TableRow>
  );
};
