import React, { FormEvent, useState } from "react";
import { HiOutlineUpload } from "react-icons/hi";
import { RiSignalTowerFill } from "react-icons/ri";
import { useModal } from "effects/useModal";
import styled from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { createAsset, isRunningDeviceRFIDTagsScanning, runDeviceRFIDTagsScan } from "store/reducers";
import { CreateAssetRequest, CreateCustomAssetProductRequest, TAsset, TAssetProduct, TCaseScanner } from "types";
import Avatar from "components/Avatar";
import { DataGrid, DataItem } from "components/DataGrid";
import { TextInput } from "components/Input";
import Loading from "components/Loading";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalHeader } from "components/modals/Modal";
import { SecondaryButton } from "components/shared/buttons";
import { AssetProductSelector, AssetRFIDConfig, CaseScannerSelector } from "components/shared/features";
import { HSpace, VSpace } from "components/shared/layouts";
import { ModalPrimaryActionButton, ModalSecondaryActionButton } from "components/shared/modals";
import { TextBody } from "components/shared/typography";

const CustomOptionButton = styled(TextBody)`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const initialCustomAssetProduct: CreateCustomAssetProductRequest = {
  name: "",
  description: "",
  mpn: "",
  imageUrl: "",
  ean: undefined,
  gtin: undefined,
};

type CreateAssetModalProps = {
  onSuccess?: (value: TAsset) => void;
};

export const CreateAssetModal = ({ onSuccess }: CreateAssetModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const scanning = useSelector(isRunningDeviceRFIDTagsScanning);
  const [epcs, setEpcs] = useState<string[]>();
  const [caseScanner, setCaseScanner] = useState<TCaseScanner>();
  const [customAssetProduct, setCustomAssetProduct] = useState<CreateCustomAssetProductRequest>();
  const [request, setRequest] = useState<CreateAssetRequest>({
    dryRun: false,
    enabled: true,
    description: "",
    assetProductId: undefined,
    serialNumber: "",
    mpn: undefined,
    barcodeTracking: false,
    barcodeValue: "",
    rfidTagId: [],
    rfidTracking: false,
    includeInReports: false,
    customAssetProduct: undefined,
  });
  const [assetProduct, setAssetProduct] = useState<TAssetProduct>();
  const [serialNumber, setSerialNumber] = useState<string>("");

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault();
      const asset = await dispatch(
        createAsset({
          ...request,
          serialNumber,
          customAssetProduct,
          assetProductId: assetProduct?.id,
          rfidTracking: Boolean(epcs?.length),
          rfidTagId: epcs || [],
        }),
      ).unwrap();

      dispatch(
        showPopupFlag({
          appearance: "success",
          title: `${asset.catalogProduct?.name || "Asset"} was created`,
        }),
      );

      closeModal();
      onSuccess && onSuccess(asset);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const startScanningEpcs = async () => {
    try {
      if (!caseScanner) return;
      const data = await dispatch(
        runDeviceRFIDTagsScan({
          storageId: caseScanner.storageId,
        }),
      ).unwrap();
      setEpcs(data);
    } catch (err: any) {
      handlePopupFlagError(err);
    }
  };

  return (
    <form>
      <Modal>
        <ModalHeader title="Setup a new tool" />
        <ModalContent>
          <VSpace>
            <DataGrid>
              <DataItem label="Information">
                <VSpace gap="gap-2">
                  {customAssetProduct ? (
                    <VSpace>
                      <FieldGroup label="Tool image">
                        <Avatar
                          canEdit
                          image={customAssetProduct.imageUrl}
                          hoverIcon={<HiOutlineUpload />}
                          onChange={(imageUrl: string) => {
                            setCustomAssetProduct({
                              ...customAssetProduct,
                              imageUrl,
                            });
                          }}
                        />
                      </FieldGroup>
                      <FieldGroup label="Tool name">
                        <TextInput
                          input={{
                            name: "name",
                            placeholder: "Enter a tool name",
                          }}
                          value={customAssetProduct.name}
                          onChange={(_: string, value: string) => {
                            setCustomAssetProduct({
                              ...customAssetProduct,
                              name: value,
                            });
                          }}
                        />
                      </FieldGroup>
                      <FieldGroup label="Product number">
                        <TextInput
                          input={{
                            name: "mpn",
                            placeholder: "Enter the tool product number",
                          }}
                          value={customAssetProduct.mpn}
                          onChange={(_: string, value: string) => {
                            setCustomAssetProduct({
                              ...customAssetProduct,
                              mpn: value,
                            });
                          }}
                        />
                      </FieldGroup>
                    </VSpace>
                  ) : (
                    <VSpace>
                      <FieldGroup label="Product">
                        <VSpace gap="gap-2">
                          <AssetProductSelector
                            appearance="normal"
                            name="product"
                            placeholder="What type of tool is this?"
                            value={assetProduct}
                            onChange={(_: string, value?: TAssetProduct) => setAssetProduct(value)}
                          />
                          <CustomOptionButton
                            textColor="text-gray-600"
                            onClick={() => {
                              setAssetProduct(undefined);
                              setCustomAssetProduct(initialCustomAssetProduct);
                            }}
                          >
                            Create a product instead
                          </CustomOptionButton>
                        </VSpace>
                      </FieldGroup>
                    </VSpace>
                  )}
                  <FieldGroup label="Serial number">
                    <TextInput
                      input={{
                        name: "serialNumber",
                        placeholder: "Enter serial number",
                      }}
                      value={serialNumber}
                      onChange={(_: string, value?: string) => setSerialNumber(value || "")}
                    />
                  </FieldGroup>
                </VSpace>
              </DataItem>
            </DataGrid>
            <DataGrid>
              <DataItem label="RFID tracking" description="Configure the RFID tags for your tool">
                {request.rfidTracking ? (
                  <>
                    {scanning ? (
                      <Loading />
                    ) : Array.isArray(epcs) ? (
                      <VSpace gap="gap-2">
                        <AssetRFIDConfig epcs={epcs} onChange={setEpcs} />
                        <CustomOptionButton textColor="text-gray-600" onClick={startScanningEpcs}>
                          Rescan tags
                        </CustomOptionButton>
                      </VSpace>
                    ) : (
                      <VSpace gap="gap-2">
                        <FieldGroup label="Scan with case scanner">
                          <CaseScannerSelector
                            onlineOnly
                            appearance="normal"
                            name="caseScannerId"
                            value={caseScanner?.id}
                            onChange={(_: string, value?: TCaseScanner) => setCaseScanner(value)}
                          />
                          <SecondaryButton
                            leadingIcon={RiSignalTowerFill}
                            onClick={startScanningEpcs}
                            disabled={!caseScanner}
                          >
                            Start scanning
                          </SecondaryButton>
                        </FieldGroup>
                        <CustomOptionButton textColor="text-gray-600" onClick={() => setEpcs([])}>
                          Enter tags manually
                        </CustomOptionButton>
                      </VSpace>
                    )}
                  </>
                ) : (
                  <CustomOptionButton
                    textColor="text-gray-600"
                    onClick={() =>
                      setRequest((prev) => ({
                        ...prev,
                        rfidTracking: true,
                      }))
                    }
                  >
                    Enable RFID tracking
                  </CustomOptionButton>
                )}
              </DataItem>
            </DataGrid>
          </VSpace>
          <HSpace>
            <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
            <ModalPrimaryActionButton onClick={handleSubmit}>Create tool</ModalPrimaryActionButton>
          </HSpace>
        </ModalContent>
      </Modal>
    </form>
  );
};
