//@ts-nocheck
import Api from "./api";

class AccessControl extends Api {
  /**
   * Update an access policy
   * @async
   * @param {number} policyID
   * @param {Object} policy
   * @returns {Promise} Response
   */
  static async updatePolicy(policyID, policy) {
    const response = await this.put(`/access_control/policies/${policyID}`).send(policy);
    return response.body.data;
  }

  /**
   * Remove an access policy grant
   * @async
   * @param {number} policyID
   * @param {number} grantID
   * @returns {Promise} Response
   */
  static async removePolicyGrant(policyID, grantID) {
    const response = await this.delete(`/access_control/policies/${policyID}/grants/${grantID}`).send();
    return response.body;
  }
}

export default AccessControl;
