//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "pages/marketing/marketing.module.scss";

const Tablet = ({ size, children, className }) => {
  return (
    <div className={classNames(style.tabletDemo, { [style.large]: size === "large" }, className)}>
      <div className={style.screen}>{children}</div>
    </div>
  );
};

Tablet.defaultProps = {
  small: "normal",
};

Tablet.propTypes = {
  size: PropTypes.oneOf(["normal", "large"]),
  className: PropTypes.string,
};

export default Tablet;
