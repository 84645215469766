import { Route, Routes } from "react-router-dom";
import { CreateToolStoreOrderForm, ToolStoreOrderProvider } from "components/shared";
import { ToolStoreHeader } from "components/shared/features/toolStores/ToolStoreHeader/ToolStoreHeader";
import PageStage from "components/stages/PageStage";
import { SelectToolStorePage } from "pages/features/toolStores/SelectToolStorePage/SelectToolStorePage";

export const CreateToolStoreOrderPage = () => {
  return (
    <ToolStoreOrderProvider>
      <ToolStoreHeader />
      <PageStage>
        <Routes>
          <Route path="" element={<SelectToolStorePage />} />
          <Route path=":toolStoreId" element={<CreateToolStoreOrderForm />} />
        </Routes>
      </PageStage>
    </ToolStoreOrderProvider>
  );
};
