import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { ThunkError, handleThunkError } from "store/utils/thunk";
import {
  CreateCalibrationCertificateRequest,
  CreateCalibrationCertificateResponse,
  DeleteCalibrationCertificateRequest,
  FetchCalibrationCertificatesRequest,
  PagedDataResponse,
  Pagination,
  TCalibrationCertificate,
} from "types";

export const fetchCalibrationCertificates = createAsyncThunk(
  "calibrationCertificates/fetchCalibrationCertificates",
  async (
    request: FetchCalibrationCertificatesRequest,
  ): Promise<{ results: TCalibrationCertificate[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TCalibrationCertificate[]>>(
      `/assets/${request.assetId}/calibration_certificates`,
      request,
    );
    return { results: data, paging: metadata };
  },
);

export const createCalibrationCertificate = createAsyncThunk<
  CreateCalibrationCertificateResponse["data"],
  CreateCalibrationCertificateRequest,
  ThunkError
>("calibrationCertificates/createCalibrationCertificate", async (request, { rejectWithValue }) => {
  try {
    const { data } = await API.POST<CreateCalibrationCertificateRequest, CreateCalibrationCertificateResponse>(
      `/assets/${request.assetId}/calibration_certificates`,
      request,
    );
    return data;
  } catch (err) {
    return handleThunkError(rejectWithValue, err);
  }
});

export const deleteCalibrationCertificate = createAsyncThunk(
  "calibrationCertificates/deleteCalibrationCertificate",
  async ({ assetId, certificateId }: DeleteCalibrationCertificateRequest): Promise<void> => {
    await API.DELETE(`/assets/${assetId}/calibration_certificates/${certificateId}`);
  },
);
