import React, { FormEvent, useState } from "react";
import { useModal } from "effects";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createStorageAsset } from "store/reducers/assets/storageAssets.thunks";
import { TAsset, TStorageAsset } from "types";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalHeader } from "components/modals/Modal";
import { AssetSelector } from "components/shared/features/assets";
import { HSpace } from "components/shared/layouts";
import { ModalPrimaryActionButton, ModalSecondaryActionButton } from "components/shared/modals";

type AssignStorageAssetModalProps = {
  storageId: string;
  title: string;
  onSuccess?: (storageAsset: TStorageAsset) => void;
};

export const AssignStorageAssetModal = ({ storageId, title, onSuccess }: AssignStorageAssetModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [asset, setAsset] = useState<TAsset>();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (!asset) return;
      const storageAsset = await dispatch(
        createStorageAsset({
          storageId,
          assetId: asset.id,
          drawerNumber: 0,
        }),
      ).unwrap();
      closeModal();
      onSuccess && onSuccess(storageAsset);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <Modal>
      <ModalHeader title={title} />
      <ModalContent>
        <FieldGroup label="Select a tool">
          <AssetSelector
            name="asset"
            placeholder="Start typing the name or product number"
            value={asset}
            queryParams={{ storage: false }}
            onChange={setAsset}
          />
        </FieldGroup>
        <HSpace>
          <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
          <ModalPrimaryActionButton disabled={!asset} onClick={handleSubmit}>
            Add tool
          </ModalPrimaryActionButton>
        </HSpace>
      </ModalContent>
    </Modal>
  );
};
