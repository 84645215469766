import { createSlice, Draft, isAnyOf, isFulfilled, isPending, isRejected, PayloadAction } from "@reduxjs/toolkit";
import { createCaseScanner, fetchCaseScannerById, fetchCaseScanners } from "store/reducers/caseScanners";
import { createDoorway, fetchDoorwayById, fetchDoorways } from "store/reducers/doorways";
import { createRagBin, fetchRagBinById, fetchRagBins } from "store/reducers/ragBins";
import {
  createStorage,
  fetchStorages,
  updateStorage,
  deleteStorage,
  fetchStorageById,
  unlinkStorage,
} from "store/reducers/storages/storages.thunks";
import { createToolRoom, fetchToolRoomById, fetchToolRooms } from "store/reducers/toolRooms";
import { TStorage, UUID } from "types";

type PayloadActionWithStorageAsRelation = { storage?: TStorage };

export type StoragesReducerStateData = Record<UUID, TStorage>;

export interface StoragesReducerState {
  loading: string;
  data: StoragesReducerStateData;
}

const handleReceiveStorage = (state: Draft<StoragesReducerState>, action: PayloadAction<TStorage>) => {
  state.data[action.payload.id] = action.payload;
};

const handleReceiveStorageFromRelation = (
  state: Draft<StoragesReducerState>,
  action: PayloadAction<PayloadActionWithStorageAsRelation>,
) => {
  if (!action.payload.storage) return;
  state.data[action.payload.storage.id] = action.payload.storage;
};

const handleReceiveManyStorageFromRelation = (
  state: Draft<StoragesReducerState>,
  action: PayloadAction<{ results: PayloadActionWithStorageAsRelation[] }>,
) => {
  state.data = action.payload.results.reduce(
    (data: StoragesReducerStateData, value: PayloadActionWithStorageAsRelation) =>
      value.storage
        ? {
            ...data,
            [value.storage.id]: value.storage,
          }
        : data,
    state.data,
  );
};

const initialState: StoragesReducerState = {
  loading: "",
  data: {},
};

export const storagesSlice = createSlice({
  name: "storages",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchStorages.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: StoragesReducerStateData, value: TStorage) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchStorageById.fulfilled, handleReceiveStorage)
      .addCase(createStorage.fulfilled, handleReceiveStorage)
      .addCase(updateStorage.fulfilled, handleReceiveStorage)
      .addCase(unlinkStorage.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (!entity || !entity.sentryApiKey) return;
        entity.sentryApiKey.dateActivated = null;
      })
      .addCase(deleteStorage.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addCase(fetchToolRooms.fulfilled, handleReceiveManyStorageFromRelation)
      .addCase(fetchToolRoomById.fulfilled, handleReceiveStorageFromRelation)
      .addCase(createToolRoom.fulfilled, handleReceiveStorageFromRelation)
      .addCase(fetchCaseScanners.fulfilled, handleReceiveManyStorageFromRelation)
      .addCase(fetchCaseScannerById.fulfilled, handleReceiveStorageFromRelation)
      .addCase(createCaseScanner.fulfilled, handleReceiveStorageFromRelation)
      .addCase(fetchRagBins.fulfilled, handleReceiveManyStorageFromRelation)
      .addCase(fetchRagBinById.fulfilled, handleReceiveStorageFromRelation)
      .addCase(createRagBin.fulfilled, handleReceiveStorageFromRelation)
      .addCase(fetchDoorways.fulfilled, handleReceiveManyStorageFromRelation)
      .addCase(fetchDoorwayById.fulfilled, handleReceiveStorageFromRelation)
      .addCase(createDoorway.fulfilled, handleReceiveStorageFromRelation)
      .addMatcher(isPending, (state, action) => {
        state.loading = action.type;
      })
      .addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
        state.loading = "";
      });
  },
});

export const storagesReducer = storagesSlice.reducer;
