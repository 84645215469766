//@ts-nocheck
import React from "react";
import defaultBranding from "brands/defaultBranding";
import withLookupData from "hocs/withLookupData";
import PropTypes from "prop-types";
import Api from "lib/api/company";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import Branding from "pages/configurations/branding/Branding/Branding";

class BrandingContainer extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      requestCompanyBranding: PropTypes.func,
    }),
  };

  state = {
    error: null,
    branding: null,
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    try {
      const { data } = await Api.getBrandingSettings();
      if (!data) {
        return this.setState({
          branding: defaultBranding,
        });
      }
      this.setState({
        branding: { ...data },
      });
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  handleSubmit = async () => {
    try {
      const { branding } = this.state;
      const { actions } = this.props;
      await Api.updateBrandingSettings(branding);
      actions.receiveCompanyBranding(branding);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  onChange = (name, value) => {
    this.setState(
      {
        branding: {
          ...this.state.branding,
          [name]: value,
        },
      },
      () => this.handleSubmit(),
    );
  };

  render() {
    const { branding, error } = this.state;
    return branding ? <Branding branding={branding} error={error} onChange={this.onChange} /> : null;
  }
}

export default withLookupData(BrandingContainer);
