//@ts-nocheck
import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import withLookupData from "hocs/withLookupData";
import PropTypes from "prop-types";
import queryString from "query-string";
import Logger from "lib/Logger";
import style from "./stageTabs.module.scss";

export const Tab = withLookupData((props) => {
  const branding = props.stateStore.lookupData.branding;
  return <div {...props} style={{ background: branding.primaryColor }} />;
});

Tab.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export const StageTabs = (props) => {
  return <Tabs {...props} className={classNames(style.stageTabs, props.className)} />;
};

class TabsComponent extends React.Component {
  static propTypes = {
    initialTab: PropTypes.string.isRequired,
  };

  state = {
    activeTab: null,
  };

  constructor(props) {
    super(props);
    if (props.initialTab) {
      this.state.activeTab = props.initialTab;
    }

    const { search } = props.location;
    const parsed = queryString.parse(search);
    try {
      if (typeof parsed.tab === "string") {
        this.state.activeTab = parsed.tab;
      }
    } catch (err) {
      Logger.error(err);
    }
  }

  generateKey = (child) => (child.props.key ? child.props.key : child.props.name);

  selectTab = (tab) => {
    this.setState({ activeTab: tab }, () => {
      this.props.history({
        search: `?tab=${tab}`,
      });
    });
  };

  renderTab(child) {
    if (child.type !== Tab) {
      Logger.warning("Tabs children must be instance of Tab");
      return null;
    }

    const branding = this.props.stateStore.lookupData.branding;
    const { activeTab } = this.state;
    const key = this.generateKey(child);
    const isActive = key === activeTab;

    return (
      branding && (
        <li
          key={key}
          className={classNames({ [style.active]: isActive })}
          style={isActive ? { borderColor: branding.primaryColor } : null}
          onClick={() => this.selectTab(key)}
        >
          {child.props.name}
        </li>
      )
    );
  }

  renderTabContent() {
    const { activeTab } = this.state;
    if (!activeTab) {
      return null;
    }

    const { children } = this.props;
    const tab = children.filter((child) => child.type === Tab).find((child) => this.generateKey(child) === activeTab);
    if (!tab) {
      Logger.error(`no tab '${activeTab}'`);
      return null;
    }

    return React.cloneElement(tab.props.children, {
      selectTab: this.selectTab,
    });
  }

  render() {
    const { className, children } = this.props;
    return (
      <div className={className}>
        <ul className={style.list}>{children.map((child) => this.renderTab(child))}</ul>
        <div className={style.tabContent}>{this.renderTabContent()}</div>
      </div>
    );
  }
}

export const Tabs = withLookupData((props) => {
  const navigate = useNavigate();
  return <TabsComponent {...props} history={navigate} />;
});
