import { BsBarChart } from "react-icons/bs";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useParams } from "react-router-dom";
import useSetSidebar from "effects/useSidebar";
import Capabilities from "lib/constants/Capabilities";
import { useSelector } from "store";
import { selectRagControllerByID } from "store/reducers";
import { Restrict } from "components/Restricted";
import { SidebarImageHeader } from "components/shared/sidebars/SidebarImageHeader/SidebarImageHeader";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import Sidebar, { MenuSeparator } from "components/sidebars/Sidebar";

export const RagControllerSidebar = () => {
  useSetSidebar(true);
  const { ragControllerID } = useParams();
  const url = `/apps/storage/rag_controllers/${ragControllerID}`;
  const entity = useSelector(selectRagControllerByID(ragControllerID || ""));
  return (
    <Sidebar>
      {entity && <SidebarImageHeader name={entity?.name || ""} imageUrl={entity?.imageUrl} />}
      <MenuSeparator />
      <MenuItem to={`${url}/dashboard`} icon={<BsBarChart />}>
        Dashboard
      </MenuItem>
      <Restrict capability={Capabilities.STORAGE_READ}>
        <MenuItem to={`${url}/bins`} icon={<RiDeleteBin7Line />}>
          Bins
        </MenuItem>
      </Restrict>
      <Restrict capability={Capabilities.RAG_CONTROL_UPDATE}>
        <MenuSeparator />
        <MenuItem to={`${url}/settings`} icon="/images/icons/config_2.svg">
          Settings
        </MenuItem>
      </Restrict>
    </Sidebar>
  );
};
