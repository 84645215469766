//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import get from "lodash/get";
import queryString from "query-string";
import Logger from "lib/Logger";
import { requestLookups } from "lib/actions/lookupData";
import AuthAPI from "lib/api/authentication";
import { useDispatch } from "store";
import { setAuthToken } from "store/reducers";
import { PasswordInput, TextInput } from "components/Input";
import RadioInput from "components/Input/RadioInput";
import { Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { VSpace } from "components/shared";
import PasswordRequirements from "pages/authentication/Login/PasswordRequirements";
import AuthForm from "pages/authentication/components/AuthForm";
import AuthFormTitle from "pages/authentication/components/AuthFormTitle";
import AuthSubmitButton from "pages/authentication/components/AuthSubmitButton";
import style from "./invite.module.scss";

const animation = "animate__animated animate__fast animate__fadeIn";

const Section = {
  ACCOUNT: 1,
  FINISHED: 2,
};

const AccountForm = ({ onNext }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState(null);
  const [values, setValues] = useState({
    fullName: "",
    password: "",
  });

  // On first load, check for the token in the URL
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    setToken(queryParams.token);
  }, []);

  const handleChange = (name, value) => setValues({ ...values, [name]: value });

  const handlePasswordValidate = (isValid) => setValidPassword(isValid);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setSubmitting(true);
      setError(null);

      const { fullName, password } = values;

      // Set the auth token
      dispatch(setAuthToken(token));

      // Change the password
      await AuthAPI.changePassword(password);

      // Confirm the invitation
      await AuthAPI.confirmInvite({ fullName });

      // Request those tasty lookups
      dispatch(requestLookups());

      onNext();
    } catch (err) {
      Logger.error(err);
      const message = get(err, "response.body.message", "Something went wrong");
      setError(message);
      setSubmitting(false);
    }
  };

  return (
    <AuthForm className={animation} onSubmit={handleSubmit}>
      <VSpace>
        <AuthFormTitle title="Let's get you setup" />
        <FieldGroup label="Full name">
          <TextInput
            input={{
              name: "fullName",
              placeholder: "Enter full name",
              autoFocus: true,
            }}
            value={values.fullName}
            onChange={handleChange}
          />
        </FieldGroup>
        <FieldGroup label="Password">
          <VSpace gap="gap-2">
            <PasswordInput
              input={{
                name: "password",
                placeholder: "Enter password",
              }}
              value={values.password}
              onChange={handleChange}
            />
            <PasswordRequirements password={values.password} onValidate={handlePasswordValidate} />
          </VSpace>
        </FieldGroup>
        {error && <Text className="text-danger">{error}</Text>}
        <AuthSubmitButton isDisabled={!validPassword || submitting}>Next</AuthSubmitButton>
      </VSpace>
    </AuthForm>
  );
};

const Finished = () => {
  const navigate = useNavigate();
  let options = [
    {
      label: "Dashboard",
      description: "Take me to the dashboard so that I can look around.",
      value: "/dashboard",
    },
  ];

  const [option, setOption] = useState(options[0]);

  const handleClick = () => {
    navigate(option.value, { replace: true, state: options.state });
  };

  return (
    <VSpace>
      <AuthFormTitle title="You’re ready! Where would you like to start?" />
      <div className={style.nextSteps}>
        {options.map((opt) => {
          return <RadioInput options={[opt]} value={option} onChange={(value) => setOption(value)} />;
        })}
      </div>
      <AuthSubmitButton isDisabled={!option} onClick={handleClick}>
        Jump right in
      </AuthSubmitButton>
    </VSpace>
  );
};

const ConfirmInvite = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [section, setSection] = useState(Section.ACCOUNT);

  // On first load, check for the token in the URL
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (queryParams.exists === "true") {
      setSection(Section.FINISHED);
      dispatch(setAuthToken(queryParams.token));
    }
  }, []);

  switch (section) {
    case Section.ACCOUNT:
      return <AccountForm onNext={() => setSection(Section.FINISHED)} />;
    case Section.FINISHED:
      return <Finished />;
    default:
      return <div />;
  }
};

export default ConfirmInvite;
