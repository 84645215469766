import React, { FormEvent, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createLocation } from "store/reducers";
import { CreateLocationRequest, TLocation } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { LocationTypeSelector } from "components/shared/features/locations";
import { VSpace } from "components/shared/layouts";

type CreateLocationModalProps = {
  onSuccess?: (location: TLocation) => void;
};

export const CreateLocationModal = ({ onSuccess }: CreateLocationModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateLocationRequest>({
    name: "",
    parentLocationId: undefined,
    type: undefined,
    line1: "",
    line2: "",
    city: "",
    country: "",
    postcode: "",
  });

  const handleChange = (name: string, value: string | number) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLocationChange = (name: string, value?: TLocation) => {
    setValues((prevState) => ({ ...prevState, [name]: value?.id }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const location = await dispatch(createLocation(values)).unwrap();
      closeModal();
      onSuccess && onSuccess(location);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader title="Create location" />
        <ModalContent>
          <VSpace>
            <FieldGroup label="Name">
              <TextInput
                input={{
                  name: "name",
                  placeholder: "Enter location name",
                  autoFocus: true,
                }}
                value={values.name}
                onChange={handleChange}
              />
            </FieldGroup>

            <FieldGroup label="Type">
              <LocationTypeSelector name="type" value={values.type} onChange={(value) => handleChange("type", value)} />
            </FieldGroup>

            {values.type && values.type !== "building" && (
              <FieldGroup label="Parent location">
                <LocationSelector
                  appearance="inline"
                  name="parentLocationId"
                  value={values.parentLocationId}
                  onChange={handleLocationChange}
                />
              </FieldGroup>
            )}

            {values.type === "building" && (
              <FieldGroup label="Postcode/zipcode (optional)">
                <TextInput
                  input={{
                    name: "postcode",
                    placeholder: "Enter postcode/zipcode",
                  }}
                  value={values.postcode}
                  onChange={handleChange}
                />
              </FieldGroup>
            )}
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit">Create location</PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
