//@ts-nocheck
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Logger from "lib/Logger";
import Api from "lib/api/storages";
import ItemViewers from "components/ItemViewers";

const StorageViewers = ({ storage }) => {
  const [viewers, setViewers] = useState(null);
  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    try {
      const { data } = await Api.getStorageViewers(storage.id, {
        pageStart: 0,
        pageLimit: 32,
      });
      setViewers(data);
    } catch (err) {
      Logger.error(err);
    }
  };
  return <ItemViewers viewers={viewers} />;
};

StorageViewers.defaultProps = {};

StorageViewers.propTypes = {
  storage: PropTypes.object,
};

export default StorageViewers;
