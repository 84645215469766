import Resolve from "lib/Resolve";
import { showPopupFlag } from "lib/actions/userInterface";
import UsersAPI from "lib/api/users";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectUsers } from "store/reducers";
import { FixLater, PaginationQueryParams, TUser } from "types";
import Avatar from "components/Avatar";
import StatusPill from "components/StatusPill";
import { Table, TableCell, TableHeader, TableLink, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import TablePagination from "components/Table/TablePagination";
import SelectRoleInput from "components/inputs/SelectRoleInput";
import { TeamMemberTableRowDropdown } from "components/shared/features/configurations/teamMembers/TeamMemberTableRowDropdown/TeamMemberTableRowDropdown";
import { TableRowLoading } from "components/shared/tables";
import { TextBody } from "components/shared/typography";

type TeamMembersTableProps = {
  loading: boolean;
  userIds: number[];
  paging?: PaginationQueryParams;
  onPrevPage: () => void;
  onNextPage: () => void;
  onLimitChange: (pageLimit: number) => void;
  onFetch: () => void;
};

export const TeamMembersTable = ({
  loading,
  userIds,
  paging,
  onPrevPage,
  onNextPage,
  onLimitChange,
  onFetch,
}: TeamMembersTableProps) => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);

  const setUserRole = (user: TUser) => {
    return async (name: string, value: FixLater) => {
      try {
        await UsersAPI.updateUser(user.id, {
          ...user,
          [name]: value ? value.id : null,
        });
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Team member role changed",
          }),
        );
        onFetch();
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeadCell isEmpty />
            <TableHeadCell>Email address</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Role</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
            <TableHeadCell isEmpty />
          </TableRow>
        </TableHeader>
        <TableBody>
          {!loading &&
            userIds.map((id) => {
              const user = users[id];
              if (!user) {
                // Can be null immediately after deleting a user
                return null;
              }

              const pendingInvitation = user.dateInvited !== null && user.dateConfirmed === null;
              return (
                <TableRow key={user.id}>
                  <TableCell>
                    <Avatar image={user.profileImage} alt={user.email.toUpperCase().slice(0, 2)} size="s" />
                  </TableCell>
                  <TableCell>
                    <TableLink to={`/configuration/users/${id}`}>{user.email}</TableLink>
                  </TableCell>
                  <TableCell>
                    {!pendingInvitation ? (
                      Resolve.resolveUserFullName(user.id)
                    ) : (
                      <TextBody textColor="text-slate-400">--</TextBody>
                    )}
                  </TableCell>
                  <TableCell>
                    {!pendingInvitation && (
                      <SelectRoleInput
                        appearance="inline"
                        name="roleId"
                        value={Resolve.resolveRoleName(user.roleId)}
                        onChange={setUserRole(user)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {pendingInvitation ? (
                      <StatusPill text="Invited" color="primary" />
                    ) : user.enabled === true ? (
                      <StatusPill text="Active" color="success" />
                    ) : (
                      <StatusPill text="Disabled" color="warning" />
                    )}
                  </TableCell>
                  <TableCell>
                    <TeamMemberTableRowDropdown userId={user.id} onDelete={onFetch} />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {loading && <TableRowLoading />}
      <TablePagination paging={paging} onPrevPage={onPrevPage} onNextPage={onNextPage} onChangeLimit={onLimitChange} />
    </div>
  );
};
