//@ts-nocheck
import React from "react";
import withReports from "hocs/withReports";
import PropTypes from "prop-types";
import ReportsTable from "./ReportsTable";

class ReportsTableContainer extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      requestReports: PropTypes.func,
    }),
    stateStore: PropTypes.shape({
      reports: PropTypes.object,
    }),
  };

  componentDidMount() {
    this.fetch(0, 5);
  }

  fetch = (pageStart = 0, pageLimit = 5) => {
    const { requestReports } = this.props.actions;
    requestReports({ pageStart, pageLimit });
  };

  handlePrevPage = () => {
    const { previousPageStart, pageLimit } = this.props.stateStore.reports.paging;
    this.fetch(previousPageStart, pageLimit);
  };

  handleNextPage = () => {
    const { nextPageStart, pageLimit } = this.props.stateStore.reports.paging;
    this.fetch(nextPageStart, pageLimit);
  };

  handleLimitChange = (pageLimit) => {
    this.fetch(0, pageLimit);
  };

  render() {
    const { data, fetching, paging } = this.props.stateStore.reports;
    return (
      <ReportsTable
        reports={data}
        fetching={fetching}
        paging={paging}
        handlePrevPage={this.handlePrevPage}
        handleNextPage={this.handleNextPage}
        handleLimitChange={this.handleLimitChange}
      />
    );
  }
}

export default withReports(ReportsTableContainer);
