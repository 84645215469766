//@ts-nocheck
import { getCartItems } from "lib/CartStore";
import * as type from "../actions/actionTypes";

const initialState = {
  data: getCartItems(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.CART_SET_PRODUCT_QUANTITY:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.itemID]: action.payload.quantity,
        },
      };
    case type.CART_REMOVE_PRODUCT:
      const withoutProduct = { ...state.data };
      delete withoutProduct[action.payload];
      return {
        ...state,
        data: {
          ...withoutProduct,
        },
      };
    default:
      return state;
  }
};
