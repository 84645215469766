//@ts-nocheck
import React from "react";
import useDidMount from "effects/useDidMount";
import ReportAPI from "lib/api/reports";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getTemplate } from "lib/reducers/reportBuilder.selectors";
import { getReport } from "lib/reducers/reports.selectors";
import { useSelector } from "store";
import LayoutItem from "./LayoutItem";
import style from "./builder.module.scss";

const ReportCanvas = () => {
  const template = useSelector(getTemplate);
  const report = useSelector(getReport);
  useDidMount(() => report && saveChanges(), [report]);

  const saveChanges = async () => {
    try {
      if (report) {
        const response = await ReportAPI.updateReport(report.id, report);
      }
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    template && (
      <div className={style.builder}>
        <div className={style.canvas}>
          {template.layout.map((item) => (
            <LayoutItem key={item.id} layout={item} />
          ))}
        </div>
      </div>
    )
  );
};

export default ReportCanvas;
