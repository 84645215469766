//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import BrandingKeys from "lib/constants/BrandingKeys";
import Avatar from "components/Avatar";
import ColorPicker from "components/ColorPicker";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import StageHeader from "components/StageHeader";
import PageStage from "components/stages/PageStage";
import style from "./branding.module.scss";

const Branding = ({ branding, onChange }) => {
  return (
    <PageStage>
      <StageHeader
        title="Branding"
        subtitle="Configure your company branding to help you feel more at home within Automify."
      />
      <DataGrid>
        <DataItem
          label="Management portal"
          value={
            <DataItemGroup>
              <DataGrid>
                <DataItem
                  label="Favicon"
                  value={
                    <Avatar
                      image={branding[BrandingKeys.PortalFavIconKey]}
                      size="s"
                      className={style.avatar}
                      imageClassName={style.avatarImage}
                      canEdit={true}
                      alt=""
                      onChange={(value) => onChange(BrandingKeys.PortalFavIconKey, value)}
                    />
                  }
                />
                <DataItem
                  label="Portal icon"
                  value={
                    <Avatar
                      canEdit
                      image={branding[BrandingKeys.PortalIconKey]}
                      size="m"
                      className={style.avatar}
                      imageClassName={style.avatarImage}
                      alt=""
                      onChange={(value) => onChange(BrandingKeys.PortalIconKey, value)}
                    />
                  }
                />
                <DataItem
                  label="Portal logo"
                  value={
                    <Avatar
                      image={branding[BrandingKeys.PortalLogoKey]}
                      className={style.avatar}
                      imageClassName={style.avatarImage}
                      canEdit={true}
                      alt=""
                      onChange={(value) => onChange(BrandingKeys.PortalLogoKey, value)}
                    />
                  }
                />
              </DataGrid>
            </DataItemGroup>
          }
        />
        <DataItem
          label="Toolbox & stores"
          value={
            <DataItemGroup>
              <DataGrid>
                <DataItem
                  label="Logo"
                  value={
                    <Avatar
                      image={branding[BrandingKeys.SentryLogoKey]}
                      className={style.avatar}
                      imageClassName={style.avatarImage}
                      canEdit={true}
                      alt=""
                      onChange={(value) => onChange(BrandingKeys.SentryLogoKey, value)}
                    />
                  }
                />
                <DataItem
                  label="Background color"
                  value={
                    <ColorPicker
                      name={BrandingKeys.SentryBackgroundColorKey}
                      value={branding[BrandingKeys.SentryBackgroundColorKey]}
                      onChange={onChange}
                    />
                  }
                />
              </DataGrid>
            </DataItemGroup>
          }
        />
      </DataGrid>
    </PageStage>
  );
};

Branding.defaultProps = {};

Branding.propTypes = {
  error: PropTypes.string,
  colors: PropTypes.shape({
    backgroundColor: PropTypes.string,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default Branding;
