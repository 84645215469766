//@ts-nocheck
import { createSelector } from "reselect";

export const getPageBlocks = createSelector(
  (state) => state.blocks,
  (state = {}) => {
    return state.pageBlocks;
  },
);

export const getCurrentBlockID = createSelector(
  (state) => state.blocks,
  (state = {}) => {
    return state.currentBlockID;
  },
);

export const getCurrentPageBlock = createSelector(
  (state) => state.blocks,
  (state = {}) => {
    return state.pageBlocks[state.currentBlockID];
  },
);

export const getTargetBlockID = createSelector(
  (state) => state.blocks,
  (state = {}) => {
    return state.targetBlockID;
  },
);

export const getBlockMenuNode = createSelector(
  (state) => state.blocks,
  (state = {}) => {
    return state.blockMenuNode;
  },
);

export const getBlockMediaMenu = createSelector(
  (state) => state.blocks,
  (state = {}) => {
    return state.blockMediaMenuNode;
  },
);

export const getBlockConfigMenu = createSelector(
  (state) => state.blocks,
  (state = {}) => {
    return state.blockConfigMenuNode;
  },
);
