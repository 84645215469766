//@ts-nocheck
import { createSelector } from "reselect";

export const getTaskLists = createSelector(
  (state) => state.taskLists,
  (state) => state.data,
);

export const getTaskListsList = createSelector(getTaskLists, (data) => Object.values(data));

export const getTaskListFetching = (actionType) =>
  createSelector(
    (state) => state.taskLists,
    (state) => state.fetching === actionType,
  );

export const getTaskListsPaging = createSelector(
  (state) => state.taskLists,
  (state) => state.paging,
);

export const getTaskListsError = createSelector(
  (state) => state.taskLists,
  (state) => state.error,
);

export const getTaskListByID = (id) => createSelector(getTaskLists, (data) => data[id] || null);
