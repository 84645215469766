import Lookup from "lib/constants/Lookup";

class TaskStatus extends Lookup<string> {
  OPEN = "OPEN";
  IN_PROGRESS = "IN_PROGRESS";
  COMPLETED = "COMPLETED";

  constructor() {
    super();
    this.items = [
      {
        value: this.OPEN,
        label: "Open",
        description: "The task has not been started",
      },
      {
        value: this.IN_PROGRESS,
        label: "In Progress",
        description: "The task is in progress",
      },
      {
        value: this.COMPLETED,
        label: "Completed",
        description: "The task has been completed",
      },
    ];
  }
}

export default new TaskStatus();
