//@ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestManufacturers } from "lib/actions/catalogue";
import { selectManufacturersList, selectManufacturersPaging } from "lib/reducers/manufacturers.selectors";
import { TableHeadCell, TableBody, Table, TableCell, TableHeader, TableRow, TablePagination } from "components/Table";
import { Anchor } from "components/Typography";

export const ManufacturersTable = () => {
  const dispatch = useDispatch();
  const manufacturers = useSelector(selectManufacturersList);
  const paging = useSelector(selectManufacturersPaging);

  useEffect(() => {
    fetch(0, 50);
  }, []);

  const fetch = (pageStart, pageLimit) => {
    dispatch(requestManufacturers({ pageStart, pageLimit }));
  };
  const handlePrevPage = () => fetch(paging.pageStart - 1, paging.pageLimit);
  const handleNextPage = () => fetch(paging.pageStart + 1, paging.pageLimit);
  const handleLimitChange = (value) => fetch(paging.pageStart, value);

  return (
    <div>
      <Table>
        <TableHeader>
          <TableHeadCell>Name</TableHeadCell>
        </TableHeader>
        <TableBody>
          {manufacturers.map(({ id, name }) => (
            <TableRow key={id}>
              <TableCell>
                <Anchor to={`/configuration/manufacturers/${id}`}>{name}</Anchor>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        paging={paging}
        onPrevPage={handlePrevPage}
        onNextPage={handleNextPage}
        onChangeLimit={handleLimitChange}
      />
    </div>
  );
};
