//@ts-nocheck
import React from "react";
import { Portal } from "react-portal";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { pageBlocksChange, setCurrentBlockID, showConfigMenuFor } from "lib/actions/blocks";
import BlockAPI from "lib/api/blocks";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getBlockConfigMenu, getCurrentPageBlock, getPageBlocks } from "lib/reducers/blocks.selectors";
import Card from "components/Card";
import Icon from "components/Icon";
import KeyHandler, { KEY_CODE_ESC } from "components/KeyHandler";
import Overlay from "components/Overlay";
import { PortalMenu, SectionTitle } from "components/Sheet/components/PortalCommon";
import { removeBlockFromPageBlocks } from "components/Sheet/utils";
import { Text } from "components/Typography";
import style from "../sheet.module.scss";

const BlockConfigMenu = ({ parentNode, pageBlock, onRemoveBlock }) => {
  const dispatch = useDispatch();
  const pageBlocks = useSelector(getPageBlocks);

  const removeBlock = async () => {
    try {
      const newPageBlocks = removeBlockFromPageBlocks(pageBlocks, pageBlock.blockId);
      dispatch(pageBlocksChange(newPageBlocks));
      dispatch(setCurrentBlockID(""));
      dispatch(showConfigMenuFor(null));

      onRemoveBlock && onRemoveBlock(newPageBlocks);
      await BlockAPI.deleteBlock(pageBlock.blockId);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return pageBlock ? (
    <PortalMenu parentNode={parentNode}>
      <Card className={style.blockConfigMenuCard}>
        <SectionTitle>Content options</SectionTitle>
        <ul>
          {false && (
            <li>
              <Icon src="/images/icons/copy_1.svg" size="medium" className={style.icon} />
              <Text>Duplicate</Text>
            </li>
          )}
          <li onClick={removeBlock}>
            <Icon src="/images/icons/trash.svg" size="medium" className={style.icon} />
            <Text>Delete</Text>
          </li>
        </ul>
      </Card>
    </PortalMenu>
  ) : null;
};

const BlockConfigMenuPortal = ({ onRemoveBlock }) => {
  const dispatch = useDispatch();
  const node = useSelector(getBlockConfigMenu);
  const currentPageBlock = useSelector(getCurrentPageBlock);

  const handleClose = () => {
    dispatch(showConfigMenuFor(null));
  };

  return (
    node && (
      <Portal>
        <Overlay onClick={handleClose} isTransparent={true} className={style.overlay} />
        <KeyHandler keyCode={KEY_CODE_ESC} onKeyHandle={handleClose} />
        <BlockConfigMenu parentNode={node} pageBlock={currentPageBlock} onRemoveBlock={onRemoveBlock} />
      </Portal>
    )
  );
};

BlockConfigMenuPortal.defaultProps = {};

BlockConfigMenuPortal.propTypes = {
  className: PropTypes.string,
};

export default BlockConfigMenuPortal;
