import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { AssignKeycardRequest, AssignKeycardResponse, DataResponse, TUserKeycard } from "types";

export const fetchUserKeycardById = createAsyncThunk(
  "userKeycards/getById",
  async (id: string): Promise<TUserKeycard> => {
    const { data } = await API.GET<DataResponse<TUserKeycard>>(`/keycards/${id}/assignment`);
    return data;
  },
);

export const assignUserKeycard = createAsyncThunk(
  "userKeycards/create",
  async (request: AssignKeycardRequest): Promise<AssignKeycardResponse> => {
    const { data } = await API.POST<AssignKeycardRequest, DataResponse<AssignKeycardResponse>>(
      `/keycards/${request.keycardId}/assignment`,
      request,
    );
    return data;
  },
);

export const unAssignUserKeycard = createAsyncThunk("userKeycards/delete", async (id: string): Promise<void> => {
  await API.DELETE(`/keycards/${id}/assignment`);
});
