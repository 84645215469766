import { useNavigate } from "react-router-dom";
import { useModal } from "effects";
import { TCaseScanner } from "types";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { CreateDoorwayModal, ListDoorwaysTable } from "components/shared/features/doorways/components";
import PageStage from "components/stages/PageStage";

export const ListDoorwaysPage = () => {
  const navigate = useNavigate();
  const { openModal } = useModal();

  const createDevice = () => {
    openModal(
      <CreateDoorwayModal
        onSuccess={(entity: TCaseScanner) => {
          navigate(`/apps/tracking/doorways/${entity.id}`);
        }}
      />,
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Doorways"
        subtitle="Create and manage doorways to monitor asset movement throughout your facilities."
        action={
          <SecondaryButton icon="/images/icons/plus_1.svg" onClick={createDevice}>
            Create doorway
          </SecondaryButton>
        }
      />
      <ListDoorwaysTable />
    </PageStage>
  );
};
