//@ts-nocheck
import { requestFailure } from "lib/actions/common";
import * as type from "./actionTypes";

export function requestUsers(options = {}) {
  return {
    type: type.REQUEST_USERS,
    payload: options,
  };
}

export function receiveUsers(users, paging) {
  return {
    type: type.RECEIVE_USERS,
    payload: users,
    paging,
  };
}

export function requestUsersFailure(error) {
  return requestFailure(type.REQUEST_USERS_FAILURE, error);
}

export function requestUser(userId) {
  return {
    type: type.REQUEST_USER,
    payload: userId,
  };
}

export function receiveUser(user) {
  return {
    type: type.RECEIVE_USER,
    payload: user,
  };
}

export function requestUserFailure(error) {
  return requestFailure(type.REQUEST_USER_FAILURE, error);
}

export function requestRoles(options = {}) {
  return {
    type: type.GET_ROLES_REQUEST,
    payload: options,
  };
}

export function receiveRoles(roles, paging) {
  return {
    type: type.GET_ROLES_RECEIVE,
    payload: roles,
    paging,
  };
}

export function requestRolesFailure(error) {
  return requestFailure(type.GET_ROLES_FAILURE, error);
}

export function requestRole(roleID) {
  return {
    type: type.GET_ROLE_REQUEST,
    payload: roleID,
  };
}

export function receiveRole(role) {
  return {
    type: type.GET_ROLE_RECEIVE,
    payload: role,
  };
}

export function requestRoleFailure(error) {
  return requestFailure(type.GET_ROLE_FAILURE, error);
}

export function requestKeycards(options = {}) {
  return {
    type: type.GET_KEYCARDS_REQUEST,
    payload: options,
  };
}

export function receiveKeycards(keycards, paging) {
  return {
    type: type.GET_KEYCARDS_RECEIVE,
    payload: keycards,
    paging,
  };
}

export function requestKeycardsFailure(error) {
  return requestFailure(type.GET_KEYCARDS_FAILURE, error);
}

export function requestKeycard(keycardID) {
  return {
    type: type.GET_KEYCARD_REQUEST,
    payload: keycardID,
  };
}

export function receiveKeycard(keycard) {
  return {
    type: type.GET_KEYCARD_RECEIVE,
    payload: keycard,
  };
}

export function requestKeycardFailure(error) {
  return requestFailure(type.GET_KEYCARD_FAILURE, error);
}
