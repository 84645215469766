import { createSlice, isPending, isFulfilled, isRejected, isAnyOf } from "@reduxjs/toolkit";
import { fetchAssetProducts, fetchAssetProductById } from "store/reducers/assetProducts/assetProducts.thunks";
import { TAssetProduct, UUID } from "types";

export type AssetProductsReducerStateData = Record<UUID, TAssetProduct>;

export interface AssetProductsReducerState {
  loading: string;
  page: UUID[];
  data: AssetProductsReducerStateData;
}

const initialState: AssetProductsReducerState = {
  loading: "",
  page: [],
  data: {},
};

export const assetProductsSlice = createSlice({
  name: "assetProducts",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAssetProducts.pending, (state, action) => {
        state.page = [];
      })
      .addCase(fetchAssetProducts.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: AssetProductsReducerStateData, value: TAssetProduct) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
        state.page = action.payload.results.map(({ id }) => id);
      })
      .addCase(fetchAssetProductById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addMatcher(isPending, (state, action) => {
        state.loading = action.type;
      })
      .addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
        state.loading = "";
      });
  },
});
export const assetProductsReducer = assetProductsSlice.reducer;
