import Account from "lib/Account";
import { formatFullName } from "lib/utility/format";
import { useSelector } from "store";
import { selectUserByID } from "store/reducers";
import { SecondaryButton } from "components/Buttons";
import Popover from "components/Popover";
import StageHeader from "components/StageHeader";
import { Text } from "components/Typography";
import { DashboardMetrics, OutstandingStorages, OutstandingVehicles } from "components/shared/features";
import { MediaQuery, VSpace } from "components/shared/layouts";
import PageStage from "components/stages/PageStage";

export const DashboardPage = () => {
  const user = useSelector(selectUserByID(Account.userId()));
  const fullName = formatFullName({
    forename: user?.forename,
    surname: user?.surname,
    email: user?.email,
  });
  return (
    <PageStage appearance={null}>
      <StageHeader
        title="Dashboard"
        subtitle={user ? `👋 Welcome back${fullName ? ", " + fullName : ""}!` : undefined}
        action={
          <MediaQuery breakpoint="md">
            <Popover content={<Text>Coming soon!</Text>}>
              <SecondaryButton icon="/images/icons/tv_2.svg">TV mode</SecondaryButton>
            </Popover>
          </MediaQuery>
        }
      />
      <VSpace gap="gap-10">
        <DashboardMetrics />
        <OutstandingStorages />
        <OutstandingVehicles />
      </VSpace>
      {/*<DashboardMetrics>*/}
      {/*  <Restrict*/}
      {/*    app={Apps.TOOL_STORAGE}*/}
      {/*    capability={Capabilities.STORAGE_READ}*/}
      {/*  >*/}
      {/*    <StoragesMetrics />*/}
      {/*  </Restrict>*/}
      {/*  <Restrict app={Apps.TOOL_TRACKING} capability={Capabilities.ASSET_READ}>*/}
      {/*    <AssetsMetrics />*/}
      {/*  </Restrict>*/}
      {/*  <Restrict app={Apps.TOOL_TRACKING} capability={Capabilities.ASSET_READ}>*/}
      {/*    <CalibrationsMetrics />*/}
      {/*  </Restrict>*/}
      {/*</DashboardMetrics>*/}
    </PageStage>
  );
};

export default DashboardPage;
