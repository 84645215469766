import { showPopupFlag } from "lib/actions/userInterface";
import Capabilities from "lib/constants/Capabilities";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectAssetByID, updateAsset } from "store/reducers";
import { UpdateAssetRequest } from "types";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import { MoneyInput } from "components/Input";
import { CurrencyInputValue } from "components/Input/CurrencyInput";
import { useRoleHasCapability } from "components/Restricted";
import StageHeader from "components/StageHeader";
import StageSection from "components/StageSection";
import { PageLoading } from "components/shared";

type AssetFinancialsProps = {
  assetId: number;
};

export const AssetFinancials = ({ assetId }: AssetFinancialsProps) => {
  const dispatch = useDispatch();
  const asset = useSelector(selectAssetByID(assetId));
  const canEdit = useRoleHasCapability(Capabilities.ASSET_UPDATE);

  /**
   * Returns true if the costs have changed.
   * @param value
   */
  const hasCostsChange = (value: UpdateAssetRequest["costs"]): boolean => {
    if (!asset) return false;
    return (
      value.retailCurrency !== asset.costs.retailCurrency ||
      value.retail !== asset.costs.retail ||
      value.actualCurrency !== asset.costs.actualCurrency ||
      value.actual !== asset.costs.actual
    );
  };

  const handleCostChange = async (value: UpdateAssetRequest["costs"]) => {
    try {
      if (!asset) return;
      if (!hasCostsChange(value)) return;
      await dispatch(
        updateAsset({
          ...asset,
          costs: value,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Financials updated",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  if (!asset) {
    return <PageLoading />;
  }

  return (
    <>
      <StageHeader title="Financials" />
      <StageSection isFirst>
        <DataGrid>
          <DataItem label="Information">
            <DataItemGroup>
              <DataItem label="Retail value" description="Enter the retail value">
                <MoneyInput
                  input={{
                    disabled: !canEdit,
                  }}
                  value={{
                    currency: asset.costs.retailCurrency,
                    amount: asset.costs.retail,
                  }}
                  placeholder="Enter retail value"
                  onBlur={(value: CurrencyInputValue) => {
                    handleCostChange({
                      ...asset.costs,
                      retailCurrency: value.currency,
                      retail: value.amount,
                    });
                  }}
                />
              </DataItem>
              <br />
              <DataItem label="Current value" description="Enter the estimated current value">
                <MoneyInput
                  input={{
                    disabled: !canEdit,
                  }}
                  value={{
                    currency: asset.costs.actualCurrency,
                    amount: asset.costs.actual,
                  }}
                  placeholder="Enter current value"
                  onBlur={(value?: CurrencyInputValue) => {
                    if (!value) return;
                    handleCostChange({
                      ...asset.costs,
                      actualCurrency: value.currency,
                      actual: value.amount,
                    });
                  }}
                />
              </DataItem>
            </DataItemGroup>
          </DataItem>
        </DataGrid>
      </StageSection>
    </>
  );
};
