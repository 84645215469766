//@ts-nocheck
import React, { useState } from "react";
import { useSortBy, useTable } from "react-table";
import classNames from "classnames";
import useDebounce from "effects/useDebounce";
import useDidMount from "effects/useDidMount";
import { FixLater } from "types";
import IconButton from "components/IconButton";
import { TextInput } from "components/Input";
import OptionSelect from "components/OptionSelector";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import { Table, TableCell, TableHeader, TableRow } from "components/Table/index";
import { Text } from "components/Typography";
import { TableRowLoading } from "components/shared/tables";
import style from "./table.module.scss";

export const SmartTable = ({ columns, data, onRowClick, onCellClick, className, cellClassName, loading }: FixLater) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy);
  return (
    <>
      <Table {...getTableProps()} className={className}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeadCell {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                </TableHeadCell>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()}>
          {!loading &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} onClick={() => onRowClick && onRowClick(row)}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        className={cellClassName}
                        onClick={() => onCellClick && onCellClick(row, cell)}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {loading && <TableRowLoading />}
    </>
  );
};

type SmartTablePaginationProps = FixLater & {
  onChange: (pageStart: number, pageLimit: number) => void;
  classNam?: string;
};

export const SmartTablePagination = ({
  className,
  paging,
  limitOptions = [5, 10, 20, 50],
  hidePageLimit,
  onChange,
}: SmartTablePaginationProps) => {
  const { nextPageStart, previousPageStart, pageLimit } = paging;
  const handleLimitChange = (newPageLimit) => {
    onChange(0, newPageLimit);
  };
  const handleNextClick = () => {
    onChange(nextPageStart, pageLimit);
  };
  const handlePrevClick = () => {
    onChange(previousPageStart, pageLimit);
  };
  return (
    <div className={classNames(style.paginationWrapper, className)}>
      {!hidePageLimit && (
        <OptionSelect
          options={limitOptions}
          value={paging.pageLimit}
          onSelect={handleLimitChange}
          className={style.limitSelector}
        />
      )}
      <div className={style.paginationButtons}>
        <IconButton
          icon="/images/icons/caret-left.svg"
          onClick={handlePrevClick}
          disabled={paging.previousPageStart === "" || paging.previousPageStart === null}
        />
        <IconButton
          icon="/images/icons/caret-right.svg"
          onClick={handleNextClick}
          disabled={paging.nextPageStart === "" || paging.nextPageStart === null}
        />
      </div>
    </div>
  );
};

export const SmartTableNav = ({
  className,
  paging,
  children,
  showPageCount,
  showPagingControls,
  showQueryInput,
  queryPlaceholder = "Search for something",
  onPageChange,
  onQueryChange,
}: FixLater) => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 400);
  const { nextPageStart, previousPageStart, pageCount, totalCount, pageLimit } = paging || {};
  const isStoragePortal = process.env.REACT_APP_PORTAL === "storage";

  useDidMount(() => {
    onQueryChange && onQueryChange(debouncedQuery);
  }, [debouncedQuery]);

  const handleNextClick = () => {
    onPageChange && onPageChange(nextPageStart, pageLimit);
  };
  const handlePrevClick = () => {
    onPageChange && onPageChange(previousPageStart, pageLimit);
  };
  return (
    <div className={classNames(style.tableNavWrapper, className)}>
      <div className={style.navPaging}>
        {showPageCount && (
          <Text className={style.navPagingCount}>
            Showing {pageCount} of {totalCount} results
          </Text>
        )}
        {showPagingControls && (
          <div className={style.paginationButtons}>
            <IconButton
              icon="/images/icons/caret-left.svg"
              onClick={handlePrevClick}
              disabled={paging.previousPageStart === ""}
            />
            <IconButton
              icon="/images/icons/caret-right.svg"
              onClick={handleNextClick}
              disabled={paging.nextPageStart === ""}
            />
          </div>
        )}
        {children}
      </div>
      {showQueryInput && (
        <TextInput
          input={{
            name: "query",
            placeholder: queryPlaceholder,
            icon: "/images/icons/search.svg",
          }}
          value={query}
          className={style.queryInput}
          showKeyboard={isStoragePortal}
          onChange={(_, value) => setQuery(value)}
        />
      )}
    </div>
  );
};
