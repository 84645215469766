import { useEffect, useState } from "react";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchStorages, selectLocationByID, selectStorageByID } from "store/reducers";
import { Pagination, UUID } from "types";
import { Table, TableCell, TableHeader, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import { TableLink } from "components/Table/TableLink/TableLink";
import TablePagination from "components/Table/TablePagination";
import { StorageInventoryProgressBar } from "components/shared/features/storages/StorageInventoryProgressBar";
import { StorageStatusPill } from "components/shared/features/storages/StorageStatusPill/StorageStatusPill";
import { TableRowLoading } from "components/shared/tables";

type TableRowProps = {
  storageId: string;
};

const Row = ({ storageId }: TableRowProps) => {
  const entity = useSelector(selectStorageByID(storageId));
  const location = useSelector(selectLocationByID(entity?.locationId || ""));
  if (!entity) return null;
  return (
    <TableRow>
      <TableCell>
        <StorageStatusPill storageId={entity?.id} />
      </TableCell>
      <TableCell>
        <TableLink to={`/apps/storage/toolboxes/${storageId}`}>{entity.name}</TableLink>
      </TableCell>
      <TableCell>{location ? location.name : "N/A"}</TableCell>
      <TableCell>
        <StorageInventoryProgressBar storageId={storageId} width="w-24" />
      </TableCell>
    </TableRow>
  );
};

export const ToolStorageTable = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [ids, setIds] = useState<UUID[]>([]);
  const [paging, setPaging] = useState<Pagination>({
    pageStart: 0,
    pageLimit: 10,
    pageCount: 0,
    totalCount: 0,
    nextPageStart: null,
    previousPageStart: null,
  });

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart, paging.pageLimit]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchStorages({ pageStart, pageLimit, type: "toolbox" })).unwrap();
      setIds(response.results.map(({ id }) => id));
      setPaging(response.paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.previousPageStart !== null ? prevState.previousPageStart : 0,
    }));
  };

  const nextPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.nextPageStart !== null ? prevState.nextPageStart : 0,
    }));
  };

  const handleLimitChange = (value: number) => {
    setPaging((prevState) => ({
      ...prevState,
      pageLimit: value,
    }));
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeadCell>Status</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Location</TableHeadCell>
            <TableHeadCell>Inventory</TableHeadCell>
          </TableRow>
        </TableHeader>
        <TableBody>{!loading && ids.map((id) => <Row key={id} storageId={id} />)}</TableBody>
      </Table>
      {loading && <TableRowLoading />}
      <TablePagination paging={paging} onPrevPage={prevPage} onNextPage={nextPage} onChangeLimit={handleLimitChange} />
    </>
  );
};
