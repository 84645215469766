import { useEffect } from "react";
import { useMap } from "react-map-gl";

type VehiclesMapDriverProps = {
  longitude: number;
  latitude: number;
  zoom: number;
};

export const VehiclesMapDriver = ({ longitude, latitude, zoom }: VehiclesMapDriverProps) => {
  const { current: map } = useMap();

  useEffect(() => {
    if (!map) return;
    map.flyTo({
      center: { lng: longitude, lat: latitude },
      zoom,
      essential: true,
    });
  }, [map, longitude, latitude]);

  return <></>;
};
