//@ts-nocheck
import React from "react";
import { useNavigate } from "react-router-dom";
import ReactSVG from "react-svg";
import classNames from "classnames";
import { Anchor } from "../Typography";
import style from "./stageMenuItem.module.scss";

const StageMenuItem = ({ icon, title, description, path, className, onClick }: any) => {
  const history = useNavigate();
  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
    if (path) {
      history(path);
    }
  };
  return (
    <div className={classNames(style.item, className)} onClick={handleClick}>
      {icon ? (
        typeof icon === "string" ? (
          <div className={style.iconWrapper}>
            <ReactSVG src={icon} className={style.icon} />
          </div>
        ) : (
          icon
        )
      ) : null}
      <div className={style.textWrapper}>
        <Anchor className={style.title}>{title}</Anchor>
        <span className={style.description}>{description}</span>
      </div>
    </div>
  );
};

export default StageMenuItem;
