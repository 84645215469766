//@ts-nocheck
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styles from "./KanbanItem.module.scss";

export const KanbanItem = ({ id, index, component }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={styles.KanbanItem}
        >
          {component}
        </div>
      )}
    </Draggable>
  );
};
