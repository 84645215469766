//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  blocks: {},
  pageBlocks: {},
  currentBlockID: null,
  targetBlockID: null,
  blockMenuNode: null,
  blockMediaMenuNode: null,
  blockConfigMenuNode: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.PAGE_BLOCKS_CHANGE:
      return {
        ...state,
        pageBlocks: action.payload.reduce(
          (pageBlocks, pageBlock) => ({
            ...pageBlocks,
            [pageBlock.blockId]: pageBlock,
          }),
          {},
        ),
      };
    case type.PAGE_BLOCK_SET_CURRENT:
      return {
        ...state,
        currentBlockID: action.payload,
      };
    case type.PAGE_BLOCK_SELECT:
      return {
        ...state,
        targetBlockID: action.payload,
      };
    case type.SHOW_BLOCK_MENU:
      return {
        ...state,
        blockMenuNode: action.payload,
      };
    case type.SHOW_BLOCK_MEDIA_MENU:
      return {
        ...state,
        blockMediaMenuNode: action.payload,
      };
    case type.SHOW_BLOCK_CONFIG_MENU:
      return {
        ...state,
        blockConfigMenuNode: action.payload,
      };
    default:
      return state;
  }
};
