import { ReactNode } from "react";
import styled from "styled-components";
import { Heading } from "components/shared/typography";

const Header = styled.div`
  margin-bottom: 1rem;
`;
const StyledHeading = styled(Heading)`
  font-size: 1rem;
`;

type SwimlaneHeaderProps = {
  children: ReactNode;
  className?: string;
};

export const SwimlaneHeader = ({ children, className }: SwimlaneHeaderProps) => {
  return (
    <Header className={className}>
      <StyledHeading level="h3">{children}</StyledHeading>
    </Header>
  );
};
