import { ReactElement, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCustomer } from "lib/reducers/lookupData.selectors";
import { customerHasApp } from "lib/utility/customerHasApp.util";
import { useSelector } from "store";
import { useDispatch } from "store";
import { logout, selectAuthSession } from "store/reducers";

type RestrictedProps = {
  app?: string;
  children?: ReactElement;
};

export const Restricted = ({ app, children }: RestrictedProps): ReactElement | null => {
  const dispatch = useDispatch();
  const session = useSelector(selectAuthSession);
  const customer = useSelector(getCustomer);

  useEffect(() => {
    if (!session) {
      dispatch(logout());
    }
  }, [session]);

  if (!customer) {
    return null;
  }

  if (app) {
    const hasPermission = customerHasApp(customer?.apps, app);
    if (!hasPermission) {
      return <Navigate replace to="/restricted" />;
    }
  }

  return children || <Outlet />;
};
