import React, { FormEvent, useEffect, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createVehicle } from "store/reducers";
import { CreateVehicleRequest, TLocation, TVehicle, VehicleCategory, VehicleType } from "types";
import Avatar from "components/Avatar";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared/layouts";
import { VehicleTypeSelector } from "../VehicleTypeSelector/VehicleTypeSelector";

type CreateVehicleModalProps = {
  onSuccess?: (entity: TVehicle) => void;
  onFinished?: () => void;
};

export const CreateVehicleModal = ({ onSuccess }: CreateVehicleModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<Partial<CreateVehicleRequest>>({
    category: undefined,
    vehicleType: undefined,
    name: "",
    registration: "",
    imageUrl: "",
    available: false,
    liveStream: true,
    locationId: undefined,
  });
  const valid = Boolean(values.registration && values.vehicleType);

  useEffect(() => {
    let imageUrl = "";
    switch (values.vehicleType) {
      case "plane":
        imageUrl = "https://automify-prod-images.s3.eu-west-2.amazonaws.com/vehicles/plane.png";
        break;
      case "helicopter":
        imageUrl = "https://automify-prod-images.s3.eu-west-2.amazonaws.com/vehicles/helicopter.png";
        break;
      case "van":
        imageUrl = "https://automify-prod-images.s3.eu-west-2.amazonaws.com/vehicles/van.png";
        break;
      default:
        imageUrl = "https://automify-prod-images.s3.eu-west-2.amazonaws.com/automify-product-placeholder.png";
        break;
    }
    if (!imageUrl) return;
    setValues((prevState) => ({ ...prevState, imageUrl }));
  }, [values.vehicleType]);

  const handleChange = (name: keyof CreateVehicleRequest, value: string | number | undefined) => {
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const requestBody = values as CreateVehicleRequest;
      const entity = await dispatch(
        createVehicle({
          ...requestBody,
          name: requestBody.registration,
          available: !!requestBody.locationId,
        }),
      ).unwrap();
      closeModal();
      onSuccess && onSuccess(entity);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleChangeLocation = (name: string, value?: TLocation) => {
    handleChange("locationId", value?.id);
  };

  const handleImageChange = async (imageUrl: string) => {
    handleChange("imageUrl", imageUrl);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader title="Create a vehicle" />
        <ModalContent>
          <VSpace>
            <FieldGroup label="Registration or name">
              <TextInput
                input={{
                  name: "registration",
                  placeholder: "Enter the vehicle registration or name",
                  autoFocus: true,
                }}
                value={values.registration}
                onChange={handleChange}
              />
            </FieldGroup>
            <FieldGroup label="Type">
              <VehicleTypeSelector
                appearance="normal"
                name="vehicleType"
                value={values.vehicleType}
                onChange={(value?: VehicleType, category?: VehicleCategory) => {
                  handleChange("category", category);
                  handleChange("vehicleType", value);
                }}
              />
            </FieldGroup>
            <FieldGroup label="Location">
              <LocationSelector
                searchable
                appearance="normal"
                name="locationId"
                value={values.locationId}
                onChange={handleChangeLocation}
              />
            </FieldGroup>
            {values.vehicleType === "miscellaneous" && (
              <FieldGroup label="Image">
                <Avatar
                  canEdit
                  image={values.imageUrl}
                  hoverIcon="/images/icons/attachment_1.svg"
                  onChange={handleImageChange}
                />
              </FieldGroup>
            )}
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit" disabled={!valid}>
            Create vehicle
          </PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
