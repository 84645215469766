//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "components/Buttons/Buttons.module.scss";

export const ButtonGroup = ({ children, className, alignRight }) => (
  <div className={classNames(style.buttonGroup, { [style.buttonGroupAlignRight]: alignRight }, className)}>
    {children}
  </div>
);

ButtonGroup.defaultProps = {
  alignRight: false,
};

ButtonGroup.propTypes = {
  alignRight: PropTypes.bool,
  className: PropTypes.string,
};
