//@ts-nocheck
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import Authentication from "lib/api/authentication";
import { TextInput } from "components/Input";
import { Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import SubDomainInput from "components/inputs/SubDomainInput";
import { VSpace } from "components/shared";
import AuthForm from "pages/authentication/components/AuthForm";
import AuthFormTitle from "pages/authentication/components/AuthFormTitle";
import AuthSubmitButton from "pages/authentication/components/AuthSubmitButton";
import OnSuccess from "./OnSuccess";
import style from "./signup.module.scss";

const validateInputs = (values, setValidated) => {
  return () => {
    if (values.fullName && values.email && values.workspace) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };
};

const SignupPage = () => {
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    workspace: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (name, value) => setValues({ ...values, [name]: value });

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setSubmitting(true);
      setError(null);

      await Authentication.beginSignup(values);
      setSubmitted(true);
    } catch (err) {
      const message = get(err, "response.body.error.message", "Something went wrong");
      setError(message);
      setSubmitting(false);
    }
  };

  useEffect(validateInputs(values, setValidated), [values]);

  return submitted ? (
    <OnSuccess email={values.email} />
  ) : (
    <AuthForm onSubmit={handleSubmit}>
      <VSpace>
        <AuthFormTitle title="Let's get you setup" />
        <FieldGroup label="Full name">
          <TextInput
            input={{
              name: "fullName",
              placeholder: "Enter full name",
            }}
            value={values.fullName}
            onChange={handleChange}
          />
        </FieldGroup>
        <FieldGroup label="Email address">
          <TextInput
            input={{
              name: "email",
              placeholder: "Enter email address",
            }}
            value={values.email}
            onChange={handleChange}
          />
        </FieldGroup>
        <FieldGroup label="Workspace">
          <p className="text-muted">This is where you and your team will be able to access automify.</p>
          <SubDomainInput input={{ name: "workspace" }} value={values.workspace} onChange={handleChange} />
        </FieldGroup>
        {error && <Text className="text-danger">{error}</Text>}
        <AuthSubmitButton isDisabled={!validated || submitting}>Create your account</AuthSubmitButton>
        <Text className={style.disclaimer}>
          By continuing you agree to our{" "}
          <a href="/terms-and-conditions" target="_blank">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
          .
        </Text>
        <Text className={style.alreadyHaveAccount}>
          Already have an account? <Link to="/">Sign in</Link>
        </Text>
      </VSpace>
    </AuthForm>
  );
};

export default SignupPage;
