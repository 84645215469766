import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.toolStoreOrderItems;

export const selectToolStoreOrderItems = createSelector(selectState, (state) => state.data);

export const selectToolStoreOrderItemsList = createSelector(selectToolStoreOrderItems, (data) => Object.values(data));

export const selectToolStoreOrderItemByID = (id: UUID) => createSelector(selectToolStoreOrderItems, (data) => data[id]);

export const selectToolStoreOrderItemsByOrderID = (id: UUID) =>
  createSelector(selectToolStoreOrderItemsList, (data) => data.filter((entity) => entity.toolStoreOrderId === id));
