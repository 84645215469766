//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import InlineInput from "components/Typography/InlineInput";

const Subtitle = ({ isDark, value, placeholder, canEdit, onConfirm, onClick, children, className, inputClassName }) => (
  <h4 className={classNames("subtitle", { dark: isDark }, className)}>
    {canEdit ? (
      <InlineInput
        input={{ type: "text", placeholder }}
        className={inputClassName}
        defaultValue={value}
        onConfirm={onConfirm}
        onClick={onClick}
      />
    ) : (
      value || children
    )}
  </h4>
);

Subtitle.defaultProps = {
  marginTop: true,
  marginBottom: true,
};

Subtitle.propTypes = {
  isDark: PropTypes.bool,
  marginTop: PropTypes.bool,
  marginBottom: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  canEdit: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};

export default Subtitle;
