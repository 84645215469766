import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestProject } from "lib/actions/projects";
import { requestTask } from "lib/actions/tasks";
import { getTask } from "lib/reducers/tasks.selectors";
import Loading from "components/Loading";
import PageStage from "components/stages/PageStage";
import { TaskSheet } from "pages/apps/ProjectManagement/tasks/ListTask/components/TaskSheet";

export const ListTaskPage = () => {
  const dispatch = useDispatch();
  const task = useSelector(getTask);
  const { projectID, taskID } = useParams();

  useEffect(() => {
    fetch();
  }, [taskID]);

  const fetch = async () => {
    dispatch(requestProject(projectID));
    dispatch(requestTask(taskID));
  };

  return <PageStage appearance="sheet">{task ? <TaskSheet task={task} /> : <Loading size="large" />}</PageStage>;
};
