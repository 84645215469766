import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Authentication from "lib/api/authentication";
import { PasswordInput } from "components/Input";
import { Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { VSpace } from "components/shared";
import PasswordRequirements from "pages/authentication/Login/PasswordRequirements";
import AuthForm from "pages/authentication/components/AuthForm";
import AuthFormTitle from "pages/authentication/components/AuthFormTitle";
import AuthSubmitButton from "pages/authentication/components/AuthSubmitButton";

const animation = "animate__animated animate__fast animate__fadeIn";

export const ChangePasswordSuccess = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dashboard", { replace: true });
  };
  return (
    <div>
      <VSpace>
        <AuthFormTitle title="Password changed" />
        <Text>You have successfully changed your password.</Text>
        <AuthSubmitButton onClick={handleClick}>Continue</AuthSubmitButton>
      </VSpace>
    </div>
  );
};

export const ChangePasswordPage = () => {
  const [values, setValues] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (name: string, value: string) => setValues({ ...values, [name]: value });

  const handlePasswordValidate = (isValid: boolean) => setValidPassword(isValid);

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault();
      setSubmitting(true);
      setError(null);
      await Authentication.changePassword(values.password);
      setSubmitted(true);
    } catch (err: any) {
      const message = err.response ? err.response.body.error.message : "Something went wrong";
      setError(message);
      setSubmitting(false);
    }
  };

  const isValid = validPassword && values.password === values.passwordConfirm;

  return submitted ? (
    <ChangePasswordSuccess />
  ) : (
    <AuthForm className={animation} onSubmit={handleSubmit}>
      <VSpace>
        <AuthFormTitle title="Create a new password" />
        <FieldGroup label="New password">
          <VSpace gap="gap-2">
            <PasswordInput
              input={{
                name: "password",
                placeholder: "Enter password",
                autoFocus: true,
              }}
              value={values.password}
              onChange={handleChange}
            />
            <PasswordRequirements password={values.password} onValidate={handlePasswordValidate} />
          </VSpace>
        </FieldGroup>
        <FieldGroup label="Confirm new password">
          <PasswordInput
            input={{
              name: "passwordConfirm",
              placeholder: "Confirm password",
            }}
            value={values.passwordConfirm}
            onChange={handleChange}
          />
        </FieldGroup>
        {error && <Text className="text-danger">{error}</Text>}
        <AuthSubmitButton isDisabled={!isValid || submitting}>Change password</AuthSubmitButton>
      </VSpace>
    </AuthForm>
  );
};
