//@ts-nocheck
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import get from "lodash/get";
import PropTypes from "prop-types";
import Resolve from "lib/Resolve";
import { requestAccessPolicyAttempts } from "lib/actions/storages";
import ReasonCodes from "lib/constants/ReasonCodes";
import { getAccessAttempts, getAccessAttemptsPaging } from "lib/reducers/storages.selectors";
import Icon from "components/Icon";
import StageHeader from "components/StageHeader";
import { SmartTable, SmartTablePagination } from "components/Table/SmartTable";
import { Text } from "components/Typography";
import { VehicleName } from "components/shared";

const StorageAccessHistory = ({ storage }) => {
  const dispatch = useDispatch();
  const { data } = useSelector(getAccessAttempts);
  const paging = useSelector(getAccessAttemptsPaging);

  const rowData = useMemo(() => {
    return data;
  }, [data]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Granted",
        Cell: ({ row }) => {
          const { authorised } = row.original;
          return authorised ? (
            <Icon src="/images/icons/tick_5.svg" color="success" />
          ) : (
            <Icon src="/images/icons/cross.svg" color="danger" />
          );
        },
      },
      {
        Header: "Reasons",
        Cell: ({ row }) => {
          const { authorised, decisions } = row.original;
          const filteredReasons = Array.isArray(decisions)
            ? decisions.filter(({ decision }) => decision.authorised === authorised)
            : [];
          let reasons = filteredReasons.reduce((reasons, { decision }) => {
            return {
              ...reasons,
              [decision.reason]: ReasonCodes.resolveItemLabel(decision.reason),
            };
          }, {});

          // Only show "No Match" if no other reasons are available
          const reasonLength = Object.values(reasons).length;
          if (reasonLength > 1 && reasons[ReasonCodes.ReasonCodeNoMatch]) {
            delete reasons[ReasonCodes.ReasonCodeNoMatch];
          }

          return <Text>{Object.values(reasons).join(", ")}</Text>;
        },
      },
      {
        Header: "Method",
        Cell: ({ row }) => {
          const { method } = row.original;
          return <Text className="capitalise">{method.toLowerCase()}</Text>;
        },
      },
      {
        Header: "Keycard / User",
        Cell: ({ row }) => {
          const { metadata, userId } = row.original;
          const keycardID = get(metadata, "keycardId", "");
          return userId ? <Text>{Resolve.resolveUserFullName(userId)}</Text> : keycardID;
        },
      },
      {
        Header: "Vehicle",
        Cell: ({ row }) => {
          const { vehicleId } = row.original;
          return vehicleId ? <VehicleName vehicleId={vehicleId} /> : null;
        },
      },
      {
        Header: "Date",
        Cell: ({ row }) => {
          const { dateOccurred } = row.original;
          return <Text>{dayjs(dateOccurred).format("MMM D, YYYY h:mm A")}</Text>;
        },
      },
    ];
  }, []);

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart, paging.pageLimit]);

  const fetch = (pageStart, pageLimit) => {
    dispatch(
      requestAccessPolicyAttempts(storage.accessPolicyId, {
        pageStart,
        pageLimit,
      }),
    );
  };

  return (
    <>
      <StageHeader title="Access history" />
      <SmartTable columns={columns} data={rowData} />
      <SmartTablePagination paging={paging} onChange={fetch} />
    </>
  );
};

StorageAccessHistory.defaultProps = {
  limit: 20,
};

StorageAccessHistory.propTypes = {
  storage: PropTypes.shape({
    id: PropTypes.string,
    accessPolicyId: PropTypes.number,
  }),
  className: PropTypes.string,
};

export default StorageAccessHistory;
