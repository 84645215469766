//@ts-nocheck
import React from "react";
import ProjectPriority from "lib/constants/ProjectPriority";
import { getProjectByID } from "lib/reducers/projects.selectors";
import { useSelector } from "store";
import StatusPill from "components/StatusPill";

export const ProjectPriorityPill = ({ projectID }) => {
  const project = useSelector(getProjectByID(projectID));
  if (!project) {
    return null;
  }
  switch (project.priority) {
    case ProjectPriority.Low:
      return <StatusPill color="success" size="small" text={ProjectPriority.resolveItemLabel(ProjectPriority.Low)} />;
    case ProjectPriority.Medium:
      return (
        <StatusPill color="warning" size="small" text={ProjectPriority.resolveItemLabel(ProjectPriority.Medium)} />
      );
    case ProjectPriority.High:
      return <StatusPill color="danger" size="small" text={ProjectPriority.resolveItemLabel(ProjectPriority.High)} />;
    case ProjectPriority.Urgent:
      return <StatusPill color="danger" size="small" text={ProjectPriority.resolveItemLabel(ProjectPriority.Urgent)} />;
    default:
      return null;
  }
};
