//@ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { requestStoreItems } from "lib/actions/marketplace";
import { getStoreItemsList } from "lib/reducers/storeItems.selectors";
import ShopItemCard from "pages/apps/Shop/shared/ShopItemCard/ShopItemCard";
import styles from "./ShopCollection.module.scss";

const ShopCollection = ({ className }) => {
  const dispatch = useDispatch();
  const items = useSelector(getStoreItemsList);
  useEffect(() => {
    dispatch(
      requestStoreItems({
        page: 1,
        limit: 50,
      }),
    );
  }, []);
  return (
    <div className={styles.ShopCollection}>
      {items.map((item) => (
        <ShopItemCard key={item.id} itemID={item.id} />
      ))}
    </div>
  );
};

ShopCollection.defaultProps = {};

ShopCollection.propTypes = {
  className: PropTypes.string,
};

export default ShopCollection;
