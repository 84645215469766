//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class ShopImportColumnTypes extends Lookup {
  NAME = "business";
  SUMMARY = "summary";
  DESCRIPTION = "description";
  SKU = "sku";
  PRICE_EXC_VAT = "price_exc_vat";
  DISCOUNT = "discount";
  QUANTITY = "quantity";
  SHIPPABLE = "shippable";
  IMAGE = "image";
  DOCUMENT = "document";
  METADATA = "metadata";

  constructor() {
    super();
    super._items = [
      {
        label: "Name",
        value: this.NAME,
        fieldName: "name",
      },
      {
        label: "Image",
        value: this.IMAGE,
        fieldName: "images",
      },
      {
        label: "Document",
        value: this.DOCUMENT,
        fieldName: "documents",
      },
      {
        label: "Metadata/Specification",
        value: this.METADATA,
        fieldName: "metadata",
      },
      {
        label: "Summary",
        value: this.SUMMARY,
        fieldName: "summary",
      },
      {
        label: "Description",
        value: this.DESCRIPTION,
        fieldName: "description",
      },
      {
        label: "SKU",
        value: this.SKU,
        fieldName: "sku",
      },
      {
        label: "Price (Excl VAT)",
        value: this.PRICE_EXC_VAT,
        fieldName: "priceExcVat",
      },
      {
        label: "Discount (0 to 100%)",
        value: this.DISCOUNT,
        fieldName: "discount",
      },
      {
        label: "Stock quantity",
        value: this.QUANTITY,
        fieldName: "quantity",
      },
      {
        label: "Shippable",
        value: this.SHIPPABLE,
        fieldName: "shippable",
      },
    ];
  }
}

export default new ShopImportColumnTypes();
