import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useModal } from "effects";
import Resolve from "lib/Resolve";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { padNumber } from "lib/utility/format";
import { useDispatch, useSelector } from "store";
import { fetchToolStoreOrderById, selectToolStoreOrderByID, updateToolStoreOrder } from "store/reducers";
import { UUID } from "types";
import Loading from "components/Loading";
import Anchor from "components/Typography/Anchor";
import { PrimaryButton, SecondaryButton } from "components/shared/buttons";
import { ToolStoreOrderItems } from "components/shared/features/toolStoreOrders/ToolStoreOrderItems/ToolStoreOrderItems";
import { ToolStoreOrderLocation } from "components/shared/features/toolStoreOrders/ToolStoreOrderLocation/ToolStoreOrderLocation";
import { ToolStoreOrderSlideOverKeyDates } from "components/shared/features/toolStoreOrders/ToolStoreOrderSlideOverKeyDates/ToolStoreOrderSlideOverKeyDates";
import { ToolStoreOrderStatusText } from "components/shared/features/toolStoreOrders/ToolStoreOrderStatusText";
import { ConfirmWithReasonModal } from "components/shared/generic/modals/ConfirmWithReasonModal/ConfirmWithReasonModal";
import { SlideOver, SlideOverBannerHeader } from "components/shared/slideOvers";

type ToolStoreOrderSlideOverProps = {
  toolStoreId?: UUID;
  toolStoreOrderId: UUID;
};

export const ToolStoreOrderSlideOver = ({ toolStoreOrderId, toolStoreId }: ToolStoreOrderSlideOverProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const entity = useSelector(selectToolStoreOrderByID(toolStoreOrderId));
  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  useEffect(() => {
    if (entity && toolStoreId) {
      dispatch(
        fetchToolStoreOrderById({
          toolStoreId,
          toolStoreOrderId,
        }),
      );
    }
  }, [entity, toolStoreOrderId, toolStoreId]);

  if (!entity) {
    return <Loading />;
  }

  const acceptOrder = async () => {
    try {
      setAccepting(true);
      await dispatch(
        updateToolStoreOrder({
          ...entity,
          status: "accepted",
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Processing order",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
    setAccepting(false);
  };

  const rejectOrder = async () => {
    openModal(
      <ConfirmWithReasonModal
        title="Rejection reason"
        description="Please give the reason to reject this order to confirm this action."
        onConfirm={async (reason: string) => {
          try {
            setRejecting(true);
            await dispatch(
              updateToolStoreOrder({
                ...entity,
                status: "rejected",
                rejectedReason: reason,
              }),
            ).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Order rejected",
                description: "You've rejected the order. We've notified the engineer.",
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
          setRejecting(false);
        }}
      />,
    );
  };

  const confirmOrderDelivered = async () => {
    try {
      if (!entity) {
        return;
      }
      setAccepting(true);
      await dispatch(
        updateToolStoreOrder({
          ...entity,
          status: "delivered",
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Order set to delivered",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
    setAccepting(false);
  };

  const cancelRejection = async () => {
    try {
      setAccepting(true);
      await dispatch(
        updateToolStoreOrder({
          ...entity,
          status: "pending",
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Order rejection reverted",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
    setAccepting(false);
  };

  const copyOrderUrl = async () => {
    if (entity && navigator.clipboard) {
      await navigator.clipboard.writeText(
        `${window.location.origin}/apps/storage/stores/${entity.toolStoreId}/orders/${entity.id}`,
      );
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Copied order link",
        }),
      );
    }
  };

  return (
    <SlideOver width="max-w-md">
      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
        <SlideOverBannerHeader title="Tool store order" />
        <div className="pb-10">
          <div className="pb-1 sm:pb-6">
            <div>
              <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                <div className="sm:flex-1">
                  <div>
                    <div className="flex items-center">
                      <h3 className="text-xl font-bold text-gray-900 sm:text-2xl pr-2 flex-1">
                        <ToolStoreOrderStatusText status={entity.status} />
                      </h3>
                      <span className="ml-3 inline-flex items-center gap-2 sm:ml-0">
                        {entity.status === "rejected" && (
                          <SecondaryButton loading={rejecting} disabled={accepting} onClick={cancelRejection}>
                            Cancel rejection
                          </SecondaryButton>
                        )}
                        <Menu as="div" className="relative inline-block text-left">
                          <Menu.Button className="inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span className="sr-only">Open options menu</span>
                            <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={`/apps/storage/stores/${entity.toolStoreId}/orders/${entity.id}`}
                                      target="_blank"
                                      className={classNames(
                                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                        "block px-4 py-2 text-sm",
                                      )}
                                    >
                                      View order
                                    </Link>
                                  )}
                                </Menu.Item>
                                {navigator.clipboard && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <span
                                        className={classNames(
                                          active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                          "block cursor-pointer px-4 py-2 text-sm",
                                        )}
                                        onClick={copyOrderUrl}
                                      >
                                        Copy order link
                                      </span>
                                    )}
                                  </Menu.Item>
                                )}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </span>
                    </div>
                    <p className="text-sm text-gray-500">Order #{padNumber(entity.orderNumber)}</p>
                  </div>
                  {entity.status === "pending" && (
                    <div className="mt-5 flex flex-wrap space-x-3">
                      <PrimaryButton loading={accepting} disabled={rejecting} onClick={acceptOrder}>
                        Processing order
                      </PrimaryButton>
                      <SecondaryButton loading={rejecting} disabled={accepting} onClick={rejectOrder}>
                        Reject
                      </SecondaryButton>
                    </div>
                  )}
                  {entity.status === "in_transit" && (
                    <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                      <PrimaryButton loading={accepting} onClick={confirmOrderDelivered}>
                        Order delivered
                      </PrimaryButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4 py-5 px-4 sm:px-6">
            {entity.dropOffLocationId && <ToolStoreOrderLocation toolStoreOrderId={toolStoreOrderId} />}
            {entity.createdByUserId && (
              <div>
                <dt className="font-medium text-gray-500">Ordered by</dt>
                <dd className="flex mt-1 text-gray-900 sm:col-span-2">
                  {Resolve.resolveUserFullName(entity.createdByUserId)}
                  <Anchor to={`/configuration/users/${entity.createdByUserId}`} target="_blank">
                    <ExternalLinkIcon className="w-5 ml-2" />
                  </Anchor>
                </dd>
              </div>
            )}
            {entity.rejectedReason && (
              <div>
                <dt className="font-medium text-gray-500">Reason for rejection</dt>
                <dd className="flex mt-1 text-gray-900 sm:col-span-2">{entity.rejectedReason}</dd>
              </div>
            )}
          </div>

          <div className="py-5">
            <ToolStoreOrderItems toolStoreOrderId={toolStoreOrderId} />
          </div>

          <div className="py-5">
            <ToolStoreOrderSlideOverKeyDates toolStoreOrderId={toolStoreOrderId} />
          </div>
        </div>
      </div>
    </SlideOver>
  );
};
