//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import classes from "components/CardSection/CardSection.module.scss";

const CardSection = ({ title, className, children }) => (
  <div className={classNames(classes.section, classes.sectionBorder, className)}>
    {title ? <p className={classes.sectionTitle}>{title}</p> : null}
    {children}
  </div>
);

CardSection.defaultProps = {};

CardSection.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default CardSection;
