import styled from "styled-components";

export const SidebarImage = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 10rem;
  border-radius: 0.5rem;
`;
