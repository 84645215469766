import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { fetchRagControllerById, selectRagControllerByID } from "store/reducers";
import { PageLoading } from "components/shared";
import PageStage from "components/stages/PageStage";
import { RagControllerDashboard } from "pages/features/ragControllers/RagControllerDashboard/RagControllerDashboard";
import { RagControllerRagBinsPage } from "pages/features/ragControllers/RagControllerRagBinsPage/RagControllerRagBinsPage";
import { RagControllerSettings } from "pages/features/ragControllers/RagControllerSettings/RagControllerSettings";

export const RagControllerPage = () => {
  const dispatch = useDispatch();
  const { pathname: url } = useLocation();
  const { ragControllerID } = useParams();
  const entity = useSelector(selectRagControllerByID(ragControllerID || ""));

  // Fetch the tool room if it is not in memory yet
  useEffect(() => {
    if (!ragControllerID) return;
    if (entity) return;
    dispatch(fetchRagControllerById(ragControllerID));
  }, [ragControllerID, entity]);

  if (!entity || !ragControllerID) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <Routes>
        <Route path="dashboard" element={<RagControllerDashboard ragControllerId={ragControllerID} />} />
        <Route path="bins" element={<RagControllerRagBinsPage ragControllerId={ragControllerID} />} />
        <Route path="settings" element={<RagControllerSettings ragControllerId={ragControllerID} />} />
        <Route path="" element={<Navigate replace to={`${url}/dashboard`} />} />
      </Routes>
    </PageStage>
  );
};
