//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class BrandingKeys extends Lookup {
  // Portal
  PortalFavIconKey = "portalFavIcon";
  PortalIconKey = "portalIcon";
  PortalLogoKey = "portalLogo";

  // Sentry
  SentryLogoKey = "sentryLogo";
  SentryBackgroundColorKey = "sentryBackgroundColor";

  constructor() {
    super();
    super._items = [
      {
        label: "Portal favicon",
        description: "Your company icon to be used in the browser tab",
        value: this.PortalFavIconKey,
      },
      {
        label: "Portal icon",
        description: "Your company icon used on smaller screens, reports, or emails",
        value: this.PortalIconKey,
      },
      {
        label: "Portal logo",
        description: "Your full company logo",
        value: this.PortalIconKey,
      },
      {
        label: "Storage logo",
        description: "The logo to be shown on your toolboxes and stores",
        value: this.PortalIconKey,
      },
      {
        label: "Storage background color",
        description: "The background color for your toolboxes",
        value: this.PortalIconKey,
      },
    ];
  }
}

export default new BrandingKeys();
