import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useSetSidebar from "effects/useSidebar";
import { requestReportSuccess } from "lib/actions/reports";
import ReportAPI from "lib/api/reports";
import Capabilities from "lib/constants/Capabilities";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getReport } from "lib/reducers/reports.selectors";
import { FixLater } from "types";
import { RestrictHasOneCapability, useRoleHasCapability } from "components/Restricted";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import Sidebar, { MenuSeparator, SidebarHeader } from "./Sidebar";

export const ReportAppSidebar = () => {
  useSetSidebar(true);
  const { reportID } = useParams();
  const url = `/apps/reporting/reports/${reportID}`;
  const dispatch = useDispatch();
  const report = useSelector(getReport);
  const canUpdate = useRoleHasCapability(Capabilities.REPORT_UPDATE);

  const saveReportName = async (value: FixLater) => {
    try {
      if (report) {
        const response = await ReportAPI.updateReport(report.id, {
          ...report,
          name: value,
        });
        dispatch(requestReportSuccess(response));
      }
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <Sidebar>
      {report && <SidebarHeader canEdit={canUpdate} value={report.name} onConfirm={saveReportName} />}
      <MenuSeparator no />
      <MenuItem to={`${url}/exports`} icon="/images/icons/report_1.svg">
        Exports
      </MenuItem>
      <MenuItem to={`${url}/template`} icon="/images/icons/paintbrush_1.svg">
        Template
      </MenuItem>
      <RestrictHasOneCapability capabilities={[Capabilities.REPORT_UPDATE, Capabilities.REPORT_REMOVE]}>
        <MenuSeparator />
        <MenuItem to={`${url}/settings`} icon="/images/icons/config_2.svg">
          Settings
        </MenuItem>
      </RestrictHasOneCapability>
    </Sidebar>
  );
};

export default ReportAppSidebar;
