import { ComponentProps } from "react";
import emojiData from "@emoji-mart/data";
import { EmojiHappyIcon, PhotographIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import styled from "styled-components";
import { FixLater } from "types";
import { Title } from "components/Typography";
import { ButtonGroup } from "components/shared";
import { TransparentButton } from "components/shared/buttons";
import { ProjectSheetCoverImage } from "components/shared/features/projects/ProjectSheetCoverImage";
import { ProjectSheetIcon } from "components/shared/features/projects/ProjectSheetIcon";

const AddOnButton = styled(TransparentButton)`
  padding: 0.25rem 0.5rem;
`;

type SheetHeaderProps = ComponentProps<"div"> & {
  title: string;
  coverImageUrl?: string;
  icon?: string;
  onIconChange?: (value?: string) => void;
  onCoverImageChange?: (imageUrl?: string) => void;
  onTitleChange?: (value: string) => void;
  disabled?: boolean;
};

export const SheetHeader = styled(
  ({
    title,
    coverImageUrl,
    icon,
    onIconChange,
    onCoverImageChange,
    onTitleChange,
    className,
    disabled,
  }: SheetHeaderProps) => {
    const useRandomIcon = () => {
      const { emojis } = emojiData as { emojis: FixLater };
      const emojiKeys = Object.keys(emojis);
      const randomEmojiKey = emojiKeys[Math.floor(Math.random() * emojiKeys.length)];
      const randomEmoji = emojis[randomEmojiKey] as { skins: FixLater[] };
      if (!randomEmoji.skins?.length) return;
      onIconChange?.(randomEmoji.skins[0].native);
    };

    return (
      <div className={classNames(className, "flex flex-col gap-2")}>
        {typeof coverImageUrl === "string" && (
          <ProjectSheetCoverImage imageUrl={coverImageUrl} onChange={onCoverImageChange} />
        )}
        {typeof icon === "string" && <ProjectSheetIcon value={icon} onChange={onIconChange} />}
        <div>
          <ButtonGroup>
            <AddOnButton
              width="w-auto"
              flex="flex-none"
              leadingIcon={EmojiHappyIcon}
              textColor="text-gray-400"
              onClick={typeof icon === "string" ? () => onIconChange?.(undefined) : useRandomIcon}
            >
              {typeof icon === "string" ? "Remove icon" : "Add icon"}
            </AddOnButton>
            <AddOnButton
              width="w-auto"
              flex="flex-none"
              leadingIcon={PhotographIcon}
              textColor="text-gray-400"
              onClick={() => onCoverImageChange?.(typeof coverImageUrl === "string" ? undefined : "")}
            >
              {typeof coverImageUrl === "string" ? "Remove cover" : "Add cover"}
            </AddOnButton>
          </ButtonGroup>
          <Title canEdit={!disabled} value={title} onConfirm={(value) => onTitleChange?.(value)} />
        </div>
      </div>
    );
  },
)`
  ${AddOnButton} {
    transition: 150ms opacity ease-in-out;
    opacity: 0;
  }

  ${ProjectSheetCoverImage} + ${ProjectSheetIcon} {
    z-index: 1;
    margin-top: -4rem;
    margin-left: 2.5rem;
  }

  &:hover {
    ${AddOnButton} {
      opacity: 1;
    }
  }
`;
