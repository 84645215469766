//@ts-nocheck
import Api from "./api";

class Checkouts extends Api {
  /**
   * Get checkouts
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>} Array of checkouts.
   */
  static async getCheckouts(queryParams = {}) {
    const response = await this.get("/checkouts").query(queryParams);
    return await response.body;
  }

  /**
   * Get a checkout
   * @async
   * @param {string} id The checkout id
   * @returns {Promise.<Object>} A single checkout.
   */
  static async getCheckout(id) {
    const response = await this.get(`/checkouts/${id}`);
    return await response.body.data;
  }

  /**
   * Create checkout
   * @async
   * @param {Object} checkout The checkout object
   * @returns {Promise.<Object>} ID and location of the new checkout.
   */
  static async createCheckout(checkout) {
    const response = await this.post("/checkouts").send(checkout);
    return await response.body.data;
  }

  // TODO: Update checkout

  /**
   * Create check-in
   * @async
   * @param {Object} checkin The check-in object
   * @returns {Promise}
   */
  static async createCheckin(checkin) {
    return await this.post("/check-in").send(checkin);
  }
}

export default Checkouts;
