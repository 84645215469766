import useSetSidebar from "effects/useSidebar";
import Capabilities from "lib/constants/Capabilities";
import Popover from "components/Popover";
import { RestrictHasOneCapability } from "components/Restricted";
import { Text } from "components/Typography";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import Sidebar, { MenuSeparator } from "components/sidebars/Sidebar";

export const ProjectsSidebar = () => {
  useSetSidebar(true);
  const url = `/apps/projects`;
  return (
    <Sidebar>
      <MenuItem to={`${url}`} icon="/images/icons/kanban_1.svg">
        Work orders
      </MenuItem>
      <Popover preferPlace="right" content={<Text>Coming soon!</Text>}>
        <MenuItem disabled to={`${url}/archives`} icon="/images/icons/layers_1.svg">
          Archives
        </MenuItem>
      </Popover>
      <MenuSeparator />
      <RestrictHasOneCapability capabilities={[Capabilities.PROJECT_TEMPLATE_UPDATE]}>
        <Popover preferPlace="right" content={<Text>Coming soon!</Text>}>
          <MenuItem to={`${url}/templates`} icon="/images/icons/template_1.svg" disabled={true}>
            Templates
          </MenuItem>
        </Popover>
      </RestrictHasOneCapability>
      <RestrictHasOneCapability capabilities={[Capabilities.PROJECT_UPDATE, Capabilities.PROJECT_REMOVE]}>
        <Popover preferPlace="right" content={<Text>Coming soon!</Text>}>
          <MenuItem to={`${url}/settings`} icon="/images/icons/config_2.svg" disabled={true}>
            Settings
          </MenuItem>
        </Popover>
      </RestrictHasOneCapability>
    </Sidebar>
  );
};

export default ProjectsSidebar;
