import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { streamUpdateToolStoreOrderItem } from "store/reducers/toolStoreOrderItems/toolStorageOrderItems.actions";
import {
  createToolStoreOrderItem,
  fetchToolStoreOrderItems,
  updateToolStoreOrderItem,
  deleteToolStoreOrderItem,
  fetchToolStoreOrderItemById,
} from "store/reducers/toolStoreOrderItems/toolStoreOrderItems.thunks";
import {
  createToolStoreOrder,
  fetchToolStoreOrderById,
  fetchToolStoreOrders,
  fetchToolStoreUserOrders,
  updateToolStoreOrder,
} from "store/reducers/toolStoreOrders";
import { TToolStoreOrder, TToolStoreOrderItem, UUID } from "types";

export type ToolStoreOrderItemsReducerStateData = Record<UUID, TToolStoreOrderItem>;

export interface ToolStoreOrderItemsReducerState {
  data: ToolStoreOrderItemsReducerStateData;
}

const initialState: ToolStoreOrderItemsReducerState = {
  data: {},
};

const handleToolStoreOrderReceive = (
  state: Draft<ToolStoreOrderItemsReducerState>,
  action: PayloadAction<TToolStoreOrder>,
) => {
  state.data = action.payload.items.reduce(
    (data: ToolStoreOrderItemsReducerStateData, value: TToolStoreOrderItem) => ({
      ...data,
      [value.id]: value,
    }),
    state.data,
  );
};

export const toolStoreOrderItemsSlice = createSlice({
  name: "toolStoreOrderItems",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // TODO: Add case for tool store orders.
      .addCase(fetchToolStoreOrderItems.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: ToolStoreOrderItemsReducerStateData, value: TToolStoreOrderItem) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchToolStoreOrderItemById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createToolStoreOrderItem.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateToolStoreOrderItem.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(streamUpdateToolStoreOrderItem, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteToolStoreOrderItem.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      })

      .addCase(fetchToolStoreOrders.fulfilled, (state, action) => {
        for (const entity of action.payload.results) {
          for (const item of entity.items) {
            state.data[item.id] = item;
          }
        }
      })
      .addCase(fetchToolStoreUserOrders.fulfilled, (state, action) => {
        for (const entity of action.payload.results) {
          for (const item of entity.items) {
            state.data[item.id] = item;
          }
        }
      })
      .addCase(fetchToolStoreOrderById.fulfilled, handleToolStoreOrderReceive)
      .addCase(createToolStoreOrder.fulfilled, handleToolStoreOrderReceive)
      .addCase(updateToolStoreOrder.fulfilled, handleToolStoreOrderReceive);
  },
});

export const toolStoreOrderItemsReducer = toolStoreOrderItemsSlice.reducer;
