//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  tasks: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  task: {
    fetching: false,
    error: null,
    data: null,
  },
  taskBlocks: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_TASKS_REQUEST:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_TASKS_RECEIVE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_TASKS_FAILURE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.GET_TASK_REQUEST:
      return {
        ...state,
        task: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_TASK_RECEIVE:
      return {
        ...state,
        task: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_TASK_FAILURE:
      return {
        ...state,
        task: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.GET_TASK_BLOCKS_REQUEST:
      return {
        ...state,
        taskBlocks: {
          ...state.taskBlocks,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_TASK_BLOCKS_RECEIVE:
      return {
        ...state,
        taskBlocks: {
          ...state.taskBlocks,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_TASK_BLOCKS_FAILURE:
      return {
        ...state,
        taskBlocks: {
          ...state.taskBlocks,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    default:
      return state;
  }
};
