//@ts-nocheck
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Files from "lib/api/files";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import { useSelector } from "store";
import { PrimaryButton } from "components/Buttons";
import { Subheader } from "components/Typography";
import ShopItemDocument from "pages/apps/Shop/shared/ShopItemDocument/ShopItemDocument";
import styles from "./ShopItemDocuments.module.scss";

const ShopItemDocuments = ({ id, editable, className }) => {
  const storeItem = useSelector(getStoreItemByID(id));
  const sortedDocuments = useMemo(() => {
    return (
      storeItem?.documents?.sort((left, right) => {
        if (left.order < right.order) return -1;
        if (left.order > right.order) return 1;
        return 0;
      }) || []
    );
  }, [storeItem]);

  const handleUploadDocument = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "*");
    input.onchange = async () => {
      try {
        const file = input.files[0];
        const { name } = file;
        const data = await Files.upload(file);
        // TODO: Dispatch create product item document
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
    input.click();
  };

  return (
    storeItem && (
      <div className={styles.ShopItemDocuments}>
        <Subheader>Documents</Subheader>
        <ul className={styles.ShopItemDocuments_preview}>
          {sortedDocuments?.map((document) => (
            <li key={`${id}_${document.id}`}>
              <ShopItemDocument editable={editable} itemId={id} documentId={document.id} />
            </li>
          ))}
        </ul>
        {editable && <PrimaryButton onClick={handleUploadDocument}>Upload document</PrimaryButton>}
      </div>
    )
  );
};

ShopItemDocuments.defaultProps = {};

ShopItemDocuments.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
};

export default ShopItemDocuments;
