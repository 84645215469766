import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { ToolStorageProvider } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { useDispatch, useSelector } from "store";
import {
  createStorageViewer,
  fetchStorageById,
  fetchCaseScannerById,
  selectStorageByID,
  selectCaseScannerByID,
} from "store/reducers";
import { PageLoading } from "components/shared";
import PageStage from "components/stages/PageStage";
import { CaseScannerAccessControlPage } from "pages/features/caseScanners/CaseScannerAccessControlPage/CaseScannerAccessControlPage";
import { CaseScannerSettingsPage } from "pages/features/caseScanners/CaseScannerSettingsPage/CaseScannerSettingsPage";
import { CaseScannerUsagePage } from "pages/features/caseScanners/CaseScannerUsagePage/CaseScannerUsagePage";
import { StorageAlertPage, StorageAlertsPage } from "pages/features/storageAlerts";

export const CaseScannerPage = () => {
  const dispatch = useDispatch();
  const { pathname: url } = useLocation();
  const { caseScannerID } = useParams();
  const entity = useSelector(selectCaseScannerByID(caseScannerID || ""));
  const storageID = entity?.storageId;
  const storage = useSelector(selectStorageByID(storageID || ""));

  // Fetch the case scanner if it is not in memory yet
  useEffect(() => {
    if (!caseScannerID) return;
    if (entity) return;
    dispatch(fetchCaseScannerById(caseScannerID));
  }, [caseScannerID, entity]);

  useEffect(() => {
    if (!storageID) return;
    if (storage) return;
    dispatch(fetchStorageById(storageID));
  }, [storageID, storage]);

  useEffect(() => {
    if (!storageID) return;
    dispatch(createStorageViewer(storageID));
  }, [storageID]);

  if (!entity || !storage || !storageID || !caseScannerID) {
    return <PageLoading />;
  }

  return (
    <ToolStorageProvider storageId={storageID}>
      <PageStage>
        <Routes>
          <Route path="usage" element={<CaseScannerUsagePage caseScannerId={caseScannerID} />} />
          <Route path="access_control" element={<CaseScannerAccessControlPage />} />
          <Route path="alerts" element={<StorageAlertsPage />} />
          <Route path="alerts/:storageAlertID" element={<StorageAlertPage />} />
          <Route path="settings" element={<CaseScannerSettingsPage caseScannerId={caseScannerID} />} />
          <Route path="" element={<Navigate replace to={`${url}/usage`} />} />
        </Routes>
      </PageStage>
    </ToolStorageProvider>
  );
};
