//@ts-nocheck
import { useEffect } from "react";
import { setPageSidebar } from "lib/actions/userInterface";
import store from "store";

const useSidebar = (bool) => {
  useEffect(() => {
    store.dispatch(setPageSidebar(bool));
    return () => {
      store.dispatch(setPageSidebar(null));
    };
  }, []);
};

export default useSidebar;
