import { Button, GenericButtonProps } from "components/shared/buttons";

export type TransparentButtonProps = GenericButtonProps & {};

export const TransparentButton = ({ backgroundColor = "bg-transparent", ...otherProps }: TransparentButtonProps) => {
  return (
    <Button
      backgroundColor={backgroundColor}
      textColor="text-gray-700"
      backgroundHover="hover:bg-zinc-100"
      ringFocusColor="focus:ring-transparent"
      shadow=""
      {...otherProps}
    />
  );
};
