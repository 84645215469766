//@ts-nocheck
import Block from "lib/blocks/Block";

export default class TaskListBlockProperties extends Block {
  /**
   * @type {string}
   */
  taskListId;

  constructor(taskListId) {
    super();
    this.taskListId = taskListId;
  }
}
