//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Text } from "components/Typography";
import style from "./table.module.scss";

const TablePageSummary = ({ currentCount, paging, className }) => {
  return (
    <Text className={classNames(style.pageSummary, className)}>
      {currentCount}/{paging.totalCount}
    </Text>
  );
};

TablePageSummary.defaultProps = {
  currentCount: 0,
  paging: {
    totalCount: 0,
  },
};

TablePageSummary.propTypes = {
  currentCount: PropTypes.number.isRequired,
  paging: PropTypes.shape({
    totalCount: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
};

export default TablePageSummary;
