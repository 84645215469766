//@ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import BlockAPI from "lib/api/blocks";
import ProjectAPI from "lib/api/projects";
import BlockProperties from "lib/blocks/BlockProperties";
import BlockTypes from "lib/constants/BlockTypes";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import SelectProjectTemplate from "components/inputs/SelectProjectTemplate";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import style from "./RootModal/modal.module.scss";

const CreateProjectModal = ({ modal, onClose }) => {
  const { useTemplates, onSuccess } = modal.props;
  const [values, setValues] = useState({ name: "", projectTemplate: null });
  const [submitting, isSubmitting] = useState(false);

  const handleChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    isSubmitting(true);
    try {
      event.preventDefault();

      let project;
      if (values.projectTemplate) {
        project = await ProjectAPI.createProjectFromTemplate(values.projectTemplate.id);
      } else {
        project = await ProjectAPI.createProject({
          ...values,
        });
        const newBlock = await BlockAPI.createBlock({
          type: BlockTypes.TEXT,
          properties: BlockProperties.createForBlockType(BlockTypes.TEXT),
        });
        const newPageBlocks = [
          {
            projectId: project.id,
            blockId: newBlock.id,
            block: newBlock,
          },
        ];
        await ProjectAPI.updateProjectBlocks(project.id, newPageBlocks);
      }

      onClose();
      onSuccess && onSuccess(project);
    } catch (err) {
      handlePopupFlagError(err);
    }
    isSubmitting(false);
  };

  const valid = useTemplates ? !!values.projectTemplate : !!values.name;

  return (
    <Modal className={style.genericModal}>
      <ModalHeader title="Create a new job" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter job name",
                autoFocus: true,
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
          {false && (
            <FieldGroup label="Template">
              <SelectProjectTemplate name="projectTemplate" value={values.projectTemplate} onChange={handleChange} />
            </FieldGroup>
          )}
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton disabled={!valid || submitting} onClick={handleSubmit}>
          Create job {useTemplates && "from template"}
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

CreateProjectModal.defaultProps = {};

CreateProjectModal.propTypes = {
  className: PropTypes.string,
};

export default CreateProjectModal;
