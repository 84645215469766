//@ts-nocheck
import { put, takeEvery } from "redux-saga/effects";
import Logger from "../Logger";
import * as types from "../actions/actionTypes";
import * as actions from "../actions/assets";
import { storeLookup } from "../actions/lookupData";
import Api from "../api/assets";

function* fetchAsset(action) {
  try {
    const asset = yield Api.getAsset(action.payload);
    yield put(actions.receiveAsset(asset));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestAssetFailure(err));
  }
}

function* fetchAssets(action) {
  try {
    const { data, metadata } = yield Api.getAssets(action.payload);
    if (action.payload.lookup) {
      yield put(storeLookup("assets", data));
    }
    yield put(actions.receiveAssets(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestAssetsFailure(err));
  }
}

function* fetchAssetHistory(action) {
  try {
    const { data, metadata } = yield Api.getAssetHistory(action.payload, action.options);
    yield put(actions.receiveAssetHistory(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestAssetHistoryFailure(err));
  }
}

function* fetchCalibrationEvents(action) {
  try {
    const { data, metadata } = yield Api.getCalibrationEvents(action.payload, action.options);
    yield put(actions.receiveCalibrationEvents(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestCalibrationEventsFailure(err));
  }
}

function* assetsSaga() {
  yield takeEvery(types.REQUEST_ASSET, fetchAsset);
  yield takeEvery(types.REQUEST_ASSETS, fetchAssets);
  yield takeEvery(types.GET_ASSET_HISTORY_REQUEST, fetchAssetHistory);
  yield takeEvery(types.GET_CALIBRATION_EVENTS_REQUEST, fetchCalibrationEvents);
}

export default assetsSaga;
