//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Logger from "../lib/Logger";
import * as actions from "../lib/actions/assets";
import mergeProps from "./mergeProps";
import mergeStateStore from "./mergeStateStore";

export default function withAssets(WrappedComponent, autoFetch = false) {
  class Component extends React.Component {
    static propTypes = {
      actions: PropTypes.shape({
        requestAssets: PropTypes.func,
        requestAsset: PropTypes.func,
        requestCalibrationEvents: PropTypes.func,
        receiveAsset: PropTypes.func,
      }),
      stateStore: PropTypes.shape({
        assets: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
          paging: PropTypes.shape({
            pageStart: PropTypes.number,
            pageLimit: PropTypes.number,
            totalCount: PropTypes.number,
            nextPageStart: PropTypes.string,
            previousPageStart: PropTypes.string,
          }),
        }),
        assignedAssets: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
          paging: PropTypes.shape({
            pageStart: PropTypes.number,
            pageLimit: PropTypes.number,
            totalCount: PropTypes.number,
            nextPageStart: PropTypes.string,
            previousPageStart: PropTypes.string,
          }),
        }),
        asset: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.object,
        }),
        history: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
          paging: PropTypes.shape({
            pageStart: PropTypes.number,
            pageLimit: PropTypes.number,
            totalCount: PropTypes.number,
            nextPageStart: PropTypes.string,
            previousPageStart: PropTypes.string,
          }),
        }),
        calibrationEvents: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
          paging: PropTypes.shape({
            pageStart: PropTypes.number,
            pageLimit: PropTypes.number,
            totalCount: PropTypes.number,
            nextPageStart: PropTypes.string,
            previousPageStart: PropTypes.string,
          }),
        }),
      }),
    };

    componentDidMount() {
      const { actions } = this.props;
      if (autoFetch) {
        Logger.trace("auto-fetching assets");
        actions.requestAssets();
      }
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) =>
    mergeStateStore(state, {
      assets: state.assets.assets,
      asset: state.assets.asset,
      assignedAssets: state.assets.assignedAssets,
      calibrationEvents: state.assets.calibrationEvents,
      history: state.assets.history,
    });
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  });
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
}
