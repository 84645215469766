//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  evidence: {
    fetching: false,
    error: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_EVIDENCE_REQUEST:
      return {
        ...state,
        evidence: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_EVIDENCE_RECEIVE:
      return {
        ...state,
        evidence: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_EVIDENCE_FAILURE:
      return {
        ...state,
        evidence: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    default:
      return state;
  }
};
