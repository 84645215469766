//@ts-nocheck
import React from "react";
import CompanyBilling from "./CompanyBilling";

class CompanyBillingContainer extends React.Component {
  static propTypes = {};

  state = {};

  render() {
    return <CompanyBilling />;
  }
}

export default CompanyBillingContainer;
