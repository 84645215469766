//@ts-nocheck
import React from "react";
import Invoices from "./Invoices";

class InvoicesContainer extends React.Component {
  static propTypes = {};

  state = {};

  render() {
    return <Invoices />;
  }
}

export default InvoicesContainer;
