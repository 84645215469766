import classNames from "classnames";
import { FixLater } from "types";
import Icon from "components/Icon";
import { Header } from "components/Typography";
import style from "./sidebar.module.scss";

export const MenuSeparator = ({ noMarginTop, noMarginBottom }: FixLater) => {
  return (
    <div
      className={classNames(
        style.menuSeparator,
        {
          [style.noMarginTop]: noMarginTop,
        },
        { [style.noMarginBottom]: noMarginBottom },
      )}
    />
  );
};

export const SidebarHeader = ({ className, ...props }: FixLater) => {
  return <Header {...props} className={classNames(style.sidebarHeader, className)} />;
};

const Resize = ({ collapsed, onClick }: FixLater) => {
  const classes = classNames(style.resizeBar, {
    [style.resizeBarCollapsable]: typeof onClick === "function",
  });
  return (
    <div className={classes} onClick={onClick}>
      <div className={style.resizeBarHandle}>
        <Icon
          src={collapsed ? "/images/icons/caret-right.svg" : "/images/icons/caret-left.svg"}
          className={style.resizeBarHandleIcon}
        />
      </div>
    </div>
  );
};

export const Sidebar = ({ main, collapsed, className, children, onToggleCollapse }: FixLater) => {
  const animation = "animate__animated animate__slideInLeft";
  const classes = classNames(
    style.sidebar,
    { [style.mainSidebar]: main },
    { [style.collapsed]: collapsed },
    animation,
    className,
  );
  return (
    <div className={classes}>
      <nav className={style.sidebarNav}>{children}</nav>
      <Resize collapsed={collapsed} onClick={onToggleCollapse} />
    </div>
  );
};

export default Sidebar;
