import { useEffect, useState } from "react";
import classNames from "classnames";
import styled from "styled-components";
import { useDispatch, useSelector } from "store";
import {
  fetchVehicles,
  selectMainSideBarCollapsed,
  selectVehiclesFilters,
  setMainSideBarCollapsed,
  setVehiclesFilterLocationId,
} from "store/reducers";
import { TLocation } from "types";
import { MapLocationController, VehiclesCard, VehiclesMap } from "components/shared";

const Page = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 3rem;
  left: 3rem;
  bottom: 3rem;
  width: 24rem;
  gap: 1.5rem;
`;

type CustomerDomain = string;
type CustomerCoords = {
  lat: number;
  lng: number;
};

const defaultCoordinates = {
  lng: -0.4332728,
  lat: 51.47206,
};
const CustomerCoordinates: Record<CustomerDomain, CustomerCoords> = {
  "bristow.automify.co.uk": {
    lng: 4.7831163,
    lat: 52.9191022,
  },
  "bae.automify.co.uk": {
    lng: -0.1714112,
    lat: 53.0988222,
  },
  "tui.automify.co.uk": {
    lng: -0.3815132,
    lat: 51.880315,
  },
  "toolraptors.automify.co.uk": {
    lng: -3.0857434,
    lat: 51.5291862,
  },
  "bombardier.automify.co.uk": {
    lng: 0.0374467,
    lat: 51.321885,
  },
};

export const VehiclesMapPage = () => {
  const dispatch = useDispatch();
  const customerCoordinate = CustomerCoordinates[window.location.host] || defaultCoordinates;
  const [longitude, setLongitude] = useState<number>(customerCoordinate.lng);
  const [latitude, setLatitude] = useState<number>(customerCoordinate.lat);
  const [location, setLocation] = useState<TLocation>();
  const [pageReady, setPageReady] = useState<boolean>(false);
  const [mapReady, setMapReady] = useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);
  const filters = useSelector(selectVehiclesFilters);
  const collapsed = useSelector(selectMainSideBarCollapsed);

  // TODO: Get the location to render the map around based on the user.

  useEffect(() => {
    dispatch(setMainSideBarCollapsed(true));
  }, []);

  useEffect(() => {
    if (!collapsed) return;
    // The map must be drawn after the sidebar collapse animation is completed
    const id = setTimeout(() => setPageReady(true), 200);
    return () => clearTimeout(id);
  }, [collapsed]);

  useEffect(() => {
    dispatch(
      fetchVehicles({
        pageStart: 0,
        pageLimit: 999,
        live_stream: true,
        available: true,
        vehicle_type: filters.types,
        location_id: filters.locationId,
      }),
    );
  }, [filters]);

  useEffect(() => {
    dispatch(setVehiclesFilterLocationId(location?.id));
    if (!location) return;
    if (location.lng && location.lat) {
      setLongitude(location.lng);
      setLatitude(location.lat);
    }
  }, [location]);

  if (!pageReady) {
    return null;
  }

  return (
    <Page>
      <VehiclesMap
        longitude={longitude}
        latitude={latitude}
        offsetLeft={200}
        onReady={() => setMapReady(true)}
        onDragStart={() => setDragging(true)}
        onDragEnd={() => setDragging(false)}
      />
      <Content
        className={classNames(
          "transition ease-in-out transition-[opacity] duration-200",
          mapReady ? (dragging ? "opacity-60" : "opacity-100") : "opacity-0",
        )}
      >
        <MapLocationController value={location} onChange={setLocation} />
        <VehiclesCard />
      </Content>
    </Page>
  );
};
