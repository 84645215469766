//@ts-nocheck
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import arrayMove from "array-move";
import classNames from "classnames";
import PropTypes from "prop-types";
import { openModal } from "lib/actions/modal";
import Modals from "lib/constants/Modals";
import TaskStatus from "lib/constants/TaskStatus";
import { SecondaryButton } from "components/Buttons";
import StatusPill from "components/StatusPill";
import { Text } from "components/Typography";
import { SortableDataList, SortableDataListItem } from "components/lists/DataList";
import style from "./taskList.module.scss";

const Tasks = ({ taskListKey, taskListTasks, onChange }) => {
  const history = useNavigate();
  const { projectID } = useParams();

  const tasks = useMemo(() => {
    return taskListTasks.sort((a, b) => (a.order > b.order ? 1 : -1));
  }, [taskListTasks]);

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    let rearranged = arrayMove(tasks, oldIndex, newIndex);
    for (let i = 0; i < tasks.length; i++) {
      rearranged[i].order = i;
    }
    onChange(rearranged);
  };

  const renderStatus = (status) => {
    switch (status) {
      case TaskStatus.OPEN:
        return <StatusPill color="grey" text="Open" />;
      case TaskStatus.IN_PROGRESS:
        return <StatusPill color="primary" text="In-progress" />;
      case TaskStatus.COMPLETED:
        return <StatusPill color="success" text="Completed" />;
    }
  };

  const handleClick = (task) => {
    history(`/apps/projects/${projectID}/tasks/${task.id}`);
  };

  return (
    <SortableDataList onSortEnd={handleSortEnd} useDragHandle>
      {tasks.map(({ taskId, task, order }) => {
        return (
          <SortableDataListItem
            key={taskId}
            index={order}
            id={`${taskListKey}-${task.code}`}
            onClick={() => handleClick(task)}
            className={style.taskListItem}
          >
            <div className={style.taskListContent}>
              <Text value={task.title} className={style.taskListTitle} />
              {renderStatus(task.status)}
            </div>
          </SortableDataListItem>
        );
      })}
    </SortableDataList>
  );
};

const TaskList = ({ taskList, className, onChange, onUpdateTaskList }) => {
  const dispatch = useDispatch();

  const handleConfirm = (name, value) => {
    onUpdateTaskList && onUpdateTaskList(name, value);
  };

  const createTask = () => {
    dispatch(
      openModal(Modals.CREATE_TASK, {
        onSuccess: (task) => {
          onChange([
            ...taskList.tasks,
            {
              taskListId: taskList.id,
              taskId: task.id,
              task: task,
              order: taskList.tasks.length,
            },
          ]);
        },
      }),
    );
  };

  return (
    <div className={classNames(style.taskList, className)}>
      <div className={style.header}>
        <Text
          className={style.title}
          value={taskList.title}
          canEdit={true}
          onConfirm={(value) => handleConfirm("title", value)}
        />
        <SecondaryButton icon="/images/icons/plus_1.svg" onClick={createTask}>
          Create Task
        </SecondaryButton>
      </div>
      <Tasks taskListKey={taskList.key} taskListTasks={taskList.tasks} onChange={onChange} />
    </div>
  );
};

TaskList.defaultProps = {};

TaskList.propTypes = {
  taskList: PropTypes.object,
  className: PropTypes.string,
  onUpdateTaskList: PropTypes.func,
};

export default TaskList;
