import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.userKeycards;

export const selectUserKeycards = createSelector(selectState, (state) => state.data);

export const selectUserKeycardsList = createSelector(selectUserKeycards, (data) => Object.values(data));

export const selectUserKeycardByID = (id: UUID) => createSelector(selectUserKeycards, (data) => data[id]);

export const selectUserKeycardByKeycardID = (id: string) =>
  createSelector(selectUserKeycardsList, (list) => list.find(({ keycardId }) => keycardId === id));
