import { useEffect, useState } from "react";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchCaseScanners, selectCaseScanners } from "store/reducers";
import { TCaseScanner, UUID } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

type CaseScannerSelectorProps = {
  name: string;
  value?: TCaseScanner | UUID;
  onChange: (name: string, value: TCaseScanner) => void;
  placeholder?: string;
  appearance?: "inline" | "normal";
  searchable?: boolean;
  menuPlacement?: string;
  className?: string;
  onlineOnly?: boolean;
};

export const CaseScannerSelector = ({
  name,
  value,
  onChange,
  placeholder = "Select case scanner",
  appearance = "normal",
  className,
  menuPlacement,
  searchable = false,
  onlineOnly = false,
}: CaseScannerSelectorProps): JSX.Element => {
  const dispatch = useDispatch();
  const entities = useSelector(selectCaseScanners);
  const [ids, setIds] = useState<UUID[]>([]);
  const input = {
    type: "select",
    name,
    placeholder,
    labelKey: "label",
    valueKey: "id",
    menuPlacement,
    options: ids.map((id) => ({
      ...entities[id],
      label: entities[id]?.storage?.name,
    })),
    isSearchable: searchable,
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const { results } = await dispatch(fetchCaseScanners({ pageLimit: 999 })).unwrap();
      setIds(
        results
          .filter(({ storage }) => {
            if (!onlineOnly) return true;
            return storage?.online;
          })
          .map(({ id }) => id),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return appearance === "inline" ? (
    <Text
      canEdit
      input={input}
      value={value as any}
      onConfirm={(value) => onChange(name, value)}
      className={className}
    />
  ) : (
    <SelectInput input={input} value={value} onChange={onChange} className={className} />
  );
};
