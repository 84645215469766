//@ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { requestStoreItem } from "lib/actions/marketplace";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import Loading from "components/Loading";
import PageStage from "components/stages/PageStage";
import ShopItemPageDashboard from "pages/apps/Shop/ShopItemPage/ShopItemPageDashboard";

const ShopItemPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { itemID } = params;
  const storeItem = useSelector(getStoreItemByID(itemID));

  useEffect(() => {
    dispatch(requestStoreItem(itemID));
  }, [itemID]);

  return (
    <PageStage>{storeItem ? <ShopItemPageDashboard itemID={storeItem.id} /> : <Loading size="large" />}</PageStage>
  );
};

ShopItemPage.defaultProps = {};

ShopItemPage.propTypes = {
  className: PropTypes.string,
};

export default ShopItemPage;
