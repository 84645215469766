//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { requestReportSuccess } from "lib/actions/reports";
import { getReport } from "lib/reducers/reports.selectors";
import withReportBuilder from "../../../../../../../hocs/withReportBuilder";
import style from "./panel.module.scss";

const useActiveCategory = (categories) => {
  const [activeGroup, setGroup] = useState(categories.length ? categories[0] : null);
  useEffect(() => {
    setGroup(categories.length ? categories[0] : null);
  }, [categories.length]);
  return [activeGroup, setGroup];
};

const Panel = ({ categories, layout, actions, className, closeDropdown }) => {
  const dispatch = useDispatch();
  const report = useSelector(getReport);
  const [activeCategory, setCategory] = useActiveCategory(categories);
  const handleSelect = (component) => {
    dispatch(
      requestReportSuccess({
        ...report,
        components: {
          ...report.components,
          [layout.id]: component,
        },
      }),
    );
    closeDropdown();
  };
  return (
    <div className={classNames(style.panel, className)}>
      <ul className={style.groups}>
        {categories.map((category) => (
          <li
            key={category.id}
            className={classNames({
              [style.active]: activeCategory && activeCategory.id === category.id,
            })}
            onClick={() => setCategory(category)}
          >
            {category.name}
          </li>
        ))}
      </ul>
      <ul className={style.items}>
        {activeCategory &&
          activeCategory.items.map((item) => (
            <li key={item.id} onClick={() => handleSelect(item)}>
              {item.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

Panel.defaultProps = {};

Panel.propTypes = {
  layout: PropTypes.object,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ),
  className: PropTypes.string,
  closeDropdown: PropTypes.func.isRequired,
};

export default withReportBuilder(Panel);
