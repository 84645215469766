//@ts-nocheck
import React, { useEffect, useState } from "react";
import cuid from "cuid";
import styled from "styled-components";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { PrimaryButton, SecondaryButton, WarningButton } from "components/Buttons";
import { FileInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { DrawerSVG } from "components/shared/features/svgs";

const StyledModal = styled(Modal)`
  min-width: calc(100% - 3rem);
  max-width: 50rem;
  margin: 0 1.5rem;
`;

export const ConfigureDrawerLayoutModal = ({ modal, onClose }) => {
  const { drawer, onSuccess } = modal.props;
  const [values, setValues] = useState({
    viewBox: "",
  });
  const [files, setFiles] = useState([]);
  const [fullSvg, setFullSvg] = useState(null);
  const [shapes, setShapes] = useState([]);
  const [drawerShape, setDrawerShape] = useState(null);
  const [groupShape, setGroupShape] = useState(null);
  const valid = fullSvg && drawerShape;

  useEffect(() => {
    if (fullSvg) {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(fullSvg, "image/svg+xml");
      const svgElement = svgDoc.querySelector("svg");
      if (!svgElement) {
        handlePopupFlagError(null, "No SVG element found in the file");
        return;
      }

      // Pull out the SVG height, width, and viewport
      const viewBox = svgElement.getAttribute("viewBox");
      setValues({
        ...values,
        viewBox: viewBox || "0 0 1000 1000",
      });

      // Now extract all the path shapes.
      setGroupShape(svgDoc.querySelector("g") || null);
      const shapes = [...svgDoc.querySelectorAll("path")].map((shape) => {
        const pathLength = shape.getTotalLength();
        return {
          id: cuid(),
          path: shape.getAttribute("d"),
          pathLength,
        };
      });
      setShapes(shapes);

      if (drawer.svgPath) {
        const defaultShape = shapes.find((shape) => shape.path === drawer.svgPath);
        if (defaultShape) {
          setDrawerShape(defaultShape);
        }
      } else {
        let largestShape = null;
        for (const shape of shapes) {
          if (!largestShape || shape.pathLength > largestShape.pathLength) {
            largestShape = shape;
          }
        }
        setDrawerShape(largestShape);
      }
    }
  }, [fullSvg]);

  useEffect(() => {
    if (drawer.fullSvg) {
      setFullSvg(drawer.fullSvg);
    }
  }, []);

  const handleUpload = async (_, files) => {
    try {
      setFiles(files);
      const file = files[0];
      const reader = new FileReader();
      reader.onload = function () {
        const svgText = reader.result;
        setFullSvg(svgText);
      };
      reader.readAsText(file);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      drawer.fullSvg = fullSvg;
      drawer.svgPath = drawerShape.path;
      onClose();
      onSuccess && onSuccess();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const onRemove = async (event) => {
    try {
      event.preventDefault();
      drawer.fullSvg = null;
      drawer.svgPath = null;
      onClose();
      onSuccess && onSuccess();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleShapeClick = (shape) => {
    setDrawerShape(shape);
  };

  return (
    <StyledModal>
      <form onSubmit={handleSubmit}>
        <ModalHeader title="Configure layout" />
        <ModalContent>
          {shapes.length ? (
            <FieldGroup label="Select drawer shape">
              <DrawerSVG
                viewBox={values.viewBox}
                shapes={shapes}
                value={[drawerShape?.id]}
                groupShape={groupShape}
                onClick={handleShapeClick}
              />
            </FieldGroup>
          ) : (
            <FieldGroup label="Upload layout">
              <FileInput
                input={{
                  name: "file",
                  placeholder: "Upload a SVG file or drag and drop",
                }}
                value={files}
                onChange={handleUpload}
              />
            </FieldGroup>
          )}
        </ModalContent>
        <ModalFooter>
          {drawer.fullSvg && <WarningButton onClick={onRemove}>Remove layout</WarningButton>}
          <PrimaryButton type="submit" disabled={!valid} onClick={handleSubmit}>
            Save changes
          </PrimaryButton>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        </ModalFooter>
      </form>
    </StyledModal>
  );
};
