import { createSlice, isAnyOf, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import {
  createCalibrationCertificate,
  deleteCalibrationCertificate,
  fetchCalibrationCertificates,
} from "store/reducers/calibrationCertificates/calibrationCertificates.thunks";
import { TCalibrationCertificate, UUID } from "types";

export type CalibrationCertificatesReducerStateData = Record<UUID, TCalibrationCertificate>;

export interface CalibrationCertificatesReducerState {
  loading: string;
  page: UUID[];
  data: CalibrationCertificatesReducerStateData;
}

const initialState: CalibrationCertificatesReducerState = {
  loading: "",
  page: [],
  data: {},
};

export const calibrationCertificatesSlice = createSlice({
  name: "calibrationCertificates",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCalibrationCertificates.pending, (state, action) => {
        state.page = [];
      })
      .addCase(fetchCalibrationCertificates.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: CalibrationCertificatesReducerStateData, value: TCalibrationCertificate) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
        state.page = action.payload.results.map(({ id }) => id);
      })
      .addCase(createCalibrationCertificate.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteCalibrationCertificate.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg.certificateId];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addMatcher(isPending, (state, action) => {
        state.loading = action.type;
      })
      .addMatcher(isAnyOf(isFulfilled, isRejected), (state) => {
        state.loading = "";
      });
  },
});

export const calibrationCertificatesReducer = calibrationCertificatesSlice.reducer;
