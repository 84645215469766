import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { ThunkError, handleThunkError } from "store/utils/thunk";
import {
  CreateAssetIssueProblemRequest,
  CreateAssetIssueProblemResponse,
  DataResponse,
  FetchAssetIssueProblemsRequest,
  PagedDataResponse,
  Pagination,
  TAssetIssueProblem,
  UUID,
  UpdateAssetIssueProblemRequest,
  UpdateAssetIssueProblemResponse,
} from "types";

export const fetchAssetIssueProblems = createAsyncThunk(
  "assetIssueProblems/fetchAssetIssueProblems",
  async (request: FetchAssetIssueProblemsRequest): Promise<{ results: TAssetIssueProblem[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TAssetIssueProblem[]>>("/asset_issue_problems", request);
    return { results: data, paging: metadata };
  },
);

export const fetchAssetIssueProblemById = createAsyncThunk(
  "assetIssueProblems/fetchAssetIssueProblemById",
  async (id: UUID): Promise<TAssetIssueProblem> => {
    const { data } = await API.GET<DataResponse<TAssetIssueProblem>>(`/asset_issue_problems/${id}`);
    return data;
  },
);

export const createAssetIssueProblem = createAsyncThunk<
  CreateAssetIssueProblemResponse["data"],
  CreateAssetIssueProblemRequest,
  ThunkError
>("assetIssueProblems/createAssetIssueProblem", async (request, { rejectWithValue }) => {
  try {
    const { data } = await API.POST<CreateAssetIssueProblemRequest, CreateAssetIssueProblemResponse>(
      "/asset_issue_problems",
      request,
    );
    return data;
  } catch (err) {
    return handleThunkError(rejectWithValue, err);
  }
});

export const updateAssetIssueProblem = createAsyncThunk<
  UpdateAssetIssueProblemResponse["data"],
  UpdateAssetIssueProblemRequest,
  ThunkError
>("assetIssueProblems/updateAssetIssueProblem", async (request, { rejectWithValue }) => {
  try {
    const { data } = await API.PUT<UpdateAssetIssueProblemRequest, UpdateAssetIssueProblemResponse>(
      `/asset_issue_problems/${request.id}`,
      request,
    );
    return data;
  } catch (err) {
    return handleThunkError(rejectWithValue, err);
  }
});

export const deleteAssetIssueProblem = createAsyncThunk(
  "assetIssueProblems/deleteAssetIssueProblem",
  async (id: UUID): Promise<void> => {
    await API.DELETE(`/asset_issue_problems/${id}`);
  },
);
