import styled from "styled-components";
import { useSelector } from "store";
import { selectLocations, selectVehicleByID } from "store/reducers";
import { TVehicle } from "types";
import { PlainCard } from "components/shared/cards";
import { TextBody } from "components/shared/typography";

const Image = styled.div<{ src: string; vehicleType?: string }>`
  background: url("${({ src }) => src}") no-repeat center;
  background-size: cover;
  position: absolute;

  // Be sure to normalise the vehicle image rather than changing these dimensions.
  height: 5rem;
  width: 5.5rem;
  right: 0;
  bottom: 0;
`;

const formatDescription = (vehicle: TVehicle): string => {
  if (vehicle.toolsAssigned === 0 && vehicle.ragsAssigned === 0) {
    return "Nothing outstanding";
  }
  if (vehicle.toolsAssigned > 0 && vehicle.ragsAssigned > 0) {
    return `${vehicle.toolsAssigned} tools and ${vehicle.ragsAssigned} rags outstanding`;
  }
  return vehicle.toolsAssigned > 0
    ? `${vehicle.toolsAssigned} tools outstanding`
    : `${vehicle.ragsAssigned} rags outstanding`;
};

type OutstandingVehicleCardProps = {
  vehicleId: string;
};

export const OutstandingVehicleCard = ({ vehicleId }: OutstandingVehicleCardProps) => {
  const locations = useSelector(selectLocations);
  const entity = useSelector(selectVehicleByID(vehicleId));
  if (!entity) return null;

  return (
    <PlainCard className="p-4 w-full md:w-80 relative overflow-hidden">
      <div className="flex flex-row gap-6">
        <Image src={entity.imageUrl} vehicleType={entity.vehicleType} />
        <div className="flex flex-col gap-4 flex-1">
          <div>
            <p className="font-medium text-gray-900">{entity.name}</p>
            {entity.locationId && (
              <TextBody textColor="text-gray-400" textSize="text-sm">
                {locations[entity.locationId]?.name}
              </TextBody>
            )}
            <TextBody>{formatDescription(entity)}</TextBody>
          </div>
        </div>
      </div>
    </PlainCard>
  );
};
