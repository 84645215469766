import styled from "styled-components";
import Loading from "components/Loading";

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.5rem;
`;

export const TableRowLoading = () => {
  return (
    <StyledRow>
      <Loading />
    </StyledRow>
  );
};
