//@ts-nocheck
import * as type from "./actionTypes";

/**
 * Open a modal
 * @param {string} name
 * @param {Object} [props={}]
 * @returns {{type: string, name: string, props: Object}}
 */
export function openModal(name, props = {}) {
  return {
    type: type.MODAL_OPEN,
    payload: {
      name,
      props,
    },
  };
}

/**
 * Close a opened modal
 * @returns {{type: string}}
 */
export function closeModal() {
  return {
    type: type.MODAL_CLOSE,
  };
}
