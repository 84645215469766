import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateToolStoreRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TToolStore,
  UpdateToolStoreRequest,
  UUID,
} from "types";

type FetchToolStoresParams = PaginationQueryParams;

export const fetchToolStores = createAsyncThunk(
  "toolStores/list",
  async (params: FetchToolStoresParams): Promise<{ results: TToolStore[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TToolStore[]>>("/tool_stores", params);
    return { results: data, paging: metadata };
  },
);

export const fetchToolStoreById = createAsyncThunk("toolStores/getById", async (id: UUID): Promise<TToolStore> => {
  const { data } = await API.GET<DataResponse<TToolStore>>(`/tool_stores/${id}`);
  return data;
});

export const fetchToolStoreByStorageId = createAsyncThunk(
  "toolStores/fetchToolStoreByStorageId",
  async (storageId: string): Promise<TToolStore> => {
    const { data } = await API.GET<DataResponse<TToolStore>>(`/storages/${storageId}/tool_store`);
    return data;
  },
);

export const fetchToolStoreQueueSizeById = createAsyncThunk(
  "toolStores/fetchToolStoreQueueSizeById",
  async (id: UUID): Promise<number> => {
    const { data } = await API.GET<DataResponse<number>>(`/tool_stores/${id}/queue_size`);
    return data;
  },
);

export const createToolStore = createAsyncThunk(
  "toolStores/create",
  async (request: CreateToolStoreRequest): Promise<TToolStore> => {
    const { data } = await API.POST<CreateToolStoreRequest, DataResponse<TToolStore>>("/tool_stores", request);
    return data;
  },
);

export const updateToolStore = createAsyncThunk(
  "toolStores/update",
  async (request: UpdateToolStoreRequest): Promise<TToolStore> => {
    const { data } = await API.PUT<UpdateToolStoreRequest, DataResponse<TToolStore>>(
      `/tool_stores/${request.id}`,
      request,
    );
    return data;
  },
);

export const deleteToolStore = createAsyncThunk("toolStores/delete", async (id: UUID): Promise<void> => {
  await API.DELETE(`/tool_stores/${id}`);
});
