//@ts-nocheck
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import classes from "./input.module.scss";

const Dropzone = ({ icon, placeholder, value, className, onChange }) => {
  const onDrop = useCallback((acceptedFiles) => onChange(acceptedFiles), []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div {...getRootProps()} className={className}>
      <input {...getInputProps()} />
      <div>
        {icon}
        {value.length > 0 ? (
          <ul className={classes.fileInputList}>
            {value.map(({ name, path }) => (
              <li key={path}>{name}</li>
            ))}
          </ul>
        ) : (
          <label className={classes.cursorPointer}>
            <Icon src="/images/icons/folder_1.svg" size="large" className={classes.fileInputIcon} />
            {placeholder}
          </label>
        )}
      </div>
    </div>
  );
};

Dropzone.defaultProps = {
  placeholder: "Drag & Drop your files here",
  className: "",
  value: [],
  icon: null,
};

Dropzone.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      type: PropTypes.string,
      size: PropTypes.number,
    }),
  ),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.node,
};

export default Dropzone;
