//@ts-nocheck
import React from "react";
import ReactSVG from "react-svg";
import classNames from "classnames";
import PropTypes from "prop-types";
import Text from "components/Typography/Text";
import style from "./radioInput.module.scss";

const RadioOption = ({ option, value, onChange, className }) => {
  const isSelected = () => {
    if (value === null) {
      return false;
    }
    if (typeof value === "object") {
      return option.value === value.value;
    } else {
      return option.value === value;
    }
  };
  const handleClick = () => (isSelected() ? onChange(null) : onChange(option));
  return (
    <div className={classNames(style.option, { [style.active]: isSelected() }, className)} onClick={handleClick}>
      <div>
        <Text className={style.name}>{option.label || option.name}</Text>
        {option.description && (
          <Text className={classNames(style.description, "text-gray-400")}>{option.description}</Text>
        )}
      </div>
      {isSelected() && <ReactSVG src="/images/icons/tick_5.svg" className={style.selectIcon} />}
    </div>
  );
};

RadioOption.defaultProps = {};

RadioOption.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default RadioOption;
