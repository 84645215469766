import { ReactNode } from "react";
import ReactSVG from "react-svg";
import styled from "styled-components";

const Overlay = styled.div<{ size: ClickOverlayProps["size"] }>`
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  background: rgba(71, 80, 89, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  & > * {
    ${({ size }) => ({
      width: size === "l" ? "2rem" : size === "m" ? "1.25rem" : "1rem",
      height: size === "l" ? "2rem" : size === "m" ? "1.25rem" : "1rem",
    })};
    color: white;
    display: inline-block;
    margin: auto;
  }
`;

const SVGIcon = styled(ReactSVG)`
  fill: white;
`;

type ClickOverlayProps = {
  size?: "s" | "m" | "l";
  icon?: string | ReactNode;
  className?: string;
};

export const ClickOverlay = ({ size = "m", icon, className }: ClickOverlayProps) => {
  if (!icon) return null;
  return (
    <Overlay size={size} className={className}>
      {typeof icon === "string" ? <SVGIcon src={icon} /> : icon}
    </Overlay>
  );
};
