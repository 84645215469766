//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  reports: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  templates: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  report: {
    fetching: false,
    error: null,
    data: null,
  },
  evidence: {
    fetching: false,
    error: null,
    data: null,
  },
  template: {
    fetching: false,
    error: null,
    data: null,
  },
  exports: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_REPORTS_REQUEST:
      return {
        ...state,
        reports: {
          ...state.reports,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_REPORTS_RECEIVE:
      return {
        ...state,
        reports: {
          ...state.reports,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_REPORTS_FAILURE:
      return {
        ...state,
        reports: {
          ...state.reports,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.GET_REPORT_TEMPLATES_REQUEST:
      return {
        ...state,
        templates: {
          ...state.templates,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_REPORT_TEMPLATES_RECEIVE:
      return {
        ...state,
        templates: {
          ...state.templates,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_REPORT_TEMPLATES_FAILURE:
      return {
        ...state,
        templates: {
          ...state.templates,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.GET_REPORT_TEMPLATE_REQUEST:
      return {
        ...state,
        template: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_REPORT_TEMPLATE_RECEIVE:
      return {
        ...state,
        template: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_REPORT_TEMPLATE_FAILURE:
      return {
        ...state,
        template: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.GET_REPORT_REQUEST:
      return {
        ...state,
        report: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_REPORT_RECEIVE:
      return {
        ...state,
        report: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_REPORT_FAILURE:
      return {
        ...state,
        report: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.GET_REPORT_EVIDENCE_REQUEST:
      return {
        ...state,
        evidence: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_REPORT_EVIDENCE_RECEIVE:
      return {
        ...state,
        evidence: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_REPORT_EVIDENCE_FAILURE:
      return {
        ...state,
        evidence: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.GET_REPORT_EXPORTS_REQUEST:
      return {
        ...state,
        exports: {
          ...state.exports,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_REPORT_EXPORTS_RECEIVE:
      return {
        ...state,
        exports: {
          ...state.exports,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_REPORT_EXPORTS_FAILURE:
      return {
        ...state,
        exports: {
          ...state.exports,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    default:
      return state;
  }
};
