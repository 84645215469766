import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { fetchAssetIssues } from "store/reducers/assetIssues/assetIssues.thunks";
import { TAssetIssue, UUID } from "types";

const selectState = (state: RootStore) => state.assetIssues;

export const selectAssetIssues = createSelector(selectState, (state) => state.data);

export const selectAssetIssuesLoading = createSelector(
  selectState,
  ({ loading }) => loading === fetchAssetIssues.pending.type,
);

export const selectAssetIssuesList = createSelector(selectAssetIssues, (data) => Object.values(data));

export const selectAssetIssuesPaging = createSelector(selectState, ({ paging }) => {
  return paging;
});

export const selectAssetIssuesPage = createSelector(selectState, ({ page, data }) => {
  return page.map((id) => data[id]).filter(Boolean);
});

export const selectAssetIssueByID = (id: UUID) =>
  createSelector(selectAssetIssues, (data): TAssetIssue | undefined => data[id]);
