//@ts-nocheck
import React from "react";
import classNames from "classnames";
import { Button, SecondaryButton } from "components/Buttons";
import { Paragraph } from "components/Typography";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import style from "./modal.module.scss";

const ConfirmationModal = ({ modal, onClose }) => {
  const {
    title = "Are you sure?",
    description,
    actionText = "Confirm",
    actionColor = "danger",
    onConfirm,
  } = modal.props;

  const handleConfirm = () => {
    onClose();
    if (typeof onConfirm === "function") {
      onConfirm();
    }
  };

  return (
    <Modal className={classNames(style.modal)}>
      <ModalHeader title={title} />
      {description && (
        <ModalContent className="text-center">
          <Paragraph className={style.description}>{description}</Paragraph>
        </ModalContent>
      )}
      <ModalFooter alignRight>
        <Button color={actionColor} type="submit" onClick={handleConfirm}>
          {actionText}
        </Button>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
