import React from "react";
import { useNavigate } from "react-router-dom";
import { useModal } from "effects";
import { TCaseScanner, UUID } from "types";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { CaseScannersTable, CreateCaseScannerModal } from "components/shared";
import PageStage from "components/stages/PageStage";

type ToolRoomCaseScannersPageProps = {
  toolRoomId: UUID;
};

export const ToolRoomCaseScannersPage = ({ toolRoomId }: ToolRoomCaseScannersPageProps) => {
  const navigate = useNavigate();
  const { openModal } = useModal();

  const createCaseScanner = () => {
    openModal(
      <CreateCaseScannerModal
        onSuccess={(entity: TCaseScanner) => {
          navigate(`/apps/storage/case_scanners/${entity.id}`);
        }}
      />,
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Case scanners"
        subtitle="Manage the case scanners assigned to your tool room."
        action={
          <SecondaryButton icon="/images/icons/plus_1.svg" onClick={createCaseScanner}>
            Create case scanner
          </SecondaryButton>
        }
      />
      <CaseScannersTable toolRoomId={toolRoomId} />
    </PageStage>
  );
};
