import { createSlice, Draft } from "@reduxjs/toolkit";
import { createAlertRule, deleteAlertRule } from "store/reducers/alertRules/alertRules.thunks";
import {
  fetchAlertById,
  fetchAlerts,
  createAlert,
  deleteAlert,
  updateAlert,
} from "store/reducers/alerts/alerts.thunks";
import {
  fetchStorageAlertById,
  createStorageAlert,
  updateStorageAlert,
} from "store/reducers/storageAlerts/storageAlerts.thunks";
import { TAlert, TAlertRule, UUID } from "types";

export type AlertRulesReducerStateData = Record<UUID, TAlertRule>;

export interface AlertRulesReducerState {
  data: AlertRulesReducerStateData;
}

const initialState: AlertRulesReducerState = {
  data: {},
};

const handleRulesFromAlert = (state: Draft<AlertRulesReducerState>, alert?: TAlert) => {
  if (alert?.rules) {
    state.data = alert?.rules?.reduce(
      (data: AlertRulesReducerStateData, value: TAlertRule) => ({
        ...data,
        [value.id]: value,
      }),
      state.data,
    );
  }
};

export const alertRulesSlice = createSlice({
  name: "alertRules",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createAlertRule.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteAlertRule.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg.id];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        action.payload.results.forEach((alert: TAlert) => {
          handleRulesFromAlert(state, alert);
        });
      })
      .addCase(fetchAlertById.fulfilled, (state, action) => {
        handleRulesFromAlert(state, action.payload);
      })
      .addCase(createAlert.fulfilled, (state, action) => {
        handleRulesFromAlert(state, action.payload);
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        handleRulesFromAlert(state, action.payload);
      })
      .addCase(deleteAlert.fulfilled, (state, action) => {
        // TODO: Remove all rules belonging to the alert
      })
      .addCase(fetchStorageAlertById.fulfilled, (state, action) => {
        handleRulesFromAlert(state, action.payload.alert);
      })
      .addCase(createStorageAlert.fulfilled, (state, action) => {
        handleRulesFromAlert(state, action.payload.alert);
      })
      .addCase(updateStorageAlert.fulfilled, (state, action) => {
        handleRulesFromAlert(state, action.payload.alert);
      });
  },
});

export const alertRulesReducer = alertRulesSlice.reducer;
