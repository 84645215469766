import { useState } from "react";
import { Link } from "react-router-dom";
import { MenuIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import styled from "styled-components";
import { getMe } from "lib/reducers/lookupData.selectors";
import { useSelector } from "store";
import PlatformLogo from "components/PlatformNavigationBar/PlatformLogo";
import { AppSidebar } from "components/sidebars";

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: white;
  z-index: 2;
`;

const MenuButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const ProfileImage = styled.div<{ imageUrl: string }>`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-image: url("${({ imageUrl }) => imageUrl}");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 1rem;
`;

type MobileNavbarProps = {
  className?: string;
};

export const MobileNavbar = ({ className }: MobileNavbarProps) => {
  const me = useSelector(getMe);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <AppSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Header className={classNames("border-b border-gray-200", className)}>
        <HeaderContent>
          <MenuButtonWrapper className="lg:hidden">
            <button
              type="button"
              className="-ml-2 bg-white p-2 rounded-md text-gray-600"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </MenuButtonWrapper>
        </HeaderContent>
        <HeaderContent className="flex-1 justify-center">
          <PlatformLogo />
        </HeaderContent>
        <HeaderContent>
          <Link to="/account/settings">
            <ProfileImage imageUrl={me?.profileImage} />
          </Link>
        </HeaderContent>
      </Header>
    </>
  );
};
