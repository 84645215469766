import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import store, { useSelector } from "store";
import { selectWorkspace, streamCreateVehicle, streamUpdateVehicle } from "store/reducers";
import { streamUpdateAsset } from "store/reducers/assets/assets.actions";
import { streamUpdateToolStoreOrderItem } from "store/reducers/toolStoreOrderItems/toolStorageOrderItems.actions";
import {
  streamCreateToolStoreOrder,
  streamUpdateToolStoreOrder,
} from "store/reducers/toolStoreOrders/toolStorageOrders.actions";
import { TAsset, TToolStoreOrder, TToolStoreOrderItem, TVehicle } from "types";

type RealtimeProviderProps = {
  debug?: boolean;
  children: ReactNode;
};

type RealtimeProviderContextProperties = {
  pusher?: Pusher;
};

export const RealtimeProviderContext = createContext<RealtimeProviderContextProperties>({});

export const useRealtime = (): RealtimeProviderContextProperties => {
  const context = useContext(RealtimeProviderContext);
  if (context === undefined) {
    throw new Error("useRealtime must be used within a RealtimeProviderContext");
  }
  return context;
};

const makeChannelName = (workspace: string, name: string) =>
  // TODO: Swap to "private-" when the backend is ready
  `stream-${workspace}-${name}`;

export const RealtimeProvider = ({ debug = false, children }: RealtimeProviderProps) => {
  const [pusher, setPusher] = useState<Pusher>();
  const workspace = useSelector(selectWorkspace);

  useEffect(() => {
    if (!process.env.REACT_APP_PUSHER_API_KEY) return;
    Pusher.logToConsole = debug;
    setPusher(
      new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
        cluster: "eu",
      }),
    );
  }, []);

  useEffect(() => {
    if (!pusher) return;
    if (!workspace) return;

    const toolStoreOrderChannel = pusher.subscribe(makeChannelName(workspace, "tool_store_order"));
    toolStoreOrderChannel.bind("created", (data: TToolStoreOrder) => {
      store.dispatch(streamCreateToolStoreOrder(data));
    });
    toolStoreOrderChannel.bind("updated", (data: TToolStoreOrder) => {
      store.dispatch(streamUpdateToolStoreOrder(data));
    });

    const toolStoreOrderItemChannel = pusher.subscribe(makeChannelName(workspace, "tool_store_order_item"));
    toolStoreOrderItemChannel.bind("updated", (data: TToolStoreOrderItem) => {
      store.dispatch(streamUpdateToolStoreOrderItem(data));
    });

    const assetChannel = pusher.subscribe(makeChannelName(workspace, "asset"));
    assetChannel.bind("updated", (data: TAsset) => {
      store.dispatch(streamUpdateAsset(data));
    });

    const vehicleChannel = pusher.subscribe(makeChannelName(workspace, "vehicle"));
    vehicleChannel.bind("created", (data: TVehicle) => {
      store.dispatch(streamCreateVehicle(data));
    });
    vehicleChannel.bind("updated", (data: TVehicle) => {
      store.dispatch(streamUpdateVehicle(data));
    });

    return () => {
      toolStoreOrderChannel.disconnect();
      toolStoreOrderItemChannel.disconnect();
      assetChannel.disconnect();
      vehicleChannel.disconnect();
    };
  }, [pusher, workspace]);

  return <RealtimeProviderContext.Provider value={{ pusher }}>{children}</RealtimeProviderContext.Provider>;
};
