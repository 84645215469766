import { createSlice } from "@reduxjs/toolkit";
import {
  streamCreateToolStoreOrder,
  streamUpdateToolStoreOrder,
} from "store/reducers/toolStoreOrders/toolStorageOrders.actions";
import {
  createToolStoreOrder,
  fetchToolStoreOrders,
  fetchToolStoreUserOrders,
  updateToolStoreOrder,
  deleteToolStoreOrder,
  fetchToolStoreOrderById,
} from "store/reducers/toolStoreOrders/toolStoreOrders.thunks";
import { TToolStoreOrder, UUID } from "types";

export type ToolStoreOrdersReducerStateData = Record<UUID, TToolStoreOrder>;

export interface ToolStoreOrdersReducerState {
  data: ToolStoreOrdersReducerStateData;
}

const initialState: ToolStoreOrdersReducerState = {
  data: {},
};

export const toolStoreOrdersSlice = createSlice({
  name: "toolStoreOrders",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchToolStoreOrders.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: ToolStoreOrdersReducerStateData, value: TToolStoreOrder) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchToolStoreUserOrders.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: ToolStoreOrdersReducerStateData, value: TToolStoreOrder) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchToolStoreOrderById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createToolStoreOrder.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateToolStoreOrder.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(streamCreateToolStoreOrder, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(streamUpdateToolStoreOrder, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteToolStoreOrder.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg.toolStoreOrderId];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const toolStoreOrdersReducer = toolStoreOrdersSlice.reducer;
