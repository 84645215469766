//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as actions from "../lib/actions/readers";
import mergeProps from "./mergeProps";
import mergeStateStore from "./mergeStateStore";

export default function withReaders(WrappedComponent) {
  class Component extends React.Component {
    static propTypes = {
      actions: PropTypes.shape({
        requestReaders: PropTypes.func,
        requestReader: PropTypes.func,
      }),
      stateStore: PropTypes.shape({
        readers: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
          paging: PropTypes.shape({
            pageStart: PropTypes.number,
            pageLimit: PropTypes.number,
            nextPageStart: PropTypes.string,
            previousPageStart: PropTypes.string,
          }),
        }),
        reader: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.object,
        }),
      }),
    };
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToProps = (state) =>
    mergeStateStore(state, {
      readers: state.readers.readers,
      reader: state.readers.reader,
    });
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  });
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
}
