import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "store";
import { fetchAssets, selectAssets } from "store/reducers";
import { FixLater, TAsset } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

export const AssetSelector = ({
  name,
  value,
  onChange,
  stateStore,
  placeholder = "Select a tool",
  appearance = "normal",
  className,
  filter,
  queryParams,
  ...otherProps
}: FixLater): JSX.Element => {
  const dispatch = useDispatch();
  const assetsMap = useSelector(selectAssets);
  const [ids, setIds] = useState<number[]>([]);
  const assets: TAsset[] = ids.map((id) => assetsMap[id]);
  const filtered: TAsset[] = filter ? assets.filter(filter) : assets;

  const input = {
    type: "select",
    name,
    placeholder,
    labelKey: "productName",
    valueKey: "id",
    options: filtered.map((asset) => ({
      ...asset,
      productName: asset.catalogProduct?.name,
    })),
  };

  useEffect(() => {
    const fetch = async () => {
      const { results } = await dispatch(fetchAssets({ pageLimit: 999, ...queryParams })).unwrap();
      setIds(results.map((result) => result.id));
    };
    fetch();
  }, []);

  return appearance === "inline" ? (
    <Text
      {...otherProps}
      value={value}
      canEdit={true}
      input={input}
      onConfirm={(value) => onChange(name, value)}
      className={className}
    />
  ) : (
    <SelectInput {...otherProps} input={input} value={value} onChange={onChange} className={className} />
  );
};
