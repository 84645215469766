//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  data: {},
  assets: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  assignedAssets: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  asset: {
    fetching: false,
    error: null,
    data: null,
  },
  history: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
  calibrationEvents: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 5,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.REQUEST_ASSETS:
      return {
        ...state,
        assets: {
          ...state.assets,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.REQUEST_ASSETS_FAILURE:
      return {
        ...state,
        assets: {
          ...state.assets,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.RECEIVE_ASSETS:
      return {
        ...state,
        data: action.payload.reduce((data, item) => ({ ...data, [item.id]: item }), state.data),
        assets: {
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.REQUEST_ASSET:
      return {
        ...state,
        asset: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.REQUEST_ASSET_FAILURE:
      return {
        ...state,
        asset: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.RECEIVE_ASSET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
        asset: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.REQUEST_ASSIGNED_ASSETS:
      return {
        ...state,
        assignedAssets: {
          fetching: true,
          error: null,
          data: [],
          paging: state.assignedAssets.paging,
        },
      };
    case type.RECEIVE_ASSIGNED_ASSETS:
      return {
        ...state,
        assignedAssets: {
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.FAILURE_ASSIGNED_ASSETS:
      return {
        ...state,
        assignedAssets: {
          fetching: false,
          error: action.payload,
          data: [],
          paging: state.assignedAssets.paging,
        },
      };
    case type.GET_ASSET_HISTORY_REQUEST:
      return {
        ...state,
        history: {
          ...state.history,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_ASSET_HISTORY_RECEIVE:
      return {
        ...state,
        history: {
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_ASSET_HISTORY_FAILURE:
      return {
        ...state,
        history: {
          ...state.history,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.GET_CALIBRATION_EVENTS_REQUEST:
      return {
        ...state,
        calibrationEvents: {
          ...state.history,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_CALIBRATION_EVENTS_RECEIVE:
      return {
        ...state,
        calibrationEvents: {
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_CALIBRATION_EVENTS_FAILURE:
      return {
        ...state,
        calibrationEvents: {
          ...state.history,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };

    default:
      return state;
  }
};
