//@ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { requestReportSuccess } from "lib/actions/reports";
import { getReport, getReportComponents } from "lib/reducers/reports.selectors";
import IconButton from "components/IconButton";
import LayoutTypes from "../../../LayoutTypes";
import Dropdown from "../../Dropdown";
import style from "../builder.module.scss";
import PlaceholderDropdown from "./PlaceholderDropdown";
import SectionEditDropdown from "./SectionEditDropdown";
import { RemoveIcon } from "./SectionEditDropdown";
import TableSpec from "./TableSpec";
import TextSpec from "./TextSpec";

const EditIcon = ({ onClick }) => (
  <IconButton icon="/images/icons/edit.svg" iconSize="medium" className={style.sectionEditIcon} onClick={onClick} />
);

const LayoutItem = ({ actions, layout, onEdit }) => {
  const dispatch = useDispatch();
  const report = useSelector(getReport);
  const components = useSelector(getReportComponents);
  const component = components[layout.id];

  if (layout.type === LayoutTypes.GROUP) {
    return (
      <div className={style.sectionGroup}>
        {layout.layout.map((section) => (
          <LayoutItem key={section.id} layout={layout} onEdit={onEdit} />
        ))}
      </div>
    );
  }

  const onRemove = () => {
    dispatch(
      requestReportSuccess({
        ...report,
        components: {
          ...report.components,
          [layout.id]: null,
        },
      }),
    );
  };

  return (
    <div className="report-content-section" style={layout.style}>
      {component ? (
        <Dropdown>
          {(isOpen, openDropdown, closeDropdown) => (
            <React.Fragment>
              {component.typeId === LayoutTypes.TEXT ? (
                <RemoveIcon onClick={onRemove} className={style.sectionEditIcon} />
              ) : (
                <EditIcon onClick={openDropdown} />
              )}
              {layout.type === LayoutTypes.TEXT ? <TextSpec layout={layout} component={component} /> : null}
              {layout.type === LayoutTypes.TABLE ? <TableSpec layout={layout} component={component} /> : null}
              {isOpen ? <SectionEditDropdown layout={layout} component={component} /> : null}
            </React.Fragment>
          )}
        </Dropdown>
      ) : (
        <PlaceholderDropdown layout={layout} />
      )}
    </div>
  );
};

LayoutItem.defaultProps = {};

LayoutItem.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    order: PropTypes.number,
  }),
  className: PropTypes.string,
};

export default LayoutItem;
