//@ts-nocheck
import React from "react";
import classNames from "classnames";
import { FixLater } from "types";
import IconButton from "../IconButton";
import OptionSelect from "../OptionSelector";
import style from "./table.module.scss";

const TablePagination = ({
  className,
  paging,
  hidePageLimit = false,
  onPrevPage,
  onNextPage,
  onChangeLimit,
}: FixLater) => {
  return (
    <div className={classNames(style.paginationWrapper, className)}>
      {!hidePageLimit && (
        <OptionSelect
          options={[1, 5, 10, 15, 20, 50]}
          value={paging.pageLimit}
          onSelect={onChangeLimit}
          className={style.limitSelector}
        />
      )}
      <div className={style.paginationButtons}>
        <IconButton
          icon="/images/icons/caret-left.svg"
          onClick={onPrevPage}
          disabled={paging.previousPageStart === null || paging.previousPageStart === ""}
        />
        <IconButton
          icon="/images/icons/caret-right.svg"
          onClick={onNextPage}
          disabled={paging.nextPageStart === null || paging.nextPageStart === ""}
        />
      </div>
    </div>
  );
};

export default TablePagination;
