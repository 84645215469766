import React, { ComponentProps } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import classes from "./layout.module.scss";

const NoticeBoard = ({ className }: ComponentProps<"aside">) => {
  return (
    <aside className={classNames(classes.noticeBoard, className)}>
      <div
        style={{
          backgroundImage:
            "url(https://automify-prod-images.s3.eu-west-2.amazonaws.com/aviation-outlook-report-hero.webp)",
        }}
        className={classes.noticeCompanyLogo}
      />
    </aside>
  );
};

NoticeBoard.defaultProps = {};

NoticeBoard.propTypes = {
  className: PropTypes.string,
};

export default NoticeBoard;
