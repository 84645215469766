//@ts-nocheck
import React from "react";
import classNames from "classnames";
import StorageModels from "lib/constants/StorageModels";
import { FixLater } from "types";
import style from "./toolboxPortrait.module.scss";

function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

const ToolboxPortrait = ({ model = "TCW207N", size, dirtyDrawers = [], openDrawers = [], className }: FixLater) => {
  const modelValue = StorageModels.resolveItemValue(model);
  const modelBaseUrl = `url(/images/toolboxes/${modelValue}_base.png)`;

  if (!Array.isArray(dirtyDrawers)) {
    dirtyDrawers = [];
  }
  if (!Array.isArray(openDrawers)) {
    openDrawers = [];
  }

  // Remove number from dirty drawer if it is currently opened so that we dont overlay two drawer images.
  let copyDirtyDrawers = [...dirtyDrawers];
  openDrawers.forEach((drawerNumber) => {
    removeItemAll(copyDirtyDrawers, drawerNumber);
  });
  return (
    <div className={classNames(style.container, { [style[size]]: true }, className)}>
      <img src="/images/toolboxes/toolbox_shadow.png" className={style.shadow} alt="" />
      <div className={style.modelBase} style={{ backgroundImage: modelBaseUrl }}>
        {copyDirtyDrawers.map((drawerNumber) => (
          <div
            key={drawerNumber}
            className={style.drawerSlice}
            style={{
              backgroundImage: `url(/images/toolboxes/${modelValue}_dirty_${drawerNumber}.png)`,
            }}
          />
        ))}
        {openDrawers.map((drawerNumber) => (
          <div
            key={drawerNumber}
            className={style.drawerSlice}
            style={{
              backgroundImage: `url(/images/toolboxes/${modelValue}_open_${drawerNumber}.png)`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

// ToolboxPortrait.defaultProps = {
//   model: "TCW207N",
//   dirtyDrawers: [],
//   openDrawers: [],
// };
//
// ToolboxPortrait.propTypes = {
//   model: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//   size: PropTypes.oneOf(["small", "medium", "large"]),
//   className: PropTypes.string,
//   dirtyDrawers: PropTypes.array,
//   openDrawers: PropTypes.array,
// };

export default ToolboxPortrait;
