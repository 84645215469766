//@ts-nocheck
import moment from "moment";
import Battery from "lib/constants/Battery";

export default class Utils {
  /**
   * Assign a value to an object using dot-notation
   * @param {Object} obj
   * @param {String} key
   * @param {*} value
   * @returns {*}
   */
  static assignValue(obj = {}, key, value) {
    const clone = Object.assign({}, obj);
    let position = clone;
    const path = key.split(".");
    while (path.length > 1) {
      position = position[path.shift()];
    }
    position[path.shift()] = value;
    return clone;
  }

  /**
   * Format a currency to two digits after the period
   * @param {number} amount
   * @returns {string}
   */
  static formatCurrency(amount) {
    if (typeof amount !== "number") {
      return "0.00";
    }
    return amount.toFixed(2);
  }

  /**
   * Returns either: morning, afternoon, evening depending on the time of input m.
   * @see https://gist.github.com/James1x0/8443042
   * @param m
   * @return {string}
   */
  static getGreetingTime(m = moment()) {
    var g = null; //return g

    if (!m || !m.isValid()) {
      return;
    } //if we can't find a valid or filled moment, we return.

    var split_afternoon = 12; //24hr time to split the afternoon
    var split_evening = 17; //24hr time to split the evening
    var currentHour = parseFloat(m.format("HH"));

    if (currentHour >= split_afternoon && currentHour <= split_evening) {
      g = "afternoon";
    } else if (currentHour >= split_evening) {
      g = "evening";
    } else {
      g = "morning";
    }

    return g;
  }

  /**
   * Return the number of seconds since the specified timestamp
   * @param {string} timestamp - ISO timestamp
   * @returns {number} seconds
   */
  static timeSinceNow(timestamp) {
    const since = moment(timestamp);
    const now = moment();
    return now.diff(since, "seconds");
  }

  /**
   * Determines the battery capacity left given a voltage.
   * @param {float} voltage
   * @returns {number}
   */
  static calculateBatteryPercentage(voltage) {
    let percent = Math.floor(((voltage - Battery.MIN_VOLTAGE) / (Battery.MAX_VOLTAGE - Battery.MIN_VOLTAGE)) * 100);
    if (percent > 100) {
      return 100;
    }
    if (percent < 0) {
      return 0;
    }
    return percent;
  }

  static formatNameInitials = (user) => {
    if (!user) {
      return "";
    }
    let names = [];
    if (user.forename) {
      names.push(user.forename[0]);
    }
    if (user.surname) {
      names.push(user.surname[0]);
    }
    return names.filter(Boolean).join("").toUpperCase();
  };

  static formatBytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }
}
