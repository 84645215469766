import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

export type AnchorProps = {
  to?: string;
  href?: string;
  target?: string;
  className?: string;
  onClick?: (event: any) => void;
  children?: ReactNode;
};

const Anchor = ({ to, href, target, children, className, onClick }: AnchorProps) => {
  return to ? (
    <Link to={to} target={target} className={classNames("link", className)} onClick={onClick}>
      {children}
    </Link>
  ) : (
    <a href={href} target={target} className={classNames("link", className)} onClick={onClick}>
      {children}
    </a>
  );
};

Anchor.defaultProps = {};

Anchor.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
  className: PropTypes.string,
};

export default Anchor;
