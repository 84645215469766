//@ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import arrayMove from "array-move";
import PropTypes from "prop-types";
import { pageBlocksChange } from "lib/actions/blocks";
import { getPageBlocks } from "lib/reducers/blocks.selectors";
import { Draggable } from "components/Draggable";
import SheetBlock from "components/Sheet/components/SheetBlock";
import { orderPageBlocks } from "components/Sheet/utils";

export const SheetBlocks = ({
  pageBlocks: orderedPageBlocks,
  onReorder,
  onCreateBlock,
  onRemoveBlock,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const [sorting, setSorting] = useState(false);
  const pageBlocks = useSelector(getPageBlocks);

  const handleSortEnd = async ({ oldIndex, newIndex }) => {
    setSorting(false);
    const ordered = orderPageBlocks(pageBlocks);
    let rearranged = arrayMove(ordered, oldIndex, newIndex);
    for (let i = 0; i < ordered.length; i++) {
      rearranged[i].order = i;
    }
    dispatch(pageBlocksChange(rearranged));
    onReorder(rearranged);
  };

  const handleSortStart = () => {
    setSorting(true);
  };

  return (
    <Draggable {...otherProps} distance={5} onSortEnd={handleSortEnd} onSortStart={handleSortStart} useDragHandle>
      {orderedPageBlocks.map(({ blockId }) => (
        <SheetBlock
          key={blockId}
          blockID={blockId}
          showSorting={!sorting}
          onCreateBlock={onCreateBlock}
          onRemoveBlock={onRemoveBlock}
        />
      ))}
    </Draggable>
  );
};

SheetBlocks.defaultProps = {};

SheetBlocks.propTypes = {
  onCreateBlock: PropTypes.func,
  onRemoveBlock: PropTypes.func,
  className: PropTypes.string,
};

export default SheetBlocks;
