//@ts-nocheck
import React from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import EmptyLayout from "components/layouts/EmptyLayout";
import { ShopNavBar } from "pages/apps/Shop/shared";
import MarketingFooter from "pages/marketing/components/MarketingFooter";
import styles from "./ShopLayout.module.scss";

export const ShopLayout = () => {
  return (
    <EmptyLayout className={styles.ShopLayout}>
      <div className={styles.ShopLayoutNavBar}>
        <ShopNavBar />
      </div>
      <Helmet>
        <title>Automify Marketplace</title>
        <link rel="icon" type="image/png" href="/images/target.png" />
      </Helmet>
      <div className={styles.ShopLayoutStage}>
        <Outlet />
      </div>
      <MarketingFooter />
    </EmptyLayout>
  );
};
