//@ts-nocheck
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { requestManufacturerSuccess } from "lib/actions/catalogue";
import { showPopupFlag } from "lib/actions/userInterface";
import CatalogueAPI from "lib/api/catalogue";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";

export const CreateManufacturerModal = ({ modal, onClose }) => {
  const dispatch = useDispatch();
  const { onSuccess } = modal.props;
  const [values, setValues] = useState({
    name: "",
    description: "",
    websiteUrl: "",
  });
  const [submitting, setSubmitting] = useState(false);
  const valid = !!values.name;

  const handleChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    setSubmitting(true);
    try {
      event.preventDefault();
      const manufacturer = await CatalogueAPI.createManufacturer({
        ...values,
      });
      dispatch(requestManufacturerSuccess(manufacturer));
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Manufacturer created",
        }),
      );
      onClose();
      onSuccess && onSuccess(manufacturer);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setSubmitting(false);
  };

  return (
    <Modal>
      <ModalHeader title="Create a manufacturer" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter manufacturer name",
                autoFocus: true,
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Website">
            <TextInput
              input={{
                name: "websiteUrl",
                placeholder: "Enter manufacturer website URL",
              }}
              value={values.websiteUrl}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Description">
            <TextInput
              input={{
                name: "description",
                placeholder: "Enter manufacturer description",
              }}
              value={values.description}
              onChange={handleChange}
            />
          </FieldGroup>
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton disabled={!valid || submitting} onClick={handleSubmit}>
          Create manufacturer
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};
