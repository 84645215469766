import { Link } from "react-router-dom";
import classNames from "classnames";
import { FixLater } from "types";
import Icon from "components/Icon";
import style from "./dropdown.module.scss";

const DropItem = ({
  className,
  appearance = "normal",
  active,
  icon,
  children,
  onClick,
  to,
  onItemClick,
  ...props
}: FixLater) => {
  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick();
    }
    if (typeof onItemClick === "function") {
      onItemClick();
    }
  };

  const content = (
    <>
      {icon && (
        <div className={style.dropItemIconContainer}>
          {typeof icon === "string" ? <Icon src={icon} size="small" className={style.dropItemIcon} /> : icon}
        </div>
      )}
      <div className={style.dropItemContent}>{children}</div>
    </>
  );

  const classes = classNames(
    style.dropItem,
    {
      [style[appearance]]: true,
      [style.active]: active,
    },
    className,
  );
  return to ? (
    <Link {...props} to={to} onClick={handleClick} className={classes}>
      {content}
    </Link>
  ) : (
    <div {...props} onClick={handleClick} className={classes}>
      {content}
    </div>
  );
};

// DropItem.propTypes = {
//   className: PropTypes.string,
//   appearance: PropTypes.oneOf(["normal", "input"]),
//   onClick: PropTypes.func,
//   active: PropTypes.bool,
// };

export default DropItem;
