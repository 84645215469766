//@ts-nocheck
import React, { useState } from "react";
import classNames from "classnames";
import useDebounce from "effects/useDebounce";
import useDidMount from "effects/useDidMount";
import EditableText from "components/Sheet/components/EditableText";
import style from "../sheet.module.scss";

const TextBlock = ({ block, tag = "div", onChange, onAddBlock, onRemoveBlock, className }) => {
  const [values, setValues] = useState(block);
  const debouncedValues = useDebounce(values, 400);

  useDidMount(() => {
    onChange(values);
  }, [debouncedValues]);

  const handleChange = (name, value) => {
    setValues({
      ...block,
      properties: {
        ...block.properties,
        [name]: value,
      },
    });
  };

  return (
    <div className={classNames(style.flex)}>
      <EditableText
        value={block.properties.text}
        className={className}
        tag={tag}
        onChange={(value) => handleChange("text", value)}
        onAddBlock={onAddBlock}
        onRemoveBlock={onRemoveBlock}
      />
    </div>
  );
};

export default TextBlock;
