import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TCaseScanner, UUID } from "types";

const selectState = (state: RootStore) => state.caseScanners;

export const selectCaseScanners = createSelector(selectState, (state) => state.data);

export const selectCaseScannersList = createSelector(selectCaseScanners, (data) => Object.values(data));

export const selectCaseScannerByID = (id: UUID) =>
  createSelector(selectCaseScanners, (data): TCaseScanner | undefined => data[id]);

export const selectCaseScannerByStorageID = (storageId: string) =>
  createSelector(selectCaseScannersList, (list) => list.find((entity) => entity.storageId === storageId));
