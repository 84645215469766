//@ts-nocheck
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { setCartProductQuantity } from "lib/actions/cart";
import { getCartItemQuantityByID } from "lib/reducers/cart.selectors";
import { Button } from "components/Buttons";
import styles from "./ButtonAddToCart.module.scss";

const ButtonAddToCart = ({ itemID, quantity }) => {
  const dispatch = useDispatch();
  const existingQuantity = useSelector(getCartItemQuantityByID(itemID));

  const handleClick = () => {
    dispatch(setCartProductQuantity(itemID, existingQuantity + quantity));
  };
  return (
    <Button icon="/images/icons/add_to_cart_1.svg" className={styles.ButtonAddToCart} onClick={handleClick}>
      Add to cart
    </Button>
  );
};

ButtonAddToCart.defaultProps = {
  quantity: 1,
};

ButtonAddToCart.propTypes = {
  itemID: PropTypes.number,
  quantity: PropTypes.number,
};

export default ButtonAddToCart;
