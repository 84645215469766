export default {
  TOOL_TRACKING: "tool_tracking",
  TOOL_TRACKING_RFID: "tool_tracking_rfid",
  TOOL_STORAGE: "tool_storage",
  TOOL_STORES: "tool_stores",
  PROJECT_TRACKING: "project_tracking",
  REPORTING: "reporting",
  TOOL_CASE_TRACKING: "tool_case_tracking",
  TOOL_SHOP: "tool_shop",
  TOOL_SHOP_MANAGEMENT: "tool_shop_management",
  REGULATORY_COMPLIANCE: "regulatory_compliance",
  WORKFORCE: "workforce",
  ACCESS_CONTROL: "access_control",
  MODBUS: "modbus",
  PRODUCT_CATALOGUE_MANAGEMENT: "product_catalogue_management",
  FACIAL_AUTHENTICATION: "facial_authentication",
  TOOL_ROOMS: "tool_rooms",
  RAG_CONTROL: "rag_control",
  VEHICLES: "vehicles",
  DOORWAYS: "doorways",
};
