import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "./overlay.module.scss";

const Overlay = ({ visible, isDark, isTransparent, className, onClick }: any) => (
  <div
    className={classNames(
      style.overlay,
      {
        [style.visible]: visible,
        [style.dark]: isDark,
        [style.transparent]: isTransparent,
      },
      className,
    )}
    onClick={onClick}
  />
);

Overlay.defaultProps = {
  visible: true,
  isDark: false,
};

Overlay.propTypes = {
  onClick: PropTypes.func,
  isDark: PropTypes.bool,
  isTransparent: PropTypes.bool,
  className: PropTypes.string,
  visible: PropTypes.bool,
};

export default Overlay;
