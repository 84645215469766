import { ReactNode } from "react";
import styled from "styled-components";

type MediaBreakPoint = "md" | "lg";

const breakpoints: Record<MediaBreakPoint, number> = {
  md: 768,
  lg: 1024,
};

const StyledMediaQuery = styled.div<MediaQueryProps>`
  display: none;

  @media only screen and (${({ clause }) => clause}-width: ${({ breakpoint }) => breakpoints[breakpoint]}px) {
    display: block;
  }
`;

type MediaQueryProps = {
  breakpoint: MediaBreakPoint;
  clause?: "min" | "max";
  children: ReactNode;
  className?: string;
};

export const MediaQuery = ({ breakpoint, children, clause = "min", className }: MediaQueryProps) => {
  return (
    <StyledMediaQuery breakpoint={breakpoint} clause={clause} className={className}>
      {children}
    </StyledMediaQuery>
  );
};
