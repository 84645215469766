import { Menu } from "@headlessui/react";
import { TrashIcon, DotsVerticalIcon } from "@heroicons/react/outline";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { deleteStorageAlert } from "store/reducers";
import { UUID } from "types";
import { TransparentButton } from "components/shared/buttons";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import { DropdownMenu, DropdownMenuItemAction } from "components/shared/menus";

type StorageAlertTableRowDropdownProps = {
  storageId: string;
  storageAlertId: UUID;
  onDelete?: () => void;
};

export const StorageAlertTableRowDropdown = ({
  storageId,
  storageAlertId,
  onDelete,
}: StorageAlertTableRowDropdownProps) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const removeAlert = () => {
    openModal(
      <ConfirmModal
        actionText="Remove alert"
        description="Are you sure you want to remove this alert? This action cannot be undone."
        actionColor="danger"
        onConfirm={async () => {
          try {
            if (!storageId || !storageAlertId) return;
            await dispatch(
              deleteStorageAlert({
                id: storageAlertId,
                storageId,
              }),
            ).unwrap();
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Device alert was removed",
              }),
            );
            onDelete && onDelete();
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  const actions: DropdownMenuItemAction[][] = [
    [
      {
        label: "Delete",
        icon: TrashIcon,
        onClick: removeAlert,
      },
    ],
  ];

  return (
    <DropdownMenu items={actions}>
      <Menu.Button as={TransparentButton} trailingIcon={DotsVerticalIcon} padding="px-2" />
    </DropdownMenu>
  );
};
