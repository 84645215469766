import { ReactNode, useState } from "react";
import { usePopper } from "react-popper";
import { Menu, Portal } from "@headlessui/react";
import { DropdownMenuItem, DropdownMenuItemAction } from "components/shared/menus/DropdownMenuItem/DropdownMenuItem";

export type DropdownMenuItemActionGroup = DropdownMenuItemAction[] | undefined;

type DropdownMenuProps = {
  items: DropdownMenuItemActionGroup[];
  children: ReactNode;
};

export const DropdownMenu = ({ items, children }: DropdownMenuProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [5, 5],
        },
      },
    ],
  });

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div ref={setReferenceElement}>{children}</div>
      <Portal>
        <Menu.Items
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {items.filter(Boolean).map((group, index) => {
            if (!group) {
              return null;
            }
            return (
              <div key={`group_${index}`} className="px-1 py-1">
                {group.map((item) => {
                  if (!item) {
                    return null;
                  }
                  const { icon, label, onClick, disabled, disabledReason } = item;
                  return (
                    <DropdownMenuItem
                      key={`groupitem_${label}`}
                      icon={icon}
                      label={label}
                      onClick={onClick}
                      disabled={disabled}
                      disabledReason={disabledReason}
                    />
                  );
                })}
              </div>
            );
          })}
        </Menu.Items>
      </Portal>
    </Menu>
  );
};
