import VehicleTypes, { VehicleLookup } from "lib/constants/VehicleTypes";
import { VehicleCategory, VehicleType } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

type VehicleTypeSelectorProps = {
  name: string;
  value?: VehicleType;
  onChange: (value?: VehicleType, category?: VehicleCategory) => void;
  placeholder?: string;
  appearance?: "inline" | "normal";
  searchable?: boolean;
  className?: string;
};

export const VehicleTypeSelector = ({
  name,
  value,
  onChange,
  placeholder = "Select type",
  appearance = "normal",
  searchable = false,
  className,
}: VehicleTypeSelectorProps): JSX.Element => {
  const input = {
    type: "select",
    name,
    placeholder,
    options: VehicleTypes.items,
    isSearchable: searchable,
  };

  const handleChange = (value?: VehicleLookup) => {
    if (value) {
      onChange(value?.value, value?.category);
    } else {
      onChange(undefined, undefined);
    }
  };

  return appearance === "inline" ? (
    <Text
      canEdit
      input={input}
      value={VehicleTypes.resolveItem(value)}
      onConfirm={(value: VehicleLookup) => {
        handleChange(value);
      }}
      className={className}
    />
  ) : (
    <SelectInput
      input={input}
      value={VehicleTypes.resolveItem(value)}
      onChange={(name: string, value: VehicleLookup) => {
        handleChange(value);
      }}
      className={className}
    />
  );
};
