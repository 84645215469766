//@ts-nocheck
import Api from "./api";

export default class Marketplace extends Api {
  /**
   * Get store items
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<Object>}
   */
  static async getStoreItems(queryParams = {}) {
    const response = await this.get(`/marketplace/items`).query(queryParams);
    return response.body;
  }

  /**
   * Get store item
   * @async
   * @param {number} itemID
   * @returns {Promise.<Object>}
   */
  static async getStoreItem(itemID) {
    const response = await this.get(`/marketplace/items/${itemID}`);
    return response.body.data;
  }

  /**
   * Create a store item
   * @async
   * @param {Object} storeItem
   * @returns {Promise.<Object>}
   */
  static async createStoreItem(storeItem) {
    const response = await this.post(`/marketplace/items`).send(storeItem);
    return await response.body.data;
  }

  /**
   * Update an existing store item
   * @async
   * @param {number} itemID
   * @param {Object} storeItem
   * @returns {Promise.<Object>}
   */
  static async updateStoreItem(itemID, storeItem) {
    const response = await this.put(`/marketplace/items/${itemID}`).send(storeItem);
    return await response.body.data;
  }

  /**
   * Archive an existing store item
   * @async
   * @param {number} itemID
   * @returns {Promise.<Object>}
   */
  static async archiveStoreItem(itemID) {
    return await this.delete(`/marketplace/items/${itemID}`).send();
  }

  /**
   * Delete a store item image
   * @async
   * @param {number} itemID
   * @param {number} imageID
   * @returns {Promise.<Object>}
   */
  static async deleteStoreItemImage(itemID, imageID) {
    return await this.delete(`/marketplace/items/${itemID}/images/${imageID}`).send();
  }

  /**
   * Create a store order
   * @async
   * @param {Object} order
   * @returns {Promise.<Object>}
   */
  static async createStoreOrder(order) {
    const response = await this.post(`/marketplace/orders`).send(order);
    return await response.body.data;
  }
}
