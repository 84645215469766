//@ts-nocheck
import * as jwt from "jose";
import Api from "./api";

export default class Authentication extends Api {
  /**
   * Login
   * @async
   * @param {string} username
   * @param {string} password
   * @param {Object} [options]
   * @returns {Promise.<{tokenString: string, token: Object}>}
   */
  static async login(username, password, options = {}) {
    const baseURL = options.baseURL || "";
    const response = await this.post(`${baseURL}/authenticate`).send({
      username,
      password,
    });
    const { token, resetPassword } = response.body.data;
    return {
      tokenString: token,
      token: jwt.decodeJwt(token),
      resetPassword,
    };
  }

  /**
   * Complete the sign up.
   * @param {string} authToken
   * @param {Object} data
   * @param {Object} [options]
   * @returns {Promise<{tokenString: string, token: Object}>}
   */
  static async completeSignup(authToken, data, options = {}) {
    const baseURL = options.baseURL || "";
    const response = await this.post(`${baseURL}/signup/callback`).set({ Authorization: authToken }).send(data);
    const { token } = response.body.data;
    return {
      tokenString: token,
      token: jwt.decodeJwt(token),
    };
  }

  /**
   * Begin sign up. This will send the user an email to complete sign up.
   * @param {{fullName: string, email: string}} data
   * @param {Object} [options]
   * @returns {Promise<void>}
   */
  static async beginSignup(data, options = {}) {
    const baseURL = options.baseURL || "";
    await this.post(`${baseURL}/signup`).send(data);
  }

  /**
   * Begin password recovery process. This will send the user an email to complete sign up.
   * @param {string} email
   * @param {Object} [options]
   * @returns {Promise<void>}
   */
  static async requestPasswordRecovery(email, options = {}) {
    const baseURL = options.baseURL || "";
    await this.post(`${baseURL}/recover_password`).send({ email });
  }

  /**
   * Finish the password recovery process
   * @async
   * @param {string} authToken
   * @param {string} password
   * @param {Object} [options]
   * @returns {Promise<void>}
   */
  static async completePasswordRecovery(authToken, password, options = {}) {
    const baseURL = options.baseURL || "";
    const response = await this.post(`${baseURL}/recover_password/callback`).set({ Authorization: authToken }).send({
      password,
    });
    const { token } = response.body.data;
    return {
      tokenString: token,
      token: jwt.decodeJwt(token),
    };
  }

  /**
   * Change the password for the authenticated user
   * @async
   * @param {string} password
   * @returns {Promise<void>}
   */
  static async changePassword(password) {
    return await this.post("/account/password").send({ password });
  }

  /**
   * Finish the invited user flow.
   * @param {Object} requestBody
   * @returns {Promise<void>}
   */
  static async confirmInvite(requestBody) {
    await this.post(`/users/callback`).send(requestBody);
  }
}
