//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import Logger from "../lib/Logger";
import * as actions from "../lib/actions/users";
import mergeProps from "./mergeProps";
import mergeStateStore from "./mergeStateStore";

export default function withUsers(WrappedComponent, autoFetch = false) {
  class Component extends React.Component {
    static propTypes = {
      actions: PropTypes.shape({
        requestUsers: PropTypes.func,
        requestUser: PropTypes.func,
      }),
      stateStore: PropTypes.shape({
        users: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
          paging: PropTypes.shape({
            pageStart: PropTypes.number,
            pageLimit: PropTypes.number,
            nextPageStart: PropTypes.string,
            previousPageStart: PropTypes.string,
          }),
        }),
        user: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.object,
        }),
      }),
    };

    componentDidMount() {
      const { actions } = this.props;
      if (autoFetch) {
        Logger.trace("auto-fetching users");
        actions.requestUsers();
      }
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToProps = (state) =>
    mergeStateStore(state, {
      users: state.users.users,
      user: state.users.user,
    });
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  });
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
}
