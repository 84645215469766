import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { ToolStorageProvider } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { useDispatch, useSelector } from "store";
import { fetchRagBinById, selectRagBinByID, selectStorageByID } from "store/reducers";
import { PageLoading } from "components/shared";
import PageStage from "components/stages/PageStage";
import { RagBinSettingsPage } from "pages/features/ragBins/RagBinSettingsPage/RagBinSettingsPage";

export const RagBinPage = () => {
  const dispatch = useDispatch();
  const { pathname: url } = useLocation();
  const { ragBinID } = useParams();
  const entity = useSelector(selectRagBinByID(ragBinID || ""));
  const storageID = entity?.storageId;
  const storage = useSelector(selectStorageByID(storageID || ""));

  // Fetch the entity if it is not in memory yet
  useEffect(() => {
    if (!ragBinID) return;
    if (entity) return;
    dispatch(fetchRagBinById(ragBinID));
  }, [ragBinID, entity]);

  if (!entity || !storage || !storageID || !ragBinID) {
    return <PageLoading />;
  }

  return (
    <ToolStorageProvider storageId={storageID}>
      <PageStage>
        <Routes>
          <Route path="settings" element={<RagBinSettingsPage ragBinId={ragBinID} />} />
          <Route path="" element={<Navigate replace to={`${url}/settings`} />} />
        </Routes>
      </PageStage>
    </ToolStorageProvider>
  );
};
