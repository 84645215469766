//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { VSpace } from "components/shared";
import {
  FullWidth,
  HalfHalf,
  InfoHeader,
  InfoText,
  MarketingHeader,
  MarketingSection,
  MarketingSectionWrapper,
} from "pages/marketing/components/Common";
import Tablet from "pages/marketing/components/Tablet";
import style from "pages/marketing/marketing.module.scss";

const MarketingEdgeProduct = ({ className }) => {
  return (
    <MarketingSection>
      <MarketingSectionWrapper>
        <FullWidth className={style.centerAlign}>
          <VSpace>
            <MarketingHeader>Your toolbox just got smarter</MarketingHeader>
            <InfoText concise spaceBottom={false}>
              By integrating real-world data gathered from your toolbox, Automify can help boost productivity, improve
              safety and ensure adherence to the ever-increasing regulatory requirements.
            </InfoText>
          </VSpace>
          <Tablet size="large" className={style.softwareDemo}>
            <img src="/images/sentry_demo_1.png" alt="Sentry software" />
          </Tablet>
        </FullWidth>

        <HalfHalf largerLeft={true}>
          <div className={style.verticalAlign}>
            <VSpace>
              <InfoHeader>Access control</InfoHeader>
              <InfoText>
                Control who can access your toolboxes, cabinets, stores, and doorways. Granularly configure user access
                using different methods: keycards, mobile app, and passcode. We offer support for a wide range of
                keycards, including HID and Mifare 13.56Mhz.
              </InfoText>
            </VSpace>
          </div>
          <div>
            <img
              src="/images/demo/keycard_reader.png"
              alt="Keycard reader"
              className={classNames(style.productImage, style.productImageSmall)}
            />
          </div>
        </HalfHalf>

        <HalfHalf largerRight={true}>
          <div>
            <img
              src="/images/demo/tracking_1.png"
              alt="Keycard reader"
              className={classNames(style.productImage, style.productImageSmall)}
            />
          </div>
          <div className={style.verticalAlign}>
            <InfoHeader>Tool tracking</InfoHeader>
            <InfoText>
              Our optional tool tracking module, combined with calibration monitoring, enables you to reach your tool
              control requirements and visibly demonstrate adherence to industry regulations.
            </InfoText>
          </div>
        </HalfHalf>
      </MarketingSectionWrapper>
    </MarketingSection>
  );
};

MarketingEdgeProduct.defaultProps = {};

MarketingEdgeProduct.propTypes = {
  className: PropTypes.string,
};

export default MarketingEdgeProduct;
