import { useModal } from "effects";
import styled, { css } from "styled-components";
import { useSelector } from "store";
import { selectAssetByID } from "store/reducers";
import { AssetListItem } from "components/shared/features/assets";
import { useToolStoreOrder } from "components/shared/features/toolStoreOrders";
import { ToolStoreAssetDetailsModal } from "components/shared/features/toolStores/ToolStoreAssetDetailsModal/ToolStoreAssetDetailsModal";

const Item = styled(AssetListItem)<{ selected: boolean }>`
  cursor: pointer;
  ${({ selected }) =>
    selected
      ? css`
          border-left: 0.275rem solid #854dff !important;
        `
      : ""}
`;

type ToolStoreAssetsListItemProps = {
  assetId: number;
};

export const ToolStoreAssetsListItem = ({ assetId }: ToolStoreAssetsListItemProps) => {
  const { basket, setBasket } = useToolStoreOrder();
  const { openModal } = useModal();
  const asset = useSelector(selectAssetByID(assetId));
  const selected = Boolean(basket[assetId]);

  const handleClick = () => {
    if (basket[assetId]) {
      setBasket((prevState) => {
        const state = { ...prevState };
        delete state[assetId];
        return state;
      });
      return;
    }
    openModal(
      <ToolStoreAssetDetailsModal
        assetId={assetId}
        onSuccess={() => {
          if (!asset) return;
          setBasket((prevState) => ({
            ...prevState,
            [assetId]: asset,
          }));
        }}
      />,
    );
  };

  if (!asset) {
    return null;
  }

  return <Item selected={selected} assetId={assetId} onClick={handleClick} />;
};
