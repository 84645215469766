//@ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import { showPopupFlag } from "lib/actions/userInterface";
import TaskAPI from "lib/api/tasks";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import store from "store";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import style from "./RootModal/modal.module.scss";

const CreateTaskModal = ({ modal, onClose }) => {
  const { onSuccess } = modal.props;
  const [values, setValues] = useState({ title: "" });
  const [submitting, isSubmitting] = useState(false);

  const handleChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    isSubmitting(true);
    try {
      event.preventDefault();
      const task = await TaskAPI.createTask({
        title: values.title,
      });
      store.dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Created Task",
        }),
      );
      onClose();
      onSuccess && onSuccess(task);
    } catch (err) {
      handlePopupFlagError(err);
    }
    isSubmitting(false);
  };

  const valid = values.title;

  return (
    <Modal className={style.genericModal}>
      <ModalHeader title="Create a new task" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "title",
                placeholder: "What needs to be done?",
                autoFocus: true,
              }}
              value={values.title}
              onChange={handleChange}
            />
          </FieldGroup>
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton disabled={!valid || submitting} onClick={handleSubmit}>
          Create task
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

CreateTaskModal.propTypes = {
  className: PropTypes.string,
};

export default CreateTaskModal;
