import { BiQrScan } from "react-icons/bi";
import { GiRolledCloth, GiSecurityGate } from "react-icons/gi";
import useSetSidebar from "effects/useSidebar";
import Apps from "lib/constants/Apps";
import Capabilities from "lib/constants/Capabilities";
import { Restrict, RestrictHasOneCapability } from "components/Restricted";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import Sidebar, { MenuSeparator } from "components/sidebars/Sidebar";

export const StorageAppSidebar = () => {
  useSetSidebar(true);
  const url = "/apps/storage";
  return (
    <Sidebar>
      <Restrict app={Apps.TOOL_STORAGE} capability={Capabilities.STORAGE_READ}>
        <MenuItem to={`${url}/toolboxes`} icon="/images/icons/roll_cab_1.svg">
          Toolboxes
        </MenuItem>
      </Restrict>
      <Restrict app={Apps.TOOL_STORES} capability={Capabilities.TOOL_STORE_READ}>
        <MenuItem to={`${url}/stores`} icon="/images/icons/cart_1.svg">
          Tool stores
        </MenuItem>
      </Restrict>
      <Restrict app={Apps.TOOL_ROOMS} capability={Capabilities.TOOL_ROOM_READ}>
        <MenuItem to={`${url}/tool_rooms`} icon={<GiSecurityGate />}>
          Tool rooms
        </MenuItem>
      </Restrict>
      <Restrict app={Apps.TOOL_CASE_TRACKING} capability={Capabilities.CASE_SCANNER_READ}>
        <MenuItem to={`${url}/case_scanners`} icon={<BiQrScan />}>
          Case scanners
        </MenuItem>
      </Restrict>
      <MenuItem to={`${url}/rag_controllers`} app={Apps.RAG_CONTROL} icon={<GiRolledCloth />}>
        Rag control
      </MenuItem>
      <RestrictHasOneCapability capabilities={[Capabilities.STORAGE_CREATE, Capabilities.STORAGE_UPDATE]}>
        <MenuSeparator />
        <MenuItem to={`${url}/alerts`} icon="/images/icons/alert_2.svg">
          Alerts
        </MenuItem>
      </RestrictHasOneCapability>
    </Sidebar>
  );
};
