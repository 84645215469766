//@ts-nocheck
import React, { useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import PropTypes from "prop-types";
import Authentication from "lib/api/authentication";
import { TextInput } from "components/Input";
import { Paragraph, Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { VSpace } from "components/shared";
import AuthForm from "pages/authentication/components/AuthForm";
import AuthFormTitle from "pages/authentication/components/AuthFormTitle";
import AuthSubmitButton from "pages/authentication/components/AuthSubmitButton";
import OnSuccess from "./OnSuccess";
import style from "./recoverPassword.module.scss";

const RecoverPasswordPage = ({ className }) => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (name, value) => setEmail(value);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setSubmitting(true);
      setError(null);
      await Authentication.requestPasswordRecovery(email);
      setSubmitted(true);
    } catch (err) {
      const message = get(err, "response.body.error.message", "Something went wrong");
      setError(message);
      setSubmitting(false);
    }
  };

  return submitted ? (
    <OnSuccess email={email} />
  ) : (
    <AuthForm onSubmit={handleSubmit}>
      <VSpace>
        <AuthFormTitle title="Forgot your password?" />
        <Paragraph>Don't worry, it happens to the best of us!</Paragraph>
        <FieldGroup label="Email address">
          <TextInput
            input={{
              name: "email",
              placeholder: "Enter email address",
            }}
            value={email}
            onChange={handleChange}
          />
        </FieldGroup>
        {error && <Text className="text-danger">{error}</Text>}
        <AuthSubmitButton isDisabled={!email || submitting}>Reset password</AuthSubmitButton>
        <Text className={style.alreadyHaveAccount}>
          Remembered your password? <Link to="/">Sign in</Link>
        </Text>
      </VSpace>
    </AuthForm>
  );
};

RecoverPasswordPage.defaultProps = {};

RecoverPasswordPage.propTypes = {
  className: PropTypes.string,
};

export default RecoverPasswordPage;
