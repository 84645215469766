import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import ImportAssetColumnTypes from "lib/constants/ImportAssetColumnTypes";
import { CreateAssetRequest, FixLater, TAsset } from "types";
import { TAssetProduct } from "types/features/assetProduct";
import { SmartTable } from "components/Table/SmartTable";
import { Text } from "components/Typography";
import { VSpace } from "components/shared/layouts";
import { TextBody } from "components/shared/typography";

type ImportedAssetsTableProps = {
  itemsCreated: TAsset[];
  conflicts: CreateAssetRequest[];
  unknowns: CreateAssetRequest[];
};

export const ImportedAssetsTable = ({ itemsCreated, conflicts, unknowns }: ImportedAssetsTableProps) => {
  const rowData = useMemo(() => itemsCreated, [itemsCreated]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Tool",
        Key: "name",
        Cell: ({ row }: FixLater) => {
          const { id, catalogProduct } = row.original;
          return <Link to={`/apps/tracking/assets/${id}`}>{catalogProduct?.name}</Link>;
        },
      },
      {
        Header: ImportAssetColumnTypes.resolveItemLabel(ImportAssetColumnTypes.PRODUCT_NUMBER),
        Key: "mpn",
        Cell: ({ row }: FixLater) => {
          const { catalogProduct }: { catalogProduct?: TAssetProduct } = row.original;
          return <Text>{catalogProduct?.mpn}</Text>;
        },
      },
      {
        Header: ImportAssetColumnTypes.resolveItemLabel(ImportAssetColumnTypes.SERIAL_NUMBER),
        Key: "serialNumber",
        Cell: ({ row }: FixLater) => {
          const { serialNumber } = row.original;
          return <Text>{serialNumber}</Text>;
        },
      },
      {
        Header: "Toolbox",
        Key: "storageConfig",
        Cell: ({ row }: FixLater) => {
          const { storageConfig } = row.original;
          return <Link to={`/apps/storage/toolboxes/${storageConfig?.storageId}`}>{storageConfig?.storageId}</Link>;
        },
      },
    ];
  }, []);
  return (
    <VSpace>
      <TextBody>Success: {itemsCreated.length}</TextBody>
      <TextBody>Conflicts: {conflicts.length}</TextBody>
      <TextBody>Unknowns: {unknowns.length}</TextBody>
      <SmartTable columns={columns} data={rowData} />
    </VSpace>
  );
};
