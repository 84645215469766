//@ts-nocheck
import React from "react";
import PageStage from "components/stages/PageStage";
import ShopCollection from "pages/apps/Shop/shared/ShopCollection/ShopCollection";

const ShopDashboard = () => {
  return (
    <PageStage>
      <ShopCollection />
    </PageStage>
  );
};

ShopDashboard.defaultProps = {};

ShopDashboard.propTypes = {};

export default ShopDashboard;
