import get from "lodash/get";
import { FixLater } from "types";

export type LookupItem<T = any, K = unknown> = K & {
  label: string;
  description: string;
  value: T;
};

type LookupItems<T, K> = LookupItem<T, K>[] | (() => LookupItem<T, K>[]);

export default class Lookup<T, K = unknown> {
  private _items: LookupItems<T, K> = [];

  set items(items: LookupItems<T, K>) {
    this._items = items;
  }

  get items(): LookupItems<T, K> {
    return this._items;
  }

  /**
   * Resolve an item
   * @param {*} value
   * @returns {Object|null}
   */
  public resolveItem(value: FixLater): FixLater {
    if (value && typeof value === "object") {
      value = value.value;
    }
    const items = typeof this._items === "function" ? this._items() : this._items;
    return items.find((item: FixLater) => item.value === value) || null;
  }

  /**
   * Resolve an item value
   * @param {*} value
   * @returns {Object|null}
   */
  public resolveItemValue(value: FixLater): FixLater {
    if (typeof value === "object") {
      return value.value;
    }
    return value;
  }

  /**
   * Resolve an item label
   * @param {*} value
   * @returns {string}
   */
  public resolveItemLabel(value: FixLater): FixLater {
    return get(this.resolveItem(value), "label", "");
  }

  /**
   * Resolve an item description
   * @param {*} value
   * @returns {string}
   */
  public resolveItemDescription(value: FixLater): FixLater {
    return get(this.resolveItem(value), "description", "");
  }
}
