//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import UserAPI from "lib/api/users";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { SelectInput } from "../../Input";

class SelectKeycard extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    queryParams: PropTypes.object,
    filterOptions: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: "Select keycard",
  };

  loadOptions = async (inputValue) => {
    try {
      const { queryParams } = this.props;
      let { data } = await UserAPI.getKeycards({
        ...queryParams,
        pageStart: 0,
        pageLimit: 10,
        revoked: false,
        query: inputValue,
      });
      return data;
    } catch (err) {
      handlePopupFlagError(err);
      return [];
    }
  };

  render() {
    const { name, value, onChange, className, placeholder, input } = this.props;
    const inputOptions = {
      ...input,
      async: true,
      name,
      loadOptions: this.loadOptions,
      placeholder,
      labelKey: "name",
      valueKey: "id",
      cacheOptions: true,
      defaultOptions: true,
    };
    return <SelectInput input={inputOptions} value={value} onChange={onChange} className={className} />;
  }
}

export default SelectKeycard;
