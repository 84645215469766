import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { ThunkError, handleThunkError } from "store/utils/thunk";
import {
  CreateStorageAssetRequest,
  DataResponse,
  DeleteStorageAssetRequest,
  TStorageAsset,
  UpdateStorageAssetRequest,
  UpdateStorageAssetResponse,
} from "types";

export const createStorageAsset = createAsyncThunk(
  "storageAssets/create",
  async (request: CreateStorageAssetRequest): Promise<TStorageAsset> => {
    const { data } = await API.POST<CreateStorageAssetRequest, DataResponse<TStorageAsset>>(
      `/storages/${request.storageId}/assets`,
      request,
    );
    return data;
  },
);

export const updateStorageAsset = createAsyncThunk<
  UpdateStorageAssetResponse["data"],
  UpdateStorageAssetRequest,
  ThunkError
>("storageAssets/updateStorageAsset", async (request, { rejectWithValue }) => {
  try {
    const { data } = await API.PUT<UpdateStorageAssetRequest, UpdateStorageAssetResponse>(
      `/storages/${request.storageId}/assets/${request.assetId}`,
      request,
    );
    return data;
  } catch (err) {
    return handleThunkError(rejectWithValue, err);
  }
});

export const deleteStorageAsset = createAsyncThunk(
  "storageAssets/delete",
  async ({ storageId, assetId }: DeleteStorageAssetRequest): Promise<void> => {
    await API.DELETE(`/storages/${storageId}/assets/${assetId}`);
  },
);
