import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { fetchAssetProducts } from "store/reducers/assetProducts/assetProducts.thunks";
import { TAssetProduct, UUID } from "types";

const selectState = (state: RootStore) => state.assetProducts;

export const selectAssetProducts = createSelector(selectState, (state) => state.data);

export const selectAssetProductsLoading = createSelector(
  selectState,
  ({ loading }) => loading === fetchAssetProducts.pending.type,
);

export const selectAssetProductsList = createSelector(selectAssetProducts, (data) => Object.values(data));

export const selectAssetProductsPage = createSelector(selectState, ({ page, data }) => {
  return page.map((id: UUID) => data[id]).filter(Boolean);
});

export const selectAssetProductByID = (id: number) =>
  createSelector(selectAssetProducts, (data): TAssetProduct | undefined => data[id]);
