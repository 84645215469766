import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { fetchCatalogueProducts } from "store/reducers/catalogue/products.thunks";
import { TCatalogueProduct } from "types";

const selectState = (state: RootStore) => state.catalogueProductsReducer;

export const selectCatalogueProducts = createSelector(selectState, (state) => state.data);

export const selectCatalogueProductsLoading = createSelector(
  selectState,
  ({ loading }) => loading === fetchCatalogueProducts.pending.type,
);

export const selectCatalogueProductsList = createSelector(selectCatalogueProducts, (data) => Object.values(data));

export const selectCatalogueProductsPage = createSelector(selectState, ({ page, data }) => {
  return page.map((id) => data[id]).filter(Boolean);
});

export const selectCatalogueProductByID = (id: number) =>
  createSelector(selectCatalogueProducts, (data): TCatalogueProduct | undefined => data[id]);
