import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import arrayMove from "array-move";
import { useToolStorage } from "contexts/ToolStorageProvider/ToolStorageProvider";
import queryString from "query-string";
import StorageAPI from "lib/api/storages";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchAssets, selectStorageByID } from "store/reducers";
import { FixLater, TAsset, TStorageDrawer } from "types";
import StageHeader from "components/StageHeader";
import { SmartTableNav } from "components/Table/SmartTable";
import { PageLoading, VSpace } from "components/shared";
import { StorageDrawerInventory } from "pages/storages/ListStorage/components/StorageInventoryPage/StorageDrawerInventory";
import StorageViewers from "pages/storages/ListStorage/components/StorageViewers/StorageViewers";
import AssetStatusFilter from "./AssetStatusFilter";
import CalibrationFilter from "./CalibrationFilter";
import { assignAsset, removeAsset } from "./common";
import style from "./inventory.module.scss";

const StorageInventoryPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { storageId } = useToolStorage();
  const storage = useSelector(selectStorageByID(storageId || ""));
  const drawers = storage?.drawers ? [...storage.drawers].sort((a, b) => (a.order > b.order ? 1 : -1)) : [];

  const [loading, setLoading] = useState(false);
  const [paging, setPaging] = useState({
    pageStart: 0,
    pageLimit: 99999,
  });
  const [filters, setFilters] = useState({
    assigned: "assigned" in queryParams ? queryParams.assigned === "true" : null,
    calibrated: "calibrated" in queryParams ? queryParams.calibrated === "true" : null,
  });
  const [query, setQuery] = useState("");
  const [assets, setAssets] = useState<TAsset[]>([]);
  const isFiltering = !!(filters.assigned || filters.calibrated);

  useEffect(() => {
    fetch();
  }, [query, filters]);

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await dispatch(
        fetchAssets({
          pageStart: paging.pageStart,
          pageLimit: paging.pageLimit,
          storageId,
          query: query || undefined,
          assigned: filters.assigned === null ? undefined : filters.assigned,
          calibrated: filters.calibrated === null ? undefined : filters.calibrated,
        }),
      ).unwrap();

      setAssets(
        response.results.sort((a: FixLater, b: FixLater) => (a.storageConfig.order > b.storageConfig.order ? 1 : -1)),
      );
      setPaging(response.paging);
    } catch (err) {
      handlePopupFlagError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSortEnd = ({ oldIndex, newIndex }: FixLater) => {
    let rearranged = arrayMove(assets, oldIndex, newIndex);
    for (let i = 0; i < assets.length; i++) {
      const asset = rearranged[i];
      if (!asset?.storageConfig) continue;
      rearranged[i] = {
        ...asset,
        storageConfig: {
          ...asset.storageConfig,
          order: i,
        },
      };
    }
    setAssets(rearranged);
    saveOrder(rearranged);
  };

  const saveOrder = async (rearranged: FixLater) => {
    try {
      const storageAssets = rearranged.map(({ storageConfig }: FixLater) => storageConfig);
      await StorageAPI.reOrderStorageAssets(storageId, storageAssets);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleAssetRemove = (assetID: number) => {
    removeAsset(storageId, assetID, () => fetch());
  };

  const handleAssignAsset = (drawer = null) => {
    return () => {
      assignAsset(storage, drawer, () => fetch());
    };
  };

  if (!storage || !storageId) {
    return <PageLoading />;
  }

  return (
    <>
      <StageHeader
        title="Inventory"
        action={
          <div className={style.navActions}>
            <StorageViewers storage={storage} />
          </div>
        }
      />
      <SmartTableNav
        paging={paging}
        onPageChange={fetch}
        showQueryInput={true}
        queryPlaceholder="Search by name or serial number"
        onQueryChange={setQuery}
      >
        <AssetStatusFilter
          value={filters.assigned}
          onChange={(value: FixLater) => setFilters({ ...filters, assigned: value })}
        />
        <CalibrationFilter
          value={filters.calibrated}
          onChange={(value: FixLater) => setFilters({ ...filters, calibrated: value })}
        />
      </SmartTableNav>

      <VSpace>
        {drawers.map((drawer: TStorageDrawer) => {
          return (
            <StorageDrawerInventory
              key={drawer.value}
              loading={loading}
              filtering={isFiltering}
              drawer={drawer}
              assets={assets}
              onSortEnd={handleSortEnd}
              onAssetAssign={handleAssignAsset}
              onAssetUnAssign={handleAssetRemove}
            />
          );
        })}
      </VSpace>
    </>
  );
};

export default StorageInventoryPage;
