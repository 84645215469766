import { useParams } from "react-router-dom";
import useSetSidebar from "effects/useSidebar";
import Apps from "lib/constants/Apps";
import Capabilities from "lib/constants/Capabilities";
import { useSelector } from "store";
import { selectStorageByID } from "store/reducers";
import { Restrict, RestrictByApp } from "components/Restricted";
import ToolboxPortrait from "components/ToolboxPortrait";
import { Header } from "components/Typography";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import Sidebar, { MenuSeparator } from "components/sidebars/Sidebar";
import style from "./sidebar.module.scss";

export const ToolboxAppSidebar = () => {
  useSetSidebar(true);
  const { storageID } = useParams();
  const url = `/apps/storage/toolboxes/${storageID}`;
  const entity = useSelector(selectStorageByID(storageID || ""));
  return (
    <Sidebar>
      {entity && (
        <div className={style.sidebarImageHeader}>
          <ToolboxPortrait model={entity.model} size="medium" className={style.storagePortrait} />
          <Header>{entity.name}</Header>
        </div>
      )}
      <MenuSeparator />
      <RestrictByApp app={Apps.TOOL_TRACKING}>
        <MenuItem to={`${url}/inventory`} icon="/images/icons/tools_1.svg">
          Inventory
        </MenuItem>
      </RestrictByApp>
      <Restrict capability={Capabilities.STORAGE_UPDATE}>
        <MenuItem to={`${url}/access_control`} icon="/images/icons/identity.svg">
          Manage access
        </MenuItem>
      </Restrict>
      <MenuItem to={`${url}/activity`} icon="/images/icons/update_2.svg">
        Access history
      </MenuItem>
      <Restrict capability={Capabilities.STORAGE_UPDATE}>
        <MenuSeparator />
        <MenuItem to={`${url}/settings`} icon="/images/icons/config_2.svg">
          Settings
        </MenuItem>
      </Restrict>
    </Sidebar>
  );
};

export default ToolboxAppSidebar;
