import { ReactNode } from "react";
import classNames from "classnames";

type PlainCardProps = {
  className?: string;
  children?: ReactNode | ReactNode[];
};

export const PlainCard = ({ className, children }: PlainCardProps) => {
  return (
    <div className={classNames("rounded-lg bg-white shadow border border-grey-50 flex flex-col", className)}>
      {children}
    </div>
  );
};
