import LocationTypes from "lib/constants/LocationTypes";
import { useSelector } from "store";
import { selectLocations } from "store/reducers";
import { PaginationQueryParams, UUID } from "types";
import { Table, TableCell, TableHeader, TableLink, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import TablePagination from "components/Table/TablePagination";
import { TableRowLoading } from "components/shared/tables";

type LocationsTableProps = {
  loading: boolean;
  locationIds: UUID[];
  paging?: PaginationQueryParams;
  onPrevPage: () => void;
  onNextPage: () => void;
  onLimitChange: (pageLimit: number) => void;
};

export const LocationsTable = ({
  loading,
  locationIds,
  paging,
  onPrevPage,
  onNextPage,
  onLimitChange,
}: LocationsTableProps) => {
  const locations = useSelector(selectLocations);
  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Type</TableHeadCell>
            <TableHeadCell>Parent</TableHeadCell>
            <TableHeadCell>Postcode</TableHeadCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {!loading &&
            locationIds.map((id) => {
              const location = locations[id];
              const { parentLocationId } = location;
              const parentLocation = parentLocationId ? locations[parentLocationId] : undefined;
              return (
                location && (
                  <TableRow key={id}>
                    <TableCell>
                      <TableLink to={`/configuration/locations/${id}`}>{location.name}</TableLink>
                    </TableCell>
                    <TableCell>{LocationTypes.resolveItemLabel(location.type) || ""}</TableCell>
                    <TableCell>
                      {parentLocation ? (
                        <TableLink to={`/configuration/locations/${parentLocation.id}`}>
                          {parentLocation.name}
                        </TableLink>
                      ) : (
                        <span className="text-muted">N/A</span>
                      )}
                    </TableCell>
                    <TableCell>{location.postcode}</TableCell>
                  </TableRow>
                )
              );
            })}
        </TableBody>
      </Table>
      {loading && <TableRowLoading />}
      <TablePagination paging={paging} onPrevPage={onPrevPage} onNextPage={onNextPage} onChangeLimit={onLimitChange} />
    </div>
  );
};
