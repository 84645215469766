// @ts-nocheck
import { Link } from "react-router-dom";
import Account from "lib/Account";
import { MixAction, MixPanel } from "lib/mixpanel";
import { getCartItemsList } from "lib/reducers/cart.selectors";
import { getMe } from "lib/reducers/lookupData.selectors";
import { useSelector } from "store";
import PlatformLogo from "components/PlatformNavigationBar/PlatformLogo";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import { MarketingButton } from "pages/marketing/components/Common";
import styles from "./ShopNavBar.module.scss";

export const ShopNavBar = () => {
  const me = useSelector(getMe);
  const cartItems = useSelector(getCartItemsList);
  return (
    <div className={styles.ShopNavBarContainer}>
      <header className={styles.ShopNavBar}>
        <div className={styles.ShopNavBarLogoContainer}>
          <PlatformLogo to="/apps/shop" image="/images/automify_logo_no_border.png" className={styles.ShopNavBarLogo} />
        </div>
        <menu className={styles.navBarMenu}>
          {Account.session() ? (
            <>
              {me && (
                <MenuItem
                  to="/dashboard"
                  image={me.profileImage || null}
                  icon={!me.profileImage && "/images/icons/account.svg"}
                  alignLeft
                >
                  Exit Shop
                </MenuItem>
              )}
            </>
          ) : (
            <>
              <Link to="/" className={styles.menuLink}>
                <MarketingButton hero primary={false} onClick={() => MixPanel.track(MixAction.CLICK_SHOP_NAVBAR_LOGIN)}>
                  Sign Into Your Account
                </MarketingButton>
              </Link>
            </>
          )}
          <MenuItem alignLeft to="/apps/shop/checkout/cart" icon={"/images/icons/cart_1.svg"}>
            Cart {cartItems.length ? `(${cartItems.length})` : null}
          </MenuItem>
        </menu>
      </header>
    </div>
  );
};
