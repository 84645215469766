import { IoAirplaneOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Account from "lib/Account";
import Logger from "lib/Logger";
import Apps from "lib/constants/Apps";
import Capabilities from "lib/constants/Capabilities";
import { getMe } from "lib/reducers/lookupData.selectors";
import { useDispatch, useSelector } from "store";
import { selectMainSideBarCollapsed, setMainSideBarCollapsed } from "store/reducers";
import { FixLater } from "types";
import PlatformLogo from "components/PlatformNavigationBar/PlatformLogo";
import { Restrict, RestrictHasOneCapability } from "components/Restricted";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import { Sidebar } from "components/sidebars/Sidebar";
import style from "./sidebar.module.scss";

const SidebarItem = ({ label, icon, to, collapsed }: FixLater) => {
  return (
    <MenuItem to={to} icon={icon} className={style.sideBarMenuItem}>
      {!collapsed && label}
    </MenuItem>
  );
};

type PagesProps = {
  collapsed: boolean;
};

const Pages = ({ collapsed }: PagesProps) => {
  return (
    <div>
      <div className={style.logo}>
        <PlatformLogo collapsed={collapsed} />
      </div>
      <div className={style.mainSideBarPages}>
        <Restrict app={Apps.TOOL_STORAGE} capability={Capabilities.STORAGE_READ}>
          <SidebarItem
            label="Tool storage"
            icon="/images/icons/roll_cab_1.svg"
            to="/apps/storage"
            collapsed={collapsed}
          />
        </Restrict>
        <Restrict app={Apps.TOOL_TRACKING} capability={Capabilities.ASSET_READ}>
          <SidebarItem
            label="Tool tracking"
            icon="/images/icons/tracking_1.svg"
            to="/apps/tracking"
            collapsed={collapsed}
          />
        </Restrict>
        <Restrict app={Apps.VEHICLES}>
          <SidebarItem label="Vehicles" icon={<IoAirplaneOutline />} to="/apps/vehicles" collapsed={collapsed} />
        </Restrict>
        <Restrict app={Apps.PROJECT_TRACKING} capability={Capabilities.PROJECT_READ}>
          <SidebarItem
            label="Work orders"
            icon="/images/icons/work_order_1.svg"
            to="/apps/projects"
            collapsed={collapsed}
          />
        </Restrict>
        <Restrict app={Apps.REPORTING} capability={Capabilities.REPORT_READ}>
          <SidebarItem label="Reporting" icon="/images/icons/report.svg" to="/apps/reporting" collapsed={collapsed} />
        </Restrict>
        <Restrict app={Apps.TOOL_SHOP}>
          <SidebarItem label="Tool shop" icon="/images/icons/checkout_1.svg" to="/apps/shop" collapsed={collapsed} />
        </Restrict>
        <Restrict app={Apps.TOOL_SHOP_MANAGEMENT}>
          <SidebarItem
            label="Shop manager"
            icon="/images/icons/marketplace_1.svg"
            to="/apps/shop_manager"
            collapsed={collapsed}
          />
        </Restrict>
        <Restrict app={Apps.REGULATORY_COMPLIANCE}>
          <SidebarItem
            label="Regulatory compliance"
            icon="/images/icons/gdpr_1.svg"
            to="/apps/compliance"
            collapsed={collapsed}
          />
        </Restrict>
        <Restrict app={Apps.WORKFORCE}>
          <SidebarItem
            label="Workforce"
            icon="/images/icons/workforce_1.svg"
            to="/apps/workforce"
            collapsed={collapsed}
          />
        </Restrict>
      </div>
    </div>
  );
};

const Footer = ({ collapsed }: FixLater) => {
  const history = useNavigate();
  const me = useSelector(getMe);
  const logout = () => {
    Account.removeSession();
    Logger.debug("logging out");
    Logger.flush().then(() => {
      history("/logout");
    });
  };
  return (
    <div>
      <RestrictHasOneCapability
        capabilities={[
          Capabilities.CONFIGURE_TEAM,
          Capabilities.CONFIGURE_ROLES,
          Capabilities.CONFIGURE_BRANDING,
          Capabilities.CONFIGURE_LOCATIONS,
        ]}
      >
        <MenuItem to="/configuration" icon="/images/icons/company.svg">
          {!collapsed && "Configuration"}
        </MenuItem>
      </RestrictHasOneCapability>

      {me && me.profileImage ? (
        <MenuItem to="/account/settings" image={me.profileImage}>
          {!collapsed && "Account"}
        </MenuItem>
      ) : (
        <MenuItem to="/account/settings" icon="/images/icons/account.svg">
          {!collapsed && "Account"}
        </MenuItem>
      )}

      <MenuItem onClick={logout} icon="/images/icons/logout_1.svg">
        {!collapsed && "Sign out"}
      </MenuItem>
    </div>
  );
};

export const MainSidebar = () => {
  const dispatch = useDispatch();
  const collapsed = useSelector(selectMainSideBarCollapsed);

  const handleToggleCollapse = () => {
    dispatch(setMainSideBarCollapsed(!collapsed));
  };

  return (
    <Sidebar main collapsed={collapsed} onToggleCollapse={handleToggleCollapse}>
      <div className={style.mainSidebarContent}>
        <Pages collapsed={collapsed} />
        <Footer collapsed={collapsed} />
      </div>
    </Sidebar>
  );
};
