import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToolStorage } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { openModal } from "lib/actions/modal";
import { showPopupFlag } from "lib/actions/userInterface";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { MixAction, MixPanel } from "lib/mixpanel";
import { useDispatch, useSelector } from "store";
import {
  deleteCaseScanner,
  selectCaseScannerByID,
  unlinkStorage,
  updateStorage,
  updateCaseScanner,
  selectStorageByID,
  refreshDeviceScreen,
  rebootDevice,
  powerOffDevice,
} from "store/reducers";
import { FixLater, TCaseScanner, TLocation, TStorage, TToolRoom, UUID } from "types";
import Avatar from "components/Avatar";
import { SecondaryButton, DangerButton, WarningButton } from "components/Buttons";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import { TextAreaInput, TextInput } from "components/Input";
import StageHeader from "components/StageHeader";
import { Paragraph, Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs/LocationSelector/LocationSelector";
import { ToolRoomSelector, VSpace } from "components/shared";

type CaseScannerSettingsPageProps = {
  caseScannerId: UUID;
};

export const CaseScannerSettingsPage = ({ caseScannerId }: CaseScannerSettingsPageProps) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { storageId } = useToolStorage();
  const storage = useSelector(selectStorageByID(storageId || ""));
  const entity = useSelector(selectCaseScannerByID(caseScannerId));
  const [values, setValues] = useState<Partial<TStorage>>();

  useEffect(() => {
    if (!storage) return;
    setValues(storage);
  }, [storage]);

  const handleChange = (name: string, value: FixLater) => {
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const saveStorageChanges = async (entity: TStorage) => {
    try {
      await dispatch(updateStorage(entity)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const saveCaseScannerChanges = async (entity: TCaseScanner) => {
    try {
      await dispatch(updateCaseScanner(entity)).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleBlur = (name: keyof TStorage) => {
    return (value: FixLater) => {
      if (!storage) return;
      saveStorageChanges({
        ...storage,
        [name]: value,
      });
    };
  };

  const changeLocation = (name: string, value?: TLocation) => {
    if (!storage) return;
    saveStorageChanges({
      ...storage,
      locationId: value?.id,
    });
  };

  const handleChangeToolRoom = async (name: string, value?: TToolRoom) => {
    if (!entity) return;
    saveCaseScannerChanges({
      ...entity,
      toolRoomId: value?.id,
    });
  };

  const handleUnlinkStorage = () => {
    if (!storageId) return;
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description:
          "Are you sure you want to unlink this case scanner? Doing this will return the case scanner to factory settings.",
        actionText: "Unlink case scanner",
        actionColor: "warning",
        onConfirm: async () => {
          try {
            await dispatch(unlinkStorage(storageId)).unwrap();
            MixPanel.track(MixAction.RemoteControlUnlinkDevice, {
              storageId: storageId,
            });
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Case scanner unlinked",
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
        },
      }),
    );
  };

  const reboot = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          if (!storageId) return;
          await dispatch(
            rebootDevice({
              storageId,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will reboot shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to reboot this device?",
      "Reboot device",
    );
  };

  const powerOff = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          if (!storageId) return;
          await dispatch(
            powerOffDevice({
              storageId,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will shutdown shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to shutdown this device?",
      "Shutdown device",
    );
  };

  const refreshStorageScreen = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          if (!storageId) return;
          await dispatch(
            refreshDeviceScreen({
              storageId,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Screen was refreshed",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to refresh the case scanner screen?",
      "Refresh screen",
    );
  };

  const sendRemoteControlCommand = (callback: () => void, description: string, actionText = "Confirm") => {
    return () => {
      dispatch(
        openModal(Modals.CONFIRMATION, {
          description: description,
          actionText: actionText,
          actionColor: "primary",
          onConfirm: callback,
        }),
      );
    };
  };

  const removeCaseScanner = () => {
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description: "Are you sure you want to delete this case scanner? This action is permanent.",
        actionText: "Delete case scanner",
        onConfirm: async () => {
          try {
            await dispatch(deleteCaseScanner(caseScannerId));
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Case scanner deleted",
              }),
            );
            history("/apps/storage/case_scanners");
          } catch (err) {
            handlePopupFlagError(err);
          }
        },
      }),
    );
  };

  const handleImageChange = async (imageUrl: string) => {
    if (!entity) return;
    await saveCaseScannerChanges({
      ...entity,
      imageUrl,
    });
  };

  if (!entity || !values || !storage || !storageId) {
    return null;
  }

  return (
    <>
      <StageHeader title="Settings" />
      <DataGrid>
        <DataItem label="Image" value={<Avatar canEdit image={entity.imageUrl} onChange={handleImageChange} />} />
        <DataItem label="Storage code" value={<Text>{storage.id}</Text>} />
        <DataItem
          label="Name"
          value={
            <TextInput
              value={values.name}
              input={{ name: "name", placeholder: "Enter name" }}
              onChange={handleChange}
              onBlur={handleBlur("name")}
            />
          }
        />
        <DataItem
          label="Description"
          value={
            <TextAreaInput
              value={values.description}
              input={{
                name: "description",
                placeholder: "Enter description",
              }}
              onChange={handleChange}
              onBlur={handleBlur("description")}
            />
          }
        />
        <DataItem
          label="Tool room"
          value={
            <ToolRoomSelector
              appearance="normal"
              name="toolRoomId"
              value={entity.toolRoomId}
              onChange={handleChangeToolRoom}
            />
          }
        />
        <DataItem
          label="Location"
          value={
            <LocationSelector
              appearance="normal"
              name="locationId"
              value={storage.locationId}
              onChange={changeLocation}
            />
          }
        />
        {storage.sentryApiKey?.dateActivated && (
          <DataItem
            label="Remote control"
            value={
              storage.online ? (
                <DataItemGroup>
                  <VSpace gap="gap-2">
                    <FieldGroup label="Power options">
                      <SecondaryButton fitContent icon="/images/icons/restart_1.svg" onClick={reboot()}>
                        Reboot
                      </SecondaryButton>
                      <SecondaryButton fitContent icon="/images/icons/power_1.svg" onClick={powerOff()}>
                        Power off
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Miscellaneous">
                      <SecondaryButton fitContent icon="/images/icons/loading.svg" onClick={refreshStorageScreen()}>
                        Refresh screen
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Unlink case scanner">
                      <WarningButton fitContent icon="/images/icons/unlink_1.svg" onClick={handleUnlinkStorage}>
                        Unlink case scanner
                      </WarningButton>
                    </FieldGroup>
                  </VSpace>
                </DataItemGroup>
              ) : (
                <DataItemGroup>
                  <FieldGroup label="Commands">
                    <Paragraph muted>The case scanner must be online to access remote control commands.</Paragraph>
                  </FieldGroup>
                  <FieldGroup label="Unlink case scanner">
                    <WarningButton fitContent icon="/images/icons/unlink_1.svg" onClick={handleUnlinkStorage}>
                      Unlink case scanner
                    </WarningButton>
                  </FieldGroup>
                </DataItemGroup>
              )
            }
          />
        )}
        <DataItem
          label="Delete case scanner"
          value={
            <DangerButton icon="/images/icons/trash.svg" onClick={removeCaseScanner}>
              Delete case scanner
            </DangerButton>
          }
        />
      </DataGrid>
    </>
  );
};
