//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import Logger from "../Logger";
import * as types from "../actions/actionTypes";
import * as actions from "../actions/storages";
import Api from "../api/storages";

function* fetchStorage(action) {
  try {
    const storage = yield Api.getStorage(action.payload);
    yield put(actions.receiveStorage(storage));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestStorageFailure(err));
  }
}

function* fetchStorages(action) {
  try {
    const { data, metadata } = yield Api.getStorages(action.payload);
    yield put(actions.receiveStorages(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestStoragesFailure(err));
  }
}

function* fetchStorageAccessGrants(action) {
  try {
    const { data, metadata } = yield Api.getStorageAccessGrants(action.payload.storageId, action.payload.options);
    yield put(actions.requestStorageAccessGrantsSuccess(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestStorageAccessGrantsFailure(err));
  }
}

function* fetchStorageStatistics(action) {
  try {
    const { totalCount, assignedCount } = yield Api.getStorageStatistics();
    yield put(actions.receiveStorageStatistics(totalCount, assignedCount));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestStorageStatisticsFailure(err));
  }
}

function* getAccessPolicy(action) {
  try {
    const { data } = yield Api.getAccessPolicy(action.payload);
    yield put(actions.receiveAccessPolicy(data));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestAccessPolicyFailure(err));
  }
}

function* getAccessPolicyAttempts(action) {
  try {
    const { data, metadata } = yield Api.getAccessPolicyAttempts(action.payload.policyID, action.payload.options);
    yield put(actions.receiveAccessPolicyAttempts(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestAccessPolicyAttemptsFailure(err));
  }
}

function* storagesSaga() {
  yield takeLatest(types.REQUEST_STORAGE, fetchStorage);
  yield takeLatest(types.REQUEST_STORAGES, fetchStorages);
  yield takeLatest(types.REQUEST_STORAGE_STATISTICS, fetchStorageStatistics);
  yield takeLatest(types.GET_STORAGE_GRANTS_REQUEST, fetchStorageAccessGrants);
  yield takeLatest(types.GET_ACCESS_POLICY_REQUEST, getAccessPolicy);
  yield takeLatest(types.GET_ACCESS_POLICY_ATTEMPTS_REQUEST, getAccessPolicyAttempts);
}

export default storagesSaga;
