//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import classes from "components/CardActions/CardActions.module.scss";

const CardActions = ({ className, children }) => (
  <div className={classNames(classes.actions, className)}>{children}</div>
);

CardActions.defaultProps = {};

CardActions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default CardActions;
