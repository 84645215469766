//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  users: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
    },
  },
  user: {
    fetching: false,
    error: null,
    data: null,
  },
  roles: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
    },
  },
  role: {
    fetching: false,
    error: null,
    data: null,
  },
  keycards: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
    },
  },
  keycard: {
    fetching: false,
    error: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.REQUEST_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.REQUEST_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.RECEIVE_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.REQUEST_USER:
      return {
        ...state,
        user: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.REQUEST_USER_FAILURE:
      return {
        ...state,
        user: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.RECEIVE_USER:
      return {
        ...state,
        user: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_ROLES_REQUEST:
      return {
        ...state,
        roles: {
          ...state.roles,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_ROLES_RECEIVE:
      return {
        ...state,
        roles: {
          ...state.roles,
          fetching: false,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_ROLES_FAILURE:
      return {
        ...state,
        roles: {
          ...state.roles,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.GET_ROLE_REQUEST:
      return {
        ...state,
        role: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_ROLE_RECEIVE:
      return {
        ...state,
        role: {
          fetching: true,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_ROLE_FAILURE:
      return {
        ...state,
        role: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.GET_KEYCARDS_REQUEST:
      return {
        ...state,
        keycards: {
          ...state.keycards,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_KEYCARDS_RECEIVE:
      return {
        ...state,
        keycards: {
          ...state.keycards,
          fetching: false,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_KEYCARDS_FAILURE:
      return {
        ...state,
        keycards: {
          ...state.keycards,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.GET_KEYCARD_REQUEST:
      return {
        ...state,
        keycard: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_KEYCARD_RECEIVE:
      return {
        ...state,
        keycard: {
          fetching: true,
          error: null,
          data: action.payload,
        },
      };
    case type.GET_KEYCARD_FAILURE:
      return {
        ...state,
        keycard: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    default:
      return state;
  }
};
