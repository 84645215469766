//@ts-nocheck
import React, { ComponentProps, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import orderBy from "lodash/orderBy";
import { pageBlocksChange, selectBlockID, setCurrentBlockID } from "lib/actions/blocks";
import { getCurrentBlockID, getPageBlocks, getTargetBlockID } from "lib/reducers/blocks.selectors";
import { FixLater } from "types";
import KeyHandler, { KEY_CODE_ARROW_DOWN, KEY_CODE_ARROW_UP, KEY_EVENT_DOWN } from "components/KeyHandler";
import BlockConfigMenuPortal from "components/Sheet/components/BlockConfigMenuPortal";
import BlockMenuPortal from "components/Sheet/components/BlockMenuPortal";
import MediaMenuPortal from "components/Sheet/components/MediaMenuPortal";
import SheetBlocks from "components/Sheet/components/SheetBlocks";
import { getDOMNodeForBlockID } from "components/Sheet/utils";
import style from "./sheet.module.scss";

type SheetProps = ComponentProps<"div"> & {
  pageBlocks: FixLater[];
  onCreateBlock: (blocks: FixLater[]) => void;
  onRemoveBlock: (blocks: FixLater[], blockID: string) => void;
  onReorder: (blocks: FixLater[]) => void;
};

export const Sheet = ({
  pageBlocks: initialBlocks,
  onReorder,
  onCreateBlock,
  onRemoveBlock,
  className,
}: SheetProps) => {
  const dispatch = useDispatch();
  const currentBlockID = useSelector(getCurrentBlockID);
  const targetBlockID = useSelector(getTargetBlockID);
  const pageBlocks = useSelector(getPageBlocks);
  const ordered = useMemo(() => {
    return orderBy(Object.values(pageBlocks), "order", "asc");
  }, [pageBlocks]);

  useEffect(() => {
    dispatch(pageBlocksChange(initialBlocks));

    // When only one block exists, select it
    if (initialBlocks.length === 1) {
      dispatch(selectBlockID(initialBlocks[0].blockId));
    }
  }, [initialBlocks]);

  // Select a specific block when a block is added or removed
  useEffect(() => {
    if (targetBlockID) {
      const nextBlock = getDOMNodeForBlockID(targetBlockID);
      if (nextBlock) {
        const editableNode = nextBlock.querySelector("[contenteditable]");
        if (editableNode) {
          editableNode.focus();
          dispatch(setCurrentBlockID(targetBlockID));
        }
      }
    }
  }, [targetBlockID]);

  const selectPreviousBlock = () => {
    const currentBlock = pageBlocks[currentBlockID];
    const currentOrder = currentBlock.order;
    const previousBlock = Object.values(pageBlocks).find(({ order }) => order === currentOrder - 1);
    if (previousBlock) {
      dispatch(selectBlockID(previousBlock.blockId));
    }
  };

  const selectNextBlock = () => {
    const currentBlock = pageBlocks[currentBlockID];
    const currentOrder = currentBlock.order;
    const nextBlock = Object.values(pageBlocks).find(({ order }) => order === currentOrder + 1);
    if (nextBlock) {
      dispatch(selectBlockID(nextBlock.blockId));
    }
  };

  return (
    <div className={classNames(style.sheet, className)}>
      <BlockMenuPortal onCreateBlock={onCreateBlock} />
      <BlockConfigMenuPortal onRemoveBlock={onRemoveBlock} />
      <MediaMenuPortal />
      <KeyHandler keyCode={KEY_CODE_ARROW_UP} keyEventName={KEY_EVENT_DOWN} onKeyHandle={selectPreviousBlock} />
      <KeyHandler keyCode={KEY_CODE_ARROW_DOWN} keyEventName={KEY_EVENT_DOWN} onKeyHandle={selectNextBlock} />
      <SheetBlocks
        pageBlocks={ordered}
        onReorder={onReorder}
        onCreateBlock={onCreateBlock}
        onRemoveBlock={onRemoveBlock}
      />
    </div>
  );
};
