import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TCaseScannerHistoryEvent, UUID } from "types";

const selectState = (state: RootStore) => state.caseScannerHistoryEvents;

export const selectCaseScannerHistoryEvents = createSelector(selectState, (state) => state.data);

export const selectCaseScannerHistoryEventsList = createSelector(selectCaseScannerHistoryEvents, (data) =>
  Object.values(data),
);

export const selectCaseScannerHistoryEventByID = (id: UUID) =>
  createSelector(selectCaseScannerHistoryEvents, (data): TCaseScannerHistoryEvent | undefined => data[id]);
