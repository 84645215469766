import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchStorageAlerts, selectStorageAlertByID } from "store/reducers";
import { Pagination, UUID } from "types";
import { Table, TableCell, TableHeader, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import { TableLink } from "components/Table/TableLink/TableLink";
import TablePagination from "components/Table/TablePagination";
import TableRowNotice from "components/Table/TableRowNotice";
import { StorageAlertTableRowDropdown } from "components/shared/features/storageAlerts/StorageAlertTableRowDropdown/StorageAlertTableRowDropdown";
import { TableRowLoading } from "components/shared/tables";

type TableRowProps = {
  storageId: string;
  storageAlertId: UUID;
  onRemove: () => void;
};

const Row = ({ storageId, storageAlertId, onRemove }: TableRowProps) => {
  const { pathname } = useLocation();
  const entity = useSelector(selectStorageAlertByID(storageAlertId));

  if (!entity?.alert) return null;

  return (
    <TableRow>
      <TableCell>
        <TableLink to={`${pathname}/${entity.id}`}>{entity.alert?.name}</TableLink>
      </TableCell>
      <TableCell>{entity.alert?.description}</TableCell>
      <TableCell>
        <StorageAlertTableRowDropdown storageId={storageId} storageAlertId={storageAlertId} onDelete={onRemove} />
      </TableCell>
    </TableRow>
  );
};

type StorageAlertsTableProps = {
  storageId: string;
};

export const StorageAlertsTable = ({ storageId }: StorageAlertsTableProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [ids, setIds] = useState<UUID[]>([]);
  const [paging, setPaging] = useState<Pagination>({
    pageStart: 0,
    pageLimit: 20,
    pageCount: 0,
    totalCount: 0,
    nextPageStart: null,
    previousPageStart: null,
  });

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart, paging.pageLimit]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchStorageAlerts({ storageId, pageStart, pageLimit })).unwrap();
      setIds(response.results.map(({ id }) => id));
      setPaging(response.paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const prevPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.previousPageStart !== null ? prevState.previousPageStart : 0,
    }));
  };

  const nextPage = () => {
    setPaging((prevState) => ({
      ...prevState,
      pageStart: prevState.nextPageStart !== null ? prevState.nextPageStart : 0,
    }));
  };

  const handleLimitChange = (value: number) => {
    setPaging((prevState) => ({
      ...prevState,
      pageLimit: value,
    }));
  };

  const handleRemove = () => {
    if (paging.pageStart === 0) {
      fetch(0, paging.pageLimit);
      return;
    }

    setPaging((prevState) => ({
      ...prevState,
      pageStart: 0,
    }));
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell>Description</TableHeadCell>
            <TableHeadCell isEmpty />
          </TableRow>
        </TableHeader>
        <TableBody>
          {!loading &&
            ids.map((id) => <Row key={id} storageId={storageId} storageAlertId={id} onRemove={handleRemove} />)}
          {!loading && ids.length === 0 ? (
            <TableRowNotice colSpan={2} notice="You have no alerts for this storage yet" />
          ) : null}
        </TableBody>
      </Table>
      {loading && <TableRowLoading />}
      <TablePagination paging={paging} onPrevPage={prevPage} onNextPage={nextPage} onChangeLimit={handleLimitChange} />
    </>
  );
};
