export const padNumber = (value: number, size: number = 3) => {
  return String(value).padStart(size, "0");
};

type FormatFullNameOptions = {
  forename?: string;
  surname?: string;
  email?: string;
};

export const formatFullName = ({ forename, surname, email = "" }: FormatFullNameOptions): string => {
  if (!forename && !surname) {
    return email;
  }
  return [forename, surname].filter(Boolean).join(" ").trim();
};
