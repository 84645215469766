//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  data: {},
  paging: {
    pageStart: 0,
    pageLimit: 50,
    totalCount: 0,
    nextPageStart: "",
    previousPageStart: "",
  },
  projectBlocks: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
      totalCount: 0,
      nextPageStart: "",
      previousPageStart: "",
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PROJECTS_SUCCESS:
      return {
        ...state,
        data: action.payload.reduce((data, item) => ({ ...data, [item.id]: item }), state.data),
      };
    case type.GET_PROJECT_SUCCESS:
    case type.CREATE_PROJECT_FULFIL:
    case type.UPDATE_PROJECT_FULFIL:
      return {
        ...state,
        data: { ...state.data, [action.payload.id]: action.payload },
      };
    case type.GET_PROJECT_FAILURE:
      return {
        ...state,
        project: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.GET_PROJECT_BLOCKS_REQUEST:
      return {
        ...state,
        projectBlocks: {
          ...state.projectBlocks,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_PROJECT_BLOCKS_RECEIVE:
      return {
        ...state,
        projectBlocks: {
          ...state.projectBlocks,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };
    case type.GET_PROJECT_BLOCKS_FAILURE:
      return {
        ...state,
        projectBlocks: {
          ...state.projectBlocks,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    default:
      return state;
  }
};
