//@ts-nocheck
import React from "react";
import withReports from "hocs/withReports";
import PropTypes from "prop-types";
import Account from "lib/Account";
import Api from "lib/api/api";
import { Anchor } from "components/Typography";
import style from "./listReport.module.scss";

const ReportResultMenu = ({ evidence, onDownload }) => {
  return (
    <div className={style.menuBar}>
      {evidence ? (
        <Anchor to={`/evidences/${evidence.id}`} target="_blank">
          Evidence
        </Anchor>
      ) : null}
      <Anchor onClick={onDownload}>Download</Anchor>
      <Anchor to="/apps/reporting/reports">Exit report</Anchor>
    </div>
  );
};

const Iframe = (props) => (
  <div className={style.iFrameContainer} dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />
);

const ListReport = ({ reportId, stateStore, onDownload }) => {
  const url = `${Api.getBaseUrl}/reports/${reportId}/result?raw=true&authorization=${Account.token()}`;
  const iframe = `<iframe src="${url}"></iframe>`;
  return (
    <div className={style.container}>
      <ReportResultMenu evidence={stateStore.evidence.data} onDownload={onDownload} />
      <Iframe iframe={iframe} />
    </div>
  );
};

ListReport.defaultProps = {};

ListReport.propTypes = {
  reportResult: PropTypes.any,
  onDownload: PropTypes.func,
};

export default withReports(ListReport);
