import { useSelector } from "react-redux";
import Capabilities from "lib/constants/Capabilities";
import { selectCapabilities } from "lib/reducers/lookupData.selectors";
import { selectRoleByID } from "store/reducers";
import { FixLater } from "types";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import PowerInput from "components/inputs/PowerInput";

type RoleCapabilitiesProps = {
  roleId: number;
  onChange: (value: FixLater) => void;
};

export const RoleCapabilities = ({ roleId, onChange }: RoleCapabilitiesProps) => {
  const role = useSelector(selectRoleByID(roleId));
  return (
    <DataGrid className="gap-y-12">
      <DataItem
        label="Configurations"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.CONFIGURE_TEAM,
                Capabilities.CONFIGURE_ROLES,
                Capabilities.CONFIGURE_BRANDING,
                Capabilities.CONFIGURE_LOCATIONS,
                Capabilities.CONFIGURE_ASSET_ISSUE_PROBLEMS,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Tools"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.ASSET_READ,
                Capabilities.ASSET_CREATE,
                Capabilities.ASSET_UPDATE,
                Capabilities.ASSET_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Toolboxes"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.STORAGE_READ,
                Capabilities.STORAGE_CREATE,
                Capabilities.STORAGE_UPDATE,
                Capabilities.STORAGE_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Reporting"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.REPORT_READ,
                Capabilities.REPORT_CREATE,
                Capabilities.REPORT_UPDATE,
                Capabilities.REPORT_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Work orders"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.PROJECT_READ,
                Capabilities.PROJECT_CREATE,
                Capabilities.PROJECT_UPDATE,
                Capabilities.PROJECT_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Tool stores"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.TOOL_STORE_READ,
                Capabilities.TOOL_STORE_CREATE,
                Capabilities.TOOL_STORE_UPDATE,
                Capabilities.TOOL_STORE_REMOVE,
                Capabilities.TOOL_STORE_ORDER_READ,
                Capabilities.TOOL_STORE_ORDER_CREATE,
                Capabilities.TOOL_STORE_ORDER_UPDATE,
                Capabilities.TOOL_STORE_ORDER_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Tool rooms"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.TOOL_ROOM_READ,
                Capabilities.TOOL_ROOM_CREATE,
                Capabilities.TOOL_ROOM_UPDATE,
                Capabilities.TOOL_ROOM_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Case scanners"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.CASE_SCANNER_READ,
                Capabilities.CASE_SCANNER_CREATE,
                Capabilities.CASE_SCANNER_UPDATE,
                Capabilities.CASE_SCANNER_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Rag control"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.RAG_CONTROL_READ,
                Capabilities.RAG_CONTROL_CREATE,
                Capabilities.RAG_CONTROL_UPDATE,
                Capabilities.RAG_CONTROL_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Vehicles"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.VEHICLE_READ,
                Capabilities.VEHICLE_CREATE,
                Capabilities.VEHICLE_UPDATE,
                Capabilities.VEHICLE_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Tool issues"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.ASSET_ISSUE_READ,
                Capabilities.ASSET_ISSUE_CREATE,
                Capabilities.ASSET_ISSUE_UPDATE,
                Capabilities.ASSET_ISSUE_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
      <DataItem
        label="Doorways"
        value={
          <DataItemGroup>
            <CapabilitiesInput
              role={role}
              onChange={onChange}
              items={[
                Capabilities.DOORWAY_READ,
                Capabilities.DOORWAY_CREATE,
                Capabilities.DOORWAY_UPDATE,
                Capabilities.DOORWAY_REMOVE,
              ]}
            />
          </DataItemGroup>
        }
      />
    </DataGrid>
  );
};

const CapabilitiesInput = ({ role, items, onChange }: FixLater) => {
  const capabilities = useSelector(selectCapabilities);
  const roleCapabilities = role.capabilities;

  const handleChange = (capabilityID: FixLater) => {
    return (value: FixLater) => {
      if (value) {
        onChange([...roleCapabilities, { roleId: role.id, capabilityId: capabilityID }]);
      } else {
        onChange(roleCapabilities.filter(({ capabilityId }: FixLater) => capabilityId !== capabilityID));
      }
    };
  };
  const isActive = (value: FixLater) => !!roleCapabilities.find(({ capabilityId }: FixLater) => value === capabilityId);

  return (
    <DataGrid className="gap-y-4">
      {items.map((itemValue: FixLater) => {
        const capability = capabilities.find((entity: FixLater) => entity.id === itemValue);
        return (
          capability && (
            <DataItem
              key={itemValue}
              label={capability.label}
              description={capability.description}
              value={<PowerInput value={isActive(itemValue)} onChange={handleChange(itemValue)} />}
            />
          )
        );
      })}
    </DataGrid>
  );
};
