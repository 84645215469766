import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Preferences from "lib/Preferences";
import { GET_LOOKUPS_RECEIVE } from "lib/actions/actionTypes";
import { FixLater, TUser } from "types";

export interface SettingsReducerState {
  workspace?: string;
  mainSideBarCollapsed: boolean;
  me?: TUser;
}

const initialState: SettingsReducerState = {
  mainSideBarCollapsed: Preferences.preferenceExists(Preferences.SIDE_BAR_MAIN_COLLAPSED),
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setMainSideBarCollapsed: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        Preferences.setPreference(Preferences.SIDE_BAR_MAIN_COLLAPSED);
      } else {
        Preferences.clearPreference(Preferences.SIDE_BAR_MAIN_COLLAPSED);
      }
      state.mainSideBarCollapsed = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(GET_LOOKUPS_RECEIVE, (state, action: FixLater) => {
      state.workspace = action.payload.workspace;
      state.me = action.payload.me;
    });
  },
});

export const { setMainSideBarCollapsed } = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
