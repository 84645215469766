//@ts-nocheck

const extractIntoCategories = (categories, component) => {
  if (!categories[component.category]) {
    categories[component.category] = {
      id: component.category,
      name: component.category,
      items: [],
    };
  }
  categories[component.category].items.push(component);
  return categories;
};

export default extractIntoCategories;
