import { ChangeEvent, FormEvent, useState } from "react";
import BaseCurrencyInput, {
  CurrencyInputProps as BaseCurrencyInputProps,
  CurrencyInputOnChangeValues,
} from "react-currency-input-field";
import styled from "styled-components";

const Input = styled(BaseCurrencyInput)`
  padding-left: 5rem;
`;

const CurrencySelector = styled.select`
  &,
  &:focus,
  &:hover {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

export type CurrencyInputValue = {
  currency?: string | null;
  amount?: string | null;
};

export type CurrencyInputElement = {
  type: "currency";
  value: CurrencyInputValue;
};

export type CurrencyInputProps = Omit<BaseCurrencyInputProps, "value" | "onValueChange" | "onChange" | "onBlur"> & {
  value: CurrencyInputValue;
  onBlur?: (value: ChangeEvent<CurrencyInputElement>) => void;
  defaultCurrency?: string;
};

export const CurrencyInput = ({
  className,
  value,
  disabled,
  allowNegativeValue = false,
  decimalScale = 2,
  defaultCurrency = "GBP",
  onBlur,
  ...props
}: CurrencyInputProps) => {
  const [currency, setCurrency] = useState<CurrencyInputValue["currency"]>(value.currency || defaultCurrency);
  const [amount, setAmount] = useState<string | undefined>(
    value.amount !== null && value.amount !== undefined ? value.amount.toString() : undefined,
  );

  const handleAmountChange = (_value: string | undefined, _name?: string, _values?: CurrencyInputOnChangeValues) => {
    setAmount(_value);
  };

  const handleBlur = (event: ChangeEvent) => {
    const element: CurrencyInputElement = {
      type: "currency",
      value: {
        currency,
        amount: amount,
      },
    };
    const newEvent = {
      ...event,
      target: element,
    } as unknown as ChangeEvent<CurrencyInputElement>;
    onBlur?.(newEvent);
  };

  const handleCurrencyChange = (event: FormEvent<HTMLSelectElement>) => setCurrency(event.currentTarget.value);

  return (
    <div className="relative mt-2 rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 flex items-center">
        <CurrencySelector
          disabled={disabled}
          name="currency"
          className="h-full rounded-md bg-transparent py-0 pl-3 pr-7 text-gray-500"
          value={value.currency !== null ? value.currency : undefined}
          onChange={handleCurrencyChange}
          onBlur={handleBlur}
        >
          <option>GBP</option>
          <option>EUR</option>
          <option>USD</option>
        </CurrencySelector>
      </div>
      <Input
        {...props}
        allowDecimals
        disabled={disabled}
        className={className}
        value={amount}
        allowNegativeValue={allowNegativeValue}
        decimalScale={decimalScale}
        onValueChange={handleAmountChange}
        onBlur={handleBlur}
      />
    </div>
  );
};
