//@ts-nocheck
import React, { useMemo } from "react";
import arrayMove from "array-move";
import { v4 as uuidv4 } from "uuid";
import { openModal } from "lib/actions/modal";
import Modals from "lib/constants/Modals";
import StorageModels from "lib/constants/StorageModels";
import { useDispatch } from "store";
import { FixLater, TStorage, TStorageDrawer } from "types";
import { SecondaryButton } from "components/Buttons";
import { Text } from "components/Typography";
import { SortableDataList, SortableDataListItem } from "components/lists/DataList";
import styles from "./DrawerNames.module.scss";

type DrawerNamesProps = {
  storage: TStorage;
  onChange: (rearranged: TStorageDrawer[]) => void;
};

export const DrawerNames = ({ storage, onChange }: DrawerNamesProps) => {
  const dispatch = useDispatch();
  const drawers = useMemo(() => {
    const model = StorageModels.resolveItem(storage.model);
    let drawers = [];
    drawers.length = model?.drawerCount || 0;
    // Populate with defaults
    for (let i = 0; i < drawers.length; i++) {
      drawers[i] = {
        id: uuidv4(),
        name: "",
        value: i + 1,
        order: i,
      };
    }

    // Populate with existing drawers
    for (const drawer of storage.drawers) {
      try {
        drawers[drawer.value - 1] = { ...drawer };
      } catch (err) {
        console.error(err);
      }
    }

    return drawers.sort((a, b) => (a.order > b.order ? 1 : -1));
  }, [storage.drawers]);

  const handleSortEnd = ({ oldIndex, newIndex }: FixLater) => {
    let rearranged = arrayMove(drawers, oldIndex, newIndex);
    for (let i = 0; i < drawers.length; i++) {
      rearranged[i].order = i;
    }
    onChange(rearranged);
  };

  const changeLayout = (drawer: TStorageDrawer) => {
    dispatch(
      openModal(Modals.CONFIGURE_STORAGE_DRAWER_LAYOUT, {
        storage,
        drawer,
        onSuccess: () => {
          onChange(drawers);
        },
      }),
    );
  };

  return (
    <SortableDataList onSortEnd={handleSortEnd} useDragHandle>
      {drawers.map((drawer, i) => {
        const handleConfirm = (value) => {
          try {
            drawers[i].name = value;
            onChange && onChange(drawers);
          } catch (err) {
            console.error(err);
          }
        };
        return (
          // @ts-ignore
          <SortableDataListItem key={drawer.value} index={drawer.order} id={drawer.value}>
            <div className={styles.DrawerNamesItem}>
              <Text
                canEdit
                value={drawer.name}
                placeholder={`Drawer ${drawer.value}`}
                onConfirm={handleConfirm}
                className={styles.DrawerNamesInput}
              />
              <SecondaryButton icon="/images/icons/tracking_1.svg" onClick={() => changeLayout(drawer)}>
                {drawer.svgPath ? "Change" : "Add"} layout
              </SecondaryButton>
            </div>
          </SortableDataListItem>
        );
      })}
    </SortableDataList>
  );
};
