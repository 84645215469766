//@ts-nocheck
import * as type from "./actionTypes";
import { receivePagedResult, requestFailure } from "./common";

export function requestStorages(options = {}) {
  return {
    type: type.REQUEST_STORAGES,
    payload: options,
  };
}

export function receiveStorages(storages, paging) {
  return {
    type: type.RECEIVE_STORAGES,
    payload: storages,
    paging,
  };
}

export function requestStoragesFailure(error) {
  const payload = typeof error === "string" ? new Error(error) : error;
  return {
    type: type.REQUEST_STORAGES_FAILURE,
    payload,
  };
}

export function requestStorage(id) {
  return {
    type: type.REQUEST_STORAGE,
    payload: id,
  };
}

export function receiveStorage(storage) {
  return {
    type: type.RECEIVE_STORAGE,
    payload: storage,
  };
}

export function requestStorageFailure(error) {
  const payload = typeof error === "string" ? new Error(error) : error;
  return {
    type: type.REQUEST_STORAGE_FAILURE,
    payload,
  };
}

export function requestStorageStatistics() {
  return {
    type: type.REQUEST_STORAGE_STATISTICS,
  };
}

export function receiveStorageStatistics(totalCount, assignedCount) {
  return {
    type: type.RECEIVE_STORAGE_STATISTICS,
    payload: {
      totalCount,
      assignedCount,
    },
  };
}

export function requestStorageStatisticsFailure(error) {
  return requestFailure(type.REQUEST_STORAGE_STATISTICS_FAILURE, error);
}

export function requestStorageAccessGrants(storageId, options = {}) {
  return {
    type: type.GET_STORAGE_GRANTS_REQUEST,
    payload: {
      storageId,
      options,
    },
  };
}

export function requestStorageAccessGrantsSuccess(users, paging) {
  return receivePagedResult(type.GET_STORAGE_GRANTS_RECEIVE, users, paging);
}

export function requestStorageAccessGrantsFailure(error) {
  return requestFailure(type.GET_STORAGE_GRANTS_FAILURE, error);
}

export function receiveStorageSession(session) {
  return {
    type: type.GET_STORAGE_SESSION_RECEIVE,
    payload: session,
  };
}

export function receiveStorageStatus(status) {
  return {
    type: type.GET_STORAGE_STATUS_RECEIVE,
    payload: status,
  };
}

export function requestAccessPolicy(policyID) {
  return {
    type: type.GET_ACCESS_POLICY_REQUEST,
    payload: policyID,
  };
}

export function receiveAccessPolicy(policy) {
  return {
    type: type.GET_ACCESS_POLICY_RECEIVE,
    payload: policy,
  };
}

export function requestAccessPolicyFailure(error) {
  return requestFailure(type.GET_ACCESS_POLICY_FAILURE, error);
}

export function requestAccessPolicyAttempts(policyID, options = {}) {
  return {
    type: type.GET_ACCESS_POLICY_ATTEMPTS_REQUEST,
    payload: {
      policyID,
      options,
    },
  };
}

export function receiveAccessPolicyAttempts(attempts, paging) {
  return receivePagedResult(type.GET_ACCESS_POLICY_ATTEMPTS_RECEIVE, attempts, paging);
}

export function requestAccessPolicyAttemptsFailure(error) {
  return requestFailure(type.GET_ACCESS_POLICY_ATTEMPTS_FAILURE, error);
}
