import { useState } from "react";
import styled from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { padNumber } from "lib/utility/format";
import { useDispatch, useSelector } from "store";
import {
  selectToolStoreByID,
  selectToolStoreOrderByID,
  selectToolStoreOrderItemsByOrderID,
  updateToolStoreOrder,
} from "store/reducers";
import { UUID } from "types";
import Card from "components/Card";
import { ProgressInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { GridContainer } from "components/shared";
import { PrimaryButton } from "components/shared/buttons";
import { ToolStoreOrderLocation } from "components/shared/features/toolStoreOrders/ToolStoreOrderLocation/ToolStoreOrderLocation";
import { ToolStoreOrderStatusText } from "components/shared/features/toolStoreOrders/ToolStoreOrderStatusText";
import { TextBody } from "components/shared/typography";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
`;

type ToolStoreUserOrderCardProps = {
  id: UUID;
};

export const ToolStoreUserOrderCard = ({ id }: ToolStoreUserOrderCardProps) => {
  const dispatch = useDispatch();
  const entity = useSelector(selectToolStoreOrderByID(id));
  const toolStore = useSelector(selectToolStoreByID(entity?.toolStoreId || ""));
  const items = useSelector(selectToolStoreOrderItemsByOrderID(id));
  const [loading, setLoading] = useState(false);

  if (!entity) {
    return null;
  }

  const requestReturn = async () => {
    try {
      setLoading(true);
      await dispatch(
        updateToolStoreOrder({
          ...entity,
          status: "return_requested",
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Return requested",
          description: "We've notified the tool store that your order should be picked up",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const itemsReady = items.filter((item) => item.dateReadyForDelivery);
  return (
    <StyledCard className="cursor-pointer">
      <GridContainer mobileColumns={2} tabletColumns={2} columns={2}>
        <div>
          <h3 className="text-sm text-gray-500">Order #{padNumber(entity.orderNumber)}</h3>
          <p className="mt-1 text-lg font-medium">
            <ToolStoreOrderStatusText status={entity.status} />
          </p>
        </div>
        <ToolStoreOrderLocation toolStoreOrderId={entity.id} />
        <FieldGroup label="Tool store" value={<TextBody>{toolStore?.storage.name}</TextBody>} />
      </GridContainer>
      {entity.status === "accepted" && (
        <div>
          <dt className="font-medium text-gray-500">
            Items ready for delivery ({itemsReady.length}/{items.length})
          </dt>
          <dd className="flex mt-1 text-gray-900 sm:col-span-2">
            <ProgressInput value={(itemsReady.length / items.length) * 100} />
          </dd>
        </div>
      )}
      {entity.status === "delivered" && (
        <div>
          <PrimaryButton onClick={requestReturn}>Ready for pick up</PrimaryButton>
        </div>
      )}
    </StyledCard>
  );
};
