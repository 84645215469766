//@ts-nocheck
import TaskListBlockProperties from "lib/blocks/TaskListBlockProperties";
import TextBlockProperties from "lib/blocks/TextBlockProperties";
import YouTubeBlockProperties from "lib/blocks/YouTubeBlockProperties";
import BlockTypes from "lib/constants/BlockTypes";

export default class BlockProperties {
  static createForBlockType(blockType) {
    switch (blockType) {
      case BlockTypes.YOUTUBE:
        return new YouTubeBlockProperties();
      case BlockTypes.TASK_LIST:
        return new TaskListBlockProperties();
      default:
        return new TextBlockProperties();
    }
  }
}
