import classNames from "classnames";
import { getBranding } from "lib/reducers/lookupData.selectors";
import { useSelector } from "store";
import { selectLocationByID, selectToolStoreByID } from "store/reducers";
import { UUID } from "types";
import { ToolStoreWaitTimeBubble } from "components/shared/features/toolStores/ToolStoreWaitTimeBubble/ToolStoreWaitTimeBubble";

type ToolStoreGridItemProps = {
  toolStoreId: UUID;
  onClick?: () => void;
  className?: string;
};

export const ToolStoreGridItem = ({ toolStoreId, onClick, className }: ToolStoreGridItemProps) => {
  const entity = useSelector(selectToolStoreByID(toolStoreId));
  const branding = useSelector(getBranding);
  const toolStoreImageUrl = entity?.imageUrl || branding?.portalLogo || "/images/automify.png";
  const location = useSelector(selectLocationByID(entity?.storage.locationId || ""));

  if (!entity) {
    return null;
  }

  return (
    <div className={classNames("group relative cursor-pointer", className)} onClick={onClick}>
      <div className="relative w-full h-56 bg-gray-200 rounded-md group-hover:opacity-75 lg:h-72 xl:h-80">
        <img
          src={toolStoreImageUrl}
          alt={entity.storage.name}
          className="w-full h-full object-center object-cover lg:w-full lg:h-full rounded-lg"
        />
        <div className="absolute right-4 -bottom-6">
          <ToolStoreWaitTimeBubble toolStoreId={toolStoreId} />
        </div>
      </div>
      <h3 className="mt-4 text-md font-semibold text-gray-700">
        <span aria-hidden="true" className="absolute inset-0" />
        {entity.storage.name}
      </h3>
      {location && <p className="mt-0.5 text-sm font-medium text-gray-900">{location.name}</p>}
    </div>
  );
};
