import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { DataResponse, PagedDataResponse, Pagination, TAssetProduct, UUID, FetchAssetProductsRequest } from "types";

export const fetchAssetProducts = createAsyncThunk(
  "assetProducts/fetchAssetProducts",
  async (request: FetchAssetProductsRequest): Promise<{ results: TAssetProduct[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TAssetProduct[]>>("/asset_products", request);
    return { results: data, paging: metadata };
  },
);

export const fetchAssetProductById = createAsyncThunk(
  "assetProducts/fetchAssetProductById",
  async (id: UUID): Promise<TAssetProduct> => {
    const { data } = await API.GET<DataResponse<TAssetProduct>>(`/asset_products/${id}`);
    return data;
  },
);
