//@ts-nocheck
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import dismissPopupFlag from "../middleware/dismissPopupFlag";
import lookupStorage from "../middleware/lookupStorage";
import rootReducer from "../reducers";
import sagas from "../sagas";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const combinedMiddlewares = applyMiddleware(sagaMiddleware, lookupStorage, dismissPopupFlag);
const store = createStore(rootReducer, {}, composeEnhancers(combinedMiddlewares));

sagaMiddleware.run(sagas);

if (store) {
  //Logger.trace("created redux store");
}

export default store;
