import { useSelector } from "store";
import { selectAuthSession } from "store/reducers";
import StageHeader from "components/StageHeader";
import StageSection from "components/StageSection";
import { PageLoading } from "components/shared";
import { AccountProfile, ChangePassword } from "components/shared/features/accounts";
import PageStage from "components/stages/PageStage";

export const AccountSettingsPage = () => {
  const session = useSelector(selectAuthSession);

  if (!session) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <StageHeader title="My Account settings" subtitle="Change your profile and account settings." />
      <StageSection isFirst>
        <AccountProfile userId={session.userId} />
      </StageSection>
      <StageSection>
        <ChangePassword />
      </StageSection>
    </PageStage>
  );
};
