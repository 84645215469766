//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import ShopItemImage from "pages/apps/Shop/shared/ShopItemImage/ShopItemImage";
import ShopItemPrimaryImage from "pages/apps/Shop/shared/ShopItemPrimaryImage/ShopItemImage";
import styles from "./ShopItemGallery.module.scss";

const ShopItemGallery = ({ id, editable, className }) => {
  const dispatch = useDispatch();
  const [activeImageID, setActiveImageID] = useState();
  const storeItem = useSelector(getStoreItemByID(id));
  const sortedImages = useMemo(() => {
    return (
      storeItem?.images?.sort((left, right) => {
        if (left.order < right.order) return -1;
        if (left.order > right.order) return 1;
        return 0;
      }) || []
    );
  }, [storeItem]);

  useEffect(() => {
    if (storeItem && storeItem.images.length && !activeImageID) {
      setActiveImageID(storeItem.images[0].id);
    }
  }, [dispatch, storeItem]);

  return (
    storeItem && (
      <div>
        <div>
          <ShopItemPrimaryImage itemID={id} />
        </div>
        <div className={styles.ShopItemGallery_preview}>
          {sortedImages?.map((image) => (
            <ShopItemImage key={`${id}_${image.id}`} editable={editable} itemID={id} imageID={image.id} />
          ))}
        </div>
      </div>
    )
  );
};

ShopItemGallery.defaultProps = {};

ShopItemGallery.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
};

export default ShopItemGallery;
