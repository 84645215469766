//@ts-nocheck
import { SHOW_POPUP_FLAG } from "../actions/actionTypes";
import { dismissPopupFlag } from "../actions/userInterface";

const dismissPopupFlagMiddleware = (store) => (next) => (action) => {
  if (action.type === SHOW_POPUP_FLAG) {
    setTimeout(() => {
      store.dispatch(dismissPopupFlag(action.payload.id));
    }, action.timeout || 5000);
  }
  next(action);
};

export default dismissPopupFlagMiddleware;
