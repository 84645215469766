//@ts-nocheck
import React from "react";
import ContentEditable from "react-contenteditable";
import classNames from "classnames";
import { wrapEmojis } from "components/Sheet/utils";
import style from "../sheet.module.scss";

const CMD_KEY = "/";

class EditableText extends React.Component {
  static defaultProps = {
    tag: "div",
  };

  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.isEmpty = this.isEmpty.bind(this);
    this.element = React.createRef();
    this.state = {
      block: props.block,
      active: props.active,
      html: wrapEmojis(props.value),
      previousKey: null,
    };
  }

  handleChange = (event) => {
    this.setState({ ...this.state, html: event.target.value });
    this.props.onChange && this.props.onChange(this.element.current.textContent);
  };

  /**
   * Returns true if the content is empty
   * @returns {boolean}
   */
  isEmpty = () => {
    return this.element.current.textContent === "";
  };

  handleKeyDown(e) {
    if (e.key === CMD_KEY) {
      // If the user starts to enter a command, we store a backup copy of
      // the html. We need this to restore a clean version of the content
      // after the content type selection was finished.
      this.setState({ htmlBackup: this.state.html });
    } else if (e.key === "Backspace" && this.isEmpty()) {
      this.props.onRemoveBlock();
    } else if (e.key === "Enter" && this.state.previousKey !== "Shift") {
      // If the user presses Enter, we want to add a new block
      // Only the Shift-Enter-combination should add a new paragraph,
      // i.e. Shift-Enter acts as the default enter behaviour
      e.preventDefault();
      this.props.onAddBlock();
    }
    // We need the previousKey to detect a Shift-Enter-combination
    this.setState({ previousKey: e.key });
  }

  render = () => {
    return (
      <ContentEditable
        innerRef={this.element}
        className={classNames(style.textBlock, this.props.className)}
        html={this.state.html}
        tagName={this.props.tag}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        placeholder="Type something"
      />
    );
  };
}

export default EditableText;
