//@ts-nocheck
import Pocket from "pocketjs";

const Store = new Pocket({
  dbname: "automify",
  driver: Pocket.Drivers.DEFAULT,
}).restore();

// Add global hook
if (process.env.NODE_ENV !== "production") {
  window.Store = Store;
}

export default Store;
