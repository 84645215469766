//@ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import YouTube from "react-youtube";
import classNames from "classnames";
import getYouTubeID from "get-youtube-id";
import { showMediaMenuFor } from "lib/actions/blocks";
import BlockTypes from "lib/constants/BlockTypes";
import Icon from "components/Icon";
import { Text } from "components/Typography";
import style from "../sheet.module.scss";

const YouTubeBlock = ({ block, onChange, onAddBlock, onRemoveBlock, className }) => {
  const dispatch = useDispatch();
  const videoID = getYouTubeID(block.properties.url);
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
  };

  const openMediaMenu = () => {
    const blockNode = document.getElementById(block.id);
    if (blockNode) {
      dispatch(showMediaMenuFor(blockNode));
    }
  };

  const handleOnReady = () => null;

  return (
    <div
      className={classNames(style.flex, style.youTubeBlock, {
        [style.contentPlaceholder]: !videoID,
      })}
      onClick={openMediaMenu}
    >
      {videoID ? (
        <YouTube videoId={videoID} opts={opts} onReady={handleOnReady} />
      ) : (
        <Text className={style.blockPlaceholder}>
          <Icon src="/images/icons/video_1.svg" className={style.blockPlaceholderIcon} />
          {BlockTypes.resolveItem(BlockTypes.YOUTUBE).description}
        </Text>
      )}
    </div>
  );
};

export default YouTubeBlock;
