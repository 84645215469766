//@ts-nocheck
import * as type from "./actionTypes";
import { receivePagedResult, requestFailure } from "./common";

export function requestManufacturers(params) {
  return {
    type: type.GET_CATALOGUE_MANUFACTURERS_REQUEST,
    payload: params,
  };
}
export function requestProducts(params) {
  return {
    type: type.GET_CATALOGUE_PRODUCTS_REQUEST,
    payload: params,
  };
}
export function requestCategories(params) {
  return {
    type: type.GET_MANUFACTURER_CATEGORIES_REQUEST,
    payload: params,
  };
}

export function requestManufacturersSuccess(items, paging) {
  return receivePagedResult(type.GET_CATALOGUE_MANUFACTURERS_RECEIVE, items, paging);
}
export function requestProductsSuccess(items, paging) {
  return receivePagedResult(type.GET_CATALOGUE_PRODUCTS_RECEIVE, items, paging);
}
export function requestCategoriesSuccess(items, paging) {
  return receivePagedResult(type.GET_MANUFACTURER_CATEGORIES_SUCCESS, items, paging);
}

export function requestManufacturersFailure(error) {
  return requestFailure(type.GET_CATALOGUE_MANUFACTURERS_FAILURE, error);
}
export function requestProductsFailure(error) {
  return requestFailure(type.GET_CATALOGUE_PRODUCTS_FAILURE, error);
}
export function requestCategoriesFailure(error) {
  return requestFailure(type.GET_MANUFACTURER_CATEGORIES_FAILURE, error);
}

export function requestManufacturer(manufacturerId) {
  return {
    type: type.GET_CATALOGUE_MANUFACTURER_REQUEST,
    payload: manufacturerId,
  };
}

export function requestManufacturerSuccess(item) {
  return {
    type: type.GET_CATALOGUE_MANUFACTURER_RECEIVE,
    payload: item,
  };
}

export function requestManufacturerFailure(error) {
  return requestFailure(type.GET_CATALOGUE_MANUFACTURER_FAILURE, error);
}

export function requestProduct(productId) {
  return {
    type: type.GET_CATALOGUE_PRODUCT_REQUEST,
    payload: productId,
  };
}

export function requestProductSuccess(item) {
  return {
    type: type.GET_CATALOGUE_PRODUCT_RECEIVE,
    payload: item,
  };
}

export function requestProductFailure(error) {
  return requestFailure(type.GET_CATALOGUE_PRODUCT_FAILURE, error);
}
