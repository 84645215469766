//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import StageHeader from "components/StageHeader";
import PageStage from "components/stages/PageStage";

const Invoices = ({ className }) => {
  const breadcrumb = [{ label: "Company", href: "/company" }, "Manage invoices"];
  return (
    <PageStage>
      <StageHeader title="Manage invoices" breadcrumb={breadcrumb} />
    </PageStage>
  );
};

Invoices.defaultProps = {};

Invoices.propTypes = {
  className: PropTypes.string,
};

export default Invoices;
