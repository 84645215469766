import { createSlice } from "@reduxjs/toolkit";
import {
  createCaseScanner,
  fetchCaseScanners,
  updateCaseScanner,
  deleteCaseScanner,
  fetchCaseScannerById,
} from "store/reducers/caseScanners/caseScanners.thunks";
import { TCaseScanner, UUID } from "types";

export type CaseScannersReducerStateData = Record<UUID, TCaseScanner>;

export interface CaseScannersReducerState {
  data: CaseScannersReducerStateData;
}

const initialState: CaseScannersReducerState = {
  data: {},
};

export const caseScannersSlice = createSlice({
  name: "caseScanners",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCaseScanners.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: CaseScannersReducerStateData, value: TCaseScanner) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchCaseScannerById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createCaseScanner.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateCaseScanner.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteCaseScanner.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const caseScannersReducer = caseScannersSlice.reducer;
