import { FormEvent, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createDoorway } from "store/reducers";
import { CreateDoorwayRequest, TDoorway, TLocation } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared/layouts";

type CreateDoorwayModalProps = {
  onSuccess?: (doorway: TDoorway) => void;
};

export const CreateDoorwayModal = ({ onSuccess }: CreateDoorwayModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateDoorwayRequest>({
    name: "",
    description: "",
  });
  const [submitting, isSubmitting] = useState<boolean>(false);
  const invalid = !values.name;

  const handleChange = (name: string, value: string | number | undefined) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      isSubmitting(true);
      const entity = await dispatch(createDoorway(values)).unwrap();
      closeModal();
      onSuccess && onSuccess(entity);
    } catch (err) {
      handlePopupFlagError(err);
    } finally {
      isSubmitting(false);
    }
  };

  const handleLocationChange = (name: string, value?: TLocation) => {
    setValues((prevState) => ({ ...prevState, [name]: value?.id }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader title="Create doorway" />
        <ModalContent>
          <VSpace>
            <FieldGroup label="Name">
              <TextInput
                input={{
                  name: "name",
                  placeholder: "Enter doorway name",
                  autoFocus: true,
                }}
                value={values.name}
                onChange={handleChange}
              />
            </FieldGroup>
            <FieldGroup label="Location">
              <LocationSelector name="locationId" value={values.locationId} onChange={handleLocationChange} />
            </FieldGroup>
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit" disabled={invalid || submitting}>
            Create doorway
          </PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
