import { ComponentProps } from "react";
import styled from "styled-components";
import Avatar from "components/Avatar";

type ProjectSheetCoverImageProps = Omit<ComponentProps<"div">, "onChange"> & {
  imageUrl: string;
  onChange?: (imageUrl?: string) => void;
};

export const ProjectSheetCoverImage = styled(({ imageUrl, onChange, className }: ProjectSheetCoverImageProps) => {
  return <Avatar canEdit image={imageUrl} onChange={(value) => onChange?.(value)} className={className} />;
})`
  height: 15rem;
  width: 100%;
  border-radius: 0.75rem;
`;
