import { Heading, ToolStoreUserOrdersGrid, VSpace } from "components/shared";
import { ToolStoresGrid } from "components/shared/features/toolStores/ToolStoresGrid/ToolStoresGrid";

export const SelectToolStorePage = () => {
  return (
    <VSpace className="pt-6">
      <Heading level="h2">Active orders</Heading>
      <div className="py-4">
        <ToolStoreUserOrdersGrid />
      </div>
      <Heading level="h2">Tool stores</Heading>
      <div className="py-4">
        <ToolStoresGrid />
      </div>
    </VSpace>
  );
};
