import { useEffect } from "react";
import { useToolStorage } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { openModal } from "lib/actions/modal";
import { requestAccessPolicy } from "lib/actions/storages";
import Modals from "lib/constants/Modals";
import { selectAccessPolicy } from "lib/reducers/storages.selectors";
import { useDispatch, useSelector } from "store";
import { selectStorageByID } from "store/reducers";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { PageLoading, StorageAccessMatrix } from "components/shared";

export const StorageAccessControlPage = () => {
  const dispatch = useDispatch();
  const { storageId } = useToolStorage();
  const storage = useSelector(selectStorageByID(storageId || ""));
  const accessPolicy = useSelector(selectAccessPolicy);

  useEffect(() => {
    if (storage?.accessPolicyId) {
      dispatch(requestAccessPolicy(storage.accessPolicyId));
    }
  }, [storage?.accessPolicyId]);

  const openCreateRuleModal = () => {
    dispatch(
      openModal(Modals.ACCESS_RULE_CREATE, {
        policy: accessPolicy,
      }),
    );
  };

  if (!storageId || !accessPolicy) {
    return <PageLoading />;
  }

  return (
    <>
      <StageHeader
        title="Manage access"
        action={
          <SecondaryButton onClick={openCreateRuleModal} icon="/images/icons/plus_1.svg">
            Grant access
          </SecondaryButton>
        }
      />
      <StorageAccessMatrix storageId={storageId} policy={accessPolicy} />
    </>
  );
};
