//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ButtonGroup } from "components/Buttons";
import IconButton from "components/IconButton";
import style from "./typography.module.scss";

const InlineControls = ({ onConfirm, onCancel, className }) => {
  return (
    <div className={classNames(style.inlineControls, className)}>
      <ButtonGroup className={classNames(style.inlineButtonGroup)}>
        <IconButton icon="/images/icons/tick_7.svg" onClick={onConfirm} className={style.inlineAction} />
        <IconButton icon="/images/icons/cross_2.svg" onClick={onCancel} className={style.inlineAction} />
      </ButtonGroup>
    </div>
  );
};

InlineControls.defaultProps = {};

InlineControls.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  className: PropTypes.string,
};

export default InlineControls;
