//@ts-nocheck
import React from "react";
import AccountMessages from "./AccountMessages";

class AccountMessagesContainer extends React.Component {
  static propTypes = {};

  state = {};

  render() {
    return <AccountMessages />;
  }
}

export default AccountMessagesContainer;
