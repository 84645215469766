import styled from "styled-components";
import { useSelector } from "store";
import { selectLocationByID } from "store/reducers";
import { UUID } from "types";

const PostcodeImage = styled.img`
  max-width: 300px;
  width: 100%;
  height: auto;
`;

type LocationPostcodeMapProps = {
  locationId: UUID;
  accessToken?: string;
  mapStyle?: string;
};

export const LocationPostcodeMap = ({
  locationId,
  accessToken = process.env.REACT_APP_MAPBOX_ID,
  mapStyle = "automify/cldwwcisd003901pas6iutz3p",
}: LocationPostcodeMapProps) => {
  const entity = useSelector(selectLocationByID(locationId));
  if (!(entity.lat && entity.lng)) {
    return null;
  }
  return (
    <PostcodeImage
      className="rounded-xl"
      src={`https://api.mapbox.com/styles/v1/${mapStyle}/static/${entity.lng},${entity.lat},11,0/300x200?access_token=${accessToken}`}
    />
  );
};
