import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { handleThunkError, ThunkError } from "store/utils/thunk";
import {
  BeginAssetRFIDScanRequest,
  BeginAssetRFIDScanResponse,
  CreateStorageRequest,
  DataResponse,
  GetDeviceSoftwareVersionRequest,
  GetDeviceSoftwareVersionResponse,
  LockDeviceRequest,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  PowerOffDeviceRequest,
  RebootDeviceRequest,
  RefreshDeviceScreenRequest,
  StorageType,
  TStorage,
  UnlockDeviceRequest,
  UpdateDeviceSoftwareVersionRequest,
  UpdateDeviceSoftwareVersionResponse,
  UpdateStorageRequest,
  UUID,
} from "types";

type FetchStoragesParams = PaginationQueryParams & {
  query?: string;
  includeHeartbeat?: boolean;
  type?: StorageType;
};

export const fetchStorages = createAsyncThunk(
  "storages/fetchStorages",
  async (params: FetchStoragesParams): Promise<{ results: TStorage[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TStorage[]>>("/storages", params);
    return { results: data, paging: metadata };
  },
);

export const fetchStorageById = createAsyncThunk("storages/fetchStorageById", async (id: string): Promise<TStorage> => {
  const { data } = await API.GET<DataResponse<TStorage>>(`/storages/${id}`);
  return data;
});

export const createStorage = createAsyncThunk(
  "storages/createStorage",
  async (request: CreateStorageRequest): Promise<TStorage> => {
    const { data } = await API.POST<CreateStorageRequest, DataResponse<TStorage>>("/storages", request);
    return data;
  },
);

export const getDeviceSoftwareVersion = createAsyncThunk(
  "storages/remoteControl/getDeviceSoftwareVersion",
  async (request: GetDeviceSoftwareVersionRequest): Promise<GetDeviceSoftwareVersionResponse["data"]> => {
    const { data } = await API.POST<GetDeviceSoftwareVersionRequest, GetDeviceSoftwareVersionResponse>(
      `/storages/${request.storageId}/remote_controls/runtime_version`,
      request,
    );
    return data;
  },
);

export const updateDeviceSoftwareVersion = createAsyncThunk(
  "storages/remoteControl/updateSoftwareVersion",
  async (request: UpdateDeviceSoftwareVersionRequest): Promise<UpdateDeviceSoftwareVersionResponse["data"]> => {
    const { data } = await API.POST<UpdateDeviceSoftwareVersionRequest, UpdateDeviceSoftwareVersionResponse>(
      `/storages/${request.storageId}/remote_controls/update_software`,
      request,
    );
    return data;
  },
);

export const refreshDeviceScreen = createAsyncThunk<void, RefreshDeviceScreenRequest, ThunkError>(
  "storages/remoteControl/refreshDeviceScreen",
  async (request, { rejectWithValue }) => {
    try {
      await API.POST(`/storages/${request.storageId}/remote_controls/refresh_screen`, request);
    } catch (err) {
      return handleThunkError(rejectWithValue, err);
    }
  },
);

export const rebootDevice = createAsyncThunk(
  "storages/remoteControl/rebootDevice",
  async (request: RebootDeviceRequest): Promise<void> => {
    await API.POST<RebootDeviceRequest, void>(`/storages/${request.storageId}/remote_controls/reboot_device`, request);
  },
);

export const powerOffDevice = createAsyncThunk(
  "storages/remoteControl/powerOffDevice",
  async (request: PowerOffDeviceRequest): Promise<void> => {
    await API.POST<PowerOffDeviceRequest, void>(
      `/storages/${request.storageId}/remote_controls/power_off_device`,
      request,
    );
  },
);

export const lockDevice = createAsyncThunk(
  "storages/remoteControl/lockDevice",
  async (request: LockDeviceRequest): Promise<void> => {
    await API.POST<LockDeviceRequest, void>(`/storages/${request.storageId}/remote_controls/lock_device`, request);
  },
);

export const unlockDevice = createAsyncThunk(
  "storages/remoteControl/unlockDevice",
  async (request: UnlockDeviceRequest): Promise<void> => {
    await API.POST<UnlockDeviceRequest, void>(`/storages/${request.storageId}/remote_controls/unlock_device`, request);
  },
);

export const runDeviceRFIDTagsScan = createAsyncThunk<
  BeginAssetRFIDScanResponse["data"],
  BeginAssetRFIDScanRequest,
  ThunkError
>("storages/remoteControl/runDeviceRFIDTagsScan", async (request, { rejectWithValue }) => {
  try {
    const { data } = await API.POST(`/storages/${request.storageId}/remote_controls/scan_rfid_tags`, request);
    return data;
  } catch (err) {
    return handleThunkError(rejectWithValue, err);
  }
});

export const createStorageViewer = createAsyncThunk(
  "storages/createStorageViewer",
  async (id: string): Promise<void> => {
    await API.POST<string, void>(`/storages/${id}/viewers`);
  },
);

export const updateStorage = createAsyncThunk(
  "storages/updateStorage",
  async (request: UpdateStorageRequest): Promise<TStorage> => {
    const { data } = await API.PUT<UpdateStorageRequest, DataResponse<TStorage>>(`/storages/${request.id}`, request);
    return data;
  },
);

export const deleteStorage = createAsyncThunk("storages/deleteStorage", async (id: UUID): Promise<void> => {
  await API.DELETE(`/storages/${id}`);
});

export const unlinkStorage = createAsyncThunk("storages/unlinkStorage", async (id: string): Promise<void> => {
  await API.POST<string, void>(`/storages/${id}/unlink`);
});
