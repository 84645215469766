//@ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { pageBlocksChange, showMediaMenuFor } from "lib/actions/blocks";
import BlockAPI from "lib/api/blocks";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getPageBlocks } from "lib/reducers/blocks.selectors";
import { PrimaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import { updateBlock } from "components/Sheet/utils";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import style from "../sheet.module.scss";

const WebLinkBlockMediaMenu = ({ block, className }) => {
  const dispatch = useDispatch();
  const pageBlocks = useSelector(getPageBlocks);
  const [state, setState] = useState(block.properties);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const updatedBlock = {
        ...block,
        properties: {
          ...state,
        },
      };
      dispatch(pageBlocksChange(updateBlock(pageBlocks, updatedBlock)));
      dispatch(showMediaMenuFor(null));
      await BlockAPI.updateBlock(block.id, updatedBlock);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleChange = (name, value) => setState({ ...state, [name]: value });

  return (
    <form onSubmit={handleSubmit} className={style.youTubeMediaMenu}>
      <FieldGroup label="Website link" className={style.mediaMenuFormField}>
        <TextInput
          input={{
            name: "url",
            placeholder: "Paste the website link...",
          }}
          value={state.url}
          onChange={handleChange}
        />
      </FieldGroup>
      <PrimaryButton onClick={handleSubmit}>Embed website link</PrimaryButton>
    </form>
  );
};

WebLinkBlockMediaMenu.defaultProps = {};

WebLinkBlockMediaMenu.propTypes = {
  className: PropTypes.string,
};

export default WebLinkBlockMediaMenu;
