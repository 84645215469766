//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  popupFlags: [],
  modal: null,
  keyboard: null,
  sidebar: false,
  tooltip: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SHOW_POPUP_FLAG:
      return {
        ...state,
        popupFlags: [action.payload, ...state.popupFlags],
      };
    case type.DISMISS_POPUP_FLAG:
      return {
        ...state,
        popupFlags: state.popupFlags.filter(({ id }) => id !== action.payload),
      };
    case type.MODAL_OPEN:
      return {
        ...state,
        modal: action.payload,
      };
    case type.MODAL_CLOSE:
      return {
        ...state,
        modal: null,
      };
    case type.KEYBOARD_OPEN:
      return {
        ...state,
        keyboard: action.payload,
      };
    case type.KEYBOARD_CLOSE:
      return {
        ...state,
        keyboard: null,
      };
    case type.SET_PAGE_SIDEBAR:
      return {
        ...state,
        sidebar: action.payload,
      };
    case type.TOOLTIP_OPEN:
      return {
        ...state,
        tooltip: action.payload,
      };
    case type.TOOLTIP_CLOSE:
      return {
        ...state,
        tooltip: null,
      };
    default:
      return state;
  }
};
