import { ExternalLinkIcon } from "@heroicons/react/outline";
import Resolve from "lib/Resolve";
import { useSelector } from "store";
import { selectToolStoreOrderByID } from "store/reducers";
import { UUID } from "types";
import Loading from "components/Loading";
import Anchor from "components/Typography/Anchor";

type ToolStoreOrderRequesterProps = {
  toolStoreOrderId: UUID;
};

export const ToolStoreOrderRequester = ({ toolStoreOrderId }: ToolStoreOrderRequesterProps) => {
  const entity = useSelector(selectToolStoreOrderByID(toolStoreOrderId));

  if (!entity) {
    return <Loading />;
  }
  return (
    <div>
      <dt className="font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Ordered by</dt>
      <dd className="flex mt-1 text-gray-900 sm:col-span-2">
        {Resolve.resolveUserFullName(entity.createdByUserId)}
        <Anchor to="/configuration/users" target="_blank">
          <ExternalLinkIcon className="w-5 ml-2" />
        </Anchor>
      </dd>
    </div>
  );
};
