import { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "store";
import { fetchToolStoreQueueSizeById } from "store/reducers";
import { UUID } from "types";
import Loading from "components/Loading";
import { Heading } from "components/shared/typography";

const Bubble = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding: 0 1rem;
  background: white;
  box-shadow: 0 4px 14px -4px rgb(0 0 0 / 8%);
  min-height: 3.5rem;
`;

const ValueHeading = styled(Heading)`
  font-size: 16px;
  line-height: 1em;
`;

type ToolStoreWaitTimeBubbleProps = {
  toolStoreId: UUID;
};

export const ToolStoreWaitTimeBubble = ({ toolStoreId }: ToolStoreWaitTimeBubbleProps) => {
  const dispatch = useDispatch();
  const [queueSize, setQueueSize] = useState<number>();

  useEffect(() => {
    fetchQueueSize();
  }, []);

  const fetchQueueSize = async () => {
    try {
      const value = await dispatch(fetchToolStoreQueueSizeById(toolStoreId)).unwrap();
      setQueueSize(value);
    } catch (err) {
      // NOOP
    }
  };

  if (queueSize === undefined) {
    return (
      <Bubble>
        <Loading />
      </Bubble>
    );
  }

  return (
    <Bubble>
      <ValueHeading level="h3">{queueSize || "None"}</ValueHeading>
      <span className="text-sm">Orders in queue</span>
    </Bubble>
  );
};
