//@ts-nocheck
import cuid from "cuid";
import * as type from "./actionTypes";

export function showPopupFlag(flag, timeout = 5000) {
  return {
    type: type.SHOW_POPUP_FLAG,
    timeout,
    payload: {
      ...flag,
      id: flag.id || cuid(),
    },
  };
}

export function dismissPopupFlag(flagID) {
  return {
    type: type.DISMISS_POPUP_FLAG,
    payload: flagID,
  };
}

/**
 * Open the keyboard
 * @param {Object} options
 * @returns {{type: string, payload: Object}}
 */
export function openKeyboard(options) {
  return {
    type: type.KEYBOARD_OPEN,
    payload: {
      onChange: options.onChange,
      initialValue: options.initialValue,
      inputOffsetTop: options.inputOffsetTop,
      screenHeightOffset: options.screenHeightOffset,
      keyboard: options.keyboard || "default",
    },
  };
}

/**
 * Close the keyboard
 * @returns {{type: string}}
 */
export function closeKeyboard() {
  return {
    type: type.KEYBOARD_CLOSE,
  };
}

/**
 * Set the page sidebar
 * @param {boolean} isVisible
 * @returns {{type: string}}
 */
export function setPageSidebar(isVisible = false) {
  return {
    type: type.SET_PAGE_SIDEBAR,
    payload: isVisible,
  };
}

/**
 * Open a tooltip
 * @param {any} component
 * @param {Object} [props={}]
 * @returns {{type: string, name: string, props: Object}}
 */
export function openTooltip(component, props = {}) {
  return {
    type: type.TOOLTIP_OPEN,
    payload: {
      component,
      props,
    },
  };
}

/**
 * Close an active tooltip
 * @returns {{type: string}}
 */
export function closeTooltip() {
  return {
    type: type.TOOLTIP_CLOSE,
  };
}
