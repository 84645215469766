import React from "react";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectToolStoreByID, updateToolStore } from "store/reducers";
import { TLocation, UUID } from "types";
import Avatar from "components/Avatar";
import { DataGrid, DataItem } from "components/DataGrid";
import { LocationSelector } from "components/inputs/LocationSelector/LocationSelector";

type ToolStoreSettingsProps = {
  toolStoreId: UUID;
};

export const ToolStoreSettings = ({ toolStoreId }: ToolStoreSettingsProps) => {
  const dispatch = useDispatch();
  const entity = useSelector(selectToolStoreByID(toolStoreId));

  if (!entity) {
    return null;
  }

  const updateImage = async (imageUrl: string) => {
    try {
      await dispatch(
        updateToolStore({
          ...entity,
          imageUrl,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Image changed",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const updateLocation = async (_: string, value: TLocation) => {
    try {
      await dispatch(
        updateToolStore({
          ...entity,
          storage: {
            ...entity.storage,
            locationId: value.id,
          },
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Location changed",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    <DataGrid>
      <DataItem label="Store image" value={<Avatar canEdit image={entity.imageUrl} onChange={updateImage} />} />
      <DataItem
        label="Location"
        value={
          <LocationSelector
            appearance="normal"
            name="locationId"
            value={entity.storage.locationId}
            onChange={updateLocation}
            types={["building", "room"]}
          />
        }
      />
    </DataGrid>
  );
};
