import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import classNames from "classnames";
import Api from "lib/api/company";
import { FixLater } from "types";
import NoticeBoard from "components/layouts/AuthLayout/NoticeBoard";
import { PageLoading } from "components/shared";
import MarketingWorkspaceAvailable from "pages/marketing/components/MarketingWorkspaceAvailable";
import style from "./layout.module.scss";

const AuthLayout = ({ useBranding = true }) => {
  const [fetching, setFetching] = useState(true);
  const [branding, setBranding] = useState<FixLater>(null);
  const defaultAutomifyLogoUrl = "/images/automify_logo_no_border.png";

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await Api.getPublicBrandingSettings();
        setBranding(data);
      } catch (err) {}
      setFetching(false);
    };
    useBranding && fetch();
  }, [useBranding]);

  return (
    <main className={classNames(style.container)}>
      <Helmet>
        {branding?.companyName ? (
          <title>{branding.companyName} - Login into Automify</title>
        ) : (
          <title>Automify - Login</title>
        )}
        {branding?.companyFavIconUrl ? (
          <link rel="icon" href={branding.companyFavIconUrl} />
        ) : (
          <link rel="icon" type="image/png" href="/images/target.png" />
        )}
      </Helmet>

      <NoticeBoard />
      {fetching && useBranding ? (
        <div className={style.pageContainer}>
          <div className={style.page}>
            <PageLoading />
          </div>
        </div>
      ) : useBranding && branding ? (
        <div className={style.pageContainer}>
          <div className={style.page}>
            <div className={style.companyLogoContainer}>
              <a href="/">
                <img
                  src={branding.companyLogoUrl || defaultAutomifyLogoUrl}
                  alt={branding.companyName || "Automify"}
                  className={style.companyLogo}
                />
              </a>
            </div>
            <div className={classNames(style.content)}>
              <Outlet />
            </div>
          </div>
        </div>
      ) : (
        <div className={style.pageContainer}>
          <div className={style.page}>
            <div className={style.companyLogoContainer}>
              <a href="https://www.automify.co.uk">
                <img src={defaultAutomifyLogoUrl} alt="Automify" className={style.companyLogo} />
              </a>
            </div>
            <div className={classNames(style.content)}>
              {useBranding ? <MarketingWorkspaceAvailable /> : <Outlet />}
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default AuthLayout;
