//@ts-nocheck
import Block from "lib/blocks/Block";

export default class YouTubeBlockProperties extends Block {
  /**
   * @type {string}
   */
  url;

  constructor(url = "") {
    super();
    this.url = url;
  }
}
