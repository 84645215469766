//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  data: {},
  fetching: "",
  error: null,
  paging: {
    pageStart: 0,
    pageLimit: 10,
    totalCount: 0,
    nextPageStart: "",
    previousPageStart: "",
  },
};

export default (state = initialState, action) => {
  let next, alert;
  switch (action.type) {
    case type.GET_ALERTS_REQUEST:
    case type.GET_ALERT_REQUEST:
    case type.CREATE_ALERT_REQUEST:
    case type.UPDATE_ALERT_REQUEST:
    case type.DELETE_ALERT_REQUEST:
    case type.CREATE_ALERT_RULE_REQUEST:
    case type.DELETE_ALERT_RULE_REQUEST:
    case type.CREATE_ALERT_RECIPIENT_REQUEST:
    case type.UPDATE_ALERT_RECIPIENT_REQUEST:
    case type.DELETE_ALERT_RECIPIENT_REQUEST:
      return {
        ...state,
        fetching: action.type,
      };
    case type.GET_ALERTS_REJECT:
    case type.GET_ALERT_REJECT:
    case type.CREATE_ALERT_REJECT:
    case type.UPDATE_ALERT_REJECT:
    case type.CREATE_ALERT_RULE_REJECT:
    case type.DELETE_ALERT_RULE_REJECT:
    case type.CREATE_ALERT_RECIPIENT_REJECT:
    case type.UPDATE_ALERT_RECIPIENT_REJECT:
    case type.DELETE_ALERT_RECIPIENT_REJECT:
      return {
        ...state,
        fetching: "",
        error: action.payload,
      };
    case type.GET_ALERTS_FULFIL:
      return {
        ...state,
        fetching: "",
        data: action.payload.reduce(
          (data, item) => ({
            ...data,
            [item.id]: {
              ...item,
              rules: item.rules.reduce((data, item) => ({ ...data, [item.id]: item }), {}),
              recipients: item.recipients.reduce((data, item) => ({ ...data, [item.id]: item }), {}),
            },
          }),
          state.data,
        ),
      };
    case type.GET_ALERT_FULFIL:
    case type.CREATE_ALERT_FULFIL:
    case type.UPDATE_ALERT_FULFIL:
      return {
        ...state,
        fetching: "",
        data: {
          ...state.data,
          [action.payload.id]: {
            ...action.payload,
            rules: action.payload.rules.reduce((data, item) => ({ ...data, [item.id]: item }), {}),
            recipients: action.payload.recipients.reduce((data, item) => ({ ...data, [item.id]: item }), {}),
          },
        },
      };
    case type.DELETE_ALERT_FULFIL:
      next = { ...state.data };
      delete next[action.payload];
      return {
        ...state,
        fetching: "",
        data: next,
      };
    case type.CREATE_ALERT_RULE_FULFIL:
      alert = state.data[action.payload.alertId];
      return {
        ...state,
        fetching: "",
        data: {
          ...state.data,
          [alert.id]: {
            ...alert,
            rules: {
              ...alert.rules,
              [action.payload.id]: action.payload,
            },
          },
        },
      };
    case type.CREATE_ALERT_RECIPIENT_FULFIL:
    case type.UPDATE_ALERT_RECIPIENT_FULFIL:
      alert = state.data[action.payload.alertId];
      return {
        ...state,
        fetching: "",
        data: {
          ...state.data,
          [alert.id]: {
            ...alert,
            recipients: {
              ...alert.recipients,
              [action.payload.id]: action.payload,
            },
          },
        },
      };
    case type.DELETE_ALERT_RULE_FULFIL:
      alert = { ...state.data[action.payload.alertId] };
      delete alert.rules[action.payload.id];
      return {
        ...state,
        fetching: "",
        [alert.id]: { ...alert },
      };
    case type.DELETE_ALERT_RECIPIENT_FULFIL:
      alert = { ...state.data[action.payload.alertId] };
      delete alert.recipients[action.payload.id];
      return {
        ...state,
        fetching: "",
        [alert.id]: { ...alert },
      };
    default:
      return state;
  }
};
