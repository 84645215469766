import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateUserRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TUser,
  UpdateUserRequest,
  UUID,
} from "types";

type FetchUsersParams = PaginationQueryParams & {};

export const fetchUsers = createAsyncThunk(
  "users/list",
  async (params: FetchUsersParams): Promise<{ results: TUser[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TUser[]>>("/users", params);
    return { results: data, paging: metadata };
  },
);

export const fetchUserById = createAsyncThunk("users/getById", async (id: UUID): Promise<TUser> => {
  const { data } = await API.GET<DataResponse<TUser>>(`/users/${id}`);
  return data;
});

export const createUser = createAsyncThunk("users/create", async (request: CreateUserRequest): Promise<TUser> => {
  const { data } = await API.POST<CreateUserRequest, DataResponse<TUser>>("/users", request);
  return data;
});

export const updateUser = createAsyncThunk("users/update", async (request: UpdateUserRequest): Promise<TUser> => {
  const { data } = await API.PUT<UpdateUserRequest, DataResponse<TUser>>(`/users/${request.id}`, request);
  return data;
});

export const deleteUser = createAsyncThunk("users/delete", async (id: number): Promise<void> => {
  await API.DELETE(`/users/${id}`);
});
