import { useEffect } from "react";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "store";
import { fetchLocationById, selectLocationByID, selectToolStoreOrderByID } from "store/reducers";
import { UUID } from "types";
import Loading from "components/Loading";
import Anchor from "components/Typography/Anchor";

type ToolStoreOrderLocationProps = {
  toolStoreOrderId: UUID;
};

export const ToolStoreOrderLocation = ({ toolStoreOrderId }: ToolStoreOrderLocationProps) => {
  const dispatch = useDispatch();
  const entity = useSelector(selectToolStoreOrderByID(toolStoreOrderId));
  const dropOffLocation = useSelector(selectLocationByID(entity?.dropOffLocationId || ""));

  useEffect(() => {
    if (entity && entity.dropOffLocationId && !dropOffLocation) {
      dispatch(fetchLocationById(entity.dropOffLocationId));
    }
  }, [entity, dropOffLocation]);

  if (!entity || !dropOffLocation) {
    return <Loading />;
  }
  return (
    <div>
      <dt className="font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Drop-off location</dt>
      <dd className="flex mt-1 text-gray-900 sm:col-span-2">
        {dropOffLocation.name}
        <Anchor to={`/configuration/locations/${dropOffLocation.id}`} target="_blank">
          <ExternalLinkIcon className="w-5 ml-2" />
        </Anchor>
      </dd>
    </div>
  );
};
