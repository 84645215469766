import React from "react";
import "react-dates/initialize";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "css/styles.scss";
import Account from "lib/Account";
import Resolve from "lib/Resolve";
import App from "./App";
import "./lib/utility/chaport";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_SENTRY_ID) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ID,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
  Sentry.configureScope((scope) => {
    scope.setTag("portal", process.env.REACT_APP_PORTAL || "unknown");
    scope.setTag("brand", Resolve.brand().id || "unknown");
  });
}

// Enable logging
localStorage.setItem("debug", "error,warning,info,debug,trace");

const session = Account.session();
if (session) {
  if (Account.sessionExpiry() <= Date.now()) {
    Account.removeSession();
  }
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
