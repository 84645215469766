//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import Logger from "../Logger";
import * as type from "../actions/actionTypes";
import * as actions from "../actions/statistics";
import Api from "../api/statistics";

function* fetchStatistics(action) {
  try {
    const { data } = yield Api.getStatistics(action.payload);
    yield put(actions.receiveStatistics(data));
  } catch (err) {
    yield put(actions.requestStatisticsFailure(err));
  }
}

function* fetchMetrics(action) {
  try {
    const { data, metadata } = yield Api.getMetrics(action.payload);
    yield put(actions.receiveMetrics(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestMetricsFailure(err));
  }
}

function* fetchStoragesMetrics(action) {
  try {
    const data = yield Api.getStoragesMetrics(action.payload);
    yield put(actions.receiveStoragesMetrics(data));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestStoragesMetricsFailure(err));
  }
}

function* fetchAssetsMetrics(action) {
  try {
    const data = yield Api.getAssetsMetrics(action.payload);
    yield put(actions.receiveAssetsMetrics(data));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestAssetsMetricsFailure(err));
  }
}

function* statisticsSaga() {
  yield takeLatest(type.REQUEST_STATISTICS, fetchStatistics);
  yield takeLatest(type.GET_METRICS_REQUEST, fetchMetrics);
  yield takeLatest(type.GET_STORAGES_METRICS_REQUEST, fetchStoragesMetrics);
  yield takeLatest(type.GET_ASSETS_METRICS_REQUEST, fetchAssetsMetrics);
}

export default statisticsSaga;
