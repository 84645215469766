import { formatFullName } from "lib/utility/format";
import { useSelector } from "store";
import { selectAssetIssueByID, selectUserByID } from "store/reducers";
import { UUID } from "types";
import Avatar from "components/Avatar";
import Popover from "components/Popover";
import { TextBody } from "components/shared/typography";

type AssetIssueReporterProps = {
  assetIssueId: UUID;
};

export const AssetIssueReporter = ({ assetIssueId }: AssetIssueReporterProps) => {
  const entity = useSelector(selectAssetIssueByID(assetIssueId));
  const user = useSelector(selectUserByID(entity?.createdByUserId || 0));
  if (!user) return null;
  return (
    <Popover
      content={
        <TextBody textColor="text-white">
          {formatFullName({
            forename: user.forename,
            surname: user.surname,
            email: user.email,
          })}
        </TextBody>
      }
    >
      <Avatar image={user.profileImage} alt={user.email.toUpperCase().slice(0, 2)} size="s" />
    </Popover>
  );
};
