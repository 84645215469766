//@ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { getBranding } from "lib/reducers/lookupData.selectors";
import { useSelector } from "store";
import { FixLater } from "types";
import style from "./navbar.module.scss";

const PlatformLogo = ({ to = "/dashboard", collapsed, image, className }: FixLater) => {
  const branding = useSelector(getBranding);
  if (!image) {
    image = collapsed ? "/images/target.png" : "/images/automify_logo_no_border.png";
    if (branding && (collapsed ? branding.portalIcon : branding.portalLogo)) {
      image = collapsed ? branding.portalIcon : branding.portalLogo;
    }
  }
  return (
    <Link to={to} className={style.companyLogoAnchor}>
      <img src={image} alt="Automify" className={classNames(style.companyLogo, className)} />
    </Link>
  );
};

export default PlatformLogo;
