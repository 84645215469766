//@ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { updateStoreItem } from "lib/actions/marketplace";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { DataGrid, DataItem } from "components/DataGrid";
import StageHeader from "components/StageHeader";
import StageSection from "components/StageSection";
import { Header, Paragraph, Text } from "components/Typography";
import ShopItemDocuments from "pages/apps/Shop/shared/ShopItemDocuments/ShopItemDocuments";
import ShopItemGallery from "pages/apps/Shop/shared/ShopItemGallery/ShopItemGallery";
import { HalfHalf } from "pages/marketing/components/Common";
import styles from "./ShopItemDashboardPage.module.scss";

const ShopItemDashboardPage = ({ storeItem }) => {
  const dispatch = useDispatch();
  const breadcrumb = [{ label: "Products", href: "/apps/shop_manager" }, storeItem.name];

  const handleConfirm = (name, value) => {
    dispatch(
      updateStoreItem(
        {
          ...storeItem,
          [name]: value,
        },
        () => {
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Store item updated",
            }),
          );
        },
        (err) => {
          handlePopupFlagError(err);
        },
      ),
    );
  };

  return (
    <>
      <StageHeader breadcrumb={breadcrumb} className={styles.StageHeader} />
      <StageSection isFirst={true}>
        <HalfHalf>
          <div>
            <ShopItemGallery editable id={storeItem.id} />
          </div>
          <div>
            <Header canEdit value={storeItem.name} onConfirm={(value) => handleConfirm("name", value)} />
            <DataGrid>
              <DataItem label="Summary">
                <Text
                  canEdit
                  value={storeItem.summary}
                  placeholder="Enter summary"
                  onConfirm={(value) => handleConfirm("summary", value)}
                />
              </DataItem>
              <DataItem label="Description">
                <Paragraph
                  canEdit
                  size="large"
                  value={storeItem.description}
                  placeholder="Enter description"
                  onConfirm={(value) => handleConfirm("description", value)}
                />
              </DataItem>
              <DataItem label="SKU">
                <Text
                  canEdit
                  value={storeItem.sku}
                  placeholder="Enter sku"
                  onConfirm={(value) => handleConfirm("sku", value)}
                />
              </DataItem>
              <DataItem label="Price (Excl VAT)">
                <Text
                  canEdit
                  value={storeItem.priceExcVat}
                  placeholder="Enter price excl VAT"
                  input={{
                    type: "number",
                    min: 0,
                    max: 100,
                    step: 0.01,
                  }}
                  onConfirm={(value) => handleConfirm("priceExcVat", value)}
                />
              </DataItem>
              <DataItem label="Discount (0 to 100%)">
                <Text
                  canEdit
                  value={storeItem.discount}
                  placeholder="Enter discount"
                  input={{
                    type: "number",
                    min: 0,
                    max: 100,
                    step: 0.01,
                  }}
                  onConfirm={(value) => handleConfirm("discount", value)}
                />
              </DataItem>
              <DataItem label="Stock quantity">
                <Text
                  canEdit
                  value={storeItem.quantity}
                  placeholder="Enter stock quantity"
                  input={{ type: "number", min: 0, step: 1 }}
                  onConfirm={(value) => handleConfirm("quantity", value)}
                />
              </DataItem>
            </DataGrid>
            <ShopItemDocuments editable id={storeItem.id} />
          </div>
        </HalfHalf>
      </StageSection>
    </>
  );
};

ShopItemDashboardPage.defaultProps = {};

ShopItemDashboardPage.propTypes = {
  storeItem: PropTypes.object,
  className: PropTypes.string,
};

export default ShopItemDashboardPage;
