import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TAlert, UUID } from "types";

const selectState = (state: RootStore) => state.alertsReducer;

export const selectAlerts = createSelector(selectState, (state) => state.data);

export const selectAlertsList = createSelector(selectAlerts, (data) => Object.values(data));

export const selectAlertsPage = createSelector(selectState, ({ page, data }) => {
  return page.map((id) => data[id]).filter(Boolean);
});

export const selectAlertByID = (id: UUID) => createSelector(selectAlerts, (data): TAlert | undefined => data[id]);
