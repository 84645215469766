import React from "react";
import { useSelector } from "store";
import { selectAssetByGUID } from "store/reducers";
import { UUID } from "types";
import { CalibrationConfigureForm } from "components/shared/features/calibration/CalibrationConfigureModal/CalibrationConfigureForm";

type CalibrationConfigureProps = {
  assetId: UUID;
  onSuccess: () => void;
};

export const CalibrationConfigureModal = ({ assetId, onSuccess }: CalibrationConfigureProps) => {
  const asset = useSelector(selectAssetByGUID(assetId));

  if (!asset) return null;
  return <CalibrationConfigureForm asset={asset} onSuccess={onSuccess} />;
};
