//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import SelectCalibrationTrackingMethod from "components/inputs/SelectCalibrationTrackingMethod";
import style from "../textEditorDropdown.module.scss";

const CalibrationTrackingMethod = ({ filter, className, onChange }) => {
  return (
    <FieldGroup label={filter.name} className={style.contentInputGroup}>
      <SelectCalibrationTrackingMethod
        name={filter.type}
        value={filter.value}
        onChange={onChange}
        className={style.contentInput}
      />
    </FieldGroup>
  );
};

CalibrationTrackingMethod.defaultProps = {};

CalibrationTrackingMethod.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default CalibrationTrackingMethod;
