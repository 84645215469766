import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import {
  deleteLocation,
  fetchLocationById,
  fetchPostcodeGeoLocation,
  selectLocationByID,
  updateLocation,
} from "store/reducers";
import { TLocation } from "types";
import { DangerButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import { Header, Text } from "components/Typography";
import { LocationSelector } from "components/inputs";
import { LocationPostcodeMap, LocationTypeSelector, PageLoading } from "components/shared";
import { ConfirmModal } from "components/shared/generic/modals/ConfirmModal/ConfirmModal";
import PageStage from "components/stages/PageStage";

export const LocationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { id } = useParams();
  const entity = useSelector(selectLocationByID(id || ""));

  useEffect(() => {
    if (!entity && id) {
      dispatch(fetchLocationById(id));
    }
  }, [entity]);

  if (!entity) {
    return <PageLoading />;
  }

  const saveChanges = (name: string) => {
    return async (value: string | null) => {
      try {
        const newLocation = {
          ...entity,
          [name]: value,
        };

        // Resolve a postcode to coordinates
        if (name === "postcode" && value) {
          try {
            const place = await dispatch(
              fetchPostcodeGeoLocation({
                accessToken: process.env.REACT_APP_MAPBOX_ID || "",
                postcode: value,
              }),
            ).unwrap();
            if (place) {
              newLocation.lng = place.center[0];
              newLocation.lat = place.center[1];
            }
          } catch (err) {
            // NOOP
          }
        }

        await dispatch(updateLocation(newLocation)).unwrap();
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Location updated",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  const handleLocationChange = (name: string, value?: TLocation) => {
    saveChanges(name)(value?.id || null);
  };

  const handleRemove = async () => {
    if (!id) return;
    openModal(
      <ConfirmModal
        actionColor="warning"
        actionText="Delete location"
        onConfirm={async () => {
          try {
            await dispatch(deleteLocation(id)).unwrap();
            navigate("/configuration/locations", { replace: true });
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Location removed",
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
        }}
      />,
    );
  };

  return (
    <PageStage>
      <Header canEdit placeholder="Enter location name" value={entity.name} onConfirm={saveChanges("name")} />
      <DataGrid>
        <DataItem
          label="Type"
          value={
            <LocationTypeSelector appearance="inline" name="type" value={entity.type} onChange={saveChanges("type")} />
          }
        />
        {(entity.type !== "building" || entity.parentLocationId) && (
          <DataItem
            label="Parent location"
            value={
              <LocationSelector
                searchable
                appearance="inline"
                name="parentLocationId"
                value={entity.parentLocationId}
                onChange={handleLocationChange}
              />
            }
          />
        )}
        {entity.type === "building" && (
          <DataItem
            label="Postcode"
            value={<Text canEdit value={entity.postcode} onConfirm={saveChanges("postcode")} />}
          />
        )}
        <DataItem label="" value={<LocationPostcodeMap locationId={entity.id} />} />
        <DataItem label="Remove location" value={<DangerButton onClick={handleRemove}>Delete</DangerButton>} />
      </DataGrid>
    </PageStage>
  );
};
