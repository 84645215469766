//@ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import { showPopupFlag } from "lib/actions/userInterface";
import ProjectAPI from "lib/api/projects";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import store from "store";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextAreaInput, TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared";
import style from "./RootModal/modal.module.scss";

const CreateProjectTemplateModal = ({ modal, onClose }) => {
  const { project, onSuccess } = modal.props;
  const [values, setValues] = useState({
    name: "",
    description: "",
    projectId: project.id,
  });
  const [submitting, isSubmitting] = useState(false);

  const handleChange = (name, value) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    isSubmitting(true);
    try {
      event.preventDefault();
      const projectTemplate = await ProjectAPI.createProjectTemplate({
        ...values,
      });
      store.dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Created Project Template",
        }),
      );
      onClose();
      onSuccess && onSuccess(projectTemplate);
    } catch (err) {
      handlePopupFlagError(err);
    }
    isSubmitting(false);
  };

  const valid = values.name;

  return (
    <Modal className={style.genericModal}>
      <ModalHeader title="Save project as template" />
      <ModalContent>
        <VSpace>
          <FieldGroup label="Name">
            <TextInput
              input={{
                name: "name",
                placeholder: "Enter template name",
                autoFocus: true,
              }}
              value={values.name}
              onChange={handleChange}
            />
          </FieldGroup>
          <FieldGroup label="Description">
            <TextAreaInput
              input={{
                name: "description",
                placeholder: "Describe the template",
              }}
              value={values.description}
              onChange={handleChange}
            />
          </FieldGroup>
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <PrimaryButton disabled={!valid || submitting} onClick={handleSubmit}>
          Save template
        </PrimaryButton>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
      </ModalFooter>
    </Modal>
  );
};

CreateProjectTemplateModal.defaultProps = {};

CreateProjectTemplateModal.propTypes = {
  className: PropTypes.string,
};

export default CreateProjectTemplateModal;
