//@ts-nocheck
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestManufacturer, requestManufacturerSuccess } from "lib/actions/catalogue";
import { showPopupFlag } from "lib/actions/userInterface";
import CatalogueAPI from "lib/api/catalogue";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { selectManufacturerByID } from "lib/reducers/manufacturers.selectors";
import StageHeader from "components/StageHeader";
import { Header, Paragraph } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { ManufacturerProductsTable } from "components/shared/features/configurations/ManufacturerProductsTable/ManufacturerProductsTable";
import PageStage from "components/stages/PageStage";

export const ConfigureManufacturerPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const id = parseInt(params.manufacturerID);
  const entity = useSelector(selectManufacturerByID(id));

  useEffect(() => {
    dispatch(requestManufacturer(id));
  }, []);

  if (!entity) {
    return null;
  }

  const breadcrumb = [
    { label: "Configuration", href: "/configuration" },
    { label: "Manufacturers", href: "/configuration/manufacturers" },
    entity.name,
  ];

  const saveChanges = (name) => {
    return async (value) => {
      try {
        const manufacturer = await CatalogueAPI.updateManufacturer(id, {
          ...entity,
          [name]: value,
        });
        dispatch(requestManufacturerSuccess(manufacturer));
        dispatch(
          showPopupFlag({
            appearance: "success",
            title: "Manufacturer saved",
          }),
        );
      } catch (err) {
        handlePopupFlagError(err);
      }
    };
  };

  return (
    entity && (
      <PageStage>
        <StageHeader breadcrumb={breadcrumb} />
        <Header canEdit placeholder="Enter manufacturer name" value={entity.name} onConfirm={saveChanges("name")} />
        <Paragraph
          canEdit
          size="large"
          placeholder="Enter manufacturer description"
          value={entity.description}
          onConfirm={saveChanges("description")}
        />
        <FieldGroup label="Website URL">
          <Paragraph
            canEdit
            placeholder="Enter manufacturer website URL"
            value={entity.websiteUrl}
            onConfirm={saveChanges("websiteUrl")}
          />
        </FieldGroup>
        <ManufacturerProductsTable manufacturerId={id} />
      </PageStage>
    )
  );
};
