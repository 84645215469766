//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { TextInput } from "components/Input";
import style from "./input.module.scss";

const SubDomainInput = ({ input, className, value, ...otherProps }) => {
  return (
    <div className={style.container}>
      <TextInput
        {...otherProps}
        input={{ placeholder: "workspace", ...input }}
        value={value}
        className={classNames(style.input, className)}
      />
      <span className={style.https}>https://</span>
      <span className={style.domain}>.automify.co.uk</span>
    </div>
  );
};

SubDomainInput.defaultProps = {};

SubDomainInput.propTypes = {
  className: PropTypes.string,
};

export default SubDomainInput;
