import { Helmet } from "react-helmet";
import { getBranding, getCustomer } from "lib/reducers/lookupData.selectors";
import { useSelector } from "store";

export const LayoutHelmet = () => {
  const customer = useSelector(getCustomer);
  const branding = useSelector(getBranding);

  return (
    <Helmet>
      {customer && customer.name ? <title>{customer.name} - Automify</title> : <title>Automify</title>}
      {branding && (branding.portalFavIcon || branding.portalIcon) ? (
        <link rel="icon" href={branding.portalFavIcon || branding.portalIcon} />
      ) : (
        <link rel="icon" type="image/png" href="/images/target.png" />
      )}
    </Helmet>
  );
};
