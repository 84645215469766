import { createSlice } from "@reduxjs/toolkit";
import { createRole, fetchRoles, updateRole } from "store/reducers";
import { deleteRole, fetchRoleById } from "store/reducers/roles/roles.thunks";
import { TRole, UUID } from "types";

export type RolesReducerStateData = Record<UUID, TRole>;

export interface RolesReducerState {
  data: RolesReducerStateData;
}

const initialState: RolesReducerState = {
  data: {},
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: RolesReducerStateData, value: TRole) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchRoleById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const rolesReducer = rolesSlice.reducer;
