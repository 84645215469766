import { useModal } from "effects";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { CreateToolStoreModal, ToolStoresTable } from "components/shared";
import PageStage from "components/stages/PageStage";

export const ToolStoresPage = () => {
  const { openModal } = useModal();

  const createToolStore = () => {
    openModal(<CreateToolStoreModal />);
  };

  return (
    <PageStage>
      <StageHeader
        title="Tool stores"
        subtitle="Manage your tool stores and orders."
        action={
          <SecondaryButton icon="/images/icons/plus_1.svg" onClick={createToolStore}>
            Create tool store
          </SecondaryButton>
        }
      />
      <ToolStoresTable />
    </PageStage>
  );
};
