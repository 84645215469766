import { BlueStatusPill, GrayStatusPill, GreenStatusPill } from "components/shared/pills";

type AssetIssueStatusIndicatorProps = {
  resolved?: boolean;
  assigned?: boolean;
};

export const AssetIssueStatusIndicator = ({ resolved, assigned }: AssetIssueStatusIndicatorProps) => {
  if (resolved) {
    return <GreenStatusPill text="Resolved" />;
  }
  return assigned ? <BlueStatusPill text="In-progress" /> : <GrayStatusPill text="Outstanding" />;
};
