export const RoutePath = {
  root: "/",
  dashboard: {
    root: "/dashboard",
  },
  login: "/login",
  logout: "/logout",
  toolStores: {
    root: "/toolstore",
    toolStore: "/toolstore/:toolStoreID",
  },
};
