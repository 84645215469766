//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { StageState } from "components/StageHeader";
import withReaders from "../../../hocs/withReaders";
import InputError from "../../../lib/InputError";
import Logger from "../../../lib/Logger";
import Api from "../../../lib/api/readers";
import ListReader from "./ListReader";

class ListReaderContainer extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    stageState: StageState.INITIAL,
    reader: null,
  };

  async componentDidMount() {
    const { actions, match } = this.props;
    try {
      const reader = await Api.getReader(match.params.id);
      actions.receiveReader(reader);
      this.setState({
        reader: {
          ...reader,
          location: reader.locationId,
        },
      });
    } catch (err) {
      Logger.error(err);
    }
  }

  onChange = (name, value) =>
    this.setState({
      stageState: StageState.DIRTY,
      reader: {
        ...this.state.reader,
        [name]: value,
      },
    });

  saveChanges = async () => {
    const { actions, match } = this.props;
    const { reader } = this.state;

    if (!reader.name) {
      return this.setState({
        error: new InputError("Reader name must be provided", "name"),
      });
    }
    if (!reader.macAddress) {
      return this.setState({
        error: new InputError("Reader MAC address must be provided", "macAddress"),
      });
    }

    try {
      await Api.updateReader(match.params.id, {
        ...reader,
        locationId: typeof reader.location === "number" ? reader.location : reader.location ? reader.location.id : 0,
      });
      this.setState({ stageState: StageState.SUCCESS });
      actions.receiveReader(reader);
    } catch (err) {
      this.setState({ stageState: StageState.FAILURE });
      Logger.error(err);
    }
  };

  onDelete = async () => {
    const { match, history } = this.props;
    try {
      await Api.deleteReader(match.params.id);
      history("/configuration/readers");
    } catch (err) {
      Logger.error(err);
    }
  };

  render() {
    const { reader, stageState } = this.state;
    return reader ? (
      <ListReader
        reader={reader}
        stageState={stageState}
        onChange={this.onChange}
        onSave={this.saveChanges}
        onDelete={this.onDelete}
      />
    ) : null;
  }
}

export default withReaders(ListReaderContainer);
