//@ts-nocheck
import React from "react";
import socket, { Headers } from "lib/Socket";
import { showPopupFlag } from "lib/actions/userInterface";
import Assets from "lib/api/assets";
import store from "store";
import Card from "components/Card";
import style from "components/modals/ManualCheckoutModal/modal.module.scss";
import CheckinAssetSummary from "./CheckinAssetSummary";

const CheckinCard = ({ session, asset, onClose }) => {
  /**
   * Submit the checkin and close the modal
   */
  const handleCheckin = async (actuations) => {
    try {
      if (!actuations) {
        actuations = null;
      }

      if (actuations && typeof actuations !== "number") {
        actuations = parseInt(actuations);
      }

      // Checkin the asset by unassigning it from the user
      await Assets.removeAssetAssignment(asset.id, { actuations });

      // Send a request for assigned assets
      socket.send(Headers.GET_ASSETS_ASSIGNED, session.user.id);
      onClose();
    } catch (err) {
      store.dispatch(
        showPopupFlag({
          appearance: "error",
          title: "Something went wrong",
          description: "Failed to check-in the tool",
        }),
      );
    }
  };

  return (
    <Card title="Tool Check-in" className={style.card}>
      {asset && <CheckinAssetSummary asset={asset} onSubmit={handleCheckin} onCancel={onClose} />}
    </Card>
  );
};

const ManualCheckinModal = ({ modal, onClose }) => {
  return <CheckinCard session={modal.props.session} asset={modal.props.asset} onClose={onClose} />;
};

export default ManualCheckinModal;
