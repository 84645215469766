import { LookupItem } from "lib/constants/Lookup";
import RagBinTypes from "lib/constants/RagBinTypes";
import { RagBinType } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

type RagBinTypeSelectorProps = {
  name: string;
  value?: RagBinType;
  onChange: (value: RagBinType) => void;
  placeholder?: string;
  appearance?: "inline" | "normal";
  searchable?: boolean;
  className?: string;
};

export const RagBinTypeSelector = ({
  name,
  value,
  onChange,
  placeholder = "Select bin type",
  appearance = "normal",
  searchable = false,
  className,
}: RagBinTypeSelectorProps): JSX.Element => {
  const input = {
    type: "select",
    name,
    placeholder,
    options: RagBinTypes.items,
    isSearchable: searchable,
  };

  return appearance === "inline" ? (
    <Text
      canEdit
      input={input}
      value={RagBinTypes.resolveItem(value)}
      onConfirm={(value: LookupItem<RagBinType>) => {
        onChange(value?.value);
      }}
      className={className}
    />
  ) : (
    <SelectInput
      input={input}
      value={RagBinTypes.resolveItem(value)}
      onChange={(name: string, value: LookupItem<RagBinType>) => {
        onChange(value?.value);
      }}
      className={className}
    />
  );
};
