import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { handleThunkError, ThunkError } from "store/utils/thunk";
import {
  CompletePasswordRecoveryRequest,
  CompletePasswordRecoveryResponse,
  DataResponse,
  LoginRequest,
  LoginResponse,
} from "types";

export const login = createAsyncThunk<LoginResponse, LoginRequest, ThunkError>(
  "accounts/login",
  async (request, { rejectWithValue }) => {
    try {
      const { data } = await API.POST<LoginRequest, DataResponse<LoginResponse>>("/authenticate", request);
      return data;
    } catch (err) {
      return handleThunkError(rejectWithValue, err);
    }
  },
);

export const completePasswordRecovery = createAsyncThunk<
  CompletePasswordRecoveryResponse,
  CompletePasswordRecoveryRequest,
  ThunkError
>("accounts/completePasswordRecovery", async (request, { rejectWithValue }) => {
  try {
    const { data } = await API.POST<
      Pick<CompletePasswordRecoveryRequest, "password">,
      DataResponse<CompletePasswordRecoveryResponse>
    >(
      "/recover_password/callback",
      {
        password: request.password,
      },
      {
        headers: {
          Authorization: request.authToken,
        },
      },
    );
    return data;
  } catch (err) {
    return handleThunkError(rejectWithValue, err);
  }
});
