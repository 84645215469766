//@ts-nocheck
import React, { useState } from "react";
import PropTypes from "prop-types";
import Marketplace from "lib/api/Marketplace";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getCartItems } from "lib/reducers/cart.selectors";
import { useSelector } from "store";
import { PrimaryButton, SecondaryButton, ButtonGroup } from "components/Buttons";
import StageHeader from "components/StageHeader";
import PageStage from "components/stages/PageStage";
import { ShopCheckoutCartTable } from "pages/apps/Shop/shared";

export const ShopCheckoutPageCart = () => {
  const [submitting, setSubmitting] = useState(false);
  const cartItems = useSelector(getCartItems);

  const beginCheckout = async () => {
    try {
      setSubmitting(true);
      const lineItems = Object.keys(cartItems).map((cartItemID) => {
        return {
          storeItemId: parseInt(cartItemID),
          quantity: cartItems[cartItemID],
        };
      });

      const data = await Marketplace.createStoreOrder({
        successUrl: `${window.location.origin}/apps/shop/checkout/order_complete`,
        errorUrl: `${window.location.origin}/apps/shop/checkout/order_cancelled`,
        lineItems,
      });
      const stripe = Stripe(process.env.REACT_APP_STRIPE_KEY);
      const result = await stripe.redirectToCheckout({
        sessionId: data.stripeSessionId,
      });
      console.log("result", result);
    } catch (err) {
      setSubmitting(false);
      handlePopupFlagError(err);
    }
  };

  return (
    <PageStage>
      <StageHeader title="Shopping Cart" subtitle="" />
      <div>
        <ShopCheckoutCartTable />
      </div>
      <div>
        <ButtonGroup alignRight>
          <SecondaryButton>Continue Shopping</SecondaryButton>
          <PrimaryButton submitting={submitting} onClick={beginCheckout}>
            Proceed to checkout
          </PrimaryButton>
        </ButtonGroup>
      </div>
    </PageStage>
  );
};

ShopCheckoutPageCart.propTypes = {
  className: PropTypes.string,
};
