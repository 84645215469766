import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { handleThunkError, ThunkError } from "store/utils/thunk";
import {
  CreateRoleRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TRole,
  UpdateRoleRequest,
} from "types";

type FetchRolesParams = PaginationQueryParams & {};

export const fetchRoles = createAsyncThunk(
  "roles/list",
  async (params: FetchRolesParams): Promise<{ results: TRole[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TRole[]>>("/roles", params);
    return { results: data, paging: metadata };
  },
);

export const fetchRoleById = createAsyncThunk("roles/getById", async (id: number): Promise<TRole> => {
  const { data } = await API.GET<DataResponse<TRole>>(`/roles/${id}`);
  return data;
});

export const createRole = createAsyncThunk("roles/create", async (request: CreateRoleRequest): Promise<TRole> => {
  const { data } = await API.POST<CreateRoleRequest, DataResponse<TRole>>("/roles", request);
  return data;
});

export const updateRole = createAsyncThunk("roles/update", async (request: UpdateRoleRequest): Promise<TRole> => {
  const { data } = await API.PUT<UpdateRoleRequest, DataResponse<TRole>>(`/roles/${request.id}`, request);
  return data;
});

export const deleteRole = createAsyncThunk<void, number, ThunkError>(
  "roles/delete",
  async (id: number, { rejectWithValue }) => {
    try {
      await API.DELETE(`/roles/${id}`);
    } catch (err) {
      return handleThunkError(rejectWithValue, err);
    }
  },
);
