//@ts-nocheck
import { createSelector } from "reselect";

export const getCartItems = createSelector(
  (state) => state.cart,
  (state) => state.data,
);

export const getCartItemsList = createSelector(getCartItems, (data) => Object.keys(data));

export const getCartItemQuantityByID = (itemID) => createSelector(getCartItems, (data) => data[itemID] || 0);
