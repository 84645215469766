//@ts-nocheck
import * as type from "lib/actions/actionTypes";

/**
 *
 * @param {Array} blocks
 * @returns {{payload, type: string}}
 */
export function pageBlocksChange(blocks) {
  return {
    type: type.PAGE_BLOCKS_CHANGE,
    payload: blocks,
  };
}

/**
 *
 * @param {string} blockID
 * @returns {{payload, type: string}}
 */
export function setCurrentBlockID(blockID) {
  return {
    type: type.PAGE_BLOCK_SET_CURRENT,
    payload: blockID,
  };
}

/**
 *
 * @param {string} blockID
 * @returns {{payload, type: string}}
 */
export function selectBlockID(blockID) {
  return {
    type: type.PAGE_BLOCK_SELECT,
    payload: blockID,
  };
}

/**
 *
 * @param {Element} node
 * @returns {{payload, type: string}}
 */
export function showBlockMenuFor(node) {
  return {
    type: type.SHOW_BLOCK_MENU,
    payload: node,
  };
}

/**
 *
 * @param {Element} node
 * @returns {{payload, type: string}}
 */
export function showMediaMenuFor(node) {
  return {
    type: type.SHOW_BLOCK_MEDIA_MENU,
    payload: node,
  };
}

/**
 *
 * @param {Element} node
 * @returns {{payload, type: string}}
 */
export function showConfigMenuFor(node) {
  return {
    type: type.SHOW_BLOCK_CONFIG_MENU,
    payload: node,
  };
}
