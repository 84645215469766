//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class ReportVisibility extends Lookup {
  PUBLIC = 1;
  PRIVATE = 2;
  PASSWORD_PROTECTED = 3;

  constructor() {
    super();
    super._items = [
      {
        label: "Public",
        description: "Your report can be shared with anyone",
        value: this.PUBLIC,
      },
      {
        label: "Password Protected",
        description: "Your report can only be accessed with a password",
        value: this.PASSWORD_PROTECTED,
      },
      {
        label: "Private",
        description: "Your report can only be accessed by authorised users",
        value: this.PRIVATE,
      },
    ];
  }
}

export default new ReportVisibility();
