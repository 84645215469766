import classNames from "classnames";
import { FixLater } from "types";
import style from "components/Card/Card.module.scss";
import { VSpace } from "components/shared";

export const Card = ({ title = "", className, children, actions, onClick }: FixLater) => (
  <VSpace className={classNames(style.card, className)} onClick={onClick}>
    {title ? (
      typeof title === "object" ? (
        title
      ) : (
        <div className={style.cardHeader}>
          <h4 className={style.title}>{title}</h4>
          {actions ? actions : null}
        </div>
      )
    ) : null}
    {children}
  </VSpace>
);

export default Card;
