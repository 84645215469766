//@ts-nocheck
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { openModal } from "lib/actions/modal";
import { requestReportExports } from "lib/actions/reports";
import { showPopupFlag } from "lib/actions/userInterface";
import ReportAPI from "lib/api/reports";
import Capabilities from "lib/constants/Capabilities";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getReportExports, getReportExportsPaging } from "lib/reducers/reports.selectors";
import { SecondaryButton } from "components/Buttons";
import Icon from "components/Icon";
import { Restrict } from "components/Restricted";
import StageHeader from "components/StageHeader";
import { SmartTable, SmartTablePagination } from "components/Table/SmartTable";
import { GenerateReportButton } from "pages/reporting/reports/ReportPage/components/ReportController";

const ReportExportsPage = ({ report, className }) => {
  const dispatch = useDispatch();
  const data = useSelector(getReportExports);
  const paging = useSelector(getReportExportsPaging);
  const fetch = (pageStart, pageLimit) => dispatch(requestReportExports(report.id, { pageStart, pageLimit }));
  useEffect(() => fetch(paging.pageStart, paging.pageLimit), []);
  const rowData = useMemo(() => data, [data]);
  const columns = useMemo(() => {
    return [
      {
        Header: "URL",
        Cell: ({ row }) => {
          const { file } = row.original;
          return (
            file && (
              <div>
                <a href={file.url} target="_blank" rel="noreferrer">
                  <Icon src="/images/icons/export_1.svg" size="small" /> {file.fileName}
                </a>
              </div>
            )
          );
        },
      },
      {
        Header: "Date Generated",
        Cell: ({ row }) => {
          const { dateCreated } = row.original;
          return <span>{dayjs(dateCreated).format("MMM D, YYYY h:mm A")}</span>;
        },
      },
      {
        id: "actions",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <div>
              <Restrict capability={Capabilities.REPORT_UPDATE}>
                <SecondaryButton icon="/images/icons/trash.svg" onClick={removeExport(id)} />
              </Restrict>
            </div>
          );
        },
      },
    ];
  }, []);

  const removeExport = (exportID) => {
    return () => {
      dispatch(
        openModal(Modals.CONFIRMATION, {
          description: "Are you sure you want to remove this export? Doing this is permanent.",
          actionText: "Remove Export",
          onConfirm: async () => {
            try {
              await ReportAPI.deleteReportExport(report.id, exportID);
              fetch(0, paging.pageLimit);
              dispatch(
                showPopupFlag({
                  appearance: "success",
                  title: "Report Export Removed",
                }),
              );
            } catch (err) {
              handlePopupFlagError(err);
            }
          },
        }),
      );
    };
  };

  return (
    <>
      <StageHeader
        title="Exports"
        action={
          <Restrict capability={Capabilities.REPORT_UPDATE}>
            <GenerateReportButton />
          </Restrict>
        }
      />
      <SmartTable columns={columns} data={rowData} />
      <SmartTablePagination paging={paging} onChange={fetch} />
    </>
  );
};

ReportExportsPage.defaultProps = {};

ReportExportsPage.propTypes = {
  className: PropTypes.string,
};

export default ReportExportsPage;
