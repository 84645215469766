//@ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "lib/actions/modal";
import Modals from "lib/constants/Modals";
import { SecondaryButton } from "components/Buttons";

export const ImportCatalogProductButton = ({ manufacturerId, onSuccess }) => {
  const dispatch = useDispatch();

  const importProducts = () => {
    dispatch(
      openModal(Modals.IMPORT_MANUFACTURER_PRODUCT, {
        manufacturerId,
        onSuccess: onSuccess,
      }),
    );
  };

  return (
    <SecondaryButton onClick={importProducts} icon="/images/icons/import_1.svg">
      Import
    </SecondaryButton>
  );
};
