import { SyntheticEvent, useState } from "react";
import { CursorClickIcon, PlusIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import styled from "styled-components";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createAsset } from "store/reducers";
import { createStorageAsset } from "store/reducers/assets/storageAssets.thunks";
import { FixLater, TCatalogueProduct, TManufacturer } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { DataGrid, DataItem } from "components/DataGrid";
import { NumberInput, TextInput } from "components/Input";
import ToolboxPortrait from "components/ToolboxPortrait";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { AssetSelector } from "components/inputs";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals";
import { ButtonGroup, VSpace } from "components/shared";
import { WhiteButton } from "components/shared/buttons";
import { CatalogueManufacturerSelector, CatalogueProductSelector } from "components/shared/features/catalogue";
import style from "./modal.module.scss";

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const AssignStorageAssetModal = ({ modal, onClose }: FixLater) => {
  const dispatch = useDispatch();
  const { storage, drawer, initialState, onSuccess } = modal.props;
  const [values, setValues] = useState({
    drawerNumber: drawer?.value,
    asset: null,
    manufacturer: null,
    product: null,
    serialNumber: "",
    ...initialState,
  });
  const [existing, setExisting] = useState(true);
  const [submitting, isSubmitting] = useState(false);

  const handleChange = (name: string, value: TManufacturer | TCatalogueProduct) => {
    setValues((values: FixLater) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    isSubmitting(true);
    try {
      event.preventDefault();
      let newAsset;
      if (!existing) {
        newAsset = await dispatch(
          createAsset({
            ...values,
            assetProductId: values.product.guid,
          }),
        ).unwrap();
      }

      const assignment = await dispatch(
        createStorageAsset({
          storageId: storage.id,
          assetId: existing ? values.asset.id : newAsset?.id,
          drawerNumber: values.drawerNumber,
        }),
      );

      dispatch(
        showPopupFlag({
          appearance: "success",
          title: `Tool added to drawer ${values.drawerNumber}`,
        }),
      );
      onClose();
      if (typeof onSuccess === "function") {
        onSuccess(assignment);
      }
    } catch (err) {
      handlePopupFlagError(err);
    }
    isSubmitting(false);
  };

  const valid = (existing ? values.asset : values.product) && values.drawerNumber;

  return (
    <Modal>
      <div className={style.toolboxContainer}>
        <ToolboxPortrait
          size="large"
          model={storage.model}
          className={style.toolbox}
          openDrawers={values.drawerNumber ? [values.drawerNumber] : null}
        />
      </div>
      <ModalHeader title={`Add a tool to drawer ${values.drawerNumber}`} />
      <ModalContent className={style.modalContent}>
        <VSpace>
          {!drawer && (
            <FieldGroup label="Drawer Number">
              <NumberInput
                input={{
                  name: "drawerNumber",
                  placeholder: "Enter drawer number",
                }}
                value={values.drawerNumber}
                onChange={handleChange}
              />
            </FieldGroup>
          )}
          {existing ? (
            <>
              <FieldGroup label="Pick from your existing tools">
                <AssetSelector
                  name="asset"
                  placeholder="Search for an existing tool"
                  value={values.asset}
                  queryParams={{ storage: false }}
                  onChange={handleChange}
                />
              </FieldGroup>
            </>
          ) : (
            <>
              <FieldGroup label="Tool Manufacturer">
                <CatalogueManufacturerSelector
                  searchable
                  appearance="normal"
                  name="manufacturer"
                  placeholder="Select manufacturer"
                  value={values.manufacturer}
                  onChange={handleChange}
                />
              </FieldGroup>
              {values.manufacturer && (
                <FieldGroup label="Tool Product">
                  <CatalogueProductSelector
                    appearance="normal"
                    name="product"
                    placeholder="Find a tool"
                    value={values.product}
                    onChange={handleChange}
                    manufacturerId={values.manufacturer?.id}
                  />
                </FieldGroup>
              )}
              <FieldGroup label="Additional Options">
                <DataGrid>
                  <DataItem label="Serial Number">
                    <TextInput
                      input={{
                        name: "serialNumber",
                        placeholder: "Enter serial number",
                      }}
                      value={values.serialNumber}
                      onChange={handleChange}
                    />
                  </DataItem>
                </DataGrid>
              </FieldGroup>
            </>
          )}
        </VSpace>
      </ModalContent>
      <ModalFooter>
        <FooterButtons>
          <div>
            {existing ? (
              <WhiteButton width="w-fit" leadingIcon={PlusIcon} onClick={() => setExisting(!existing)}>
                Create a new tool
              </WhiteButton>
            ) : (
              <WhiteButton width="w-fit" leadingIcon={CursorClickIcon} onClick={() => setExisting(!existing)}>
                Use existing tool
              </WhiteButton>
            )}
          </div>
          <ButtonGroup>
            <PrimaryButton disabled={!valid} submitting={submitting} onClick={handleSubmit}>
              Add tool
            </PrimaryButton>
            <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          </ButtonGroup>
        </FooterButtons>
      </ModalFooter>
    </Modal>
  );
};

AssignStorageAssetModal.defaultProps = {};

AssignStorageAssetModal.propTypes = {
  className: PropTypes.string,
};

export default AssignStorageAssetModal;
