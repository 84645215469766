import { createSlice } from "@reduxjs/toolkit";
import {
  createToolStore,
  fetchToolStores,
  updateToolStore,
  deleteToolStore,
  fetchToolStoreById,
  fetchToolStoreByStorageId,
} from "store/reducers/toolStores/toolStores.thunks";
import { TToolStore, UUID } from "types";

export type ToolStoresReducerStateData = Record<UUID, TToolStore>;

export interface ToolStoresReducerState {
  data: ToolStoresReducerStateData;
}

const initialState: ToolStoresReducerState = {
  data: {},
};

export const toolStoresSlice = createSlice({
  name: "toolStores",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchToolStores.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: ToolStoresReducerStateData, value: TToolStore) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchToolStoreById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(fetchToolStoreByStorageId.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createToolStore.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateToolStore.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteToolStore.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const toolStoresReducer = toolStoresSlice.reducer;
