//@ts-nocheck
import React, { useState } from "react";
import Papa from "papaparse";
import PropTypes from "prop-types";
import MarketplaceAPI from "lib/api/Marketplace";
import ShopImportColumnTypes from "lib/constants/ShopImportColumnTypes";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { ButtonGroup, SecondaryButton, PrimaryButton } from "components/Buttons";
import Card from "components/Card";
import CardActions from "components/CardActions";
import CardSection from "components/CardSection";
import { FileInput } from "components/Input";
import Loading from "components/Loading";
import ItemsCreatedTable from "pages/apps/ShopManager/ShopImportPage/components/ShopImporter/ItemsCreatedTable";
import SelectColumnTypes from "pages/apps/ShopManager/ShopImportPage/components/ShopImporter/SelectColumnTypes";

const ShopImporter = ({ className }) => {
  const [files, setFiles] = useState([]);
  const [parsing, setParsing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [itemsCreated, setItemsCreated] = useState([]);
  const [columnsMapping, setColumnsMapping] = useState(null);
  const [rows, setRows] = useState(null);

  const handleUpload = async (_, files) => {
    try {
      setFiles(files);
      const file = files[0];
      setParsing(true);
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        worker: true,
        complete: (results) => {
          setParsing(false);
          setRows(results.data);
        },
      });
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleConfirmColumns = (mapping) => {
    setColumnsMapping(mapping);
  };

  const handleBeginImport = async () => {
    const columnsMappingKeys = Object.keys(columnsMapping);
    setCreating(true);
    for (const row of rows) {
      const storeItem = {
        images: [],
        documents: [],
        metadata: [],
      };

      for (const columnKey of columnsMappingKeys) {
        const columnType = ShopImportColumnTypes.resolveItem(columnsMapping[columnKey]);
        if (!columnType) {
          continue;
        }
        const cellKey = columnType.fieldName;
        const cellValue = row[columnKey];

        switch (columnType.value) {
          case ShopImportColumnTypes.SHIPPABLE:
            storeItem[cellKey] = ["true", "1", "yes", "on"].indexOf(cellValue.toLowerCase()) !== -1;
            break;
          case ShopImportColumnTypes.PRICE_EXC_VAT:
          case ShopImportColumnTypes.DISCOUNT:
            storeItem[cellKey] = parseFloat(cellValue);
            break;
          case ShopImportColumnTypes.QUANTITY:
            storeItem[cellKey] = parseInt(cellValue);
            break;
          case ShopImportColumnTypes.IMAGE:
            storeItem.images.push({
              url: cellValue,
            });
            break;
          case ShopImportColumnTypes.DOCUMENT:
            storeItem.documents.push({
              url: cellValue,
            });
            break;
          case ShopImportColumnTypes.METADATA:
            // TODO: How do we split it because metadata is key/value?
            break;
          default:
            storeItem[cellKey] = cellValue;
            break;
        }
      }

      try {
        const createdStoreItem = await MarketplaceAPI.createStoreItem(storeItem);
        setItemsCreated((items) => [...items, createdStoreItem]);
      } catch (err) {
        handlePopupFlagError(err);
      }
    }
  };

  return (
    <Card className={className}>
      {creating ? (
        <CardSection title="Items Created Report">
          <ItemsCreatedTable itemsCreated={itemsCreated} />
        </CardSection>
      ) : (
        <>
          <CardSection title="1. Upload CSV file">
            {parsing ? (
              <Loading size="large" />
            ) : (
              <FileInput
                input={{
                  name: "file",
                  placeholder: "Drag & Drop the CSV file containing products here",
                }}
                value={files}
                onChange={handleUpload}
              />
            )}
          </CardSection>
          {Array.isArray(rows) && (
            <CardSection title="2. Select column types">
              <SelectColumnTypes
                rows={rows}
                columnTypeOptions={ShopImportColumnTypes.items}
                onConfirm={handleConfirmColumns}
              />
            </CardSection>
          )}
          <CardActions>
            <ButtonGroup alignRight>
              <SecondaryButton>Cancel</SecondaryButton>
              <PrimaryButton onClick={handleBeginImport} icon="/images/icons/import_1.svg" disabled={!columnsMapping}>
                Begin Import
              </PrimaryButton>
            </ButtonGroup>
          </CardActions>
        </>
      )}
    </Card>
  );
};

ShopImporter.defaultProps = {};

ShopImporter.propTypes = {
  className: PropTypes.string,
};

export default ShopImporter;
