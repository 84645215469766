import { createSelector } from "reselect";
import { RootStore } from "store/reducer";

const selectState = (state: RootStore) => state.roles;

export const selectRoles = createSelector(selectState, (state) => state.data);

export const selectRolesList = createSelector(selectRoles, (data) => Object.values(data));

export const selectRoleByID = (id: number) => createSelector(selectRoles, (data) => data[id]);
