import React, { useEffect, useMemo, useState } from "react";
import { Combobox } from "@headlessui/react";
import { DocumentSearchIcon } from "@heroicons/react/outline";
import { ChevronRightIcon, SearchIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import dayjs from "dayjs";
import { useModal } from "effects/useModal";
import styled from "styled-components";
import Resolve from "lib/Resolve";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { fetchAssets, selectAssets, selectVehicleByID } from "store/reducers";
import { TAsset, UUID } from "types";
import { Anchor } from "components/Typography";
import { Modal } from "components/modals/Modal";
import { PrimaryButton } from "components/shared/buttons";

const StyledModal = styled(Modal)`
  min-width: unset;
  max-width: 50rem;
  width: 100%;
  padding: 1rem 0 0;
`;

type VehicleAssignmentsModalProps = {
  vehicleId: UUID;
};

export const VehicleAssignmentsModal = ({ vehicleId }: VehicleAssignmentsModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const assets = useSelector(selectAssets);
  const vehicle = useSelector(selectVehicleByID(vehicleId));
  const [loading, setLoading] = useState<boolean>(false);
  const [ids, setIds] = useState<number[]>([]);
  const [activeOption, setActiveOption] = useState<TAsset>();
  const [query, setQuery] = useState<string>("");
  const options = useMemo(() => {
    return ids.map((id) => assets[id]).filter(Boolean);
  }, [ids, assets]);

  const filteredOptions =
    query === ""
      ? []
      : options.filter((asset) => {
          if (!asset?.catalogProduct) return false;
          return asset.catalogProduct.name.toLowerCase().includes(query.toLowerCase());
        });

  useEffect(() => {
    fetch();
  }, [vehicleId]);

  const fetch = async () => {
    try {
      setLoading(true);
      const { results } = await dispatch(
        fetchAssets({
          pageLimit: 250,
          assigned: true,
          vehicleId,
        }),
      ).unwrap();
      setIds(results.map(({ id }) => id));
      if (results.length > 0) setActiveOption(results[0]);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const handleDownloadReport = () => {
    if (!vehicle) return;
    const csvContent = [
      ["Name", "MPN", "Serial number", "Assigned to", "Assigned at", "Storage location", "Vehicle"],
      ...options.map((asset) => {
        return [
          asset.catalogProduct?.name || "",
          asset.catalogProduct?.mpn || "",
          asset.serialNumber || "",
          Resolve.resolveUserFullName(asset.assignment?.userId) || "",
          dayjs(asset.assignment?.dateAssigned).format("ddd, MMM D, YYYY h:mm A"),
          asset.storageConfig?.storageId || "",
          vehicle.registration,
        ].map((value) => `"${value.replaceAll('"', '""')}"`);
      }),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const filename = `${vehicle.registration} tools outstanding report - ${dayjs().format()}.csv`;
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", filename);
    pom.click();
  };

  return (
    <StyledModal className="max-w-3xl divide-y divide-gray-100">
      <Combobox value={activeOption} onChange={setActiveOption}>
        <div className="flex flex-row gap-4 pr-4">
          <div className="relative flex-1">
            <SearchIcon
              className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <Combobox.Input
              autoFocus
              className="h-12 w-full border-0 bg-transparent pl-11 text-gray-900 placeholder:text-gray-400 focus:ring-0"
              placeholder="Search..."
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>
          <div>
            <PrimaryButton onClick={handleDownloadReport}>Download report</PrimaryButton>
          </div>
        </div>

        {!loading && options.length === 0 ? (
          <div className="px-6 py-14 text-center sm:px-14">
            <DocumentSearchIcon className="mx-auto h-8 w-8 text-gray-400" aria-hidden="true" />
            <p className="mt-4 font-semibold text-gray-900">No tools outstanding</p>
            <p className="mt-2 text-gray-500">We couldn't find any tools outstanding for this vehicle.</p>
          </div>
        ) : (
          <>
            {(query === "" || filteredOptions.length > 0) && (
              <Combobox.Options as="div" static hold className="flex transform-gpu divide-x divide-gray-100">
                <div
                  className={classNames(
                    "max-h-[32rem] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4",
                    activeOption && "sm:h-[32rem]",
                  )}
                >
                  {query === "" && <h2 className="mb-4 mt-2 text-sm font-semibold text-gray-500">Tools outstanding</h2>}
                  <div className="-mx-2 text-base text-gray-700">
                    {(query === "" ? options : filteredOptions).map((asset) => (
                      <Combobox.Option
                        as="div"
                        key={asset.id}
                        value={asset}
                        className={classNames(
                          "group flex cursor-pointer select-none items-center rounded-md p-2 hover:bg-gray-100",
                          activeOption?.id === asset.id && "bg-gray-100",
                        )}
                      >
                        <img src={asset.catalogProduct?.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full" />
                        <span className="ml-3 flex-auto truncate">{asset.catalogProduct?.name}</span>
                        <ChevronRightIcon
                          className="ml-3 hidden h-5 w-5 flex-none text-gray-400 group-data-[focus]:block"
                          aria-hidden="true"
                        />
                      </Combobox.Option>
                    ))}
                  </div>
                </div>

                {activeOption && (
                  <div className="h-[32rem] w-1/2 flex-none flex-col divide-y divide-gray-100 overflow-y-auto sm:flex">
                    <div className="flex-none p-6 text-center">
                      <img
                        src={activeOption.catalogProduct?.imageUrl}
                        alt=""
                        className="mx-auto h-16 w-16 rounded-full"
                      />
                      <h2 className="mt-3 font-semibold text-gray-900">{activeOption.catalogProduct?.name}</h2>
                      <p className="text-sm leading-6 text-gray-500">{activeOption.catalogProduct?.mpn}</p>
                    </div>
                    <div className="flex flex-auto flex-col justify-between p-6">
                      <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                        <dt className="col-end-1 font-semibold text-gray-900">Assigned to</dt>
                        <dd className="truncate">
                          <Anchor to={`/configuration/users/${activeOption.assignment?.userId}`}>
                            {Resolve.resolveUserFullName(activeOption.assignment?.userId)}
                          </Anchor>
                        </dd>
                        <dt className="col-end-1 font-semibold text-gray-900">Assigned at</dt>
                        <dd className="truncate">
                          {dayjs(activeOption.assignment?.dateAssigned).format("ddd, MMM D, YYYY h:mm A")}
                        </dd>
                        <dt className="col-end-1 font-semibold text-gray-900">ID</dt>
                        <dd>{activeOption.id}</dd>
                        <dt className="col-end-1 font-semibold text-gray-900">Serial number</dt>
                        <dd>{activeOption.serialNumber || <span className="text-gray-400">N/A</span>}</dd>
                        <dt className="col-end-1 font-semibold text-gray-900">Storage location</dt>
                        <dd>{activeOption.storageConfig?.storageId || <span className="text-gray-400">N/A</span>}</dd>
                      </dl>
                    </div>
                  </div>
                )}
              </Combobox.Options>
            )}
            {query !== "" && filteredOptions.length === 0 && (
              <div className="px-6 py-14 text-center sm:px-14">
                <DocumentSearchIcon className="mx-auto h-8 w-8 text-gray-400" aria-hidden="true" />
                <p className="mt-4 font-semibold text-gray-900">No tools found</p>
                <p className="mt-2 text-gray-500">We couldn't find any tools with that term. Please try again.</p>
              </div>
            )}
          </>
        )}
      </Combobox>
    </StyledModal>
  );
};
