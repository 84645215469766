import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TAlertRecipient, UUID } from "types";

const selectState = (state: RootStore) => state.alertRecipients;

export const selectAlertRecipients = createSelector(selectState, (state) => state.data);

export const selectAlertRecipientsList = createSelector(selectAlertRecipients, (data) => Object.values(data));

export const selectAlertRecipientByID = (id: UUID) =>
  createSelector(selectAlertRecipients, (data): TAlertRecipient | undefined => data[id]);

export const selectAlertRecipientsByAlertID = (alertId: UUID) =>
  createSelector(selectAlertRecipientsList, (list) => list.filter((entity) => entity.alertId === alertId));

export const selectAlertRecipientsHighestOrderByAlertID = (alertId: UUID) =>
  createSelector(selectAlertRecipientsByAlertID(alertId), (list) => {
    return list.reduce((maxOrder, cur) => {
      if (cur.order > maxOrder) {
        return cur.order;
      }
      return maxOrder;
    }, 0);
  });
