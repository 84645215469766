//@ts-nocheck
import React, { ReactNode } from "react";
import classNames from "classnames";
import style from "./fieldGroup.module.scss";

type FieldGroupProps = {
  label?: string | ReactNode;
  value?: string | ReactNode;
  withAddon?: boolean;
  className?: string;
  children?: ReactNode;
};

export const FieldGroup = ({ className = "", label, value, children, withAddon = false }: FieldGroupProps) => (
  <div className={classNames(style.fieldGroup, { [style.withAddon]: withAddon }, className)}>
    {label ? typeof label === "string" ? <label className="text-gray-600">{label}</label> : label : null}
    {value || children}
  </div>
);

export default FieldGroup;
