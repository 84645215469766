import React, { FormEvent, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createToolRoom } from "store/reducers";
import { CreateToolRoomRequest, TLocation, TToolRoom } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared/layouts";

type CreateToolRoomModalProps = {
  onSuccess?: (toolRoom: TToolRoom) => void;
};

export const CreateToolRoomModal = ({ onSuccess }: CreateToolRoomModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateToolRoomRequest>({
    name: "",
    description: "",
    locationId: undefined,
  });

  const handleChange = (name: string, value: string | number | undefined) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const toolRoom = await dispatch(createToolRoom(values)).unwrap();
      closeModal();
      onSuccess && onSuccess(toolRoom);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleChangeLocation = (name: string, value?: TLocation) => {
    handleChange("locationId", value?.id);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader title="Create tool room" />
        <ModalContent>
          <VSpace>
            <FieldGroup label="Name">
              <TextInput
                input={{
                  name: "name",
                  placeholder: "Enter room name",
                  autoFocus: true,
                }}
                value={values.name}
                onChange={handleChange}
              />
            </FieldGroup>
            <FieldGroup label="Location">
              <LocationSelector
                appearance="normal"
                name="locationId"
                value={values.locationId}
                onChange={handleChangeLocation}
              />
            </FieldGroup>
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit">Create room</PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
