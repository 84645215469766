import { ComponentProps, useState } from "react";
import { usePopper } from "react-popper";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import styled from "styled-components";

type ProjectSheetIconProps = Omit<ComponentProps<"div">, "onChange"> & {
  value: string;
  onChange?: (value?: string) => void;
};

export const ProjectSheetIcon = styled(({ value, onChange, className }: ProjectSheetIconProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [5, 5],
        },
      },
    ],
  });
  const [picking, setPicking] = useState<boolean>(false);

  const handleClick = (result: { id: string; native: string; shortcodes: string }) => {
    onChange?.(result.native);
    setPicking(false);
  };

  return (
    <>
      <div ref={setReferenceElement} className={className} onClick={() => setPicking(!picking)}>
        {value}
      </div>
      {picking && (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="z-10">
          <Picker data={data} onEmojiSelect={handleClick} />
        </div>
      )}
    </>
  );
})`
  position: relative;
  flex: 0;
  font-size: 5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  line-height: 1;
  width: fit-content;

  &:hover {
    transition: 100ms background-color ease-in-out;
    background-color: rgba(0, 0, 20, 0.1);
  }
`;
