//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";

const AuthForm = ({ children, className, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} className={className} autoComplete="off">
      {children}
    </form>
  );
};

AuthForm.defaultProps = {};

AuthForm.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default AuthForm;
