//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import { useSelector } from "store";
import { DataGrid, DataItem } from "components/DataGrid";
import StageSection from "components/StageSection";
import { Header, Paragraph, Text } from "components/Typography";
import {
  ShopItemStockIndicator,
  ShopItemDocuments,
  ShopItemGallery,
  ShopItemPagePurchaseControls,
  ShopItemPrice,
  ShopItemBreadcrumb,
} from "pages/apps/Shop/shared";
import { HalfHalf } from "pages/marketing/components/Common";

const ShopItemPageDashboard = ({ itemID }) => {
  const item = useSelector(getStoreItemByID(itemID));
  return (
    item && (
      <>
        <ShopItemBreadcrumb itemID={itemID} />
        <StageSection isFirst={true}>
          <HalfHalf>
            <div>
              <ShopItemGallery id={item.id} />
            </div>
            <div>
              <Header value={item.name} />
              <ShopItemPrice vatDisclaimer itemID={item.id} />
              <Paragraph>{item.description || <span className="text-muted">No description available</span>}</Paragraph>
              <DataGrid>
                <DataItem label="Availability">
                  <ShopItemStockIndicator itemID={item.id} />
                </DataItem>
                <DataItem label="SKU">
                  <Text value={item.sku} />
                </DataItem>
              </DataGrid>
              <ShopItemPagePurchaseControls itemID={item.id} />
              <ShopItemDocuments id={item.id} />
            </div>
          </HalfHalf>
        </StageSection>
      </>
    )
  );
};

ShopItemPageDashboard.defaultProps = {};

ShopItemPageDashboard.propTypes = {
  itemID: PropTypes.number,
};

export default ShopItemPageDashboard;
