import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { UUID } from "types";

const selectState = (state: RootStore) => state.toolStoreOrderItemEvents;

export const selectToolStoreOrderItemEvents = createSelector(selectState, (state) => state.data);

export const selectToolStoreOrderItemEventsList = createSelector(selectToolStoreOrderItemEvents, (data) =>
  Object.values(data),
);

export const selectToolStoreOrderItemEventByID = (id: UUID) =>
  createSelector(selectToolStoreOrderItemEvents, (data) => data[id]);
