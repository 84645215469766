//@ts-nocheck
import Api from "./api";

class Projects extends Api {
  /**
   * Get projects
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, metadata: Object}>} Response
   */
  static async getProjects(queryParams = {}) {
    const response = await this.get("/projects").query(queryParams);
    return response.body;
  }

  /**
   * Get a project
   * @async
   * @param {number} id
   * @returns {Promise.<Object>}
   */
  static async getProject(id) {
    const response = await this.get(`/projects/${id}`);
    return response.body.data;
  }

  /**
   * Create project
   * @async
   * @param {Object} project
   * @returns {Promise} Response
   */
  static async createProject(project) {
    const response = await this.post("/projects").send(project);
    return response.body.data;
  }

  /**
   * Update project
   * @param {number} id - Project id
   * @param {Object} project
   * @returns {Promise} Response
   */
  static async updateProject(id, project) {
    const response = await this.put(`/projects/${id}`).send(project);
    return response.body.data;
  }

  /**
   * Delete project
   * @param {number} id - Project id
   * @returns {Promise<void>}
   */
  static async deleteProject(id) {
    return await this.delete(`/projects/${id}`).send();
  }

  /**
   * Get project blocks
   * @async
   * @param {number} projectID
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, metadata: Object}>}
   */
  static async getProjectBlocks(projectID, queryParams = {}) {
    const response = await this.get(`/projects/${projectID}/blocks`).query(queryParams);
    return response.body;
  }

  /**
   * Update or reorder project blocks
   * @async
   * @param {number} projectID
   * @param {Array} projectBlocks
   * @returns {Promise}
   */
  static async updateProjectBlocks(projectID, projectBlocks) {
    return await this.put(`/projects/${projectID}/blocks`).send(projectBlocks);
  }

  /**
   * Get project templates
   * @async
   * @param {Object} [queryParams={}]
   * @returns {Promise.<{data: Array, metadata: Object}>} Response
   */
  static async getProjectTemplates(queryParams = {}) {
    const response = await this.get("/project_templates").query(queryParams);
    return response.body;
  }

  /**
   * Get a project template
   * @async
   * @param {number} ID
   * @returns {Promise.<Object>}
   */
  static async getProjectTemplate(ID) {
    const response = await this.get(`/project_templates/${ID}`);
    return response.body.data;
  }

  /**
   * Create project template
   * @async
   * @param {Object} requestBody
   * @returns {Promise} Response
   */
  static async createProjectTemplate(requestBody) {
    const response = await this.post("/project_templates").send(requestBody);
    return response.body.data;
  }

  /**
   * Create project template
   * @async
   * @param {Object} projectTemplateID Project template ID
   * @returns {Promise} Response
   */
  static async createProjectFromTemplate(projectTemplateID) {
    const response = await this.post(`/project_templates/${projectTemplateID}/generate`).send();
    return response.body.data;
  }

  /**
   * Delete project template
   * @param {number} ID
   * @returns {Promise<void>}
   */
  static async deleteProjectTemplate(ID) {
    return await this.delete(`/project_templates/${ID}`).send();
  }
}

export default Projects;
