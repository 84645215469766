//@ts-nocheck
import Api from "./api";

class Users extends Api {
  /**
   * Get users
   * @async
   * @param {Object} [queryParams=null]
   * @returns {Promise.<Object.<{ data: Array, metadata: Object }>>} Array of users.
   */
  static async getUsers(queryParams = {}) {
    const response = await this.get("/users").query(queryParams);
    return response.body;
  }

  /**
   * Get user roles
   * @async
   * @param {Object} [queryParams=null]
   * @returns {Promise.<Object.<{ data: Array, metadata: Object }>>}.
   */
  static async getRoles(queryParams = {}) {
    const response = await this.get(`/roles`).query(queryParams);
    return response.body;
  }

  /**
   * Get user
   * @async
   * @param {number} userId The user ID of the user to get
   * @returns {Promise.<Object>} A single user.
   */
  static async getUser(userId) {
    const response = await this.get(`/users/${userId}`);
    return response.body.data;
  }

  /**
   * Get user role
   * @async
   * @param {number} roleID
   * @returns {Promise.<Object>}
   */
  static async getRole(roleID) {
    const response = await this.get(`/roles/${roleID}`);
    return response.body.data;
  }

  /**
   * Update user
   * @async
   * @param {number} userId The user ID of the user to update
   * @param {Object} user The user object
   * @returns {Promise} No content.
   */
  static async updateUser(userId, user) {
    return await this.put(`/users/${userId}`).send(user);
  }

  /**
   * Update user role
   * @async
   * @param {number} roleID
   * @param {Object} role
   * @returns {Promise}
   */
  static async updateRole(roleID, role) {
    const response = await this.put(`/roles/${roleID}`).send(role);
    return response.body.data;
  }

  /**
   * Create user
   * @async
   * @param {Object} user The user object
   * @returns {Promise.<Object>} ID and location of new user.
   */
  static async createUser(user) {
    const response = await this.post(`/users`).send(user);
    return await response.body.data;
  }

  /**
   * Create role
   * @async
   * @param {Object} role
   * @returns {Promise.<Object>}
   */
  static async createRole(role) {
    const response = await this.post(`/roles`).send(role);
    return await response.body.data;
  }

  /**
   * Invite a new user
   * @async
   * @param {Object} requestBody
   * @returns {Promise.<Object>}
   */
  static async inviteUser(requestBody) {
    return await this.post(`/users`).send(requestBody);
  }

  /**
   * Delete a user
   * @param {number} userId
   * @returns {Promise.<void>}
   */
  static async deleteUser(userId) {
    return await this.delete(`/users/${userId}`).send();
  }

  /**
   * Delete user role
   * @param {number} roleID
   * @returns {Promise.<void>}
   */
  static async deleteRole(roleID) {
    return await this.delete(`/roles/${roleID}`).send();
  }

  /**
   * Get user keycards
   * @async
   * @param {Object} [queryParams=null]
   * @returns {Promise.<Object.<{ data: Array, metadata: Object }>>}.
   */
  static async getKeycards(queryParams = {}) {
    const response = await this.get(`/keycards`).query(queryParams);
    return response.body;
  }

  /**
   * Get user keycard
   * @async
   * @param {string} keycardID
   * @returns {Promise.<Object>}
   */
  static async getKeycard(keycardID) {
    const response = await this.get(`/keycards/${keycardID}`);
    return response.body.data;
  }

  /**
   * Get user keycard assignment
   * @async
   * @param {string} keycardID
   * @returns {Promise.<Object>}
   */
  static async getKeycardAssignment(keycardID) {
    const response = await this.get(`/keycards/${keycardID}/assignment`);
    return response.body.data;
  }

  /**
   * Update user keycard
   * @async
   * @param {string} keycardID
   * @param {Object} keycard
   * @returns {Promise}
   */
  static async updateKeycard(keycardID, keycard) {
    const response = await this.put(`/keycards/${keycardID}`).send(keycard);
    return response.body.data;
  }

  /**
   * Create keycard
   * @async
   * @param {Object} keycard
   * @returns {Promise.<Object>}
   */
  static async createKeycard(keycard) {
    const response = await this.post(`/keycards`).send(keycard);
    return await response.body.data;
  }

  /**
   * Create keycard
   * @async
   * @param {string} keycardID
   * @param {{userId: number}} requestBody
   * @returns {Promise.<Object>}
   */
  static async assignKeycard(keycardID, requestBody) {
    const response = await this.post(`/keycards/${keycardID}/assignment`).send(requestBody);
    return await response.body.data;
  }

  /**
   * Delete keycard assignment
   * @param {string} keycardID
   * @returns {Promise.<void>}
   */
  static async unAssignKeycard(keycardID) {
    return await this.delete(`/keycards/${keycardID}/assignment`).send();
  }

  /**
   * Delete user keycard
   * @param {string} keycardID
   * @returns {Promise.<void>}
   */
  static async deleteKeycard(keycardID) {
    return await this.delete(`/keycards/${keycardID}`).send();
  }
}

export default Users;
