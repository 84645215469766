//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../lib/actions/lookupData";
import mergeProps from "./mergeProps";
import mergeStateStore from "./mergeStateStore";

export default function withLookupData(WrappedComponent) {
  class Component extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) =>
    mergeStateStore(state, {
      lookupData: state.lookupData,
    });
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  });
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
}
