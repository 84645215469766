//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Icon from "../Icon";
import style from "./iconButton.module.scss";

const IconButton = ({ icon, iconSize, disabled, onClick, className, iconClassName }) => (
  <button type="button" className={classNames(style.button, className)} onClick={onClick} disabled={disabled}>
    <Icon src={icon} size={iconSize} className={classNames(style.icon, iconClassName)} />
  </button>
);

IconButton.defaultProps = {
  iconSize: "medium",
};

IconButton.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default IconButton;
