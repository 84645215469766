import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { CreateAlertRuleRequest, DataResponse, DeleteAlertRuleRequest, TAlertRule } from "types";

export const createAlertRule = createAsyncThunk(
  "alertRules/createAlertRule",
  async (request: CreateAlertRuleRequest): Promise<TAlertRule> => {
    const { data } = await API.POST<CreateAlertRuleRequest, DataResponse<TAlertRule>>(
      `/alerts/${request.alertId}/rules`,
      request,
    );
    return data;
  },
);

export const deleteAlertRule = createAsyncThunk(
  "alertRules/deleteAlertRule",
  async (request: DeleteAlertRuleRequest): Promise<void> => {
    await API.DELETE(`/alerts/${request.alertId}/rules/${request.id}`);
  },
);
