//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { PrimaryButton } from "components/Buttons";
import IconButton from "components/IconButton";
import { InputGroup, InputGroups, NumberInput, SelectInput } from "components/Input";
import { Table, TableCell, TableHeader, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import Resolve from "../../../../../lib/Resolve";
import style from "./readerAntennas.module.scss";

const ReaderAntennas = ({ antennas, antenna, onChange, onCreate, onRemove }) => (
  <div>
    <h3>Add a antenna</h3>
    <p>Add a new antenna to the RFID reader. Antenna's are used to detect RFID tags.</p>
    <InputGroups>
      <InputGroup className={style.inputGroup}>
        <NumberInput
          input={{ name: "antennaPort", placeholder: "Antenna port" }}
          value={antenna.antennaPort}
          onChange={onChange}
          className={style.portInput}
        />
        <SelectInput
          input={{
            name: "type",
            placeholder: "Select antenna type",
            options: Resolve.getAntennaTypes(),
          }}
          value={antenna.type}
          onChange={onChange}
          className={style.typeInput}
        />
        <PrimaryButton onClick={onCreate} disabled={!antenna.antennaPort || !antenna.type} className={style.submitBtn}>
          Add antenna
        </PrimaryButton>
      </InputGroup>
    </InputGroups>
    <h3>Antennas</h3>
    <Table>
      <TableHeader>
        <TableHeadCell>Port number</TableHeadCell>
        <TableHeadCell>Type</TableHeadCell>
        <TableHeadCell isEmpty={true} />
      </TableHeader>
      <TableBody>
        {antennas.map(({ readerId, antennaPort, typeId }) => (
          <TableRow key={`${readerId}.${antennaPort}`}>
            <TableCell>{antennaPort}</TableCell>
            <TableCell>{Resolve.resolveAntennaTypeName(typeId)}</TableCell>
            <TableCell>
              <IconButton icon="/images/icons/cross.svg" onClick={() => onRemove(antennaPort)} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
);

ReaderAntennas.defaultProps = {};

ReaderAntennas.propTypes = {
  antennas: PropTypes.array,
  antenna: PropTypes.object,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
};

export default ReaderAntennas;
