//@ts-nocheck
import * as type from "./actionTypes";
import { receivePagedResult, requestFailure } from "./common";

export function requestTemplates(options = {}) {
  return {
    type: type.GET_REPORT_TEMPLATES_REQUEST,
    payload: options,
  };
}

export function requestTemplatesSuccess(items, paging) {
  return receivePagedResult(type.GET_REPORT_TEMPLATES_RECEIVE, items, paging);
}

export function requestTemplatesFailure(error) {
  return requestFailure(type.GET_REPORT_TEMPLATES_FAILURE, error);
}

export function requestReports(options = {}) {
  return {
    type: type.GET_REPORTS_REQUEST,
    payload: options,
  };
}

export function requestReportsSuccess(items, paging) {
  return receivePagedResult(type.GET_REPORTS_RECEIVE, items, paging);
}

export function requestReportsFailure(error) {
  return requestFailure(type.GET_REPORTS_FAILURE, error);
}

export function requestTemplate(templateId) {
  return {
    type: type.GET_REPORT_TEMPLATE_REQUEST,
    payload: templateId,
  };
}

export function requestTemplateSuccess(template) {
  return {
    type: type.GET_REPORT_TEMPLATE_RECEIVE,
    payload: template,
  };
}

export function requestTemplateFailure(error) {
  return requestFailure(type.GET_REPORT_TEMPLATE_FAILURE, error);
}

export function requestReport(reportId) {
  return {
    type: type.GET_REPORT_REQUEST,
    payload: reportId,
  };
}

export function requestReportSuccess(report) {
  return {
    type: type.GET_REPORT_RECEIVE,
    payload: report,
  };
}

export function requestReportFailure(error) {
  return requestFailure(type.GET_REPORT_FAILURE, error);
}

export function requestReportEvidence(reportId) {
  return {
    type: type.GET_REPORT_EVIDENCE_REQUEST,
    payload: reportId,
  };
}

export function requestReportEvidenceSuccess(evidence) {
  return {
    type: type.GET_REPORT_EVIDENCE_RECEIVE,
    payload: evidence,
  };
}

export function requestReportEvidenceFailure(error) {
  return requestFailure(type.GET_REPORT_EVIDENCE_FAILURE, error);
}

export const requestReportExports = (reportID, options = {}) => {
  return {
    type: type.GET_REPORT_EXPORTS_REQUEST,
    payload: {
      reportID,
      options,
    },
  };
};

export const receiveReportExports = (items, paging) => {
  return receivePagedResult(type.GET_REPORT_EXPORTS_RECEIVE, items, paging);
};

export const requestReportExportsFailure = (err) => {
  return requestFailure(type.GET_REPORT_EXPORTS_FAILURE, err);
};
