import { getCustomer } from "lib/reducers/lookupData.selectors";
import { customerHasApp } from "lib/utility/customerHasApp.util";
import { useSelector } from "store";

export const useCustomerHasApp = (app: string): boolean => {
  const customer = useSelector(getCustomer);
  if (!customer) {
    return false;
  }
  return customerHasApp(customer.apps, app);
};
