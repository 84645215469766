//@ts-nocheck
import moment from "moment";
import Account from "./Account";
import Store from "./Store";
import Logs from "./api/logs";

const error = require("debug")("error");
const warning = require("debug")("warning");
const info = require("debug")("info");
const debug = require("debug")("debug");
const trace = require("debug")("trace");

class Logger {
  get LOG_RETENTION_IN_MINUTES() {
    return 5;
  }

  constructor() {
    this.outbound = Store.collection("logs").remove({});
    this._dispatch = this._dispatch.bind(this);
    this.dispatcher = null;
    this.enableOutgoing = false;
    this.start();
  }

  /**
   * Start sending logs
   */
  start() {
    if (this.dispatcher === null) {
      this.dispatcher = setInterval(this._dispatch, 5000);
    }
  }

  /**
   * Stop sending logs
   */
  stop() {
    clearInterval(this.dispatcher);
    this.dispatcher = null;
  }

  /**
   * Flush outbound queue. Any requeued logs will be dropped.
   */
  async flush() {
    await this._dispatch();
    this.outbound.remove();
  }

  error(message) {
    error(message);
    this._emit("error", message);
  }

  warning(message) {
    warning(message);
    this._emit("warning", message);
  }

  info(message) {
    info(message);
    this._emit("info", message);
  }

  debug(message) {
    debug(message);
    this._emit("debug", message);
  }

  trace(message) {
    trace(message);
    // trace messages are not sent to the backend
  }

  /**
   * Pushes a message into an outbound queue
   * @param level log level
   * @param message log message
   * @private
   */
  _emit(level, message) {
    if (this.enableOutgoing) {
      this.outbound.insert({
        level,
        message: JSON.stringify(message),
        dateCreated: new Date(),
      });
    }
  }

  /**
   * Dispatch logs from the outbound queue to the backend
   * @private
   */
  async _dispatch() {
    // If there is no logs to send, return immediately
    if (this.outbound.size() === 0) {
      return;
    }

    // If the user is not authenticated, return immediately
    if (!this._isUserAuthenticated()) {
      return;
    }

    // Get all outbound logs
    let logs = this.outbound.find();

    // Clear outbound logs
    this.outbound.remove();

    //Dispatch to server
    try {
      await Logs.sendLogs(logs);
    } catch (err) {
      console.error("failed to send logs:", err);

      // Drop old logs
      const now = new Date();
      logs = logs.filter(
        (log) => moment(now).diff(moment(log.dateCreated), "minutes") <= this.LOG_RETENTION_IN_MINUTES,
      );

      // Place logs back into outbound queue
      this.outbound.insert(logs);
    }
  }

  /**
   * Returns true if a session cookie is found
   * @returns {boolean}
   * @private
   */
  _isUserAuthenticated() {
    return !!Account.session();
  }
}

const instance = new Logger();
export default instance;
