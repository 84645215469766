//@ts-nocheck
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import withEvidences from "hocs/withEvidences";
import withLookupData from "hocs/withLookupData";
import moment from "moment";
import QRCodeStyling from "qr-code-styling";
import Logger from "lib/Logger";
import Resolve from "lib/Resolve";
import Avatar from "components/Avatar";
import { PrimaryButton } from "components/Buttons";
import Card from "components/Card";
import { DataGrid, DataItem } from "components/DataGrid";
import Loading from "components/Loading";
import StatusPill from "components/StatusPill";
import Timeline from "components/Timeline";
import { Header } from "components/Typography";
import { Heading, VSpace } from "components/shared";
import PageStage from "components/stages/PageStage";
import style from "./listEvidence.module.scss";

const PageHeader = ({ title, onDownload }) => (
  <Header className={style.header}>
    {title}
    <PrimaryButton className={style.action} onClick={onDownload}>
      Download evidence
    </PrimaryButton>
  </Header>
);

const Fingerprint = ({ evidence }) => {
  const dataHash = evidence && evidence.event && evidence.event.dataHash ? evidence.event.dataHash.value : "";
  const value = dataHash.replace(/(.{2})/g, "$1 ");
  return <DataItem label="Data Fingerprint" value={value} />;
};

const CustodyChain = () => {
  const value = Resolve.resolveCustomerName() || <StatusPill text="Restricted" color="danger" />;
  return <DataItem label="Custody Chain" value={<span>{value} =&gt; Automify =&gt; Guardtime</span>} />;
};

const BlockchainEntry = ({ evidence }) => {
  const value = evidence && evidence.signature ? evidence.signature.id : "N/A";
  return <DataItem label="Blockchain Entry" value={value} />;
};

const prepareTimeline = (evidence) => {
  if (!evidence) {
    return [];
  }
  const dateReceived = moment(evidence.dateCreated).format("MMM Do YYYY - h:mm:ss A");
  const signingTime = evidence.signature
    ? moment(evidence.signature?.signingTime).format("MMM Do YYYY - h:mm:ss A")
    : "";

  let eventDateCreated = <StatusPill text="Restricted" color="danger" />;
  try {
    if (evidence.event.data) {
      const { data } = JSON.parse(atob(evidence.event.data));
      eventDateCreated = moment(data.dateCreated).format("MMM Do YYYY - h:mm:ss A");
    }
  } catch (err) {
    Logger.error(err);
  }

  return [
    {
      name: "Automify received data",
      description: eventDateCreated,
    },
    {
      name: "Data fingerprint sent to Guardtime",
      description: eventDateCreated,
    },
    {
      name: "Guardtime included data fingerprint in Blockchain",
      description: signingTime,
    },
    {
      name: "Evidence received by Automify",
      description: dateReceived,
    },
  ];
};

const ListEvidence = ({ actions, stateStore, className }) => {
  const params = useParams();
  const evidenceId = params.evidenceId;
  const evidence = stateStore.evidence.data;
  const [qrCode, setQRCode] = useState<QRCodeStyling>();
  const [QRImageUrl, setQRImageUrl] = useState<string>();
  useEffect(() => {
    actions.requestEvidence(evidenceId);
  }, [evidenceId]);

  const generateQRImage = async (id: string) => {
    const qrCode = new QRCodeStyling({
      width: 512,
      height: 512,
      type: "svg",
      data: `${window.location.origin}/evidences/${id}`,
      image: "/images/qr-logo.png",
      margin: 16,
      qrOptions: {
        errorCorrectionLevel: "H",
      },
      dotsOptions: {
        color: "#854dff",
        type: "extra-rounded",
      },
      backgroundOptions: {
        color: "transparent",
      },
    });
    setQRCode(qrCode);

    const blob = await qrCode.getRawData("png");
    const reader = new FileReader();
    reader.onloadend = () => {
      setQRImageUrl(reader.result);
    };
    reader.readAsDataURL(blob);
  };

  useEffect(() => {
    generateQRImage(evidenceId);
  }, [evidenceId]);

  const downloadQR = async () => {
    if (!qrCode || !evidence) {
      return;
    }
    await qrCode.download({
      name: `Automify evidence QR code - ${evidenceId}`,
      extension: "png",
    });
  };

  const downloadSignature = () => {
    if (evidence) {
      const a = document.createElement("a");
      const payload = {
        ...evidence,
        qrCodeImage: undefined,
        event: {
          ...evidence.event,
          data: evidence.event.data ? JSON.parse(atob(evidence.event.data)) : null,
        },
      };
      a.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(payload));
      a.download = `evidence-${evidenceId}.json`;
      a.click();
    }
  };

  return (
    <div className={classNames(style.container, className)}>
      <div className={style.stage}>
        {evidence ? (
          <PageStage>
            <VSpace gap="gap-8">
              <PageHeader title="Digital evidence" onDownload={downloadSignature} />
              <Card>
                <VSpace gap="gap-4">
                  {evidence ? (
                    <DataGrid>
                      <DataItem
                        label="Public QR"
                        value={
                          <Avatar
                            image={QRImageUrl}
                            alt=""
                            hoverIcon="/images/icons/download.svg"
                            onClick={downloadQR}
                          />
                        }
                      />
                      <DataItem label="Status" value={<StatusPill text="Protected" color="success" />} />
                      <Fingerprint evidence={evidence} />
                      <CustodyChain evidence={evidence} />
                      <BlockchainEntry evidence={evidence} />
                    </DataGrid>
                  ) : null}

                  <Heading level="h3">Timeline</Heading>
                  <Timeline events={prepareTimeline(evidence)} />
                </VSpace>
              </Card>
            </VSpace>
          </PageStage>
        ) : (
          <Loading size="large" />
        )}
      </div>
    </div>
  );
};

export default withEvidences(withLookupData(ListEvidence));
