import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { TRagBin, UUID } from "types";

const selectState = (state: RootStore) => state.ragBins;

export const selectRagBins = createSelector(selectState, (state) => state.data);

export const selectRagBinsList = createSelector(selectRagBins, (data) => Object.values(data));

export const selectRagBinByID = (id: UUID) => createSelector(selectRagBins, (data): TRagBin | undefined => data[id]);

export const selectRagBinByStorageID = (storageId: string) =>
  createSelector(selectRagBinsList, (list) => list.find((entity) => entity.storageId === storageId));
