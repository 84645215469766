//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import Logger from "../Logger";
import * as type from "../actions/actionTypes";
import * as actions from "../actions/evidences";
import Api from "../api/evidences";

function* fetchEvidence(action) {
  try {
    const evidence = yield Api.getEvidence(action.payload);
    yield put(actions.requestEvidenceSuccess(evidence));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestEvidenceFailure(err));
  }
}

function* evidencesSaga() {
  yield takeLatest(type.GET_EVIDENCE_REQUEST, fetchEvidence);
}

export default evidencesSaga;
