import { AlertEventKey, UUID } from "types";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import { AlertRuleInput } from "components/shared/features/alerts/AlertRuleInput/AlertRuleInput";

type AlertRulesFormProps = {
  alertId: UUID;
  ruleKeys: AlertEventKey[];
};

export const AlertRulesForm = ({ alertId, ruleKeys }: AlertRulesFormProps) => {
  return (
    <DataGrid className="gap-y-12">
      <DataItem
        label="Triggers"
        value={
          <DataItemGroup>
            {ruleKeys.map((ruleKey) => (
              <AlertRuleInput alertId={alertId} ruleKey={ruleKey} />
            ))}
          </DataItemGroup>
        }
      />
    </DataGrid>
  );
};
