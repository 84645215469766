//@ts-nocheck
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as actions from "../lib/actions/reportBuilder";
import mergeProps from "./mergeProps";
import mergeStateStore from "./mergeStateStore";

export default function withReportBuilder(WrappedComponent) {
  class Component extends React.Component {
    static propTypes = {
      actions: PropTypes.shape({
        selectTemplate: PropTypes.func,
        selectTheme: PropTypes.func,
        createReport: PropTypes.func,
        setElementContent: PropTypes.func,
        resetReportBuilder: PropTypes.func,
        requestReportComponents: PropTypes.func,
      }),
      stateStore: PropTypes.shape({
        templates: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
        }),
        components: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
        }),
        themes: PropTypes.shape({
          fetching: PropTypes.bool,
          error: PropTypes.object,
          data: PropTypes.array,
        }),
        template: PropTypes.object,
        theme: PropTypes.object,
        current: PropTypes.object,
      }),
    };
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToProps = (state) =>
    mergeStateStore(state, {
      reportBuilder: state.reportBuilder,
    });
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
      {
        ...actions,
      },
      dispatch,
    ),
  });
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Component);
}
