import { createAction } from "@reduxjs/toolkit";
import { TToolStoreOrder } from "types";

export const streamCreateToolStoreOrder = createAction("toolStoreOrders/streamCreate", (data: TToolStoreOrder) => ({
  payload: data,
}));

export const streamUpdateToolStoreOrder = createAction("toolStoreOrders/streamUpdate", (data: TToolStoreOrder) => ({
  payload: data,
}));
