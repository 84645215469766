import { Link } from "react-router-dom";
import { useSelector } from "store";
import { selectVehiclesLoading } from "store/reducers";
import Loading from "components/Loading";
import { SecondaryButton } from "components/shared/buttons";
import { VehicleList } from "components/shared/features/vehicles/VehicleList/VehicleList";

export const VehiclesCard = () => {
  const loading = useSelector(selectVehiclesLoading);
  return (
    <div className="rounded-xl bg-white shadow-2xl flex flex-col flex-grow min-h-0">
      <div className="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Vehicles</h3>
          <p className="flex items-center gap-1 mt-1 text-sm text-gray-500">Realtime</p>
        </div>
        <div>
          <Link to="/apps/vehicles/manage">
            <SecondaryButton>Manage</SecondaryButton>
          </Link>
        </div>
      </div>
      <div className="flex-grow overflow-auto">
        {loading ? (
          <div className="text-center mt-4">
            <Loading />
          </div>
        ) : (
          <VehicleList />
        )}
      </div>
    </div>
  );
};
