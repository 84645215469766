//@ts-nocheck
import React from "react";
import { Portal } from "react-portal";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { showMediaMenuFor } from "lib/actions/blocks";
import BlockTypes from "lib/constants/BlockTypes";
import { getBlockMediaMenu, getCurrentBlockID, getPageBlocks } from "lib/reducers/blocks.selectors";
import Card from "components/Card";
import KeyHandler, { KEY_CODE_ESC } from "components/KeyHandler";
import Overlay from "components/Overlay";
import FileBlockMediaMenu from "components/Sheet/components/FileBlockMediaMenu";
import PortalMenu from "components/Sheet/components/PortalCommon";
import WebLinkBlockMediaMenu from "components/Sheet/components/WebLinkBlockMediaMenu";
import YouTubeBlockMediaMenu from "components/Sheet/components/YouTubeBlockMediaMenu";
import style from "../sheet.module.scss";

const MediaMenu = ({ parentNode, block }) => {
  return block ? (
    <PortalMenu parentNode={parentNode}>
      <Card className={style.blockMediaMenuCard}>
        {block.type === BlockTypes.YOUTUBE && <YouTubeBlockMediaMenu block={block} />}
        {block.type === BlockTypes.FILE && <FileBlockMediaMenu block={block} />}
        {block.type === BlockTypes.LINK && <WebLinkBlockMediaMenu block={block} />}
      </Card>
    </PortalMenu>
  ) : null;
};

const MediaMenuPortal = ({ className }) => {
  const dispatch = useDispatch();
  const node = useSelector(getBlockMediaMenu);
  const pageBlocks = useSelector(getPageBlocks);
  const currentBlockID = useSelector(getCurrentBlockID);
  const currentPageBlock = pageBlocks[currentBlockID];

  const handleClose = () => {
    dispatch(showMediaMenuFor(null));
  };

  return (
    node &&
    currentPageBlock && (
      <Portal>
        <Overlay onClick={handleClose} isTransparent={true} className={style.overlay} />
        <KeyHandler keyCode={KEY_CODE_ESC} onKeyHandle={handleClose} />
        <MediaMenu parentNode={node} block={currentPageBlock.block} />
      </Portal>
    )
  );
};

MediaMenuPortal.defaultProps = {};

MediaMenuPortal.propTypes = {
  className: PropTypes.string,
};

export default MediaMenuPortal;
