//@ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import PropTypes from "prop-types";
import MarketplaceAPI from "lib/api/Marketplace";
import ShopImportColumnTypes from "lib/constants/ShopImportColumnTypes";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { SmartTable, SmartTableNav, SmartTablePagination } from "components/Table/SmartTable";

const ShopInventory = () => {
  const [paging, setPaging] = useState({
    pageStart: 0,
    pageLimit: 10,
  });
  const [fetching, setFetching] = useState(false);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [storeItems, setStoreItems] = useState([]);
  const rowData = useMemo(() => storeItems, [storeItems]);
  const columns = useMemo(() => {
    return [
      {
        Header: ShopImportColumnTypes.resolveItemLabel(ShopImportColumnTypes.NAME),
        accessor: ({ row }) => get(row, "original.name", ""),
        Cell: ({ row }) => {
          const { id, name } = row.original;
          return <Link to={`/apps/shop_manager/items/${id}`}>{name}</Link>;
        },
      },
      {
        Header: ShopImportColumnTypes.resolveItemLabel(ShopImportColumnTypes.SKU),
        accessor: ({ row }) => get(row, "original.sku", ""),
        Cell: ({ row }) => {
          const { sku } = row.original;
          return <div>{sku}</div>;
        },
      },
      {
        Header: ShopImportColumnTypes.resolveItemLabel(ShopImportColumnTypes.PRICE_EXC_VAT),
        accessor: ({ row }) => get(row, "original.priceExcVat", ""),
        Cell: ({ row }) => {
          const { priceExcVat } = row.original;
          return <div>{priceExcVat}</div>;
        },
      },
      {
        Header: ShopImportColumnTypes.resolveItemLabel(ShopImportColumnTypes.QUANTITY),
        accessor: ({ row }) => get(row, "original.quantity", ""),
        Cell: ({ row }) => {
          const { quantity } = row.original;
          return <div>{quantity}</div>;
        },
      },
      {
        Header: ShopImportColumnTypes.resolveItemLabel(ShopImportColumnTypes.DISCOUNT),
        accessor: ({ row }) => get(row, "original.discount", ""),
        Cell: ({ row }) => {
          const { discount } = row.original;
          return <div>{discount}</div>;
        },
      },
    ];
  }, []);

  useEffect(() => {
    fetch(0, paging.pageLimit);
  }, [paging.pageLimit, query, filters]);

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart]);

  const fetch = async (pageStart, pageLimit) => {
    setFetching(true);
    try {
      const { data, metadata } = await MarketplaceAPI.getStoreItems({
        pageStart,
        pageLimit,
        query: query || undefined,
      });
      setStoreItems(data);
      setPaging(metadata);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setFetching(false);
  };

  return (
    <>
      <SmartTableNav
        paging={paging}
        onPageChange={fetch}
        showQueryInput={true}
        queryPlaceholder="Search by product name or SKU"
        onQueryChange={setQuery}
      ></SmartTableNav>
      <SmartTable columns={columns} data={rowData} />
      <SmartTablePagination paging={paging} onChange={fetch} />
    </>
  );
};

ShopInventory.defaultProps = {};

ShopInventory.propTypes = {
  className: PropTypes.string,
};

export default ShopInventory;
