//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import MarketplaceAPI from "lib/api/Marketplace";
import Logger from "../Logger";
import * as types from "../actions/actionTypes";
import * as actions from "../actions/marketplace";

function* fetchMany(action) {
  try {
    const { data, metadata } = yield MarketplaceAPI.getStoreItems(action.payload);
    yield put(actions.fulfillRequestStoreItems(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectRequestStoreItems(err));
  }
}

function* fetchOne(action) {
  try {
    const data = yield MarketplaceAPI.getStoreItem(action.payload);
    yield put(actions.fulfilRequestStoreItem(data));
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectRequestStoreItem(err));
  }
}

function* createOne(action) {
  try {
    const data = yield MarketplaceAPI.createStoreItem(action.payload);
    yield put(actions.fulfilCreateStoreItem(data));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectCreateStoreItem(err));
    action.onFailed && action.onFailed(err);
  }
}

function* updateOne(action) {
  try {
    const data = yield MarketplaceAPI.updateStoreItem(action.payload.id, action.payload);
    yield put(actions.fulfilUpdateStoreItem(data));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectUpdateStoreItem(err));
    action.onFailed && action.onFailed(err);
  }
}

function* deleteImage(action) {
  try {
    const { itemID, imageID } = action.payload;
    const data = yield MarketplaceAPI.deleteStoreItemImage(itemID, imageID);
    yield put(actions.fulfilRemoveStoreItemImage(itemID, imageID));
    action.onSuccess && action.onSuccess(data);
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectRemoveStoreItemImage(err));
    action.onFailed && action.onFailed(err);
  }
}

function* marketplaceSaga() {
  yield takeLatest(types.GET_STORE_ITEMS_REQUEST, fetchMany);
  yield takeLatest(types.GET_STORE_ITEM_REQUEST, fetchOne);
  yield takeLatest(types.CREATE_STORE_ITEM_REQUEST, createOne);
  yield takeLatest(types.UPDATE_STORE_ITEM_REQUEST, updateOne);
  yield takeLatest(types.DELETE_STORE_ITEM_IMAGE_REQUEST, deleteImage);
}

export default marketplaceSaga;
