import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { fetchAssetProducts } from "store/reducers";
import { TAssetProduct, UUID } from "types";
import { SelectInput } from "components/Input";
import { Text } from "components/Typography";

type AssetProductSelectorProps = {
  name: string;
  value?: TAssetProduct | UUID;
  onChange: (name: string, value: TAssetProduct) => void;
  placeholder?: string;
  appearance?: "inline" | "normal";
  menuPlacement?: string;
  pageLimit?: number;
  className?: string;
};

export const AssetProductSelector = ({
  name,
  value,
  onChange,
  placeholder = "Select a product",
  appearance = "normal",
  className,
  menuPlacement,
  pageLimit = 25,
}: AssetProductSelectorProps) => {
  const dispatch = useDispatch();

  const formatOption = (entity: TAssetProduct) => ({
    ...entity,
    label: entity.name,
  });
  const fetch = async (inputValue?: string) => {
    try {
      const { results } = await dispatch(
        fetchAssetProducts({
          pageLimit,
          search_term: inputValue,
        }),
      ).unwrap();
      return results.map((entity: TAssetProduct) => formatOption(entity));
    } catch (err) {
      handlePopupFlagError(err);
      return [];
    }
  };

  const input = {
    type: "select",
    name,
    placeholder,
    labelKey: "label",
    valueKey: "id",
    menuPlacement,
    isSearchable: true,
    async: true,
    loadOptions: fetch,
    defaultOptions: true,
  };

  return appearance === "inline" ? (
    <Text
      canEdit
      input={input}
      value={value as any}
      onConfirm={(value) => onChange(name, value)}
      className={className}
    />
  ) : (
    <SelectInput input={input} value={value} onChange={onChange} className={className} />
  );
};
