import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import * as jwt from "jose";
import Account from "lib/Account";
import Store from "lib/Store";
import { completePasswordRecovery, login } from "store/reducers/accounts/accounts.thunks";
import { AuthSession, LoginResponse } from "types";

type StateAuthSession = AuthSession & {
  authToken: string;
};

const hydrateSession = (): StateAuthSession | undefined => {
  const session: StateAuthSession = Store.collection("session").findOne();
  if (!session) {
    return;
  }
  // If the session has expired, remove it
  if (session && session.exp * 1000 <= Date.now()) {
    Store.collection("session").remove();
    return;
  }
  return session;
};

export interface AccountsReducerState {
  session?: StateAuthSession;
}

const initialState: AccountsReducerState = {
  session: hydrateSession(),
};

const setToken = (state: Draft<AccountsReducerState>, token: string) => {
  const session: AuthSession = jwt.decodeJwt(token) as AuthSession;
  const stateSession: StateAuthSession = {
    ...session,
    authToken: token,
  };
  state.session = stateSession;
  Store.collection("session").insert(stateSession);
};

const handleLogin = (state: Draft<AccountsReducerState>, action: PayloadAction<LoginResponse>) => {
  const { token } = action.payload;
  setToken(state, token);
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    logout: (state, _: PayloadAction) => {
      if (state.session) {
        state.session = undefined;
      }
      Account.removeSession();
      Store.collection("session").remove();
      Store.collection("roles").remove();
      Store.collection("logs").remove();
      Store.collection("projects").remove();
      Store.collection("branding").remove();
      Store.collection("me").remove();
      Store.collection("customer").remove();
      Store.collection("users").remove();
      window.location.href = "/login";
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      setToken(state, action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(login.fulfilled, handleLogin).addCase(completePasswordRecovery.fulfilled, handleLogin);
  },
});

export const { logout, setAuthToken } = accountsSlice.actions;

export const accountsReducer = accountsSlice.reducer;
