//@ts-nocheck
import React from "react";
import withUsers from "hocs/withUsers";
import PropTypes from "prop-types";
import Logger from "lib/Logger";
import Resolve from "lib/Resolve";
import Api from "lib/api/users";
import { SelectInput } from "components/Input";

class UsersInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  state = {
    users: [],
  };

  componentDidMount() {
    this.fetch();
  }

  async fetch() {
    try {
      const { data } = await Api.getUsers({
        pageStart: 0,
        pageLimit: 999,
      });
      this.setState({
        users: data,
      });
    } catch (err) {
      Logger.error(err);
    }
  }

  prepareUsers(users) {
    if (!Array.isArray(users)) {
      Logger.error("expected users to be an array");
      return [];
    }
    return users.map((user) => {
      return {
        label: Resolve.resolveUserFullName(user.id),
        id: user.id,
      };
    });
  }

  render() {
    const { name, value, onChange, className } = this.props;
    const { users } = this.state;
    return (
      <SelectInput
        input={{
          name,
          placeholder: "Select team member",
          valueKey: "id",
          options: this.prepareUsers(users),
        }}
        value={value}
        onChange={onChange}
        className={className}
      />
    );
  }
}

export default withUsers(UsersInput);
