import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { requestProject } from "lib/actions/projects";
import { getProjectByID } from "lib/reducers/projects.selectors";
import Loading from "components/Loading";
import { ProjectSheet } from "components/shared/features/projects";
import { PageStage } from "components/stages/PageStage";

export const ListProject = () => {
  const dispatch = useDispatch();
  const { projectID = "" } = useParams();
  const project = useSelector(getProjectByID(parseInt(projectID)));

  useEffect(() => {
    fetch();
  }, [projectID]);

  const fetch = async () => {
    dispatch(requestProject(projectID));
  };

  return (
    <PageStage appearance="sheet">{project ? <ProjectSheet project={project} /> : <Loading size="large" />}</PageStage>
  );
};
