//@ts-nocheck
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import debounce from "lib/utility/debouce";
import { FixLater } from "types";
import ComplexInput from "components/Input";
import KeyHandler, { KEY_CODE_ENTER, KEY_CODE_ESC } from "components/KeyHandler";
import InlineControls from "components/Typography/InlineControls";
import style from "components/Typography/typography.module.scss";

const InlineInput = ({ defaultValue, input, onClick, onConfirm, clearOnConfirm, onKeyPress, className }: FixLater) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const [isEditing, setEditing] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (currentValue !== defaultValue && !isEditing) {
      if (typeof onConfirm === "function") {
        dirty && onConfirm(currentValue);
        setDirty(false);
        if (clearOnConfirm) {
          setCurrentValue("");
        }
      }
    }
  }, [currentValue, isEditing]);

  const handleChange = (_, newValue) => {
    if (onKeyPress) {
      newValue = onKeyPress(newValue);
    }
    setDirty(true);
    setCurrentValue(newValue);
  };

  const handleClick = (e) => {
    if (!isEditing) setEditing(true);
    if (typeof onClick === "function") onClick(e);
  };

  const handleBlur = () => {
    setEditing(false);
  };

  const handleCancel = () => {
    setCurrentValue(defaultValue);
    setEditing(false);
  };

  const baseClassName = classNames(style.inlineInputContainer, { [style.isEditing]: isEditing }, className);

  const renderTextValue = () => {
    if (!currentValue) {
      return input ? <span className="text-muted">{input.placeholder}</span> : "";
    }
    let textValue;
    if (typeof currentValue === "object") {
      textValue = input && input.labelKey ? currentValue[input.labelKey] : currentValue["label"];
    } else {
      textValue = currentValue;
    }
    const isPassword = input.type === "password";
    return (
      <span
        className={classNames({
          [style.emulatePassword]: isPassword && !isEditing,
        })}
      >
        {isPassword && !isEditing ? textValue.replace(/./g, "*") : textValue}
      </span>
    );
  };

  return (
    <div className={baseClassName} onClick={handleClick}>
      {isEditing ? (
        <ComplexInput
          input={{ ...input, autoFocus: true }}
          value={currentValue}
          className={style.inlineInput}
          onBlur={debounce(handleBlur, 100)}
          onChange={handleChange}
        />
      ) : (
        renderTextValue()
      )}
      {isEditing && (
        <React.Fragment>
          <InlineControls onConfirm={handleBlur} onCancel={handleCancel} />
          <KeyHandler keyCode={KEY_CODE_ESC} onKeyHandle={handleCancel} />
          {input.type !== "textarea" && <KeyHandler keyCode={KEY_CODE_ENTER} onKeyHandle={handleBlur} />}
        </React.Fragment>
      )}
    </div>
  );
};

InlineInput.defaultProps = {
  input: {
    type: "text",
    labelKey: "label",
  },
};

InlineInput.propTypes = {
  defaultValue: PropTypes.any,
  input: PropTypes.object,
  onConfirm: PropTypes.func,
  clearOnConfirm: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default InlineInput;
