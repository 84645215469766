//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Resolve from "lib/Resolve";
import { Text } from "components/Typography";
import { SelectInput } from "../../Input";

class SelectStorageModelInput extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    appearance: PropTypes.oneOf(["normal", "inline"]),
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    appearance: "normal",
    placeholder: "Select toolbox type",
  };

  render() {
    const { name, value, onChange, placeholder, appearance, className, ...otherProps } = this.props;
    const input = {
      type: "select",
      name,
      placeholder: placeholder,
      options: Resolve.getStorageModels(),
    };
    return appearance === "inline" ? (
      <Text
        {...otherProps}
        value={value}
        canEdit={true}
        input={input}
        onConfirm={(value) => onChange(name, value)}
        className={className}
      />
    ) : (
      <SelectInput {...otherProps} input={input} value={value} onChange={onChange} className={className} />
    );
  }
}

export default SelectStorageModelInput;
