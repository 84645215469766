//@ts-nocheck
import * as type from "./actionTypes";
import { receivePagedResult, receiveSingleResult, requestFailure, requestInitiateWithCallback } from "./common";

export const requestProjects = (options, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.GET_PROJECTS_REQUEST, options, onSuccess, onFailed);
export const fulfilRequestProjects = (item) => receivePagedResult(type.GET_PROJECTS_SUCCESS, item);
export const rejectRequestProjects = (err) => requestFailure(type.GET_PROJECTS_FAILURE, err);

export const updateProject = (payload, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.UPDATE_PROJECT_REQUEST, payload, onSuccess, onFailed);
export const fulfilUpdateProject = (payload) => receiveSingleResult(type.UPDATE_PROJECT_FULFIL, payload);
export const rejectUpdateProject = (err) => requestFailure(type.UPDATE_PROJECT_REJECT, err);

export function requestProject(projectId) {
  return {
    type: type.GET_PROJECT_REQUEST,
    payload: projectId,
  };
}
export function requestProjectSuccess(project) {
  return {
    type: type.GET_PROJECT_SUCCESS,
    payload: project,
  };
}
export function requestProjectFailure(error) {
  return requestFailure(type.GET_PROJECT_FAILURE, error);
}

export function requestProjectBlocks(projectID, options = {}) {
  return {
    type: type.GET_PROJECT_BLOCKS_REQUEST,
    payload: {
      projectID,
      options,
    },
  };
}
export function requestProjectBlocksSuccess(projectBlocks, paging) {
  return receivePagedResult(type.GET_PROJECT_BLOCKS_RECEIVE, projectBlocks, paging);
}
export function requestProjectBlocksFailure(error) {
  return requestFailure(type.GET_PROJECT_BLOCKS_FAILURE, error);
}
