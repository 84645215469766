import { createSlice } from "@reduxjs/toolkit";
import { fetchCaseScannerHistoryEvents } from "store/reducers/caseScannerHistoryEvents/caseScannerHistoryEvents.thunks";
import { TCaseScannerHistoryEvent, UUID } from "types";

export type CaseScannerHistoryEventsReducerStateData = Record<UUID, TCaseScannerHistoryEvent>;

export interface CaseScannerHistoryEventsReducerState {
  data: CaseScannerHistoryEventsReducerStateData;
}

const initialState: CaseScannerHistoryEventsReducerState = {
  data: {},
};

export const caseScannerHistoryEventsSlice = createSlice({
  name: "caseScannerHistoryEvents",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchCaseScannerHistoryEvents.fulfilled, (state, action) => {
      state.data = action.payload.results.reduce(
        (data: CaseScannerHistoryEventsReducerStateData, value: TCaseScannerHistoryEvent) => ({
          ...data,
          [value.id]: value,
        }),
        state.data,
      );
    });
  },
});

export const caseScannerHistoryEventsReducer = caseScannerHistoryEventsSlice.reducer;
