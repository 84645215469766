import { createSlice, Draft } from "@reduxjs/toolkit";
import {
  createAlertRecipient,
  deleteAlertRecipient,
  updateAlertRecipient,
} from "store/reducers/alertRecipients/alertRecipients.thunks";
import {
  fetchAlertById,
  fetchAlerts,
  createAlert,
  deleteAlert,
  updateAlert,
} from "store/reducers/alerts/alerts.thunks";
import {
  fetchStorageAlertById,
  createStorageAlert,
  updateStorageAlert,
} from "store/reducers/storageAlerts/storageAlerts.thunks";
import { TAlert, TAlertRecipient, UUID } from "types";

export type AlertRecipientsReducerStateData = Record<UUID, TAlertRecipient>;

export interface AlertRecipientsReducerState {
  data: AlertRecipientsReducerStateData;
}

const initialState: AlertRecipientsReducerState = {
  data: {},
};

const handleRecipientsFromAlert = (state: Draft<AlertRecipientsReducerState>, alert?: TAlert) => {
  if (alert?.recipients) {
    state.data = alert?.recipients?.reduce(
      (data: AlertRecipientsReducerStateData, value: TAlertRecipient) => ({
        ...data,
        [value.id]: value,
      }),
      state.data,
    );
  }
};

export const alertRecipientsSlice = createSlice({
  name: "alertRecipients",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createAlertRecipient.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateAlertRecipient.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteAlertRecipient.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg.id];
        if (entity) {
          delete state.data[entity.id];
        }
      })
      .addCase(fetchAlerts.fulfilled, (state, action) => {
        action.payload.results.forEach((alert: TAlert) => {
          handleRecipientsFromAlert(state, alert);
        });
      })
      .addCase(fetchAlertById.fulfilled, (state, action) => {
        handleRecipientsFromAlert(state, action.payload);
      })
      .addCase(createAlert.fulfilled, (state, action) => {
        handleRecipientsFromAlert(state, action.payload);
      })
      .addCase(updateAlert.fulfilled, (state, action) => {
        handleRecipientsFromAlert(state, action.payload);
      })
      .addCase(deleteAlert.fulfilled, (state, action) => {
        // TODO: Remove all recipients belonging to the alert
      })
      .addCase(fetchStorageAlertById.fulfilled, (state, action) => {
        handleRecipientsFromAlert(state, action.payload.alert);
      })
      .addCase(createStorageAlert.fulfilled, (state, action) => {
        handleRecipientsFromAlert(state, action.payload.alert);
      })
      .addCase(updateStorageAlert.fulfilled, (state, action) => {
        handleRecipientsFromAlert(state, action.payload.alert);
      });
  },
});

export const alertRecipientsReducer = alertRecipientsSlice.reducer;
