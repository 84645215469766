import { ReactNode } from "react";
import { Dialog } from "@headlessui/react";
import classNames from "classnames";

type SlideOverProps = {
  width: "max-w-xs" | "max-w-sm" | "max-w-md" | "max-w-lg";
  children: ReactNode;
  className?: string;
};

export const SlideOver = ({ width, className, children }: SlideOverProps) => {
  return (
    <>
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Dialog.Panel className={classNames(`pointer-events-auto w-screen ${width}`, className)}>
              {children}
            </Dialog.Panel>
          </div>
        </div>
      </div>
    </>
  );
};
