//@ts-nocheck
import * as type from "./actionTypes";
import { requestFailure } from "./common";

export const STORE_LOOKUP_VALUE = "STORE_LOOKUP_VALUE";

export function storeLookup(collectionName, value) {
  return {
    type: STORE_LOOKUP_VALUE,
    collectionName,
    payload: value,
  };
}

export function requestLookups() {
  return {
    type: type.GET_LOOKUPS_REQUEST,
  };
}

export function receiveLookups(data) {
  return {
    type: type.GET_LOOKUPS_RECEIVE,
    payload: data,
  };
}

export function requestLookupsFailure(error) {
  return requestFailure(type.GET_LOOKUPS_FAILURE, error);
}

export function requestRoles() {
  return {
    type: type.GET_ROLES_REQUEST,
  };
}

export function receiveRoles(roles) {
  return {
    type: type.GET_ROLES_RECEIVE,
    payload: roles,
  };
}

export function requestRolesFailure(error) {
  return requestFailure(type.GET_ROLES_FAILURE, error);
}

export function requestProjects() {
  return {
    type: type.GET_LOOKUP_PROJECTS_REQUEST,
  };
}

export function receiveProjects(tasks) {
  return {
    type: type.GET_LOOKUP_PROJECTS_RECEIVE,
    payload: tasks,
  };
}

export function requestProjectsFailure(error) {
  return requestFailure(type.GET_LOOKUP_PROJECTS_FAILURE, error);
}

export function requestAccountSettings() {
  return {
    type: type.GET_ACCOUNT_SETTINGS_REQUEST,
  };
}

export function receiveAccountSettings(settings) {
  return {
    type: type.GET_ACCOUNT_SETTINGS_RECEIVE,
    payload: settings,
  };
}

export function requestAccountSettingsFailure(error) {
  return requestFailure(type.GET_ACCOUNT_SETTINGS_FAILURE, error);
}

export function requestCompanyBranding() {
  return {
    type: type.GET_COMPANY_BRANDING_REQUEST,
  };
}

export function receiveCompanyBranding(banding) {
  return {
    type: type.GET_COMPANY_BRANDING_RECEIVE,
    payload: banding,
  };
}

export function requestCompanyBrandingFailure(error) {
  return requestFailure(type.GET_COMPANY_BRANDING_FAILURE, error);
}
