//@ts-nocheck
import * as type from "./actionTypes";

export function requestTags(options = {}, skipClear = false) {
  return {
    type: type.REQUEST_TAGS,
    payload: options,
    skipClear,
  };
}

export function receiveTags(tags, totalCount, page, limit) {
  return {
    type: type.RECEIVE_TAGS,
    tags,
    totalCount,
    page,
    limit,
  };
}

export function requestTagsFailure(error) {
  const payload = typeof error === "string" ? new Error(error) : error;
  return {
    type: type.REQUEST_TAGS_FAILURE,
    error: payload,
  };
}

export function requestTag(epc) {
  return {
    type: type.REQUEST_TAG,
    payload: epc,
  };
}

export function receiveTag(tag) {
  return {
    type: type.RECEIVE_TAG,
    tag,
  };
}

export function requestTagFailure(error) {
  const payload = typeof error === "string" ? new Error(error) : error;
  return {
    type: type.REQUEST_TAG_FAILURE,
    error: payload,
  };
}
