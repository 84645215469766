//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import UserApi from "lib/api/users";
import Logger from "../Logger";
import {
  REQUEST_USERS,
  REQUEST_USER,
  GET_ROLES_REQUEST,
  GET_ROLE_REQUEST,
  GET_KEYCARD_REQUEST,
  GET_KEYCARDS_REQUEST,
} from "../actions/actionTypes";
import * as actions from "../actions/users";

function* fetchUser(action) {
  try {
    const user = yield UserApi.getUser(action.payload);
    yield put(actions.receiveUser(user));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestUserFailure(err));
  }
}
function* fetchRole(action) {
  try {
    const result = yield UserApi.getRole(action.payload);
    yield put(actions.receiveRole(result));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestRoleFailure(err));
  }
}

function* fetchUsers(action) {
  try {
    const { data, metadata } = yield UserApi.getUsers(action.payload);
    yield put(actions.receiveUsers(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestUsersFailure(err));
  }
}

function* fetchRoles(action) {
  try {
    const { data, metadata } = yield UserApi.getRoles(action.payload);
    yield put(actions.receiveRoles(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestRolesFailure(err));
  }
}

function* fetchKeycards(action) {
  try {
    const { data, metadata } = yield UserApi.getKeycards(action.payload);
    yield put(actions.receiveKeycards(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestKeycardsFailure(err));
  }
}

function* fetchKeycard(action) {
  try {
    const result = yield UserApi.getKeycard(action.payload);
    yield put(actions.receiveKeycard(result));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestKeycardFailure(err));
  }
}

function* usersSaga() {
  yield takeLatest(REQUEST_USER, fetchUser);
  yield takeLatest(GET_ROLE_REQUEST, fetchRole);
  yield takeLatest(REQUEST_USERS, fetchUsers);
  yield takeLatest(GET_ROLES_REQUEST, fetchRoles);
  yield takeLatest(GET_KEYCARD_REQUEST, fetchKeycard);
  yield takeLatest(GET_KEYCARDS_REQUEST, fetchKeycards);
}

export default usersSaga;
