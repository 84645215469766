import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { fetchAssetById, selectAssetByID } from "store/reducers";
import { PageLoading } from "components/shared";
import PageStage from "components/stages/PageStage";
import { AssetFinancials } from "pages/assets/ListAsset/components/AssetFinancials/AssetFinancials";
import { AssetTracking } from "pages/assets/ListAsset/components/AssetTracking/AssetTracking";
import AssetCalibration from "./components/AssetCalibration/AssetCalibration";
import AssetDetails from "./components/AssetDetails/AssetDetails";
import AssetHistoryContainer from "./components/AssetHistory/AssetHistoryContainer";
import AssetSettings from "./components/AssetSettings";

export const ListAssetPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { assetID, section } = params;
  const assetId = parseInt(assetID || "");
  const asset = useSelector(selectAssetByID(assetId));

  useEffect(() => {
    if (isNaN(assetId)) return;
    dispatch(fetchAssetById(assetId));
  }, [assetId]);

  if (!asset) return <PageLoading />;

  const renderPage = () => {
    switch (section) {
      case "summary":
        return <AssetDetails assetId={assetId} />;
      case "tracking":
        return <AssetTracking assetId={assetId} />;
      case "calibration":
        return <AssetCalibration assetId={assetId} />;
      case "history":
        return <AssetHistoryContainer asset={asset} />;
      case "financials":
        return <AssetFinancials assetId={assetId} />;
      case "settings":
        return <AssetSettings assetId={assetId} />;
    }
  };

  return <PageStage>{renderPage()}</PageStage>;
};
