//@ts-nocheck
import Store from "./Store";

export default class Preferences {
  static SIDE_BAR_MAIN_COLLAPSED = "SIDE_BAR_MAIN_COLLAPSED";

  static get collection() {
    return Store.collection("preferences");
  }

  static preferenceExists(name) {
    return !!this.collection.findOne({ name });
  }

  static setPreference(name) {
    this.collection.insert({ name });
  }

  static clearPreference(name) {
    this.collection.remove({ name });
  }
}

if (process.env.NODE_ENV !== "production") {
  window.Preferences = Preferences;
}
