//@ts-nocheck
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getComponents } from "lib/reducers/reportBuilder.selectors";
import { useSelector } from "store";
import LayoutTypes from "pages/reporting/reports/ReportPage/LayoutTypes";
import Panel from "./Panel";
import extractIntoCategories from "./extractIntoCategories";

const TablePanel = (props) => {
  const components = useSelector(getComponents);
  const tableComponents = useMemo(() => components.filter(({ typeId }) => typeId === LayoutTypes.TABLE), [components]);
  const categories = useMemo(() => Object.values(tableComponents.reduce(extractIntoCategories, {})), [tableComponents]);

  return <Panel {...props} categories={categories} />;
};

TablePanel.defaultProps = {};

TablePanel.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
  closeDropdown: PropTypes.func,
};

export default TablePanel;
