import { createSlice } from "@reduxjs/toolkit";
import {
  createDoorway,
  fetchDoorways,
  updateDoorway,
  deleteDoorway,
  fetchDoorwayById,
  fetchDoorwayByStorageId,
} from "store/reducers/doorways/doorways.thunks";
import { TDoorway, UUID } from "types";

export type DoorwaysReducerStateData = Record<UUID, TDoorway>;

export interface DoorwaysReducerState {
  data: DoorwaysReducerStateData;
}

const initialState: DoorwaysReducerState = {
  data: {},
};

export const doorwaysSlice = createSlice({
  name: "doorways",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDoorways.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: DoorwaysReducerStateData, value: TDoorway) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchDoorwayById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(fetchDoorwayByStorageId.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createDoorway.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateDoorway.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteDoorway.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const doorwaysReducer = doorwaysSlice.reducer;
