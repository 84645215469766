import { ReactNode } from "react";
import classNames from "classnames";
import styled from "styled-components";
import { TailwindTextColor, TailwindTextSize } from "types/tailwind";

const StyledTextBody = styled.p`
  margin: 0;
`;

type TextBodyProps = {
  textColor?: TailwindTextColor;
  textSize?: TailwindTextSize;
  children: ReactNode;
  onClick?: () => void;
  className?: string;
};

export const TextBody = ({
  textColor = "text-gray-900",
  textSize = "text-base",
  children,
  className,
  ...otherProps
}: TextBodyProps) => {
  return (
    <StyledTextBody {...otherProps} className={classNames(textColor, textSize, className)}>
      {children}
    </StyledTextBody>
  );
};
