import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateLocationRequest,
  DataResponse,
  FetchPostcodeGeoLocationRequest,
  FetchPostcodeGeoLocationResponse,
  LocationType,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TLocation,
  TMapBoxPlace,
  UpdateLocationRequest,
  UUID,
} from "types";

type FetchLocationsParams = PaginationQueryParams & {
  types?: LocationType[];
};

export const fetchLocations = createAsyncThunk(
  "locations/list",
  async (params: FetchLocationsParams): Promise<{ results: TLocation[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TLocation[]>>("/locations", params);
    return { results: data, paging: metadata };
  },
);

export const fetchLocationById = createAsyncThunk("locations/getById", async (id: UUID): Promise<TLocation> => {
  const { data } = await API.GET<DataResponse<TLocation>>(`/locations/${id}`);
  return data;
});

export const fetchPostcodeGeoLocation = createAsyncThunk<TMapBoxPlace | undefined, FetchPostcodeGeoLocationRequest>(
  "locations/getById",
  async (request) => {
    const sanitised = encodeURIComponent(request.postcode);
    const response = await API.GET<FetchPostcodeGeoLocationResponse>(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${sanitised}.json?access_token=${request.accessToken}`,
      {},
      {
        withCredentials: false,
        headers: {},
      },
    );
    return response.features.find(({ id }) => id.includes("postcode"));
  },
);

export const createLocation = createAsyncThunk(
  "locations/create",
  async (request: CreateLocationRequest): Promise<TLocation> => {
    const { data } = await API.POST<CreateLocationRequest, DataResponse<TLocation>>("/locations", request);
    return data;
  },
);

export const updateLocation = createAsyncThunk(
  "locations/update",
  async (request: UpdateLocationRequest): Promise<TLocation> => {
    const { data } = await API.PUT<UpdateLocationRequest, DataResponse<TLocation>>(`/locations/${request.id}`, request);
    return data;
  },
);

export const deleteLocation = createAsyncThunk("locations/delete", async (id: UUID): Promise<void> => {
  await API.DELETE(`/locations/${id}`);
});
