//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Overlay from "components/Overlay";
import style from "./dropdown.module.scss";

const Dropdown = ({ visible, children, alignRight, onClose, className, ...otherProps }) => {
  const classes = classNames(
    style.dropdown,
    {
      [style.dropdownAlignRight]: alignRight,
    },
    className,
  );
  return (
    visible && (
      <>
        <Overlay isTransparent={true} visible={true} className={style.dropdownOverlay} onClick={onClose} />
        <div {...otherProps} className={classes}>
          {children}
        </div>
      </>
    )
  );
};

Dropdown.defaultProps = {};

Dropdown.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  alignRight: PropTypes.bool,
};

export default Dropdown;
