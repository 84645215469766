import React, { FormEvent, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createCaseScanner } from "store/reducers";
import { CreateCaseScannerRequest, TCaseScanner, TToolRoom, UUID } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { ToolRoomSelector } from "components/shared/features/toolRooms";
import { VSpace } from "components/shared/layouts";

type CreateCaseScannerModalProps = {
  toolRoomId?: UUID; // Optionally set the tool room ID right away
  onSuccess?: (caseScanner: TCaseScanner) => void;
};

export const CreateCaseScannerModal = ({ toolRoomId, onSuccess }: CreateCaseScannerModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateCaseScannerRequest>({
    name: "",
    description: "",
    toolRoomId,
  });

  const handleChange = (name: string, value: string | number | undefined) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const caseScanner = await dispatch(createCaseScanner(values)).unwrap();
      closeModal();
      onSuccess && onSuccess(caseScanner);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleChangeToolRoom = (name: string, value?: TToolRoom) => {
    handleChange("toolRoomId", value?.id);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader title="Create case scanner" />
        <ModalContent>
          <VSpace>
            <FieldGroup label="Name">
              <TextInput
                input={{
                  name: "name",
                  placeholder: "Enter case scanner name",
                  autoFocus: true,
                }}
                value={values.name}
                onChange={handleChange}
              />
            </FieldGroup>
            <FieldGroup label="Tool room">
              <ToolRoomSelector
                appearance="normal"
                name="toolRoomId"
                value={values.toolRoomId}
                onChange={handleChangeToolRoom}
              />
            </FieldGroup>
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit">Create case scanner</PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
