//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import PlatformLogo from "components/PlatformNavigationBar/PlatformLogo";
import { Subtitle, Text } from "components/Typography";
import { VSpace } from "components/shared";
import { HalfHalf, MarketingSection } from "pages/marketing/components/Common";
import style from "pages/marketing/marketing.module.scss";

const LinkGroup = ({ title, links = [] }) => {
  return (
    <div className={style.linkGroup}>
      <VSpace>
        <Subtitle>{title}</Subtitle>
        <ul>
          {links.map((link) => (
            <a key={link.to} href={link.to} target={link.target || ""} className={style.menuLink}>
              <li>{link.label}</li>
            </a>
          ))}
        </ul>
      </VSpace>
    </div>
  );
};

const MarketingFooter = ({ className }) => {
  const platformLinks = [
    {
      label: "System Status",
      to: "https://status.automify.co.uk/",
      target: "_blank",
    },
  ];
  const resourcesLinks = [
    {
      label: "Terms and Conditions",
      to: "/terms-and-conditions",
    },
    {
      label: "Privacy Policy",
      to: "/privacy-policy",
    },
  ];
  return (
    <MarketingSection className={style.footerSection}>
      <div className={style.footer}>
        <HalfHalf largerRight={true}>
          <div>
            <PlatformLogo to="/" image="/images/automify_logo_white_no_border.png" className={style.footerLogo} />
            <Text>Made with ❤️ in Wales, United Kingdom.</Text>
          </div>
          <div className={style.linkGroups}>
            <LinkGroup title="Resources" links={resourcesLinks} />
          </div>
        </HalfHalf>
        <div>
          <Text className={style.copyright}>© Copyright 2023 Automify Ltd. Company number 10541057.</Text>
        </div>
      </div>
    </MarketingSection>
  );
};

MarketingFooter.defaultProps = {};

MarketingFooter.propTypes = {
  className: PropTypes.string,
};

export default MarketingFooter;
