//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { MixAction, MixPanel } from "lib/mixpanel";
import { Paragraph, Text, Title, Subheader, Subtitle } from "components/Typography";
import { MarketingSection } from "pages/marketing/components/Common";
import MarketingFooter from "pages/marketing/components/MarketingFooter";
import NavBar from "pages/marketing/components/NavBar";
import style from "./marketing.module.scss";

class PrivacyPolicyPage extends React.Component {
  componentDidMount() {
    MixPanel.track(MixAction.VISIT_PRIVACY_POLICY);
  }
  render() {
    return (
      <div className={style.page}>
        <NavBar />
        <MarketingSection>
          <div>
            <Title>PRIVACY NOTICE</Title>
            <Text>
              <strong>Last updated November 01, 2020</strong>
            </Text>
            <Paragraph>
              Thank you for choosing to be part of our community at Automify Ltd, doing business as Automify
              ("Automify", "we", "us", "our"). We are committed to protecting your personal information and your right
              to privacy. If you have any questions or concerns about our policy, or our practices with regards to your
              personal information, please contact us at vincent@automify.co.uk.
            </Paragraph>
            <Paragraph>
              When you visit our website <a href="https://www.automify.co.uk/">https://www.automify.co.uk/</a>, and use
              our services, you trust us with your personal information. We take your privacy very seriously. In this
              privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible
              what information we collect, how we use it and what rights you have in relation to it. We hope you take
              some time to read through it carefully, as it is important. If there are any terms in this privacy policy
              that you do not agree with, please discontinue use of our Sites and our services.
            </Paragraph>
            <Paragraph>
              This privacy policy applies to all information collected through our website (such as{" "}
              <a href="https://www.automify.co.uk/">https://www.automify.co.uk/</a>
              ), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy
              policy as the "Sites").
            </Paragraph>
            <Paragraph>
              Please read this privacy policy carefully as it will help you make informed decisions about sharing your
              personal information with us.
            </Paragraph>
            <Subheader>TABLE OF CONTENTS</Subheader>
            1. WHAT INFORMATION DO WE COLLECT?
            <br />
            2. HOW DO WE USE YOUR INFORMATION?
            <br />
            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            <br />
            4. WHO WILL YOUR INFORMATION BE SHARED WITH?
            <br />
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            <br />
            6. HOW LONG DO WE KEEP YOUR INFORMATION?
            <br />
            7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            <br />
            8. DO WE COLLECT INFORMATION FROM MINORS?
            <br />
            9. WHAT ARE YOUR PRIVACY RIGHTS?
            <br />
            10. CONTROLS FOR DO-NOT-TRACK FEATURES
            <br />
            11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            <br />
            12. DO WE MAKE UPDATES TO THIS NOTICE?
            <br />
            13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            <Subheader>1. WHAT INFORMATION DO WE COLLECT?</Subheader>
            <Subtitle>Personal information you disclose to us</Subtitle>
            <Paragraph>
              <strong>In Short:</strong> We collect personal information that you provide to us such as name, address,
              contact information, passwords and security data, and payment information.
            </Paragraph>
            <Paragraph>
              We collect personal information that you voluntarily provide to us when registering at the Sites
              expressing an interest in obtaining information about us or our products and services, when participating
              in activities on the Sites or otherwise contacting us.
            </Paragraph>
            <Paragraph>
              The personal information that we collect depends on the context of your interactions with us and the
              Sites, the choices you make and the products and features you use. The personal information we collect may
              include the following:
            </Paragraph>
            <Paragraph>
              <strong>Name and Contact Data.</strong> We collect your first and last name, email address, postal
              address, phone number, and other similar contact data.
            </Paragraph>
            <Paragraph>
              <strong>Credentials.</strong> We collect passwords, password hints, and similar security information used
              for authentication and account access.
            </Paragraph>
            <Paragraph>
              <strong>Payment Data.</strong> We collect data necessary to process your payment if you make purchases,
              such as your payment instrument number (such as a credit card number), and the security code associated
              with your payment instrument. All payment data is stored by our payment processor and you should review
              its privacy policies and contact the payment processor directly to respond to your questions.
            </Paragraph>
            <Paragraph>
              All personal information that you provide to us must be true, complete and accurate, and you must notify
              us of any changes to such personal information.
            </Paragraph>
            <Subtitle>Information automatically collected</Subtitle>
            <Paragraph>
              <strong>In Short:</strong> Some information — such as IP address and/or browser and device characteristics
              — is collected automatically when you visit our Sites.
            </Paragraph>
            <Paragraph>
              We automatically collect certain information when you visit, use or navigate the Sites. This information
              does not reveal your specific identity (like your name or contact information) but may include device and
              usage information, such as your IP address, browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location, information about how and when you use our
              Sites and other technical information. This information is primarily needed to maintain the security and
              operation of our Sites, and for our internal analytics and reporting purposes.
            </Paragraph>
            <Paragraph>
              Like many businesses, we also collect information through cookies and similar technologies.
            </Paragraph>
            <Subtitle>Information collected from other sources</Subtitle>
            <Paragraph>
              <strong>In Short:</strong> We may collect limited data from public databases, marketing partners, and
              other outside sources.
            </Paragraph>
            <Paragraph>
              We may obtain information about you from other sources, such as public databases, joint marketing
              partners, as well as from other third parties. Examples of the information we receive from other sources
              include: social media profile information; marketing leads and search results and links, including paid
              listings (such as sponsored links).
            </Paragraph>
            <Subheader>2. HOW DO WE USE YOUR INFORMATION?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> We process your information for purposes based on legitimate business
              interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your
              consent.
            </Paragraph>
            <Paragraph>
              We use personal information collected via our Sites for a variety of business purposes described below. We
              process your personal information for these purposes in reliance on our legitimate business interests
              ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your
              consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the
              specific processing grounds we rely on next to each purpose listed below.
            </Paragraph>
            <Paragraph>We use the information we collect or receive:</Paragraph>
            <ul>
              <li>
                <strong>To facilitate account creation and logon process.</strong> If you choose to link your account
                with us to a third party account (such as your Google or Facebook account), we use the information you
                allowed us to collect from those third parties to facilitate account creation and logon process.
              </li>

              <li>
                <strong>To send you marketing and promotional communications.</strong> We and/or our third party
                marketing partners may use the personal information you send to us for our marketing purposes, if this
                is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time
                (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
              </li>

              <li>
                <strong>To send administrative information to you.</strong> We may use your personal information to send
                you product, service and new feature information and/or information about changes to our terms,
                conditions, and policies.
              </li>

              <li>
                <strong>Fulfill and manage your orders.</strong> We may use your information to fulfill and manage your
                orders, payments, returns, and exchanges made through the Sites.
              </li>

              <li>
                <strong>To post testimonials.</strong> We post testimonials on our Sites that may contain personal
                information. Prior to posting a testimonial, we will obtain your consent to use your name and
                testimonial. If you wish to update, or delete your testimonial, please contact us at
                vincent+privacy@automify.co.uk and be sure to include your name, testimonial location, and contact
                information.
              </li>

              <li>
                <strong>Request Feedback.</strong> We may use your information to request feedback and to contact you
                about your use of our Sites.
              </li>

              <li>
                <strong>To protect our Sites.</strong> We may use your information as part of our efforts to keep our
                Sites safe and secure (for example, for fraud monitoring and prevention).
              </li>

              <li>
                <strong>To enforce our terms, conditions and policies.</strong>
              </li>

              <li>
                <strong>To respond to legal requests and prevent harm.</strong> If we receive a subpoena or other legal
                request, we may need to inspect the data we hold to determine how to respond.
              </li>

              <li>
                <strong>For other Business Purposes.</strong> We may use your information for other Business Purposes,
                such as data analysis, identifying usage trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Sites, products, services, marketing and your experience.
              </li>
            </ul>
            <Subheader>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> We only share information with your consent, to comply with laws, to provide
              you with services, to protect your rights, or to fulfill business obligations.
            </Paragraph>
            <Paragraph>We may process or share your data that we hold based on the following legal basis:</Paragraph>
            <ul>
              <li>
                <strong>Consent:</strong> We may process your data if you have given us specific consent to use your
                personal information for a specific purpose.
              </li>

              <li>
                <strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to
                achieve our legitimate business interests.
              </li>

              <li>
                <strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may
                process your personal information to fulfill the terms of our contract.
              </li>

              <li>
                <strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do
                so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or
                legal process, such as in response to a court order or a subpoena (including in response to public
                authorities to meet national security or law enforcement requirements).
              </li>

              <li>
                <strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to
                investigate, prevent, or take action regarding potential violations of our policies, suspected fraud,
                situations involving potential threats to the safety of any person and illegal activities, or as
                evidence in litigation in which we are involved.
              </li>
            </ul>
            <Paragraph>
              More specifically, we may need to process your data or share your personal information in the following
              situations:
            </Paragraph>
            <ul>
              <li>
                <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or
                during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                portion of our business to another company.
              </li>

              <li>
                <strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your data
                with third party vendors, service providers, contractors or agents who perform services for us or on our
                behalf and require access to such information to do that work. Examples include: payment processing,
                data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow
                selected third parties to use tracking technology on the Sites, which will enable them to collect data
                about how you interact with the Sites over time. This information may be used to, among other things,
                analyze and track data, determine the popularity of certain content and better understand online
                activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information
                with third parties for their promotional purposes.
              </li>

              <li>
                <strong>Business Partners.</strong> We may share your information with our business partners to offer
                you certain products, services or promotions.
              </li>
            </ul>
            <Subheader>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> We only share information with the following third parties.
            </Paragraph>
            <Paragraph>
              We only share and disclose your information with the following third parties. We have categorised each
              party so that you may be easily understand the purpose of our data collection and processing practices. If
              we have processed your data based on your consent and you wish to revoke your consent, please contact us.
            </Paragraph>
            <ul>
              <li>
                <strong>Communicate and Chat with Users:</strong> Chaport
              </li>
              <li>
                <strong>Invoice and Billing:</strong> Stripe
              </li>
              <li>
                <strong>Web and Mobile Analytics:</strong> Google Analytics
              </li>
              <li>
                <strong>Website Performance Monitoring:</strong> Sentry
              </li>
            </ul>
            <Subheader>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your
              information.
            </Paragraph>
            <Paragraph>
              We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
              information. Specific information about how we use such technologies and how you can refuse certain
              cookies is set out in our Cookie Policy.
            </Paragraph>
            <Subheader>6. HOW LONG DO WE KEEP YOUR INFORMATION?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes
              outlined in this privacy policy unless otherwise required by law.
            </Paragraph>
            <Paragraph>
              We will only keep your personal information for as long as it is necessary for the purposes set out in
              this privacy policy, unless a longer retention period is required or permitted by law (such as tax,
              accounting or other legal requirements). No purpose in this policy will require us keeping your personal
              information for longer than 1 year past the termination of the user's account.
            </Paragraph>
            <Paragraph>
              When we have no ongoing legitimate business need to process your personal information, we will either
              delete or anonymize it, or, if this is not possible (for example, because your personal information has
              been stored in backup archives), then we will securely store your personal information and isolate it from
              any further processing until deletion is possible.
            </Paragraph>
            <Subheader>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> We aim to protect your personal information through a system of organisational
              and technical security measures.
            </Paragraph>
            <Paragraph>
              We have implemented appropriate technical and organisational security measures designed to protect the
              security of any personal information we process. However, please also remember that we cannot guarantee
              that the internet itself is 100% secure. Although we will do our best to protect your personal
              information, transmission of personal information to and from our Sites is at your own risk. You should
              only access the services within a secure environment.
            </Paragraph>
            <Subheader>8. DO WE COLLECT INFORMATION FROM MINORS?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of
              age.
            </Paragraph>
            <Paragraph>
              We do not knowingly solicit data from or market to children under 18 years of age. By using the Sites, you
              represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to
              such minor dependent’s use of the Sites. If we learn that personal information from users less than 18
              years of age has been collected, we will deactivate the account and take reasonable measures to promptly
              delete such data from our records. If you become aware of any data we may have collected from children
              under age 18, please contact us at vincent+privacy@automify.co.uk.
            </Paragraph>
            <Subheader>9. WHAT ARE YOUR PRIVACY RIGHTS?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> In some regions, such as the European Economic Area, you have rights that allow
              you greater access to and control over your personal information. You may review, change, or terminate
              your account at any time.
            </Paragraph>
            <Paragraph>
              In some regions (like the European Economic Area), you have certain rights under applicable data
              protection laws. These may include the right (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal
              information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the
              right to object to the processing of your personal information. To make such a request, please use the
              contact details provided below. We will consider and act upon any request in accordance with applicable
              data protection laws.
            </Paragraph>
            <Paragraph>
              If we are relying on your consent to process your personal information, you have the right to withdraw
              your consent at any time. Please note however that this will not affect the lawfulness of the processing
              before its withdrawal.
            </Paragraph>
            <Paragraph>
              If you are a resident in the European Economic Area and you believe we are unlawfully processing your
              personal information, you also have the right to complain to your local data protection supervisory
              authority. You can find their contact details here:
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </Paragraph>
            <Subtitle>Account Information</Subtitle>
            <Paragraph>
              If you would at any time like to review or change the information in your account or terminate your
              account, you can:
            </Paragraph>
            <ul>
              <li>Log into your account settings and update your user account.</li>
            </ul>
            <Paragraph>
              Upon your request to terminate your account, we will deactivate or delete your account and information
              from our active databases. However, some information my be retained in our files to prevent fraud,
              troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal
              requirements.
            </Paragraph>
            <Paragraph>
              <strong>
                <u>Cookies and similar technologies:</u>
              </strong>{" "}
              Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your
              browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this
              could affect certain features or services of our Sites. To opt-out of interest-based advertising by
              advertisers on our Sites visit{" "}
              <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
            </Paragraph>
            <Paragraph>
              <strong>
                <u>Opting out of email marketing:</u>
              </strong>{" "}
              You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the
              emails that we send or by contacting us using the details provided below. You will then be removed from
              the marketing email list — however, we will still need to send you service-related emails that are
              necessary for the administration and use of your account. To otherwise opt-out, you may:
            </Paragraph>
            <ul>
              <li>Note your preferences when you register an account with the site.</li>
              <li>Access your account settings and update preferences.</li>
            </ul>
            <Subheader>10. CONTROLS FOR DO-NOT-TRACK FEATURES</Subheader>
            <Paragraph>
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
              feature or setting you can activate to signal your privacy preference not to have data about your online
              browsing activities monitored and collected. No uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals
              or any other mechanism that automatically communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future, we will inform you about that practice
              in a revised version of this Privacy Policy.
            </Paragraph>
            <Subheader>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> Yes, if you are a resident of California, you are granted specific rights
              regarding access to your personal information.
            </Paragraph>
            <Paragraph>
              California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are
              California residents to request and obtain from us, once a year and free of charge, information about
              categories of personal information (if any) we disclosed to third parties for direct marketing purposes
              and the names and addresses of all third parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the contact information provided below.
            </Paragraph>
            <Paragraph>
              If you are under 18 years of age, reside in California, and have a registered account with the Sites, you
              have the right to request removal of unwanted data that you publicly post on the Sites. To request removal
              of such data, please contact us using the contact information provided below, and include the email
              address associated with your account and a statement that you reside in California. We will make sure the
              data is not publicly displayed on the Sites, but please be aware that the data may not be completely or
              comprehensively removed from all our systems.
            </Paragraph>
            <Subheader>12. DO WE MAKE UPDATES TO THIS POLICY?</Subheader>
            <Paragraph>
              <strong>In Short:</strong> Yes, we will update this policy as necessary to stay compliant with relevant
              laws.
            </Paragraph>
            <Paragraph>
              We may update this privacy policy from time to time. The updated version will be indicated by an updated
              "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
              changes to this privacy policy, we may notify you either by prominently posting a notice of such changes
              or by directly sending you a notification. We encourage you to review this privacy policy frequently to be
              informed of how we are protecting your information.
            </Paragraph>
            <Subheader>13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</Subheader>
            <Paragraph>
              If you have questions or comments about this policy, you may contact our Data Protection Officer (DPO),
              Vincent Racine, by email at vincent+privacy@automify.co.uk, or by post to:
            </Paragraph>
            <Paragraph>
              Automify Ltd
              <br />
              Vincent Racine
              <br />
              Sticle, Castle Road
              <br />
              Pencader, Carmarthenshire SA39 9AN
              <br />
              United Kingdom
            </Paragraph>
            <Subheader>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</Subheader>
            <Paragraph>
              Based on the applicable laws of your country, you may have the right to request access to the personal
              information we collect from you, change that information, or delete it in some circumstances. To request
              to review, update, or delete your personal information, please visit:{" "}
              <a href="https://www.automify.co.uk/account/privacy">https://www.automify.co.uk/account/privacy</a>. We
              will respond to your request within 30 days.
            </Paragraph>
          </div>
        </MarketingSection>
        <MarketingFooter />
      </div>
    );
  }
}

PrivacyPolicyPage.defaultProps = {};

PrivacyPolicyPage.propTypes = {
  className: PropTypes.string,
};

export default PrivacyPolicyPage;
