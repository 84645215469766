import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { selectAuthSession } from "store/reducers/accounts";
import { TToolStoreOrder, UUID } from "types";

const selectState = (state: RootStore) => state.toolStoreOrders;

export const selectToolStoreOrders = createSelector(selectState, (state) => state.data);

export const selectToolStoreOrdersList = createSelector(selectToolStoreOrders, (data) => Object.values(data));

export const selectToolStoreUserOrdersList = createSelector(
  selectToolStoreOrdersList,
  selectAuthSession,
  (list, session) => list.filter((entity) => entity.createdByUserId === session?.userId),
);

export const selectToolStoreOrderByID = (id: UUID) =>
  createSelector(selectToolStoreOrders, (data): TToolStoreOrder | undefined => data[id]);
