//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  data: {},
  fetching: "",
  error: null,
  paging: {
    pageStart: 0,
    pageLimit: 50,
    totalCount: 0,
    nextPageStart: "",
    previousPageStart: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_TASK_LISTS_REQUEST:
      return {
        ...state,
        fetching: action.type,
      };
    case type.GET_TASK_LISTS_REJECT:
      return {
        ...state,
        fetching: "",
        error: action.payload,
      };
    case type.GET_TASK_LISTS_FULFIL:
      return {
        ...state,
        fetching: "",
        data: action.payload.reduce(
          (data, item) => ({
            ...data,
            [item.id]: {
              ...item,
            },
          }),
          state.data,
        ),
      };
    default:
      return state;
  }
};
