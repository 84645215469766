//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Header } from "components/Typography";
import style from "pages/authentication/components/AuthSubmitButton.module.scss";

const AuthFormTitle = ({ title, className }) => {
  return <Header className={classNames(style.title, className)}>{title}</Header>;
};

AuthFormTitle.defaultProps = {};

AuthFormTitle.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default AuthFormTitle;
