//@ts-nocheck
import { takeEvery } from "redux-saga/effects";
import { removeItem, setItemQuantity } from "lib/CartStore";
import * as types from "../actions/actionTypes";

function* setCartProductQuantity(action) {
  setItemQuantity(action.payload.itemID, action.payload.quantity);
}

function* removeCartProduct(action) {
  removeItem(action.payload.itemID, action.payload.quantity);
}

function* cartSaga() {
  yield takeEvery(types.CART_SET_PRODUCT_QUANTITY, setCartProductQuantity);
  yield takeEvery(types.CART_REMOVE_PRODUCT, removeCartProduct);
}

export default cartSaga;
