//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Utils from "lib/Utils";
import { getStoreItemByID } from "lib/reducers/storeItems.selectors";
import { useSelector } from "store";
import { Text } from "components/Typography";
import styles from "./ShopItemPrice.module.scss";

const ShopItemPrice = ({ itemID, appearance = "normal", quantity = 1, currency = "£", vatDisclaimer }) => {
  const item = useSelector(getStoreItemByID(itemID));

  if (!item) {
    return null;
  }

  const subtotal = item.priceExcVat * quantity;
  switch (appearance) {
    case "inline":
      return (
        <Text className={styles.ShopItemPriceInline}>
          {currency} {Utils.formatCurrency(subtotal)} {vatDisclaimer && <small>(Exc VAT)</small>}
        </Text>
      );
    case "compact":
      return (
        <Text className={styles.ShopItemPriceCompact}>
          {currency} {Utils.formatCurrency(subtotal)} {vatDisclaimer && <small>(Exc VAT)</small>}
        </Text>
      );
    case "normal":
      return (
        <Text className={styles.ShopItemPriceNormal}>
          {currency} {Utils.formatCurrency(subtotal)} {vatDisclaimer && <small>(Exc VAT)</small>}
        </Text>
      );
    default:
      return null;
  }
};

ShopItemPrice.propTypes = {
  vatDisclaimer: PropTypes.bool,
  appearance: PropTypes.oneOf(["compact", "normal", "inline"]),
  itemID: PropTypes.number,
};

export default ShopItemPrice;
