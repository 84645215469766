import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { fetchVehicles } from "store/reducers/vehicles/vehicles.thunks";
import { TVehicle, UUID } from "types";

const selectState = (state: RootStore) => state.vehicles;

export const selectVehicles = createSelector(selectState, (state) => state.data);

export const selectVehiclesLoading = createSelector(
  selectState,
  ({ loading }) => loading === fetchVehicles.pending.type,
);

export const selectVehiclesList = createSelector(selectVehicles, (data) => Object.values(data));

export const selectVehiclesFilters = createSelector(selectState, (state) => state.filters);

export const selectVehiclesPaging = createSelector(selectState, ({ paging }) => {
  return paging;
});

export const selectVehiclesPage = createSelector(selectState, ({ page, data }) => {
  return page.map((id) => data[id]).filter(Boolean);
});

export const selectVehicleByID = (id: UUID) => createSelector(selectVehicles, (data): TVehicle | undefined => data[id]);
