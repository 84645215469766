//@ts-nocheck
import React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import Utils from "lib/Utils";
import { showMediaMenuFor } from "lib/actions/blocks";
import BlockTypes from "lib/constants/BlockTypes";
import Icon from "components/Icon";
import { Text } from "components/Typography";
import style from "../sheet.module.scss";

const FileBlock = ({ block, onChange, onAddBlock, onRemoveBlock, className }) => {
  const dispatch = useDispatch();
  const { url, fileName, size } = block.properties;

  const openMediaMenu = () => {
    if (!url) {
      const blockNode = document.getElementById(block.id);
      if (blockNode) {
        dispatch(showMediaMenuFor(blockNode));
      }
    }
  };

  return (
    <div
      className={classNames(style.flex, style.fileBlock, {
        [style.contentPlaceholder]: !url,
      })}
      onClick={openMediaMenu}
    >
      <Text className={style.blockPlaceholder}>
        <Icon src="/images/icons/attachment_1.svg" className={style.blockPlaceholderIcon} />
        {url ? (
          <>
            <a target="_blank" href={url} rel="noreferrer">
              {fileName}
            </a>
            <Text className={style.fileSize}>{Utils.formatBytesToSize(size)}</Text>
          </>
        ) : (
          BlockTypes.resolveItem(BlockTypes.FILE).description
        )}
      </Text>
    </div>
  );
};

export default FileBlock;
