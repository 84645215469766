import React, { FormEvent, useState } from "react";
import { useModal } from "effects/useModal";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { createStorageAlert } from "store/reducers";
import { CreateAlertRequest, TStorageAlert } from "types";
import { PrimaryButton, SecondaryButton } from "components/Buttons";
import { TextAreaInput, TextInput } from "components/Input";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalFooter, ModalHeader } from "components/modals/Modal";
import { VSpace } from "components/shared/layouts";

type CreateStorageAlertModalProps = {
  storageId: string;
  onSuccess?: (storageAlert: TStorageAlert) => void;
};

export const CreateStorageAlertModal = ({ storageId, onSuccess }: CreateStorageAlertModalProps) => {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [values, setValues] = useState<CreateAlertRequest>({
    name: "",
    description: "",
    enabled: true,
  });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const invalid = !values.name;

  const handleChange = (name: string, value: string | number | undefined) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      setSubmitting(true);
      const storageAlert = await dispatch(
        createStorageAlert({
          storageId,
          alert: values,
        }),
      ).unwrap();
      closeModal();
      onSuccess && onSuccess(storageAlert);
    } catch (err) {
      handlePopupFlagError(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Modal>
        <ModalHeader title="Create alert" />
        <ModalContent>
          <VSpace>
            <FieldGroup label="Name">
              <TextInput
                input={{
                  name: "name",
                  placeholder: "Enter alert name",
                  autoFocus: true,
                }}
                value={values.name}
                onChange={handleChange}
              />
            </FieldGroup>
            <FieldGroup label="Description">
              <TextAreaInput
                input={{
                  name: "description",
                  placeholder: "This alert will notify...",
                }}
                value={values.description}
                onChange={handleChange}
              />
            </FieldGroup>
          </VSpace>
        </ModalContent>
        <ModalFooter>
          <PrimaryButton type="submit" disabled={invalid || submitting}>
            Create alert
          </PrimaryButton>
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        </ModalFooter>
      </Modal>
    </form>
  );
};
