//@ts-nocheck
import React from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import PageStage from "components/stages/PageStage";
import { ShopCheckoutPageCart } from "pages/apps/Shop/ShopCheckoutPage/ShopCheckoutPageCart";

export const ShopCheckoutPage = () => {
  const params = useParams();
  const { section } = params;

  const renderPage = () => {
    switch (section) {
      case "cart":
        return <ShopCheckoutPageCart />;
    }
  };

  return <PageStage>{renderPage()}</PageStage>;
};

ShopCheckoutPage.propTypes = {
  className: PropTypes.string,
};
