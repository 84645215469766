//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Card from "components/Card";
import StageHeader from "components/StageHeader";
import StageMenuItem from "components/StageMenuItem";
import { Subtitle } from "components/Typography";
import PageStage from "components/stages/PageStage";

const Company = ({ className }) => {
  return (
    <PageStage>
      <Card>
        <StageHeader title="Company" />
        <Subtitle>Billing</Subtitle>
        <StageMenuItem
          title="Manage billing"
          path="/company/billing/settings"
          description="Manage billing options and settings"
          icon="/images/icons/bank_1.svg"
        />
        <StageMenuItem
          title="Manage invoices"
          path="/company/billing/invoices"
          description="Manage your previous invoices"
          icon="/images/icons/receipt.svg"
        />

        <Subtitle>Privacy settings</Subtitle>
        <StageMenuItem
          title="Manage privacy"
          path="/company/privacy"
          description="Manage your privacy policy and settings"
          icon="/images/icons/privacy.svg"
        />
      </Card>
    </PageStage>
  );
};

Company.defaultProps = {};

Company.propTypes = {
  className: PropTypes.string,
};

export default Company;
