//@ts-nocheck
import request from "superagent";
import Account from "../Account";

class Api {
  /**
   * Perform GET request
   * @param {string} uri - the URI should start with a "/".
   * @returns {Request}
   */
  static get(uri) {
    this._checkUri(uri);
    return request.get(this._prepareUrl(uri)).withCredentials().set(this.requestOptions());
  }

  /**
   * Perform POST request
   * @param {string} uri - the URI should start with a "/".
   * @returns {Request}
   */
  static post(uri) {
    this._checkUri(uri);
    return request.post(this._prepareUrl(uri)).withCredentials().set(this.requestOptions());
  }

  /**
   * Perform PUT request
   * @param {string} uri - the URI should start with a "/".
   * @returns {Request}
   */
  static put(uri) {
    this._checkUri(uri);
    return request.put(this._prepareUrl(uri)).withCredentials().set(this.requestOptions());
  }

  /**
   * Perform DELETE request
   * @param {string} uri - the URI should start with a "/".
   * @returns {Request}
   */
  static delete(uri) {
    this._checkUri(uri);
    return request.delete(this._prepareUrl(uri)).withCredentials().set(this.requestOptions());
  }

  /**
   * Check that a URI is valid
   * @param {string} uri
   * @throws {Error} error
   * @returns {boolean}
   */
  static _checkUri(uri) {
    if (typeof uri === "undefined") {
      throw new Error("URI not specified");
    }
    return true;
  }

  /**
   * Prepare a URL for a request
   * @param {string} uri
   * @returns {*}
   * @private
   */
  static _prepareUrl(uri) {
    if (uri.startsWith("http")) {
      return uri;
    }
    return Api.getBaseUrl + uri;
  }

  static requestOptions() {
    let options = {
      Accept: "application/json",
    };

    if (Account.session()) {
      options.Authorization = Account.session().authToken;
    }

    return options;
  }

  static get getBaseUrl() {
    return process.env.REACT_APP_API_URL || "";
  }
}

export default Api;
