import { HiOutlineTrash } from "react-icons/hi";
import styled from "styled-components";

const RemoveIconWrapper = styled.span`
  font-size: 1.25rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    transition: background-color ease 50ms;
    background-color: rgba(208, 215, 222, 0.32);
  }
`;

type TrashButtonProps = {
  onClick?: () => void;
};

export const TrashButton = ({ onClick }: TrashButtonProps) => {
  return (
    <RemoveIconWrapper onClick={onClick}>
      <HiOutlineTrash />
    </RemoveIconWrapper>
  );
};
