import { RiSignalTowerFill } from "react-icons/ri";
import { useModal } from "effects";
import { showPopupFlag } from "lib/actions/userInterface";
import Apps from "lib/constants/Apps";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectAssetByID, updateAsset } from "store/reducers";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import { CheckboxInput } from "components/Input";
import { Restrict } from "components/Restricted";
import StageHeader from "components/StageHeader";
import StageSection from "components/StageSection";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { DetectAssetRFIDTagsModal, PageLoading, VSpace } from "components/shared";
import { SecondaryButton } from "components/shared/buttons";
import { AssetRFIDConfig } from "components/shared/features/assets/AssetRFIDConfig/AssetRFIDConfig";
import AssetAssignee from "./components/AssetAssignee";

type AssetTrackingProps = {
  assetId: number;
};

export const AssetTracking = ({ assetId }: AssetTrackingProps) => {
  const dispatch = useDispatch();
  const asset = useSelector(selectAssetByID(assetId));
  const { openModal } = useModal();

  const onChange = async (name: string, value: any) => {
    if (!asset) return;
    try {
      await dispatch(
        updateAsset({
          ...asset,
          [name]: value,
        }),
      ).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Tracking updated",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleEpcsDetected = (epcs: string[]) => {
    if (!asset) return;
    onChange("rfidTagId", Array.from(new Set([...asset.rfidTagId, ...epcs])));
  };

  const handleDetectUsingDevice = () => {
    openModal(<DetectAssetRFIDTagsModal onSuccess={handleEpcsDetected} />);
  };

  if (!asset) {
    return <PageLoading />;
  }

  return (
    <>
      <StageHeader title="Tool tracking" />
      <StageSection isFirst={true}>
        <DataGrid>
          <DataItem label="Tool assignment">
            <DataItemGroup>
              <AssetAssignee assetId={asset.id} />
            </DataItemGroup>
          </DataItem>
          <Restrict app={Apps.TOOL_TRACKING_RFID}>
            <DataItem
              label="RFID"
              value={
                <DataItemGroup>
                  <VSpace gap="gap-4">
                    <FieldGroup label="Enable RFID">
                      <CheckboxInput
                        input={{ name: "rfidTracking" }}
                        label="Enable RFID tracking"
                        value={asset.rfidTracking}
                        onChange={onChange}
                      />
                    </FieldGroup>
                    <FieldGroup label="RFID tags">
                      <VSpace gap="gap-2">
                        <SecondaryButton leadingIcon={RiSignalTowerFill} onClick={handleDetectUsingDevice}>
                          Scan with device
                        </SecondaryButton>
                        <AssetRFIDConfig epcs={asset.rfidTagId} onChange={(epcs) => onChange("rfidTagId", epcs)} />
                      </VSpace>
                    </FieldGroup>
                  </VSpace>
                </DataItemGroup>
              }
            />
          </Restrict>
        </DataGrid>
      </StageSection>
    </>
  );
};
