import { createSlice } from "@reduxjs/toolkit";
import { createRag, fetchRags, updateRag, deleteRag, fetchRagById } from "store/reducers/rags/rags.thunks";
import { TRag, UUID } from "types";

export type RagsReducerStateData = Record<UUID, TRag>;

export interface RagsReducerState {
  data: RagsReducerStateData;
}

const initialState: RagsReducerState = {
  data: {},
};

export const ragsSlice = createSlice({
  name: "rags",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRags.fulfilled, (state, action) => {
        state.data = action.payload.results.reduce(
          (data: RagsReducerStateData, value: TRag) => ({
            ...data,
            [value.id]: value,
          }),
          state.data,
        );
      })
      .addCase(fetchRagById.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(createRag.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(updateRag.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(deleteRag.fulfilled, (state, action) => {
        const entity = state.data[action.meta.arg];
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const ragsReducer = ragsSlice.reducer;
