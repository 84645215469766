//@ts-nocheck
import { createSelector } from "reselect";

export const getAlerts = createSelector(
  (state) => state.alerts,
  (state) => Object.values(state.data),
);

export const getAlertsCollection = createSelector(
  (state) => state.alerts,
  (state) => state.data,
);

export const getAlertFetching = (actionType) =>
  createSelector(
    (state) => state.alerts,
    (state) => state.fetching === actionType,
  );

export const getAlertByID = (itemID) =>
  createSelector(
    (state) => state.alerts,
    (state) => state.data[itemID] || null,
  );

export const getAlertsPaging = createSelector(
  (state) => state.alerts,
  (state) => state.paging,
);

export const getAlertsError = createSelector(
  (state) => state.alerts,
  (state) => state.error,
);

export const getAlertRulesByAlertID = (alertID) =>
  createSelector(
    (state) => state.alerts,
    (state) => {
      const alert = state.data[alertID] || null;
      return alert ? Object.values(alert.rules) : [];
    },
  );

export const getAlertRecipientsByAlertID = (alertID) =>
  createSelector(
    (state) => state.alerts,
    (state) => {
      const alert = state.data[alertID] || null;
      return alert ? Object.values(alert.recipients) : [];
    },
  );
