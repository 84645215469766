//@ts-nocheck
import * as type from "./actionTypes";
import { requestInitiate, receivePagedResult, requestFailure, requestInitiateWithCallback } from "./common";

export const requestStoreItems = (params) => requestInitiate(type.GET_STORE_ITEMS_REQUEST, params);
export const fulfillRequestStoreItems = (items, paging) =>
  receivePagedResult(type.GET_STORE_ITEMS_RECEIVE, items, paging);
export const rejectRequestStoreItems = (err) => requestFailure(type.GET_STORE_ITEMS_FAILURE, err);

export const requestStoreItem = (itemID) => requestInitiate(type.GET_STORE_ITEM_REQUEST, itemID);
export const fulfilRequestStoreItem = (items, paging) => receivePagedResult(type.GET_STORE_ITEM_RECEIVE, items, paging);
export const rejectRequestStoreItem = (err) => requestFailure(type.GET_STORE_ITEM_FAILURE, err);

export const createStoreItem = (item, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.CREATE_STORE_ITEM_REQUEST, item, onSuccess, onFailed);
export const fulfilCreateStoreItem = (item) => receivePagedResult(type.CREATE_STORE_ITEM_FULFIL, item);
export const rejectCreateStoreItem = (err) => requestFailure(type.CREATE_STORE_ITEM_REJECT, err);

export const updateStoreItem = (item, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.UPDATE_STORE_ITEM_REQUEST, item, onSuccess, onFailed);
export const fulfilUpdateStoreItem = (item) => receivePagedResult(type.UPDATE_STORE_ITEM_FULFIL, item);
export const rejectUpdateStoreItem = (err) => requestFailure(type.UPDATE_STORE_ITEM_REJECT, err);

export const removeStoreItemImage = (itemID, imageID, onSuccess, onFailed) =>
  requestInitiateWithCallback(type.DELETE_STORE_ITEM_IMAGE_REQUEST, { itemID, imageID }, onSuccess, onFailed);
export const fulfilRemoveStoreItemImage = (itemID, imageID) =>
  receivePagedResult(type.DELETE_STORE_ITEM_IMAGE_FULFIL, {
    itemID,
    imageID,
  });
export const rejectRemoveStoreItemImage = (err) => requestFailure(type.DELETE_STORE_ITEM_IMAGE_REJECT, err);
