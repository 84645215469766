import { ReactNode } from "react";
import styled from "styled-components";
import InlineInput from "components/Typography/InlineInput";

const StyledText = styled.h1`
  color: #0f172a;
  font-weight: 600;
  font-size: 2.25rem;
  margin: 0;
  line-height: 1.1111111;
`;

type TitleProps = {
  id?: string;
  value?: string;
  canEdit?: boolean;
  onConfirm?: (value: string) => void;
  onClick?: () => void;
  placeholder?: string;
  children?: ReactNode;
  className?: string;
  inputClassName?: string;
};

export const Title = ({ id, value, canEdit, onConfirm, onClick, children, className }: TitleProps) => {
  return (
    <StyledText id={id} className={className}>
      {canEdit ? (
        <InlineInput input={{ type: "text" }} defaultValue={value} onConfirm={onConfirm} onClick={onClick} />
      ) : (
        value || children
      )}
    </StyledText>
  );
};

export default Title;
