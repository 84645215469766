import { createSlice } from "@reduxjs/toolkit";
import {
  assignUserKeycard,
  fetchUserKeycardById,
  unAssignUserKeycard,
} from "store/reducers/userKeycards/userKeycards.thunks";
import { TUserKeycard, UUID } from "types";

export type UserKeycardsReducerStateData = Record<UUID, TUserKeycard>;

export interface UserKeycardsReducerState {
  data: UserKeycardsReducerStateData;
}

const initialState: UserKeycardsReducerState = {
  data: {},
};

export const userKeycardsSlice = createSlice({
  name: "userKeycards",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserKeycardById.fulfilled, (state, action) => {
        // Payload will be null if the keycard is not assigned.
        if (action.payload) {
          state.data[action.payload.id] = action.payload;
        }
      })
      .addCase(assignUserKeycard.fulfilled, (state, action) => {
        state.data[action.payload.id] = action.payload;
      })
      .addCase(unAssignUserKeycard.fulfilled, (state, action) => {
        const keycardId = action.meta.arg;
        const entity = Object.values(state.data).find((userKeycard) => userKeycard.keycardId === keycardId);
        if (entity) {
          delete state.data[entity.id];
        }
      });
  },
});

export const userKeycardsReducer = userKeycardsSlice.reducer;
