import { useEffect, useState } from "react";
import { openModal } from "lib/actions/modal";
import Modals from "lib/constants/Modals";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch } from "store";
import { fetchKeycards } from "store/reducers";
import { Pagination } from "types";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { KeycardsTable } from "components/shared/features/configurations/keycards";
import PageStage from "components/stages/PageStage";

export const ListKeycardsPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [ids, setIds] = useState<string[]>([]);
  const [paging, setPaging] = useState<Pagination>({
    pageStart: 0,
    pageLimit: 20,
    pageCount: 0,
    totalCount: 0,
    nextPageStart: null,
    previousPageStart: null,
  });

  useEffect(() => {
    fetch(paging.pageStart, paging.pageLimit);
  }, [paging.pageStart, paging.pageLimit]);

  const fetch = async (pageStart: number, pageLimit: number) => {
    try {
      setLoading(true);
      const response = await dispatch(fetchKeycards({ pageStart, pageLimit })).unwrap();
      setIds(response.results.map(({ id }) => id));
      setPaging(response.paging);
    } catch (err) {
      handlePopupFlagError(err);
    }
    setLoading(false);
  };

  const handleCreate = () => {
    dispatch(
      openModal(Modals.CREATE_KEYCARD, {
        onSuccess: () => {
          const { pageLimit } = paging;
          fetch(0, pageLimit);
        },
      }),
    );
  };

  return (
    <PageStage>
      <StageHeader
        title="Keycards"
        subtitle="Manage your user keycards."
        action={
          <SecondaryButton onClick={handleCreate} icon="/images/icons/plus_1.svg">
            Create keycard
          </SecondaryButton>
        }
      />
      <KeycardsTable loading={loading} keycardIds={ids} paging={paging} onFetch={fetch} />
    </PageStage>
  );
};
