import React, { useState } from "react";
import classNames from "classnames";
import { FixLater } from "types";
import { SecondaryButton } from "components/Buttons";
import Dropdown from "components/dropdowns/Dropdown";
import style from "./dropdown.module.scss";

// DropButton.propTypes = {
//   button: PropTypes.any,
//   text: PropTypes.string,
//   className: PropTypes.string,
//   size: PropTypes.oneOf(["small", "medium", "large"]),
//   alignRight: PropTypes.bool,
//   border: PropTypes.bool,
// };

const DropButton = ({
  button,
  text,
  size = "medium",
  children,
  border = false,
  alignRight,
  className,
  ...otherProps
}: FixLater) => {
  const [visible, setVisible] = useState(false);

  const toggle = () => setVisible(!visible);

  const renderButton = () => {
    if (button) {
      return React.cloneElement(button, { onClick: toggle });
    }
    const classes = classNames(
      style.dropButton,
      {
        [style.dropButtonVisible]: visible,
        [style.dropButtonBorder]: border,
      },
      className,
    );
    return (
      <SecondaryButton {...otherProps} className={classes} onClick={toggle}>
        {text}
      </SecondaryButton>
    );
  };

  const items = React.Children.toArray(children);
  return (
    <div className={classNames(style.dropButtonContainer)}>
      {renderButton()}
      <Dropdown visible={visible} alignRight={alignRight} onClose={toggle} className={style[size]}>
        {items.map((item: FixLater) => React.cloneElement(item, { onItemClick: toggle }))}
      </Dropdown>
    </div>
  );
};

export default DropButton;
