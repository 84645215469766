//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import LayoutTypes from "../../../LayoutTypes";
import Dropdown, { DropdownPanel } from "../../Dropdown";
import style from "../builder.module.scss";
import { TextPanel, TablePanel } from "../panels";

const Placeholder = ({ text, layout, ...otherProps }) => {
  let placeholderText, placeholderSize;
  switch (layout.type) {
    case LayoutTypes.TEXT:
      placeholderText = "Add Text";
      placeholderSize = "text";
      break;
    case LayoutTypes.CHART:
      placeholderText = "Add Chart";
      placeholderSize = "chart";
      break;
    case LayoutTypes.TABLE:
      placeholderText = "Add Table";
      placeholderSize = "table";
      break;
  }
  return (
    <svg {...otherProps} className={classNames(style.svgPlaceholder, style[placeholderSize])}>
      <rect className={style.svgPlaceholderBackground} />
      <rect className={style.svgPlaceholderBorder} />
      <g className={style.svgPlaceholderGroup}>
        <text dominantBaseline="middle" textAnchor="middle" className={style.svgPlaceholderText}>
          <tspan>{placeholderText}</tspan>
        </text>
      </g>
    </svg>
  );
};

const PlaceholderDropdown = ({ layout }) => {
  return (
    <Dropdown>
      {(isOpen, openDropdown, closeDropdown) => (
        <>
          <Placeholder layout={layout} onClick={openDropdown} className={style.sectionPlaceholder} />
          {isOpen ? (
            <DropdownPanel>
              {layout.type === LayoutTypes.TEXT ? <TextPanel layout={layout} closeDropdown={closeDropdown} /> : null}
              {layout.type === LayoutTypes.TABLE ? <TablePanel layout={layout} closeDropdown={closeDropdown} /> : null}
            </DropdownPanel>
          ) : null}
        </>
      )}
    </Dropdown>
  );
};

PlaceholderDropdown.defaultProps = {};

PlaceholderDropdown.propTypes = {
  layout: PropTypes.shape({
    placeholder: PropTypes.string,
    type: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default PlaceholderDropdown;
