import classNames from "classnames";
import style from "./statuspill.module.scss";

const colorStyle = (color: string) => {
  switch (color) {
    case "success":
      return style.success;
    case "danger":
      return style.danger;
    case "warning":
      return style.warning;
    case "grey":
      return style.grey;
    default:
      return style.primary;
  }
};

type StatusPillProps = {
  text: string;
  color?: "success" | "danger" | "warning" | "grey" | "primary";
  size?: "small" | "normal";
  className?: string;
  onClick?: () => void;
};

export const StatusPill = ({ text, color = "primary", size = "normal", className, onClick }: StatusPillProps) => (
  <div onClick={onClick} className={classNames(style.pill, colorStyle(color), style[size], className)}>
    {text}
  </div>
);

export default StatusPill;
