//@ts-nocheck
import { fork } from "redux-saga/effects";
import cartSaga from "lib/sagas/cart";
import alertsSaga from "./alerts";
import assetsSaga from "./assets";
import catalogueSaga from "./catalogue";
import checkoutsSaga from "./checkouts";
import evidencesSaga from "./evidences";
import lookupDataSaga from "./lookupData";
import marketplaceSaga from "./marketplace";
import projectsSaga from "./projects";
import readersSaga from "./readers";
import reportsSaga from "./reports";
import statisticsSaga from "./statistics";
import storagesSaga from "./storages";
import tagsSaga from "./tags";
import tasksSaga from "./tasks";
import usersSaga from "./users";

export default function* rootSaga() {
  yield [
    fork(lookupDataSaga),
    fork(usersSaga),
    fork(assetsSaga),
    fork(storagesSaga),
    fork(readersSaga),
    fork(checkoutsSaga),
    fork(tagsSaga),
    fork(statisticsSaga),
    fork(projectsSaga),
    fork(catalogueSaga),
    fork(reportsSaga),
    fork(evidencesSaga),
    fork(tasksSaga),
    fork(marketplaceSaga),
    fork(alertsSaga),
    fork(cartSaga),
  ];
}
