//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  data: {},
  fetching: "",
  error: null,
  paging: {
    pageStart: 0,
    pageLimit: 20,
    totalCount: 0,
    nextPageStart: "",
    previousPageStart: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_CATALOGUE_PRODUCTS_REQUEST:
    case type.GET_CATALOGUE_PRODUCT_REQUEST:
      return {
        ...state,
        error: null,
        fetching: action,
      };
    case type.GET_CATALOGUE_PRODUCTS_RECEIVE:
      return {
        ...state,
        fetching: "",
        paging: action.paging,
        data: action.payload.reduce((data, item) => ({ ...data, [item.id]: item }), {}),
      };
    case type.GET_CATALOGUE_PRODUCT_RECEIVE:
      return {
        ...state,
        fetching: "",
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    case type.GET_CATALOGUE_PRODUCTS_FAILURE:
    case type.GET_CATALOGUE_PRODUCT_FAILURE:
      return {
        ...state,
        fetching: "",
        error: action.payload,
      };
    default:
      return state;
  }
};
