//@ts-nocheck
import React from "react";
import classNames from "classnames";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import PropTypes from "prop-types";
import { VSpace } from "components/shared";
import {
  FullWidth,
  HalfHalf,
  InfoHeader,
  InfoText,
  MarketingHeader,
  MarketingSection,
  MarketingSectionWrapper,
} from "pages/marketing/components/Common";
import style from "pages/marketing/marketing.module.scss";

const AnimatedNetwork = () => {
  const { scrollYProgress } = useViewportScroll();
  const yPosAnim = useTransform(scrollYProgress, [0.35, 0.82], [0, 2000]);
  const fadeAnim = useTransform(scrollYProgress, [0.8, 0.83], [1, 0]);

  return (
    <div className={style.animatedNetwork}>
      <motion.div
        style={{
          opacity: fadeAnim,
          height: yPosAnim,
        }}
        className={style.photonTrail}
      />
      <motion.div
        style={{
          opacity: fadeAnim,
          y: yPosAnim,
        }}
        className={style.photon}
      />
    </div>
  );
};

const MarketingFeatures = () => {
  return (
    <>
      <MarketingSection>
        <HalfHalf largerRight>
          <div>
            <div className={style.relative}>
              <img
                src="/images/demo/storage_side.png"
                alt="Smart-Access toolbox"
                className={classNames(style.productImage, style.productImageSmall)}
              />
            </div>
          </div>
          <div className="flex items-center">
            <VSpace className="text-left">
              <InfoHeader className="text-gray-800">There is more in the drawer</InfoHeader>
              <InfoText concise>
                We recognise that businesses experience different challenges. Our software is designed with a modular
                approach to provide exact solutions to your relevant problems. By allowing only necessary modules to be
                purchased, costs remain minimal.
              </InfoText>
            </VSpace>
          </div>
        </HalfHalf>
      </MarketingSection>
      <MarketingSection>
        <MarketingSectionWrapper>
          <FullWidth className={style.centerAlign}>
            <MarketingHeader className="text-center">Blockchain secure</MarketingHeader>
          </FullWidth>
          <HalfHalf largerLeft={true}>
            <div className={style.verticalAlign}>
              <InfoText>
                Data processed by Automify is secured using{" "}
                <a href="https://guardtime.com/" target="_blank" rel="noreferrer">
                  Guardtime’s KSI® Blockchain
                </a>
                , the world’s first EU-eIDAS accredited blockchain-based trust service (
                <a href="https://webgate.ec.europa.eu/tl-browser/#/tl/EE/1" target="_blank" rel="noreferrer">
                  European Trusted List
                </a>
                ).
              </InfoText>
            </div>
            <div>
              <img
                src="/images/evidence-qr.png"
                alt="QR code"
                className={classNames(style.productImage, style.productImageTiny)}
              />
            </div>
          </HalfHalf>
        </MarketingSectionWrapper>
      </MarketingSection>
    </>
  );
};

MarketingFeatures.defaultProps = {};

MarketingFeatures.propTypes = {
  className: PropTypes.string,
};

export default MarketingFeatures;
