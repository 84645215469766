import { BsDoorOpen } from "react-icons/bs";
import { GiBrokenBottle } from "react-icons/gi";
import useSetSidebar from "effects/useSidebar";
import Apps from "lib/constants/Apps";
import Capabilities from "lib/constants/Capabilities";
import { Restrict, RestrictHasOneCapability } from "components/Restricted";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import Sidebar, { MenuSeparator } from "./Sidebar";

export const TrackingAppSidebar = () => {
  useSetSidebar(true);
  const url = "/apps/tracking";
  return (
    <Sidebar>
      <MenuItem to={`${url}/assets`} icon="/images/icons/tools_1.svg">
        All tools
      </MenuItem>
      <Restrict app={Apps.TOOL_TRACKING} capability={Capabilities.ASSET_ISSUE_READ}>
        <MenuItem to={`${url}/issues`} icon={<GiBrokenBottle />}>
          Tool issues
        </MenuItem>
      </Restrict>
      <Restrict app={Apps.DOORWAYS} capability={Capabilities.DOORWAY_READ}>
        <MenuItem to={`${url}/doorways`} icon={<BsDoorOpen />}>
          Doorways
        </MenuItem>
      </Restrict>
      <RestrictHasOneCapability capabilities={[Capabilities.ASSET_CREATE, Capabilities.ASSET_UPDATE]}>
        <MenuSeparator />
        <MenuItem to={`${url}/alerts`} icon="/images/icons/alert_2.svg">
          Alerts
        </MenuItem>
      </RestrictHasOneCapability>
    </Sidebar>
  );
};
