//@ts-nocheck
import Api from "./api";

class LookupData extends Api {
  /**
   * Get lookup data
   * @async
   * @returns {Promise.<Object>} Lookup data.
   */
  static async getLookups() {
    const response = await this.get("/lookups");
    return await response.body;
  }

  /**
   * Get lookup projects
   * @async
   * @returns {Promise.<Object>} Array of lookup projects.
   */
  static async getLookupProjects() {
    const response = await this.get("/lookups/projects");
    return await response.body;
  }
}

export default LookupData;
