import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import classNames from "classnames";
import styled from "styled-components";
import { FixLater } from "types";
import { DragHandle } from "components/Draggable";
import { Text } from "components/Typography";
import { TrashButton } from "components/shared/buttons";
import style from "./dataList.module.scss";

const StyledDataList = styled.div`
  gap: 0.5rem;
`;

export const SortableDataList = SortableContainer((props: FixLater) => <DataList {...props} />);

export const DataList = ({ className, children, ...otherProps }: FixLater) => {
  return (
    <div {...otherProps} className={classNames(style.dataList, className)}>
      {children}
    </div>
  );
};

export const SortableDataListItem = SortableElement((props: FixLater) => {
  return <DataListItem {...props} draggable={true} />;
});

export const DataListItem = ({
  id,
  value,
  children,
  className,
  onClick,
  onRemove,
  draggable = false,
  valueClassName,
}: FixLater) => {
  return (
    <StyledDataList className={classNames(style.dataListItem, className)} onClick={onClick}>
      {draggable && (
        // @ts-ignore
        <DragHandle className={style.dragHandle} />
      )}
      {id && <Text className={style.dataListItemIndex}>{id}</Text>}
      <div className={classNames(style.dataValue, valueClassName)}>{value || children}</div>
      {typeof onRemove === "function" && <TrashButton onClick={onRemove} />}
    </StyledDataList>
  );
};
