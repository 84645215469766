import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  DataResponse,
  PagedDataResponse,
  Pagination,
  TManufacturer,
  UUID,
  FetchCatalogueManufacturersRequest,
} from "types";

export const fetchManufacturers = createAsyncThunk(
  "catalogue/manufacturers/fetchManufacturers",
  async (request: FetchCatalogueManufacturersRequest): Promise<{ results: TManufacturer[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TManufacturer[]>>("/catalogue/manufacturers", request);
    return { results: data, paging: metadata };
  },
);

export const fetchManufacturerById = createAsyncThunk(
  "catalogue/manufacturers/fetchManufacturerById",
  async (id: UUID): Promise<TManufacturer> => {
    const { data } = await API.GET<DataResponse<TManufacturer>>(`/catalogue/manufacturers/${id}`);
    return data;
  },
);
