import get from "lodash/get";
import Logger from "lib/Logger";
import { showPopupFlag } from "lib/actions/userInterface";
import store from "store";
import { FixLater } from "types";

export const handlePopupFlagError = (err: FixLater, defaultErrorMessage = "Something went wrong") => {
  Logger.error(err);
  const message = get(err, "response.body.message") || get(err, "response.data.message");
  store.dispatch(
    showPopupFlag({
      appearance: "error",
      title: message || defaultErrorMessage,
    }),
  );
};

export default handlePopupFlagError;
