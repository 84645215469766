import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import { handleThunkError, ThunkError } from "store/utils/thunk";
import {
  CreateRagBinRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  RagBinType,
  SynchroniseRagBinRagsRequest,
  TRagBin,
  UpdateRagBinRequest,
  UUID,
} from "types";

type FetchRagBinsParams = PaginationQueryParams & {
  ragControllerId?: UUID;
  binType?: RagBinType;
};

export const fetchRagBins = createAsyncThunk(
  "ragBins/fetchRagBins",
  async (params: FetchRagBinsParams): Promise<{ results: TRagBin[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TRagBin[]>>("/rag_bins", params);
    return { results: data, paging: metadata };
  },
);

export const fetchRagBinById = createAsyncThunk("ragBins/fetchRagBinById", async (id: UUID): Promise<TRagBin> => {
  const { data } = await API.GET<DataResponse<TRagBin>>(`/rag_bins/${id}`);
  return data;
});

export const fetchRagBinByStorageId = createAsyncThunk(
  "ragBins/fetchRagBinByStorageId",
  async (storageId: string): Promise<TRagBin> => {
    const { data } = await API.GET<DataResponse<TRagBin>>(`/storages/${storageId}/rag_bin`);
    return data;
  },
);

export const createRagBin = createAsyncThunk(
  "ragBins/createRagBin",
  async (request: CreateRagBinRequest): Promise<TRagBin> => {
    const { data } = await API.POST<CreateRagBinRequest, DataResponse<TRagBin>>("/rag_bins", request);
    return data;
  },
);

export const updateRagBin = createAsyncThunk(
  "ragBins/updateRagBin",
  async (request: UpdateRagBinRequest): Promise<TRagBin> => {
    const { data } = await API.PUT<UpdateRagBinRequest, DataResponse<TRagBin>>(`/rag_bins/${request.id}`, request);
    return data;
  },
);

export const synchroniseRagBinRags = createAsyncThunk(
  "ragBins/synchroniseRagBinRags",
  async (request: SynchroniseRagBinRagsRequest): Promise<TRagBin> => {
    const { data } = await API.POST<SynchroniseRagBinRagsRequest, DataResponse<TRagBin>>(
      `/rag_bins/${request.ragBinId}/synchronise`,
      request,
    );
    return data;
  },
);

export const deleteRagBin = createAsyncThunk<void, UUID, ThunkError>(
  "ragBins/deleteRagBin",
  async (id: UUID, { rejectWithValue }): Promise<void> => {
    try {
      await API.DELETE(`/rag_bins/${id}`);
    } catch (err) {
      return handleThunkError(rejectWithValue, err);
    }
  },
);
