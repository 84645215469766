import classNames from "classnames";
import Icon from "components/Icon";
import style from "./trusted.module.scss";

type TrustedProps = {
  className?: string;
};

export const Trusted = ({ className }: TrustedProps) => {
  return (
    <div className={classNames(style.trusted, className)}>
      <Icon src="/images/icons/secure_1.svg" color="success" className={style.trustedIcon} />
      Trusted
    </div>
  );
};

export default Trusted;
