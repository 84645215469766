//@ts-nocheck
import Api from "./api";

export default class Account extends Api {
  /**
   * Change the password for the authenticated user
   * @async
   * @param {string} password
   * @returns {Promise<void>}
   */
  static async changePassword(password) {
    return await this.post("/account/password").send({ password });
  }

  /**
   * Get the account settings for the authenticated user
   * @async
   * @returns {Promise<Object>}
   */
  static async getAccountSettings() {
    const response = await this.get("/account/settings").send();
    return response.body;
  }

  /**
   * Update the account settings for the authenticated user
   * @async
   * @param {Object} user
   * @returns {Promise<Object>}
   */
  static async updateAccountSettings(user = {}) {
    return await this.put("/account/settings").send(user);
  }
}
