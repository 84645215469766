import { createSelector } from "reselect";
import { RootStore } from "store/reducer";
import { synchroniseRagControllerRags } from "store/reducers/ragControllers/ragControllers.thunks";
import { TRagController, UUID } from "types";

const selectState = (state: RootStore) => state.ragControllers;

export const selectRagControllers = createSelector(selectState, (state) => state.data);

export const selectRagControllersList = createSelector(selectRagControllers, (data) => Object.values(data));

export const selectRagControllerByID = (id: UUID) =>
  createSelector(selectRagControllers, (data): TRagController | undefined => data[id]);

export const isSynchronisingRagControllerRags = createSelector(
  selectState,
  ({ loading }) => loading === synchroniseRagControllerRags.pending.type,
);
