import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "store";
import { fetchVehicleById, selectVehicleByID } from "store/reducers";

type VehicleNameProps = {
  as?: any;
  vehicleId: string;
};

export const VehicleName = ({ as = Fragment, vehicleId }: VehicleNameProps) => {
  const dispatch = useDispatch();
  const vehicle = useSelector(selectVehicleByID(vehicleId));
  const As = as || Fragment;

  useEffect(() => {
    if (!vehicleId) return;
    if (vehicle) return;
    dispatch(fetchVehicleById(vehicleId));
  }, [vehicleId, vehicle]);

  return <As>{vehicle?.registration}</As>;
};
