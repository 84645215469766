//@ts-nocheck
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import get from "lodash/get";
import PropTypes from "prop-types";
import ShopImportColumnTypes from "lib/constants/ShopImportColumnTypes";
import { SmartTable } from "components/Table/SmartTable";

const ItemsCreatedTable = ({ itemsCreated, className }) => {
  const rowData = useMemo(() => itemsCreated, [itemsCreated]);
  const columns = useMemo(() => {
    return [
      {
        Header: ShopImportColumnTypes.resolveItemLabel(ShopImportColumnTypes.NAME),
        accessor: ({ row }) => get(row, "original.name", ""),
        Cell: ({ row }) => {
          const { id, name } = row.original;
          return <Link to={`/apps/shop_manager/items/${id}`}>{name}</Link>;
        },
      },
      {
        Header: ShopImportColumnTypes.resolveItemLabel(ShopImportColumnTypes.SKU),
        accessor: ({ row }) => get(row, "original.sku", ""),
        Cell: ({ row }) => {
          const { sku } = row.original;
          return <div>{sku}</div>;
        },
      },
      {
        Header: ShopImportColumnTypes.resolveItemLabel(ShopImportColumnTypes.PRICE_EXC_VAT),
        accessor: ({ row }) => get(row, "original.priceExcVat", ""),
        Cell: ({ row }) => {
          const { priceExcVat } = row.original;
          return <div>{priceExcVat}</div>;
        },
      },
    ];
  }, []);
  return <SmartTable columns={columns} data={rowData} />;
};

ItemsCreatedTable.defaultProps = {};

ItemsCreatedTable.propTypes = {
  itemsCreated: PropTypes.array,
  className: PropTypes.string,
};

export default ItemsCreatedTable;
