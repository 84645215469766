import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import get from "lodash/get";
import { showPopupFlag } from "lib/actions/userInterface";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { selectRoles, updateRole } from "store/reducers";
import { Pagination, TRole } from "types";
import { TableLink, SmartTable, SmartTablePagination } from "components/Table";
import { Text } from "components/Typography";
import { RoleTableRowDropdown } from "components/shared/features/configurations/roles/RoleTableRowDropdown/RoleTableRowDropdown";

type TeamMembersTableProps = {
  loading: boolean;
  roleIds: number[];
  paging: Pagination;
  onFetch: (pageStart: number, pageLimit: number) => void;
};

type RowData = {
  row: {
    original: TRole;
  };
};

export const RolesTable = ({ loading, roleIds, paging, onFetch }: TeamMembersTableProps) => {
  const { pathname: url } = useLocation();
  const dispatch = useDispatch();
  const roles = useSelector(selectRoles);

  const saveChanges = async (role: TRole) => {
    try {
      await dispatch(updateRole(role)).unwrap();
      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Role updated",
        }),
      );
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const rowData = useMemo(() => {
    return roleIds.map((id) => roles[id]).filter((role) => role && !role.isAdmin);
  }, [roleIds, roles]);
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        Cell: ({ row }: RowData) => {
          const { id } = row.original;
          return (
            <TableLink to={`${url}/${id}`}>
              <Text
                canEdit={false}
                placeholder="Enter role name"
                value={get(row, "original.name", "")}
                onConfirm={(value) =>
                  saveChanges({
                    ...row.original,
                    name: value,
                  })
                }
              />
            </TableLink>
          );
        },
      },
      {
        Header: "Description",
        Cell: ({ row }: RowData) => {
          return (
            <Text
              canEdit={true}
              placeholder="Role description"
              value={get(row, "original.description", "")}
              onConfirm={(value) =>
                saveChanges({
                  ...row.original,
                  description: value,
                })
              }
            />
          );
        },
      },
      {
        Header: "Capabilities",
        Cell: ({ row }: RowData) => {
          const num = get(row, "original.capabilities.length", 0);
          return num ? `${row.original.capabilities.length} capabilities` : "None";
        },
      },
      {
        id: "actions",
        Cell: ({ row }: RowData) => {
          const { id } = row.original;
          return <RoleTableRowDropdown roleId={id} onDelete={() => onFetch(0, paging.pageLimit)} />;
        },
      },
    ];
  }, []);

  return (
    <>
      <SmartTable loading={loading} columns={columns} data={rowData} />
      <SmartTablePagination paging={paging} onChange={onFetch} />
    </>
  );
};
