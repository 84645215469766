import { Fragment, ReactNode, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BiCar } from "react-icons/bi";
import { IoAirplaneOutline } from "react-icons/io5";
import { TbHelicopter } from "react-icons/tb";
import { usePopper } from "react-popper";
import { Menu, Portal } from "@headlessui/react";
import { FilterIcon, XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useDispatch, useSelector } from "store";
import { selectVehiclesFilters, setVehiclesFilter } from "store/reducers";
import { VehiclesQueryFilters } from "types";
import { VehicleStatusIndicator } from "components/shared/features/vehicles/VehicleStatusIndicator/VehicleStatusIndicator";

const MenuItem = ({ icon, label, onClick, selected }: Action) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={classNames(
            "group flex rounded-md items-center w-full px-2 py-2 text-sm",
            selected ? "bg-gray-100 text-gray-900" : active ? "bg-primary-700 text-white" : "text-gray-900",
          )}
          onClick={onClick}
        >
          {icon && <div className="flex items-center justify-center w-5 h-5 mr-2">{icon}</div>}
          {label}
        </button>
      )}
    </Menu.Item>
  );
};

type ActionGroup = {
  heading: string;
  actions: Action[];
};

type Action = {
  label: string;
  icon: ReactNode;
  selected?: boolean;
  onClick?: () => void;
};

export const VehiclesFilterMenu = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectVehiclesFilters);
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const handleChange = (value: VehiclesQueryFilters) => {
    dispatch(setVehiclesFilter(value));
  };

  const addOrRemoveFromList = (listKey: keyof VehiclesQueryFilters, option: string) => {
    return () => {
      if (!Array.isArray(filters[listKey])) return;
      const listCopy = filters[listKey] as Array<string>;
      const exists = listCopy.find((v) => v === option);
      if (exists) {
        handleChange({
          ...filters,
          [listKey]: listCopy.filter((key) => key !== option),
        });
        return;
      }

      handleChange({
        ...filters,
        [listKey]: [...listCopy, option],
      });
    };
  };

  const actions: ActionGroup[] = [
    {
      heading: "Status",
      actions: [
        {
          label: "Ready",
          icon: <VehicleStatusIndicator ready={true} />,
          selected: !!filters.statuses.find((value) => value === "ready"),
          onClick: addOrRemoveFromList("statuses", "ready"),
        },
        {
          label: "Busy",
          icon: <VehicleStatusIndicator ready={false} />,
          selected: !!filters.statuses.find((value) => value === "busy"),
          onClick: addOrRemoveFromList("statuses", "busy"),
        },
      ],
    },
    {
      heading: "Vehicle type",
      actions: [
        {
          label: "Aircraft",
          icon: <IoAirplaneOutline />,
          selected: !!filters.types.find((value) => value === "plane"),
          onClick: addOrRemoveFromList("types", "plane"),
        },
        {
          label: "Helicopter",
          icon: <TbHelicopter />,
          selected: !!filters.types.find((value) => value === "helicopter"),
          onClick: addOrRemoveFromList("types", "helicopter"),
        },
        {
          label: "Van",
          icon: <BiCar />,
          selected: !!filters.types.find((value) => value === "van"),
          onClick: addOrRemoveFromList("types", "van"),
        },
        {
          label: "Miscellaneous",
          icon: <AiOutlineQuestionCircle />,
          selected: !!filters.types.find((value) => value === "miscellaneous"),
          onClick: addOrRemoveFromList("types", "miscellaneous"),
        },
      ],
    },
    {
      heading: "",
      actions: [
        {
          label: "Clear filters",
          icon: <XIcon />,
          onClick: () =>
            handleChange({
              ...filters,
              statuses: [],
              types: [],
            }),
        },
      ],
    },
  ];

  return (
    <Menu as={Fragment}>
      <Menu.Button
        ref={setReferenceElement}
        className="relative -ml-px inline-flex items-center space-x-2 rounded-r-xl bg-gray-50 px-4 text-sm font-medium text-gray-700 hover:bg-gray-100"
      >
        <FilterIcon className="h-5 w-5 text-gray-400" />
        <span>Refine</span>
      </Menu.Button>
      <Portal>
        <Menu.Items
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {actions.map((group, index) => (
            <div key={`group_${index}`} className="flex flex-col px-1 py-1 gap-y-px">
              {group.heading && <p className="my-1 px-2 text-sm text-gray-500">{group.heading}</p>}
              {group.actions.map((options) => (
                <MenuItem key={`${group.heading}_${options.label}`} {...options} />
              ))}
            </div>
          ))}
        </Menu.Items>
      </Portal>
    </Menu>
  );
};
