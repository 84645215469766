import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { requestAssetsMetrics, requestStoragesMetrics } from "lib/actions/statistics";
import { getAssetsMetrics, getStoragesMetrics } from "lib/reducers/statistics.selectors";
import { useSelector } from "store";

export const DashboardMetrics = () => {
  const dispatch = useDispatch();
  const storageMetrics = useSelector(getStoragesMetrics);
  const assetMetrics = useSelector(getAssetsMetrics);

  useEffect(() => {
    dispatch(requestStoragesMetrics());
    dispatch(requestAssetsMetrics());
  }, []);

  if (!storageMetrics || !assetMetrics) {
    return null;
  }

  const stats = [
    {
      name: "Devices",
      value: storageMetrics.totalCount,
      link: "/apps/storage/toolboxes",
    },
    {
      name: "Tools",
      value: assetMetrics.totalCount,
      link: "/apps/tracking",
    },
    {
      name: "Tools in-use",
      value: assetMetrics.totalCount - assetMetrics.availableCount,
      link: "/apps/tracking/assets?assigned=true",
    },
    {
      name: "Outstanding tool issues",
      value: assetMetrics.outstandingAssetIssuesCount,
      link: "/apps/tracking/issues",
    },
  ];
  return (
    <div className="rounded-lg ring-1 ring-slate-900/10 overflow-hidden">
      <dl className="mx-auto grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((stat) => (
          <Link key={stat.link} to={stat.link}>
            <div
              key={stat.name}
              className="flex flex-wrap items-baseline justify-between gap-x-4 bg-white px-4 py-4 sm:gap-y-2 sm:py-10 sm:px-6 xl:px-8 hover:bg-gray-100"
            >
              <dt className="font-medium leading-6 text-gray-500">{stat.name}</dt>
              <dd className="w-full flex-none text-2xl sm:text-4xl font-medium leading-10 tracking-tight text-gray-900">
                {stat.value}
              </dd>
            </div>
          </Link>
        ))}
      </dl>
    </div>
  );
};
