//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import Logger from "../Logger";
import * as type from "../actions/actionTypes";
import * as actions from "../actions/tasks";
import Api from "../api/tasks";

function* fetchTask(action) {
  try {
    const task = yield Api.getTask(action.payload);
    yield put(actions.receiveTask(task));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestTaskFailure(err));
  }
}

function* fetchTasks(action) {
  try {
    const { data, metadata } = yield Api.getTasks(action.payload);
    yield put(actions.receiveTasks(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestTasksFailure(err));
  }
}

function* fetchTaskBlocks(action) {
  try {
    const { data, metadata } = yield Api.getTaskBlocks(action.payload.taskID, action.payload.options);
    yield put(actions.receiveTaskBlocks(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.requestTaskBlocksFailure(err));
  }
}

function* fetchTaskLists(action) {
  try {
    const { data, metadata } = yield Api.getTaskLists(action.payload);
    yield put(actions.fulfillRequestTaskLists(data, metadata));
  } catch (err) {
    Logger.error(err);
    yield put(actions.rejectRequestTaskLists(err));
  }
}

function* tasksSaga() {
  yield takeLatest(type.GET_TASK_REQUEST, fetchTask);
  yield takeLatest(type.GET_TASKS_REQUEST, fetchTasks);
  yield takeLatest(type.GET_TASK_LISTS_REQUEST, fetchTaskLists);
  yield takeLatest(type.GET_TASK_BLOCKS_REQUEST, fetchTaskBlocks);
}

export default tasksSaga;
