//@ts-nocheck
import cuid from "cuid";
import ReconnectingWebSocket from "reconnecting-websocket";
import { receiveCompanyBranding } from "lib/actions/lookupData";
import { receiveSentryConfiguration } from "lib/actions/sentry";
import { receiveStorageStatus } from "lib/actions/storages";
import { showPopupFlag } from "lib/actions/userInterface";
import Logger from "./Logger";
import { receiveAssignedAssets } from "./actions/assets";
import { receiveStorage, receiveStorageStatistics, receiveStorageSession } from "./actions/storages";
import store from "./reduxStore";

export const Headers = {
  // Internal events
  SOCKET_CONNECT: "socket.connect",
  SOCKET_DISCONNECT: "socket.disconnect",

  // From Sentry backend
  GET_SENTRY_CONFIGURATION: "sync.web.sentry.configuration",
  GET_STORAGE_SYSTEM_STATUS: "sync.web.storage.system.status",
  GET_STORAGE_SYSTEM_BOARD_STATUS: "sync.web.storage.system.board.status",
  GET_STORAGE_SYSTEM_KEYCARD_READ: "sync.web.storage.system.board.keycard_read",
  GET_STORAGE_SYSTEM_SPEEDWAY_STATUS: "sync.web.storage.system.speedway.status",
  SET_STORAGE_DRAWERS_LOCKED: "sync.web.storage.system.drawers.lock",
  SET_STORAGE_DRAWERS_UNLOCKED: "sync.web.storage.system.drawers.unlock",
  GET_STORAGE_HEARTBEAT: "sync.web.storage.heartbeat",
  GET_STORAGE: "sync.web.storage",
  GET_STORAGE_SESSION: "sync.web.storage.session",
  GET_STORAGE_SESSION_START: "sync.web.storage.session.start",
  GET_STORAGE_SESSION_CLOSING: "sync.web.storage.session.closing",
  GET_STORAGE_SESSION_REVIEW: "sync.web.storage.session.review",
  GET_STORAGE_SESSION_CLOSE: "sync.web.storage.session.close",
  GET_STORAGE_STATISTICS: "sync.web.storage.statistics",
  GET_STORAGE_ACCESS_ATTEMPT: "sync.web.storage.access.challenge.result",
  STORAGE_UNSUPPORTED_KEYCARD: "sync.web.storage.access.keycard_unsupported",
  GET_ASSETS_ASSIGNED: "sync.web.assets.assigned",
  GET_ASSETS_FLAG_ISSUE: "sync.web.assets.flagissue",
  GET_STORAGE_SESSION_UNDO: "sync.web.storage.session.undo",
  SET_USER_PROJECT: "sync.web.user.project.set",
  GET_COMPANY_BRANDING: "sync.web.branding",
};

class Socket {
  /**
   *
   * @type {boolean}
   * @private
   */
  _isConnected = false;

  /**
   *
   * @type {ReconnectingWebSocket}
   * @private
   */
  _socket = null;

  /**
   * Hashmap of message subscriptions
   */
  _subscriptions = {};

  /**
   * Connect to the server
   * @param url
   * @returns {Promise<Socket>}
   */
  connect = (url) => {
    if (url === "/ws") {
      url = this._makeRelativeURL("ws");
    }

    const ws = new ReconnectingWebSocket(url, [], {
      maxEnqueuedMessages: 5,
      reconnectDecay: 1.1,
      connectionTimeout: 10 * 1000,
      minUptime: 2 * 1000,
      reconnectInterval: 2 * 1000,
      minReconnectionDelay: 2 * 1000,
      maxReconnectionDelay: 5 * 1000,
    });

    ws.addEventListener("open", (event) => {
      this._isConnected = true;
      this._emitToSubscribers(Headers.SOCKET_CONNECT, null);
    });
    ws.addEventListener("close", (event) => {
      this._isConnected = false;
      this._emitToSubscribers(Headers.SOCKET_DISCONNECT, null);
    });
    ws.addEventListener("error", (event) => {
      Logger.debug(`ws error`, event);
    });
    ws.addEventListener("message", this._onMessage);

    this._socket = ws;
  };

  /**
   * Subscribe to messages with a given header
   * @param {string} header
   * @param {function} onMessage
   * @returns {string}
   */
  subscribe = (header, onMessage) => {
    const subscriptionId = cuid();
    if (typeof onMessage !== "function") {
      throw new Error("expected onMessage to be a function");
    }
    this._subscriptions[subscriptionId] = {
      header,
      callback: onMessage,
    };
    return subscriptionId;
  };

  /**
   * Cancel a previously create subscription
   * @param {string} subscriptionId
   */
  unsubscribe = (subscriptionId) => {
    delete this._subscriptions[subscriptionId];
  };

  /**
   * Send a message to the server
   * @param header
   * @param body
   */
  send = (header, body = null) => {
    const message = {
      header: header,
      body: btoa(JSON.stringify(body)),
    };

    this._socket.send(JSON.stringify(message));
    Logger.debug(`[sync]: sent '${message.header}'`);
  };

  /**
   * Returns true if the socket is connected to the server
   * @returns {*}
   */
  isConnected = () => {
    return this._isConnected;
  };

  /**
   * Handle a received message
   * @param event
   * @private
   */
  _onMessage = (event) => {
    const message = JSON.parse(event.data);
    const data = JSON.parse(atob(message.body));

    // Logger.debug(`[sync]: received '${message.header}'`);

    // Emit via subscriptions
    this._emitToSubscribers(message, data);

    // Emit via redux
    switch (message.header) {
      case Headers.GET_STORAGE:
        store.dispatch(receiveStorage(data));
        break;
      case Headers.GET_STORAGE_STATISTICS:
        store.dispatch(receiveStorageStatistics(data.totalCount, data.assignedCount));
        break;
      case Headers.GET_STORAGE_SESSION:
        store.dispatch(receiveStorageSession(data));
        break;
      case Headers.GET_STORAGE_SYSTEM_BOARD_STATUS:
        store.dispatch(receiveStorageStatus(data));
        store.dispatch(receiveStorageSession(data.session || null));
        break;
      case Headers.GET_STORAGE_SESSION_START:
      case Headers.GET_STORAGE_SESSION_CLOSING:
      case Headers.GET_STORAGE_SESSION_REVIEW:
        this.send(Headers.GET_STORAGE_SESSION);
        break;
      case Headers.GET_STORAGE_SESSION_CLOSE:
        store.dispatch(receiveStorageSession(null));
        break;
      case Headers.GET_ASSETS_ASSIGNED:
        store.dispatch(receiveAssignedAssets(data));
        break;
      case Headers.GET_COMPANY_BRANDING:
        store.dispatch(receiveCompanyBranding(data.configuration));
        break;
      case Headers.GET_SENTRY_CONFIGURATION:
        store.dispatch(receiveSentryConfiguration(data));
        break;
      case Headers.STORAGE_UNSUPPORTED_KEYCARD:
        store.dispatch(
          showPopupFlag(
            {
              appearance: "error",
              title: "Unsupported Keycard",
            },
            8000,
          ),
        );
        break;
    }
  };

  _emitToSubscribers = (message, data) => {
    const subscriptions = Object.keys(this._subscriptions);
    subscriptions.forEach((subscriptionId) => {
      const subscription = this._subscriptions[subscriptionId];
      if (subscription && subscription.header === message.header && typeof subscription.callback === "function") {
        subscription.callback(data);
      }
    });
  };

  _makeRelativeURL = (url) => {
    const { location } = window;
    const port = location.port !== 80 && location.port !== 443 ? `:${location.port}` : "";
    return (location.protocol === "https:" ? "wss://" : "ws://") + location.hostname + port + location.pathname + url;
  };
}

const socket = new Socket();

if (process.env.REACT_APP_PORTAL === "storage") {
  socket.connect(`${process.env.REACT_APP_API_URL}/ws`);
}

export default socket;
