import { UUID } from "types";
import { AlertRecipient } from "components/shared/features/alerts/AlertRecipient/AlertRecipient";

type AlertEmailAddressesProps = {
  alertId: UUID;
};

export const AlertEmailAddresses = ({ alertId }: AlertEmailAddressesProps) => {
  return <AlertRecipient name="emailAddress" placeholder="Add an email address to alert" alertId={alertId} />;
};
