import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateToolRoomRequest,
  DataResponse,
  PagedDataResponse,
  Pagination,
  PaginationQueryParams,
  TToolRoom,
  UpdateToolRoomRequest,
  UUID,
} from "types";

type FetchToolRoomsParams = PaginationQueryParams;

export const fetchToolRooms = createAsyncThunk(
  "toolRooms/list",
  async (params: FetchToolRoomsParams): Promise<{ results: TToolRoom[]; paging: Pagination }> => {
    const { data, metadata } = await API.GET<PagedDataResponse<TToolRoom[]>>("/tool_rooms", params);
    return { results: data, paging: metadata };
  },
);

export const fetchToolRoomById = createAsyncThunk("toolRooms/getById", async (id: UUID): Promise<TToolRoom> => {
  const { data } = await API.GET<DataResponse<TToolRoom>>(`/tool_rooms/${id}`);
  return data;
});

export const fetchToolRoomByStorageId = createAsyncThunk(
  "toolRooms/fetchToolRoomByStorageId",
  async (storageId: string): Promise<TToolRoom> => {
    const { data } = await API.GET<DataResponse<TToolRoom>>(`/storages/${storageId}/tool_room`);
    return data;
  },
);

export const createToolRoom = createAsyncThunk(
  "toolRooms/create",
  async (request: CreateToolRoomRequest): Promise<TToolRoom> => {
    const { data } = await API.POST<CreateToolRoomRequest, DataResponse<TToolRoom>>("/tool_rooms", request);
    return data;
  },
);

export const updateToolRoom = createAsyncThunk(
  "toolRooms/update",
  async (request: UpdateToolRoomRequest): Promise<TToolRoom> => {
    const { data } = await API.PUT<UpdateToolRoomRequest, DataResponse<TToolRoom>>(
      `/tool_rooms/${request.id}`,
      request,
    );
    return data;
  },
);

export const deleteToolRoom = createAsyncThunk("toolRooms/delete", async (id: UUID): Promise<void> => {
  await API.DELETE(`/tool_rooms/${id}`);
});
