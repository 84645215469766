//@ts-nocheck
import Lookup from "lib/constants/Lookup";

class ProjectPriority extends Lookup {
  Unknown = 0;
  Low = 1;
  Medium = 2;
  High = 3;
  Urgent = 4;

  constructor() {
    super();
    super._items = [
      {
        value: this.Low,
        label: "Low",
      },
      {
        value: this.Medium,
        label: "Medium",
      },
      {
        value: this.High,
        label: "High",
      },
      {
        value: this.Urgent,
        label: "Urgent",
      },
    ];
  }
}

export default new ProjectPriority();
