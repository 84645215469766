//@ts-nocheck
import React from "react";
import { Portal } from "react-portal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  pageBlocksChange,
  selectBlockID,
  setCurrentBlockID,
  showBlockMenuFor,
  showMediaMenuFor,
} from "lib/actions/blocks";
import BlockAPI from "lib/api/blocks";
import BlockProperties from "lib/blocks/BlockProperties";
import BlockTypes, { BlockCategory } from "lib/constants/BlockTypes";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { getBlockMenuNode, getCurrentBlockID, getPageBlocks } from "lib/reducers/blocks.selectors";
import Card from "components/Card";
import KeyHandler, { KEY_CODE_ESC } from "components/KeyHandler";
import Overlay from "components/Overlay";
import { PortalMenu } from "components/Sheet/components/PortalCommon";
import { getDOMNodeForBlockID, insertBlockAfterSibling } from "components/Sheet/utils";
import { Text } from "components/Typography";
import style from "../sheet.module.scss";

const BlockItem = ({ blockType, onClick }) => {
  return (
    <li onClick={() => onClick(blockType)}>
      <Text>{blockType.label}</Text>
      <Text className={style.blockDescription}>{blockType.description}</Text>
    </li>
  );
};

const BlockMenu = ({ parentNode, onBlockClick }) => {
  const { taskID } = useParams();

  // Cannot create a task list from within a task
  const listBlockTypes = BlockTypes.getTypesForCategory(BlockCategory.LIST).filter(
    ({ value }) => !(value === BlockTypes.TASK_LIST && taskID),
  );

  return (
    <PortalMenu parentNode={parentNode}>
      <Card className={style.blockMenuCard}>
        <Text className={style.sectionTitle}>BASIC CONTENT</Text>
        <ul>
          {BlockTypes.getTypesForCategory(BlockCategory.BASIC).map((blockType) => {
            return <BlockItem key={blockType.value} blockType={blockType} onClick={onBlockClick} />;
          })}
        </ul>
        <Text className={style.sectionTitle}>MEDIA CONTENT</Text>
        <ul>
          {BlockTypes.getTypesForCategory(BlockCategory.MEDIA).map((blockType) => {
            return <BlockItem key={blockType.value} blockType={blockType} onClick={onBlockClick} />;
          })}
        </ul>
        <Text className={style.sectionTitle}>LISTS</Text>
        <ul>
          {listBlockTypes.map((blockType) => {
            return <BlockItem key={blockType.value} blockType={blockType} onClick={onBlockClick} />;
          })}
        </ul>
      </Card>
    </PortalMenu>
  );
};

const BlockMenuPortal = ({ onCreateBlock }) => {
  const dispatch = useDispatch();
  const pageBlocks = useSelector(getPageBlocks);
  const node = useSelector(getBlockMenuNode);
  const currentBlockID = useSelector(getCurrentBlockID);

  const handleClose = () => {
    dispatch(showBlockMenuFor(null));
  };

  const addBlock = async (blockType) => {
    try {
      if (blockType.value === BlockTypes.TASK_LIST) {
        // TODO: Create task list
      }

      // Create the new block
      const newBlock = await BlockAPI.createBlock({
        type: blockType.value,
        properties: BlockProperties.createForBlockType(blockType.value),
      });

      const newPageBlocks = insertBlockAfterSibling(pageBlocks, currentBlockID, newBlock);
      dispatch(pageBlocksChange(newPageBlocks));
      dispatch(selectBlockID(newBlock.id));
      dispatch(setCurrentBlockID(newBlock.id));
      dispatch(showBlockMenuFor(null));

      // Open media menu if necessary
      if (blockType.value === BlockTypes.YOUTUBE) {
        dispatch(showMediaMenuFor(getDOMNodeForBlockID(newBlock.id)));
      }

      onCreateBlock && onCreateBlock(newPageBlocks);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  return (
    node && (
      <Portal>
        <Overlay onClick={handleClose} isTransparent={true} className={style.overlay} />
        <KeyHandler keyCode={KEY_CODE_ESC} onKeyHandle={handleClose} />
        <BlockMenu parentNode={node} onBlockClick={addBlock} />
      </Portal>
    )
  );
};

BlockMenuPortal.defaultProps = {};

BlockMenuPortal.propTypes = {
  className: PropTypes.string,
};

export default BlockMenuPortal;
