//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FixLater } from "types";
import style from "./table.module.scss";

const TableCell = ({ colSpan, iconOnly, className, children, ...otherProps }: FixLater) => {
  const classes = classNames(style.tableCell, { [style.cellIcon]: iconOnly }, className);
  return (
    <td {...otherProps} colSpan={colSpan} className={classes}>
      {children}
    </td>
  );
};

TableCell.defaultProps = {
  className: "",
  colSpan: 1,
  iconOnly: false,
};

TableCell.propTypes = {
  iconOnly: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  colSpan: PropTypes.number,
};

export default TableCell;
