//@ts-nocheck
import React from "react";
import classNames from "classnames";
import { closeModal } from "lib/actions/modal";
import Modals from "lib/constants/Modals";
import { getModal } from "lib/reducers/userInterface.selectors";
import { useSelector } from "store";
import store from "store";
import KeyHandler, { KEY_CODE_ESC } from "components/KeyHandler";
import Overlay from "components/Overlay";
import AssignStorageAssetModal from "components/modals/AssignStorageAssetModal/AssignStorageAssetModal";
import ConfirmationModal from "components/modals/ConfirmationModal";
import CreateAccessRuleModal from "components/modals/CreateAccessRuleModal";
import CreateAlertModal from "components/modals/CreateAlertModal";
import CreateKeycardModal from "components/modals/CreateKeycardModal";
import { CreateManufacturerModal } from "components/modals/CreateManufacturerModal/CreateManufacturerModal";
import { CreateManufacturerProductModal } from "components/modals/CreateManufacturerProductModal/CreateManufacturerProductModal";
import CreateProjectModal from "components/modals/CreateProjectModal";
import CreateProjectTemplateModal from "components/modals/CreateProjectTemplateModal";
import CreateReportModal from "components/modals/CreateReportModal/CreateReportModal";
import CreateRoleModal from "components/modals/CreateRoleModal";
import CreateStoreItemModal from "components/modals/CreateStoreItemModal";
import CreateTaskModal from "components/modals/CreateTaskModal";
import InviteUser from "components/modals/InviteUser";
import ManualCheckinModal from "components/modals/ManualCheckinModal";
import ManualCheckoutModal from "components/modals/ManualCheckoutModal";
import ViewAccessRuleModal from "components/modals/ViewAccessRuleModal";
import { ConfigureStorageAssetLayoutModal, ImportAssetsModal } from "components/shared";
import { ImportProductsModal } from "components/shared/features/dataimport/modals/ImportProductsModal/ImportProductsModal";
import { ConfigureDrawerLayoutModal } from "components/shared/features/storages/modals/ConfigureDrawerLayoutModal/ConfigureDrawerLayoutModal";
import style from "./modal.module.scss";

const RootModal = () => {
  let modal = useSelector(getModal);

  if (!modal) {
    return null;
  }

  const handleDismiss = () => {
    store.dispatch(closeModal());
  };

  let component = null;
  const modalProps = {
    modal,
    onClose: handleDismiss,
  };
  switch (modal.name) {
    case Modals.MANUAL_CHECKOUT:
      component = <ManualCheckoutModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.MANUAL_CHECKIN:
      component = <ManualCheckinModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.ACCESS_RULE_CREATE:
      component = <CreateAccessRuleModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.ACCESS_RULE_VIEW:
      component = <ViewAccessRuleModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.INVITE_USER:
      component = <InviteUser modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CONFIRMATION:
      component = <ConfirmationModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.ASSIGN_STORAGE_ASSET:
      component = <AssignStorageAssetModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CREATE_REPORT:
      component = <CreateReportModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CREATE_PROJECT:
      component = <CreateProjectModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CREATE_TASK:
      component = <CreateTaskModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CREATE_ROLE:
      component = <CreateRoleModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CREATE_KEYCARD:
      component = <CreateKeycardModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CREATE_PROJECT_TEMPLATE:
      component = <CreateProjectTemplateModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CREATE_STORE_ITEM:
      component = <CreateStoreItemModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CREATE_ALERT:
      component = <CreateAlertModal modal={modal} onClose={handleDismiss} />;
      break;
    case Modals.CREATE_MANUFACTURER:
      component = <CreateManufacturerModal {...modalProps} />;
      break;
    case Modals.CREATE_MANUFACTURER_PRODUCT:
      component = <CreateManufacturerProductModal {...modalProps} />;
      break;
    case Modals.IMPORT_MANUFACTURER_PRODUCT:
      component = <ImportProductsModal {...modalProps} />;
      break;
    case Modals.IMPORT_ASSETS:
      component = <ImportAssetsModal {...modalProps} />;
      break;
    case Modals.CONFIGURE_STORAGE_DRAWER_LAYOUT:
      component = <ConfigureDrawerLayoutModal {...modalProps} />;
      break;
    case Modals.CONFIGURE_STORAGE_ASSET_LAYOUT:
      component = <ConfigureStorageAssetLayoutModal {...modalProps} />;
      break;
  }

  return component ? (
    <div className={classNames(style.container)}>
      <KeyHandler keyCode={KEY_CODE_ESC} onKeyHandle={handleDismiss} />
      <Overlay onClick={handleDismiss} isDark={true} />
      {component}
    </div>
  ) : null;
};

RootModal.defaultProps = {};

RootModal.propTypes = {};

export default RootModal;
