import { ReactNode } from "react";
import ReactTooltip, { Place, Effect } from "react-tooltip";
import styled from "styled-components";

const StyledTooltip = styled(ReactTooltip)`
  display: flex !important;
  flex-direction: row;
  border-radius: 0.5rem;
  background-color: rgb(51, 65, 85) !important;
  padding: 0.5rem 0.75rem !important;
  align-items: center;
  justify-content: left;
`;

type TooltipProps = {
  id: string;
  place?: Place;
  effect?: Effect;
  children: ReactNode;
};

export const Tooltip = ({ id, place = "top", effect = "solid", children }: TooltipProps) => {
  return (
    <StyledTooltip id={id} place={place} effect={effect} arrowColor="rgb(51, 65, 85)">
      {children}
    </StyledTooltip>
  );
};
