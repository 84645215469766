//@ts-nocheck
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import style from "./swimlane.module.scss";

const Swimlane = ({ className, children }) => {
  return <div className={classNames(style.swimlane, className)}>{children}</div>;
};

Swimlane.defaultProps = {};

Swimlane.propTypes = {
  className: PropTypes.string,
};

export default Swimlane;
