//@ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import Icon from "components/Icon";
import style from "../builder.module.scss";

const TableSpec = ({ layout, component }) => {
  return (
    <div className={style.sectionTextValue}>
      <Icon src={component.placeholder} size="" className={style.tablePlaceholder} />
    </div>
  );
};

TableSpec.defaultProps = {};

TableSpec.propTypes = {
  layout: PropTypes.object,
  component: PropTypes.object,
  className: PropTypes.string,
};

export default TableSpec;
