//@ts-nocheck
import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import StageHeader from "components/StageHeader";
import { Table, TableCell, TableHeader, TableLink, TableRow } from "components/Table";
import TableBody from "components/Table/TableBody";
import TableHeadCell from "components/Table/TableHeadCell";
import TablePagination from "components/Table/TablePagination";
import Trusted from "components/Trusted";
import { Anchor } from "components/Typography";
import style from "../../form.module.scss";

const renderEvent = (event) => {
  return <span>{event.type}</span>;
};

const renderActioner = (event) => {
  if (!event.user) {
    return null;
  }
  let name = event.user.username;
  if (event.user.forename || event.user.surname) {
    name = [event.user.forename, event.user.surname].join(" ");
  }
  return <Anchor to={`/configuration/users/${event.userId}`}>{name}</Anchor>;
};

const renderWhen = (event) => {
  const when = moment(event.dateCreated);
  const since = moment().diff(when, "hours");
  return (
    <span className={style.when}>
      {since === 0 ? when.fromNow() : when.calendar(null, { sameElse: "DD/MM/YYYY [at] h:mm A" })}
    </span>
  );
};

const AssetHistory = ({ events, paging, handlePrevPage, handleNextPage, handleLimitChange }) => (
  <>
    <StageHeader title="Activity history" />
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeadCell>Event</TableHeadCell>
          <TableHeadCell isEmpty={true} />
        </TableRow>
      </TableHeader>
      <TableBody>
        {events.map((event) => (
          <TableRow key={event.id}>
            <TableCell>
              <div>{renderEvent(event)}</div>
              <div>
                {renderActioner(event)} {renderWhen(event)}
              </div>
            </TableCell>
            <TableCell>
              {event.evidenceId ? (
                <TableLink to={`/evidences/${event.evidenceId}`} target="_blank">
                  <Trusted />
                </TableLink>
              ) : null}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <TablePagination
      onPrevPage={handlePrevPage}
      onNextPage={handleNextPage}
      paging={paging}
      onChangeLimit={handleLimitChange}
    />
  </>
);

AssetHistory.defaultProps = {};

AssetHistory.propTypes = {
  events: PropTypes.array,
  paging: PropTypes.object,
  handlePrevPage: PropTypes.func,
  handleNextPage: PropTypes.func,
  handleLimitChange: PropTypes.func,
};

export default AssetHistory;
