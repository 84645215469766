import React, { FormEvent, useEffect, useState } from "react";
import { HiPaperClip } from "react-icons/hi";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useModal } from "effects";
import styled from "styled-components";
import Utils from "lib/Utils";
import { showPopupFlag } from "lib/actions/userInterface";
import Files from "lib/api/files";
import { CalibrationIntervals, CalibrationResultOption, CalibrationResults } from "lib/constants/Calibration";
import CalibrationTrackingMethods from "lib/constants/CalibrationTrackingMethods";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { useDispatch, useSelector } from "store";
import { createCalibrationCertificate, selectAssetByGUID } from "store/reducers";
import { CreateCalibrationCertificateRequest, FixLater, TCalibrationCertificate, TFile, UUID } from "types";
import { DateInput, FileInput, NumberInput, TextInput } from "components/Input";
import RadioInput from "components/Input/RadioInput";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { Modal, ModalContent, ModalHeader } from "components/modals/Modal";
import { HSpace, ModalPrimaryActionButton, ModalSecondaryActionButton, VSpace } from "components/shared";
import { TrashButton } from "components/shared/buttons";
import { Heading, TextBody } from "components/shared/typography";

dayjs.extend(utc);

const FileIcon = styled(HiPaperClip)`
  width: 1rem;
  height: 1rem;
`;

const FileListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FileName = styled(TextBody)`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
`;

const calculateNextDueDate = (config: FixLater) => {
  if (!config) {
    return "";
  }
  if (!config.timeInterval) {
    return "";
  }
  const unit = config.timeInterval === CalibrationIntervals.QUARTER ? CalibrationIntervals.MONTH : config.timeInterval;
  const count = config.timeInterval === CalibrationIntervals.QUARTER ? 3 : 1;
  return dayjs().add(count, unit).format("YYYY-MM-DD");
};

type CalibrationResultProps = {
  value: FixLater;
  onChange: (value?: CalibrationResultOption) => void;
};

const CalibrationResult = ({ value, onChange }: CalibrationResultProps) => {
  return (
    <FieldGroup label="Calibration test result">
      <TextBody textColor="text-gray-400">Specify whether or not the tool passed calibration</TextBody>
      <RadioInput options={CalibrationResults.items} value={value} onChange={onChange} />
    </FieldGroup>
  );
};

type CreateCalibrationEventModalProps = {
  assetId: UUID;
  onSuccess: (value: TCalibrationCertificate) => void;
};

export const CreateCalibrationEventModal = ({ assetId, onSuccess }: CreateCalibrationEventModalProps) => {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const asset = useSelector(selectAssetByGUID(assetId));
  const { calibrationConfig } = asset || {};
  const [submitting, isSubmitting] = useState(false);
  const [values, setValues] = useState<Partial<CreateCalibrationCertificateRequest>>({
    assetId,
    certificateNumber: undefined,
    result: undefined,
    dateCalibrated: "",
    dateNextDue: undefined,
    procedureNumber: undefined,
    tolerance: undefined,
    temperature: undefined,
    humidity: undefined,
    performedBy: undefined,
    attachments: [],
  });
  const [files, setFiles] = useState<TFile[]>([]);
  const invalid = !(values.certificateNumber && values.result && values.dateCalibrated);

  useEffect(() => {
    if (!calibrationConfig) return;
    setValues((values) => ({
      ...values,
      dateNextDue: calculateNextDueDate(calibrationConfig),
    }));
  }, [calibrationConfig]);

  const handleChange = (name: string, value: FixLater) =>
    setValues((values: FixLater) => ({ ...values, [name]: value }));

  const handleSubmit = async (event: FormEvent) => {
    try {
      event.preventDefault();
      isSubmitting(true);
      if (!values.result) return;
      if (!values.certificateNumber) return;
      if (!values.dateCalibrated) return;

      const response = await dispatch(
        createCalibrationCertificate({
          ...values,
          assetId,
          result: values.result,
          certificateNumber: values.certificateNumber,
          dateCalibrated: dayjs.utc(values.dateCalibrated).toISOString(),
          dateNextDue: values.dateNextDue ? dayjs.utc(values.dateNextDue).toISOString() : undefined,
          attachments: files.length ? files.map((f) => f.id) : undefined,
        }),
      ).unwrap();

      dispatch(
        showPopupFlag({
          appearance: "success",
          title: "Calibration certificate submitted",
        }),
      );

      onSuccess(response);
      closeModal();
    } catch (err) {
      handlePopupFlagError(err);
    } finally {
      isSubmitting(false);
    }
  };

  const handleUploadFiles = async (name: string, files: File[]) => {
    try {
      if (!files.length) return;
      const entities = await Promise.all(files.map((file: File) => Files.upload(file)));
      setFiles((files) => [...files, ...entities]);
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleRemoveFile = (fileId: number) => {
    setFiles((files) => files.filter((f) => f.id !== fileId));
  };

  if (!calibrationConfig) return;

  return (
    <Modal>
      <ModalHeader title="Submit calibration certificate" />
      <ModalContent>
        <VSpace>
          <Heading level="h3">Certificate information</Heading>
          <FieldGroup label="When did the calibration occur?">
            <TextBody textColor="text-gray-400">Select the date when calibration took place</TextBody>
            <DateInput
              input={{
                name: "dateCalibrated",
                placeholder: "Pick a date",
              }}
              value={values.dateCalibrated}
              onChange={handleChange}
            />
          </FieldGroup>

          <FieldGroup label="Who performed the calibration?">
            <TextBody textColor="text-gray-400">Specify who performed the calibration</TextBody>
            <TextInput
              input={{
                name: "performedBy",
                placeholder: "Who performed the calibration?",
              }}
              value={values.performedBy}
              onChange={handleChange}
            />
          </FieldGroup>

          <FieldGroup label="Tolerance +/-">
            <TextBody textColor="text-gray-400">Specify the certified accuracy tolerance</TextBody>
            <NumberInput
              input={{
                name: "tolerance",
                step: "0.01",
                placeholder: "Enter tolerance",
              }}
              value={values.tolerance}
              onChange={handleChange}
            />
          </FieldGroup>

          <FieldGroup label="Temperature">
            <TextBody textColor="text-gray-400">Ambient temperature under which the calibration occurred</TextBody>
            <NumberInput
              input={{
                name: "temperature",
                step: "0.01",
                placeholder: "Enter measured temperature",
              }}
              value={values.temperature}
              onChange={handleChange}
            />
          </FieldGroup>

          <FieldGroup label="Humidity">
            <TextBody textColor="text-gray-400">Ambient humidity under which the calibration occurred</TextBody>
            <NumberInput
              input={{
                name: "humidity",
                step: "0.01",
                placeholder: "Enter measured humidity",
              }}
              value={values.humidity}
              onChange={handleChange}
            />
          </FieldGroup>

          <Heading level="h3">Testing standards</Heading>
          <FieldGroup label="Calibration procedure">
            <TextBody textColor="text-gray-400">Specify the standards procedure used to perform calibration</TextBody>
            <TextInput
              input={{
                name: "procedureNumber",
                placeholder: "Enter procedure number",
              }}
              value={values.procedureNumber}
              onChange={handleChange}
            />
          </FieldGroup>

          <Heading level="h3">Outcomes</Heading>
          <CalibrationResult
            value={values.result}
            onChange={(value?: CalibrationResultOption) => handleChange("result", value?.value)}
          />

          <FieldGroup label="Certificate number">
            <TextBody textColor="text-gray-400">The calibration certificate number</TextBody>
            <TextInput
              input={{
                name: "certificateNumber",
                placeholder: "Enter certificate number",
              }}
              value={values.certificateNumber}
              onChange={handleChange}
            />
          </FieldGroup>

          {calibrationConfig.methodId !== CalibrationTrackingMethods.ACTUATION ? (
            <FieldGroup label="When is calibration next due?">
              <TextBody textColor="text-gray-400">Specify when the day when the next calibration is due</TextBody>
              <DateInput
                input={{
                  name: "dateNextDue",
                  openDirection: "up",
                  placeholder: "Pick a date",
                }}
                value={values.dateNextDue}
                onChange={handleChange}
              />
            </FieldGroup>
          ) : null}

          <FieldGroup label="Attachments">
            <VSpace>
              <VSpace gap="gap-2">
                {files.map((file: TFile) => (
                  <FileListItem key={file.id}>
                    <FileName>
                      <FileIcon />
                      <a href={file.url} target="_blank" rel="noreferrer">
                        {file.fileName}
                      </a>
                      <TextBody textSize="text-sm">{Utils.formatBytesToSize(file.size)}</TextBody>
                    </FileName>
                    <TrashButton onClick={() => handleRemoveFile(file.id)} />
                  </FileListItem>
                ))}
              </VSpace>
              <FileInput input={{ name: "file" }} value={[]} onChange={handleUploadFiles} />
            </VSpace>
          </FieldGroup>
        </VSpace>
        <HSpace>
          <ModalSecondaryActionButton onClick={closeModal}>Cancel</ModalSecondaryActionButton>
          <ModalPrimaryActionButton disabled={invalid || submitting} onClick={handleSubmit}>
            Submit certificate
          </ModalPrimaryActionButton>
        </HSpace>
      </ModalContent>
    </Modal>
  );
};
