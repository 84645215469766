type Pages = "login" | "dashboard";

type TrackPageNavigation = `Navigate to: ${Pages}`;

type Modals = string | "Scan RFID Tags";
type Buttons = string | "Scan RFID Tags" | "Rescan RFID Tags" | "Use Scanned RFID Tags";

type OpenModal = `Open Modal: ${Modals}`;
type ButtonClick = `Click Button: ${Buttons}`;

export type TrackAction = string | TrackPageNavigation | OpenModal | ButtonClick | "View Page";

export enum MixAction {
  VISIT_TERMS_CONDITIONS = "Visit Terms & Conditions Page",
  VISIT_PRIVACY_POLICY = "Visit Privacy Policy Page",

  CLICK_NAVBAR_PRICING = "Click NavBar Pricing",
  CLICK_NAVBAR_LOGIN = "Click NavBar Login",
  CLICK_NAVBAR_CTA = "Click NavBar Call-to-Action",
  CLICK_HERO_CTA = "Click Hero Call-to-Action",
  CLICK_PRICING_CTA = "Click Pricing Call-to-Action",
  CLICK_HERO_DEMO = "Click See It In Action",
  CLICK_TALK_TO_SALES = "Click Talk to Sales",

  TRY_DEMO_SUBSCRIBE = "Try Subscribe Demo",

  CREATE_ACCOUNT_INITIATE = "Initiate Create Account",
  CREATE_ACCOUNT_VALIDATE = "Validate Account Email",
  CREATE_ACCOUNT_FINISH = "Finish Create Account",
  CREATE_ACCOUNT_START_USING = "Begin Using Product",

  CLICK_SHOP_NAVBAR_LOGIN = "Click Shop NavBar Login",

  OpenRFIDTagScannerModal = "Open RFID tag scanner modal",
  StartScanningRFIDTags = "Start scanning for RFID tags",
  AcceptScannedRFIDTags = "Accept scanned RFID tags",
  RemoteControlUnlinkDevice = "Unlink device",
}
