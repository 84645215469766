//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  statistics: {
    fetching: false,
    error: null,
    data: null,
  },
  metrics: {
    fetching: false,
    error: null,
    data: [],
    paging: {
      pageStart: 0,
      pageLimit: 10,
    },
  },
  storagesMetrics: {
    fetching: false,
    error: null,
    data: null,
  },
  assetsMetrics: {
    fetching: false,
    error: null,
    data: null,
  },
};

export default (state = initialState, action) => {
  let statistics;
  switch (action.type) {
    case type.REQUEST_STATISTICS:
      statistics = Object.assign({}, state.statistics, {
        fetching: true,
        error: null,
        data: null,
      });
      return Object.assign({}, state, { statistics });
    case type.REQUEST_STATISTICS_FAILURE:
      statistics = Object.assign({}, state.statistics, {
        fetching: false,
        error: action.error,
        data: null,
      });
      return Object.assign({}, state, { statistics });
    case type.RECEIVE_STATISTICS:
      statistics = Object.assign({}, state.statistics, {
        fetching: false,
        error: null,
        data: action.statistics,
      });
      return Object.assign({}, state, { statistics });
    case type.GET_METRICS_REQUEST:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          fetching: true,
          error: null,
          data: [],
        },
      };
    case type.GET_METRICS_FAILURE:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          fetching: false,
          error: action.payload,
          data: [],
        },
      };
    case type.GET_METRICS_RECEIVE:
      return {
        ...state,
        metrics: {
          ...state.metrics,
          fetching: false,
          error: null,
          data: action.payload,
          paging: action.paging,
        },
      };

    case type.GET_STORAGES_METRICS_REQUEST:
      return {
        ...state,
        storagesMetrics: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_STORAGES_METRICS_FAILURE:
      return {
        ...state,
        storagesMetrics: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.GET_STORAGES_METRICS_RECEIVE:
      return {
        ...state,
        storagesMetrics: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };

    case type.GET_ASSETS_METRICS_REQUEST:
      return {
        ...state,
        assetsMetrics: {
          fetching: true,
          error: null,
          data: null,
        },
      };
    case type.GET_ASSETS_METRICS_FAILURE:
      return {
        ...state,
        assetsMetrics: {
          fetching: false,
          error: action.payload,
          data: null,
        },
      };
    case type.GET_ASSETS_METRICS_RECEIVE:
      return {
        ...state,
        assetsMetrics: {
          fetching: false,
          error: null,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
