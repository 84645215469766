import styled from "styled-components";
import { Header } from "components/Typography";
import { SidebarImage } from "components/sidebars/SidebarImage/SidebarImage";

const Container = styled.div`
  text-align: center;
`;

const Name = styled(Header)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

type SidebarImageHeaderProps = {
  name: string;
  imageUrl?: string;
  className?: string;
};

export const SidebarImageHeader = ({ name, imageUrl, className }: SidebarImageHeaderProps) => {
  return (
    <Container className={className}>
      {imageUrl && <SidebarImage imageUrl={imageUrl} />}
      <Name>{name}</Name>
    </Container>
  );
};
