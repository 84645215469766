import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { MobileNavbar } from "components/shared";

const StyledHeader = styled(MobileNavbar)`
  display: none;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;

export const AppSidebarLayout = () => {
  return (
    <>
      <StyledHeader />
      <Outlet />
    </>
  );
};
