import Lookup from "lib/constants/Lookup";

export type TLookupStorageModel = {
  drawerCount: number;
};

class StorageModels extends Lookup<string, TLookupStorageModel> {
  TCW207N = "TCW207N";
  TCW208NBK = "TCW208NBK";
  TCB135 = "TCB135";
  KRL1033DPBO = "KRL1033DPBO";
  L5P1023BPES = "L5P1023BPES";
  KETN843C0PBO = "KETN843C0PBO";
  RCT070C = "RCT-070C";
  TCWXD120 = "TCWXD120";
  RC060_SAEI = "RC060-SAEI";
  BOTT_40402057 = "BOTT_40402057";

  constructor() {
    super();
    this.items = [
      {
        value: this.TCW207N,
        label: 'TCW207N - 37" Roller Cabinet',
        description: 'TCW207N - 37" Roller Cabinet',
        drawerCount: 7,
      },
      {
        value: this.TCW208NBK,
        label: 'TCW208NBK - 37" Black Tool Cabinet',
        description: 'TCW208NBK - 37" Black Tool Cabinet',
        drawerCount: 8,
      },
      {
        value: this.TCB135,
        label: "1.3M WIDE WALL HANGING TOOL CABINET",
        description: "1.3M WIDE WALL HANGING TOOL CABINET",
        drawerCount: 1,
      },
      {
        value: this.KRL1033DPBO,
        label: 'Snap-On 72" 19-Drawer Triple-Bank Masters Series Roll Cab',
        description: 'Snap-On 72" 19-Drawer Triple-Bank Masters Series Roll Cab',
        drawerCount: 19,
      },
      {
        value: this.L5P1023BPES,
        label: 'Snap-On 72" 22-Drawer Roll Cab',
        description: 'Snap-On 72" 22-Drawer Roll Cab',
        drawerCount: 22,
      },
      {
        value: this.KETN843C0PBO,
        label: '84" 16-Drawer Triple-Bank EPIQ™ Series Roll Cab',
        description: '84" 16-Drawer Triple-Bank EPIQ™ Series Roll Cab',
        drawerCount: 16,
      },
      {
        value: this.RCT070C,
        label: "Dura RCT-070C 1200 series",
        description: "Dura 1200 series tool storage cabinet with 7 drawers",
        drawerCount: 7,
      },
      {
        value: this.TCWXD120,
        label: '53" MAGNUM HD Cabinet 12 Drawers',
        description: '53" MAGNUM HD Cabinet 12 Drawers',
        drawerCount: 12,
      },
      {
        value: this.RC060_SAEI,
        label: "Dura RC060-SAEI",
        description: "Dura RC060-SAEI",
        drawerCount: 6,
      },
      {
        value: this.BOTT_40402057,
        label: "Bott 40402057",
        description: "Bott 40402057",
        drawerCount: 5,
      },
    ];
  }
}

export default new StorageModels();
