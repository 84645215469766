//@ts-nocheck
import * as type from "../actions/actionTypes";

const initialState = {
  data: {},
  fetching: "",
  error: null,
  paging: {
    pageStart: 0,
    pageLimit: 10,
    totalCount: 0,
    nextPageStart: "",
    previousPageStart: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_STORE_ITEMS_REQUEST:
    case type.GET_STORE_ITEM_REQUEST:
    case type.CREATE_STORE_ITEM_REQUEST:
    case type.UPDATE_STORE_ITEM_REQUEST:
    case type.DELETE_STORE_ITEM_IMAGE_REQUEST:
      return {
        ...state,
        fetching: action.type,
      };
    case type.GET_STORE_ITEMS_FAILURE:
    case type.GET_STORE_ITEM_FAILURE:
    case type.CREATE_STORE_ITEM_REJECT:
    case type.UPDATE_STORE_ITEM_REJECT:
      return {
        ...state,
        fetching: "",
        error: action.payload,
      };
    case type.GET_STORE_ITEMS_RECEIVE:
      return {
        ...state,
        fetching: "",
        data: action.payload.reduce((data, item) => ({ ...data, [item.id]: item }), state.data),
      };
    case type.GET_STORE_ITEM_RECEIVE:
    case type.CREATE_STORE_ITEM_FULFIL:
    case type.UPDATE_STORE_ITEM_FULFIL:
      return {
        ...state,
        fetching: "",
        data: { ...state.data, [action.payload.id]: action.payload },
      };
    case type.DELETE_STORE_ITEM_IMAGE_FULFIL:
      const item = state.data[action.payload.itemID];
      return {
        ...state,
        fetching: "",
        data: {
          ...state.data,
          [action.payload.itemID]: {
            ...item,
            images: item.images.filter((image) => image.id !== action.payload.imageID),
          },
        },
      };
    default:
      return state;
  }
};
