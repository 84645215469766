import { Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "store";
import { selectLocations, selectStorageByID } from "store/reducers";
import { TStorage } from "types";
import ToolboxPortrait from "components/ToolboxPortrait";
import { SecondaryButton } from "components/shared/buttons";
import { PlainCard } from "components/shared/cards";
import { StorageInventoryProgressBar } from "components/shared/features/storages";

const Image = styled.div`
  display: flex;
  flex: 0 0 7rem;
  align-items: center;
  justify-content: center;
`;

const Portrait = styled(ToolboxPortrait)`
  width: 7rem;
  height: 7rem;
`;

const formatDescription = (storage: TStorage): string => {
  if (!storage.assetsMetric) {
    return "No metrics available";
  }
  const { taken } = storage.assetsMetric;
  return taken > 0 ? `${taken} tools outstanding` : "Nothing outstanding";
};

type OutstandingStorageCardProps = {
  storageId: string;
};

export const OutstandingStorageCard = ({ storageId }: OutstandingStorageCardProps) => {
  const locations = useSelector(selectLocations);
  const entity = useSelector(selectStorageByID(storageId));
  if (!entity) return null;

  return (
    <Link to={`/apps/storage/toolboxes/${storageId}/inventory?assigned=true`} className="flex">
      <PlainCard className="p-4 w-full md:w-96 relative overflow-hidden hover:bg-gray-50 cursor-pointer">
        <div className="flex flex-row gap-6 flex-grow">
          <Image>
            <Portrait model={entity.model} />
          </Image>
          <div className="flex flex-col gap-4 flex-1 justify-between">
            <div>
              <p className="font-medium text-gray-900">{entity.name}</p>
              {entity.locationId && <p className="text-sm text-gray-400">{locations[entity.locationId]?.name}</p>}
              <StorageInventoryProgressBar summarise storageId={storageId} />
            </div>
            <SecondaryButton width="w-auto" className="flex-grow-0">
              See toolbox
            </SecondaryButton>
          </div>
        </div>
      </PlainCard>
    </Link>
  );
};
