import { useState } from "react";
import useDebounce from "effects/useDebounce";
import useDidMount from "effects/useDidMount";
import { v4 as uuidv4 } from "uuid";
import { openModal } from "lib/actions/modal";
import { showPopupFlag } from "lib/actions/userInterface";
import Apps from "lib/constants/Apps";
import Modals from "lib/constants/Modals";
import StorageModels from "lib/constants/StorageModels";
import handlePopupFlagError from "lib/errors/handlePopupFlagError";
import { MixAction, MixPanel } from "lib/mixpanel";
import { useDispatch } from "store";
import {
  lockDevice,
  powerOffDevice,
  rebootDevice,
  refreshDeviceScreen,
  unlinkStorage,
  unlockDevice,
  updateStorage,
} from "store/reducers";
import { FixLater, TStorage } from "types";
import { SecondaryButton, WarningButton } from "components/Buttons";
import { DataGrid, DataItem, DataItemGroup } from "components/DataGrid";
import { NumberInput, TextAreaInput, TextInput } from "components/Input";
import { RestrictByApp } from "components/Restricted";
import StageHeader from "components/StageHeader";
import { Paragraph, Text } from "components/Typography";
import FieldGroup from "components/form/FieldGroup/FieldGroup";
import { LocationSelector } from "components/inputs/LocationSelector/LocationSelector";
import SelectStorageModeInput from "components/inputs/SelectStorageModeInput";
import SelectStorageModelInput from "components/inputs/SelectStorageModelInput";
import { DrawerNames, VSpace } from "components/shared";

type StorageSettingsPageProps = {
  storage: TStorage;
};

export const StorageSettingsPage = ({ storage }: StorageSettingsPageProps) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState<TStorage>(storage);
  const debouncedValues = useDebounce(values, 750);

  useDidMount(() => saveChanges(), [debouncedValues]);

  const handleChange = (name: FixLater, value: FixLater) => {
    // @ts-ignore
    setValues((values) => {
      const patch = { ...values, [name]: value };
      if (name === "model") {
        // Ensure that the correct amount of drawers are saved when changing model
        const storageModel = StorageModels.resolveItem(patch.model);
        if (!storageModel) {
          console.error(`failed to resolve storage model '${patch.model}'`);
          return;
        }
        const newDrawers = new Array(storageModel.drawerCount);
        for (let i = 0; i < storageModel.drawerCount; i++) {
          const oldDrawer = values.drawers.length > i ? values.drawers[i] : null;
          newDrawers[i] = {
            id: uuidv4(),
            name: oldDrawer?.name,
            value: i + 1,
            order: i,
            fullSvg: oldDrawer?.fullSvg,
            svgPath: oldDrawer?.svgPath,
            irSensorBoardId: oldDrawer?.irSensorBoardId,
            irSensorThreshold: oldDrawer?.irSensorThreshold,
          };
        }
        patch.drawers = newDrawers;
      }
      return patch;
    });
  };

  const handleSelectChange = (name: string, value: FixLater) => {
    handleChange(name, value.value);
  };

  const changeLocation = (name: string, value: FixLater) => {
    handleChange(name, value?.id);
  };

  const saveChanges = async () => {
    try {
      await dispatch(
        updateStorage({
          ...storage,
          ...values,
        }),
      ).unwrap();
    } catch (err) {
      handlePopupFlagError(err);
    }
  };

  const handleUnlinkStorage = () => {
    dispatch(
      openModal(Modals.CONFIRMATION, {
        description:
          "Are you sure you want to unlink this toolbox? Doing this will return your physical toolbox to factory settings.",
        actionText: "Unlink toolbox",
        actionColor: "warning",
        onConfirm: async () => {
          try {
            await dispatch(unlinkStorage(storage.id)).unwrap();
            MixPanel.track(MixAction.RemoteControlUnlinkDevice, {
              storageId: storage.id,
            });
            dispatch(
              showPopupFlag({
                appearance: "success",
                title: "Storage unlinked",
              }),
            );
          } catch (err) {
            handlePopupFlagError(err);
          }
        },
      }),
    );
  };

  const lock = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          await dispatch(
            lockDevice({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will lock shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to lock this device?",
      "Lock device",
    );
  };

  const unlock = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          await dispatch(
            unlockDevice({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will unlock shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to unlock this device?",
      "Unlock device",
    );
  };

  const reboot = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          await dispatch(
            rebootDevice({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will reboot shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to reboot this device?",
      "Reboot device",
    );
  };

  const powerOff = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          await dispatch(
            powerOffDevice({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Device will shutdown shortly",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to shutdown this device?",
      "Shutdown device",
    );
  };

  const refreshStorageScreen = () => {
    return sendRemoteControlCommand(
      async () => {
        try {
          await dispatch(
            refreshDeviceScreen({
              storageId: storage.id,
            }),
          ).unwrap();
          dispatch(
            showPopupFlag({
              appearance: "success",
              title: "Screen was refreshed",
            }),
          );
        } catch (err) {
          handlePopupFlagError(err);
        }
      },
      "Are you sure you want to refresh the toolbox screen?",
      "Refresh screen",
    );
  };

  const sendRemoteControlCommand = (callback: () => void, description: string, actionText = "Confirm") => {
    return () => {
      dispatch(
        openModal(Modals.CONFIRMATION, {
          description: description,
          actionText: actionText,
          actionColor: "primary",
          onConfirm: callback,
        }),
      );
    };
  };

  if (!storage) {
    return null;
  }

  return (
    <>
      <StageHeader title="Settings" />
      <DataGrid>
        <DataItem label="Toolbox code" value={<Text>{storage.id}</Text>} />
        <DataItem
          label="Name"
          value={
            <TextInput
              value={values.name}
              input={{ name: "name", placeholder: "Enter name" }}
              onChange={handleChange}
            />
          }
        />
        <DataItem
          label="Description"
          value={
            <TextAreaInput
              value={values.description}
              input={{
                name: "description",
                placeholder: "Enter description",
              }}
              onChange={handleChange}
            />
          }
        />
        <DataItem
          label="Toolbox"
          value={
            <DataItemGroup>
              <VSpace>
                <FieldGroup label="Model">
                  <SelectStorageModelInput
                    appearance="normal"
                    name="model"
                    value={values.model}
                    onChange={handleSelectChange}
                  />
                </FieldGroup>
                <FieldGroup label="Drawer names">
                  <DrawerNames storage={values} onChange={(value: FixLater) => handleChange("drawers", value)} />
                </FieldGroup>
              </VSpace>
            </DataItemGroup>
          }
        />
        <DataItem
          label="Mode"
          value={
            <SelectStorageModeInput
              appearance="normal"
              name="modeId"
              value={values.modeId}
              onChange={handleSelectChange}
            />
          }
        />
        <RestrictByApp app={Apps.MODBUS}>
          <DataItem
            label="Modbus server port"
            value={
              <NumberInput
                value={values.modbusPort}
                input={{
                  name: "modbusPort",
                  placeholder: "Enter Modbus server port number (default: 502)",
                }}
                onChange={handleChange}
              />
            }
          />
        </RestrictByApp>
        <DataItem
          label="Location"
          value={
            <LocationSelector
              appearance="normal"
              name="locationId"
              value={values.locationId}
              onChange={changeLocation}
            />
          }
        />
        {storage.sentryApiKey?.dateActivated && (
          <DataItem
            label="Remote control"
            value={
              storage.online ? (
                <DataItemGroup>
                  <VSpace gap="gap-2">
                    <FieldGroup label="Drawers">
                      <SecondaryButton fitContent icon="/images/icons/lock_5.svg" onClick={lock()}>
                        Lock device
                      </SecondaryButton>
                      <SecondaryButton fitContent icon="/images/icons/unlock_2.svg" onClick={unlock()}>
                        Unlock device
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Power options">
                      <SecondaryButton fitContent icon="/images/icons/restart_1.svg" onClick={reboot()}>
                        Reboot
                      </SecondaryButton>
                      <SecondaryButton fitContent icon="/images/icons/power_1.svg" onClick={powerOff()}>
                        Power off
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Miscellaneous">
                      <SecondaryButton fitContent icon="/images/icons/loading.svg" onClick={refreshStorageScreen()}>
                        Refresh screen
                      </SecondaryButton>
                    </FieldGroup>
                    <FieldGroup label="Unlink toolbox">
                      <WarningButton fitContent icon="/images/icons/unlink_1.svg" onClick={handleUnlinkStorage}>
                        Unlink toolbox
                      </WarningButton>
                    </FieldGroup>
                  </VSpace>
                </DataItemGroup>
              ) : (
                <DataItemGroup>
                  <FieldGroup label="Commands">
                    <Paragraph muted>Toolbox must be online to access remote control commands.</Paragraph>
                  </FieldGroup>
                  <FieldGroup label="Unlink toolbox">
                    <WarningButton fitContent icon="/images/icons/unlink_1.svg" onClick={handleUnlinkStorage}>
                      Unlink Toolbox
                    </WarningButton>
                  </FieldGroup>
                </DataItemGroup>
              )
            }
          />
        )}
      </DataGrid>
    </>
  );
};
