import React, { createContext, ReactNode, useState } from "react";
import { Dialog } from "@headlessui/react";
import styled from "styled-components";

const SlideOverParent = styled.div<{ zIndex?: number }>`
  position: fixed;
  right: 0;
  top: 0;
  overflow-y: auto;
  z-index: ${({ zIndex = 0 }) => 20 + zIndex};
`;

type SlideOverProviderProps = {
  children: ReactNode;
};

type SlideOverContextProperties = {
  openSlideOver: (component: ReactNode) => void;
  closeSlideOver: () => void;
};

export const SlideOverContext = createContext<SlideOverContextProperties>({
  openSlideOver: () => {},
  closeSlideOver: () => {},
});

type State = {
  slideOvers: ReactNode[];
};

export const SlideOverProvider = ({ children }: SlideOverProviderProps) => {
  const [state, setState] = useState<State>({
    slideOvers: [],
  });

  const openSlideOver = (slideOver: ReactNode): void => {
    setState((prevState) => {
      return {
        slideOvers: [...prevState.slideOvers, slideOver],
      };
    });
  };

  const closeSlideOver = (): void => {
    setState((prevState) => {
      return {
        slideOvers: prevState.slideOvers.slice(0, prevState.slideOvers.length - 1),
      };
    });
  };

  return (
    <SlideOverContext.Provider
      value={{
        openSlideOver,
        closeSlideOver,
      }}
    >
      {children}
      {state.slideOvers.map((SlideOver, index) => {
        return (
          <Dialog key={`slide_over_${index}`} open as="div" className="relative z-10" onClose={closeSlideOver}>
            <SlideOverParent zIndex={index}>{SlideOver}</SlideOverParent>
          </Dialog>
        );
      })}
    </SlideOverContext.Provider>
  );
};
