//@ts-nocheck
import Apps from "lib/constants/Apps";
import Lookup from "lib/constants/Lookup";

class StorageModes extends Lookup {
  NO_TRACKING = 1;
  MANUAL_TRACKING = 2;
  RFID_TRACKING = 3;
  RFID_MONITORING = 4;
  KIOSK = 5;
  TOOL_STORE = 6;
  AUTOMATED_TRACKING = 7;

  constructor() {
    super();
    super._items = [
      {
        value: this.AUTOMATED_TRACKING,
        label: "Access control with automated tracking",
        app: Apps.TOOL_TRACKING,
      },
      {
        value: this.MANUAL_TRACKING,
        label: "Access control with manual tracking",
        app: Apps.TOOL_TRACKING,
      },
      {
        value: this.RFID_TRACKING,
        label: "Access control with RFID tracking",
        app: Apps.TOOL_TRACKING_RFID,
      },
      {
        value: this.KIOSK,
        label: "Kiosk (No access control)",
        app: Apps.TOOL_TRACKING,
      },
      {
        value: this.RFID_MONITORING,
        label: "RFID monitoring only",
        app: Apps.TOOL_TRACKING_RFID,
      },
      {
        value: this.NO_TRACKING,
        label: "Access control only",
        app: Apps.ACCESS_CONTROL,
      },
    ];
  }

  filterByApps = (appsNames) => {
    return this.items.filter(({ app }) => appsNames.find((appName) => app === appName));
  };
}

export default new StorageModes();
