//@ts-nocheck
import React, { useState } from "react";
import socket, { Headers } from "lib/Socket";
import { showPopupFlag } from "lib/actions/userInterface";
import Assets from "lib/api/assets";
import store from "store";
import { SecondaryButton } from "components/Buttons";
import Card from "components/Card";
import { Subheader } from "components/Typography";
import style from "components/modals/ManualCheckoutModal/modal.module.scss";
import ManualCheckoutList from "components/toolTrackingLists/ManualCheckoutList";
import CheckoutAssetSummary from "./CheckoutAssetSummary";

const CheckoutCard = ({ session, onClose }) => {
  const [asset, selectAsset] = useState(null);

  /**
   * Select an asset from the list of assets. This will change the modal view to show a
   * summary of the potential checkout.
   * @param asset
   */
  const handleSelect = (asset) => {
    selectAsset(asset);
  };

  /**
   * Unselect an asset. Calling this function will not close the modal but will show
   * the asset list again
   */
  const handleClearSelect = () => {
    selectAsset(null);
  };

  /**
   * Submit the checkout and close the modal
   */
  const handleCheckout = async () => {
    try {
      // Checkout the asset and assign it to the user
      await Assets.setAssetAssignment(asset.id, session.user.id);

      // Send a request for assigned assets
      socket.send(Headers.GET_ASSETS_ASSIGNED, session.user.id);
      onClose();
    } catch (err) {
      console.error(err);
      store.dispatch(
        showPopupFlag({
          appearance: "error",
          title: "Something went wrong",
          description: "Failed to check-out the tool",
        }),
      );
    }
  };

  return (
    <Card className={style.card} title={<Subheader>Tool Checkout</Subheader>}>
      {asset ? (
        <CheckoutAssetSummary asset={asset} onSubmit={handleCheckout} onCancel={handleClearSelect} />
      ) : (
        <>
          <ManualCheckoutList session={session} onSelect={handleSelect} />
          <SecondaryButton onClick={onClose} className={style.cardButton}>
            Close
          </SecondaryButton>
        </>
      )}
    </Card>
  );
};

const ManualCheckoutModal = ({ modal, onClose }) => {
  return <CheckoutCard session={modal.props.session} onClose={onClose} />;
};

export default ManualCheckoutModal;
