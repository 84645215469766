import { useLocation, useNavigate } from "react-router-dom";
import { useToolStorage } from "contexts/ToolStorageProvider/ToolStorageProvider";
import { useModal } from "effects";
import { TStorageAlert } from "types";
import { SecondaryButton } from "components/Buttons";
import StageHeader from "components/StageHeader";
import { CreateStorageAlertModal, PageLoading, StorageAlertsTable } from "components/shared";
import PageStage from "components/stages/PageStage";

export const StorageAlertsPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { storageId } = useToolStorage();
  const { openModal } = useModal();

  const createStorageAlert = () => {
    if (!storageId) return;
    openModal(
      <CreateStorageAlertModal
        storageId={storageId}
        onSuccess={(entity: TStorageAlert) => {
          navigate(`${pathname}/${entity.id}`);
        }}
      />,
    );
  };

  if (!storageId) {
    return <PageLoading />;
  }

  return (
    <PageStage>
      <StageHeader
        title="Device alerts"
        subtitle="Manage the alerts for this device"
        action={
          <SecondaryButton onClick={createStorageAlert} icon="/images/icons/plus_1.svg">
            Create alert
          </SecondaryButton>
        }
      />
      <StorageAlertsTable storageId={storageId} />
    </PageStage>
  );
};
