//@ts-nocheck
import * as type from "./actionTypes";
import { requestFailure } from "./common";

export function requestEvidence(evidenceId) {
  return {
    type: type.GET_EVIDENCE_REQUEST,
    payload: evidenceId,
  };
}

export function requestEvidenceSuccess(evidence) {
  return {
    type: type.GET_EVIDENCE_RECEIVE,
    payload: evidence,
  };
}

export function requestEvidenceFailure(error) {
  return requestFailure(type.GET_EVIDENCE_FAILURE, error);
}
