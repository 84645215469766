//@ts-nocheck
import { put, takeLatest } from "redux-saga/effects";
import * as type from "../actions/actionTypes";
import * as actions from "../actions/catalogue";
import Api from "../api/catalogue";

function* fetchManufacturers(action) {
  try {
    const { data, metadata } = yield Api.getManufacturers(action.payload);
    yield put(actions.requestManufacturersSuccess(data, metadata));
  } catch (err) {
    yield put(actions.requestManufacturersFailure(err));
  }
}

function* fetchProducts(action) {
  try {
    const { data, metadata } = yield Api.getProducts(action.payload);
    yield put(actions.requestProductsSuccess(data, metadata));
  } catch (err) {
    yield put(actions.requestProductsFailure(err));
  }
}

function* fetchCategories(action) {
  try {
    const { data, metadata } = yield Api.getCategories(action.payload);
    yield put(actions.requestCategoriesSuccess(data, metadata));
  } catch (err) {
    yield put(actions.requestCategoriesFailure(err));
  }
}

function* fetchManufacturer(action) {
  try {
    const data = yield Api.getManufacturer(action.payload);
    yield put(actions.requestManufacturerSuccess(data));
  } catch (err) {
    yield put(actions.requestManufacturerFailure(err));
  }
}

function* fetchProduct(action) {
  try {
    const data = yield Api.getProduct(action.payload);
    yield put(actions.requestProductSuccess(data));
  } catch (err) {
    yield put(actions.requestProductFailure(err));
  }
}

function* catalogueSaga() {
  yield takeLatest(type.GET_CATALOGUE_MANUFACTURERS_REQUEST, fetchManufacturers);
  yield takeLatest(type.GET_CATALOGUE_PRODUCTS_REQUEST, fetchProducts);
  yield takeLatest(type.GET_MANUFACTURER_CATEGORIES_REQUEST, fetchCategories);
  yield takeLatest(type.GET_CATALOGUE_MANUFACTURER_REQUEST, fetchManufacturer);
  yield takeLatest(type.GET_CATALOGUE_PRODUCT_REQUEST, fetchProduct);
}

export default catalogueSaga;
