import { createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "lib/API";
import {
  CreateAlertRecipientRequest,
  DataResponse,
  DeleteAlertRecipientRequest,
  TAlertRecipient,
  UpdateAlertRecipientRequest,
} from "types";

export const createAlertRecipient = createAsyncThunk(
  "alertRecipients/createAlertRecipient",
  async (request: CreateAlertRecipientRequest): Promise<TAlertRecipient> => {
    const { data } = await API.POST<CreateAlertRecipientRequest, DataResponse<TAlertRecipient>>(
      `/alerts/${request.alertId}/recipients`,
      request,
    );
    return data;
  },
);

export const updateAlertRecipient = createAsyncThunk(
  "alertRecipients/updateAlertRecipient",
  async (request: UpdateAlertRecipientRequest): Promise<TAlertRecipient> => {
    const { data } = await API.PUT<UpdateAlertRecipientRequest, DataResponse<TAlertRecipient>>(
      `/alerts/${request.alertId}/recipients/${request.id}`,
      request,
    );
    return data;
  },
);

export const deleteAlertRecipient = createAsyncThunk(
  "alertRecipients/deleteAlertRecipient",
  async (request: DeleteAlertRecipientRequest): Promise<void> => {
    await API.DELETE(`/alerts/${request.alertId}/recipients/${request.id}`);
  },
);
