import { useParams } from "react-router-dom";
import useSetSidebar from "effects/useSidebar";
import Capabilities from "lib/constants/Capabilities";
import { useSelector } from "store";
import { selectToolStoreByID } from "store/reducers";
import { Restrict } from "components/Restricted";
import { MenuItem } from "components/sidebars/MenuItem/MenuItem";
import Sidebar from "components/sidebars/Sidebar";
import { SidebarLoading } from "components/sidebars/SidebarLoading/SidebarLoading";
import { SidebarName } from "components/sidebars/SidebarName/SidebarName";

export const ToolStoreSidebar = () => {
  useSetSidebar(true);
  const { toolStoreID } = useParams();
  const url = `/apps/storage/stores/${toolStoreID}`;
  const entity = useSelector(selectToolStoreByID(toolStoreID || ""));

  if (!entity) {
    return <SidebarLoading />;
  }

  return (
    <Sidebar>
      <SidebarName name={entity.storage.name} />
      <Restrict capability={Capabilities.STORAGE_UPDATE}>
        <MenuItem to={`${url}/orders`} icon="/images/icons/order_2.svg">
          Orders
        </MenuItem>
        <MenuItem to={`${url}/inventory`} icon="/images/icons/tools_1.svg">
          Inventory
        </MenuItem>
        <MenuItem to={`${url}/settings`} icon="/images/icons/config_2.svg">
          Settings
        </MenuItem>
      </Restrict>
    </Sidebar>
  );
};
